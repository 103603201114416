import React, {memo, useCallback, useRef} from 'react';
import style from './ModalEditRouteSheetTableList.module.css'
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import CreateRouteSheetsOrderListTableListItem
    from "../CreateRouteSheetsOrderListTableListItem/CreateRouteSheetsOrderListTableListItem";
import {v4 as uuidv4} from "uuid";
import ModalEditRouteSheetTableItem from "../ModalEditRouteSheetTableItem/ModalEditRouteSheetTableItem";

const ModalEditRouteSheetTableList = observer(() => {
    const {editRouteSheetsData:{order_list}} = createRouteSheetsStore
    const cache = useRef(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 51
    }))

    const rowRenderer = useCallback(({key, index, style, parent}) => {
        return (
            <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                {({ measure }) => (
                    <div key={key} style={style} ref={measure}>
                        <ModalEditRouteSheetTableItem key={uuidv4()} orderData={order_list[index]} />
                    </div>
                )}
            </CellMeasurer>
        )
    }, [order_list]);
    //
    // const rowRenderer = ({ key, index, style, parent }) => (
    //     <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
    //         {({ measure }) => (
    //             <div key={key} style={style} ref={measure}>
    //
    //             </div>
    //         )}
    //     </CellMeasurer>
    //
    //     // <div key={key} style={{...style, display: 'flex', minWidth: '600px'}}>
    //     //
    //     //     {/*<CreateRouteSheetsOrderListTableListItem key={uuidv4()} orderData={orderList[index]} orderIndex={index}/>*/}
    //     // </div>
    // );

    return (
        <div style={{width: '100%', height: '100%', overflowX: 'auto'}}>
            {
                order_list && <AutoSizer disableHeight>
                {({width}) => (
                    <List
                        key={order_list.length}
                        width={width} // Используем переданную ширину
                        height={400} // Используем переданную высоту
                        rowCount={order_list.length || 0} // Количество строк
                        deferredMeasurementCache={cache.current}
                        rowHeight={cache.current.rowHeight} // Высота одной строки
                        rowRenderer={rowRenderer} // Функция для рендеринга строки
                        overscanRowCount={5} // Количество строк, которые будут предварительно загружены
                        className={style.tableList}
                    />
                )}
            </AutoSizer>}
        </div>
    );
});

export default memo(ModalEditRouteSheetTableList);