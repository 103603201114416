import React, {memo, useEffect, useState} from 'react';
import {ReactComponent as SettingIcon} from '../../../imges/SettingIcon.svg';
import {ReactComponent as InfoIcon} from '../../../imges/InfoIcon.svg';
import style from "./DocumentRouteList.module.css";
import {observer} from 'mobx-react-lite';
import {observable} from "mobx";
import optimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import settingOptimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import InfoBlock from "../InfoBlock/InfoBlock";
import {Tooltip} from 'react-tooltip';
import {Link} from "react-router-dom";
import toast from "react-hot-toast";

const DocumentRouteList = observer(({documentData, idDocument, uid}) => {
    const [localStateComponent] = useState(() => observable({
        settingChange: false, selectThisDocument: false, showFaq(value) {
            this.faqShowCreatePage = value
        }
    }))

    const showCurrentData = (dateValue) => {
        let date = new Date(documentData.delivery_date);
        return date.getDate().toString().padStart(2, '0') + '.' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
            date.getFullYear();
    }
    const [currentDeliveryDate, setCurrentDeliveryDate] = useState(showCurrentData())

    const handleSettingValue = () => {
        localStateComponent.settingChange = !localStateComponent.settingChange;
    }

    const checkCoordinateForInitPoint = () => {
        return (documentData.initial_route_point?.coordinate?.lat !== "" && documentData.initial_route_point?.coordinate?.lat !== null  && documentData.initial_route_point?.coordinate?.lon !== "" && documentData.initial_route_point?.coordinate?.lon !== null);
    }


    return (<>
        <div
            className={(documentData.checked) ? `${style.routeListDocument} ${style.active}` : style.routeListDocument}>
            {
                (!documentData.all_coordinates_present) ?
                    <div className={style.buttonInfo}>
                        <div
                            data-tooltip-id={`tooltip_${uid}`}
                            data-tooltip-html={"<div>Есть адреса без указанных координат! Перейдите на страницу редактирования координат адресов, для исправления."}
                            className="my-anchor-element"
                        >
                            <InfoIcon/>
                        </div>
                        <Tooltip
                            id={`tooltip_${uid}`}
                            // content="Есть адреса без указаных координат! Перейдите в параметры документа для исправления."
                        />
                    </div>
                    :
                    <div>
                        <input
                            type="checkbox"
                            className={style.selectDocumentML}
                            id={`selectDocumentML_${documentData.document_id}`}
                            name="selectDocumentML"
                            value={documentData.checked}
                            checked={documentData.checked}
                            onChange={() => {
                                optimizationRouteSheetStore.selectDocumentRoute(!documentData.checked, documentData.document_id)
                            }}
                        />
                        <label htmlFor={`selectDocumentML_${documentData.document_id}`}></label>
                    </div>
            }


            <div>{documentData.document_number}</div>
            <div>
                <div
                    className={(documentData.route_optimization) ? style.statusRouteOn : style.statusRouteOff}>оптимизирован
                </div>
                <div className={(documentData.route_built) ? style.statusRouteOn : style.statusRouteOff}>построен</div>
                <div className={(documentData.in_route) ? style.statusRouteOn : style.statusRouteOff}>в маршруте</div>
            </div>
            <div>
                <div>{showCurrentData(documentData.document_date)}</div>
                <div>{showCurrentData(documentData.delivery_date)}</div>
            </div>
            <div>
                <div>{documentData.drivers_info[0].driver_name}</div>
                <div>{documentData.vehicle_number}</div>
            </div>
            <div>
                {
                    (!documentData.initial_route_point.name || !checkCoordinateForInitPoint())
                        ? <div className={style.buttonInfo}>
                            <div
                                data-tooltip-id={`tooltip_${uid}`}
                                data-tooltip-html={`<div>${ checkCoordinateForInitPoint()? "Не указана начальная точка!":"У начальной точки нет координат!"}</div>`}
                                className="my-anchor-element"
                            >
                                <InfoIcon/>
                            </div>
                            <Tooltip
                                id={`tooltip_${uid}`}
                                // content="Есть адреса без указаных координат! Перейдите в параметры документа для исправления."
                            />
                        </div> : documentData.initial_route_point.name

                }
            </div>
            <div>
                <div
                    className={style.documentSetting}
                    onClick={() => handleSettingValue()}
                ><Link to={`/logisticService/routingByRouteList/editRouteSheets/${documentData.document_id}/${documentData.id}`}><SettingIcon/></Link></div>
            </div>
        </div>
    </>);
});

export default memo(DocumentRouteList);