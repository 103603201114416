import TrendingUpIcon from '@mui/icons-material/ExpandLess';
import TrendingDownIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { formatAndPrintDate } from "../../../helpers/helpers";


export default function DistributorCurrentHistory(props) {

    return (
        <TableRow sx={{backgroundColor: '#f1eded'}}>
            <TableCell style={{padding: 0}} colSpan={9}>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                    <Box>
                        <Table aria-label="simple table" sx={{backgroundColor: '#ffffff'}}>
                            <TableHead>
                                <TableRow
                                    sx={{fontWeight: 'bold', padding: '2px', backgroundColor: 'rgb(225,223,223)'}}>
                                    <TableCell>Дата</TableCell>
                                    <TableCell>Время</TableCell>
                                    <TableCell>Продажи</TableCell>
                                    <TableCell>Поставки</TableCell>
                                    <TableCell>Возвраты поставщику</TableCell>
                                    <TableCell>Возвраты покупателей</TableCell>
                                    <TableCell>Остаток начало дня</TableCell>
                                    <TableCell>Остаток конец дня</TableCell>
                                </TableRow>
                            </TableHead>
                            {props.history ?
                                <TableBody>
                                    {props.history.map((row) => (
                                        <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell>{formatAndPrintDate(row.dayOfUnloading)}</TableCell>
                                            <TableCell>{row.receivingTime}</TableCell>
                                            <TableCell>
                                                {row.productSales} шт.{' '}
                                                {row.productSales !== props.current.productSales ? (
                                                    row.productSales > props.current.productSales ? (
                                                        <TrendingUpIcon sx={{color: '#4dff4d'}}/>
                                                    ) : (
                                                        <TrendingDownIcon sx={{color: '#ff0000'}}/>
                                                    )
                                                ) : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.productPurchases} шт.{' '}
                                                {row.productPurchases !== props.current.productPurchases ? (
                                                    row.productPurchases > props.current.productPurchases ? (
                                                        <TrendingUpIcon sx={{color: '#4dff4d'}}/>
                                                    ) : (
                                                        <TrendingDownIcon sx={{color: '#ff0000'}}/>
                                                    )
                                                ) : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.productCustomerReturns} шт.{' '}
                                                {row.productCustomerReturns !== props.current.productCustomerReturns ? (
                                                    row.productCustomerReturns > props.current.productCustomerReturns ? (
                                                        <TrendingUpIcon sx={{color: '#4dff4d'}}/>
                                                    ) : (
                                                        <TrendingDownIcon sx={{color: '#ff0000'}}/>
                                                    )
                                                ) : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.productSellerReturns} шт.{' '}
                                                {row.productSellerReturns !== props.current.productSellerReturns ? (
                                                    row.productSellerReturns > props.current.productSellerReturns ? (
                                                        <TrendingUpIcon sx={{color: '#4dff4d'}}/>
                                                    ) : (
                                                        <TrendingDownIcon sx={{color: '#ff0000'}}/>
                                                    )
                                                ) : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.remainsDayStart} шт.{' '}
                                                {row.remainsDayStart !== props.current.remainsDayStart ? (
                                                    row.remainsDayStart > props.current.remainsDayStart ? (
                                                        <TrendingUpIcon sx={{color: '#4dff4d'}}/>
                                                    ) : (
                                                        <TrendingDownIcon sx={{color: '#ff0000'}}/>
                                                    )
                                                ) : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.remainsDayEnd} шт.{' '}
                                                {row.remainsDayEnd !== props.current.remainsDayEnd ? (
                                                    row.remainsDayEnd > props.current.remainsDayEnd ? (
                                                        <TrendingUpIcon sx={{color: '#4dff4d'}}/>
                                                    ) : (
                                                        <TrendingDownIcon sx={{color: '#ff0000'}}/>
                                                    )
                                                ) : null}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody> : null}
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
