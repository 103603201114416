import React, { memo, useContext, useEffect, useState } from "react";
import style from "./DriverAndForwarderTableList.module.css";
import { useNavigate, useParams } from "react-router-dom";
import deliveredStatusList from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import GlobalContext from "../../../../context/GlobalContext";
import { observer } from "mobx-react-lite";
import { ReactComponent as ImgNoneData } from "../../../../imges/noneData.svg";
import { ReactComponent as DeleteIcon } from "../../../../imges/DeleteIcon.svg";
import { v4 as uuidv4 } from "uuid";
import TablePaginationRouteSheets from "../../TablePaginationRouteSheets/TablePaginationRouteSheets";
import pageDriverAndForwarderStore from "../../../../store/transportationLogisticsService/pageDriverAndForwarderStore/pageDriverAndForwarderStore";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import DriverAndForwarderListItem from "../DriverAndForwarderListItem/DriverAndForwarderListItem";
import { ReactComponent as ArrowUp } from "../../../../imges/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../../imges/ArrowDown.svg";
const DriverAndForwarderTableList = observer(() => {
  const { authTokens, setUser, setAuthTokens, logoutUser } =
    useContext(GlobalContext);
  const {
    documentListCopy,
    documentList,
    paginationState: { page },
    tableDocumentListState: { allChecked },
    popupState: { popupShow, popupType, popupScrollPosition },
  } = pageDriverAndForwarderStore;
  const navigate = useNavigate();
  const { typeStatus } = useParams();

  const handleChangePage = (newPage) => {
    pageDriverAndForwarderStore.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    pageDriverAndForwarderStore.handleChangeRowsPerPage(rowsPerPage);
  };

  const changeSelectedAllTemp = (value) => {
    pageDriverAndForwarderStore.changeSelectedAllDocument(value);
  };

  const deleteSelectedTemp = async () => {
    try {
      await pageDriverAndForwarderStore.deleteSelectCardById(
        authTokens,
        setUser,
        setAuthTokens,
        logoutUser
      );
    } catch (e) {}
  };

  const [sortOrder, setSortOrder] = useState({
    driver_name: "asc",
    forwarder: "asc",
  });

  const sortTemplates = (field) => {
    // Определяем новый порядок сортировки (asc, desc или none)
    const newOrder =
      sortOrder[field] === "asc"
        ? "desc"
        : sortOrder[field] === "desc"
        ? "none"
        : "asc";

    // Обновляем состояние сортировки с новым порядком для данного поля
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [field]: newOrder,
    }));

    // Проверяем, требуется ли сортировка
    if (newOrder === "none") {
      // Если сортировка отсутствует, возвращаем список к исходному состоянию (без сортировки)
      pageDriverAndForwarderStore.documentListCopy =
        pageDriverAndForwarderStore.documentListCopy.slice();
    } else {
      // Если сортировка задана, выполняем сортировку
      pageDriverAndForwarderStore.documentListCopy =
        pageDriverAndForwarderStore.documentListCopy.slice().sort((a, b) => {
          if (field === "driver_name") {
            if (a.driver_name < b.driver_name)
              return newOrder === "asc" ? -1 : 1;
            if (a.driver_name > b.driver_name)
              return newOrder === "asc" ? 1 : -1;
            return 0;
          } else if (field === "forwarder") {
            if (a.forwarder < b.forwarder) return newOrder === "asc" ? -1 : 1;
            if (a.forwarder > b.forwarder) return newOrder === "asc" ? 1 : -1;
            return 0;
          }
          return 0;
        });
    }
  };

  return (
    <div className={style.templateList}>
      <div className={style.templateListHeader}>Список карточек</div>

      <div className={style.pagination}>
        <TablePaginationRouteSheets
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={documentList.length}
          paginationText={"Карточек на странице:"}
        />
      </div>

      <div className={style.templateListTableHeader}>
        <div>
          <input
            type="checkbox"
            className={style.selectAllTemplate}
            id="selectAllTemplate"
            name="selectAllTemplate"
            checked={allChecked}
            value={allChecked}
            onChange={(event) => {
              event.stopPropagation();
              changeSelectedAllTemp(!allChecked);
            }}
          />
          <label htmlFor="selectAllTemplate"></label>
        </div>
        <div
          className={style.documentDelivery}
          onClick={() => sortTemplates("driver_name")}
        >
          ФИО
          <div>
            <span
              className={
                sortOrder.driver_name === "asc" ? style.activeArrow : ""
              }
            >
              <ArrowUp />
            </span>
            <span
              className={
                sortOrder.driver_name === "desc" ? style.activeArrow : ""
              }
            >
              <ArrowDown />
            </span>
          </div>
        </div>
        <div
          className={style.documentDelivery}
          onClick={() => sortTemplates("forwarder")}
        >
          Тип
          <div>
            <span
              className={sortOrder.forwarder === "asc" ? style.activeArrow : ""}
            >
              <ArrowUp />
            </span>
            <span
              className={
                sortOrder.forwarder === "desc" ? style.activeArrow : ""
              }
            >
              <ArrowDown />
            </span>
          </div>
        </div>
        <div>Удалить</div>
        <div>Редактирование</div>
      </div>

      {documentListCopy.length > 0 ? (
        documentListCopy.map((doc) => {
          return <DriverAndForwarderListItem key={uuidv4()} docData={doc} />;
        })
      ) : (
        <div className={style.noDataImg}>
          <ImgNoneData />
          <div>Нет созданных шаблонов</div>
        </div>
      )}

      <PopupRouting
        isOpen={popupShow && popupType === "askDeleteSelectedDocs"}
        onClose={() => pageDriverAndForwarderStore.closePopup()}
        scrollPosition={popupScrollPosition}
      >
        <div className={style.popupHeader}>
          Уточнение
          <div
            className={style.btnClosePopup}
            onClick={() => {
              pageDriverAndForwarderStore.closePopup();
            }}
          >
            <DeleteIcon />
          </div>
        </div>
        <div className={style.askText}>
          Вы точно хотите удалить все выбранные карточки, действие будет
          невозможно отменить?
        </div>
        <div className={style.actionAskBtns}>
          <button
            className={style.acceptAskBtn}
            onClick={() => deleteSelectedTemp()}
          >
            Подтвердить
          </button>
          <button
            className={style.cancelAskBtn}
            onClick={() => pageDriverAndForwarderStore.closePopup()}
          >
            Отменить
          </button>
        </div>
      </PopupRouting>
    </div>
  );
});

export default memo(DriverAndForwarderTableList);
