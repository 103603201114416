import React, {useCallback, useEffect, useState} from 'react';
import routeParamSelectorHOC from "../hoc/RouteParamSelectorHOC";
import ModalChangeRouteParam from "../ModalChangeRouteParam/ModalChangeRouteParam";
import style from "./ModalEditViewRouteSheet.module.css";
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import ModalEditRouteSheetTable from "../ModalEditRouteSheetTable/ModalEditRouteSheetTable";
import {observer} from "mobx-react-lite";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import DatePicker from "../../../../inputs/logisticService/DatePicker/DatePicker";
import globalState from "../../../../store/transportationLogisticsService/globalState";
import TimeInput from "../../../../inputs/logisticService/TimeInput/TimeInput";


const CreateRouteSheetsOrderListModalSelectedInitialPointWithHOC = routeParamSelectorHOC(
    ModalChangeRouteParam,
    "Выбрать начальную точку",
    "changeInitPoint",
    "editRoute"
);

const CreateRouteSheetsOrderListModalSelectedDriverWithHOC = routeParamSelectorHOC(
    ModalChangeRouteParam,
    "Выбрать водителя",
    "changeDriver",
    "editRoute"
);

const CreateRouteSheetsOrderListModalSelectedVehicleWithHOC = routeParamSelectorHOC(
    ModalChangeRouteParam,
    "Выбрать транспорт",
    "changeVehicle",
    "editRoute"
);

const ModalEditViewRouteSheet = observer(() => {
    const {editRouteSheetsData, creatingRouteSheetsModalState: {changeDeleteRoute}} = createRouteSheetsStore
    const axiosInstance = AxiosConfig_LogisticService();
    const [inputValueTime, setInputValueTime] = useState(editRouteSheetsData.delivery_time || "00:00");
    const [statusTonnage, setStatusTonnage] = useState(undefined);

    useEffect(() => {
        setTonnageStatus();
    }, [editRouteSheetsData?.order_list.length ]);

    const getTotalTonnage = () => {
        let totalTonnage = 0;

        if (editRouteSheetsData.order_list.length === 0){
            return totalTonnage
        } else {
            for (const order of editRouteSheetsData.order_list){
                totalTonnage += order?.vehicle_tonnage;
            }
            return Number(totalTonnage).toFixed(2)
        }

    }

    const getFreeLoad = () => {
        const vehicleTonnageInKg = editRouteSheetsData?.vehicle_data?.tonnage * 1000;
        const totalTonnageInRoute = getTotalTonnage();

        return Number(vehicleTonnageInKg - totalTonnageInRoute).toFixed(2);
    }

    const handleInputChange = useCallback((newTime) => {
        setInputValueTime(newTime);
        createRouteSheetsStore.changeDeliveryTime(newTime, "edit");
    }, []);

    const updateEditRouteSheet = async () => {

        if (editRouteSheetsData?.order_list.length === 0){
            createRouteSheetsStore.changeRouteSheetDataModal(!changeDeleteRoute, "changeDeleteRoute");
        } else {
            await createRouteSheetsStore.updateEditRouteSheet(axiosInstance);
        }

    }

    const setTonnageStatus = (totalTonnage) => {
        const vehicleTonnageInKg = editRouteSheetsData?.vehicle_data?.tonnage * 1000;
        const totalTonnageInRoute = getTotalTonnage();

        if (vehicleTonnageInKg && !isNaN(vehicleTonnageInKg) && totalTonnageInRoute && !isNaN(totalTonnageInRoute)) {
            const midpoint = vehicleTonnageInKg / 2;

            if (totalTonnageInRoute <= midpoint) {
                setStatusTonnage("totalTonnageSuccess");
            } else if (totalTonnageInRoute > midpoint && totalTonnageInRoute < vehicleTonnageInKg) {
                setStatusTonnage("totalTonnageOverHalf");
            } else {
                setStatusTonnage("totalTonnagePreload");
            }
        } else {
            setStatusTonnage("unknown");
        }
    }

    const deleteSelectedOrder = () => {
        createRouteSheetsStore.deleteSelectedOrdersInRoute();
    }

    const changeDeliveryDate = (newDate) => {
        createRouteSheetsStore.changeDeliveryDate(newDate, "edit")
    }

    return (
        <div className={style.modalContent}>
            <div className={style.popupHeader}>
                Редактирование маршрутного листа
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup();
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>

            <div className={style.routeInfo}>
                <div className={style.infoBlock}>
                    <div className={style.tonnageCar}>Грузоподъемность транспорта</div>
                    <div>
                        {`${editRouteSheetsData?.vehicle_data?.tonnage || 0} т.`}
                    </div>
                </div>
                <div className={style.infoBlock}>
                    <div>Общий вес заказов</div>
                    <div
                        className={`${style[statusTonnage]}`}
                    >{`${getTotalTonnage()} кг.`}</div>
                </div>
                <div className={style.infoBlock}>
                    <div>Свободный вес</div>
                    <div
                        className={`${style[statusTonnage]}`}
                    >{`${getFreeLoad()} кг.`}</div>
                </div>
                <div className={style.infoBlock}>
                    <div>Заказов</div>
                    <div>{`${editRouteSheetsData?.order_list.length || 0}`}</div>
                </div>
            </div>

            <div className={style.selectProperty}>
                <div className={style.headerOptimizationInitPoint}>Начальная точка маршрута:</div>
                <CreateRouteSheetsOrderListModalSelectedInitialPointWithHOC/>
                <div className={style.headerOptimizationInitPoint}>Водитель:</div>
                <CreateRouteSheetsOrderListModalSelectedDriverWithHOC/>
                <div className={style.headerOptimizationInitPoint}>Транспорт:</div>
                <CreateRouteSheetsOrderListModalSelectedVehicleWithHOC/>
            </div>

            <div className={style.deliveryDateContent}>
                <div className={style.changeDate}>
                    <div className={style.selectDataText}>Дата доставки:</div>
                    <DatePicker
                        value={editRouteSheetsData.delivery_date}
                        onChange={(newDate) => changeDeliveryDate(newDate)}
                    />
                </div>
                <div className={style.changeTime}>
                    <div className={style.selectDataText}>Время доставки:</div>
                    <TimeInput value={inputValueTime} onChange={(newTime) => handleInputChange(newTime)}/>
                </div>
            </div>

            <div className={style.actionBtns}>
                <button className={style.showSelectedAreaBtn}
                        onClick={() => updateEditRouteSheet()}
                >
                    Сохранить
                </button>
                <button
                    className={style.cancelAskBtn}
                    onClick={() => {
                        createRouteSheetsStore.closePopup()
                    }}
                >
                    Отмена
                </button>
                <button
                    className={style.cancelAskBtn}
                    onClick={() => deleteSelectedOrder()}
                >
                    Удалить выбранные заказы
                </button>
            </div>

            <div className={style.subHeader}>Заказы</div>

            <ModalEditRouteSheetTable/>

        </div>
    );
});

export default ModalEditViewRouteSheet;