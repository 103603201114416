// export function user (state, action) {
//     return {user: state.user = action.dataJSON}
// }
export function manufacturer (state, action) {
    return {manufacturer: state.manufacturer = action.dataJSON}
}

export function distributors(state, action) {
    // console.log(state)
    return {distributors: state.distributers = action.dataJSON}
}


export function counteragentsAndRetailOutlets(state, action) {
    // console.log(action.idDistrib)

    return {counteragentsAndRetailOutlets: {...state.counteragentsAndRetailOutlets, [action.idDistrib]: action.dataJSON}}
}

export function sku(state, action) {
    // console.log(action.idDistrib)

    return {sku: {...state.sku, [action.idDistrib]: action.dataJSON}}
}

export function docsSum(state, action) {

    return {docsSum: state.docsSum = action.dataJSON}
    // return {docsSum: {...state.distributors[action.idDistrib], sum: action.dataJSON}}

}




export function distributorCurrentRemains(state, action) {
    // console.log(action.idDistrib)

    return {currentRemains: {...state.currentRemains, [action.idDistrib]: action.dataJSON}}
}

export function distributorHistoryRemains(state, action) {
    // console.log(action.idDistrib)

    return {historyRemains: {...state.historyRemains, [action.idRemains]: action.dataJSON}}
}

export function registerDates(state, action) {
    // console.log(action.idDistrib)

    return {registerDates: {...state.registerDates, [action.idDistrib]: action.dataJSON}}
}



export function counterparties (state, action) {
    // console.log(state)
    return {counterparties: state.counterparties = action.dataJSON}
}

export function retailOutlets (state, action) {
    // console.log(state)
    return {retailOutlets: state.retailOutlets = action.dataJSON}
}

export function distributorUsers (state, action) {
    // console.log(state)
    return {distributorUsers: state.distributorUsers = action.dataJSON}
}

export function manufacturerNomenclature (state, action) {
    // console.log(state)
    return {manufacturerNomenclature: state.manufacturerNomenclature = action.dataJSON}
}

export function totalSells (state, action) {
    // console.log(state)
    return {totalSells: state.manufacturerNomenclature = action.dataJSON}
}

export function profileDistributors (state, action) {
    return {profileDistributors: state.profileDistributors = action.dataJSON}
}

export function profileManufacturersList (state, action) {
    // console.log(state)
    return {profileManufacturersList: state.profileManufacturersList = action.dataJSON}
}

export function distibutorNomenclature (state, action) {
    // console.log(state)
    return {distibutorNomenclature: state.distibutorNomenclature = action.dataJSON}
}

export function profileDistibutorRetail (state, action) {
    // console.log(state)
    return {profileDistibutorRetail: state.profileDistibutorRetail = action.dataJSON}
}

export function profileDistibutorRegisterDate (state, action) {
    // console.log(state)
    return {profileDistibutorRegisterDate: state.profileDistibutorRegisterDate = action.dataJSON}
}


export function distributorDocsSum(state, action) {

    return {distributorDocsSum: state.distributorDocsSum = action.dataJSON}
    // return {docsSum: {...state.distributors[action.idDistrib], sum: action.dataJSON}}

}

export function distributorCounteragentsAndRetailOutlets(state, action) {
    // console.log(action.idDistrib)

    return {distributorCounteragentsAndRetailOutlets: {...state.distributorCounteragentsAndRetailOutlets, [action.idManufacturer]: action.dataJSON}}
}


export function manufacturerSKU(state, action) {
    // console.log(action.idDistrib)

    return {manufacturerSKU: {...state.manufacturerSKU, [action.idManufacturer]: action.dataJSON}}
}

export function manufacturerCurrentRemains(state, action) {
    // console.log(action.idDistrib)

    return {manufacturerCurrentRemains: {...state.manufacturerCurrentRemains, [action.idManufacturer]: action.dataJSON}}
}



export function manufacturerHistoryRemains(state, action) {
    // console.log(action.idDistrib)

    return {manufacturerHistoryRemains: {...state.manufacturerHistoryRemains, [action.idRemains]: action.dataJSON}}
}


export function subscriptions(state, action) {
    // console.log(action.idDistrib)

    return {subscriptions: state.subscriptions = action.dataJSON}
}
