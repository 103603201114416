import * as React from 'react';
import "./ReportsPage.css";

import VerticalTabs from '../../components/Reports/ReportsMenu';
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import IsLockedPage from '../IsLockedPage/IsLockedPage';
const ReportsPage = () => {
    const {state} = useContext(GlobalContext);

    return (
        <>
        {state.subscriptions ? (
          <>
            {state.subscriptions.filter((item) => item.code === 2)[0].active ? (
        <div className={"reportsContent"}>
                        <VerticalTabs/>
        </div>
         ) : (
            <IsLockedPage nameService={"САВП"} />
          )}
        </>
      ) : null}
    </>
    )
}
export default ReportsPage