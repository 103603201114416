import React, {memo, useEffect} from 'react';
import ContentPrintApplicationAccess from "../../ContentPrintApplicationAccess/ContentPrintApplicationAccess";
import {v4 as uuidv4} from "uuid";
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import {observer} from "mobx-react-lite";
import {useReactToPrint} from "react-to-print";

const RoutePrintQrData = observer(() => {
    const componentRef = React.useRef(null);
    const {pageState:{createMobAuthData}, routingSheets} = optimizationRouteSheetStore

    const printDriversAuth = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "DriverLoginToMobileApp"
    });

    useEffect(()=> {
        if (createMobAuthData && componentRef.current) {
            const timeoutId = setTimeout(() => {
                printDriversAuth();
                optimizationRouteSheetStore.changeCreateMobAuthData();
            }, 120); // Установите минимальное время ожидания, чтобы гарантировать, что эффект выполняется после рендеринга

            return () => clearTimeout(timeoutId);
        }
    }, [createMobAuthData])


    return (
        <>
            {
                (createMobAuthData) ?
                    <div style={{display: 'none'}}>
                        <div ref={componentRef}>
                            {
                                routingSheets.map((objDoc, index) => {
                                    if (objDoc.in_route && objDoc.checked) {
                                        return <ContentPrintApplicationAccess key={uuidv4()} docData={objDoc}/>
                                    }
                                })
                            }
                        </div>
                    </div> : null
            }
        </>
    );
});

export default memo(RoutePrintQrData);