import React, {memo, useEffect} from 'react';
import style from './DeliveryAreaCreatePointList.module.css'
import {AutoSizer, List} from "react-virtualized";
import createNewZona from "../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone";
import {observer} from "mobx-react-lite";
import DeliveryAreaCoordinate from "../DeliveryAreaCoordinate/DeliveryAreaCoordinate";
import {v4 as uuidv4} from "uuid";

const DeliveryAreaCreatePointList = observer(() => {
    const {coordinates} = createNewZona.newZona

    const rowRenderer = ({index, key, style, isVisible, isScrolling}) => {
        const content = <DeliveryAreaCoordinate
            key={uuidv4()}
            number={index + 1}
            typeAction={"create"}
            coordinate={createNewZona.newZona.coordinates[index]["coordinates"]}
            onChecked={createNewZona.newZona.coordinates[index]["checked"]}
        />;

        return (
            <div key={key} style={style}>
                {content}
            </div>
        )
            ;
    };

    return (
        <div className={style.areaPointList}>
            {<AutoSizer disableHeight>
                {({width}) => (
                    <List
                        // ref={setListRef}
                        width={width}
                        height={455}
                        rowCount={coordinates.length} // Количество элементов в списке
                        rowHeight={51} // Высота отдельного элемента списка
                        rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                        // className={style.settingDeliveryZoneNavCreateListPoints}
                        overscanRowCount={10}
                    />
                )}
            </AutoSizer>}
        </div>
    );
});

export default memo(DeliveryAreaCreatePointList);