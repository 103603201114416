import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './DeliveryAreaCreateSelectAreaList.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {AutoSizer, List} from "react-virtualized";
import {observer} from "mobx-react-lite";
import Skeleton from "@mui/material/Skeleton";
import {v4 as uuidv4} from "uuid";
import GlobalContext from "../../../../context/GlobalContext";
import debounce from "lodash/debounce";
import createNewZona from "../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone";
import DeliveryAreaCreateSelectAreaHeaderList
    from "../DeliveryAreaCreateSelectAreaHeaderList/DeliveryAreaCreateSelectAreaHeaderList";
import DeliveryAreaCreateSelectAreaItem from "../DeliveryAreaCreateSelectAreaItem/DeliveryAreaCreateSelectAreaItem";


const DeliveryAreaCreateSelectAreaList = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const [areaName, setAreaName] = useState("")
    const {listCreatedArea} = createNewZona

    useEffect(() => {

        async function getCreatedAreaList(){
            await createNewZona.getAllDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser);
        }

        getCreatedAreaList();

        return () => {
            createNewZona.changeDefaultValueModalSelectCreatedArea();
        }

    }, []);

    const updateAreaName = useCallback(
        debounce((value) => {
            createNewZona.changeDeliveryAreaText(value)
        }, 500), // Задержка в миллисекундах
        []
    );

    const changeNewSearchValue = (newValue) => {
        setAreaName(newValue);
        updateAreaName(newValue);
    }

    const showSelectedArea = () => {
        createNewZona.showCreatedDeliveryArea();
        createNewZona.closePopup();
    }

    const rowRenderer = ({index, key, style, isVisible, isScrolling}) => {
        const content = !isVisible ? <Skeleton
            animation="wave"
            variant="rounded"
            width={"100%"}
            height={100}
        /> : <DeliveryAreaCreateSelectAreaItem
            key={uuidv4()}
            number={index}
            area={listCreatedArea[index]}
            // typeAction={"update"}
            // showSelectZoneByMap={showSelectZoneByMap}
            // zoomAndShowClickZona={zoomAndShowClickZona}
        />;

        return (
            <div key={key} style={style}>
                {content}
            </div>
        )
            ;
    };

    return (
        <>
            <div className={style.popupHeader}>
                Список зон
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createNewZona.closePopup()
                    }}
                ><DeleteIcon/></div>
            </div>
            <div
                id={"searchInputZone"}
                className={style.inputSearchNameZona}>
                <input
                    type="text"
                    maxLength={50}
                    value={areaName}
                    onChange={(e) => changeNewSearchValue(e.target.value)}
                    placeholder="Поиск по наименованию"
                />
                <span onClick={
                    () => {
                        setAreaName("")
                        changeNewSearchValue("")
                    }
                }>Очистить</span>
            </div>

            <div className={style.actionBtns}>
                <button className={style.showSelectedAreaBtn}
                        onClick={() => showSelectedArea()}
                >
                    Показать выбранные
                </button>
                <button className={style.cancelAskBtn}
                        onClick={() => createNewZona.closePopup()}
                >
                    Отмена
                </button>
            </div>
            <DeliveryAreaCreateSelectAreaHeaderList/>
            <div>
                {listCreatedArea && <AutoSizer disableHeight>
                    {({width}) => (
                        <List
                            // ref={setListRef}
                            width={width}
                            height={255}
                            rowCount={listCreatedArea.length} // Количество элементов в списке
                            rowHeight={51} // Высота отдельного элемента списка
                            rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                            className={style.popupInitPointListBlock}
                            overscanRowCount={10}
                        />
                    )}
                </AutoSizer>}
            </div>
        </>
    );
});

export default memo(DeliveryAreaCreateSelectAreaList);