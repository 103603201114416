import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import OutputIcon from "@mui/icons-material/Output";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import RouteIcon from "@mui/icons-material/Route";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PlaceIcon from "@mui/icons-material/Place";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import MapIcon from "@mui/icons-material/Map";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { memo, useContext, useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import logoGeneral from "../../imges/generalLogo.svg";
import "./Content&Menu.css";
import DatePickerMui from "../../components/ui/DatePickerMui/DatePickerMui";
import { Button, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import PopupSideMenuLogistic from "../../components/transportationLogisticsService/UI/PopupSideMenuLogistic/PopupSideMenuLogistic";
import { observer } from "mobx-react-lite";
import savpMenuLogisticOption from "../../store/transportationLogisticsService/savpMenuLogisticOption/savpMenuLogisticOption";
import ContentMenuLogisticService from "../../components/transportationLogisticsService/Content&MenuLogisticService/Content&MenuLogisticService";
import LogisticServiceSideBar from "../../components/transportationLogisticsService/UI/LogisticServiceSideBar/LogisticServiceSideBar";
import { useNavigate } from "react-router-dom";
const drawerWidth = 210;

const ContentMenu = observer((props) => {
  const {
    menuLogisticState: {
      showSubRightMenu,
      selectLogisticMenuOption,
      selectedSubNavId,
    },
    logisticServiceUrl,
  } = savpMenuLogisticOption;

  const { state } = useContext(GlobalContext);
  const subscriptionNav = useNavigate();
  const location = useLocation();
  const savpAccess = state.subscriptions?.filter((item) => item.code === 2)[0].active

  let [navigate, setNavigate] = useState(decodeURIComponent(location.pathname));

const [accessLogistics, setAccessLogistics] = useState(false)

useEffect(() => {
  const checkSubscription = () => {
    if (state.subscriptions && navigate.split("/")[1] === "logisticService") {
      const subscription = state.subscriptions.find((item) => item.code === 3);
      if (subscription && !subscription.active) {
        setAccessLogistics(false);
        subscriptionNav("/notSubscription");
      } else {
        setAccessLogistics(true);
      }
    }
  };

  checkSubscription();
}, [state.subscriptions, navigate, subscriptionNav]);


  const { logoutUser, user } = useContext(GlobalContext);

  useEffect(() => {
    setNavigate(decodeURIComponent(location.pathname));
  }, [location.pathname]);

  

  const url =
    user && user.accessLevel == 1 && savpAccess
      ? [
          { fulName: "Профиль", urlName: "/profile/", icon: <CoPresentIcon /> },
          { fulName: "Метрика", urlName: "/", icon: <QueryStatsIcon /> },
          {
            fulName: "Реализация",
            urlName: "/realization/",
            icon: <PointOfSaleIcon />,
          },
          { fulName: "Остатки", urlName: "/remains/", icon: <WarehouseIcon /> },
          {
            fulName: "Отчеты",
            urlName: "/сonstructor/",
            icon: <AppRegistrationIcon />,
          },
          { fulName: "Скоро", urlName: "/soon/", icon: <EmojiObjectsIcon /> },
          {
            fulName: "Логистика",
            urlName: "/logisticService/routingByRouteList/routeSheetsList/",
            icon: <RouteIcon />,
          },
        ]
      : user && user.accessLevel == 0 && savpAccess ?
      [
          { fulName: "Профиль", urlName: "/profile/", icon: <CoPresentIcon /> },
          { fulName: "Метрика", urlName: "/", icon: <QueryStatsIcon /> },
          {
            fulName: "Реализация",
            urlName: "/realization/",
            icon: <PointOfSaleIcon />,
          },
          { fulName: "Остатки", urlName: "/remains/", icon: <WarehouseIcon /> },
          {
            fulName: "Отчеты",
            urlName: "/сonstructor/",
            icon: <AppRegistrationIcon />,
          },
          { fulName: "Скоро", urlName: "/soon/", icon: <EmojiObjectsIcon /> },
          {
            fulName: "Логистика",
            urlName: "/logisticService/routingByRouteList/routeSheetsList/",
            icon: <RouteIcon />,
          },
        ] : [
          {
            fulName: "Логистика",
            urlName: "/logisticService/routingByRouteList/routeSheetsList/",
            icon: <RouteIcon />,
          },
        ];

  // Функция для проверки наличия определенной подстроки в строке navigate.
  const checkedLogisticService = () => {
    const substr = "/routersOnTheMap";
    const substr1 = "/logisticService";

    if (navigate.includes(substr) || navigate.includes(substr1)) {
      return false;
    } else {
      return true;
    }
  };


  const checkRoutingByOrderListPage = () => {
    const substr = "/logisticService/createRouteSheets";

    if (navigate.includes(substr)) {
      return false;
    } else {
      return true;
    }
  };

  

  return (
    <>
      {!checkRoutingByOrderListPage() ? (
        <>
          <LogisticServiceSideBar subscriptions={state.subscriptions} setAccessLogistics={setAccessLogistics} accessLogistics={accessLogistics} child={props.child} url={url} />
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          {user && (
            <>
              {"accessLevel" in user && user.accessLevel !== "2" ? (
                <>
                  <AppBar
                    position="fixed"
                    sx={{
                      width: `calc(100% - ${drawerWidth}px)`,
                      ml: `${drawerWidth}px`,
                      backgroundColor: "#ffffff",
                      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                      boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
                      color: "#000",
                    }}
                  >
                    {navigate !== "/admin/" &&
                      navigate !== "/soon/" &&
                      navigate !== "/notSubscription" &&
                      navigate !== "/profile/" &&
                      navigate !== "/reports/" &&
                      navigate !== "/сonstructor/" &&
                      checkedLogisticService() && (
                        <Toolbar>
                          <DatePickerMui />
                        </Toolbar>
                      )}
                  </AppBar>

                  <Drawer
                    variant="permanent"
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,

                      [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        paddingRight: "10px",
                        borderRight: 0,
                        overflowY: showSubRightMenu ? "hidden" : "auto",
                      },
                    }}
                  >
                    <Toolbar>
                      <List>
                        <ListItem disablePadding className={"logo"}>
                          <img className={"logoSvg"} src={logoGeneral} />
                        </ListItem>
                      </List>
                    </Toolbar>
                    <Divider />
                    <List id={"test"} className={"MuiButtonBase-root"}>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        {url.map((nav) => {
                          if (nav.fulName === "Логистика") {
                            return (
                              <ContentMenuLogisticService
                              accessLogistics = {accessLogistics}
                                key={uuidv4()}
                                nav={nav}
                                navigate={navigate}
                                setNavigate={setNavigate}
                              />
                            );
                          } else {
                            return (
                              <ListItemButton
                                key={nav.urlName}
                                onClick={() => {
                                  savpMenuLogisticOption.changeLogisticMenuOption(
                                    false
                                  );
                                  savpMenuLogisticOption.changeSubRightMenu(
                                    false
                                  );
                                  savpMenuLogisticOption.changeDefaultStateRightMenu();
                                }}
                                component={Link}
                                to={nav.urlName}
                                className={
                                  navigate === nav.urlName
                                    ? "navigateButton"
                                    : null
                                }
                              >
                                <ListItemIcon
                                  sx={{
                                    color:
                                      navigate === nav.urlName
                                        ? "#ffffff"
                                        : "rgba(58, 53, 65, 0.87)",
                                  }}
                                >
                                  {nav.icon}
                                </ListItemIcon>
                                <ListItemText primary={nav.fulName} />
                              </ListItemButton>
                            );
                          }
                        })}
                      </ListItem>
                    </List>
                    <Divider />
                    <List>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton onClick={() => logoutUser()}>
                          <ListItemIcon>
                            <OutputIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Выйти"} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                    {/* </Box> */}
                  </Drawer>
                </>
              ) : null}
            </>
          )}
          <Box
            className={
              navigate !== "/login" &&
              navigate !== "/admin" &&
              navigate !== "/reports/" &&
              navigate !== "/сonstructor/" &&
              navigate !== "/notSubscription" &&
              navigate !== "/profile/" &&
              navigate !== "/soon/" &&
              checkedLogisticService()
                ? "contentMain"
                : "contentMainDefault"
            }
            component="main"
            id={"portal_menu"}
          >
            {props.child}
          </Box>
        </Box>
      )}
    </>
  );
});

export default memo(ContentMenu);
