import React from 'react';
import Icon from '../../imges/Logo.png'; // Импорт SVG файла
import './RotatingLoadIcon.css'; // Import CSS file


const RotatingLoadIcon = () => {
  return (
    <div className='contentLogo'>
    <img width='140' height="140" className='rotating-icon' src={Icon} alt="Иконка" />
    </div>
  );
};

export default RotatingLoadIcon;
