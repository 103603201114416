import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './CreateTemplateItem.module.css'
import {Navigate, useNavigate} from "react-router-dom";
import Popover_SelectMobileStatusDelivered
    from "../../UI/Popover_SelectMobileStatusDelivered/Popover_SelectMobileStatusDelivered";
import {observer} from "mobx-react-lite";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import debounce from "lodash/debounce";
import GlobalContext from "../../../../context/GlobalContext";

const CreateTemplateItem = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {selected_status, mobileStatusList} = deliveredStatusList.popoverSelectStatus
    const navigate = useNavigate();

    const [tempName, setTempName] = useState("")

    useEffect(() => {

        return () => {
            deliveredStatusList.changeDefaultValueCreateTemp()
        }
    }, []);

    const getSelectedValueDestination = (newValue) => {
        deliveredStatusList.changeSelectMobileStatus(newValue)
    };

    const updateTemplate = useCallback(
        debounce((value) => {
            deliveredStatusList.changeTemplateText(value)
        }, 500), // Задержка в миллисекундах
        []
    );

    const changeTemplateName = (newValue) => {
        setTempName(newValue)
        updateTemplate(newValue)
    }

    const createNewTemplate = async () => {
        try {
            await deliveredStatusList.createTemplate( navigate, authTokens, setUser, setAuthTokens, logoutUser);
          
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={style.createTemplate}>
            <div className={style.createTemplateHeader}>
                <div className={style.goBackToList}
                     onClick={() => navigate(-1)}>Назад
                </div>
                Создание шаблона ответа
            </div>

            <div className={style.createTemplateForm}>
                <div className={style.selectTypeStatus}>
                    <div>Статус в приложении:</div>
                    <Popover_SelectMobileStatusDelivered selectOptions={selected_status}
                                                         options={mobileStatusList}
                                                         onChange={(newValue) => getSelectedValueDestination(newValue)}/>
                </div>

                <div className={style.nameTemplateInput}>
                    <div>Наименование шаблона в приложении:</div>
                    <textarea
                        // type="text"
                        placeholder="Наименование в приложении"
                        value={tempName}
                        maxLength={100}
                        rows={5}

                        onChange={
                            (e) => {
                                changeTemplateName(e.target.value)
                            }
                        }
                    ></textarea>
                </div>
            </div>

            <div className={style.actionTemplateBtns}>
                <button
                    className={style.createTemplateBtn}
                    onClick={() => createNewTemplate()}
                >Создать
                </button>
                <button
                    className={style.cancelCreateTemplateBtn}
                    onClick={() =>  navigate(-1)}
                >Отменить</button>
            </div>
        </div>
    );
});

export default memo(CreateTemplateItem);
