import React, {memo, useEffect} from 'react';
import style from './DriverAndForwarderTableHeader.module.css'
import {Link, useNavigate, useParams} from "react-router-dom";
import pageDriverAndForwarderStore
    from "../../../../store/transportationLogisticsService/pageDriverAndForwarderStore/pageDriverAndForwarderStore";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import {observer} from "mobx-react-lite";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';


const DriverAndForwarderTableHeader = observer(() => {
    const {
        popupState: {popupShow},
        documentListCopy
    } = pageDriverAndForwarderStore
    const navigate = useNavigate();
    const {typeList} = useParams();

    const changeNewPath = (newTypeStatus) => {
        navigate(`/logisticService/driverAndForwarder/list/${newTypeStatus}`, {relative: 'path'});
    }

    function isChecked(array) {
        return array.some(temp => temp.checked === true);
    }

    const changePopupShow = (value, typePopup) => {
        if (isChecked(documentListCopy)){
            pageDriverAndForwarderStore.changePopupShow(window.pageYOffset, value, typePopup);
        } else {
            toastMassage(toast.error, "Выберите хотя бы одину карточку!", 10000, "bottom-right")
        }
    }


    return (
        <div className={style.headerPage}>
            <div className={style.selectStatusHeader}>Карточки водителей и экспедиторов</div>

            <div className={style.selectedStatusBtns}>

                <button className={(String(typeList) === "drivers") ? `${style.statusBtn} ${style.active}` : style.statusBtn}
                        onClick={() => changeNewPath("drivers")}
                >Водители
                </button>
                <button
                    className={(String(typeList) === "forwarders") ? `${style.statusBtn} ${style.active}` : style.statusBtn}
                    onClick={() => changeNewPath("forwarders")}
                >Экспедиторы
                </button>
                <button
                    className={(String(typeList) === "all") ? `${style.statusBtn} ${style.active}` : style.statusBtn}
                    onClick={() => changeNewPath("all")}
                >Все
                </button>

            </div>

            <div className={style.actionTemplateBtns}>
                <Link to={"/logisticService/driverAndForwarder/create/"}>
                    <button className={style.createTemplateBtn}>Создать карточку</button>
                </Link>
                <button className={style.deleteSelectTemplateBtn}
                onClick={() => changePopupShow(!popupShow, "askDeleteSelectedDocs")}
                >Удалить выбранные карточки</button>
            </div>
        </div>
    );
});

export default memo(DriverAndForwarderTableHeader);