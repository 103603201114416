import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import * as React from "react";
import {
    generateLocalColorFromText
} from "../../../helpers/helpers";
import ManufacturersCurrentRemains from "../ManufacturersCurrentRemains/ManufacturersCurrentRemains";
// import DistributorCurrentRemains from "../DistributorCurrentRemains/DistributorCurrentRemains";

export default function ManufacturersCurrentRemainsCard(props) {
    const [activeString, setActiveString] = React.useState(null);

    const manufacturer = props.manufacturer;

    const [currentPage, setCurrentPage] = React.useState(1); // Current page number
    const itemsPerPage = 10; // Number of items to display per page
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <Card
            sx={{
                boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
                height: "84vh",
            }}
        >
            {activeString ? (
                <ManufacturersCurrentRemains
                    setActiveString={setActiveString}
                    id={activeString.id}
                    inn={activeString.inn}
                />
            ) : (
                <>
                    <CardHeader
                        avatar={
                            <div>
                                <Button disabled>Реализация</Button>/
                                <Button disabled={activeString}>Дистрибьюторы</Button>
                            </div>
                        }
                    />
                    <Divider/>
                    <CardContent sx={{padding: 0}}>
                        <Table stickyHeader aria-label="table in dashboard">
                            <TableHead>
                                <TableRow sx={{fontWeight: 800}}>
                                    <TableCell
                                        sx={{
                                            fontWeight: 700,
                                            backgroundColor: "rgb(250, 250, 250)",
                                        }}
                                        colSpan={2}
                                    >
                                        Дистрибьютор
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: 700,
                                            backgroundColor: "rgb(250, 250, 250)",
                                        }}
                                        align="left"
                                    >
                                        Почта
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: 700,
                                            backgroundColor: "rgb(250, 250, 250)",
                                        }}
                                        align="left"
                                    >
                                        ИНН
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            fontWeight: 700,
                                            backgroundColor: "rgb(250, 250, 250)",
                                        }}
                                        align="left"
                                    >
                                        Адрес
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {manufacturer
                                    .slice(
                                        (currentPage - 1) * itemsPerPage,
                                        currentPage * itemsPerPage
                                    )
                                    .map((row) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow
                                                onClick={() => setActiveString(row)}
                                                className="hoverable-row"
                                            >
                                                <TableCell sx={{paddingRight: 0, width: "1vw"}}>
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: generateLocalColorFromText(row.name),
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        {" "}
                                                        {row.name[0]}
                                                    </Avatar>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    sx={{paddingLeft: 0}}
                                                    scope="row"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.mail == "" ? "Отсутствует" : row.mail}
                                                </TableCell>
                                                <TableCell align="left">{row.inn}</TableCell>
                                                <TableCell sx={{width: "30%"}} align="left">{row.address}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vw",
                            }}
                        >
                            <Pagination
                                sx={{marginTop: "1vw"}}
                                color="primary"
                                shape="rounded"
                                count={Math.ceil(manufacturer.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    </CardContent>
                </>
            )}
        </Card>
    );
}
