import React, {useEffect, useState} from 'react';
import style from './ModalRouteSheetTableItem.module.css'
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";


const ModalRouteSheetTableItem = ({docData}) => {
    const {typeCreatingRouteSheet} = createRouteSheetsStore
    const axiosInstance = AxiosConfig_LogisticService();
    const [ statusTonnage, setStatusTonnage] = useState(undefined);

    useEffect(() => {
        setTonnageStatus();
    }, []);

    const setTonnageStatus = () => {
        const vehicleTonnageInKg = docData?.vehicle_data?.tonnage * 1000;
        const totalTonnageInRoute = getTotalTonnage();

        if (vehicleTonnageInKg && !isNaN(vehicleTonnageInKg) && totalTonnageInRoute && !isNaN(totalTonnageInRoute)) {
            const midpoint = vehicleTonnageInKg / 2;

            if (totalTonnageInRoute <= midpoint) {
                setStatusTonnage("totalTonnageSuccess");
            } else if (totalTonnageInRoute > midpoint && totalTonnageInRoute < vehicleTonnageInKg) {
                setStatusTonnage("totalTonnageOverHalf");
            } else {
                setStatusTonnage("totalTonnagePreload");
            }
        } else {
            setStatusTonnage("unknown");
        }
    }

    const addOrderToTheRouteSheet = async () => {
        try {
            console.log(typeCreatingRouteSheet)
            switch (typeCreatingRouteSheet) {
                case "deliveryArea":
                    await createRouteSheetsStore.addSelectedOrderToTheRouteSheet(axiosInstance, docData?.id);
                    break;
                default:
                    await createRouteSheetsStore.addSelectedOrderToTheRouteSheet(axiosInstance, docData?.id);
            }


            createRouteSheetsStore.closePopup();
        } catch (e) {

        }
    }

    const getTotalTonnage = () => {
        let totalTonnage = 0;

        if (docData?.order_list){
            for (const order of docData?.order_list){
                totalTonnage += order?.vehicle_tonnage
            }

            return Number(totalTonnage).toFixed(2)
        } else {
            return totalTonnage
        }
    }

    const getFreeLoad = () => {
        const vehicleTonnageInKg = docData?.vehicle_data?.tonnage * 1000;
        const totalTonnageInRoute = getTotalTonnage();

        return Number(vehicleTonnageInKg - totalTonnageInRoute).toFixed(2);
    }

    return (
        <div className={style.tableItem}
             onClick={() => addOrderToTheRouteSheet()}
        >
            <div>{docData?.vehicle_data?.tonnage} т.</div>
            <div>{docData?.vehicle_data?.appellation}</div>
            <div
                className={`${style[statusTonnage]}`}
            >{getTotalTonnage()} кг.</div>
            <div>{docData?.order_list.length}</div>
            <div>{docData?.drivers_info[0]?.driver_name}</div>
            <div
                className={`${style[statusTonnage]}`}
            >
                {getFreeLoad()} кг.</div>
        </div>
    );
};

export default ModalRouteSheetTableItem;