import React, {memo, useEffect, useMemo, useState} from 'react';
import style from './TablePaginationRouteSheets.module.css';
import PopoverSelector from "../PopoverSelector/PopoverSelector";
import {ReactComponent as ArrowBack} from '../../../imges/ArrowBack.svg';
import {ReactComponent as ArrowForward} from '../../../imges/ArrowForward.svg';

const TablePaginationRouteSheets = ({count,onPageChange,onRowsPerPageChange,page, paginationText}) => {
    const options = [10,25,50,100]; // То сколько элементом отображать
    const [currentPage, setCurrentPage] = useState(page); // Текущая страница
    const [itemsPerPage, setItemsPerPage] = useState(10) // Сколько отображать элементов на странице
    const [totalItems, setTotalItems] = useState(count) // Общее количество элементов
    const [startIndex, setStartIndex] = useState((currentPage - 1) * itemsPerPage + 1) // Номер первого элемента
    const [endIndex, setEndIndex] = useState(Math.min(currentPage * itemsPerPage, totalItems)) // Номер последнего элемента

    // Записываем новое значение того, сколько элементов отображать на странице
    const getSelectedValueDestination = (newValue) => {
        setItemsPerPage(newValue)
        setCurrentPage(1)
        onRowsPerPageChange(newValue)
        onPageChange(1);
    };

    useEffect(() => {
        setTotalItems(count);
        setStartIndex((currentPage - 1) * itemsPerPage + 1);
        setEndIndex(Math.min(currentPage * itemsPerPage, totalItems));
    }, []);

    useEffect(() => {
        setCurrentPage(page)
        setTotalItems(count);
        setStartIndex((page - 1) * itemsPerPage + 1);
        setEndIndex(Math.min(page * itemsPerPage, count));
    }, [page, count])

    // Переход на следующую страницу
    const goToNextPage = () => {
        if ((currentPage * itemsPerPage) < totalItems) {
            setCurrentPage(currentPage + 1);
            onPageChange(currentPage + 1);
        }

    };

    // Переход на предыдущую страницу
    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            onPageChange(currentPage - 1);
        }
    };

    return (
        <div className={style.tablePaginationContent}>
            <div className={style.selectNumItemsPage}>
                <div>{paginationText}</div>
                <PopoverSelector options={options} onChange={getSelectedValueDestination}/>
            </div>
            <div className={style.paginationCounter}>
                <div>{startIndex}-{endIndex} из {totalItems}</div>
                <div className={style.paginationNavigationPage}>
                    <div onClick={goToPrevPage}><ArrowBack/></div>
                    <div onClick={goToNextPage}><ArrowForward/></div>
                </div>
            </div>
        </div>

    );
};

export default memo(TablePaginationRouteSheets);