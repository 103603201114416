import React, {memo} from 'react';
import style from './DeliveryAreaCreateSelectAreaItem.module.css'
import {observer} from 'mobx-react-lite';
import createDeliveryZone from "../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";


const DeliveryAreaCreateSelectAreaItem = observer(({
                                       area,
                                       zoomAndShowClickZona,
                                       showSelectZoneByMap,
                                   }) => {
    const navigate = useNavigate();

    return (
        <>
            <div
                className={style.settingDeliveryZoneNavZone}>
                <div>
                    <input type="checkbox"
                           className={style.selectDeliveryZone}
                           id={`SelectZona_${uuidv4()}`}
                           name="selectedZona"
                           value={area?.checked}
                           checked={area?.checked}
                           onChange={() => {}}
                    />
                    <label
                        htmlFor={`SelectZona_${uuidv4()}`}
                        onClick={()=> createDeliveryZone.selectCreatedDeliveryArea(!area?.checked, area?.id)}
                    ></label>
                </div>
                <div
                    onClick={()=> {
                        zoomAndShowClickZona(area?.id,area?.coordinates)
                    }}
                    className={style.settingDeliveryZoneNavZoneName}>{area?.name_area}</div>
            </div>
        </>
    );
});

export default memo(DeliveryAreaCreateSelectAreaItem);