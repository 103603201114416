import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Button, CardHeader, Divider, Paper} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
// import DistributorNomenclatureTable from "../DistributorNomenclatureTable/DistributorNomenclatureTable";
// import DistributorTableRetail from "../DistributorRetailChainsTable/DistributorRetailChainsTable";
// import DistributorTableTradingPoints from "../DistributorTableTradingPoints/DistributorTableTradingPoints";
import {useAxiosAPI} from "../../../apiRequest/apiRequest";
import GlobalContext from "../../../context/GlobalContext";
import imgNoneData from "../../../imges/noneData.svg";
import RotatingLoadIcon from "../../RotatingLoadIcon/RotatingLoadIcon";
import {Alert, Box, List, TableContainer} from "@mui/material";
import "./ManufacturersCardСounterparty.css";
import DistributorTableRetail
    from "../../ManufacturerComponents/DistributorRetailChainsTable/DistributorRetailChainsTable";
import ManufacturerRealizationTableRetail
    from "../ManufacturerRetailChainsTable/ManufacturerRetailChainsTable";
import ManufacturerTableTradingPoints from "../ManufacturerTableTradingPoints/ManufacturerTableTradingPoints";
import ManufacturerNomenclatureTable from "../ManufacturerNomenclatureTable/ManufacturerNomenclatureTable";

function getCheckedIds(data) {
  return data.flatMap((item) =>
      (item.retailOutletsList || []).filter((outlet) => outlet.checked).map((outlet) => outlet.id)
  );
}


const ManufacturersCardCounterparty = (props) => {
  const {apiDistributorCounteragentsAndRetailOutlets, apiDistributorGetRetailOutletsAndDistrNoms} = useAxiosAPI();  const {state, distributorStartDate, distributorFinishDate} = useContext(GlobalContext);
  const [load, setLoad] = useState(false);
  const [items, setItems] = useState([]);
  const [nom, setNom] = useState([]);
  const [valueId, setValueId] = useState(null);
  const [listOpen, setListOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectSeeAll, setSelectSeeAll] = useState(true);

  const idArray = useMemo(() => {
    return items
        .filter((item) => item.retailOutletsList.some((outlet) => outlet.checked))
        .flatMap((item) => item.retailOutletsList.map((outlet) => outlet.id));
  }, [items]);

  const handleOpen = useCallback(() => {
    setLoad(true);
      apiDistributorGetRetailOutletsAndDistrNoms(props.inn, idArray, false)
        .then((resolve) => {
          setNom(resolve.data);
          setListOpen(!listOpen);
          setLoad(false);
        })
        .catch(() => {
          console.log("Ошибка");
          setLoad(false);
        });
  }, [props.id, idArray, listOpen, apiDistributorGetRetailOutletsAndDistrNoms]);

  useEffect(() => {
    if (state.distributorCounteragentsAndRetailOutlets[props.id]) {
      setItems(state.distributorCounteragentsAndRetailOutlets[props.id]);
      setLoad(false);
    } else {
      setItems([]);
      setLoad(false);
    }
  }, [state.distributorCounteragentsAndRetailOutlets, props.id]);

  useEffect(() => {
    setLoad(true);
      apiDistributorCounteragentsAndRetailOutlets(props.id, props.inn);
  }, [distributorStartDate, distributorFinishDate, props.id]);

  const handleSelectAll = useCallback(() => {
    const allSelected = items.every((section) =>
        section.retailOutletsList.every((retail) => retail.checked)
    );

    setItems((prevItems) =>
        prevItems.map((section) => ({
          ...section,
          retailOutletsList: section.retailOutletsList.map((retail) => ({
            ...retail,
            checked: !allSelected,
          })),
        }))
    );

    setValueId(null);
    setSelectAll(!allSelected);
  }, [items]);

  const handleSelectLocal = useCallback(
      (checkedItem) => {
        setItems((prevItems) =>
            prevItems.map((doc) => ({
              ...doc,
              retailOutletsList: doc.retailOutletsList.map((retail) => ({
                ...retail,
                checked: checkedItem.some((retailObj) => retail.id === retailObj.id)
                    ? !retail.checked
                    : retail.checked,
              })),
            }))
        );
      },
      []
  );

  const handleClick = useCallback((sectionId) => {
    setListOpen(false);
    setValueId(sectionId);
  }, []);
  return (
      <>
        <CardHeader
            avatar={
              <div>
                <Button
                    onClick={() => listOpen ? setListOpen(false) : props.setActiveString(null)}
                    variant="contained"
                >
                  <WestIcon/>
                </Button>
                <Button disabled sx={{marginLeft: "10px"}}>Остатки</Button>
                /
                <Button disabled={props.setActiveString} onClick={() => props.setActiveString(null)}>
                  Дистрибьюторы
                </Button>
                /<Button disabled={listOpen}>Торговые точки</Button>/
                {listOpen ? <Button>Номенклатура</Button> : null}
              </div>
            }
        />
        <Divider/>
        {!load ? (
            <Box>
              {items.length > 0 ? (
                  <Paper sx={{display: "flex", padding: 0}}>
                    {!listOpen ? (
                        <>
                          <ManufacturerRealizationTableRetail
                              selectAll={selectAll}
                              handleSelectAll={handleSelectAll}
                              items={items}
                              handleClick={handleClick}
                              handleSelectLocal={handleSelectLocal}
                          />
                          <Divider orientation="vertical" variant="middle" flexItem/>
                          <ManufacturerTableTradingPoints
                              setItems={setItems}
                              items={items}
                              handleOpen={handleOpen}
                              getCheckedIds={getCheckedIds}
                              selectSeeAll={selectSeeAll}
                              setSelectSeeAll={setSelectSeeAll}
                              valueId={valueId}
                          />
                        </>
                    ) : (
                        <>
                         <ManufacturerNomenclatureTable
                            docs={nom}
                            items={getCheckedIds(items)}
                            distr={props.id}
                         />
                        </>
                    )}
                  </Paper>
              ) : (
                  <Box sx={{width: "100%", height: "10vh"}}>
                    <Alert severity="info">Информация по заданному периоду отсутствует !</Alert>
                    <img className="img-none-data" src={imgNoneData} alt="No data"/>
                  </Box>
              )}
            </Box>
        ) : (
            <List sx={{overflow: "hidden", padding: "10px"}}>
              <TableContainer
                  component={"div"}
                  style={{
                    height: "73vh",
                    borderRadius: 0,
                    boxShadow: 0,
                    backgroundColor: "#ffffff",
                  }}
              >
                <RotatingLoadIcon/>
              </TableContainer>
            </List>
        )}
      </>
  );
};

export default ManufacturersCardCounterparty;
