import React, {memo} from 'react';
import ModalMaster from "../ModalMaster/ModalMaster";
import style from "./OrderTransferPopup.module.css";
import createRouteSheetsStore
    from "../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import RouteSheetList from "./RouteSheetList";

const OrderTransferPopup = ({isOpen, onClose, scrollPosition, onTransfer}) => {
    return (
        <ModalMaster
            isOpen={isOpen}
            onClose={onClose}
            scrollPosition={scrollPosition}
        >
            <div>
                <div className={style.popupHeader}>
                    Выбор маршрутного листа
                    <div
                        className={style.btnClosePopup}
                        onClick={() => {
                            createRouteSheetsStore.closePopup();
                        }}
                    >
                        <DeleteIcon/>
                    </div>
                </div>
                <div className={style.tableHeader}>
                    <div>Грузоподъёмность (т)</div>
                    <div>Транспорт (Наименование)</div>
                    <div>Вес заказов (кг)</div>
                    <div>Заказов</div>
                    <div>Водитель</div>
                    <div>Свободный вес (кг)</div>
                    <div>Номер маршрутного листа</div>
                </div>
                <RouteSheetList onTransfer={(orderId) => onTransfer(orderId)}/>
            </div>
        </ModalMaster>
    );
};

export default memo(OrderTransferPopup);