import React, {useMemo, useState} from 'react';
import style from './Popover_SelectMobileStatusDelivered.module.css'
import "./Popover_SelectMobileStatusDelivered.css"
import {ReactComponent as ArrowUp} from '../../../../imges/ArrowUp.svg';
import {ReactComponent as ArrowDown} from '../../../../imges/ArrowDown.svg';

const Popover_SelectMobileStatusDelivered = ({ options, selectOptions, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useMemo(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !(event.target).closest('.Popover_SelectMobileStatusDelivered_popoverSelector')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);


    const handleOptionClick = (option) => {
        setIsOpen(false);
        onChange(option);
    };

    return (
        <div className={`Popover_SelectMobileStatusDelivered_customSelect Popover_SelectMobileStatusDelivered_popoverSelector`}>
            <div
                className={(!isOpen)? "Popover_SelectMobileStatusDelivered_selectSelected" : "Popover_SelectMobileStatusDelivered_selectSelected Popover_SelectMobileStatusDelivered_active"}
                 onClick={() => setIsOpen(!isOpen)}>
                {selectOptions.status_text}
                <span>
                    {(isOpen)?<ArrowUp/>:<ArrowDown/>}
                </span>
            </div>
            {isOpen && (
                <div className={"Popover_SelectMobileStatusDelivered_selectItems"}>
                    {options.map((option, index) => (
                        <div className={
                            (option.status_text === selectOptions.status_text) ? `Popover_SelectMobileStatusDelivered_ItemsList Popover_SelectMobileStatusDelivered_ItemsList_Active` : `Popover_SelectMobileStatusDelivered_ItemsList`
                        } key={index} onClick={() => handleOptionClick(option)}>
                            {option.status_text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Popover_SelectMobileStatusDelivered;