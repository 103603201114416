import React from 'react';
import style from './MapMarketPoint.module.css'
import {ReactComponent as MarketIconMap} from '../../../imges/MarketIconMap.svg';

const MapMarketPoint = ({idDoc}) => {
    return (
        <div className={style.mapMarker}>
            <MarketIconMap/>
        </div>
    );
};

export default MapMarketPoint;