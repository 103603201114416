import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import * as React from 'react';
import "./ManufacturerCardInfo.css";

const style = {
    width: '100%',
    maxWidth: "100%",
    bgcolor: 'background.paper',
};



export default function ManufacturerCardInfo(props) {
    return (
        <List sx={style} aria-label="mailbox folders">
            <ListItem className={"infoText"}>
                <span className={"headerList"}>Наименование: </span>  {props.info.manufacturer.name}
            </ListItem>
            <Divider />
            <ListItem className={"infoText"} divider>
                <span className={"headerList"}>ИНН:</span> {props.info.manufacturer.inn}
            </ListItem>
            <ListItem className={"infoText"}>
                <span className={"headerList"}>Регион:</span> {props.info.manufacturer.region}
            </ListItem>
            <Divider light />
            <ListItem className={"infoText"}>
                <span className={"headerList"}>КПП:</span> {props.info.manufacturer.kpp}
            </ListItem>
            <Divider light />
            <ListItem className={"infoText"}>
                <span className={"headerList"}>Адрес:</span> {props.info.manufacturer.address}
            </ListItem>
            <Divider light />
            <ListItem className={"infoText"}>
                <span className={"headerList"}>Почта:</span> {props.info.manufacturer.mail}
            </ListItem>
            <Divider light />
        </List>

    );
}
