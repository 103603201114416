import React, {useEffect, useState} from 'react';
import ModalMaster from "../ModalMaster/ModalMaster";
import style from "./OrderListFilterOptionPopup.module.css";
import createRouteSheetsStore
    from "../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import {ReactComponent as SettingIcon_v2} from '../../../imges/SettingIcon_v2.svg';
import FilterChangeOptions from "./FilterChangeOptions";
import ChangeDeliveryArea from "./ChangeDeliveryArea";
import {observer} from "mobx-react-lite";

const OrderListFilterOptionPopup = observer(({isOpen, onClose, scrollPosition, onTransfer}) => {
    const {tableOrderListFilters:{deliveryArea}} = createRouteSheetsStore
    const [changeDeliveryArea, setChangeDeliveryArea] = useState(false);
    const [modalViewContent, setModalViewContent] = useState(null);
    const [filterOption, setFilterOption] = useState(deliveryArea);

    useEffect(() => {
        setModalViewContent(<FilterChangeOptions filterOption={deliveryArea} setChangeDeliveryArea={(value) => setChangeDeliveryArea(value)}/>)
    }, [isOpen]);

    useEffect(() => {

        if (changeDeliveryArea){
            setModalViewContent(<ChangeDeliveryArea setChangeDeliveryArea={(value) => setChangeDeliveryArea(value)} setFilterOption={areaData => setFilterOption(areaData)}/>)
        } else {
            setModalViewContent(<FilterChangeOptions filterOption={filterOption} setChangeDeliveryArea={(value) => setChangeDeliveryArea(value)}/>)
        }

    }, [changeDeliveryArea]);


    return (
        <ModalMaster
            isOpen={isOpen}
            onClose={onClose}
            scrollPosition={scrollPosition}
        >
            {modalViewContent}
        </ModalMaster>
    );
});

export default OrderListFilterOptionPopup;