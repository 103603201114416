import React, {useContext, useEffect, useRef, useState} from 'react';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {addDays, format} from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import "./DatePickerMui.css"
import {Box, Button} from "@mui/material";
import {dateConversion, dateConvertSelection} from "../../../helpers/helpers";
import GlobalContext from "../../../context/GlobalContext";

const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timezone: 'UTC',
    // hour: 'numeric',
    // minute: 'numeric',
};
const DatePickerMui = () => {
    const {
        distributorStartDate,
        setDistributorStartDate,
        distributorFinishDate,
        setDistributorFinishDate,
    } = useContext(GlobalContext);

    const [state, setState] = useState([
        {
            startDate: distributorStartDate,
            endDate: distributorFinishDate,
            key: 'selection'
        }
    ]);

    const [open, setOpen] = useState(false)

   const clearDate = () => {
       setState([{
           startDate: distributorStartDate,
           endDate: distributorFinishDate,
           key: 'selection'
       },

       ])
        setOpen(false)
   }

    const saveDate = () => {
        setDistributorStartDate(state[0].startDate)
        setDistributorFinishDate(state[0].endDate)
        setOpen(false)
    }

    const calendarRef = useRef(null);

    const handleToggleCalendar = () => {
        setOpen(!open);
    };

    const handleClickOutsideCalendar = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideCalendar);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideCalendar);
        };
    }, []);
    return (
        <Box>
            {!open ?
                <div className="rdrDateDisplay" style={{width: 300, color: "rgb(61, 145, 255)}}"}}>
                        <span className="rdrDateInput rdrDateDisplayItem rdrDateDisplayItemActive">
                        <input onClick={handleToggleCalendar}
                                value={`${new Date((state[0].startDate)).toLocaleString("ru", options)}` + " / " + `${state[0].endDate ? new Date((state[0].endDate)).toLocaleString("ru", options) : "Конец"}`}/>
                        </span>
                </div>
                :
                <Box className="dataPickerBox" ref={calendarRef}>


                <DateRange
                    showMonthAndYearPickers={true}
                    editableDateInputs={true}
                    onChange={item => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    maxDate={addDays(new Date(), 0)}
                    ranges={state}
                    locale={ruLocale}
                    startDatePlaceholder="Начальная дата"
                    endDatePlaceholder="Конечная дата"
                    dateDisplayFormat="dd.MM.yyyy" // изменение формата вывода даты

                />
                    <Box className="buttonPicker">
                    <Button onClick={() => clearDate()}>Отмена</Button>  <Button onClick={() => saveDate()}>Применить</Button>
                    </Box>
                </Box>
            }
        </Box>
    );
};

export default DatePickerMui;
