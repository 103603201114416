import mmrgl from 'mmr-gl';
import ReactDOM from "react-dom/client";
import React from "react";
import style from "../../page/RouteDisplayMapPage/RouteDisplayMapPage.module.css";
import createRouteSheetsStore
    from "../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import DeliveryAreaMapPopup
    from "../../components/transportationLogisticsService/CreateRouteSheetsPageComponents/DeliveryAreaMapPopup/DeliveryAreaMapPopup";
import OrderMapPopup
    from "../../components/transportationLogisticsService/CreateRouteSheetsPageComponents/OrderMapPopup/OrderMapPopup";
import * as turf from "@turf/turf";
import {GlobalProvider} from "../../context/GlobalContext";

class CreateRouteSheetsUtils {
    filterPropertyList = {
        areaList: {
            state: "modalState",
            list: 'areaList',
            copy: 'areaListCopy',
            fields: ['name_area']
        },
        modalInitPointList: {
            state: "modalState",
            list: 'modalInitPointList',
            copy: 'modalInitPointListCopy',
            fields: ['initial_route_point_name', 'address']
        },
        modalDriverList: {
            state: "modalState",
            list: 'modalDriverList',
            copy: 'modalDriverListCopy',
            fields: ['driver_name']
        },
        modalVehicleList: {
            state: "modalState",
            list: 'modalVehicleList',
            copy: 'modalVehicleListCopy',
            fields: ['appellation', 'vehicle_brand', 'tonnage', 'license_plate']
        },
        orderList: {
            state: "tableOrderListState",
            list: 'orderList',
            copy: 'orderListCopy',
            fields: ['order_client', 'order_adress_delivery', 'vehicle_tonnage']
        },
        routeSheetsList: {
            state: "tableRouteSheetsState",
            list: 'routeSheetsList',
            copy: 'routeSheetsListCopy',
            fields: ['vehicle_data.tonnage', 'vehicle_data.appellation', 'drivers_info[0].driver_name']
        }
    };

    getValueByPath(item, path) {
        const props = path.split('.');
        let value = item;

        for (const prop of props) {
            const match = prop.match(/(\w+)\[(\d+)\]/);

            if (match) {
                const propName = match[1];
                const index = parseInt(match[2]);
                value = value && value[propName] && value[propName][index];
            } else {
                value = value && value[prop];
            }
        }

        return value;
    }

    async createRouteSheet(deliveryData, orderListData) {
        const {orderListPanelState: {uploadDate}} = createRouteSheetsStore

        const {
            delivery_date,
            delivery_time,
            initial_route_point,
            drivers_info,
            vehicle_number,
            vehicle_data
        } = deliveryData;

        const dateOnly = delivery_date.split('T')[0];
        const deliveryDate = `${dateOnly}T${delivery_time}`;

        let routeSheetData = {
            document_date: uploadDate.slice(0, 10),
            delivery_date: deliveryDate,
            vehicle_number: vehicle_number,
            vehicle_data: vehicle_data,
            initial_route_point: initial_route_point,
            order_list: [],
            drivers_info: drivers_info
        };

        for (const orderData of orderListData) {
            routeSheetData.order_list.push({
                id: orderData.id,
                order_id: orderData.order_id,
                coordinate: orderData.coordinate,
                unload_time: null,
                order_client: orderData.order_client,
                order_number: orderData.order_number,
                vehicle_tonnage: orderData.vehicle_tonnage,
                volume_goods: orderData.volume_goods,
                coordinates_given: orderData.coordinates_given,
                order_client_number: orderData.order_client_number,
                order_adress_delivery: orderData.order_adress_delivery
            });
        }

        return routeSheetData;
    }

    updateOrderList(orderList, dataId, value) {
        return orderList.map(order => {
            if (order.id === dataId) {
                order.checked = value;
            }

            return order;
        });
    }

    updateRouteList(routeList, dataId, value) {
        return routeList.map(route => {
            if (route.id === dataId) {
                route.checked = value;
            }
            return route
        });
    }

    updateOrderWithMapData(orderList, dataId, value, selectOrderListData, map) {
        return orderList.slice().map(order => {
            if (order.id === dataId && order?.coordinates_given) {
                order.checked = value;

                const featureUID = String(order.id).match(/\d+/g).join('').slice(0, 15);
                const index = selectOrderListData.findIndex(sOrder => sOrder.id === order.id);

                if (index > -1) {
                    map.setFeatureState({source: "points", id: featureUID}, {
                        colorArea: `#FF0000`,
                        color: false
                    });
                    selectOrderListData.splice(index, 1);
                } else {
                    selectOrderListData.push(order);
                    map.setFeatureState({source: "points", id: featureUID}, {
                        colorArea: `#006fcbe6`,
                        color: true
                    });
                }
            }
            return order
        });
    }

    createDeliveryAreaGeoJSON(selectArea) {
        const geojson = {
            type: "FeatureCollection",
            features: [],
        };

        for (const createdArea of selectArea) {

            let itsDemoArea = createdArea.id.startsWith("demo");

            if (itsDemoArea) {
                const feature = {
                    type: "Feature",
                    geometry: createdArea.coordinates,
                    properties: {
                        area_id: createdArea?.id,
                        color_area: `${createdArea?.color_area}`,
                        name_area: `${createdArea?.name_area}`
                    }
                };
                geojson.features.push(feature);
            } else {
                const coordinatesArray = createdArea.coordinates.map(point => point.coordinates);

                const feature = {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [coordinatesArray]
                    },
                    properties: {
                        area_id: createdArea?.id,
                        color_area: `${createdArea?.color_area}`,
                        name_area: `${createdArea?.name_area}`
                    }
                };
                geojson.features.push(feature);
            }
        }

        return geojson;
    }

    extendBounds(geojson) {
        const bounds = new mmrgl.LngLatBounds();
        geojson.features.forEach(feature => {
            bounds.extend(feature.geometry.coordinates[0]);
        });
        return bounds;
    }

    addDeliveryAreaMapLayers(map) {
        map.addLayer({
            'id': 'userPolygon',
            'type': 'fill',
            'source': 'userPolygon',
            'layout': {},
            'paint': {
                'fill-color': ["get", "color_area"],
                'fill-opacity': 0.5
            }
        });

        map.addLayer({
            'id': 'userPolygonBorder',
            'type': 'line',
            'source': 'userPolygon',
            'layout': {},
            'paint': {
                'line-color': '#000000',
                'line-width': 3,
                'line-opacity': 1
            }
        });

        map.addLayer({
            'id': 'userPolygonText',
            'type': 'symbol',
            'source': 'userPolygon',
            'layout': {
                'text-field': ["get", "name_area"],
                'text-font': ['Open Sans Regular'],
                'text-size': 12
            },
            'paint': {
                'text-color': '#000000',
                'text-halo-color': '#ffffff',
                'text-halo-width': 2
            }
        });
    }

    setupDeliveryAreaClickHandler(map, context) {
        map.on('click', 'userPolygon', (e) => {
            if (e.originalEvent.ctrlKey || context.currentPopup === "point") {
                return;
            }
            const coordinates = e.lngLat;
            const properties = e.features[0].properties;

            if (context.currentPopup === "deliveryArea" && context.currentPopupId === properties?.area_id) {
                createRouteSheetsStore.closeCurrentPopup();
                context.currentPopup = "";
                context.currentPopupId = "";
                return
            }
            context.currentPopupId = properties?.area_id;
            context.currentPopup = "deliveryArea"

            const popupElement = document.createElement('div');
            const popupRoot = ReactDOM.createRoot(popupElement);
            popupRoot.render(<DeliveryAreaMapPopup selectedAreaId={properties.area_id}/>);

            const currentPopup = new mmrgl.Popup({
                anchor: "bottom",
                maxWidth: "none",
                className: style.mainPopupBlock
            }).setDOMContent(popupElement).setLngLat(coordinates).addTo(map);

            context.typeCreatingRouteSheet = "deliveryArea";
            context.currentPopupData = currentPopup;
        });
    }

    setupDeliveryOrderCursorEvents(map) {
        map.on('mouseenter', 'userPolygon', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'userPolygon', () => {
            map.getCanvas().style.cursor = '';
        });
    }


    createOrderGeoJSON(orderList, bounds) {
        const geojson = {
            type: "FeatureCollection",
            features: [],
        };

        const coordCounter = {}; // To track duplicate coordinates
        let generalCounter = 0; // To track the general order number

        orderList.forEach((orderData) => {
            if (orderData?.coordinate.lat !== null || orderData?.coordinate.lon !== null || orderData?.coordinate.lat !== "" || orderData?.coordinate.lon !== "null") {
                let lngLat = [Number(orderData?.coordinate?.lon), Number(orderData?.coordinate?.lat)];

                // Check if the coordinate already exists
                const key = `${lngLat[0]},${lngLat[1]}`;
                if (coordCounter[key]) {
                    coordCounter[key].push({orderData, generalOrder: ++generalCounter});
                } else {
                    coordCounter[key] = [{orderData, generalOrder: ++generalCounter}];
                }
            }
        });

        Object.keys(coordCounter).forEach((key) => {
            const duplicates = coordCounter[key];
            const baseLngLat = key.split(',').map(Number);

            duplicates.forEach((entry, i) => {
                const {orderData, generalOrder} = entry;
                let lngLat = [...baseLngLat];

                if (i > 0) {
                    const angle = (i * 360 / duplicates.length) * (Math.PI / 180);
                    const offset = 0.0001;
                    lngLat[0] += offset * Math.cos(angle);
                    lngLat[1] += offset * Math.sin(angle);
                }

                bounds.extend(lngLat);

                const featureUID = String(orderData.id).match(/\d+/g).join('').slice(0, 15);
                const colorPoint = (orderData?.in_route_sheets) ? `#006fcbe6` : `#FF0000`;

                const feature = {
                    type: "Feature",
                    id: featureUID,
                    geometry: {
                        type: "Point",
                        coordinates: lngLat,
                    },
                    properties: {
                        id: orderData.id,
                        counter: i + 1,
                        client: orderData?.order_client,
                        text: `${generalOrder}, ${i + 1}, ${orderData?.order_client}`,
                        color_area: colorPoint,
                        color_point: colorPoint
                    }
                };

                geojson.features.push(feature);
            });
        });

        return geojson;
    }

    addOrderMapLayers(map) {
        map.addLayer({
            id: "points",
            type: "circle",
            source: "points",
            minzoom: 5,
            paint: {
                "circle-color": [
                    "case",
                    ["boolean", ["feature-state", "color"], false],
                    ["feature-state", "colorArea"],
                    ["get", "color_point"]
                ],
                "circle-radius": 8,
                "circle-stroke-width": 2,
                "circle-stroke-color": "#FFFFFF",
            },
        });

        map.addLayer({
            id: "points-text",
            type: "symbol",
            source: "points",
            minzoom: 5,
            layout: {
                "text-field": ["get", "text"],
                "text-offset": [0, 1.5],
                "text-anchor": "top",
                "text-size": 12,
            },
            paint: {
                "text-color": "#000000",
            },
        });
    }

    setupOrderClickHandler(map, context) {

        const clickHandler = function (e){
            if (e.originalEvent.ctrlKey) {
                context.addSelectOrderData(e.features[0].properties.id);
            } else {
                context.currentPopup = "point";
                const coordinates = e.features[0].geometry.coordinates.slice();
                const popupElement = document.createElement('div');
                const popupRoot = ReactDOM.createRoot(popupElement);

                popupRoot.render(
                    <GlobalProvider>
                        <OrderMapPopup orderId={e.features[0].properties.id}/>
                    </GlobalProvider>
                );

                const currentPopup = new mmrgl.Popup({
                    anchor: "bottom",
                    maxWidth: "none",
                    className: style.mainPopupBlock
                }).setDOMContent(popupElement).setLngLat(coordinates).addTo(map);

                context.typeCreatingRouteSheet = "selectPoint";

                currentPopup.on('close', () => {
                    context.currentPopup = null;
                    context.currentPopupData = null;
                });

                context.currentPopupData = currentPopup;
            }
        };

        map.on('click', 'points', clickHandler);

        context.mapListenerList = [clickHandler];
    }

    setupOrderCursorEvents(map) {
        map.on('mouseenter', 'points', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'points', () => {
            map.getCanvas().style.cursor = '';
        });
    }

    /**
     * Проверяет, находится ли точка в многоугольнике
     * @param {object} point - координаты точки
     * @param {object} polygon - координаты многоугольника
     * @returns {boolean} - true, если точка находится внутри многоугольника, иначе false
     */
    isPointInPolygon = (point, polygon) => {
        let itsMultiPolygon = polygon?.coordinates?.type === "MultiPolygon" || false;// ДЕМО УДАЛИТЬ
        let itsDemoArea = polygon.id.startsWith("demo");// ДЕМО УДАЛИТЬ

        const pointGeoJSON = turf.point([
            parseFloat(point.coordinate.lon),
            parseFloat(point.coordinate.lat),
        ]);

        if (itsMultiPolygon) {// ДЕМО УДАЛИТЬ

            for (const polygonGroupCoords of polygon.coordinates.coordinates) {
                for (const polygonCoords of polygonGroupCoords) {
                    if (!Array.isArray(polygonCoords)) {
                        throw new TypeError('Invalid polygon coordinates structure');
                    }

                    const polygonGeoJSON = turf.polygon([polygonCoords]);
                    if (turf.booleanPointInPolygon(pointGeoJSON, polygonGeoJSON)) {
                        let insideHole = false;

                        for (let i = 1; i < polygon.length; i++) {
                            const holeGeoJSON = turf.polygon([[...polygon[i], polygon[i][0]]]);
                            if (turf.booleanPointInPolygon(pointGeoJSON, holeGeoJSON)) {
                                insideHole = true;
                                break;
                            }
                        }

                        if (!insideHole) {
                            console.log(point, point.order_number)
                            return true;
                        }
                    }
                }
            }

            return false;
        } else if (itsDemoArea) {// ДЕМО УДАЛИТЬ
            // ДЕМО УДАЛИТЬ
            const polygonCoords = polygon.coordinates.coordinates[0]
            const polygonGeoJSON = turf.polygon([polygonCoords]);
            return turf.booleanPointInPolygon(pointGeoJSON, polygonGeoJSON);
        } else {
            const polygonCoords = [...polygon.coordinates, polygon.coordinates[0]].map(
                (coord) => coord.coordinates
            );
            const polygonGeoJSON = turf.polygon([polygonCoords]);
            return turf.booleanPointInPolygon(pointGeoJSON, polygonGeoJSON);
        }


    };

}

export default new CreateRouteSheetsUtils()