import React from 'react';
import style from './DeliveryAreaEditSelectAreaHeaderList.module.css';
import EditDeliveryAreaStore from "../../../../store/transportationLogisticsService/PageDeliveryZone/editDeliveryArea";
import {observer} from "mobx-react-lite";

const DeliveryAreaEditSelectAreaHeaderList = observer(() => {
    const {selectCreatedAreaState} = EditDeliveryAreaStore

    return (
        <div className={style.deliveryAreaHeader}>
            <div>
                <input
                    type="checkbox"
                    className={style.selectDocumentML}
                    id="selectAllArea"
                    name="selectAllArea"
                    value={selectCreatedAreaState.selectAllArea}
                    checked={selectCreatedAreaState.selectAllArea}
                    onChange={(e) => {
                        EditDeliveryAreaStore.selectAllCreatedArea(!selectCreatedAreaState.selectAllArea)
                    }}
                />
                <label htmlFor="selectAllArea"></label>
            </div>
            <div>Наименование</div>
        </div>
    );
});

export default DeliveryAreaEditSelectAreaHeaderList;