import React, {memo, useCallback, useEffect} from 'react';
import style from './CreateRouteSheetsOrderListTableListItem.module.css'
import toast from "react-hot-toast";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
    import { IconButton } from '@mui/material';
    import CloseIcon from '@mui/icons-material/Close';

import {Tooltip} from "react-tooltip";
import OrderInfoOrCheckbox from "../OrderInfoOrCheckbox/OrderInfoOrCheckbox";
import {useNavigate} from "react-router-dom";
import { toastMassage } from '../../../ToastMassage/ToastMassage';
import {observer} from "mobx-react-lite";


const CreateRouteSheetsOrderListTableListItem = observer(({orderData, orderIndex}) => {

    const {order_client, order_adress_delivery, vehicle_tonnage, in_route_sheets} = orderData
    const navigate = useNavigate();


    const zoomToPoint = () => {
        if (orderData?.coordinate.lat === null || orderData?.coordinate.lon === null || orderData?.coordinate.lat === "" || orderData?.coordinate.lon === "null") {
            toastMassage(toast.error, "У заказа нет координат!", 10000, "bottom-right")
            return
        }
        createRouteSheetsStore.zoomToPointOrder([Number(orderData?.coordinate?.lon), Number(orderData?.coordinate?.lat)]);
    }

    const changeChecked = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (in_route_sheets) {
            toastMassage(toast.error, "Заказ находится в маршруте!", 10000, "bottom-right")
            return
        }

        createRouteSheetsStore.changeSelectInTable(!orderData?.checked, orderData.id, "orderList")
    }, [])

    const redirectToEditOrder = (orderId) => {
        navigate(`/logisticService/addressCoordinateEditing/editAddress/${orderId}/createRouteSheet`)
    }

    // Функция для открытия листа маршрута по заданному orderId
    const handleRouteSheetOpen = (event,orderId) => {
        event.stopPropagation();

        const {popupShow} = createRouteSheetsStore.popupState;
        const {routeSheetsList} = createRouteSheetsStore

        if (!Array.isArray(routeSheetsList) || routeSheetsList.length === 0) {
            return;
        }

        const routeSheetData = routeSheetsList.find(route =>
            route.order_list.some(order => order.id === orderId)
        );

        if (routeSheetData){
            createRouteSheetsStore.changeEditRouteSheetsData(routeSheetData);
            createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "editRouteSheet");
        }
    }

    return (
        <div className={
            `${style.order} ${orderData?.in_route_sheets ? style.inRouteSheet : ''} ${!orderData?.coordinates_given ? style.noCoords : ''}`
        }
             onClick={() => zoomToPoint()}
        >
            <div>
                {orderIndex + 1}
            </div>
            <OrderInfoOrCheckbox
                orderData={orderData}
                onChangeOrder={(event => changeChecked(event))}
                onEditOrder={(orderId) => redirectToEditOrder(orderId)}
                onOpenRoute={(event,orderId) => handleRouteSheetOpen(event, orderId)}
            />
            <div>
                {order_adress_delivery}
            </div>
            <div>{order_client}</div>
            <div>{vehicle_tonnage} кг</div>
        </div>
    );
});

export default memo(CreateRouteSheetsOrderListTableListItem);