import { Alert, Card } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import DistributorRatingBox from "../../components/ManufacturerComponents/DistributorRatingBox/DistributorRatingBox";
import DistributorStatisticsCard from "../../components/ManufacturerComponents/DistributorStatisticsCard/DistributorStatisticsCard";
import DistributorSumCard from "../../components/ManufacturerComponents/DistributorSumCard/DistributorSumCard";
import DistributorTable from "../../components/ManufacturerComponents/DistributorTable/DistributorTable";
import Loader from "../../components/Loader/Loader";
import ManufacturerRatingBox from "../../components/DistributorComponents/ManufacturerRatingBox/ManufacturerRatingBox";
import ManufacturerStatisticsCard from "../../components/DistributorComponents/ManufacturerStatisticsCard/ManufacturerStatisticsCard";
import ManufacturerSumCard from "../../components/DistributorComponents/ManufacturerSumCard/ManufacturerSumCard";
import ManufacturersTable from "../../components/DistributorComponents/ManufacturersTable/ManufacturersTable";
import GlobalContext from "../../context/GlobalContext";
import imgNoneData from "../../imges/noneData.svg";
import "./distributorPage.css";
import RotatingLoadIcon from "../../components/RotatingLoadIcon/RotatingLoadIcon";
import IsLockedPage from "../IsLockedPage/IsLockedPage";

const DistributorPage = () => {
  const { state, distributorStartDate, distributorFinishDate } =
    useContext(GlobalContext);
  const {
    apiDocsSum,
    apiRegisterDates,
    apiGetTarifsUser,
    apiDocsSumDistributor,
    apiProfileDistibutorRegisterDate,
  } = useAxiosAPI();
  const distributors = state.distributors;
  const manufacturers = state.profileManufacturersList;
  const registerDates = state.registerDates;
  const docsSum = state.docsSum;
  const distributorDocsSum = state.distributorDocsSum;
  const [loadSum, setLoadSum] = React.useState(false);
  const status = Boolean(distributors == null || registerDates == null);
  const { user } = useContext(GlobalContext);
  useEffect(() => {
    if (user.accessLevel === "0") {
      apiProfileDistibutorRegisterDate();
    }

    const fetchData = () => {
      for (const distributor of distributors) {
        Promise.all([apiRegisterDates(distributor.id)])
          .then(() => {})
          .catch((error) => {
            // Handle error
          });
      }
    };

    if (distributors != null) {
      fetchData();
    }
  }, [distributors]);

  useEffect(() => {
    setLoadSum(true);
    if (user.accessLevel === "1") {
      apiGetTarifsUser();
      apiDocsSum()
        .then(() => {
          setLoadSum(false);
        })
        .catch(() => {
          setLoadSum(false);
          alert("Ошибка");
        });
    } else if (user.accessLevel === "0") {
      apiDocsSumDistributor()
        .then(() => {
          setLoadSum(false);
        })
        .catch(() => {
          setLoadSum(false);
          alert("Ошибка");
        });
    }
  }, [distributorStartDate && distributorFinishDate]);
  return (
    <>
      {state.subscriptions ? (
        <>
          {state.subscriptions.filter((item) => item.code === 2)[0].active ? (
            <Box className={"distributorPage"}>
              {user.accessLevel === "1" ? (
                <>
                  {!status ? (
                    <>
                      <Box className={"distributor-static"}>
                        <DistributorSumCard loadSum={loadSum} />
                        <DistributorStatisticsCard loadSum={loadSum} />
                      </Box>
                      {!loadSum ? (
                        <>
                          {docsSum.length > 0 ? (
                            <Box className={"distributor-diagram"}>
                              <DistributorRatingBox loadSum={loadSum} />
                            </Box>
                          ) : (
                            <Box className={"distributor-diagram-null-data"}>
                              <Card
                                className={"distributor-card-diagram-null-data"}
                              >
                                <Alert className={"noneData"} severity="info">
                                  За указанный период отсутствую данные -
                                  измените период!
                                </Alert>
                                <img
                                  className="img-none-data"
                                  src={imgNoneData}
                                />
                              </Card>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box className={"distributor-diagram-null-data"}>
                          <Card
                            className={"distributor-card-diagram-null-data"}
                          >
                            <RotatingLoadIcon />
                          </Card>
                        </Box>
                      )}

                      <DistributorTable
                        registerDates={registerDates}
                        distributors={distributors}
                      />
                    </>
                  ) : null}
                </>
              ) : user.accessLevel === "0" ? (
                <>
                  {true ? (
                    <>
                      <Box className={"distributor-static"}>
                        <ManufacturerSumCard loadSum={loadSum} />
                        <ManufacturerStatisticsCard loadSum={loadSum} />
                      </Box>

                      {!loadSum ? (
                        <>
                          {distributorDocsSum.length > 0 ? (
                            <Box className={"distributor-diagram"}>
                              <ManufacturerRatingBox loadSum={loadSum} />
                            </Box>
                          ) : (
                            <Box className={"distributor-diagram-null-data"}>
                              <Card
                                className={"distributor-card-diagram-null-data"}
                              >
                                <Alert className={"noneData"} severity="info">
                                  За указанный период отсутствую данные -
                                  измените период!
                                </Alert>
                                <img
                                  className="img-none-data"
                                  src={imgNoneData}
                                />
                              </Card>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box className={"distributor-diagram-null-data"}>
                          <Card
                            className={"distributor-card-diagram-null-data"}
                          >
                            <RotatingLoadIcon />
                          </Card>
                        </Box>
                      )}

                      <ManufacturersTable manufacturers={manufacturers} />
                    </>
                  ) : null}
                </>
              ) : null}
            </Box>
          ) : (
            <IsLockedPage nameService={"САВП"} />
          )}
        </>
      ) : null}
    </>
  );
};
export default DistributorPage;
