import BallotIcon from "@mui/icons-material/Ballot";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  ToggleButton,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import { dateConversion } from "../../helpers/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  flexGrow: 1,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "70vh",
};
function ChildModal({ id, inputPatch }) {
  const [name, setName] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const saveData = () => {
    inputPatch(id, name);
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>Переименовать</MenuItem>
      <Modal
        open={open}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300, height: "20vh" }}>
          <Typography
            sx={{ marginBottom: "10px" }}
            id="modal-modal-description"
          >
            Назовите шаблон
          </Typography>
          <TextField
            onChange={handleNameChange}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Название"
            variant="outlined"
          />
          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <Button variant="contained" onClick={handleClose}>
              Отмена
            </Button>
            <Button
              onClick={saveData}
              sx={{
                marginLeft: "10px",
                backgroundColor: "#52AB01",
                "&:hover": {
                  background: "#008000",
                },
              }}
              variant="contained"
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function Row({ template, inputData, inputDel, inputPatch }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ToggleButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        sx={{ marginLeft: "10px", marginTop: "10px" }}
        key={template.id}
        value="check"
      >
        <Box>
          <Box>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              {template.name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box>
              <BallotIcon sx={{ color: "#3382BC", paddingTop: "0.4rem" }} />
            </Box>
            <Box>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingTop: "0.4rem",
                }}
              >
                {dateConversion(template.templateCreationDate)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ToggleButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => inputData(template.dataReport)}>
          Открыть
        </MenuItem>
        <ChildModal id={template.id} inputPatch={inputPatch} />
        <MenuItem onClick={() => inputDel(template.id)}>Удалить</MenuItem>
      </Menu>
    </>
  );
}

const ConstructorTemplates = (props) => {
  const { apiPivotGet, apiPivotDelete, apiPivotRename } = useAxiosAPI();
  const [name, setName] = useState("");
  const [templates, setTemplates] = useState([]);
  const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);

  useEffect(() => {
    apiPivotGet().then((resolve) => {
      setTemplates(resolve.data);
    });
  }, [props.open]);

  const inputData = (data) => {
    props.setParams(data);
    handleClose();
  };

  const inputDel = (id) => {
    apiPivotDelete(id).then((resolve) => {
      apiPivotGet().then((resolve) => {
        setTemplates(resolve.data);
      });
    });
  };

  const inputPatch = (id, name) => {
    apiPivotRename(id, name).then((resolve) => {
      apiPivotGet().then((resolve) => {
        setTemplates(resolve.data);
      });
    });
  };

  return (
    <div>
      <IconButton
        variant="contained"
        sx={{
          backgroundColor: "#CB3424",
          color: "#ffffff",
          marginLeft: "10px",
          "&:hover": {
            background: "#ab2020",
          },
        }}
        onClick={() => handleOpen()}
      >
        <CollectionsBookmarkIcon />
      </IconButton>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ marginBottom: "10px" }}
            id="modal-modal-description"
          >
            Мои шаблоны
          </Typography>
          <Divider />

          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              maxHeight: "65vh",
              height: "65vh",
            }}
          >
            {templates.map((template) => (
              <Row
                template={template}
                inputData={inputData}
                inputDel={inputDel}
                inputPatch={inputPatch}
              />
            ))}
          </Box>
          <Divider sx={{ marginTop: "10px" }} />
        </Box>
      </Modal>
    </div>
  );
};
export default ConstructorTemplates;
