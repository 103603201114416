import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './DeliveryVehiclesEdit.module.css'
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import GlobalContext from "../../../../context/GlobalContext";
import DeliveryVehiclesCreateForm from "../DeliveryVehiclesCreateForm/DeliveryVehiclesCreateForm";
import pageDeliveryVehiclesStore
    from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
import DeliveryVehiclesEditForm from "../DeliveryVehiclesEditForm/DeliveryVehiclesEditForm";

const DeliveryVehiclesEdit = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const navigate = useNavigate();
    const {vehId} = useParams();


    useEffect(() => {

        async function getVehicleById(){
            await pageDeliveryVehiclesStore.getVehicleById(vehId, authTokens, setUser, setAuthTokens, logoutUser, navigate);
        }

        getVehicleById()

        return () => {
            pageDeliveryVehiclesStore.changeDefaultValue_Edit();
        }
    }, [])

    const createNewTemplate = async (e) => {
        e.preventDefault()
        await pageDeliveryVehiclesStore.updateVehicleData(authTokens, setUser, setAuthTokens, logoutUser,navigate);
    }

    return (
        <div className={style.createTemplate}>
            <div className={style.createTemplateHeader}>
                <div className={style.goBackToList}
                     onClick={() => navigate(-1)}>Назад
                </div>
                Редактирование карточки транспортного средства
            </div>


            <form action="" onSubmit={(e) => createNewTemplate(e)}>
                <DeliveryVehiclesEditForm/>
            </form>

        </div>
    );
});

export default memo(DeliveryVehiclesEdit);