import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import WestIcon from "@mui/icons-material/West";
import {
    Alert,
    Badge,
    Button,
    CardHeader,
    Divider,
    IconButton,
    List,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useAxiosAPI } from "../../../apiRequest/apiRequest";
import GlobalContext from "../../../context/GlobalContext";
import { formatAndPrintDate } from "../../../helpers/helpers";
// import DistributorCurrentRemainsDataList from "../DistributorCurrentRemainsDataList/DistributorCurrentRemainsDataList";
import RotatingLoadIcon from "../../RotatingLoadIcon/RotatingLoadIcon";
import "./ManufacturersCurrentRemains.css";
import Search from "../../ui/Search/Search";
import ManufacturersCurrentRemainsDataList
    from "../ManufacturersCurrentRemainsDataList/ManufacturersCurrentRemainsDataList";

function filterDataByMonthAndYear(data, onlyHistoryCheck, searchText) {
    return data.filter((item) => {
        const itemName = item.nomenclatureObj.name.toLowerCase();
        const itemArticle = item.nomenclatureObj.article.toLowerCase();
        const itemBrand = item.nomenclatureObj.manBrand.toLowerCase();

        if (onlyHistoryCheck) {
            return (
                (itemName.includes(searchText.toLowerCase()) ||
                    itemBrand.includes(searchText.toLowerCase()) ||
                    itemArticle.includes(searchText.toLowerCase())) &&
                parseInt(
                    item.nomenclatureObj.currentRemains
                        .map((item) => parseFloat(item.historyCount))
                        .reduce((sum, value) => sum + value, 0)
                ) > 0
            );
        } else {
            return (
                itemName.includes(searchText.toLowerCase()) ||
                itemArticle.includes(searchText.toLowerCase()) ||
                itemBrand.includes(searchText.toLowerCase())
            );
        }
    });
}

// Оптимизированная версия компонента DistributorCurrentRemains
export default function ManufacturersCurrentRemains(props) {
    const [modifiedArray, setMmodifiedArray] = useState([]);
    const [searchComplete, setSearchComplete] = useState("");
    const [onlyHistoryCheck, setOnlyHistoryCheck] = useState(false);
    const { state, distributorStartDate, distributorFinishDate } = useContext(GlobalContext);
    const [load, setLoad] = useState(false);
    const { apiManufacturerCurrentRemains } = useAxiosAPI();
    const [activeString, setActiveString] = React.useState(null);
    useEffect(() => {
        // Проверяем, есть ли данные в стейте для текущего props.id
        if (state.manufacturerCurrentRemains[props.id]) {
            setMmodifiedArray(state.manufacturerCurrentRemains[props.id]);
            setLoad(false);
        } else {
            setMmodifiedArray([]);
            setLoad(false);
        }
    }, [state.manufacturerCurrentRemains]);

    useEffect(() => {
        setLoad(true);
        apiManufacturerCurrentRemains(props.id, props.inn);
    }, [distributorStartDate, distributorFinishDate, props.id]);
    return (
        <>
            <CardHeader
                avatar={
                    <div>
                        <Button
                            onClick={() =>
                                !activeString
                                    ? props.setActiveString(null)
                                    : setActiveString(null)
                            }
                            variant="contained"
                        >
                            <WestIcon />
                        </Button>
                        <Button disabled sx={{ marginLeft: "10px" }}>
                            Остатки
                        </Button>
                        /
                        <Button
                            disabled={props.setActiveString}
                            onClick={() => props.setActiveString(null)}
                        >
                            Дистрибьюторы
                        </Button>
                        /
                        <Button
                            disabled={activeString}
                            onClick={() => setActiveString(null)}
                        >
                            Номенклатура
                        </Button>
                        /
                        {activeString ? (
                            <Button>
                                Остатки / <p className="truncate">{activeString.manNomName}</p>
                            </Button>
                        ) : null}
                    </div>
                }
                action={
                    <div>
                        {!activeString ? (
                            <Search
                                searchComplete={searchComplete}
                                setSearchComplete={setSearchComplete}
                            />
                        ) : null}
                    </div>
                }
            />
            <Divider />
            {!load ? (
                <List sx={{ overflow: "hidden" }}>
                    <TableContainer
                        component={"div"}
                        style={{
                            height: "73vh",
                            borderRadius: 0,
                            boxShadow: 0,
                            backgroundColor: "#ffffff",
                        }}
                    >
                        <>
                            {!activeString ? (
                                <>
                                    <Table stickyHeader aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 700, width: "30%" }}>Найминование</TableCell>
                                                <TableCell sx={{ fontWeight: 700 }}>Артикль</TableCell>
                                                <TableCell sx={{ fontWeight: 700 }}>Бренд</TableCell>
                                                <TableCell sx={{ fontWeight: 700 }}>Группа</TableCell>
                                                <TableCell sx={{ fontWeight: 700 }}>Дата выгрузки</TableCell>
                                                <TableCell sx={{ fontWeight: 700 }}>Дата остатка</TableCell>
                                                <TableCell sx={{ fontWeight: 700 }}>
                                                    <IconButton
                                                        onClick={() =>
                                                            setOnlyHistoryCheck(!onlyHistoryCheck)
                                                        }
                                                    >
                                                        <NotificationsActiveIcon
                                                            color={onlyHistoryCheck ? "error" : ""}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {modifiedArray.length > 0 ? (
                                            <TableBody>
                                                {filterDataByMonthAndYear(
                                                    modifiedArray,
                                                    onlyHistoryCheck,
                                                    searchComplete
                                                ).map((row) => (
                                                    <TableRow
                                                        className="hoverable-row"
                                                        onClick={() => setActiveString(row.nomenclatureObj)}
                                                    >
                                                        <TableCell
                                                            style={{
                                                                paddingBottom: 0,
                                                                paddingTop: 0,
                                                                width: "10%",
                                                            }}
                                                        >
                                                            {row.nomenclatureObj.manNomName}
                                                        </TableCell>
                                                        <TableCell>{row.nomenclatureObj.article}</TableCell>
                                                        <TableCell>
                                                            {row.nomenclatureObj.manBrand}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.nomenclatureObj.manGroup_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatAndPrintDate(
                                                                row.nomenclatureObj.currentRemains[0]
                                                                    .dayOfUnloading
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatAndPrintDate(
                                                                row.nomenclatureObj.currentRemains[0]
                                                                    .reportingDay
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.nomenclatureObj.currentRemains
                                                                .map((item) => parseFloat(item.historyCount))
                                                                .reduce((sum, value) => sum + value, 0) > 0 ? (
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "1vw",
                                                                        marginLeft: 1,
                                                                    }}
                                                                    gutterBottom
                                                                    component="div"
                                                                >
                                                                    <Badge
                                                                        color="primary"
                                                                        badgeContent={row.nomenclatureObj.currentRemains
                                                                            .map((item) =>
                                                                                parseFloat(item.historyCount)
                                                                            )
                                                                            .reduce((sum, value) => sum + value, 0)}
                                                                    ></Badge>
                                                                </Typography>
                                                            ) : (
                                                                0
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={7}>
                                                        <Alert severity="info">
                                                            {" "}
                                                            Информация по заданному периоду отсутствует !
                                                        </Alert>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </>
                            ) : (
                                <ManufacturersCurrentRemainsDataList
                                    totalHistoryCount={activeString.currentRemains
                                        .map((item) => parseFloat(item.historyCount))
                                        .reduce((sum, value) => sum + value, 0)}
                                    name={activeString.name}
                                    current={activeString.currentRemains[0]}
                                    currentAll={activeString.currentRemains}
                                    activeString={activeString}
                                />
                            )}
                        </>
                    </TableContainer>
                </List>
            ) : (
                <List sx={{ overflow: "hidden", padding: "10px" }}>
                    <TableContainer
                        component={"div"}
                        style={{
                            height: "73vh",
                            borderRadius: 0,
                            boxShadow: 0,
                            backgroundColor: "#ffffff",
                        }}
                    >
                        <RotatingLoadIcon />
                    </TableContainer>
                </List>
            )}
        </>
    );
}
