import Box from "@mui/material/Box";
import React, { useContext } from 'react';
import { Navigate } from "react-router-dom";
import AuthorizationForm from "../../components/AuthorizationForm/AuthorizationForm";
import GlobalContext from "../../context/GlobalContext";
import "./AdminPage.css";
import AdminUserPage from '../AdminUserPage/AdminUserPage'

const AdminPage = () => {
    let { user } = useContext(GlobalContext);

    return (
        <>
            {user ? (
                <AdminUserPage/>
            ) : (
                <Box>
                    <Box>
                        <AuthorizationForm />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default AdminPage;
