import React from 'react';
import style from './DeliveryAreaHeaderList.module.css';
import deliveryZone from "../../../../store/transportationLogisticsService/PageDeliveryZone/deliveryZone";
import {observer} from "mobx-react-lite";

const DeliveryAreaHeaderList = observer(() => {
    const {deliveryAreaTableState} = deliveryZone

    return (
        <div className={style.deliveryAreaHeader}>
            <div>
                <input
                    type="checkbox"
                    className={style.selectDocumentML}
                    id="selectAllArea"
                    name="selectAllArea"
                    value={deliveryAreaTableState.selectAllArea}
                    checked={deliveryAreaTableState.selectAllArea}
                    onChange={(e) => {
                        deliveryZone.selectAllDeliveryArea(!deliveryAreaTableState.selectAllArea)
                    }}
                />
                <label htmlFor="selectAllArea"></label>
            </div>
            <div>Наименование</div>
            <div>Редактировать</div>
        </div>
    );
});

export default DeliveryAreaHeaderList;