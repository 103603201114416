import { TextField } from "@mui/material";
import ru from "date-fns/locale/ru";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import "./DatePickerReportsStart.css";

const InputDate = styled(TextField)`
  width: 230px;
  margin: 0;
  padding: 0;
  input {
    height: 15px; /* Уменьшаем высоту поля ввода */
  }
`;

const DatePickerReportsStart = (props) => {
  const [dateRange, setDateRange] = useState([
    props.distributorStartDateReportsStart,
    props.distributorFinishDateReportsStart,
  ]);
  const [startDate, endDate] = dateRange;
  const isBothFieldsFilled = startDate && endDate;
  const datePickerRef = useRef(null);

  const handleDateChange = (dates) => {
    if (!dates) {
      props.setDistributorStartDateReportsStart(null);
      props.setDistributorFinishDateReportsStart(null);
      setDateRange([null, null]);
      return;
    }

    const threeMonthsLater = new Date(dates[0]);
    threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

    const adjustedEndDate =
      dates[1] > threeMonthsLater ? threeMonthsLater : dates[1];

    props.setDistributorStartDateReportsStart(dates[0]);
    props.setDistributorFinishDateReportsStart(adjustedEndDate);
    setDateRange([dates[0], adjustedEndDate]);
  };

  const clearForm = () => {
    props.setDistributorStartDateReportsStart(null);
    props.setDistributorFinishDateReportsStart(null);
    setDateRange([null, null]);
  };

  const years = [];
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1960; year--) {
    years.push(year);
  }

  const filterDate = (date) => {
    if (!startDate) {
      return true;
    }

    const threeMonthsLater = new Date(startDate);
    threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

    return date >= startDate && date <= threeMonthsLater;
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      const target = e.target;
      if (isBothFieldsFilled && datePickerRef.current.contains(target)) {
        clearForm();
      } else {
        if (
          target.classList.contains("react-datepicker__day--disabled") ||
          target.closest(".react-datepicker__day--disabled")
        ) {
          props.setTooltipVisible(true);
        } else props.setTooltipVisible(false);
      }
    };

    document.body.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.body.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [isBothFieldsFilled]);

  return (
    <div style={{ display: "flex", zIndex: 100}} ref={datePickerRef}>
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={handleDateChange}
      isClearable={true}
      placeholderText="Выберите период"
      customInput={<InputDate/>}
      locale={ru}
      dateFormat="dd.MM.yyyy"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      yearDropdownItemNumber={10}
      scrollableYearDropdown
      yearDropdownItems={years}
      filterDate={filterDate}
    />
     </div>
  );
};

export default DatePickerReportsStart;
