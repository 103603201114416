import React, {useContext} from 'react';
import style from './DeliveryAreaListActionBtns.module.css';
import {useNavigate} from "react-router-dom";
import deliveryZone from "../../../../store/transportationLogisticsService/PageDeliveryZone/deliveryZone";
import GlobalContext from "../../../../context/GlobalContext";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import toast from "react-hot-toast";
import {observer} from "mobx-react-lite";
import { toastMassage } from '../../../ToastMassage/ToastMassage';

const DeliveryAreaListActionBtns = observer(() => {
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);
    const {popupScrollPosition,popupShow,popupType} = deliveryZone.popupState;
    const navigate = useNavigate();
    const createNewArea = () => {
        navigate("/logisticService/deliveryAreas/createArea");
    }
    const deleteDeliveryAreas = async () => {
        try {
            await deliveryZone.deleteDeliveryAreas(authTokens, setUser, setAuthTokens, logoutUser, navigate);
            deliveryZone.closePopup()
        } catch (e) {
        }
    }

    const changePopupShow = (value, typePopup) => {
        deliveryZone.changePopupShow(window.pageYOffset, value, typePopup);
    }

    const clearMap = () => {
        deliveryZone.clearUserPointMap();
        deliveryZone.setDefaultCheckedValue();
        toastMassage(toast.success, "Карта очищена!", 2500, "bottom-right")
    }

    const showSelectedArea = () => {
        deliveryZone.showCreatedDeliveryArea();
    }

    return (

        <div className={style.actionBtns}>
            <button
                onClick={() => {
                    changePopupShow(!popupShow, "askDeleteDeliveryArea")
                }}
                className={style.deleteSelectPoints_btn}>
                Удалить выбранные зоны
            </button>
            <button
                id={'createBtn'}
                className={style.createNewArea_btn}
                onClick={() => createNewArea()}
            >
                Создать зону
            </button>
            <button
                id={'createBtn'}
                className={style.showCreatingArea_btn}
                onClick={() => showSelectedArea()}
                // onClick={() => createNewZona.createDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser)}
            >
                Показать выбранные зоны
            </button>
            <button
                onClick={() => clearMap()}
                className={style.showCreatingArea_btn}>
                Очистить карту
            </button>

            <PopupRouting
                isOpen={popupShow && popupType === "askDeleteDeliveryArea"}
                onClose={() => deliveryZone.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <div className={style.popupHeader}>
                    Уточнение
                    <div
                        className={style.btnClosePopup}
                        onClick={() => {
                            deliveryZone.closePopup()
                        }}
                    ><DeleteIcon/></div>
                </div>
                <div className={style.askText}>Вы точно хотите удалить выбранные зоны доставки, действие будет невозможно
                    отменить?
                </div>
                <div className={style.actionAskBtns}>
                    <button
                        className={style.acceptAskBtn}
                        onClick={() => deleteDeliveryAreas()}
                    >Подтвердить
                    </button>
                    <button
                        className={style.cancelAskBtn}
                        onClick={() => deliveryZone.closePopup()}
                    >Отменить
                    </button>
                </div>
            </PopupRouting>
        </div>
)
    ;
});

export default DeliveryAreaListActionBtns;