import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import "../ManufacturerComponents/DistributorRatingBox/DistributorRatingBox.css";
import RegisterDates from "../ui/RegisterDates/RegisterDates";

export default function ProfileDistributorRegisterDate() {
  // Массив торговых точек с привязкой табличной части
  const { apiProfileDistibutorRegisterDate } = useAxiosAPI();
  const { state, distributorStartDate, distributorFinishDate } = useContext(GlobalContext);
  const [modifiedArray, setMmodifiedArray] = useState([]);
  const [load, setLoad] = useState(false)
  // Массив всей нуменклатуры
  const [searchResults, setSearchResults] = useState(modifiedArray);


  useEffect(() => {
    // Проверяем, есть ли данные в стейте для текущего props.id
    if (state.profileDistibutorRegisterDate) {
      setMmodifiedArray(state.profileDistibutorRegisterDate);
      setSearchResults(modifiedArray)
      setLoad(false)
    } else {
      setMmodifiedArray([]);
      setLoad(false)
    }
  }, [state.profileDistibutorRegisterDate]);

  

  useEffect(() => {
    setLoad(true)
    apiProfileDistibutorRegisterDate();
    setMmodifiedArray(state.profileDistibutorRegisterDate)
  }, [state.profileDistributors, apiProfileDistibutorRegisterDate]);

  return (
    <>
    {!load ?
    <Box sx={{ borderRadius: 0, backgroundColor: "#ffffff", overflow: "auto", maxHeight: "80vh" }}>
    <RegisterDates dateArr={modifiedArray}/>
    </Box>
    :
    <Box sx={{height: "40vh", backgroundColor: "#fff", position: 'relative'}}>
    <CircularProgress  className="load" />
    </Box>
    }
    </>
  );
}
