import React, {memo, useEffect} from 'react';
import style from './ModalSelectedInitialPointItem.module.css';
import {Tooltip} from 'react-tooltip';
import {observer} from "mobx-react-lite";

const ModalSelectedInitialPointItem = observer(({paramRowData, onClick}) => {

    const changeInitPoint = () => {
        onClick(paramRowData)
    }

    return (
        <div
            onClick={() => changeInitPoint()}
            className={style.addressCoordsItem}
        >
            <div>
                <div data-tooltip-id={`client-info_${paramRowData.id}`}
                     data-tooltip-variant="info">
                    {(paramRowData?.initial_route_point_name.length > 50) ? paramRowData?.initial_route_point_name.slice(0, 50) + "..." : paramRowData?.initial_route_point_name || "----"}
                    {(paramRowData?.initial_route_point_name.length > 50) ? <Tooltip
                        id={`client-info_${paramRowData.id}`}
                        content={paramRowData?.initial_route_point_name}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
                <div data-tooltip-id={`address-info_${paramRowData.id}`}
                     data-tooltip-variant="info">
                    {(paramRowData?.address.length > 50) ? paramRowData?.address.slice(0, 45) + "..." : paramRowData?.address || "----"}
                    {(paramRowData?.address.length > 50) ? <Tooltip
                        id={`address-info_${paramRowData.id}`}
                        content={paramRowData?.address}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
            </div>
        </div>
    );
})

export default memo(ModalSelectedInitialPointItem);