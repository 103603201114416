import React from 'react';
import style from './ModalAddInRouteSheet.module.css'
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import ModalRouteSheetTableHead from "../ModalRouteSheetTableHead/ModalRouteSheetTableHead";
import ModalRouteSheetTableList from "../ModalRouteSheetTableList/ModalRouteSheetTableList";


const ModalAddInRouteSheet = () => {
    return (
        <div>
            <div className={style.popupHeader}>
                Выбор маршрутного листа
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup();
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>
            <ModalRouteSheetTableHead/>
            <ModalRouteSheetTableList/>
        </div>
    );
};

export default ModalAddInRouteSheet;