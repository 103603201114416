import React, {memo, useEffect} from 'react';
import style from './RouteOptimizationParameters.module.css';
import settingOptimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import {ReactComponent as SettingIcon_v2} from '../../../../imges/SettingIcon_v2.svg';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {observer} from "mobx-react-lite";

const RouteOptimizationParameters = observer(() => {
    const {pageState} = optimizationRouteSheetStore;

    return (
        <div className={style.routeOptimizationParameters}>

            <div className={style.btnRouteSettings}
                 onClick={() => settingOptimizationRouteSheetStore.openPopupSettingOptimization(window.pageYOffset, "selectTypeOptimizationRoute")}>
                <SettingIcon_v2/>
                <div>Параметры оптимизации маршрута</div>
            </div>

            <PopupRouting
                isOpen={pageState.popup_show && pageState.popup_type === "selectTypeOptimizationRoute"}
                onClose={() => settingOptimizationRouteSheetStore.closePopupSettingOptimization()}
                scrollPosition={pageState.popup_scroll_position}
            >
                <div className={style.popupHeader}>
                    Параметры оптимизации маршрута
                    <div
                        className={style.btnClosePopup}
                        onClick={() => {
                            optimizationRouteSheetStore.closePopupInitPoints()
                        }}
                    ><DeleteIcon/></div>
                </div>
                <div className={style.routeOptimizationParametersSelector}>

                    <div>
                        <input
                            type="checkbox"
                            className={style.routeOptimizationOptionsCheckbox}
                            id="optimizationForTime"
                            name="optimization_for_time"
                            value={settingOptimizationRouteSheetStore["generalSettingForDocuments"].optimization_parameters_checked.optimization_for_time}
                            checked={settingOptimizationRouteSheetStore["generalSettingForDocuments"].optimization_parameters_checked.optimization_for_time}
                            onChange={(e) => settingOptimizationRouteSheetStore.selectOptimizationParameter(e.target.name, true, "generalSettingForDocuments")}
                        />
                        <label htmlFor="optimizationForTime">Оптимизировать по времени</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            className={style.routeOptimizationOptionsCheckbox}
                            id="optimizationForDistance"
                            name="optimization_for_distance"
                            value={settingOptimizationRouteSheetStore["generalSettingForDocuments"].optimization_parameters_checked.optimization_for_distance}
                            checked={settingOptimizationRouteSheetStore["generalSettingForDocuments"].optimization_parameters_checked.optimization_for_distance}
                            onChange={(e) => settingOptimizationRouteSheetStore.selectOptimizationParameter(e.target.name, true, "generalSettingForDocuments")}
                        />
                        <label htmlFor="optimizationForDistance">Оптимизировать по расстоянию</label>
                    </div>
                </div>
            </PopupRouting>

        </div>
    );
});

export default memo(RouteOptimizationParameters);