import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import style from './SettingOptimizationRouteByVehicle.module.css';
import {ReactComponent as CarIcon} from '../../../../imges/CarIcon.svg';
import {ReactComponent as TruckIcon} from '../../../../imges/TruckIcon.svg';
import {ReactComponent as SettingIcon_v2} from '../../../../imges/SettingIcon_v2.svg';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import debounce from "lodash/debounce";
import 'react-calendar/dist/Calendar.css';
import PopoverSelector from "../../PopoverSelector/PopoverSelector";
import DateSelectionField from "../../../../inputs/logisticService/DatePicker/DatePicker";
import InputMask from "react-input-mask";
import {observer} from 'mobx-react-lite';
import {observable} from "mobx";
import settingOptimizationRouteSheetStore
    from '../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore'
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import InitialPointPopupList from "../../InitialPointPopupList/InitialPointPopupList";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import SettingBuildingRouteByVehicle from "../SettingBuildingRouteByVehicle/SettingBuildingRouteByVehicle";

const SettingOptimizationRouteByVehicle = observer(({typeSetting}) => {
    const {
        costing
    } = settingOptimizationRouteSheetStore[typeSetting];
    const {popup_show,popup_scroll_position,popup_type} = optimizationRouteSheetStore.pageState;
    const openPopUp = useCallback(() => {
        settingOptimizationRouteSheetStore.openPopupSettingOptimization(window.pageYOffset, typeSetting)
    }, [])

    return (

            <div className={style.vehicleTypeSelector}>
                <div className={style.vehicleTypeSelectorHeader}>Вид транспорта:</div>

                <div className={costing === 'auto' ? style.selectTypeCar : style.selectTypeCar}>
                    <label>
                        <input
                            type="checkbox"
                            value="auto"
                            id={`selectCar_${typeSetting}`}
                            checked={costing === 'auto'}
                            onChange={(e) => settingOptimizationRouteSheetStore.handleCheckboxChangeTypeCar(e.target.value, typeSetting)}
                        />
                        <CarIcon/>
                    </label>
                    <span className={costing === 'auto' ? style.selectLine : ' '}></span>
                </div>

                <div className={costing === 'truck' ? style.selectTypeCar : style.selectTypeCar}>
                    <label>
                        <input
                            type="checkbox"
                            value="truck"
                            id={`selectTruck_${typeSetting}`}
                            checked={costing === 'truck'}
                            onChange={(e) => settingOptimizationRouteSheetStore.handleCheckboxChangeTypeCar(e.target.value, typeSetting)}
                        />
                        <TruckIcon/>
                    </label>
                    <span className={costing === 'truck' ? style.selectLine : ' '}></span>
                </div>

                <div className={style.btnRouteSettings}
                     onClick={openPopUp}>
                    <SettingIcon_v2/>
                    <div>Параметры построения маршрута</div>
                </div>

                {
                    <PopupRouting
                        isOpen={popup_show && (popup_type === "generalSettingForDocuments" || popup_type === "settingForDocuments")}
                        onClose={() => settingOptimizationRouteSheetStore.closePopupSettingOptimization()}
                        scrollPosition={popup_scroll_position}
                    >
                            <div className={style.popupHeader}>
                                Параметры построения маршрута
                                <div
                                    className={style.btnClosePopup}
                                    onClick={() => {
                                        optimizationRouteSheetStore.closePopupInitPoints()
                                    }}
                                ><DeleteIcon/></div>
                            </div>
                                <SettingBuildingRouteByVehicle typeSetting={popup_type}/>
                    </PopupRouting>
                }

            </div>


    );
});

export default memo(SettingOptimizationRouteByVehicle);