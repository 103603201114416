import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AdminFormMapKey({selectedUser}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [quantityKey, setQuantityKey] = React.useState(0);
  const {authTokens} = useContext(GlobalContext);
  const headers = {
    'Authorization': `Bearer ${authTokens?.access}`
    
}

  const createKey = () => {
    axios.post(`http://192.168.1.14:5006/logisticService/mobile/api/v1/createLicenseForCompany/companyId=${selectedUser.inn}&countLic=${quantityKey}`, "",  {headers: headers}
    )
    .then((response) => {
      if (response.status === 200) {
        handleClose()
      } else {
          // Обработка ошибки
          console.error(
              "Ошибка при запросе за производителем:",
              response.statusText
          );
      }
  })
  .catch((error) => {
      // Обработка ошибки
      console.error(
          "Ошибка",
          error
      );
  });
  }
  return (
    <div>
      
      <Button             sx={{ marginTop: "20px" }}
 variant="contained" onClick={handleOpen}>
        Добавить ключ
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Сколько ключей добавить пользователю?
          </Typography>

          <TextField
            sx={{ width: "300px" }}
            type={"number"}
            id="standard-basic"
            value={quantityKey}
            onChange={(event) => setQuantityKey(event.target.value)}
            label="Кол-во запросов"
            variant="standard"
          />

          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            onClick={createKey}
          >
            Добавить ключи
          </Button>

        </Box>
      </Modal>
    </div>
  );
}