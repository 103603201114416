import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {useAxiosAPI} from "../../apiRequest/apiRequest";
import Search from "../ui/Search/Search";
import AdminUpdateUserForm from "../AdminUpdateUserForm/AdminUpdateUserForm";


export default function AdminUserTable({typeUser}) {
    const [manufacturerUser, setManufacturerUser] = useState([]);
    const [searchComplete, setSearchComplete] = useState("");
    const [rows, setSearchResults] = useState(manufacturerUser);
    const [loader, setLoader] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const {apiAdminUsersList} = useAxiosAPI();
    useEffect(() => {
        setLoader(true); // Установите loader в true перед началом запроса
        apiAdminUsersList(typeUser)
            .then((resolve) => {
                setManufacturerUser(resolve.data);
                setSearchResults(resolve.data);
                setLoader(false);
            })
            .catch(() => {
                setManufacturerUser([]);
                setSearchResults([]);
                setLoader(false);
            });
    }, [apiAdminUsersList]);

    return (

        <>
            {selectedUser ?
            <AdminUpdateUserForm selectedUser={selectedUser} setSelectedUser={setSelectedUser}/>
                :
        <TableContainer sx={{maxWidth: "100%"}} component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><Search
                            searchComplete={searchComplete}
                            setSearchComplete={setSearchComplete}
                        /></TableCell>
                        <TableCell align="right">Почта</TableCell>
                        <TableCell align="right">Организация</TableCell>
                        <TableCell align="right">ИНН</TableCell>
                        <TableCell align="right">Доступ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            onClick={() => setSelectedUser(row)}
                            key={row.name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.mail}</TableCell>
                            <TableCell align="right">{row.organization}</TableCell>
                            <TableCell align="right">{row.inn}</TableCell>
                            <TableCell align="right">{row.access ? "Разрешен" : "Запрещен"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            }
            </>
    );
}
