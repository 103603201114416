import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import "./ProfileDistributorRegistrationModal.css";
import ProfilePatchRegionUser from "../ProfilePatchRegionUser/ProfilePatchRegionUser";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



export default function ProfileDistributorRegistrationModal(props) {
    const { state } = React.useContext(GlobalContext);
    const [selectedRegion, setSelectedRegion] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [uploadDays, setUploadDays] = React.useState('');
    const [uploadDaysError, setUploadDaysError] = React.useState('');
    const [regionError, setRegionError] = React.useState('');
    const { apiRegistration } = useAxiosAPI();

 

  const registerPost = () => {
    apiRegistration(props.actionDistributor.id, {  
      "mail": email,
      "inn": props.actionDistributor.inn,
      "dayLimit": + uploadDays,
      "region": selectedRegion
    })
    .then(() => {
      props.setOpenModalRegister(false)
    })
    .catch(() => {
      alert("Ошибка")
    });
  }

    const handleChangeRegion = (event) => {
      setSelectedRegion(event.target.value);
      setRegionError('');
    };
  
    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
      
        // Allow any input during typing, enforce strict validation on form submission
        setEmail(enteredEmail);
      
      };
  
    const handleUploadDaysChange = (event) => {
      const enteredUploadDays = event.target.value;
  
      const isValidUploadDays = enteredUploadDays >= 0 && enteredUploadDays <= 360;
  
      if (isValidUploadDays || enteredUploadDays === '') {
        setUploadDays(enteredUploadDays);
        setUploadDaysError('');
      } else {
        setUploadDaysError('Количество дней выгрузки не может быть меньше 0 или больше 360');
      }
    };
  
    const isButtonDisabled = !email || !uploadDays || !selectedRegion;
  
    
     return (
    <Modal
      open={props.openModalRegister}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 900 }}>
      <Box sx={{display: "flex", width: "100%" }}>
        <Box sx={{ marginBottom: "3vh", width: "50%" }}>
        <TextField 
        className="inputModal"
          id="filled-read-only-input"
          label="Наименование"
          defaultValue={props.actionDistributor.name}
          InputProps={{
            readOnly: true,
          }}
          variant="filled"
        />
          <TextField 
        className="inputModal"
          id="filled-read-only-input"
          label="ИНН"
          defaultValue={props.actionDistributor.inn}
          InputProps={{
            readOnly: true,
          }}
          variant="filled"
        />
        <TextField 
        className="inputModal"
          id="filled-read-only-input"
          label="КПП"
          defaultValue={props.actionDistributor.kpp}
          InputProps={{
            readOnly: true,
          }}
          variant="filled"
        />
      
      <TextField
            className="inputModal"
            required
            id="filled-read-only-input"
            label="Почта"
            value={email}
            onChange={handleEmailChange}
            type="email"
            variant="standard"
          />

          <TextField
            className="inputModal"
            required
            id="filled-read-only-input"
            label="Лимит дней выгрузки"
            value={uploadDays}
            onChange={handleUploadDaysChange}
            type="number"
            variant="standard"
            error={!!uploadDaysError}
            helperText={uploadDaysError}
          />

<Box sx={{marginTop: "10px"}}>
<ProfilePatchRegionUser
                                   
                                    setRegions={setSelectedRegion}
                                    regions={selectedRegion}
                                /> 
                                </Box>

          {/* <FormControl sx={{marginTop: "10px"}} className="inputModal"  required>
            <InputLabel id="demo-simple-select-label">Регион</InputLabel>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRegion}
              label="Регион"
              onChange={handleChangeRegion}
              error={!!regionError}
              helperText={regionError}
            >
              {menuItems}
            </Select>
          </FormControl> */}
        


          </Box>
          <Box sx={{ marginBottom: "3vh", width: "50%"}}>
        <h3 id="parent-modal-title">
          Уважаемый пользователь: <span>{state.manufacturer.name}</span>
        </h3>
        <Typography variant="body1" gutterBottom>
        Дистрибьютор: <span>{props.actionDistributor.name}</span>
      </Typography>
        <Typography variant="body1" gutterBottom>
          {/* Уважаемый пользователь: {state.manufacturer.name}<br /> */}
          Ранее не был
          зарегистрирован в нашем сервисе. <br />
          Для предоставления ему возможности выгружать данные, просим вас
          воспользоваться кнопкой "Зарегистрировать". После этого на указанный
          вами адрес электронной почты дистрибьютора будет направлен токен,
          необходимый для доступа к выгрузке данных из 1C, а также для
          предоставления доступа к личному кабинету пользователя в нашем
          сервисе.
        </Typography>
        <Typography variant="body1" gutterBottom>
          С благодарностью за выбор нашей платформы.
          </Typography>
          </Box>
          </Box>
          <Button
          onClick={registerPost}
          sx={{ marginRight: "1vw" }}
          variant="contained"
          disabled={isButtonDisabled}
        >
          Зарегистрировать
        </Button>
                <Button variant="contained" onClick={() => props.setOpenModalRegister(false)}>
          Отмена
        </Button>
      </Box>
    </Modal>
  );
}