import React, {memo, useState} from 'react';
import style from './DeliversRoutesListHeader.module.css'
import toast from "react-hot-toast";
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import PopoverBasic from "../../UI/PopoverBasic/PopoverBasic";
import {observer} from "mobx-react-lite";
import {ReactComponent as FilterTableIcon} from '../../../../imges/FilterTableIcon.svg';
import {ReactComponent as ArrowUp} from '../../../../imges/ArrowUp.svg';
import {ReactComponent as ArrowDown} from '../../../../imges/ArrowDown.svg';
import {ReactComponent as InfoRouteIcon} from '../../../../imges/InfoRouteIcon.svg';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';


const DeliversRoutesListHeader = observer(() => {

    const {
        deliversList, copyDeliversList,
        deliverListTableSortState: {sort_name, sort_value},
        tableFilterDeliveredState: {selected_filters, statusDocumentList, uniqueDriversNames, uniqueForwardersNames}
    } = deliversRoutes;

    const [showPopover_StatusDelivered, setShowPopover_StatusDelivered] = useState(false);
    const [showPopover_DriverName, setShowPopover_DriverName] = useState(false);
    const [showPopover_ForwarderName, setShowPopover_ForwarderName] = useState(false);
    const [newValuePopover, setNewValuePopover] = useState(null);

    const handlePopoverVision = (filterName) => {
        if (deliversList.length === 0) {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
            return
        } else if (newValuePopover === "closePopover") {
            setNewValuePopover(null)
            return
        }

        if (filterName === "status_document") {
            setShowPopover_StatusDelivered(!showPopover_StatusDelivered);
        } else if (filterName === "driver_name") {
            setShowPopover_DriverName(!showPopover_DriverName);
        } else if (filterName === "forwarder_name") {
            setShowPopover_ForwarderName(!showPopover_ForwarderName);
        }
    }


    const checkedFilerStatus = (filterName) => {
        if (selected_filters.length === 0) {
            return true
        }
        for (const filter of selected_filters) {
            if (filter.filter_name === filterName && filter.filter_text === "без фильтра") {
                return true
            }
        }
        return false
    }

    // Записываем новое значение того, сколько элементов отображать на странице
    const getSelectedValueDestination = (newValue, filterName) => {
        setNewValuePopover(newValue);
        if (filterName === "status_document") {
            setShowPopover_StatusDelivered(false);
        } else if (filterName === "driver_name") {
            setShowPopover_DriverName(false)
        } else if (filterName === "forwarder_name") {
            setShowPopover_ForwarderName(false)
        }

        if (newValue === "closePopover") {
            return
        }

        if (!deliversList) {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
            return
        }

        deliversRoutes.selectFilterAndUpdateDocList(newValue, "tableFilterDeliveredState");
    };

    const getSelectOptionPopover = (filterName) => {
        if (selected_filters.length === 0) {
            return
        }

        return selected_filters.find((filter) => filter.filter_name === filterName)
    }

    return (
        <div className={style.deliversHeaderTable}>
            <div className={style.numberDoc}
                 onClick={() => {
                     if (copyDeliversList.length === 0) {
                        toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                         return
                     }
                     deliversRoutes.changeSortCollTableRouteList("document_number", "deliverListTableSortState");
                     deliversRoutes.selectSortedDocList("deliversList", "deliverListTableSortState", "tableFilterDeliveredState", "filteredDeliversList")
                 }}
            >Номер
                <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "document_number" || sort_name !== "document_number") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "document_number") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                    <span
                        style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "document_number" || sort_name !== "document_number") ? "visible" : "hidden"}}
                        className={(sort_value === 2 && sort_name === "document_number") ? style.activeArrow : ""}
                    ><ArrowDown/></span>
                </div>
            </div>
            <div className={style.progressRoute}
                 onClick={() => {
                     if (copyDeliversList.length === 0) {
                         toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                         return
                     }
                     deliversRoutes.changeSortCollTableRouteList("totalDeliveredRoutePoint", "deliverListTableSortState");
                     deliversRoutes.selectSortedDocList("deliversList", "deliverListTableSortState", "tableFilterDeliveredState", "filteredDeliversList")
                 }}
            >Прогресс
                <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "totalDeliveredRoutePoint" || sort_name !== "totalDeliveredRoutePoint") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "totalDeliveredRoutePoint") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                    <span
                        style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "totalDeliveredRoutePoint" || sort_name !== "totalDeliveredRoutePoint") ? "visible" : "hidden"}}
                        className={(sort_value === 2 && sort_name === "totalDeliveredRoutePoint") ? style.activeArrow : ""}
                    ><ArrowDown/></span>
                </div>
            </div>
            <div className={style.statusAndCar}>
                <div className={style.statusRoute}>
                    Статус
                    <span
                        className={
                            `
                                         ${showPopover_StatusDelivered ? style.showPopover : ""}
                                         ${checkedFilerStatus("status_document") ? "" : style.selectFiler}
                                    `
                        }
                        onClick={() => handlePopoverVision("status_document")}
                    ><FilterTableIcon/></span>
                    <PopoverBasic
                        selectOptions={getSelectOptionPopover("status_document")}
                        options={statusDocumentList}
                        onChange={(newValue) => getSelectedValueDestination(newValue, "status_document")}
                        open={showPopover_StatusDelivered}/>
                </div>
                <div>ТС</div>
            </div>
            <div className={style.dateDocAndDelivery}>
                <div
                    onClick={() => {
                        if (copyDeliversList.length === 0) {
                            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                            return
                        }
                        deliversRoutes.changeSortCollTableRouteList("document_date", "deliverListTableSortState");
                        deliversRoutes.selectSortedDocList("deliversList", "deliverListTableSortState", "tableFilterDeliveredState", "filteredDeliversList")
                    }}
                >Дата документа
                    <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "document_date" || sort_name !== "document_date") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "document_date") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "document_date" || sort_name !== "document_date") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "document_date") ? style.activeArrow : ""}
                        ><ArrowDown/></span>
                    </div>
                </div>
                <div
                    onClick={() => {
                        if (copyDeliversList.length === 0) {
                            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                            return
                        }
                        deliversRoutes.changeSortCollTableRouteList("delivery_date", "deliverListTableSortState");
                        deliversRoutes.selectSortedDocList("deliversList", "deliverListTableSortState", "tableFilterDeliveredState", "filteredDeliversList")
                    }}
                >Дата доставки
                    <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "delivery_date" || sort_name !== "delivery_date") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "delivery_date") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "delivery_date" || sort_name !== "delivery_date") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "delivery_date") ? style.activeArrow : ""}
                        ><ArrowDown/></span>
                    </div>
                </div>
            </div>
            <div className={style.driverAndForward}>
                <div className={style.driverInfo}>
                    Водитель
                    <span
                        className={
                            `
                                         ${showPopover_DriverName ? style.showPopover : ""}
                                         ${checkedFilerStatus("driver_name") ? "" : style.selectFiler}
                                    `
                        }
                        onClick={() => handlePopoverVision("driver_name")}
                    ><FilterTableIcon/></span>
                    <PopoverBasic
                        selectOptions={getSelectOptionPopover("driver_name")}
                        options={uniqueDriversNames}
                        onChange={(newValue) => getSelectedValueDestination(newValue, "driver_name")}
                        open={showPopover_DriverName}/>
                </div>
                <div
                    className={style.forwarderInfo}
                >
                    Экспедитор
                    <span
                        className={
                            `
                                         ${showPopover_ForwarderName ? style.showPopover : ""}
                                         ${checkedFilerStatus("forwarder_name") ? "" : style.selectFiler}
                                    `
                        }
                        onClick={() => handlePopoverVision("forwarder_name")}
                    ><FilterTableIcon/></span>
                    <PopoverBasic
                        selectOptions={getSelectOptionPopover("forwarder_name")}
                        options={uniqueForwardersNames}
                        onChange={(newValue) => getSelectedValueDestination(newValue, "forwarder_name")}
                        open={showPopover_ForwarderName}/>
                </div>
            </div>
            <div className={style.infoRoute}>
                <InfoRouteIcon/>
            </div>
        </div>
    );
});

export default memo(DeliversRoutesListHeader);