import {makeAutoObservable} from 'mobx'
import {v4 as uuidv4} from "uuid";
import globalState from "../globalState";
import axios from "axios";
import toast from "react-hot-toast";
import MapData_DeliveryArea from "./mapData_DeliveryArea";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../components/ToastMassage/ToastMassage';

class deliveryZone {
    listZones = []
    listZonesCopy = []

    deliveryAreaTableState = {
        selectAllArea: false,
        searchValue: ""
    }

    statusSettingAction = {
        settingPoint: false,
        loadingMap: true,
        focusZona: true,
        saveChanges: false,
        showAllArea: false,
        mainPageArea: false
    }
    // Состояние модального окна
    popupState = {
        popupShow: false,
        popupType: "",
        popupScrollPosition: 0
    }


    constructor() {
        makeAutoObservable(this,)
    }

    closePopup(){
        this.popupState.popupShow = false;
        this.popupState.popupType = "";
    }

    changePopupShow(position,value, typePopup){
        this.popupState.popupShow = value;
        this.popupState.popupType = typePopup;
        this.popupState.popupScrollPosition = position;
    }

    selectAllDeliveryArea(newValue) {
        this.deliveryAreaTableState.selectAllArea = newValue;

        this.listZones = this.listZones.slice().map(area => {
            area.checked = newValue;
            return area
        })
    }

    selectDeliveryArea(areaId, newValue) {

        this.listZones = this.listZones.slice().map(area => {
            if (area.id === areaId) {
                area.checked = newValue
            }
            return area
        })
    }

    changeDeliveryAreaText(newText){
        this.deliveryAreaTableState.searchValue = newText;
        const regex = new RegExp(`^.*${newText.split(' ').join('.*')}.*$`, 'i');

        this.listZones = this.listZonesCopy.slice().filter(area =>
            regex.test(area.name_area.toString())
        )
    }


    /**
     * Добавляет новые координаты в полигон.
     *
     * @param {object} coordinates - Объект с координатами, должен содержать поля lng и lat.
     */
    addedPolygonCords(coordinates) {
        // Проверяем, что объект coordinates не является пустым
        if (!coordinates) {
            throw new Error('Invalid coordinate'); // Выбрасываем ошибку, если координаты недопустимы
        }
        // Создаем новый объект координат
        const newCoordinate = {coordinates: [coordinates.lng, coordinates.lat], checked: false};
        // Обновляем список зон с учетом новых координат
        const updatedListZones = this.listZones.map(obj => {
            if (obj.setting === true) {
                return {...obj, coordinates: [...obj.coordinates, newCoordinate]};
            }
            return obj;
        });

        // Устанавливаем фокус на зону
        this.statusSettingAction.focusZona = false;
        // Обновляем список зон
        this.listZones = updatedListZones;
    }

    /**
     * Удаляет выбранные точки из зоны по идентификатору.
     * @param {number} idZona - Идентификатор зоны
     */
    deleteSelectPoints(idZona) {
        // Снимаем отметку "выбрано" со всех точек
        this.statusSettingAction.checkedAllPoint = false;
        // Устанавливаем фокус на зону
        this.statusSettingAction.focusZona = false;
        console.log(this.statusSettingAction.focusZona)
        // Фильтруем координаты новой зоны, оставляя только невыбранные точки
        const updatedListZones = this.listZones.map(obj => {
            if (obj.id === idZona) {
                obj.coordinates = obj.coordinates.filter(obj2 => !obj2.checked);
            }
            return obj
        })

        this.listZones = updatedListZones;
    }

    updateNameZone(newName, idZona) {
        const updatedListZones = this.listZones.map(obj => {
            if (obj.id === idZona) {
                obj.nameZone = newName
            }
            return obj
        })

        this.listZones = updatedListZones;
    }

    saveNewZone(idZona) {
        // Установить статус сохранения зоны
        this.statusSettingAction.saveChanges = true;
        // Вывести объект, который передается на сервер
        console.log("На сервер передаётся объект:")
        this.updateSelectedZoneSetting(idZona, false)
        // Установить таймер для сброса статуса сохранения зоны
        setTimeout(() => {
            this.statusSettingAction.saveChanges = false;
        }, 2500)
    }

    updateInfoLoading(value) {
        this.statusSettingAction.loadingMap = value;
    }

    getEditingArea() {
        return this.listZones.filter(obj => obj.setting === true)

    }


    async getAllDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getAllDeliveryArea`, {headers}
        ).then(res => {
            this.listZones = res.data
            this.setDefaultCheckedValue();

            this.listZonesCopy = this.listZones
        }).catch(error => {
            toastMassage(toast.error, "Ошибка при получении зон доставки", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })

    }

    async deleteDeliveryAreas(authTokens, setUser, setAuthTokens, logoutUser){
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            deliveryArea: this.getSelectedAreaId()
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteDeliveryAreas`, data,{headers}
        ).then(res => {
            toastMassage(toast.success, "Выбранные зоны успешно удалены!", 2500, "bottom-right")
            this.getAllDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser);

        }).catch(error => {
            toastMassage(toast.error, "Ошибка при удалении выбранных зон доставки", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    getSelectedAreaId() {
        let selectedArea = [];

        for (const area of this.listZones){

            if (area?.checked){
                selectedArea.push(area.id)
            }

        }

        return selectedArea;
    }


    setDefaultCheckedValue() {
        this.deliveryAreaTableState.selectAllArea = false;
        this.listZones = this.listZones.slice().map(area => {
            area.checked = false
            return area
        })
    }


    clearUserPointMap() {
        const map = MapData_DeliveryArea.mapData;

        if (map){
            const mapStyle = map.getStyle();

            if (!mapStyle || !mapStyle.layers) return;
            const layers = mapStyle.layers.map(layer => layer.id);
            layers.forEach(layerId => {

                if (layerId.startsWith("userPolygonText_") || layerId.startsWith("userPolygonBorder_") || layerId.startsWith("userPolygon_") || layerId.startsWith("userPoint_")) {
                    map.removeLayer(layerId);
                }
            });
        }


    }

    showCreatedDeliveryArea() {
        const map = MapData_DeliveryArea.mapData;
        this.clearUserPointMap();

        for (const checkedArea of this.listZones) {
            if (checkedArea.checked && checkedArea.coordinates.length !== 0){
                const idZone = checkedArea.id;
                const coordinatesArray = checkedArea.coordinates.map(point => point.coordinates);

                if (map) {
                    // Проверяем, существует ли уже источник данных с таким именем
                    if (!map.getSource(`userPolygon_${idZone}`)) {
                        map.addSource(`userPolygon_${idZone}`, {
                            type: 'geojson',
                            data: {
                                type: "FeatureCollection",
                                features: []
                            }
                        });
                    }

                    // Проверяем, существует ли уже слой с таким именем
                    if (!map.getLayer(`userPolygon_${idZone}`)) {
                        map.addLayer({
                            'id': `userPolygon_${idZone}`,
                            'type': 'fill',
                            'source': `userPolygon_${idZone}`,
                            'layout': {
                                'visibility': 'visible'
                            },
                            'paint': {
                                'fill-color': `${checkedArea?.color_area}`,
                                'fill-opacity': 0.5,
                                'fill-antialias': true
                            }
                        });

                        map.addLayer({
                            'id': `userPolygonBorder_${idZone}`,
                            'type': 'line',
                            'source': `userPolygon_${idZone}`,
                            'layout': {
                                'visibility': 'visible'
                            },
                            'paint': {
                                'line-color': '#000000', // Цвет границы
                                'line-width': 3, // Ширина границы
                                'line-opacity': 1
                            }
                        });

                        map.addLayer({
                            'id': `userPolygonText_${idZone}`,
                            'type': 'symbol',
                            'source': `userPolygon_${idZone}`,
                            'layout': {
                                'text-field': `${checkedArea?.name_area}`, // Замените 'Текст' на нужный текст
                                'text-font': ['Open Sans Regular'], // Шрифт текста
                                'text-size': 12 // Размер текста
                            },
                            'paint': {
                                'text-color': '#000000', // Цвет текста
                                'text-halo-color': '#ffffff', // Цвет алого круга
                                'text-halo-width': 2 // Ширина алого круга
                            }
                        });
                    }

                    // Формируем данные для слоя userPolygon
                    const userPolygonData = {
                        type: "FeatureCollection",
                        features: [{
                            type: "Feature",
                            geometry: {
                                type: "Polygon",
                                coordinates: [coordinatesArray] // Убираем дополнительный массив
                            }
                        }]
                    };

                    // Устанавливаем данные для источника данных userPolygon
                    const userPolygonSource = map.getSource(`userPolygon_${idZone}`);
                    if (userPolygonSource) {
                        userPolygonSource.setData(userPolygonData);



                        // if (userPolygonData.features[0].geometry.coordinates[0].length > 0) {
                        //     map.fitBounds(coordinatesArray, {zoom: 12});
                        // }
                    }
                }
            }
        }


    }

    zoomAndShowClickArea(areaId, coordinates, colorArea, nameArea) {

        if (coordinates.length === 0){
            toastMassage(toast.error, "У данной зоны доставки нет координат!", 10000, "bottom-right")
        }

        const map = MapData_DeliveryArea.mapData;

        const idZone = areaId;
        const coordinatesArray = coordinates.map(point => point.coordinates);

        if (map) {
            // Проверяем, существует ли уже источник данных с таким именем
            if (!map.getSource(`userPolygon_${idZone}`)) {
                map.addSource(`userPolygon_${idZone}`, {
                    type: 'geojson',
                    data: {
                        type: "FeatureCollection",
                        features: []
                    }
                });
            }

            // Проверяем, существует ли уже слой с таким именем
            if (!map.getLayer(`userPolygon_${idZone}`)) {
                map.addLayer({
                    'id': `userPolygon_${idZone}`,
                    'type': 'fill',
                    'source': `userPolygon_${idZone}`,
                    'layout': {
                        'visibility': 'visible'
                    },
                    'paint': {
                        'fill-color': `${colorArea}`,
                        'fill-opacity': 0.5,
                        'fill-antialias': true
                    }
                });

                map.addLayer({
                    'id': `userPolygonBorder_${idZone}`,
                    'type': 'line',
                    'source': `userPolygon_${idZone}`,
                    'layout': {
                        'visibility': 'visible'
                    },
                    'paint': {
                        'line-color': '#000000', // Цвет границы
                        'line-width': 3, // Ширина границы
                        'line-opacity': 1
                    }
                });

                map.addLayer({
                    'id': `userPolygonText_${idZone}`,
                    'type': 'symbol',
                    'source': `userPolygon_${idZone}`,
                    'layout': {
                        'text-field': `${nameArea}`, // Замените 'Текст' на нужный текст
                        'text-font': ['Open Sans Regular'], // Шрифт текста
                        'text-size': 12 // Размер текста
                    },
                    'paint': {
                        'text-color': '#000000', // Цвет текста
                        'text-halo-color': '#ffffff', // Цвет алого круга
                        'text-halo-width': 2 // Ширина алого круга
                    }
                });
            }

            // Формируем данные для слоя userPolygon
            const userPolygonData = {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [coordinatesArray] // Убираем дополнительный массив
                    }
                }]
            };

            // Устанавливаем данные для источника данных userPolygon
            const userPolygonSource = map.getSource(`userPolygon_${idZone}`);
            if (userPolygonSource) {
                userPolygonSource.setData(userPolygonData);

                if (userPolygonData.features[0].geometry.coordinates[0].length > 0) {
                    if (coordinatesArray.length > 4){
                        map.fitBounds(coordinatesArray, {zoom: 12});
                    } else {
                        const focusCoordsArray = coordinatesArray.slice(0, coordinatesArray.length - 1);
                        map.fitBounds(focusCoordsArray, {zoom: 12});
                    }
                }

            }
        }
    }

}

export default new deliveryZone();