import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  CardHeader,
  Divider,
  Paper,
  Box,
  List,
  TableContainer,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import DistributorNomenclatureTable from "../DistributorNomenclatureTable/DistributorNomenclatureTable";
import DistributorTableRetail from "../DistributorRetailChainsTable/DistributorRetailChainsTable";
import { useAxiosAPI } from "../../../apiRequest/apiRequest";
import GlobalContext from "../../../context/GlobalContext";
import imgNoneData from "../../../imges/noneData.svg";
import RotatingLoadIcon from "../../RotatingLoadIcon/RotatingLoadIcon";
import { Alert } from "@mui/material";
import "./DistributorCardСounterparty.css";

function getCheckedIds(data) {
  return data.flatMap((item) =>
    (item.retailOutletsList || [])
      .filter((outlet) => outlet.checked)
      .map((outlet) => outlet.id)
  );
}
const DistributorCardCounterparties = (props) => {
  const { apiCounteragentsAndRetailOutlets, apiGetRetailOutletsAndDistrNoms } =
    useAxiosAPI();
  const { state, distributorFinishDate } = useContext(GlobalContext);
  const [load, setLoad] = useState(false);
  const [items, setItems] = useState([]);
  const [nom, setNom] = useState([]);
  const [valueId, setValueId] = useState(null);
  const [listOpen, setListOpen] = useState(false);
  const [activeRetail, setActiveRetail] = useState(null);

  // Отфильтровывает все с checked true и преобразует в массив id
  const idArray = useMemo(() => {
    return items.flatMap((item) =>
      item.retailOutletsList
        .filter((outlet) => outlet.checked)
        .map((outlet) => outlet.id)
    );
  }, [items]);

  // Запрос за номенклатурой
  const handleNomenclature = useCallback(() => {
    setLoad(true);
    apiGetRetailOutletsAndDistrNoms(props.id, idArray, false)
      .then((resolve) => {
        setNom(resolve.data);
        setListOpen((prevListOpen) => !prevListOpen);
        setLoad(false);
      })
      .catch(() => {
        console.log("Ошибка");
        setLoad(false);
      });
  }, [props.id, items, apiGetRetailOutletsAndDistrNoms]);

  // Запрос за сетями и точками
  useEffect(() => {
    setLoad(true);
    apiCounteragentsAndRetailOutlets(props.id);
  }, [distributorFinishDate, props.id]);

  // Проверка есть ли что-то в стейте до/после запроса
  useEffect(() => {
    if (state.counteragentsAndRetailOutlets[props.id]) {
      setItems(state.counteragentsAndRetailOutlets[props.id]);
      setLoad(false);
    } else {
      setItems([]);
      setLoad(false);
    }
  }, [state.counteragentsAndRetailOutlets]);

  // Выбор торговой сети
  const handleClick = useCallback((sectionId) => {
    setListOpen(false);
    setValueId(sectionId);
  }, []);

  return (
    <>
      <CardHeader
        avatar={
          <div>
            <Button
              onClick={() => (props.activeString && !listOpen && !activeRetail ? props.setActiveString(null) : listOpen ? setListOpen(false) : setActiveRetail(null))}
              variant="contained"
            >
              <WestIcon />
            </Button>
            <Button disabled sx={{ marginLeft: "10px" }}>
              Реализация
            </Button>
            /
            <Button
              disabled={props.setActiveString}
              onClick={() => props.setActiveString(null)}
            >
              Дистрибьюторы
            </Button>
            /<Button disabled={listOpen}>Торговые точки</Button>/
            {listOpen ? <Button>Номенклатура</Button> : null}
          </div>
        }
      />
      <Divider />
      {!load ? (
        <Box>
          {items.length > 0 ? (
            <Paper sx={{ display: "flex", padding: 0 }}>
              {!listOpen ? (
                <>
                  <DistributorTableRetail
                    handleNomenclature={handleNomenclature}
                    getCheckedIds={getCheckedIds}
                    valueId={valueId}
                    setValueId={setValueId}
                    distr={props.id}
                    activeRetail={activeRetail}
                    setActiveRetail={setActiveRetail}
                    items={items}
                    handleClick={handleClick}
                    setItems={setItems} // Добавлен проп setItems
                  />
                </>
              ) : (
                <DistributorNomenclatureTable
                  docs={nom.nomenclatures}
                  allSum={nom.allSum}
                  items={getCheckedIds(items)}
                  distr={props.id}
                />
              )}
            </Paper>
          ) : (
            <Box sx={{ width: "100%", height: "10vh" }}>
              <Alert severity="info">
                Информация по заданному периоду отсутствует !
              </Alert>
              <img className="img-none-data" src={imgNoneData} alt="No data" />
            </Box>
          )}
        </Box>
      ) : (
        <List sx={{ overflow: "hidden", padding: "10px" }}>
          <TableContainer
            component={"div"}
            style={{
              height: "73vh",
              borderRadius: 0,
              boxShadow: 0,
              backgroundColor: "#ffffff",
            }}
          >
            <RotatingLoadIcon />
          </TableContainer>
        </List>
      )}
    </>
  );
};

export default DistributorCardCounterparties;
