// import React, {useContext, useState} from 'react';
// import axios from 'axios';
import "./AuthorizationForm.css";

import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import GlobalContext from "../../context/GlobalContext";

// import "./styles.css";

export default function AuthorizationForm() {
        const {loginUser} = useContext(GlobalContext);

    const {register, handleSubmit} = useForm();
    const onSubmit = (data) => loginUser(data.username, data.password);


    return(
        <Box className={"authBox"}>
        <form className={"authForm"} onSubmit={handleSubmit(onSubmit)}>
            <h1 className={"headerH"}>Авторизация</h1>
            <label type="email" className={"authLabel"}>Почта</label>
            <input className={"authInput"}{...register("username")} />
            <label type="password" className={"authLabel"}>Пароль</label>
            <input className={"authInput"}
                type={"password"}
                {...register("password", )}
            />
            <input  value="Войти" className={"authInput"} type="submit" />
        </form>
        </Box>
);
}


