import React from "react";
import {makeAutoObservable} from "mobx";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import settingOptimizationRouteSheetStore from "./settingOptimizationRouteSheetStore";
import toast from "react-hot-toast";
import globalState from "../globalState";
import AxiosConfig_LogisticService from "../../../utils/transportationLogisticsService/axiosConfig";
import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from "@mui/material";
import {toastMassage} from "../../../components/ToastMassage/ToastMassage";

const DEFAULT_PAGE_STATE = {
    document_upload_date: null, // Дата за которую получить документы
    show_info_block: false, // Статус, который решает показывать ли пользователю уведомление
    type_info_block: "success", // Тип блока уведомления
    checked_all_document_route: false, // Выбраны все документы МЛ
    checked_all_document_order: false, // Выбраны все заказы
    setting_document: false, // Статус того, что документ редактируется
    setting_document_id: null, // ID редактируемого документа
    text_info_block: null, // Текст уведомления
    popup_show: false, // Отображать ли popup
    popup_type: null, // Тип popup: selectInitialPoint, generalSettingForDocument, settingForDocuments
    popup_scroll_position: 0,
    unload_time: 15, // Время на выгрузку товара (15м поумолчанию),
    createMobAuthData: false, // Отображать данные для печати QR,
    table_search_value: "", // Значение для поиска данных по таблице
};

const DEFAULT_STATUS_DOCUMENT_FILTER = [
    {
        filter_name: "status_document",
        filter_text: "оптимизирован",
        filter_params_name: "route_optimization",
        filter_value: true,
    },
    {
        filter_name: "status_document",
        filter_text: "не оптимизирован",
        filter_params_name: "route_optimization",
        filter_value: false,
    },
    {
        filter_name: "status_document",
        filter_text: "построен",
        filter_params_name: "route_built",
        filter_value: true,
    },
    {
        filter_name: "status_document",
        filter_text: "не построен",
        filter_params_name: "route_built",
        filter_value: false,
    },
    {
        filter_name: "status_document",
        filter_text: "в маршруте",
        filter_params_name: "in_route",
        filter_value: true,
    },
    {
        filter_name: "status_document",
        filter_text: "без фильтра",
        filter_params_name: "",
        filter_value: false,
    },
];

class optimizationRouteSheetStore {
    // Маршрутные листы
    routingSheets = null;

    copyRoutingSheets = null; // Копия, используется для фильтров и визуальной работы с отображением на странице

    filteredArrayDoc = null; // Отфильтрованный массив с МЛ

    // Массив для запроса к API VK (Построение маршрута)
    routeBuilder = [];

    // Массив для запроса к API VK (Оптимизация маршрута)
    routeOptimization = [];

    selectedDocumentArray = []; // Массив ID документов которые были выделены пользователем

    // Состояния на странице
    pageState = {
        ...DEFAULT_PAGE_STATE,
        document_upload_date: this.getISONowDate(),
    };

    routeListTableSortState = {
        sort_name: "document_number",
        sort_value: 0,
    };

    paginationState = {
        page: 1,
        rowsPerPage: 10,
    };

    tableFilterRouteSheetsState = {
        // Стейт для фильтров таблицы маршрутных листов
        select_filter: false, // Выбран ли хотя бы 1 фильтр в таблице
        uniqueDriversNames: null, // Список всех водителей (по 1) по выгруженным документам
        statusDocumentList: DEFAULT_STATUS_DOCUMENT_FILTER,
        selected_filters: [
            {
                filter_name: "status_document",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false,
            },
            {
                filter_name: "driver_name",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false,
            },
        ], // Массив выбранных фильтров
    };

    constructor() {
        makeAutoObservable(this);
    }

    // Получение документов по выбранной дате
    async upLoadDocumentRouteSheet(uploadDate, axiosInstance) {
        const data = {
            delivery_date: uploadDate, // "2022-08-23"
        };

        const uploadingRoutSheets = toast.loading(
            "Попытка получить маршрутные листы!",
            {
                position: "bottom-right",
            }
        );

        await axiosInstance
            .post(`/getTransportOrderByDate`, data)
            .then((response) => {
                if (response.data.length > 0) {
                    this.pageState = {
                        ...DEFAULT_PAGE_STATE,
                        unload_time: this.pageState.unload_time,
                    };
                    this.routingSheets = response.data;
                    this.getUniqDriversNameByFilter();
                    this.copyRoutingSheets = this.routingSheets.slice();
                    this.selectCheckedValue();

                    if (this.tableFilterRouteSheetsState.select_filter) {
                        this.selectFilterAndUpdateDocList("UpdateDocList");
                    }

                    this.showCurrentAmountDocument();
                } else {

                    toastMassage(toast.error, "Нет документов за выбранную дату!", 10000, "bottom-right")
                    this.pageState = DEFAULT_PAGE_STATE;
                    this.routingSheets = null;
                    this.copyRoutingSheets = null;
                }
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            })
            .finally(() => {
                toast.dismiss(uploadingRoutSheets);
            });
    }

    selectCheckedValue() {
        if (this.selectedDocumentArray.length > 0) {
            const checkedDoc = this.routingSheets.slice().map((objDoc) => {
                for (const checkId of this.selectedDocumentArray) {
                    if (objDoc.id === checkId) {
                        objDoc.checked = true;
                    }
                }
                return objDoc;
            });

            this.routingSheets = checkedDoc;
            this.copyRoutingSheets = checkedDoc;
        }
    }

    getUniqDriversNameByFilter() {
        const uniqueDriversNames = [
            {
                filter_name: "driver_name",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false,
            },
        ];
        for (const objDoc of this.routingSheets) {
            const searDouble = uniqueDriversNames.find(
                (obj) => obj.filter_text === objDoc.drivers_info[0].driver_name
            );
            if (searDouble === undefined) {
                uniqueDriversNames.push({
                    filter_name: "driver_name",
                    filter_text: objDoc.drivers_info[0].driver_name,
                    filter_params_name: "drivers_info[0].driver_name",
                    filter_value: objDoc.drivers_info[0].driver_name,
                });
            }
        }

        this.tableFilterRouteSheetsState.uniqueDriversNames = uniqueDriversNames;
    }

    // Установка даты загрузки документов
    changeUpLoadDocumentDate(navigate, date) {
        if (date === undefined) {
            return;
        }
        const currentDate = new Date();
        const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

        // Увеличиваем дату на один день
        const destinationDateT = new Date(date);
        destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);

        navigate(
            `/logisticService/routingByRouteList/routeSheetsList/${destinationDateT
                .toISOString()
                .slice(0, 10)}`
        );
        this.pageState.document_upload_date = destinationDateT
            .toISOString()
            .slice(0, 10);
    }

    getISONowDate() {
        const currentDate = new Date();
        const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

        // Увеличиваем дату на один день
        const destinationDateT = new Date();
        destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);
        return destinationDateT.toISOString().slice(0, 16);
    }

    /**
     * Получить маршрутный лист по указанному ID
     *
     * @param {type} documentId - описание параметра
     * @return {type} getObjectRoute - описание возвращаемого значения
     */
    getRoutingSheets(documentId) {
        const getObjectRoute = this?.routingSheets.filter((obj) => {
            return obj.document_id === documentId;
        });

        return getObjectRoute;
    }

    /**
     * Удаляем заказ из маршрутного листа
     * @param {string} idDocument - идентификатор заказа
     * @param {string} idOrderDocument - идентификатор документа
     */
    deleteSelectOrderDocument(idDocument, idOrderDocument) {
        // Создаем новый массив маршрутных листов, в котором удаляем указанный заказ
        const newArray = this.routingSheets.map((obj) => {
            if (obj.document_id === idOrderDocument) {
                obj.order_list = obj.order_list.filter(
                    (objOrder) => objOrder.order_id !== idDocument
                );
            }
            return obj;
        });

        this.routingSheets = newArray; // Обновляем маршрутные листы
    }

    /**
     * Обновляет состояние всех маршрутов документов и соответственно обновляет листы маршрутизации.
     *
     * @param {type} value - новое состояние для всех маршрутов документов
     * @return {type} undefined
     */
    selectAllDocumentRoute(value) {
        if (this.routingSheets != null) {
            this.pageState = {...this.pageState, checked_all_document_route: value};
            const arrayRoute = this.routingSheets;
            const newArrayRoute = arrayRoute.map((obj) => {
                const checkCoordinateForInitPoint =
                    obj.initial_route_point?.coordinate?.lat !== "" &&
                    obj.initial_route_point?.coordinate?.lat !== null &&
                    obj.initial_route_point?.coordinate?.lon !== "" &&
                    obj.initial_route_point?.coordinate?.lon !== null;
                if (
                    obj.all_coordinates_present &&
                    Object.keys(obj.initial_route_point).length !== 0 &&
                    checkCoordinateForInitPoint
                ) {
                    if (value) {
                        this.selectedDocumentArray.push(obj.id);
                    } else {
                        this.selectedDocumentArray = this.selectedDocumentArray.filter(
                            (doc) => doc !== obj.id
                        );
                    }

                    obj.checked = Boolean(value);
                }
                return obj;
            });
            this.routingSheets = newArrayRoute;

            this.showCurrentAmountDocument();
        }
    }

    /**
     * Выбирает или отменяет выбор всех документов заказ для заданного идентификатора маршрута документов
     *
     * @param {boolean} value - Значение для установки выбора (true для выбора, false для отмены выбора)
     * @param {number} documentRouteId - Идентификатор маршрута документов
     */
    selectAllDocumentOrder(value, documentRouteId) {
        this.pageState = {...this.pageState, checked_all_document_order: value};

        const arrayRoute = this.routingSheets;
        const newArrayRoute = arrayRoute.map((obj) => {
            if (obj.document_id === documentRouteId) {
                obj.order_list.map((objOrder) => {
                    objOrder.checked = value;
                    return objOrder;
                });
            }
            return obj;
        });

        this.routingSheets = newArrayRoute;
    }

    /**
     * Обновляет свойство checked документа в массиве routingSheets.
     *
     * @param {boolean} value - Новое значение для свойства checked
     * @param {number} documentId - ID документа, который нужно обновить
     * @return {void}
     */
    selectDocumentRoute(value, documentId) {
        const newArrayRout = this.routingSheets.map((obj) => {
            if (
                documentId === obj.document_id &&
                Object.keys(obj.initial_route_point).length === 0
            ) {

                toastMassage(toast.error, "Не указана начальная точка маршрута. Укажите ее в глобальных настройках или в индивидуальных к нужному документу!", 10000, "bottom-right")
            }
            const checkCoordinateForInitPoint =
                obj.initial_route_point?.coordinate?.lat !== "" &&
                obj.initial_route_point?.coordinate?.lat !== null &&
                obj.initial_route_point?.coordinate?.lon !== "" &&
                obj.initial_route_point?.coordinate?.lon !== null;
            if (documentId === obj.document_id && !checkCoordinateForInitPoint) {
                toastMassage(toast.error, "У начальной точки нет координат!", 10000, "bottom-right")
            }

            if (
                documentId === obj.document_id &&
                Object.keys(obj.initial_route_point).length !== 0 &&
                checkCoordinateForInitPoint
            ) {
                if (value) {
                    this.selectedDocumentArray.push(obj.id);
                } else {
                    this.selectedDocumentArray = this.selectedDocumentArray.filter(
                        (doc) => doc !== obj.id
                    );
                }

                return {...obj, checked: value}; // Создаем новый объект с обновленным свойством checked
            }
            return obj;
        });

        this.routingSheets = newArrayRout;

        if (
            this.tableFilterRouteSheetsState.select_filter === true &&
            this.tableFilterRouteSheetsState.selected_filters.length > 0
        ) {
            this.selectFilterAndUpdateDocList("UpdateDocList");
        } else if (this.pageState.table_search_value !== "") {
            this.changeSearchAddress(this.pageState.table_search_value);
        } else {
            this.showCurrentAmountDocument();
        }
    }

    async createOptimizedRouteObjectForAPI(uploadDate, axiosInstance) {

        if (!this.routingSheets) {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
        }

        if (!this.routingSheets.some((objOrder) => objOrder.checked)) {
            toastMassage(toast.error, "Выберите хотя бы один документ из списка, перед оптимизацией маршрута!", 10000, "bottom-right")
            return;
        }

        if (
            this.routingSheets.some(
                (objOrder) => objOrder.checked && objOrder.in_route
            )
        ) {
            toastMassage(toast.error, "Документы которые в маршруте нельзя отправлять на оптимизацию!", 10000, "bottom-right")
            return;
        }

        // const routingSheets = this.routingSheets.filter(setting => setting.all_coordinates_present && setting.checked && (Object.keys(setting.initial_route_point).length !== 0));
        const routingSheets = this.correctingSettingRoutingSheets();
        const generalSettings = this.updateCostingOptionAtOptimizationRoute();

        const data = {
            typeAction: "optimizedRoute",
            arrayDoc: routingSheets,
            generalSettings,
        };

        const buildingRoute = toast.loading(
            "Документы отправлены на оптимизацию!",
            {
                position: "bottom-right",
            }
        );

        await axiosInstance.post(`/uploadTransportOrderFromWebService`, data,
        )
            .then(response => {
                if (response.status === 200) {
                    toastMassage(toast.success, "Оптимизация по выбранным документам выполнена!", 2500, "top-center")
                    this.upLoadDocumentRouteSheet(uploadDate, axiosInstance);
                } else {
                    toastMassage(toast.error, "Ошибка в оптимизации маршрута", 10000, "bottom-right")
                }
            })
            .catch(error => {
                toastMassage(toast.error, "Ошибка в оптимизации маршрута", 10000, "bottom-right")
                console.error('Ошибка:', error);
            }).finally(() => {
                toast.dismiss(buildingRoute);
            });

    }

    updateCostingOptionAtOptimizationRoute() {
        let generalSetting =
            settingOptimizationRouteSheetStore.generalSettingForDocuments;
        const {costing_options, costing} = generalSetting;
        const valueShortest =
            generalSetting.optimization_parameters_checked.optimization_for_distance;

        return {
            ...generalSetting,
            costing_options: {
                ...costing_options,
                [costing]: {
                    ...costing_options[costing],
                    shortest: valueShortest,
                },
            },
        };
    }

    async getRouteSheetById(
        UID,
        docId,
        authTokens,
        setUser,
        setAuthTokens,
        logoutUser
    ) {
        let userAccessToken;
        await globalState
            .useLogoutUserAfterTimeToken(
                authTokens,
                setUser,
                setAuthTokens,
                logoutUser
            )
            .then((tokens) => (userAccessToken = tokens));

        const headers = {
            Authorization: `Bearer ${userAccessToken}`,
        };

        await axios
            .get(
                `${process.env.REACT_APP_LOGISTICS_SERVICE}/getTransportOrderById/document_id=${docId}&id=${UID}`,
                {headers}
            )
            .then((res) => {
                setTimeout(() => {
                    settingOptimizationRouteSheetStore.editedRouteSheet = res.data;
                    settingOptimizationRouteSheetStore.editedRouteSheetCopy = res.data;
                    settingOptimizationRouteSheetStore.changeSettingDocument(
                        res.data.document_id
                    );
                }, 300);
            })
            .catch((error) => {
                console.error(error);
            });
    }


    async createBuildingRouteObjectForAPI(uploadDate, axiosInstance) {
        if (!this.routingSheets) {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
        }

        if (!this.routingSheets.some((objOrder) => objOrder.checked)) {
            toastMassage(toast.error, "Выберите хотя бы один документ из списка, перед построением маршрута!", 10000, "bottom-right")
            return;
        }

        if (
            this.routingSheets.some(
                (objOrder) => objOrder.checked && objOrder.in_route
            )
        ) {
            toastMassage(toast.error, "Документы которые в маршруте нельзя отправлять на построение маршрута!", 10000, "bottom-right")
            return;
        }


        if (this.pageState.unload_time === "") {
            toastMassage(toast.error, "Время на выгрузку товара должно обязательно быть заполенным!", 10000, "bottom-right")
            return;
        }

        const routingSheets = this.correctingSettingRoutingSheets();
        const generalSettings = settingOptimizationRouteSheetStore.generalSettingForDocuments;

        const data = {
            typeAction: "buildingRoute",
            arrayDoc: routingSheets,
            generalSettings,
            unload_time: this.pageState.unload_time,
        };

        await axiosInstance.post(`/uploadTransportOrderFromWebService`, data)
            .then(response => {
                if (response.status === 200) {
                    toastMassage(toast.success, "Построение маршрутов по выбранным документам выполнена!", 2500, "top-center")
                    this.upLoadDocumentRouteSheet(uploadDate, axiosInstance);
                } else {
                    toastMassage(toast.error, "Ошибка в построении маршрута", 10000, "bottom-right")
                }
            })
            .catch(error => {
                toastMassage(toast.error, "Ошибка в построении маршрута", 10000, "bottom-right")
                console.error('Ошибка:', error);
            });
    }

    async createBuildingAndOptimizationRouteObjectForAPI(uploadDate, axiosInstance) {


        if (!this.routingSheets.some((objOrder) => objOrder.checked)) {
            toastMassage(toast.error, "Выберите хотя бы один документ из списка, перед оптимизацией и построением маршрута!", 10000, "bottom-right")
            return;
        }

        if (
            this.routingSheets.some(
                (objOrder) => objOrder.checked && objOrder.in_route
            )
        ) {
            toastMassage(toast.error, "Документы которые в маршруте нельзя отправлять на оптимизацию или построение маршрута!", 10000, "bottom-right")
            return;
        }

        if (this.pageState.unload_time === "") {
            toastMassage(toast.error, "Время на выгрузку товара должно обязательно быть заполенным!", 10000, "bottom-right")
            return;
        }

        const routingSheets = this.correctingSettingRoutingSheets();
        const generalSettings = settingOptimizationRouteSheetStore.generalSettingForDocuments;

        const data = {
            typeAction: "buildingAndOptimizationRoute",
            arrayDoc: routingSheets,
            generalSettings,
            unload_time: this.pageState.unload_time,
        };

        toast.loading(
            "Документы отправлены на оптимизацию и построение маршрута!",
            {
                duration: 2500,
                position: "top-center",
            }
        );

        await axiosInstance.post(`/uploadTransportOrderFromWebService`, data)
            .then(response => {
                if (response.status === 200) {
                    toastMassage(toast.success, "Построение маршрутов по выбранным документам выполнена!", 2500, "top-center")
                    this.upLoadDocumentRouteSheet(uploadDate, axiosInstance);
                } else {
                    toastMassage(toast.error, "Ошибка в построении маршрута", 10000, "bottom-right")
                }
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    }

    correctingSettingRoutingSheets() {
        const generalSettings =
            settingOptimizationRouteSheetStore.generalSettingForDocuments;
        const routingSheetsList = this.routingSheets.filter(
            (setting) =>
                setting.all_coordinates_present &&
                setting.checked &&
                Object.keys(setting.initial_route_point).length !== 0
        );
        return routingSheetsList.map((objDoc) => {
            console.log(objDoc.document_number);

            // Проверяем, что дата назначения не установлена и отсутствуют настройки маршрутизации
            if (
                generalSettings.destinationDateTime.date === null &&
                Object.keys(objDoc.routing_settings).length === 0
            ) {
                const deliveryDate = objDoc.delivery_date;
                console.log("true 1");

                // Обновляем generalSettingForDocuments
                settingOptimizationRouteSheetStore.generalSettingForDocuments = {
                    ...generalSettings,
                    destinationDateTime: {
                        date: deliveryDate,
                        type: 1,
                        valueType: "отправления",
                        time: deliveryDate.slice(11),
                    },
                };
                // Обновляем объект date_time в objDoc
                objDoc.date_time = {
                    type: 1,
                    value: objDoc.delivery_date,
                };
            } else if (
                generalSettings.destinationDateTime.date === null &&
                Object.keys(objDoc.routing_settings).length !== 0
            ) {
                // Проверяем, что дата назначения не установлена, но присутствуют настройки маршрутизации

                const newDateStr = this.getParamDateTimeForBuildRoute(
                    objDoc.routing_settings
                );
                console.log(newDateStr, "true 2");

                objDoc.delivery_date = newDateStr;

                objDoc.date_time = {
                    type: objDoc.routing_settings.destinationDateTime.type,
                    value: newDateStr,
                };
            } else if (
                generalSettings.destinationDateTime.date !== null &&
                Object.keys(objDoc.routing_settings).length !== 0
            ) {
                // Проверяем, что дата назначения установлена и присутствуют настройки маршрутизации

                const generalDateStr =
                    this.getParamDateTimeForBuildRoute(generalSettings);
                const documentDateStr = this.getParamDateTimeForBuildRoute(
                    objDoc.routing_settings
                );
                console.log(documentDateStr, "true 3");
                if (documentDateStr === objDoc.delivery_date) {
                    objDoc.delivery_date = generalDateStr;
                    objDoc.routing_settings.destinationDateTime =
                        generalSettings.destinationDateTime;

                    objDoc.date_time = {
                        type: generalSettings.destinationDateTime.type,
                        value: generalDateStr,
                    };
                } else {
                    objDoc.delivery_date = documentDateStr;
                    objDoc.date_time = {
                        type: objDoc.routing_settings.destinationDateTime.type,
                        value: documentDateStr,
                    };
                }
            } else if (
                generalSettings.destinationDateTime.date !== null &&
                Object.keys(objDoc.routing_settings).length === 0
            ) {
                // Проверяем, что дата назначения установлена и отсутствуют настройки маршрутизации
                console.log("true 4");
                const generalDateStr =
                    this.getParamDateTimeForBuildRoute(generalSettings);
                objDoc.delivery_date = generalDateStr;
                objDoc.date_time = {
                    type: generalSettings.destinationDateTime.type,
                    value: generalDateStr,
                };
            }

            return objDoc;
        });
    }

    getParamDateTimeForBuildRoute(paramsSettings) {
        const currentDate = new Date();
        const timezoneOffset = -currentDate.getTimezoneOffset() / 60;
        const date = new Date(paramsSettings.destinationDateTime.date);
        const hours = parseInt(
            paramsSettings.destinationDateTime.time.split(":")[0]
        );
        const minutes = parseInt(
            paramsSettings.destinationDateTime.time.split(":")[1]
        );
        date.setHours(hours, minutes);
        date.setHours(date.getHours() + timezoneOffset);

        return date.toISOString().substring(0, 16);
    }

    async updateAddressCoordinateOnOrders(uploadDate, axiosInstance) {

        const data = {
            update_date: uploadDate,
        };

        toast.loading("Документы отправлены на корректировку адресов.", {
            duration: 1500,
            position: "top-center",
        });

        await axiosInstance.post(`/updateAddressCoordinatesOnOrders/`, data,)
            .then(async response => {
                response.status === 200 && await this.upLoadDocumentRouteSheet(uploadDate, axiosInstance)
                response.status === 200 && toastMassage(toast.success, "Координаты адресов были успешно скорректированы!", 1500, "top-center")
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    }

    async unloadRouteSheetsInMobileApp(uploadDate, axiosInstance) {

        if (!this.routingSheets.some((objOrder) => objOrder.checked)) {
            toastMassage(toast.error, "Выберите хотя бы один документ из списка, перед выгрузкой в мобильное приложение!", 10000, "bottom-right")
            return;
        }

        const data = {
            documents_uid: this.generateArrayUIDRouteSheets(this.routingSheets),
            unload_time: this.pageState.unload_time
        };


        await axiosInstance.post(`/unloadRouteSheetsInMobileApp/`, data)
            .then(async response => {
                response.status === 200 && await this.upLoadDocumentRouteSheet(uploadDate, axiosInstance)
                response.status === 200 && toastMassage(toast.success, "Документы выгружены в мобильное приложение!", 1500, "top-center")

            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    }

    generateArrayUIDRouteSheets(documents) {
        let arrayDocsUid = [];

        for (const objDoc of documents) {
            if (
                objDoc.checked &&
                // objDoc.route_optimization &&
                // objDoc.route_built &&
                !objDoc.in_route
            ) {
                // Документ выбран, оптимизирован, построен
                arrayDocsUid.push(objDoc.document_id);
            }
            // else if (
            //     objDoc.checked &&
            //     (!objDoc.route_optimization || !objDoc.route_built)
            // ) {
            //     // Документ выбран, не оптимизирован, не построен
            //     toastMassage(toast.error, `Маршрут документа № ${objDoc.document_number} должен быть оптимизирован и построен перед выгрузкой в мобильное приложение!`, 10000, "bottom-right")
            // }
            else if (
                objDoc.checked &&
                // objDoc.route_optimization &&
                // objDoc.route_built &&
                objDoc.in_route
            ) {
                // Документ выбран, оптимизирован, построен, уже в маршруте (МП)
                toastMassage(toast.error, `Документ № ${objDoc.document_number} уже выгружался в мобильное приложение!`, 10000, "bottom-right")
            }
        }

        return arrayDocsUid;
    }

    showInformationBlock(text, typeAlert) {
        this.pageState.show_info_block = true;
        this.pageState.text_info_block = text;
        this.pageState.type_info_block = typeAlert;

        setTimeout(() => {
            this.pageState.show_info_block = false;
            this.pageState.text_info_block = null;
        }, 2000);
    }

    // Изменяем показатель pag, который означается какая сейчас страница выбрана пользователем
    handleChangePage = (newPage) => {
        this.paginationState.page = newPage;

        this.showCurrentAmountDocument();
    };

    // Изменяем показатель rowsPerPage, который отвечает за количество отображаемых документов на странице
    handleChangeRowsPerPage = (rowsPerPage) => {
        if (rowsPerPage != null) {
            this.paginationState.rowsPerPage = rowsPerPage;
            this.paginationState.page = 1;

            this.showCurrentAmountDocument();
        }
    };

    // В копию списка документом для отображения записываем измененное количество документов
    showCurrentAmountDocument() {
        if (
            !this.tableFilterRouteSheetsState.select_filter &&
            this.copyRoutingSheets != null
        ) {
            const startIndex =
                (this.paginationState.page - 1) * this.paginationState.rowsPerPage;
            const endIndex = startIndex + this.paginationState.rowsPerPage;

            if (this.pageState.table_search_value === "") {
                this.copyRoutingSheets = this.routingSheets.slice(startIndex, endIndex);
            } else {
                this.copyRoutingSheets = this.copyRoutingSheets.slice(
                    startIndex,
                    endIndex
                );
            }
        } else if (
            this.tableFilterRouteSheetsState.select_filter &&
            this.filteredArrayDoc != null
        ) {
            this.paginationState.page = 1;
            const startIndex =
                (this.paginationState.page - 1) * this.paginationState.rowsPerPage;
            const endIndex = startIndex + this.paginationState.rowsPerPage;

            if (this.pageState.table_search_value !== "") {
                this.changeSearchAddress(this.pageState.table_search_value);
                this.copyRoutingSheets = this.copyRoutingSheets.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.copyRoutingSheets = this.filteredArrayDoc.slice(
                    startIndex,
                    endIndex
                );
            }
        }
    }

    // Открытие всплывающего окна, для выбора начального пункта
    openPopupInitPoints(userAccessToken, scrollY, typePopup) {
        this.pageState.popup_scroll_position = scrollY;
        this.pageState.popup_type = typePopup;
        this.pageState.popup_show = true;
        if (typePopup === "selectDrivers_EditDoc") {
            settingOptimizationRouteSheetStore.uploadPopupDriversList(
                userAccessToken
            );
        } else {
            settingOptimizationRouteSheetStore.uploadPopupInitPointList(
                userAccessToken
            );
        }
    }

    // Закрытие всплывающего окна для выбора начального пунка
    closePopupInitPoints() {
        this.pageState.popup_show = false;
        this.pageState.popup_type = null;
    }

    /**
     * Устанавливает глобальный начальный пункт для всех документов.
     *
     * @param {object} pointData - объект, содержащий информацию о начальном пункте
     */
    setGlobalInitialPoint(pointData) {
        // Проверка: если нет списка документов, ничего не делаем
        if (this.routingSheets === null) {
            return;
        }

        // Скрытие всплывающего окна
        this.pageState.popup_show = false;

        // Обновление общей настройки (только имя)
        settingOptimizationRouteSheetStore.generalSettingForDocuments.initial_point_route =
            pointData.initial_route_point_name;

        // Обновление начальных пунктов документов
        this.routingSheets = this.routingSheets.map((objDoc) => {
            // Создание объекта начального пункта
            objDoc.initial_route_point = {
                address: pointData.address,
                name: pointData.initial_route_point_name,
                coordinate: {
                    lat: pointData.lat,
                    lon: pointData.lon,
                },
            };

            // Возврат обновленного документа
            return objDoc;
        });
    }

    /**
     * Устанавливает пользовательский начальный пункт для документа.
     *
     * @param {object} pointData - объект, содержащий информацию о начальном пункте
     */
    setCustomInitialPointForDocument(pointData) {
        // Проверка: если нет настроек для документов, ничего не делаем
        if (settingOptimizationRouteSheetStore.settingForDocuments === null) {
            return;
        }

        // Скрытие всплывающего окна
        this.pageState.popup_show = false;

        // Сохранение пользовательского начального пункта
        settingOptimizationRouteSheetStore.editedRouteSheet.initial_route_point = {
            address: pointData.address,
            name: pointData.initial_route_point_name,
            coordinate: {
                lat: pointData.lat,
                lon: pointData.lon,
            },
        };
    }

    setCustomDeliveryForDocument(deliveryData) {
        // Проверка: если нет настроек для документов, ничего не делаем
        if (settingOptimizationRouteSheetStore.settingForDocuments === null) {
            return;
        }

        const {drivers_info} =
            settingOptimizationRouteSheetStore.editedRouteSheet;

        // Скрытие всплывающего окна
        this.pageState.popup_show = false;

        settingOptimizationRouteSheetStore.editedRouteSheet.drivers_info =
            drivers_info.map((objDriverInf) => {
                if (objDriverInf.forwarder === false) {
                    objDriverInf.driver_name = deliveryData.driver_name;
                    objDriverInf.driver_uid = deliveryData.driver_id;
                }
                return objDriverInf;
            });

        // Сохранение пользовательского начального пункта
        // settingOptimizationRouteSheetStore.editedRouteSheetCopy.initial_route_point = {
        //     address: pointData.address,
        //     name: pointData.initial_route_point_name,
        //     coordinate: {
        //         lat: pointData.lat,
        //         lon: pointData.lon,
        //     },
        // };
    }

    changeNewUnloadTime(value) {
        this.pageState.unload_time = value;
    }

    selectFilterAndUpdateDocList(selectFilter) {
        this.tableFilterRouteSheetsState.select_filter = true;

        if (selectFilter !== "UpdateDocList") {
            const foundFilter =
                this.tableFilterRouteSheetsState.selected_filters.find((obj, index) => {
                    if (obj.filter_name === selectFilter.filter_name) {
                        this.tableFilterRouteSheetsState.selected_filters[index] =
                            selectFilter;
                        return true;
                    }
                    return false;
                });

            if (!foundFilter) {
                this.tableFilterRouteSheetsState.selected_filters.push(selectFilter);
            }
        }

        const allNoFilter = this.tableFilterRouteSheetsState.selected_filters.every(
            (obj) => obj.filter_text === "без фильтра"
        );

        if (allNoFilter) {
            this.tableFilterRouteSheetsState.select_filter = false;
        }

        let firstFilter = true;
        this.copyRoutingSheets = this.routingSheets.slice();
        for (const selectFilter of this.tableFilterRouteSheetsState
            .selected_filters) {
            if (selectFilter.filter_text !== "без фильтра") {
                if (firstFilter) {
                    this.filteredArrayDoc = this.copyRoutingSheets.filter((objDoc) => {
                        if (selectFilter.filter_name === "driver_name") {
                            return (
                                objDoc.drivers_info[0].driver_name === selectFilter.filter_value
                            );
                        } else {
                            return (
                                objDoc[selectFilter.filter_params_name] ===
                                selectFilter.filter_value
                            );
                        }
                    });

                    firstFilter = false;
                }

                this.filteredArrayDoc = this.filteredArrayDoc.filter((objDoc) => {
                    if (selectFilter.filter_name === "driver_name") {
                        return (
                            objDoc.drivers_info[0].driver_name === selectFilter.filter_value
                        );
                    } else {
                        return (
                            objDoc[selectFilter.filter_params_name] ===
                            selectFilter.filter_value
                        );
                    }
                });
            }
        }

        if (!this.tableFilterRouteSheetsState.select_filter) {
            this.selectSortedDocList("routingSheets");
        } else if (this.routeListTableSortState.sort_value !== 0) {
            this.selectSortedDocList("filteredArrayDoc");
        } else if (this.pageState.table_search_value !== "") {
            this.changeSearchAddress(this.pageState.table_search_value);
        } else {
            this.showCurrentAmountDocument();
        }
    }

    changeCreateMobAuthData() {
        this.pageState.createMobAuthData = !this.pageState.createMobAuthData;
    }

    changeSortCollTableRouteList(sortName) {
        const {sort_value, sort_name} = this.routeListTableSortState;

        if (sortName !== sort_name) {
            this.routeListTableSortState.sort_value = 1;
        } else {
            if (this.routeListTableSortState.sort_value < 2) {
                this.routeListTableSortState.sort_value = Number(sort_value) + 1;
            } else {
                this.routeListTableSortState.sort_value = 0;
            }
        }

        this.routeListTableSortState.sort_name = sortName;
    }

    selectSortedDocList(sortedArrName) {
        const {sort_value, sort_name} = this.routeListTableSortState;

        if (
            this.tableFilterRouteSheetsState.select_filter &&
            this.filteredArrayDoc != null
        ) {
            sortedArrName = "filteredArrayDoc";
        } else if (this.pageState.table_search_value !== "") {
            sortedArrName = "copyRoutingSheets";
        }

        if (sort_value === 0) {
            // Восстанавливаем исходный порядок значений
            if (this.tableFilterRouteSheetsState.select_filter) {
                this.selectFilterAndUpdateDocList("UpdateDocList");
            }
        } else if (sort_value === 1) {
            // Cортируем по возрастанию
            if (sort_name === "document_date" || sort_name === "delivery_date") {
                this[sortedArrName] = this[sortedArrName].sort(
                    (a, b) => new Date(a[sort_name]) - new Date(b[sort_name])
                );
            } else {
                this[sortedArrName] = this[sortedArrName].sort(
                    (a, b) => a[sort_name] - b[sort_name]
                );
            }
        } else if (sort_value === 2) {
            // Cортируем по убыванию
            if (sort_name === "document_date" || sort_name === "delivery_date") {
                this[sortedArrName] = this[sortedArrName].sort(
                    (a, b) => new Date(b[sort_name]) - new Date(a[sort_name])
                );
            } else {
                this[sortedArrName] = this[sortedArrName].sort(
                    (a, b) => b[sort_name] - a[sort_name]
                );
            }
        }

        this.showCurrentAmountDocument();
    }

    changeSearchAddress(newSearValue) {
        if (this.pageState.table_search_value === "") {
            if (this.tableFilterRouteSheetsState.select_filter) {
                this.selectFilterAndUpdateDocList("UpdateDocList");
            } else {
                this.copyRoutingSheets = this.routingSheets.slice();
            }
        }

        this.pageState.table_search_value = newSearValue;
        const regex = new RegExp(
            `^.*${newSearValue.split(" ").join(".*")}.*$`,
            "i"
        );

        if (
            this.tableFilterRouteSheetsState.select_filter &&
            this.filteredArrayDoc != null
        ) {
            this.copyRoutingSheets = this.filteredArrayDoc.filter(
                (docObj) =>
                    regex.test(docObj.document_number.toString()) ||
                    regex.test(docObj.vehicle_number.toString()) ||
                    regex.test(docObj.drivers_info[0]?.driver_name.toString())
            );
        } else {
            this.copyRoutingSheets = this.routingSheets.filter(
                (docObj) =>
                    regex.test(docObj.document_number.toString()) ||
                    regex.test(docObj.vehicle_number.toString()) ||
                    regex.test(docObj.drivers_info[0]?.driver_name.toString())
            );
        }
    }
}

export default new optimizationRouteSheetStore();
