import React, {useEffect, useState} from 'react';
import style from './ModalEditRouteSheet.module.css'
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {observer} from "mobx-react-lite";
import ModalEditViewRouteSheet from "../ModalEditViewRouteSheet/ModalEditViewRouteSheet";
import routeParameterModalHOC from "../hoc/RouteParameterModalHOC";
import ModalSelectedInitialPoint from "../ModalSelectedInitialPoint/ModalSelectedInitialPoint";
import ModalSelectedDriver from "../ModalSelectedDriver/ModalSelectedDriver";
import ModalSelectedVehicle from "../ModalSelectedVehicle/ModalSelectedVehicle";
import ModalRouteSheetAskDelete from "../ModalRouteSheetAskDelete/ModalRouteSheetAskDelete";

const ModalSelectedInitialPointHOC = routeParameterModalHOC(
    ModalSelectedInitialPoint,
    "changeInitPoint",
    "edit"
);

const ModalSelectedDriverHOC = routeParameterModalHOC(
    ModalSelectedDriver,
    "changeDriver",
    "edit"
);

const ModalSelectedVehicleHOC = routeParameterModalHOC(
    ModalSelectedVehicle,
    "changeVehicle",
    "edit"
)

const ModalEditRouteSheet = observer(() => {
    const [modalViewContent, setModalViewContent] = useState(null);
    const {changeInitPoint, changeDriver, changeVehicle, changeDeleteRoute} = createRouteSheetsStore.creatingRouteSheetsModalState

    useEffect(() => {
        setModalViewContent(<ModalEditViewRouteSheet/>)

        return () => {
            createRouteSheetsStore.changeDefaultEditRouteData()
        }

    }, []);

    useEffect(() => {
        if (changeInitPoint) {
            setModalViewContent(<ModalSelectedInitialPointHOC/>)
        } else if (changeDriver) {
            setModalViewContent(<ModalSelectedDriverHOC/>)
        } else if (changeVehicle) {
            setModalViewContent(<ModalSelectedVehicleHOC/>)
        } else if (changeDeleteRoute){
            setModalViewContent(<ModalRouteSheetAskDelete/>)
        } else {
            setModalViewContent(<ModalEditViewRouteSheet/>)
        }
    }, [changeInitPoint, changeDriver, changeVehicle, changeDeleteRoute]);

    return (
        <div className={style.modalContent}>
            {modalViewContent}
        </div>
    );
});

export default ModalEditRouteSheet;