import React, { useCallback, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormGroup,
  IconButton,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Search from "../../ui/Search/Search";
import DistributorReatailDocsList from "../DistributorReatailDocsList/DistributorReatailDocsList";

// Фильтр для поиска
function filterChainsTable(data, searchText) {
  const lowerSearchText = searchText.toLowerCase();
  return data.filter((item) => {
    const itemName = item.counteragentName.toLowerCase();
    return itemName.includes(lowerSearchText);
  });
}

const DistributorRetailChainsTable = ({
  handleNomenclature,
  getCheckedIds,
  setValueId,
  valueId,
  activeRetail,
  setActiveRetail,
  items,
  distr,
  setItems,
}) => {
  const [searchComplete, setSearchComplete] = useState("");

  const checkAllChecked = (outletsList) =>
    outletsList.every((check) => check.checked);

  const countCheckedOutlets = (outletsList) =>
    checkAllChecked(outletsList) ? outletsList.length : 0;

  // const [selectSeeAll, setSelectSeeAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  // Выбрать все торговые сети
  const handleSelectAll = useCallback(() => {
    const allSelected = items.every((section) =>
      section.retailOutletsList.every((retail) => retail.checked)
    );

    setItems((prevItems) =>
      prevItems.map((section) => ({
        ...section,
        retailOutletsList: section.retailOutletsList.map((retail) => ({
          ...retail,
          checked: !allSelected,
        })),
      }))
    );

    setValueId(null);
    setSelectAll(!selectAll);
  }, [items, setItems, setValueId, selectAll]);

  // Выбрать конкретную торговую сеть
  const handleSelectLocal = useCallback(
    (retailOutletsList) => {
      const allChecked = retailOutletsList.every((retail) => retail.checked);
      setItems((prevItems) =>
        prevItems.map((section) => ({
          ...section,
          retailOutletsList: section.retailOutletsList.map((retail) => ({
            ...retail,
            checked: retailOutletsList.includes(retail)
              ? !allChecked
              : retail.checked,
          })),
        }))
      );
    },
    [setItems]
  );

  //   Проверка выбрано ли все
  const getCheckedAll = useCallback((data) => {
    return data.flatMap((item) =>
      (item.retailOutletsList || []).filter((outlet) => outlet.checked)
    );
  }, []);

  const getChecked = useCallback((data) => {
    return data.flatMap((item) =>
      item.retailOutletsLists.filter((outlet) => outlet)
    );
  }, []);
  const onCheckHandle = useCallback(
    (checkedItem) => {
      setItems((prevItems) =>
        prevItems.map((doc) => {
          const newRetailOutletsList = doc.retailOutletsList.map((retail) => {
            if (retail.id === checkedItem.id) {
              return {
                ...retail,
                checked: !retail.checked,
              };
            }
            return retail;
          });
          return { ...doc, retailOutletsList: newRetailOutletsList };
        })
      );
    },
    [setItems]
  );
  return (
    <>
      {activeRetail ? (
        <DistributorReatailDocsList distr={distr} retailId={activeRetail} />
      ) : (
        <>
          <Box sx={{ width: "40%", overflow: "hidden" }}>
            <TableContainer sx={{ height: "73vh" }}>
              <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        sx={{ marginLeft: "10px" }}
                        control={
                          <Checkbox
                            edge="start"
                            checked={
                              getCheckedAll(items).length === items.length
                            }
                            onChange={handleSelectAll}
                            disableRipple
                          />
                        }
                        label="Все"
                      />
                    </TableCell>
                    <TableCell >
                      <Search
                        searchComplete={searchComplete}
                        setSearchComplete={setSearchComplete}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterChainsTable(items, searchComplete).map((section) => (
                    <TableRow
                      onClick={() =>
                        handleSelectLocal(section.retailOutletsList)
                      }
                      key={section.id}
                      className="hoverable-row"
                      // onClick={() => handleClick(section)}
                    >
                      <TableCell>
                        <Badge
                          sx={{ marginLeft: "5px" }}
                          badgeContent={countCheckedOutlets(
                            section.retailOutletsList
                          )}
                          color="success"
                        >
                          <Checkbox
                            sx={{ marginLeft: "5px" }}
                            edge="start"
                            checked={checkAllChecked(section.retailOutletsList)}
                            // onChange={() =>
                            //   handleSelectLocal(section.retailOutletsList)
                            // }
                            disableRipple
                          />
                        </Badge>
                      </TableCell>
                      <TableCell sx={{ fontSize: "80%" }}>
                        {section.counteragentName}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box sx={{ width: "60%", overflow: "hidden" }}>

      
            
          
            <TableContainer sx={{ height: "73vh" }}>
              <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                <TableRow>
              <TableCell>
                <Search 
                  searchComplete={searchComplete}
                  setSearchComplete={setSearchComplete}
                />
              </TableCell>
              <TableCell colSpan={3}>
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  onClick={handleNomenclature}
                  disabled={getCheckedIds(items).length === 0}
                >
                  Показать номенклатуру
                </Button>
              </TableCell>
            </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Наименование
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>ИНН</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Адрес</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getCheckedAll(items).map((retailOutlet) => (
                    <TableRow key={retailOutlet.id}>
                      <TableCell>
                        <FormControlLabel
                          sx={{ marginLeft: "10px", margin: 0 }}
                          control={
                            <Checkbox
                              edge="start"
                              checked={retailOutlet.checked}
                              onChange={() => onCheckHandle(retailOutlet)}
                              disableRipple
                            />
                          }
                          label={retailOutlet.name}
                        />
                      </TableCell>
                      <TableCell>{retailOutlet.inn}</TableCell>
                      <TableCell>{retailOutlet.address}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => setActiveRetail(retailOutlet)}
                        >
                          <DocumentScannerIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default DistributorRetailChainsTable;
