import React, {useContext} from 'react';
import style from './DeliveryVehiclesTableItemList.module.css'
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {ReactComponent as SettingIcon} from '../../../../imges/SettingIcon.svg';
import {Link, useParams} from "react-router-dom";
import pageDeliveryVehiclesStore
    from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
import GlobalContext from "../../../../context/GlobalContext";

const DeliveryVehiclesTableItemList = ({vehData}) => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);

    const deleteVehicle = async () => {
        await pageDeliveryVehiclesStore.deleteVehicleById(vehData.id, authTokens, setUser, setAuthTokens, logoutUser);
    }

    const changeSelectVehicle = (vehId, newValue) => {
        pageDeliveryVehiclesStore.changeSelectVehicle(vehId, newValue);
    }

    return (
        <div className={style.templateListTableHeader}>
            <div>
                <input
                    type="checkbox"
                    className={style.selectAllTemplate}
                    id={`selectVehicle_${vehData.id}`}
                    name={`selectVehicle_${vehData.id}`}
                    checked={vehData?.checked}
                    value={vehData?.checked}
                    onChange={(event) => {
                        event.stopPropagation();
                        changeSelectVehicle(vehData.id,!vehData?.checked)
                    }}
                />
                <label htmlFor={`selectVehicle_${vehData.id}`}></label>
            </div>
            <div>{vehData?.appellation}</div>
            <div>
                <div>{vehData?.license_plate}</div>
                <div>{vehData?.vehicle_brand}</div>
            </div>
            <div>{vehData?.tonnage} т.</div>
            <div><DeleteIcon
                onClick={() => deleteVehicle()}
            /></div>
            <div><Link to={`/logisticService/vehicleList/editVehicle/${vehData?.id}`}><SettingIcon/></Link></div>
        </div>
    );
};

export default DeliveryVehiclesTableItemList;