import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import "./ProfileSettingsPassword.css";

const steps = [
  "Введите старый пароль",
  "Введите новый пароль",
  "Введите новый пароль еще раз",
  "Отправить",
];

export default function ProfileSettingsPassword() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const { apiPasswordVerification, apiNewPassword } = useAxiosAPI();

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setError(false);
    // Шаг назад
    if (activeStep === completedSteps()) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      const newCompleted = { ...completed };
      delete newCompleted[activeStep];
      setCompleted(newCompleted);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const [error, setError] = useState(false);
  const passwordRegex = /^.{8,}$/;

  const handleComplete = () => {
    setError(false);
    // Следующий шаг
    const newCompleted = { ...completed };
    if (activeStep === 0) {
      apiPasswordVerification(oldPassword)
        .then(() => {
          setError(false);
          newCompleted[activeStep] = true;
          setCompleted(newCompleted);
          handleNext();
        })
        .catch(() => {
          setError(true);
        });
    } else if (activeStep === 1) {
      if (passwordRegex.test(newPassword)) {
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      } else {
        setError(true);
      }
    } else if (activeStep === 2) {
      if (newPassword === checkNewPassword) {
        setError(false);
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      } else {
        setError(true);
      }
    } else if (activeStep === 3) {
      apiNewPassword(oldPassword, newPassword)
        .then(() => {
          setError(false);
          newCompleted[activeStep] = true;
          setCompleted(newCompleted);
          handleNext();
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);

  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleInputChangeOldPassword = (event) => {
    const inputValue = event.target.value;
    setOldPassword(inputValue);
  };

  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleInputChangeNewPassword = (event) => {
    const inputValue = event.target.value;
    setNewPassword(inputValue);
  };

  const [checkNewPassword, setCheckNewPassword] = useState("");
  const [showCheckNewPassword, setShowCheckNewPassword] = useState(false);

  const toggleShowCheckNewPassword = () => {
    setShowCheckNewPassword(!showCheckNewPassword);
  };

  const handleInputCheckNewPassword = (event) => {
    const inputValue = event.target.value;
    setCheckNewPassword(inputValue);
  };

  const handleCompletePost = () => {
    // Post запрос на изменение пароля
    console.log(oldPassword, newPassword);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel
              icon={
                index === activeStep && error ? (
                  <ReportProblemIcon color="error" />
                ) : null
              }
            >
              {label}
              <Typography color="error">
                {index === 0 && error && index === activeStep
                  ? "Неверный пароль"
                  : index === 1 && error && index === activeStep
                  ? "Не менее 8 символов*"
                  : index === 2 && error && index === activeStep
                  ? "Пароль не совпадает!"
                  : index === 3 && error && index === activeStep
                  ? "Ошибка!"
                  : null}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: "50%", fontSize: "1rem" }}>Смена пароля:</TableCell>
            <TableCell sx={{ width: "50%" }}>
              <Box className={"passwordBox"}>
                {allStepsCompleted() ? (
                  <Box>
                    <Box className={"massageCompl"}>
                      <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
                        Пароль успешно изменен{" "}
                        <VerifiedUserIcon color="success" />
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>В начало</Button>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                   
                    {/* <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel
              icon={
                index === activeStep && error ? (
                  <ReportProblemIcon color="error" />
                ) : null
              }
            >
              {label}
              <Typography color="error">
                {index === 0 && error && index === activeStep
                  ? "Неверный пароль"
                  : index === 1 && error && index === activeStep
                  ? "Не менее 8 символов*"
                  : index === 2 && error && index === activeStep
                  ? "Пароль не совпадает!"
                  : index === 3 && error && index === activeStep
                  ? "Ошибка!"
                  : null}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper> */}
                    <>
                      {activeStep === 0 ? (
                        <Box className={"inputBox"}>
                            <Typography>
                            Введите старый пароль
                            </Typography>
                          <input
                            className={"passwordNew"}
                            type={showOldPassword ? "text" : "password"}
                            value={oldPassword}
                            onChange={handleInputChangeOldPassword}
                            placeholder="Старый пароль"
                          />
                          {activeStep === 0 && (
                            <IconButton
                              onClick={toggleShowOldPassword}
                              edge="end"
                              aria-label="toggle password visibility"
                            >
                              {showOldPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          )}
                        </Box>
                      ) : null}

                      {activeStep === 1 ? (
                        <Box className={"inputBox"}>
                            <Typography>
                            Введите новый пароль
                            </Typography>
                          <input
                            disabled={
                              activeStep === 2 || activeStep === 3
                                ? true
                                : false
                            }
                            className={"passwordNew"}
                            type={showNewPassword ? "text" : "password"}
                            value={newPassword}
                            onChange={handleInputChangeNewPassword}
                            placeholder="Новый пароль"
                          />
                          {activeStep === 1 && (
                            <IconButton
                              onClick={toggleShowNewPassword}
                              edge="end"
                              aria-label="toggle password visibility"
                            >
                              {showNewPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          )}
                        </Box>
                      ) : null}

                      {activeStep === 2 ? (
                        <Box className={"inputBox"}>
                            <Typography>
                            Введите новый пароль еще раз
                            </Typography>
                          <input
                            disabled={activeStep === 3 ? true : false}
                            className={"passwordNew"}
                            type={showCheckNewPassword ? "text" : "password"}
                            value={checkNewPassword}
                            onChange={handleInputCheckNewPassword}
                            placeholder="Повторите пароль"
                          />
                          {activeStep === 2 && (
                            <IconButton
                              onClick={toggleShowCheckNewPassword}
                              edge="end"
                              aria-label="toggle password visibility"
                            >
                              {showCheckNewPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          )}
                        </Box>
                      ) : null}

                      {activeStep === 3 ? (
                        <Box className={"inputBox"}>
                          <Typography>Изменить пароль ?</Typography>
                        </Box>
                      ) : null}
                    </>

                    <Box sx={{ display: "flex" }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Назад
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStep === 3 ? (
                        <Button onClick={handleComplete}>Да</Button>
                      ) : (
                        <Button onClick={handleComplete}>Далее</Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* <Box className={"passwordBox"}> 
         123
      </Box> */}
    </Box>
  );
}
