import React, {memo, useState} from 'react';
import style from './CreateRouteSheetsOrderListAreaList.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {observer} from "mobx-react-lite";
import Skeleton from "@mui/material/Skeleton";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import CreateRouteSheetsOrderListAreaHeaderList
    from "../CreateRouteSheetsOrderListAreaHeaderList/CreateRouteSheetsOrderListAreaHeaderList";


const CreateRouteSheetsOrderListAreaListSkeleton = observer(() => {


    return (
        <>
            <div className={style.popupHeader}>
                Список зон
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup()
                    }}
                ><DeleteIcon/></div>
            </div>
            <div
                id={"searchInputZone"}
                className={style.inputSearchNameZona}>
                <input
                    type="text"
                    maxLength={50}
                    value={""}
                    disabled={true}
                    placeholder="Поиск по наименованию"
                />
                <span>Очистить</span>
            </div>

            <div className={style.actionBtns}>
                <button className={style.showSelectedAreaBtn}>
                    Показать выбранные
                </button>
                <button className={style.cancelAskBtn}
                        onClick={() => createRouteSheetsStore.closePopup()}
                >
                    Отмена
                </button>
            </div>
            <CreateRouteSheetsOrderListAreaHeaderList/>
            <div>
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"100%"}
                    height={150}
                />
            </div>
        </>
    );
});

export default memo(CreateRouteSheetsOrderListAreaListSkeleton);