import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Modal from '@mui/material/Modal';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import * as React from 'react';
import { useContext, useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import {
    Avatar,
    Button,
    CardContent,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {generateLocalColorFromText} from "../../helpers/helpers";
import Paper from "@mui/material/Paper";
import Search from "../ui/Search/Search";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 470,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingTop: 1,
    paddingBottom: 6,
};


function filterDistributor(data, searchText) {
    return data.filter((item) => {
        const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
        const itemInn = item.inn.toLowerCase(); // Получаем поле "article" объекта
        return itemName.includes(searchText.toLowerCase()) || itemInn.includes(searchText.toLowerCase());
    });
}


export default function ProfileDistributorMenuNomen(props) {
    const {apiCheckRelativeManDistrNom} = useAxiosAPI()
    const handleClose = () => props.setOpen(false);
    const distributors = props.distributors;
    const [currentPage, setCurrentPage] = React.useState(1); // Current page number
    const itemsPerPage = 10; // Number of items to display per page
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const [searchComplete, setSearchComplete] = useState("")

    const handlePost = (activ) => {
        props.setActivDistributor(activ.name)
        apiCheckRelativeManDistrNom(activ.id)
        .then((result) => {
            handleClose()
            props.setDataNumDistributor(result.data);

        })
        .catch(() => {
            // setLoader(false);
        });

    };
  

    return (
        <Paper sx={{width: "100%", borderRadius: 0, boxShadow: 0, maxHeight: "calc(84vh - 16px)"}}>
            <Box sx={{margin: "10px"}}>
            <Search
                searchComplete={searchComplete}
                setSearchComplete={setSearchComplete}
            />
            </Box>
        <TableContainer sx={{ padding: 0, maxHeight: "calc(84vh - 16px)" }}>

                    <Table stickyHeader aria-label="table in dashboard">
                        <TableHead>
                            <TableRow sx={{ fontWeight: 800 }}>
                                <TableCell
                                    sx={{
                                        fontWeight: 700,
                                        backgroundColor: "rgb(250, 250, 250)",
                                    }}
                                    colSpan={2}
                                >
                                    <IconButton sx={{marginRight: "10px"}} onClick={() => props.setOpen(false)}>
                                        <ArrowCircleLeftIcon color="primary"/>
                                    </IconButton>
                                    Дистрибьютор
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: 700,
                                        backgroundColor: "rgb(250, 250, 250)",
                                    }}
                                    align="left"
                                >
                                    Почта
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: 700,
                                        backgroundColor: "rgb(250, 250, 250)",
                                    }}
                                    align="left"
                                >
                                    ИНН
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterDistributor(distributors, searchComplete)
                                .slice(
                                    (currentPage - 1) * itemsPerPage,
                                    currentPage * itemsPerPage
                                )
                                .map((row) => (
                                    <React.Fragment key={row.id}>
                                        <TableRow
                                            onClick={() =>
                                                handlePost(row)
                                            }
                                            // onClick={() => props.setActiveString(row)}
                                            className="hoverable-row"
                                        >
                                            <TableCell sx={{ paddingRight: 0, width: "1vw" }}>
                                                <Avatar
                                                    sx={{
                                                        bgcolor: generateLocalColorFromText(row.name),
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {" "}
                                                    {row.name[0]}
                                                </Avatar>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                sx={{ paddingLeft: 0 }}
                                                scope="row"
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.mail == "" ? "Отсутствует" : row.mail}
                                            </TableCell>
                                            <TableCell align="left">{row.inn}</TableCell>

                                        </TableRow>
                                    </React.Fragment>
                                ))}
                        </TableBody>
                    </Table>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1vw",
                        }}
                    >
                        <Pagination
                            sx={{ marginTop: "1vw" }}
                            color="primary"
                            shape="rounded"
                            count={Math.ceil(distributors.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </div>
                </TableContainer>
        </Paper>

    );
}
