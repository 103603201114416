import React from 'react';
import ModalMaster from "../ModalMaster/ModalMaster";
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import style from './DeleteConfirmationPopup.module.css'

const DeleteConfirmationPopup = (({isOpen, onClose, scrollPosition, askText = "", onSuccess}) => {
    return (
        <ModalMaster
            isOpen={isOpen}
            onClose={onClose}
            scrollPosition={scrollPosition}
        >
            <div className={style.popupHeader}>
                Уточнение
                <div
                    className={style.btnClosePopup}
                    onClick={onClose}
                ><DeleteIcon/></div>
            </div>
            <div className={style.askText}>{askText}</div>
            <div className={style.actionAskBtns}>
                <button
                    className={style.acceptAskBtn}
                    onClick={onSuccess}
                >Подтвердить
                </button>
                <button
                    className={style.cancelAskBtn}
                    onClick={onClose}
                >Отменить
                </button>
            </div>
        </ModalMaster>
    );
});

export default DeleteConfirmationPopup;