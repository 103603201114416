import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import savpMenuLogisticOption
    from "../../../../store/transportationLogisticsService/savpMenuLogisticOption/savpMenuLogisticOption";
import style from './PopupSideMenuLogistic.module.css'
import "./PopupSideMenuLogistic.css"
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

const PopupSideMenuLogistic = observer(({onClose, children}) => {
    const {
        selectedSubNavId,
        positionSubRightMenu,
        selectedSubNavData,
    } = savpMenuLogisticOption.menuLogisticState;
    const rightMenuRef = useRef(document.getElementById(selectedSubNavId));
    const rootElementRef = useRef(document.getElementById("root"));
    const menuRef = useRef(null);


    const setListRef = useCallback(node => {
        if (node !== null){
            menuRef.current = node;
            rightMenuRef.current = document.getElementById(selectedSubNavId);
            if (menuRef.current){
                const rect = rightMenuRef.current.getBoundingClientRect();
                const menu = menuRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                if (menu.bottom > windowHeight) {
                    const newPositionY = windowHeight - menu.height;
                    savpMenuLogisticOption.changeNewPositionSubRightMenu({x: rect.left, y: newPositionY - 5});
                } else {
                    savpMenuLogisticOption.changeNewPositionSubRightMenu({x: rect.left, y: rect.top});
                }
            }
        }
    },[selectedSubNavId])

    useEffect(() => {
        disableScroll();
        return () => {
            rootElementRef.current.classList.remove(style.noScroll);
        };
    }, []);

    const disableScroll = (() => {
        rootElementRef.current.classList.add(style.noScroll);
    });

    const popupContent = (
        <div className={style.popupOverlay} onClick={onClose}>
            <div ref={setListRef} style={{top: positionSubRightMenu.y, left: 210}} className={style.popupContent}
                 onClick={(e) => e.stopPropagation()}>
                <>
                    <>
                        {
                            selectedSubNavData.map((subNav) => {
                                return <Link key={uuidv4()}
                                             onClick={() => {
                                                 savpMenuLogisticOption.changeDefaultStateRightMenu()
                                                 savpMenuLogisticOption.changeSubRightMenu(false)
                                             }}
                                    to={subNav.urlName}>{subNav.fulName}</Link>
                            })
                        }
                    </>
                </>
            </div>
        </div>
    );

    return ReactDOM.createPortal(popupContent, document.getElementById('portal_menu'));
});

export default memo(PopupSideMenuLogistic);