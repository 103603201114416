import React, {useState} from 'react';
import "./ListOfDocumentsOfRouteSheets.module.css"
import style from './ListOfDocumentsOfRouteSheets.module.css';
import DocumentRouteList from "../../DocumentRouteList/DocumentRouteList";
import {observer} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import {ReactComponent as ImgNoneData} from '../../../../imges/noneData.svg';
import CustomisationRouteList from "../CustomisationRouteList/CustomisationRouteList";
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import RouteSheetsTableHeader from "../RouteSheetsTableHeader/RouteSheetsTableHeader";
import RotatingLoadIcon from "../../../RotatingLoadIcon/RotatingLoadIcon";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "../../../../utils/PrivateRoute";
import GeneralRouteOptimizationSettings from "../GeneralRouteOptimizationSettings/GeneralRouteOptimizationSettings";
import SkeletonEditRouteSheet from "../SkeletonEditRouteSheet/SkeletonEditRouteSheet";

const ListOfDocumentsOfRouteSheets = observer(() => {

    const {routingSheets, copyRoutingSheets} = optimizationRouteSheetStore;
    const {setting_document} = optimizationRouteSheetStore.pageState


    return (
        <div className={style.documentBlock}>
            <div className={style.selectDocumentList}>

                <Routes>
                    <Route path='routeSheetsList/:uploadDate?'
                           element={
                               <PrivateRoute>
                                   <RouteSheetsTableHeader/>
                                   {
                                       (routingSheets) ?
                                           copyRoutingSheets.map(obj => {
                                               return <DocumentRouteList
                                                   key={uuidv4()}
                                                   uid={uuidv4()}
                                                   documentData={obj}
                                                   idDocument={obj.document_id}
                                               />
                                           }) :
                                           <div className={style.noDataImg}>
                                               <ImgNoneData/>
                                               <div>Нет документов за выбранную дату</div>
                                           </div>

                                   }
                               </PrivateRoute>
                           }
                    />
                </Routes>

                <Routes>
                    <Route path='editRouteSheets/:docId?/:UID?'
                           element={
                               <PrivateRoute>
                                   <CustomisationRouteList/>
                               </PrivateRoute>
                           }
                    />
                </Routes>

            </div>
        </div>
    );
});

export default ListOfDocumentsOfRouteSheets;