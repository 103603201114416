import {makeAutoObservable} from "mobx";


class MapData_DeliveryArea{
    mapData = null

    constructor() {
        makeAutoObservable(this,)
    }

    setMapData(mapData) {
        this.mapData = mapData;
    }
}

export default new MapData_DeliveryArea();

