import React, {memo, useContext} from 'react';
import style from './RouteButtonGroup.module.css';
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import {ReactComponent as MapLegendIcon} from '../../../../imges/MapLegendIcon.svg';
import {ReactComponent as MapControlIcon} from '../../../../imges/MapControlIcon.svg';
import {ReactComponent as MapRouteIcon} from '../../../../imges/MapRouteIcon.svg';
import GlobalContext from "../../../../context/GlobalContext";
import {useNavigate, useParams} from "react-router-dom";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";

const RouteButtonGroup = () => {
    const axiosInstance = AxiosConfig_LogisticService();
    const {uploadDate} = useParams();

    return (
        <div className={style.accessButtonGroup}>
            <button
                onClick={async () => await optimizationRouteSheetStore.createOptimizedRouteObjectForAPI(uploadDate, axiosInstance)}>
                <MapControlIcon/>
                Оптимизировать маршрут
            </button>
            <button
                onClick={async () => await optimizationRouteSheetStore.createBuildingRouteObjectForAPI(uploadDate, axiosInstance)}>
                <MapRouteIcon/>
                Построить маршрут
            </button>
            <button
                onClick={async () => {
                    await optimizationRouteSheetStore.createBuildingAndOptimizationRouteObjectForAPI(uploadDate, axiosInstance)
                }}
            >
                <MapLegendIcon/>
                Оптимизировать и построить маршрут
            </button>
        </div>
    );
};

export default memo(RouteButtonGroup);