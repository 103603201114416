import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './DeliveryVehiclesCreate.module.css'
import {Navigate, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import GlobalContext from "../../../../context/GlobalContext";
import DeliveryVehiclesCreateForm from "../DeliveryVehiclesCreateForm/DeliveryVehiclesCreateForm";
import pageDeliveryVehiclesStore
    from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";

const DeliveryVehiclesCreate = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            pageDeliveryVehiclesStore.changeDefaultValue_Create();
        }
    }, [])

    const createNewTemplate = async (e) => {
        e.preventDefault()
        await pageDeliveryVehiclesStore.createNewVehicle(authTokens, setUser, setAuthTokens, logoutUser,navigate);
    }

    return (
        <div className={style.createTemplate}>
            <div className={style.createTemplateHeader}>
                <div className={style.goBackToList}
                     onClick={() => navigate(-1)}>Назад
                </div>
                Создание карточки транспортного средства
            </div>


            <form action="" onSubmit={(e) => createNewTemplate(e)}>
                <DeliveryVehiclesCreateForm/>

                <div className={style.actionTemplateBtns}>
                    <input
                        type="submit"
                        value="Создать"
                        className={style.createTemplateBtn}
                    />
                    {/*Создать*/}
                    {/*</input>*/}
                    <button
                        className={style.cancelCreateTemplateBtn}
                        onClick={() => navigate(-1)}
                    >Отменить
                    </button>
                </div>
            </form>

        </div>
    );
});

export default memo(DeliveryVehiclesCreate);