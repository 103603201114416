import React, {memo} from 'react';
import deliversRoutes from "../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import {observer} from "mobx-react-lite";
import style from './DriverMessagesInfo.module.css'
import {v4 as uuidv4} from "uuid";

const DriverMessagesInfo = observer(() => {
    const {
        delivery_message, order_number, order_client, delivered_status
    } = deliversRoutes.selectedRouteLoc;

    const getStartCurrentDate = (dateValue) => {
        let date = new Date(dateValue);

        // Форматирование даты и времени в формат "dd.mm.yyyy hhч mmм"
        return date.getDate().toString().padStart(2, '0') + '.' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
            date.getFullYear() + ' ' +
            date.getHours().toString().padStart(2, '0') + 'ч ' +
            date.getMinutes().toString().padStart(2, '0') + 'м';
    }


    return (
        <div className={style.driverInfBlock}>
            {
                delivery_message.map(messageObj => {
                    if (messageObj.delivery_status_info !== ""){
                        return <div key={uuidv4()} className={style.driverMessage}>
                            <div>Номер заказа: {order_number}</div>
                            <div>Клиент: {order_client}</div>
                            <div>Статус заказа: {messageObj.delivery_status}</div>
                            <div className={style.comment}>Комментарий: {messageObj.delivery_status_info}</div>
                            <div>{getStartCurrentDate(messageObj.delivery_status_time)}</div>
                        </div>

                    }
                })
            }
        </div>
    );
});

export default memo(DriverMessagesInfo);