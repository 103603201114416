import { useCallback, useContext } from "react";
import globalContext from "../context/GlobalContext";
import GlobalContext from "../context/GlobalContext";
import useAxios from "../utils/useAxios";
import { dateConvertSelection, transformArray } from "../helpers/helpers";

export const useAxiosAPI = () => {
  const {
    dispatch,
    state,
    distributorStartDate,
    distributorFinishDate,
    setExcelData,
    authTokens,
  } = useContext(GlobalContext);
  const { searchName } = useContext(globalContext);
  const api = useAxios();
  return {
    // ЗАПРОСЫ ПРОИЗВОДИТЕЛЬ //

    // Запрос за производителем
    apiManufacturer: useCallback(() => {
      api
        .get(`Manufacturer`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: "manufacturer", dataJSON: response.data });
          } else {
            // Обработка ошибки
            console.error(
              "Ошибка при запросе за производителем:",
              response.statusText
            );
          }
        })
        .catch((error) => {
          // Обработка ошибки
          console.error(
            "Ошибка при выполнении запроса за производителем:",
            error
          );
        });
    }, [dispatch]),

    // Запрос за дистрибьюторами
    apiDistributor: useCallback(() => {
      api
        .get(`Distributers?distribNamePart=${searchName}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: "distributors", dataJSON: response.data });
          } else {
            // Обработка ошибки
            console.error(
              "Ошибка при запросе за дистрибьюторами:",
              response.statusText
            );
          }
        })
        .catch((error) => {
          // Обработка ошибки
          console.error(
            "Ошибка при выполнении запроса за дистрибьюторами:",
            error
          );
        });
    }, [dispatch, searchName]),

    // Запрос за суммой по документам
    apiDocsSum: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          api

            .get(
              `doctablesSums?dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
            )
            .then((response) => {
              if (response.status === 200) {
                // console.log(response.data)
                resolve(response);
                dispatch({
                  type: "docsSum",
                  dataJSON: response.data,
                  idDistrib: id,
                });
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе за суммой номенклатуры по документам:",
                  response.statusText
                );
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса за суммой номенклатуры по документам:",
                error
              );
            });
        });
      },
      [dispatch, distributorStartDate && distributorFinishDate]
    ),

    // Запрос за торговыми точками для карты
    apiMapMarker: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(
            `GetDocRetOutlet?dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(
              distributorFinishDate
            )}&type=districts`
          )
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
            } else {
              // Обработка ошибки
              console.error(
                "Ошибка при запросе за суммой номенклатуры по документам:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за суммой номенклатуры по документам:",
              error
            );
          });
      });
    }, [dispatch, distributorStartDate, distributorFinishDate]),

    // Запрос за торговыми точками для карты интерфейс дистрибьютора
    apiDistributorMapMarker: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(
            `DistrGetDocRetOutlet?dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(
              distributorFinishDate
            )}&type=districts`
          )
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
            } else {
              // Обработка ошибки
              console.error(
                "Ошибка при запросе за суммой номенклатуры по документам:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за суммой номенклатуры по документам:",
              error
            );
          });
      });
    }, [dispatch, distributorStartDate, distributorFinishDate]),

    // Запрос за регистром выгрузок
    apiRegisterDates: useCallback(
      (id) => {
        api
          .get(`uploadsRegisterById?id=${id}`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "registerDates",
                dataJSON: response.data,
                idDistrib: id,
              });
            } else {
              console.error(
                "Ошибка при запросе за регистром выгрузок:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за регистром выгрузок:",
              error
            );
          });
      },
      [dispatch]
    ),

    // Запрос за списком контрагентов (Р. Торговые точки)
    apiCounteragentsAndRetailOutlets: useCallback(
      (id) => {
        api
          .get(
            `GetCounteragentsAndRetailOutlets?distrId=${id}&dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "counteragentsAndRetailOutlets",
                dataJSON: response.data,
                idDistrib: id,
              });
            } else {
              console.error(
                "Ошибка при запросе за counteragentsAndRetailOutlets:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за counteragentsAndRetailOutlets:",
              error
            );
          });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    // Запрос за списком торговые точки (Р. Торговые точки)
    apiGetRetailOutletsAndDistrNoms: useCallback(
      (id, retailOutlets, all) => {
        return new Promise((resolve, reject) => {
          api
            .post(
              `GetRetailOutletsAndDistrNoms?distrId=${id}&dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(
                distributorFinishDate
              )}&all=${all}`,
              { retOutsIds: retailOutlets }
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    apiGetRetailOutlets: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          api
            .get(
              `RetailOutletsSums?distrId=${id}&dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    apiGetRetailDocs: useCallback(
      (distr, retailId) => {
        return new Promise((resolve, reject) => {
         
          api
            .get(
              `RetailDocs?distrId=${distr}&dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(distributorFinishDate)}&retOutId=${retailId.id}`
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),
    // Запрос за списком номенклатуры (Р. SKU)
    apiGetRetailOutletsByNomSums: useCallback(
      (distrID, id, retail) => {
        return new Promise((resolve, reject) => {
          api
            .post(
              `GetRetailOutletsByNomSums?distrId=${distrID}&dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(
                distributorFinishDate
              )}&distrNomId=${id}`,
              { retailOutlets: retail }
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    // Запрос за списком Торговых точек на основании номенклатуры (Р. SKU)
    apiGetAllManDistrNomSums: useCallback(
      (id) => {
        api
          .get(
            `GetAllManDistrNomSums?distrId=${id}&dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "sku",
                dataJSON: response.data,
                idDistrib: id,
              });
            } else {
              console.error("Ошибка при запросе за sku:", response.statusText);
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error("Ошибка при выполнении запроса за sku:", error);
          });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    // Запрос за списокм актуальных остатков номенклатуры по конкретному дистрибьютору с учетом периода (Р. Остатки)
    apiDistributorCurrentRemains: useCallback(
      (id) => {
        api
          .get(
            `GetRemainsById?distrId=${id}&dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "distributorCurrentRemains",
                idDistrib: id,
                dataJSON: transformArray(response.data),
              });
            } else {
              console.error(
                "Ошибка при запросе за списокм актуальных остатков по конкретному дистрибьютору с учетом периода:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм актуальных остатков по конкретному дистрибьютору с учетом периода:",
              error
            );
          });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    // Запрос за списокм истории остатков по конкретной номенклатуре и дистрибьютору с учетом периода (Р. Остатки)
    apiDistributorHistoryRemains: useCallback(
      (idRemains, idNom, dateRemains) => {
        api
          .get(`GetRegisterDistrRemainsById?date=${dateRemains}&nomId=${idNom}`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "distributorHistoryRemains",
                idRemains: idRemains,
                dataJSON: response.data,
              });
            } else {
              console.error(
                "Ошибка при запросе за списокм истории остатков по конкретному дистрибьютору с учетом периода:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм истории остатков по конкретному дистрибьютору с учетом периода:",
              error
            );
          });
      },
      [dispatch]
    ),

    // Запрос за списокм дистрибьюторов производителя (Р. Профиль/Дистрибьюторы)
    apiDistributorUsers: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`distributorlists`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({ type: "distributorUsers", dataJSON: response.data });
              resolve(response);
            } else {
              reject(response.statusText);

              console.error(
                "Ошибка при запросе за списокм дистрибьюторов производителя:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    apiManufacturerUsers: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`GetSubManUsers`)
          .then((response) => {
            if (response.status === 200) {
              // dispatch({ type: "distributorUsers", dataJSON: response.data });
              resolve(response);
            } else {
              reject(response.statusText);

              console.error(
                "Ошибка при запросе за списокм дистрибьюторов производителя:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    // Запрос за списокм номенклатуры производителя (Р. Профиль/Номенклатура)
    apiManufacturerNomenclature: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`NomenclatMan`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "manufacturerNomenclature",
                dataJSON: response.data,
              });
              resolve();
            } else {
              reject(response.statusText);
              console.error(
                "Ошибка при запросе за списокм номенклатуры производителя:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм номенклатуры производителя:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    // Post запрос на указание количества дней выгрузки для дистрибьютора (Р. Профиль/Дистрибьюторы)
    apiLimitDays: useCallback(
      (limit, arrId) => {
        return new Promise((resolve, reject) => {
          api
            .post(`daysLimitMass?daysLimit=${limit}`, arrId)
            .then((response) => {
              if (response.status === 200) {
                resolve();
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    // Post запрос на указание региона для дистрибьютора (Р. Профиль/Дистрибьюторы)
    apiPatchDistribList: useCallback(
      (dataDistrUser, id) => {
        console.log(`PatchDistribList?dListId=${id}`, dataDistrUser);
        return new Promise((resolve, reject) => {
          api
            .patch(`PatchDistribList?dListId=${id}`, dataDistrUser)
            .then((response) => {
              if (response.status === 200) {
                resolve(); // Разрешение обещания после успешного получения данных
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание региона:",
                  response.statusText
                );
                reject(response.statusText); // Отклонение обещания в случае ошибки
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание региона:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiPatchManufacturerList: useCallback(
      (dataManufacturerUser, id) => {
        return new Promise((resolve, reject) => {
          api
            .patch(`PatchSubManUser?userId=${id}`, dataManufacturerUser)
            .then((response) => {
              if (response.status === 200) {
                resolve(); // Разрешение обещания после успешного получения данных
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание региона:",
                  response.statusText
                );
                reject(response.statusText); // Отклонение обещания в случае ошибки
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание региона:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    // Запрос на проверку пароля для изменения (Р. Профиль)
    apiPasswordVerification: useCallback(
      (password) => {
        return new Promise((resolve, reject) => {
          api
            .post(`manOldPassCheck`, {
              oldPassword: password,
            })
            .then((response) => {
              if (response.status === 200) {
                resolve();
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    // Запрос на указание нового пароля (Р. Профиль)
    apiNewPassword: useCallback(
      (oldPassword, newPassword) => {
        return new Promise((resolve, reject) => {
          api
            .patch(`manPassChange`, {
              oldPassword: oldPassword,
              newPassword: newPassword,
            })
            .then((response) => {
              if (response.status === 200) {
                resolve();
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    // Запрос на проверку сопоставления номенклатуры дистрибьютора с номенклатурой производителя (Р. Профиль/Номенклатура)
    apiCheckRelativeManDistrNom: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          // console.log(`CheckRelativeManDistrNom?distrId=${id}`)
          api
            .post(`CheckRelativeManDistrNom?distrId=${id}`)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiCheckRelativeDistrNom: useCallback(
      (inn) => {
        return new Promise((resolve, reject) => {
          // console.log(`CheckRelativeManDistrNom?distrId=${id}`)
          api
            .get(`DistrCheckRelativeNom?manInn=${inn}`)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),
    apiDistributorCheckRelativeManDistrNom: useCallback(
      (inn) => {
        return new Promise((resolve, reject) => {
          // console.log(`DistrCheckRelativeNom?manInn=${inn}`)
          api
            .get(`DistrCheckRelativeNom?manInn=${inn}`)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiDelete: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          api
            .delete(`DeleteSubManUser?userId=${id}`)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    // Запрос на подстановку сопоставления конкретной номенклатуры дистрибьютора с номенклатурой производителя (Р. Профиль/Номенклатура)
    apiManualManDistrNomSelectPatch: useCallback(
      (dataNom) => {
        return new Promise((resolve, reject) => {
          console.log(`ManualManDistrNomSelectPatch`, [dataNom]);
          api
            .patch(`ManualManDistrNomSelectPatch`, [dataNom])
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiRegistration: useCallback(
      (id, userObj) => {
        return new Promise((resolve, reject) => {
          console.log(`NewDistribUser?dListId=${id}`, userObj)
          api
            .post(`NewDistribUser?dListId=${id}`, userObj)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),


    apiRegistrationUserManufacturer: useCallback(
      (userObj) => {
        return new Promise((resolve, reject) => {
          api
            .post(`AddNewSubManUser`, userObj)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiRegistrationUserDistributor: useCallback(
      (userObj) => {
        return new Promise((resolve, reject) => {
          api
            .post(`NewSubDistrUser`, userObj)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе сапоставление:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса сапоставление:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiConstructorExel: useCallback(
      (
        action,
        distributorStartDateReportsStart,
        distributorFinishDateReportsStart,
        distributorStartDateReportsFinish,
        distributorFinishDateReportsFinish,
        data
      ) => {
        return new Promise((resolve, reject) => {
          api
            .post(
              `PivotTable?&dateBegin=${dateConvertSelection(
                distributorStartDateReportsStart
              )}&dateEnd=${dateConvertSelection(
                distributorFinishDateReportsStart
              )}&dateBeginSecond=${dateConvertSelection(
                distributorStartDateReportsFinish
              )}&dateEndSecond=${dateConvertSelection(
                distributorFinishDateReportsFinish
              )}`,
              data,
              { responseType: "arraybuffer" }
            )
            .then((response) => {
              if (response.status === 200) {
                // Получение данных из ответа
                const data = response.data;

                if (action === "excel") {
                  // Преобразование данных в Blob
                  const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });

                  // Создание URL для Blob
                  const url = URL.createObjectURL(blob);
                  // Создание элемента <a> для скачивания (как в вашем коде)
                  const downloadLink = document.createElement("a");

                  downloadLink.href = url;
                  downloadLink.download = "output.xlsx"; // Название файла для скачивания
                  downloadLink.style.display = "none";

                  // Добавление элемента <a> на страницу (как в вашем коде)
                  document.body.appendChild(downloadLink);

                  // Имитация клика по элементу <a> (как в вашем коде)
                  downloadLink.click();

                  // Освобождение ресурсов (как в вашем коде)
                  URL.revokeObjectURL(url);

                  // Удаление элемента <a> со страницы (как в вашем коде)
                  document.body.removeChild(downloadLink);

                  resolve(); // Разрешение обещания после завершения скачивания
                } else if (action === "json") {
                  setExcelData(data);
                  resolve(); // Разрешение обещания после успешного получения данных
                }
              } else {
                // Обработка ошибки
                alert("Пусто");
                console.error(
                  "Ошибка при загрузке файла:",
                  response.statusText
                );
                reject(response.statusText); // Отклонение обещания в случае ошибки
              }
            })
            .catch((error) => {
              alert("За данный период отсутствуют данные");

              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса для скачивания файла:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiPivotSave: useCallback(
      (data) => {
        return new Promise((resolve, reject) => {
          api
            .post(`PivotSave`, data)
            .then((response) => {
              resolve(response);
              return response.status;
            })
            .catch((error) => {
              alert("За данный период отсутствуют данные");

              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса для скачивания файла:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiPivotGet: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`PivotGet`)
          .then((response) => {
            resolve(response);
            return response.status;
          })
          .catch((error) => {
            alert("За данный период отсутствуют данные");

            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса для скачивания файла:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    apiPivotDelete: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          api
            .delete(`PivotDelete?id=${id}`)
            .then((response) => {
              resolve(response);
              return response.status;
            })
            .catch((error) => {
              // alert("За данный период отсутствуют данные")

              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса для скачивания файла:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiPivotRename: useCallback(
      (id, name) => {
        return new Promise((resolve, reject) => {
          api
            .patch(`PivotRename?id=${id}&name=${name}`)
            .then((response) => {
              resolve(response);
              return response.status;
            })
            .catch((error) => {
              // alert("За данный период отсутствуют данные")

              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса для скачивания файла:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

  
    // Запрос за дистрибьютором
    apiProfileDistributor: useCallback(() => {
      api
        .get(`DistribInfoCard`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: "profileDistributors", dataJSON: response.data });
          } else {
            // Обработка ошибки
            console.error(
              "Ошибка при запросе за дистрибьюторами:",
              response.statusText
            );
          }
        })
        .catch((error) => {
          // Обработка ошибки
          console.error(
            "Ошибка при выполнении запроса за дистрибьюторами:",
            error
          );
        });
    }, [dispatch]),

    // Запрос за списком производителей
    apiProfileManufacturersList: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`DistrMans`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "profileManufacturersList",
                dataJSON: response.data,
              });
              resolve();
            } else {
              reject(response.statusText);

              console.error(
                "Ошибка при запросе за списокм дистрибьюторов производителя:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    apiDistibutorNomenclature: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`DistrNoms`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "distibutorNomenclature",
                dataJSON: response.data,
              });
              resolve();
            } else {
              reject(response.statusText);
              console.error(
                "Ошибка при запросе за списокм номенклатуры дистрибьютора:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм номенклатуры дистрибьютора:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    apiProfileDistibutorRetail: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`DistribCounterparties`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "profileDistibutorRetail",
                dataJSON: response.data,
              });
              resolve();
            } else {
              reject(response.statusText);
              console.error(
                "Ошибка при запросе за списокм номенклатуры дистрибьютора:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм номенклатуры дистрибьютора:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    apiProfileDistibutorRetailString: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          api
            .get(`DistribRetOuts?counterpartyId=${id}`)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),
    apiProfileDistibutorRegisterDate: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`DistribUploadsRegister`)
          .then((response) => {
            
            if (response.status === 200) {
              dispatch({
                type: "profileDistibutorRegisterDate",
                dataJSON: response.data,
              });
              resolve();
            } else {
              reject(response.statusText);
              console.error(
                "Ошибка при запросе за списокм номенклатуры дистрибьютора:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм номенклатуры дистрибьютора:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    apiDocsSumDistributor: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          api

            .get(
              `DistrDoctablesSums?dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                dispatch({
                  type: "distributorDocsSum",
                  dataJSON: response.data,
                  idDistrib: id,
                });
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе за суммой номенклатуры по документам:",
                  response.statusText
                );
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса за суммой номенклатуры по документам:",
                error
              );
            });
        });
      },
      [dispatch, distributorStartDate && distributorFinishDate]
    ),

    // Запрос за списком контрагентов (Р. Торговые точки)
    apiDistributorCounteragentsAndRetailOutlets: useCallback(
      (id, inn) => {
        api
          .get(
            `DistrGetCounteragentsAndRetailOutlets?maninn=${inn}&dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "distributorCounteragentsAndRetailOutlets",
                dataJSON: response.data,
                idManufacturer: id,
              });
            } else {
              console.error(
                "Ошибка при запросе за counteragentsAndRetailOutlets:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за counteragentsAndRetailOutlets:",
              error
            );
          });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    // Запрос за списком торговые точки (Р. Торговые точки)
    apiDistributorGetRetailOutletsAndDistrNoms: useCallback(
      (inn, retailOutlets) => {
        return new Promise((resolve, reject) => {
          console.log(
            `DistrGetRetailOutletsAndDistrNoms?maninn=${inn}&dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(distributorFinishDate)}&all=false`
          );
          api
            .post(
              `DistrGetRetailOutletsAndDistrNoms?maninn=${inn}&dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(
                distributorFinishDate
              )}&all=true`,
              { retOutsIds: retailOutlets }
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    apiDistrRetailOutletsSums: useCallback(
      (id) => {
        return new Promise((resolve, reject) => {
          api
            .get(
              `DistrRetailOutletsSums?manId=${id}&dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),
    // Запрос за списком Торговых точек на основании номенклатуры (Р. SKU)
    apiDistributorGetAllManDistrNomSums: useCallback(
      (id, inn) => {
        api
          .get(
            `DistrGetAllManDistrNomSums?maninn=${inn}&dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "manufacturerSKU",
                dataJSON: response.data,
                idManufacturer: id,
              });
            } else {
              console.error("Ошибка при запросе за sku:", response.statusText);
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error("Ошибка при выполнении запроса за sku:", error);
          });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    // Запрос за списком номенклатуры (Р. SKU)
    apiDistrGetRetailOutletsByNomSums: useCallback(
      (distrID, id, retail) => {
        return new Promise((resolve, reject) => {
          api
            .post(
              `DistrGetRetailOutletsByNomSums?distrId=${distrID}&dateBegin=${dateConvertSelection(
                distributorStartDate
              )}&dateEnd=${dateConvertSelection(
                distributorFinishDate
              )}&distrNomId=${id}`,
              { retailOutlets: retail }
            )
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
                return response.status;
              } else {
                // Обработка ошибки
                console.error(
                  "Ошибка при запросе на указание количества дней выгрузки:",
                  response.statusText
                );
                reject(response.statusText);
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса на указание количества дней выгрузки:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),

    // Запрос за списокм актуальных остатков номенклатуры по конкретному дистрибьютору с учетом периода (Р. Остатки)
    apiManufacturerCurrentRemains: useCallback(
      (id, inn) => {
        api
          .get(
            `DistrGetRemainsById?manInn=${inn}&dateBegin=${dateConvertSelection(
              distributorStartDate
            )}&dateEnd=${dateConvertSelection(distributorFinishDate)}`
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "manufacturerCurrentRemains",
                idManufacturer: id,
                dataJSON: transformArray(response.data),
              });
            } else {
              console.error(
                "Ошибка при запросе за списокм актуальных остатков по конкретному дистрибьютору с учетом периода:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм актуальных остатков по конкретному дистрибьютору с учетом периода:",
              error
            );
          });
      },
      [dispatch, distributorStartDate, distributorFinishDate]
    ),
    apiManufacturerHistoryRemains: useCallback(
      (idRemains, idNom, dateRemains) => {
        api
          .get(
            `DistrGetRegisterDistrRemainsById?date=${dateRemains}&nomId=${idNom}`
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "manufacturerHistoryRemains",
                idRemains: idRemains,
                dataJSON: response.data,
              });
            } else {
              console.error(
                "Ошибка при запросе за списокм истории остатков по конкретному дистрибьютору с учетом периода:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм истории остатков по конкретному дистрибьютору с учетом периода:",
              error
            );
          });
      },
      [dispatch]
    ),

    apiAdminUsersList: useCallback(
      (typeUser) => {
        return new Promise((resolve, reject) => {
          api
            .get(`admin/GetOrganizations?level=${typeUser}`)
            .then((response) => {
              if (response.status === 200) {
                // dispatch({ type: "distributorUsers", dataJSON: response.data });
                resolve(response);
              } else {
                reject(response.statusText);

                console.error(
                  "Ошибка при запросе за списокм дистрибьюторов производителя:",
                  response.statusText
                );
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiAdminUsersPatchTariff: useCallback(
      (data) => {
        return new Promise((resolve, reject) => {
          api
            .patch(`admin/UpdateTariff`, [data])
            .then((response) => {
              if (response.status === 200) {
                // dispatch({ type: "distributorUsers", dataJSON: response.data });
                resolve(response);
              } else {
                reject(response.statusText);

                console.error(
                  "Ошибка при запросе за списокм дистрибьюторов производителя:",
                  response.statusText
                );
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiAdminUsersPatch: useCallback(
      (data) => {
        return new Promise((resolve, reject) => {
          api
            .patch(`admin/UpdateUsers`, [data])
            .then((response) => {
              if (response.status === 200) {
                // dispatch({ type: "distributorUsers", dataJSON: response.data });
                resolve(response);
              } else {
                reject(response.statusText);

                console.error(
                  "Ошибка при запросе за списокм дистрибьюторов производителя:",
                  response.statusText
                );
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiAdminCreateUsers: useCallback(
      (data) => {
        return new Promise((resolve, reject) => {
          console.log(data);
          api
            .post(`admin/CreateUser`, data)
            .then((response) => {
              if (response.status === 200) {
                // dispatch({ type: "distributorUsers", dataJSON: response.data });
                resolve(response);
              } else {
                reject(response.statusText);

                console.error(
                  "Ошибка при запросе за списокм дистрибьюторов производителя:",
                  response.statusText
                );
              }
            })
            .catch((error) => {
              // Обработка ошибки
              console.error(
                "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
                error
              );
              reject(error); // Отклонение обещания в случае ошибки
            });
        });
      },
      [dispatch]
    ),

    apiGetTarifsUser: useCallback(() => {
      return new Promise((resolve, reject) => {
        api
          .get(`Tarifs`)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: "subscriptions",
                dataJSON: response.data,
              });
            } else {
              reject(response.statusText);

              console.error(
                "Ошибка при запросе за списокм дистрибьюторов производителя:",
                response.statusText
              );
            }
          })
          .catch((error) => {
            // Обработка ошибки
            console.error(
              "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
              error
            );
            reject(error); // Отклонение обещания в случае ошибки
          });
      });
    }, [dispatch]),

    apiGetToken1C: useCallback(
        (id) => {
          return new Promise((resolve, reject) => {
            api
              .get(`admin/AdminUploadTokens?id=${id}`)
              .then((response) => {
                if (response.status === 200) {
                  // dispatch({ type: "distributorUsers", dataJSON: response.data });
                  resolve(response);
                } else {
                  reject(response.statusText);
  
                  console.error(
                    "Ошибка при запросе за списокм дистрибьюторов производителя:",
                    response.statusText
                  );
                }
              })
              .catch((error) => {
                // Обработка ошибки
                console.error(
                  "Ошибка при выполнении запроса за списокм дистрибьюторов производителя:",
                  error
                );
                reject(error); // Отклонение обещания в случае ошибки
              });
          });
        },
        [dispatch]
      ),
  };
};
