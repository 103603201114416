import List from '@mui/material/List';
import * as React from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Box, CircularProgress, Collapse, IconButton, Snackbar, TableContainer, Typography} from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {useState} from "react";
import {useAxiosAPI} from '../../../apiRequest/apiRequest';
import "./DistributorNomenclatureTable.css";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';
import Search from "../../ui/Search/Search";
import Divider from "@mui/material/Divider";
function filterNomenclatureTable(data, searchText) {
    return data.filter((item) => {
        const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
        const itemArticle = item.article.toLowerCase(); // Получаем поле "article" объекта
        const itemBrand = item.brand.toLowerCase(); // Получаем поле "article" объекта


        return itemName.includes(searchText.toLowerCase()) || itemArticle.includes(searchText.toLowerCase()) || itemBrand.includes(searchText.toLowerCase());
    });
}
function filterNomenclatureTableChild(data, searchText) {
    return data.filter((item) => {
        const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
        const itemInn = item.inn.toLowerCase(); // Получаем поле "article" объекта
        return itemName.includes(searchText.toLowerCase()) || itemInn.includes(searchText.toLowerCase());
    });
}

export default function DistributorNomenclatureTable({ docs, allSum, items, distr }) {
    const { apiGetRetailOutletsByNomSums } = useAxiosAPI();
    const [load, setLoad] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [searchComplete, setSearchComplete] = useState("");
    const [selectedId, setSelectedId] = React.useState(null); // Состояние для хранения id выбранного элемента
    const [sortBy, setSortBy] = useState(null); // Поле, по которому происходит сортировка
    const [sortOrder, setSortOrder] = useState('asc'); // Порядок сортировки: 'asc' (по возрастанию) или 'desc' (по убыванию)
    const [open, setOpen] = React.useState(false);
    const [searchCompleteChild, setSearchCompleteChild] = useState("");
    const [sortByChild, setSortByChild] = useState(null); // Поле, по которому происходит сортировка

    const [sortBrand, setSortBrand] = useState(false)
    const [sortQuantity, setSortQuantity] = useState(false)
    const [sortPrice, setSortPrice] = useState(false)
    const [sortSum, setSortSum] = useState(false)

    const [sortBrandChild, setSortBrandChild] = useState(false)
    const [sortQuantityChild, setSortQuantityChild] = useState(false)
    const [sortPriceChild, setSortPriceChild] = useState(false)
    const [sortSumChild, setSortSumChild] = useState(false)
    

    function sortItem (sortFunction, item, setParameter, parameter) {
        sortFunction(item)
        setParameter(!parameter)
    }


    function sortItemChild (sortFunction, item, setParameter, parameter) {
        sortFunction(item)
        setParameter(!parameter)
    }


    const toggleRow = (id) => {
        setLoad(true);
        // Сохраняем id выбранного элемента
        if (selectedId === id) {
            setSelectedId(null);
        } else {
            setSelectedId(id);
            fetchData(id);
        }
    };

    const fetchData = (id) => {
        apiGetRetailOutletsByNomSums(distr, id, items)
            .then((resolve) => {
                setData(resolve.data);
                setLoad(false);
            })
            .catch(() => {
                alert("Ошибка");
                setLoad(false);
            });
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const sortData = (field) => {
        const order = sortBy === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(field);
        setSortOrder(order);
        const sortedData = [...docs].sort((a, b) => {
            if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
            if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
            return 0;
        });
        setSearchResults(sortedData);
    };
    const sortTradePoints = (field) => {
        const order = sortByChild === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortByChild(field)
        setSortOrder(order);

        const sortedData = [...data].sort((a, b) => {
            if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
            if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
            return 0;
        });
        setData(sortedData);
    };
    const resultMerged = docs;
    const [searchResults, setSearchResults] = useState(resultMerged);

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
            <TableContainer sx={{ height: "68vh" }}>
                <Table stickyHeader aria-label="collapsible table" sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Search
                                    searchComplete={searchComplete}
                                    setSearchComplete={setSearchComplete}
                                />
                            </TableCell>
                            <TableCell sx={{ fontWeight: 700}} onClick={() => sortItem(sortData, 'brand', setSortBrand, sortBrand)}>
                                <div style={{display: "flex", cursor: 'pointer'}}>  <span>Бренд</span> {sortBy === "brand" && !sortBrand ? "↓" : null}</div>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>
                                Артикул
                            </TableCell>

                            <TableCell sx={{ fontWeight: 700}} onClick={() => sortItem(sortData, 'quantity', setSortQuantity, sortQuantity)}>
                                <div style={{display: "flex", cursor: 'pointer'}}>  <span>Количество</span> {sortBy === "quantity" && sortQuantity ?  "↓" : sortBy === "quantity" && !sortQuantity ? "↑" : null}</div>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 700 }} onClick={() => sortItem(sortData, 'price', setSortPrice, sortPrice)}>
                                <div style={{display: "flex", cursor: 'pointer'}}>  <span>Цена (руб.){sortBy === "price" && sortPrice ?  "↓" : sortBy === "price" && !sortPrice ? "↑" : null}</span></div>
                            </TableCell>
                            <TableCell sx={{fontWeight: 700}} onClick={() => sortItem(sortData, 'sum', setSortSum, sortSum)}>
                                <div style={{display: "flex", cursor: 'pointer'}}>
                                    <span> Сумма (руб.) {sortBy === "sum" && sortSum ?  "↓" : sortBy === "sum" && !sortSum ? "↑" : null}</span></div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Данная номенклатура не сопоставлена!
                        </Alert>
                    </Snackbar>
                    <TableBody>
                        {filterNomenclatureTable(searchResults, searchComplete).map((tag) => (
                            <>
                                <TableRow
                                    sx={{ backgroundColor: selectedId == tag.nomenclatureId ? "#a5ed9d" : "default" }}
                                    key={tag.nomenclatureId}>
                                    <TableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => tag.comparison ? toggleRow(tag.nomenclatureId) : handleClick()} // Передаем id элемента
                                        >
                                            {!tag.comparison ?
                                                <ErrorOutlineIcon color={"error"} />
                                                :
                                                selectedId == tag.nomenclatureId ?
                                                    <KeyboardArrowUpIcon sx={{ color: "#1976d0" }} /> :
                                                    <KeyboardArrowDownIcon sx={{ color: "#1976d0" }} />
                                            }
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{tag.name}</TableCell>
                                    <TableCell>{tag.brand}</TableCell>
                                    <TableCell>{tag.article}</TableCell>
                                    <TableCell>{tag.quantity} {tag.packing}</TableCell>
                                    <TableCell>{Math.floor(tag.price)}</TableCell>
                                    <TableCell>{Math.floor(tag.sum)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                        <Collapse in={selectedId === tag.nomenclatureId} timeout="auto" unmountOnExit>
                                            {!load ?

                                                <Box sx={{ margin: 1 }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Торговые точки
                                                    </Typography>
                                                    <Table aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: 700 }}>
                                                                    <Search
                                                                        searchComplete={searchCompleteChild}
                                                                        setSearchComplete={setSearchCompleteChild}
                                                                    />
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: 700 }}>
                                                                  ИНН
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: 700 }} onClick={() => sortItemChild(sortTradePoints, 'quantity', setSortQuantityChild, sortQuantityChild)}>
                                                                   <div style={{display: "flex"}}><span> Количество {sortByChild === "quantity" && sortQuantityChild ?  "↓" : sortByChild === "quantity" && !sortQuantityChild ? "↑" : null}</span></div>
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: 700 }} onClick={() => sortItemChild(sortTradePoints, 'price', setSortPriceChild, sortPriceChild)}>
                                                                    <div style={{display: "flex"}}><span>Цена (руб.){sortByChild === "price" && sortPriceChild ?  "↓" : sortByChild === "price" && !sortPriceChild ? "↑" : null}</span></div>
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: 700 }} onClick={() => sortItemChild(sortTradePoints, 'sum', setSortSumChild, sortSumChild)}>
                                                                    <div style={{display: "flex"}}><span>Сумма (руб.){sortByChild === "sum" && sortSumChild ?  "↓" : sortByChild === "sum" && !sortSumChild ? "↑" : null}</span></div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {filterNomenclatureTableChild(data, searchCompleteChild).map((item) => (
                                                                <TableRow key={item.id}>
                                                                    <TableCell>{item.name}</TableCell>
                                                                    <TableCell>{item.inn}</TableCell>
                                                                    <TableCell>{item.quantity} {item.packing}</TableCell>
                                                                    <TableCell >{Math.floor(item.price)}</TableCell>
                                                                    <TableCell>{Math.floor(item.sum)}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                                :
                                                <Box sx={{ position: 'relative', height: "40vh" }}>
                                                    <CircularProgress className="load" />
                                                </Box>
                                            }
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider/>
            <Box sx={{fontWeight: 700, padding: "5px", textAlign: "right", marginRight: "10px"}}>
            Итого: {Math.floor(allSum)} руб.
            </Box>
        </List>
    );
}
