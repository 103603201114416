import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './CreateRouteSheetsOrderListAreaList.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import EditDeliveryAreaStore from "../../../../store/transportationLogisticsService/PageDeliveryZone/editDeliveryArea";
import {AutoSizer, List} from "react-virtualized";
import {observer} from "mobx-react-lite";
import Skeleton from "@mui/material/Skeleton";
import {v4 as uuidv4} from "uuid";
import GlobalContext from "../../../../context/GlobalContext";
import debounce from "lodash/debounce";
import {useParams} from "react-router-dom";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import CreateRouteSheetsOrderListAreaHeaderList
    from "../CreateRouteSheetsOrderListAreaHeaderList/CreateRouteSheetsOrderListAreaHeaderList";
import CreateRouteSheetsSelectAreaItem from "../CreateRouteSheetsSelectAreaItem/CreateRouteSheetsSelectAreaItem";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import CreateRouteSheetsOrderListAreaListSkeleton from "./CreateRouteSheetsOrderListAreaList.skeleton";


const CreateRouteSheetsOrderListAreaList = observer(() => {
    const axiosInstance = AxiosConfig_LogisticService();
    const [areaName, setAreaName] = useState("")
    const [loading, setLoading] = useState(true)
    const {areaList} = createRouteSheetsStore

    useEffect(() => {

        async function getCreatedAreaList() {
            try {
                await createRouteSheetsStore.getAllDeliveryArea(axiosInstance);
                setLoading(false);
            } catch (e) {
                console.error(e)
            }
        }

        getCreatedAreaList();

        return () => {
            createRouteSheetsStore.changeDefaultValueModalSelectArea();
        }

    }, []);


    const updateAreaName = useCallback(
        debounce((value) => {
            createRouteSheetsStore.changeSearchText(value, "areaList");
        }, 500), // Задержка в миллисекундах
        []
    );

    const changeNewSearchValue = (newValue) => {
        setAreaName(newValue);
        updateAreaName(newValue);
    }

    const showSelectedArea = () => {
        createRouteSheetsStore.showCreatedDeliveryArea();
        createRouteSheetsStore.closePopup();
    }

    const rowRenderer = ({index, key, style, isVisible, isScrolling}) => {
        const content = !isVisible ? <Skeleton
            animation="wave"
            variant="rounded"
            width={"100%"}
            height={100}
        /> : <CreateRouteSheetsSelectAreaItem
            key={uuidv4()}
            number={index}
            area={areaList[index]}
            // typeAction={"update"}
            // showSelectZoneByMap={showSelectZoneByMap}
            // zoomAndShowClickZona={zoomAndShowClickZona}
        />;

        return (
            <div key={key} style={style}>
                {content}
            </div>
        )
            ;
    };

    return (
        <>
            {(loading) ? <CreateRouteSheetsOrderListAreaListSkeleton/> :
                <>
                    <div className={style.popupHeader}>
                        Список зон
                        <div
                            className={style.btnClosePopup}
                            onClick={() => {
                                createRouteSheetsStore.closePopup()
                            }}
                        ><DeleteIcon/></div>
                    </div>
                    <div
                        id={"searchInputZone"}
                        className={style.inputSearchNameZona}>
                        <input
                            type="text"
                            maxLength={50}
                            value={areaName}
                            onChange={(e) => changeNewSearchValue(e.target.value)}
                            placeholder="Поиск по наименованию"
                        />
                        <span onClick={
                            () => {
                                setAreaName("")
                                changeNewSearchValue("")
                            }
                        }>Очистить</span>
                    </div>

                    <div className={style.actionBtns}>
                        <button className={style.showSelectedAreaBtn}
                                onClick={() => showSelectedArea()}
                        >
                            Показать выбранные
                        </button>
                        <button className={style.cancelAskBtn}
                                onClick={() => createRouteSheetsStore.closePopup()}
                        >
                            Отмена
                        </button>
                    </div>
                    <CreateRouteSheetsOrderListAreaHeaderList/>
                    <div>
                        {areaList && <AutoSizer disableHeight>
                            {({width}) => (
                                <List
                                    // ref={setListRef}
                                    width={width}
                                    height={255}
                                    rowCount={areaList.length} // Количество элементов в списке
                                    rowHeight={51} // Высота отдельного элемента списка
                                    rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                    className={style.listContent}
                                    overscanRowCount={10}
                                />
                            )}
                        </AutoSizer>}
                    </div>
                </>
            }
        </>
    )

});

export default memo(CreateRouteSheetsOrderListAreaList);