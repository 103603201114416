import React, {memo, useEffect, useState} from 'react';
import style from './RouteSheetTable.module.css'
import {observer} from "mobx-react-lite";
import RouteSheetTableHeader from "../RouteSheetTableHeader/RouteSheetTableHeader";
import RouteSheetTableList from "../RouteSheetTableList/RouteSheetTableList";

const RouteSheetTable = observer(() => {
    const [widthHeader, setWidthHeader] = useState(0)

    const setNewWidthHeader = (newValue) => {
        setWidthHeader(newValue)
    }
    return (
        <div className={style.table}>
            <RouteSheetTableHeader key={"routeListHead"} widthHeader={setNewWidthHeader}/>
            <RouteSheetTableList key={"routeList"} width={widthHeader} height={850}/>
        </div>
    );
});

export default memo(RouteSheetTable);