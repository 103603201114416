import React, { memo, useState } from "react";
import style from "./MapPopupRouteInfo.module.css";
import { observer } from "mobx-react-lite";
import createRouteSheetsStore from "../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const MapPopupRouteInfo = observer(({ orderDoc }) => {

  const [counter, setCounter] = useState(0);

  const increment = () => {
    if (counter < orderDoc.length - 1) {
      setCounter(counter + 1);
    }
  };

  const decrement = () => {
    if (counter > 0) {
      setCounter(counter - 1);
    }
  };

  return (
    <div className={style.popupContentMap}>
      <div className={style.popupContentHeader}>Информация по точке</div>

      <div className={style.popupContentMapFlex}>
      {orderDoc.length > 1 ?

        <div onClick={() => decrement()} className={style.arrow}>
          <div className={style.arrowTopLeft}></div>
          <div className={style.arrowBottomLeft}></div>
        </div>
        : null}

        <div>
            
          <div className={style.popupContentName}>
            Клиент: <span>{orderDoc[counter]?.order_client}</span>
          </div>
          <div className={style.popupContentName}>
            Номер заказ: <span>{orderDoc[counter]?.order_number}</span>
          </div>
          <div className={style.popupContentName}>
            Адрес: <span>{orderDoc[counter]?.order_adress_delivery}</span>
          </div>
        </div>

{orderDoc.length > 1 ?
        <div onClick={() => increment()} className={style.arrow}>
          <div className={style.arrowTop}></div>
          <div className={style.arrowBottom}></div>
        </div> : null}

      </div>
    </div>
  );
});

export default memo(MapPopupRouteInfo);
