import React, {memo} from 'react';
import style from './ModalSelectedInitialVehicleItem.module.css';
import {Tooltip} from 'react-tooltip';
import {observer} from "mobx-react-lite";

const ModalSelectedInitialVehicleItem = observer(({paramRowData, onClick, vehicleTonnage}) => {
    const {appellation,tonnage, vehicle_brand, license_plate} = paramRowData

    const changeInitPoint = () => {
        onClick(paramRowData)
    }

    const checkTransportLoadLimit = () => {
        const tonnageToKilogram =  Number(tonnage) * 1000;

        return vehicleTonnage > tonnageToKilogram
    }

    return (
        <div
            onClick={() => changeInitPoint()}
            className={
                (checkTransportLoadLimit())?`${style.addressCoordsItem} ${style.itsLimit}`:style.addressCoordsItem
            }
        >
            <div>
                <div data-tooltip-id={`client-info_${paramRowData.id}`}
                     data-tooltip-variant="info">
                    {(appellation.length > 50) ? appellation.slice(0, 50) + "..." : appellation}
                    {(appellation.length > 50) ? <Tooltip
                        id={`client-info_${paramRowData.id}`}
                        content={appellation}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
            </div>
            <div>
                <div>{license_plate}</div>
                <div>{vehicle_brand}</div>
            </div>
            <div>
                <div>{tonnage} т.</div>
                <div>{Number(vehicleTonnage).toFixed(2)} кг.</div>
            </div>
        </div>
    );
})

export default memo(ModalSelectedInitialVehicleItem);