import * as React from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import Constructor from "../../components/Constructor/Constructor";
import IsLockedPage from "../IsLockedPage/IsLockedPage";
import { Box, Typography } from "@mui/material";
import "../IsLockedPage/IsLockedPage.css";
import LockImg from "../../imges/lock.svg";
const SubscriptionNotActivePage = () => {
  return (
    <>
      <Box className={"locked-page"}>
        <Box sx={{ paddingTop: "20px" }}>
          <Typography sx={{ textAlign: "center" }} variant="h5" component="h2">
            Отсутствует подписка на сервис Логистика
          </Typography>
          <Typography sx={{ textAlign: "center" }} variant="h6" component="h2">
            Чтобы получить доступ к сервису активируйте подписку
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <img style={{ width: "50%", margin: "0 auto" }} src={LockImg} />
        </Box>
      </Box>
    </>
  );
};
export default SubscriptionNotActivePage;
