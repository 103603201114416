import React, {useEffect, useState} from "react";
import "./Search.css";
import {Button} from "@mui/material";
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import BackspaceIcon from '@mui/icons-material/Backspace';

const Search = (props) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (event) => {
        const searchText = event.target.value;
        setSearchTerm(searchText);
    };

    const clearInput = () => {
        setSearchTerm("")
        props.setSearchComplete("")
    }

    useEffect(() => {
        if (searchTerm === "" && props.searchComplete !== "") {
            props.setSearchComplete("")
        }
    }, [searchTerm]);

    return (
        <Paper
            sx={{
                p: "2px",
                display: 'flex',
                alignItems: 'center',
                width: 270,
                boxShadow: 0,
                border: "1px solid #1976d1"
            }}
        >
            <input
                className={"searchSKUinput"}
                placeholder="Поиск"
                value={searchTerm}
                onChange={handleSearch}
            />
            <IconButton onClick={() => props.setSearchComplete(searchTerm)}>
                <SearchIcon/>
            </IconButton>
            <IconButton sx={{display: props.searchComplete === "" ? "none" : ""}} onClick={() => clearInput()}>
                <BackspaceIcon fontSize="small"/>
            </IconButton>
        </Paper>
    );
};

export default Search;
