import React, {useEffect, useState} from 'react';
import style from "./RouteReadyDocument.module.css"
import {observer} from "mobx-react-lite";
import routeOnMap from "../../../../store/transportationLogisticsService/pageRouteOnMap/routeOnMap";

const RouteReadyDocument = observer(({showSelectRouteToMap, document}) => {


    const getStartCurrentDate = () => {
        let date = new Date(document.delivery_date);

    // Форматирование даты и времени в формат "dd.mm.yyyy hhч mmм"
        return date.getDate().toString().padStart(2, '0') + '.' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
            date.getFullYear() + ' ' +
            date.getHours().toString().padStart(2, '0') + 'ч ' +
            date.getMinutes().toString().padStart(2, '0') + 'м';
    }
    const getEndCurrentDate = () => {
        if (document.route_summary?.time){
            let date = new Date(document.delivery_date);

            // 2. Получить количество миллисекунд
            const milliseconds = document.route_summary?.time * 1000;
            // 3. Добавить миллисекунды к дате
            date.setMilliseconds(date.getMilliseconds() + milliseconds);
            // 4. Получить новую дату в строке
            return date.getDate().toString().padStart(2, '0') + '.' +
                (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
                date.getFullYear() + ' ' +
                date.getHours().toString().padStart(2, '0') + 'ч ' +
                date.getMinutes().toString().padStart(2, '0') + 'м';
        }
    }
    const [currentStartDayAndTine, setCurrentStartDayAndTine] = useState(getStartCurrentDate());
    const [endCurrentDate, setEndCurrentDate] = useState(getEndCurrentDate());

    return (
        <div
            onClick={()=> {
                showSelectRouteToMap(document.document_id)
                routeOnMap.showRouteToMap(document.document_id)
            }}
            className={(!document.show_to_map)?style.routeReadyDocument:`${style.routeReadyDocument} ${style.active}`}>
            <div>
                <div>{document.document_number}</div>
                <div>{document.vehicle_number}</div>
            </div>
            <div>
                <div>{currentStartDayAndTine}</div>
                <div>{endCurrentDate}</div>
            </div>
            <div>
                <div>{document.route_summary?.length} км</div>
                <div>{document.order_list.length}</div>
            </div>
        </div>
    );
});

export default RouteReadyDocument;