import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import Loader from "../Loader/Loader";
import SearchDistributorList from "../SearchDistributorList/SearchDistributorList";
import "./ProfiledManufacturersList.css";
import Search from "../ui/Search/Search";
import Avatar from "@mui/material/Avatar";
import {generateLocalColorFromText} from "../../helpers/helpers";
function filterDistributor(data, searchText) {
  return data.filter((item) => {
    const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
    const itemInn = item.inn.toLowerCase(); // Получаем поле "article" объекта
    return itemName.includes(searchText.toLowerCase()) || itemInn.includes(searchText.toLowerCase());
  });
}
export default function ProfiledManufacturersList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = useState(false); // Добавляем состояние loader
  const { state } = useContext(GlobalContext);
  const [rows, setSearchResults] = useState(state.profileManufacturersList);
  const [searchComplete, setSearchComplete] = useState("")


  const [selectedRow, setSelectedRow] = useState(null);
  const [openCard, setOpenCard] = useState(false);

  useEffect(() => {
    if (selectedRow) {
      const updatedSelectedRow = rows.find((row) => row.id === selectedRow.id);
      setSelectedRow(updatedSelectedRow || null);
    }
  }, [rows]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <Paper sx={{ width: "100%", borderRadius: 0, boxShadow: 0 }}>
      <Loader open={loader} />
        <>
          <TableContainer sx={{ padding: 0, maxHeight: "calc(84vh - 16px)" }}>
            <Loader open={loader} />
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" colSpan={6}>
                    <Search
                        searchComplete={searchComplete}
                        setSearchComplete={setSearchComplete}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 700 }}>
                    Наименование
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>ИНН</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>КПП</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Почта</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Адрес</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterDistributor(rows, searchComplete).map((manufacturers) => (
                  <TableRow
                    key={manufacturers.id}
                  >
                    <TableCell>
                      <Avatar
                          sx={{
                            backgroundColor: generateLocalColorFromText(
                                manufacturers.name
                            ),
                          }}
                          alt={manufacturers.name}
                          src="/broken-image.jpg"
                      />
                    </TableCell>
                    <TableCell>{manufacturers.name}</TableCell>

                    <TableCell>{manufacturers.inn}</TableCell>
                    <TableCell>{manufacturers.kpp}</TableCell>

                    <TableCell>{manufacturers.mail}</TableCell>
                    <TableCell>{manufacturers.address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
    </Paper>
  );
}
