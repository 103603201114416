import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import style from './CreateRouteSheetsOrderListTableHeader.module.css';
import ResizeObserver from "resize-observer-polyfill";
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";



const CreateRouteSheetsOrderListTableHeader = observer(({widthHeader}) => {
    const containerRef = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const {tableOrderListState} = createRouteSheetsStore

    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                widthHeader(containerRef.current.offsetWidth)
                setSize({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight
                });
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateSize();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Установить размеры при первой отрисовке
        updateSize();

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [containerRef.current]);

    const changeAll = async (e) => {
        e.preventDefault()
        await createRouteSheetsStore.changeSelectedAllInTable(!tableOrderListState.selectAllOrder, "orderList");
    }


    return (
        <div ref={containerRef} className={style.tableHeader}>
            <div>
                №
            </div>
            <div
                onClick={ (e) => { changeAll(e)}}
            >
                <input
                    type="checkbox"
                    className={style.selectDocumentML}
                    id="selectAll_OrderList"
                    name="selectAll_OrderList"
                    value={tableOrderListState.selectAllOrder}
                    checked={tableOrderListState.selectAllOrder}
                    onChange={() => {}}
                />
                <label htmlFor="selectAll_OrderList"></label>
            </div>
            <div>
                Адрес
            </div>
            <div>Клиент</div>
            <div>Вес</div>
        </div>
    );
});

export default memo(CreateRouteSheetsOrderListTableHeader);