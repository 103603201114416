import React, {useContext} from 'react';
import style from './DeliveryAreaEditActionBtnsGroup.module.css'
import EditDeliveryAreaStore from "../../../../store/transportationLogisticsService/PageDeliveryZone/editDeliveryArea";
import GlobalContext from "../../../../context/GlobalContext";
import {observer} from "mobx-react-lite";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {useNavigate} from "react-router-dom";
import DeliveryAreaEditSelectAreaList from "../DeliveryAreaEditSelectAreaList/DeliveryAreaEditSelectAreaList";

const DeliveryAreaEditActionBtnsGroup = observer(() => {
    const {popupType, popupShow, popupScrollPosition} = EditDeliveryAreaStore.popupState
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);
    const navigate = useNavigate();

    const changePopupShow = (value, typePopup) => {
        EditDeliveryAreaStore.changePopupShow(window.pageYOffset, value, typePopup);
    }

    const deleteDeliveryArea = async () => {
        try {
            await EditDeliveryAreaStore.deleteDeliveryAreaById(authTokens, setUser, setAuthTokens, logoutUser, navigate);
            EditDeliveryAreaStore.closePopup();
        } catch (e) {
        }
    }

    return (
        <div className={style.actionBtns}>
            <button
                onClick={() => {
                    EditDeliveryAreaStore.deleteSelectPoints()
                }}
                className={style.deleteSelectPoints_btn}>
                Удалить выбранные точки
            </button>
            <button
                id={'createBtn'}
                className={style.createNewArea_btn}
                onClick={() => EditDeliveryAreaStore.updateAreaById(authTokens, setUser, setAuthTokens, logoutUser,navigate)}
            >
                Сохранить
            </button>
            <button
                id={'createBtn'}
                className={style.showCreatingArea_btn}
                onClick={() => {
                    changePopupShow(!popupShow, "selectCreateArea")
                }}
                // onClick={() => createNewZona.createDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser)}
            >
                Показать созданные зоны
            </button>
            <button
                onClick={() => {
                    changePopupShow(!popupShow, "askDeleteDeliveryArea")
                }}
                className={style.deleteSelectPoints_btn}>
                Удалить зону
            </button>

            <PopupRouting
                isOpen={popupShow && popupType === "askDeleteDeliveryArea"}
                onClose={() => EditDeliveryAreaStore.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <div className={style.popupHeader}>
                    Уточнение
                    <div
                        className={style.btnClosePopup}
                        onClick={() => {
                            EditDeliveryAreaStore.closePopup()
                        }}
                    ><DeleteIcon/></div>
                </div>
                <div className={style.askText}>Вы точно хотите удалить зону доставки, действие будет невозможно
                    отменить?
                </div>
                <div className={style.actionAskBtns}>
                    <button
                        className={style.acceptAskBtn}
                        onClick={() => deleteDeliveryArea()}
                    >Подтвердить
                    </button>
                    <button
                        className={style.cancelAskBtn}
                        onClick={() => EditDeliveryAreaStore.closePopup()}
                    >Отменить
                    </button>
                </div>
            </PopupRouting>


            <PopupRouting
                isOpen={popupShow && popupType === "selectCreateArea"}
                onClose={() => EditDeliveryAreaStore.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <DeliveryAreaEditSelectAreaList/>
            </PopupRouting>
        </div>
    );
});

export default DeliveryAreaEditActionBtnsGroup;