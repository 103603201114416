import React, {memo} from 'react';
import style from './DeliveryAreaItem.module.css'
import {ReactComponent as SettingIcon} from '../../../../imges/SettingIcon.svg';
import {observer} from 'mobx-react-lite';
import deliveryZones from '../../../../store/transportationLogisticsService/PageDeliveryZone/deliveryZone'
import {v4 as uuidv4} from "uuid";
import {debounce} from 'lodash';
import {useNavigate} from "react-router-dom";


const DeliveryAreaItem = observer(({
                                       area,
                                       zoomAndShowClickZona,
                                       showSelectZoneByMap,
                                   }) => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (zona.setting === true) {
    //         showSelectZoneByMap(zona.coordinates, zona.id)
    //     }
    //
    // }, [zona.setting])

    const handleInputChange = debounce((e) => {
        deliveryZones.updateNameZone(e.target.value, area.id);
    }, 1500);

    const settingThisArea = (e) => {
        e.stopPropagation();
        navigate(`/logisticService/deliveryAreas/editArea/${area.id}`);
    }

    const selectArea = (e) => {
        e.stopPropagation();
        deliveryZones.selectDeliveryArea(area?.id, !area?.checked)
    }

    return (
        <>
            <div
                className={
                    (area.coordinates.length === 0)?`${style.settingDeliveryZoneNavZone} ${style.noCoords}`:style.settingDeliveryZoneNavZone
                }
                onClick={() => {
                    deliveryZones.zoomAndShowClickArea(area?.id, area?.coordinates, area?.color_area, area?.name_area)
                }}
            >
                <div>
                    <input type="checkbox"
                           className={style.selectDeliveryZone}
                           id={`SelectZona_${uuidv4()}`}
                           name="selectedZona"
                           value="select"
                           checked={area?.checked}
                           onChange={(e) => {
                           }}
                    />
                    <label
                        htmlFor={`SelectZona_${uuidv4()}`}
                        onClick={(e) => selectArea(e)}
                    ></label>
                </div>
                <div
                    className={style.settingDeliveryZoneNavZoneName}>{area?.name_area}</div>
                <div
                    onClick={(e) => settingThisArea(e)}
                    className={style.settingBtn}>
                    <SettingIcon/></div>
            </div>
        </>
    );
});

export default memo(DeliveryAreaItem);