import {makeAutoObservable} from 'mobx'
import {v4 as uuidv4} from "uuid";
import globalState from "../globalState";
import axios from "axios";
import toast from "react-hot-toast";
import MapData_DeliveryArea from "./mapData_DeliveryArea";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../components/ToastMassage/ToastMassage';


const DEFAULT_AREA_VALUE = {
    id: uuidv4(),
    name_area: "",
    color_area: '#6ea5e8',
    coordinates: []
}

class createDeliveryZone {
    newZona = DEFAULT_AREA_VALUE

    statusCreatingPage = {
        createStatus: false,
        clearMap: false,
        zonaSave: false,
        checkedAllPoint: false
    }

    listCreatedArea = []; // Массив созданных зон
    listCreatedAreaCopy = []; // Массив созданных зон

    selectCreatedArea = []; // Массив с выбранными зонами

    selectCreatedAreaState = { // Состояния модального окна выбора созданных зон
        selectAllArea: false,
        searchValue: ""
    }

    // Состояние модального окна
    popupState = {
        popupShow: false,
        popupType: "",
        popupScrollPosition: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    selectCreatedDeliveryArea(newValue, areaId){
        this.listCreatedArea = this.listCreatedArea.slice().map(area => {
            if (area.id === areaId){
                area.checked = newValue;

                if (newValue){
                    this.selectCreatedArea = [...this.selectCreatedArea, area];
                } else {
                    this.selectCreatedArea = this.selectCreatedArea.slice().filter(selectedArea =>
                        selectedArea.id !== areaId
                    )
                }
            }
            return area
        })
    }

    selectAllCreatedArea(newValue) {
        this.selectCreatedAreaState.selectAllArea = newValue;

        this.listCreatedArea = this.listCreatedArea.slice().map(area => {
            area.checked = newValue;
            return area
        })

        if (newValue){
            this.selectCreatedArea = JSON.parse(JSON.stringify(this.listCreatedArea));
        } else {
            this.selectCreatedArea = [];
        }
    }

    showCreatedDeliveryArea(){
        const map = MapData_DeliveryArea.mapData;
        this.clearUserPointMap();

        if (this.selectCreatedArea.length === 0) return

        for (const createdArea of this.selectCreatedArea){
            const idZone = createdArea.id;
            const coordinatesArray = createdArea.coordinates.map(point => point.coordinates);

            if (map) {
                // Проверяем, существует ли уже источник данных с таким именем
                if (!map.getSource(`userPolygon_${idZone}`)) {
                    map.addSource(`userPolygon_${idZone}`, {
                        type: 'geojson',
                        data: {
                            type: "FeatureCollection",
                            features: []
                        }
                    });
                }

                // if (!map.getSource(`userPoint_${idZone}`)) {
                //     map.addSource(`userPoint_${idZone}`, {
                //         type: 'geojson',
                //         data: {
                //             type: "FeatureCollection",
                //             features: []
                //         }
                //     });
                // }

                // Проверяем, существует ли уже слой с таким именем
                if (!map.getLayer(`userPolygon_${idZone}`)) {
                    map.addLayer({
                        'id': `userPolygon_${idZone}`,
                        'type': 'fill',
                        'source': `userPolygon_${idZone}`,
                        'layout': {},
                        'paint': {
                            'fill-color': `${createdArea?.color_area}`,
                            'fill-opacity': 0.5
                        }
                    });

                    map.addLayer({
                        'id': `userPolygonBorder_${idZone}`,
                        'type': 'line',
                        'source': `userPolygon_${idZone}`,
                        'layout': {},
                        'paint': {
                            'line-color': '#000000', // Цвет границы
                            'line-width': 3, // Ширина границы
                            'line-opacity': 1
                        }
                    });

                    map.addLayer({
                        'id': `userPolygonText_${idZone}`,
                        'type': 'symbol',
                        'source': `userPolygon_${idZone}`,
                        'layout': {
                            'text-field': `${createdArea?.name_area}`, // Замените 'Текст' на нужный текст
                            'text-font': ['Open Sans Regular'], // Шрифт текста
                            'text-size': 12 // Размер текста
                        },
                        'paint': {
                            'text-color': '#000000', // Цвет текста
                            'text-halo-color': '#ffffff', // Цвет алого круга
                            'text-halo-width': 2 // Ширина алого круга
                        }
                    });
                }

                // if (!map.getLayer(`userPoint_${idZone}`)) {
                //     map.addLayer({
                //         'id': `userPoint_${idZone}`,
                //         'type': 'symbol',
                //         'source': `userPoint_${idZone}`,
                //         "layout": {
                //             "icon-image": 'custom_pin',
                //             "icon-size": 0.2,
                //             'text-field': "{id}", // Отображаем номера точек
                //             'text-anchor': 'top',
                //             'text-offset': [0, 1],
                //             'text-size': 15
                //         },
                //         'paint': {
                //             'text-color': '#000000'
                //         }
                //     });
                // }


                // Формируем данные для слоя userPolygon
                const userPolygonData = {
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [coordinatesArray] // Убираем дополнительный массив
                        }
                    }]
                };

                // const userPointData = {
                //     type: "FeatureCollection",
                //     features: coordinatesArray.map((coords, index) => ({
                //         type: "Feature",
                //         properties: {
                //             id: index + 1 // Используем индекс как ID
                //         },
                //         geometry: {
                //             type: "Point",
                //             coordinates: coords
                //         }
                //     }))
                // };

                // Устанавливаем данные для источника данных userPolygon
                const userPolygonSource = map.getSource(`userPolygon_${idZone}`);
                if (userPolygonSource) {
                    userPolygonSource.setData(userPolygonData);

                    if (userPolygonData.features[0].geometry.coordinates[0].length > 0) {
                        map.fitBounds(coordinatesArray, {zoom: 12});
                    }
                }

                // const userPointSource = map.getSource(`userPoint_${idZone}`);
                // if (userPointSource) {
                //     userPointSource.setData(userPointData);
                // }
            }
        }



    }

    changeDeliveryAreaText(newText){
        this.selectCreatedAreaState.searchValue = newText;
        const regex = new RegExp(`^.*${newText.split(' ').join('.*')}.*$`, 'i');

        this.listCreatedArea = this.listCreatedAreaCopy.slice().filter(area =>
            regex.test(area.name_area.toString())
        )
    }

    changeDefaultValueModalSelectCreatedArea(){
        this.listCreatedArea = [];
        this.listCreatedAreaCopy = [];
        this.selectCreatedAreaState = {
            selectAllArea: false,
            searchValue: ""
        }
    }

    async getAllDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getAllDeliveryArea`, {headers}
        ).then(res => {
            const areaListData = this.changeCheckedValue_Modal(res.data);
            this.listCreatedArea = areaListData;
            this.listCreatedAreaCopy = areaListData;
        }).catch(error => {
            toastMassage(toast.error, "Ошибка при получении зон доставки", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })

    }
    changeCheckedValue_Modal(areaList, areaId){
        if (this.selectCreatedArea.length > 0){
            let checkedArray = JSON.parse(JSON.stringify(this.selectCreatedArea));

            return areaList.map(area => {
                    for (const checkedArea of checkedArray){
                        if (checkedArea.id === area.id){
                            area.checked = true;

                            checkedArray = this.selectCreatedArea.slice().filter(SelectArea => SelectArea.id !== area.id);
                            break;
                        }
                    }
                    return area;
                })
        } else {
            return areaList
        }
    }

    closePopup(){
        this.popupState.popupShow = false;
        this.popupState.popupType = "";
    }

    changePopupShow(position,value, typePopup){
        this.popupState.popupShow = value;
        this.popupState.popupType = typePopup;
        this.popupState.popupScrollPosition = position;
    }

    changeDefaultValue(){
        const map = MapData_DeliveryArea.mapData;

        this.selectCreatedArea = [];
        this.newZona = DEFAULT_AREA_VALUE;
        this.clearUserPointMap();
        if (map) {
            map.off("click", this.clickHandler);
        }
    }

    updateNameZone(name) {
        this.newZona.name_area = name;
    }

    updateColorZona(color) {
        this.newZona.color_area = color;

        const map = MapData_DeliveryArea.mapData;
        if (map.getLayer(`userPolygon`)) {
            map.setPaintProperty(`userPolygon`, 'fill-color', color);
        }
    }

    clickHandler = (e) => {
        this.addedPolygonCords(e.lngLat);
    };
    initializationCreatePage() {
        const map = MapData_DeliveryArea.mapData;
        if (map) {
            if (!map.isStyleLoaded()) {
                map.once('styledata', () => {
                    this.addSourceAndLayer(map);
                    this.addClickHandler(map);
                })
            } else {
                this.addSourceAndLayer(map);
                this.addClickHandler(map);
            }

            map.on("click", this.clickHandler);
        }
    }

    addedPolygonCords(coordinates) {
        if (!coordinates) {
            throw new Error('Invalid coordinate');
        }
        const newCoordinate = {coordinates: [coordinates.lng, coordinates.lat], checked: false};
        this.newZona = {
            ...this.newZona,
            coordinates: [...this.newZona.coordinates, newCoordinate]
        }

        this.addPointToTheMap()
    };

    createStatusUpdate(status) {
        this.statusCreatingPage.createStatus = status;

        // Если статус создания ложный, очищаем карту и создаем новую зону
        if (this.statusCreatingPage.createStatus === false) {
            this.statusCreatingPage.clearMap = true;
            this.newZona = {
                id: null,
                IdManufacturer: null,
                nameZona: "",
                coordinates: []
            }
        } else {
            this.statusCreatingPage.clearMap = false;
        }
    }

    deleteCoordinateForArr(coordinateToDelete) {
        this.newZona.coordinates = this.newZona.coordinates.filter(coordinate => coordinate.coordinates !== coordinateToDelete);

        this.addPointToTheMap()
    }

    changeCheckboxPoint(targetCoordinates, checkStatus) {
        this.newZona.coordinates = this.newZona.coordinates.map(obj => {
            // Проверяет, совпадают ли координаты объекта с целевыми координатами
            if (obj.coordinates[0] === targetCoordinates[0] && obj.coordinates[1] === targetCoordinates[1]) {
                // Если координаты совпадают, обновляет статус проверки объекта
                return {
                    ...obj,
                    checked: checkStatus
                };
            }
            // Если координаты не совпадают, возвращает исходный объект
            return obj;
        });
    }

    setAllCheckedTrue(checkedAll) {
        // Устанавливаем флаг для всех точек
        this.statusCreatingPage.checkedAllPoint = checkedAll;
        // Устанавливаем флаг для всех координат
        this.newZona.coordinates = this.newZona.coordinates.map(obj => {
            obj.checked = checkedAll;
            return obj;
        });
    }

    deleteSelectPoints() {
        // Снимаем отметку "выбрано" со всех точек
        this.statusCreatingPage.checkedAllPoint = false;
        // Фильтруем координаты новой зоны, оставляя только невыбранные точки
        this.newZona.coordinates = this.newZona.coordinates.filter(obj => !obj.checked);

        this.addPointToTheMap();
    }

    // Сохранить новую зону
    saveNewZone() {
        // Установить идентификатор производителя
        this.newZona.IdManufacturer = 23232323;
        // Установить статус сохранения зоны
        this.statusCreatingPage.zonaSave = true;
        // Вывести объект, который передается на сервер
        console.log("На сервер передаётся объект:", this.newZona)
        // Обновить статус создания
        this.createStatusUpdate(false)
        // Установить таймер для сброса статуса сохранения зоны
        setTimeout(() => {
            this.statusCreatingPage.zonaSave = false;
        }, 2500)
    }


    async createDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser, navigate) {

        if (this.newZona.name_area === ""){
            toastMassage(toast.error, "Укажите наименование зоны!", 10000, "bottom-right")
            return
        }

        if (this.newZona.coordinates.length < 3){
            toastMassage(toast.error, "Зона доставки должна состоять минимум из 3 точек на карте!", 10000, "bottom-right")
            return
        }

        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        let copiedElement = {...JSON.parse(JSON.stringify(this.newZona.coordinates[0]))};
        copiedElement.coordinates[0] -= 0.00001;

        const data = {
            ...this.newZona,
            coordinates: [...this.newZona.coordinates, copiedElement]
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/createDeliveryArea`, data, {headers}
        ).then(res => {
            toastMassage(toast.success, "Новая зона успешно создана!", 2500, "bottom-right")
            navigate(-1);
        }).catch(error => {
            toastMassage(toast.error, "Ошибка при создании зоны доставки!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    /**/

    addSourceAndLayer = (map) => {
        /**
         * Добавление источника и слоя для пользовательских полигонов
         */
        if (!map.getSource(`userPolygon`)) {
            map.addSource('userPolygon', {
                type: 'geojson',
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });
        }

        /**
         * Добавление источника и слоя для пользовательских точек
         */
        if (!map.getSource(`userPoint`)) {
            map.addSource('userPoint', {
                type: 'geojson',
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });
        }
    };
    addClickHandler = (map) => {

        if (!map.getLayer(`userPolygon`)) {
            map.addLayer({
                'id': 'userPolygon',
                'type': 'fill',
                'source': 'userPolygon',
                'layout': {},
                'paint': {
                    'fill-color': `${this.newZona.color_area}`,
                    'fill-opacity': 0.5
                }
            });
        }

        /**
         * Загрузка пользовательского изображения для точек
         */
        if (!map.getLayer(`userPoint`)) {
            map.loadImage(
                'https://maps.vk.com/api/styles/pins/blue_target.png',
                function (error, image) {
                    if (error) throw error;
                    map.addImage('custom_pin', image);
                    map.addLayer({
                        'id': 'userPoint',
                        'type': 'symbol',
                        'source': 'userPoint',
                        "layout": {
                            "icon-image": 'custom_pin',
                            "icon-size": 0.2,
                            'text-field': "{id}", // Отображаем номера точек
                            'text-anchor': 'top',
                            'text-offset': [0, 1],
                            'text-size': 15
                        },
                        'paint': {
                            'text-color': '#000000'
                        }
                    });
                }
            );
        }
    };
    addPointToTheMap(){

        const map = MapData_DeliveryArea.mapData;

        const coordinatesArray = this.newZona.coordinates.map(obj => obj.coordinates);

        if (map && coordinatesArray.length > 0) {

            if (coordinatesArray.length === 1) {
                this.addSourceAndLayer(map);
                this.addClickHandler(map);
            }

            // Создаем данные для пользовательского полигона
            const userPolygonData = {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [coordinatesArray] // Убираем дополнительный массив
                    }
                }]
            };

            // Создаем данные для пользовательских точек
            const userPointData = {
                type: "FeatureCollection",
                features: coordinatesArray.map((coords, index) => ({
                    type: "Feature",
                    properties: {
                        id: index + 1 // Используем индекс как ID
                    },
                    geometry: {
                        type: "Point",
                        coordinates: coords
                    }
                }))
            };

            // Устанавливаем данные для источника полигона
            const userPolygonSource = map.getSource('userPolygon');
            if (userPolygonSource) {
                userPolygonSource.setData(userPolygonData);
            }

            // Устанавливаем данные для источника точек
            const userPointSource = map.getSource('userPoint');
            if (userPointSource) {
                userPointSource.setData(userPointData);
            }
        } else if (map && coordinatesArray.length === 0) {
            // Удаляем слои, если зона отсутствует
            if (map.getLayer("userPolygon")) {
                map.removeLayer("userPolygon");
                map.removeSource("userPolygon");
            }
            if (map.getLayer("userPoint")) {
                map.removeLayer("userPoint");
                map.removeSource("userPoint");
            }

            if (map.hasImage('custom_pin')) {
                map.removeImage('custom_pin');
            }
        }
    }


    clearMap() {
        const map = MapData_DeliveryArea.mapData;

        // Проверяем, существует ли слой "userPolygon"
        if (map.getLayer("userPolygon")) {
            map.removeLayer("userPolygon");
            map.removeSource("userPolygon");
        }

        // Проверяем, существует ли слой "userPoint"
        if (map.getLayer("userPoint")) {
            map.removeLayer("userPoint");
            map.removeSource("userPoint");
        }

        if (map.hasImage('custom_pin')) {
            // Если изображение существует, удаляем его
            map.removeImage('custom_pin');
        }
    }

    clearUserPointMap(){
        const map = MapData_DeliveryArea.mapData;
        const mapStyle = map.getStyle();

        if (!mapStyle || !mapStyle.layers) return;
        const layers = mapStyle.layers.map(layer => layer.id);
        layers.forEach(layerId => {

            if (layerId.startsWith("userPolygonText_") || layerId.startsWith("userPolygonBorder_") || layerId.startsWith("userPolygon_") || layerId.startsWith("userPoint_")) {
                map.removeLayer(layerId);
            }
        });
    }

}

export default new createDeliveryZone();