import React from 'react';
import style from './CreateRouteSheetsOrderListAreaHeaderList.module.css';
import EditDeliveryAreaStore from "../../../../store/transportationLogisticsService/PageDeliveryZone/editDeliveryArea";
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const CreateRouteSheetsOrderListAreaHeaderList = observer(() => {
    const {modalState, areaList} = createRouteSheetsStore

    return (
        <div style={{
            paddingRight: (areaList.length > 5)?10: 0
        }} className={style.deliveryAreaHeader}>
            <div
                onClick={(e) => {
                    e.preventDefault()
                    createRouteSheetsStore.selectAllCreatedArea(!modalState.selectAll)
                }}
            >
                <input
                    type="checkbox"
                    className={style.selectDocumentML}
                    id="selectAllArea"
                    name="selectAllArea"
                    value={modalState.selectAll}
                    checked={modalState.selectAll}
                    onChange={(e) => {}}
                />
                <label htmlFor="selectAllArea"></label>
            </div>
            <div>Наименование</div>
        </div>
    );
});

export default CreateRouteSheetsOrderListAreaHeaderList;