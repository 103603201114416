import axios from 'axios';
import { useContext, useEffect } from 'react';
import GlobalContext from '../../context/GlobalContext';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const AxiosConfig_LogisticService = () => {
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_LOGISTICS_SERVICE, // Базовый URL для всех запросов
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens?.access}` // Пример токена авторизации
        }
    });

    axiosInstance.interceptors.request.use(
        async (config) => {
            if (!authTokens) {
                return config;
            }

            const user = jwt_decode(authTokens.access);
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

            if (!isExpired) {
                return config;
            }

            try {
                const response = await axios.post(`http://176.99.11.148:5000/api/MVD/RefreshToken/`, {
                    refresh: authTokens.refresh
                });

                localStorage.setItem('authTokens', JSON.stringify(response.data));
                setAuthTokens(response.data);
                setUser(jwt_decode(response.data.access));

                config.headers.Authorization = `Bearer ${response.data.access}`;
            } catch (e) {
                console.error(e);
                logoutUser();
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default AxiosConfig_LogisticService;
