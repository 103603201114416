import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAxiosAPI } from "../apiRequest/apiRequest";
import GlobalContext from "../context/GlobalContext";

const PrivateRoute = ({ children }) => {
  const { user } = useContext(GlobalContext);

  const { apiManufacturer, apiDistributor, apiManufacturerNomenclature, apiProfileDistributor, apiProfileManufacturersList, apiDistibutorNomenclature, apiGetTarifsUser } = useAxiosAPI();
  useEffect(() => {
    // Выполняем запросы только при условии, что user === true
    if (user) {
      if (user && user.accessLevel === '1') {
      apiManufacturer();
      apiDistributor();     
      apiManufacturerNomenclature();
      apiGetTarifsUser();
    } else if (user && user.accessLevel === '0') {
      apiProfileDistributor();
      apiProfileManufacturersList();
      apiDistibutorNomenclature();
      apiGetTarifsUser();

    }
      else if (user && user.accessLevel === '2') {

      }
        }
  }, [user, apiManufacturer, apiDistributor, apiManufacturerNomenclature]);

  return user ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
