import React, {useContext, useEffect, useRef, useState} from 'react';
import {Outlet, useNavigate, useParams, useMatch, useLocation} from "react-router-dom";
import mmrgl from 'mmr-gl';
import 'mmr-gl/dist/mmr-gl.css';
import style from './DeliveryAreasPage.module.css';
import {observer} from 'mobx-react-lite';
import createNewZona from '../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone'
import deliveryZones from '../../store/transportationLogisticsService/PageDeliveryZone/deliveryZone'
import {observable} from "mobx"
import globalState from "../../store/transportationLogisticsService/globalState";
import GlobalContext from "../../context/GlobalContext";
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import {Toaster} from "react-hot-toast";
import MapData_DeliveryArea from "../../store/transportationLogisticsService/PageDeliveryZone/mapData_DeliveryArea";
import RequestLimitLogistics from '../../components/RequestLimitLogistics/RequestLimitLogistics';

const DeliveryAreasPage = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const [loading, setLoading] = useState(true)
    const mapRef = useRef(null);
    const [tarif, setTarif] = useState(true);

    /**
     * Хук useEffect для инициализации карты при загрузке компонента
     */
    useEffect(() => {

        async function initMap() {
            try {
                const resultCheck = await globalState.checkCountOrTariff("mapСount", authTokens, setUser, setAuthTokens, logoutUser);
                setTarif(resultCheck.status === 200 ? true : false)

                if (resultCheck.status === 200) {
                    // Инициализация карты при загрузке компонента
                    // deliveryZones.statusSettingAction.mainPageArea = true;
                    deliveryZones.statusSettingAction.settingPoint = false;
                    createNewZona.statusCreatingPage.createStatus = false;
                    mmrgl.accessToken = process.env.REACT_APP_ACCES_TOKEN_FOR_VKMAP;
                    // mmrgl.accessToken = "";
                    /**
                     * Создание нового экземпляра карты
                     */
                    const map = new mmrgl.Map({
                        container: 'map',
                        zoom: 12,
                        center: [34.11823, 44.94554],
                        style: 'mmr://api/styles/main_style.json',
                        hash: false
                    });

                    mapRef.current = map;
                    MapData_DeliveryArea.setMapData(mapRef.current)
                    deliveryZones.updateInfoLoading(false);
                    setLoading(false);
                }
            } catch (e) {
                console.error(e)
            }
        }

        initMap();

        // Функция очистки для удаления карты при размонтировании компонента
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    return (
        <div className={style.settingDeliveryZonePage}>
            {/*Уведомления*/}
            <Toaster containerStyle={{
                position: 'fixed',
                top: '80px'
            }}/>

            <MainMenuLogisticsService namePage={"Зоны доставки"}/>

            <div className={style.settingDeliveryZoneContent}>

                <div className={style.settingDeliveryZoneNav}>
                    <Outlet/>
                </div>

                <div className={style.settingDeliveryZoneMap}>
                    {tarif ?
                    <div id="map" style={{width: '100%', height: '95vh'}}>
                    </div>
                    : 
                    <div style={{backgroundColor: "#f3f4f9", width: "100%", height: "100%", border: "1px solid #fcfcfcfc"}}>

                    <RequestLimitLogistics/>
                    </div>
                    }
                </div>
            </div>

        </div>
    );
});

export default DeliveryAreasPage;