import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './EditTemplateItem.module.css'
import {useNavigate, useParams} from "react-router-dom";
import Popover_SelectMobileStatusDelivered
    from "../../UI/Popover_SelectMobileStatusDelivered/Popover_SelectMobileStatusDelivered";
import {observer} from "mobx-react-lite";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import GlobalContext from "../../../../context/GlobalContext";
import Skeleton from '@mui/material/Skeleton';
import debounce from "lodash/debounce";

const EditTemplateItem = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {selected_status, mobileStatusList} = deliveredStatusList.popoverSelectStatus;
    const {selectedTemplate} = deliveredStatusList
    const {loading} = deliveredStatusList.editTempState
    const [tempName, setTempName] = useState("")

    const navigate = useNavigate();
    const {idTemplate} = useParams();

    useEffect(() => {
        async function getTemplate() {
            const response = await deliveredStatusList.getTemplateByTempId(idTemplate, authTokens, setUser, setAuthTokens, logoutUser);
            setTempName(deliveredStatusList.editTempState.templateText)
        }

        getTemplate();

        return () => {
            deliveredStatusList.changeDefaultValueEditTemp();
        }
    }, [idTemplate]);

    const getSelectedValueDestination = (newValue) => {
        deliveredStatusList.changeSelectMobileStatus(newValue, "edit")
    };

    const updateTemplateText = useCallback(
        debounce((value) => {
            deliveredStatusList.changeTemplateText(value, "edit")
        }, 500), // Задержка в миллисекундах
        []
    );

    const changeTemplateName = (newValue) => {
        setTempName(newValue)
        updateTemplateText(newValue)
    }

    const updateTemplate = async () => {
        try {
            await deliveredStatusList.updateTemplateData(idTemplate, authTokens, setUser, setAuthTokens, logoutUser);
            navigate(`/logisticService/deliveredStatusList/templateList/${selected_status.status_name}`);
        }catch (e) {
            console.error(e)
        }
    }

    return (
        <div className={style.createTemplate}>
            <div className={style.createTemplateHeader}>
                <div className={style.goBackToList}
                     onClick={() => navigate(-1)}>Назад
                </div>
                Редактирование шаблона ответа
            </div>

            <div className={style.createTemplateForm}>
                <div className={style.selectTypeStatus}>
                    <div>Статус в приложении:</div>
                    {
                        loading ? <Skeleton
                                style={{
                                    marginLeft: "10px"
                                }}
                                animation="wave"
                                variant="rounded"
                                width={100}
                                height={20}
                            /> :
                            <Popover_SelectMobileStatusDelivered selectOptions={selected_status}
                                                                 options={mobileStatusList}
                                                                 onChange={(newValue) => getSelectedValueDestination(newValue)}
                            />
                    }
                </div>

                <div className={style.nameTemplateInput}>
                    <div>Наименование шаблона в приложении:</div>
                    {
                        loading ? <Skeleton
                                style={{
                                    marginLeft: "-10px"
                                }}
                                animation="wave"
                                variant="rounded"
                                width={252}
                                height={31}
                            /> :
                            <textarea
                                type="text"
                                placeholder="Наименование в приложении"
                                value={tempName}
                                maxLength={100}
                                onChange={
                                    (e) => {
                                        changeTemplateName(e.target.value)
                                    }
                                }
                            />
                    }
                </div>
            </div>

            <div className={style.actionTemplateBtns}>
                <button
                    className={style.createTemplateBtn}
                    onClick={() => updateTemplate()}
                >Сохранить
                </button>
                <button
                    className={style.cancelCreateTemplateBtn}
                    onClick={() => navigate(-1)}
                >Отменить
                </button>
            </div>
        </div>
    );
});

export default memo(EditTemplateItem);