import React, {memo, useCallback, useContext, useEffect, useRef} from 'react';
import style from './DeliveryAreaItemList.module.css';
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import DeliveryAreaItem from "../DeliveryAreaItem/DeliveryAreaItem";
import {v4 as uuidv4} from "uuid";
import {observer} from "mobx-react-lite";
import deliveryZone from "../../../../store/transportationLogisticsService/PageDeliveryZone/deliveryZone";
import Skeleton from "@mui/material/Skeleton";
import {useNavigate, useParams} from "react-router-dom";
import GlobalContext from "../../../../context/GlobalContext";

const DeliveryAreaItemList = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const cache = useRef(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 51
    }))
    const {listZones} = deliveryZone
    const {typeList} = useParams();

    useEffect(() => {

        const getDeliveryArea = async () => {
            await deliveryZone.getAllDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser)
        }

        getDeliveryArea();


    }, [typeList]);


    /**
     * Функция для установки ссылки на список.
     */
    // const setListRef = useCallback(node => {
    //     if (node !== null) {
    //         listRef.current = node;
    //         if (statePage.setting === false && listScrollToAddress.address != null && listScrollToAddress.client != null) {
    //             if (listRef.current) {
    //                 listRef.current.scrollToRow(statePage.index_edit_address)
    //             }
    //         }
    //     }
    // }, [typeAddressList])

    const rowRenderer = ({index, key, style, isVisible, isScrolling, parent}) => {
        const content =  <DeliveryAreaItem
            key={uuidv4()}
            number={index}
            area={listZones[index]}
            // typeAction={"update"}
            // showSelectZoneByMap={showSelectZoneByMap}
            // zoomAndShowClickZona={zoomAndShowClickZona}
        />;

        return (
            <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                <div key={key} style={style}>
                    {content}
                </div>
            </CellMeasurer>
        )
            ;
        };

    return (
        <div>
            {listZones && <AutoSizer disableHeight>
                {({width}) => (
                    <List
                        // ref={setListRef}
                        width={width}
                        height={455}
                        rowCount={listZones.length} // Количество элементов в списке
                        deferredMeasurementCache={cache.current}
                        rowHeight={cache.current.rowHeight} // Высота отдельного элемента списка
                        rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                        className={style.addressListBlock}
                        overscanRowCount={10}
                    />
                )}
            </AutoSizer>}
        </div>
    );
});

export default memo(DeliveryAreaItemList);