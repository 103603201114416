import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './OrderMapPopup.module.css'
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import toast from "react-hot-toast";
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {toastMassage} from '../../../ToastMassage/ToastMassage';
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import GlobalContext from "../../../../context/GlobalContext";
import OrderTransferPopup from "../../../../popups/logisticService/OrderTransferPopup/OrderTransferPopup";

const OrderMapPopup = observer(({orderId}) => {
    const axiosInstance = AxiosConfig_LogisticService();
    const {popupState: {popupShow, popupType, popupScrollPosition}} = createRouteSheetsStore;
    const [store, setStore] = useState({
        orderData: null,
        areaData: null
    });


    useEffect(() => {
        console.log("RENDER")
        const orderData = getOrderData();
        const areaData = getAreaData(orderData);
        let routeData = null

        if (orderData?.in_route_sheets){
            routeData = getRouteData(orderData?.id);
        }

        setStore({
            orderData: orderData,
            areaData: areaData,
            routeData: routeData
        });

    }, []);

    const getRouteData = (orderId) => {
        const {routeSheetsList} = createRouteSheetsStore

        return routeSheetsList.find(route => route?.order_list.some(order => order?.id === orderId));
    }

    const getOrderData = () => {
        const {orderList} = createRouteSheetsStore
        return orderList.find((order) => {
            return order.id === orderId
        })
    }

    const getAreaData = (orderData) => {
        const {selectArea} = createRouteSheetsStore

        return selectArea.find((area) => {
            return area.id === orderData?.deliveryArea
        })
    }

    const createRouteSheet = () => {
        const {popupShow} = createRouteSheetsStore.popupState
        createRouteSheetsStore.changeSelectOrderData(store.orderData);
        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "createRouteSheetModal");
    };

    const addToTheRouteSheet = () => {
        const {routeSheetsList} = createRouteSheetsStore
        const {popupShow} = createRouteSheetsStore.popupState

        if (routeSheetsList.length === 0) {
            toastMassage(toast.error, "Нет созданных маршрутных листов!", 10000, "bottom-right")
            return
        }

        createRouteSheetsStore.changeSelectOrderData(store.orderData);
        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "addInRouteSheet");
    }


    // Функция для открытия листа маршрута по заданному orderId
    const handleRouteSheetOpen = (orderId) => {
        const {popupShow} = createRouteSheetsStore.popupState;
        const {routeSheetsList} = createRouteSheetsStore

        if (!Array.isArray(routeSheetsList) || routeSheetsList.length === 0) {
            return;
        }

        const routeSheetData = routeSheetsList.find(route =>
            route.order_list.some(order => order.id === orderId)
        );

        if (routeSheetData) {
            createRouteSheetsStore.changeEditRouteSheetsData(routeSheetData);
            createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "editRouteSheet");
        }
    }

    const deleteOrderInRoute = async (orderId) => {
        await createRouteSheetsStore.deleteOrderInRouteSheet(axiosInstance, orderId)
    }

    const openPopup = () => {
        const { popupShow } = createRouteSheetsStore.popupState

        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "transferOrder");
        createRouteSheetsStore.setTransferOrderData(store.orderData, "orderData");
    }


    return (
        <div className={style.popupContentMap}>
            <div className={style.popupContentHeader}>Информация по заказу</div>
            <div className={style.popupContentName}>Клиент: <span>{store.orderData?.order_client}</span></div>
            <div className={style.popupContentName}>Номер заказ: <span>{store.orderData?.order_number}</span></div>
            <div className={style.popupContentName}>Адрес: <span>{store.orderData?.order_adress_delivery}</span></div>
            <div className={style.popupContentName}>Зона: <span>{store.areaData?.name_area || "-----"}</span></div>
            <div className={style.popupContentName}>Вес: <span>{Number(store.orderData?.vehicle_tonnage).toFixed(2)} кг</span>
            </div>
            <div className={style.popupContentName}>Объем: <span>{Number(store.orderData?.volume_goods).toFixed(3)} м3</span>
            </div>
            <div className={
                (store.orderData?.in_route_sheets) ? `${style.popupContentName} ${style.inRoute}` : `${style.popupContentName}`}
                 onClick={() => {
                     if (store.orderData?.in_route_sheets) {
                         handleRouteSheetOpen(store.orderData?.id)
                     }
                 }}
            >Статус: <span>{(store.orderData?.in_route_sheets) ? `В маршрутном листе ${store?.routeData?.document_number}` : "Не в маршрутном листе"}</span>
            </div>
            {
                !store.orderData?.in_route_sheets &&
                <div className={style.buttonsActive}>
                    <button className={style.buttonActive}
                            onClick={() => createRouteSheet()}
                    >Создать маршрутный лист
                    </button>
                    <button className={style.buttonActive}
                            onClick={() => addToTheRouteSheet()}
                    >Добавить в маршрутный лист
                    </button>
                </div>
            }
            {
                store.orderData?.in_route_sheets &&
                <div>
                    <button className={style.buttonActive}
                            onClick={() => openPopup()}
                    >Перенести в другой маршрутный лист
                    </button>
                    <button className={style.buttonActive}
                            onClick={() => deleteOrderInRoute(store.orderData?.id)}
                    >Удалить из текущего маршрутного листа
                    </button>
                </div>
            }



        </div>
    );
});

export default memo(OrderMapPopup);