class sortAndFilterTableCols {

    sortBasic_Value(arr, field, typeSort) {
        if (typeSort === "asc"){ // Простая сортировка по возрастанию
            return arr.sort((a, b) => {
                return a[field] - b[field];
            })
        } else if (typeSort === "desc"){ // Простая сортировка по убыванию
            return arr.sort((a, b) => {
                return b[field] - a[field];
            })
        }
    }

    sortBasic_Date(arr, field, typeSort){
        if (typeSort === "asc"){
            return arr.sort((a, b) => new Date(a[field]) - new Date(b[field]))
        } else if (typeSort === "desc"){
            return arr.sort((a, b) => new Date(b[field]) - new Date(a[field]))
        }
    }

    sortBy_TotalDeliveredRoutePoint(arr, field, typeSort){
        if (typeSort === "asc"){
           return  arr.sort((a, b) => a.route_summary[field] - b.route_summary[field])
        } else if (typeSort === "desc"){
            return arr.sort((a, b) => b.route_summary[field] -a.route_summary[field])
        }
    }

    sortBy_OrderAdressDelivery(arr, field, typeSort) {
       return arr.sort((a, b) => {
            const aValue = a[field] !== undefined ? a[field] : a.address;
            const bValue = b[field] !== undefined ? b[field] : b.address;

            if (typeSort === "asc") {
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
            } else if (typeSort === "desc") {
                if (aValue > bValue) return -1;
                if (aValue < bValue) return 1;
            }

            return 0;
        })
    }

    sortBy_OrderNumber(arr, field, typeSort){
        return arr.sort((a, b) => {
            if (a[field] === undefined) return 1;
            if (b[field] === undefined) return -1;
            const numA = parseInt(a[field].match(/\d+/)[0], 10);
            const numB = parseInt(b[field].match(/\d+/)[0], 10);

            if (typeSort === "asc"){
                return numA - numB;
            } else if (typeSort === "desc"){
                return numB - numA;
            } else {
                return 0
            }
        })
    }

    sortBy_DateAndTimeThisPoint(arr, field, typeSort){
        return arr.sort((a, b) => {
            if (a.numberInRoute === 1) return 1;
            if (b.numberInRoute === 1) return -1;

            if (typeSort === "asc"){
                return new Date(a[field]) - new Date(b[field]);
            } else if (typeSort === "desc"){
                return new Date(b[field]) - new Date(a[field]);
            } else {
                return 0
            }
        })
    }

    sortBy_DateAndTimeUnload(arr, field, typeSort){
        return arr.sort((a, b) => {
            if (a.numberInRoute === arr.length) return 1;
            if (b.numberInRoute === arr.length) return -1;
            const aValue = a.numberInRoute === 1 ? a.dateAndTimeThisPoint : a[field];
            const bValue = b.numberInRoute === 1 ? b.dateAndTimeThisPoint: b[field];

            if (typeSort === "asc"){
                return new Date(aValue) - new Date(bValue);
            } else if (typeSort === "desc") {
                return new Date(bValue) - new Date(aValue);
            }
        })
    }



}

export default new sortAndFilterTableCols();