import React, {useContext, useEffect} from 'react';
import style from './AddressCoordinateEdit.module.css'
import editCoordinatesAddress
    from "../../../store/transportationLogisticsService/pageEditCoordinatesAddress/editCoordinatesAddress";
import {observer} from "mobx-react-lite";
import GlobalContext from "../../../context/GlobalContext";
import {useNavigate, useParams} from "react-router-dom";

const AddressCoordinateEdit = observer(({mapRef, showSelectAddressToMap}) => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {editDataAddressCoordinates} = editCoordinatesAddress;
    const navigate = useNavigate();
    const {addressId, typeRedirect} = useParams();

    useEffect(() => {
        async function getAddressById(){
            await editCoordinatesAddress.getCoordinateAddressById(addressId, authTokens, setUser, setAuthTokens, logoutUser);
        }

        getAddressById();

        console.log(typeRedirect)

        return () => {
            editCoordinatesAddress.changeDefaultValueEditAddress();
        }

    }, [addressId]);


    useEffect(()=> {
        if (editDataAddressCoordinates !== null){
            showSelectAddressToMap(editDataAddressCoordinates);
        }
    }, [editDataAddressCoordinates])

    const canceledEditAddress = () => {
        try {
            editCoordinatesAddress.cancelEditAddressCoordinate();
            navigate(-1);
            // navigate(`/logisticService/addressCoordinateEditing/addressList/all`, {relative: 'path'});
        } catch (e) {

        }
    }

    const saveEditAddressData = async () => {
        try {
            await editCoordinatesAddress.updateNewCoordinateAddress(typeRedirect,authTokens, setUser, setAuthTokens, logoutUser, navigate);
        } catch (e) {

        }
    }

    return (
        <>
            <div className={style.CoordsEditHeaderMenu}>
                Редактирование координат
            </div>
            <div
                id={"addressContext_AddressCoordinateEdit"}
                className={style.addressEditContent}>
                <span>Адрес:</span>
                <div className={style.addressContent}>{editDataAddressCoordinates?.address}</div>
            </div>

            <div
                id={"editCoordinatesBlock_AddressCoordinateEdit"}
            >
                <div className={style.addressEditCoordinates}>
                    <div id={"settingDeliveryZoneNavNameZone"}>
                        <span>Широта:</span>
                        <input
                            type="number"
                            value={editDataAddressCoordinates?.lat}
                            onChange={
                                (e) => {
                                    editCoordinatesAddress.changeNewAddressCoordinate("lat", e.target.value, mapRef)
                                }
                            }
                        />
                    </div>
                </div>
                <div className={style.addressEditCoordinates}>
                    <div id={"settingDeliveryZoneNavNameZone"}>
                        <span>Долгота:</span>
                        <input
                            type="number"
                            value={editDataAddressCoordinates?.lon}
                            onChange={
                                (e) => {
                                    editCoordinatesAddress.changeNewAddressCoordinate("lon", e.target.value, mapRef)
                                }
                            }
                        />
                    </div>
                </div>
                <div className={`${style.addressEditCoordinates} ${style.addressEditTimes}`}>
                    <div id={"settingDeliveryZoneNavNameZone"}>
                        <span>Время на выгрузку товара (минуты):</span>
                        <input
                            type="number"
                            value={editDataAddressCoordinates?.unload_time}
                            onChange={
                                (e) => {
                                    editCoordinatesAddress.changeNewAddressCoordinate("time", e.target.value, mapRef)
                                }
                            }
                        />
                    </div>
                </div>
            </div>

            <div
                id={"cancelEditCoordinate_AddressCoordinateEdit"}
                className={style.btnCancelEditCoords}
                onClick={() => canceledEditAddress()}
            >
                Отменить изменения
            </div>
            <div
                id={"saveEditCoordinate_AddressCoordinateEdit"}
                className={style.btnSaveEditCoords}
                onClick={ () => saveEditAddressData()}
            >
            Сохранить
            </div>

            <div className={style.informationEditCoords}>
                <div className={style.informationEditHeader}>FAQ: Как можно корректировать координаты?</div>
                <ul>
                    <li>
                        <div>1) Вносить координаты вручную в поля "Широта" и "Долгота".</div>
                        <div>Пример вводимых кординат:</div>
                        <div>Широта: 55.755863</div>
                        <div>Долгота: 37.617700</div>
                    </li>
                    <li>2) <span>Двойным кликом мыши</span> в необходимую область на карте. После этого в полях "Широта" и "Долгота" появятся координаты.</li>
                    <li>3) Если точка адреса уже существует на карте, можно её зажать (левой кнопкой мыши) и переместить в нужное место.</li>
                </ul>
            </div>

        </>
    );
});

export default AddressCoordinateEdit;