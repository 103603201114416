import React, {memo, useCallback, useContext, useEffect} from 'react';
import style from "./RouteOptimizationInitPoint.module.css";
import {ReactComponent as SettingIcon_v2} from '../../../../imges/SettingIcon_v2.svg';
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import toast from "react-hot-toast";
import {Tooltip} from "react-tooltip";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import InitialPointPopupList from "../../InitialPointPopupList/InitialPointPopupList";
import {observer} from "mobx-react-lite";
import GlobalContext from "../../../../context/GlobalContext";
import settingOptimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
    import { IconButton } from '@mui/material';
    import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';
    
const RouteOptimizationInitPoint = observer(() => {
    const {authTokens} = useContext(GlobalContext);
    const {popup_show,popup_scroll_position,popup_type} = optimizationRouteSheetStore.pageState;
    const {initial_point_route} = settingOptimizationRouteSheetStore.generalSettingForDocuments;

    const changeInitialPoint = useCallback(() => {
        const {routingSheets} = optimizationRouteSheetStore;

        if (routingSheets) {
            optimizationRouteSheetStore.openPopupInitPoints(authTokens?.access, window.pageYOffset, "selectInitialPoint_General")
        } else {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
        }
    }, []);


    return (
        <div className={style.routeOptimizationInitPoint}>
            <div className={style.headerOptimizationInitPoint}>Начальная точка маршрутов:</div>
            <div
                data-tooltip-id={`client-info_routeOptimizationInitPoint`}
                data-tooltip-variant="info"
                className={style.btnSelectInitPoint}
                onClick={() => changeInitialPoint()}
            >
                <SettingIcon_v2/>
                <div>{((initial_point_route?.length > 25) ? initial_point_route.slice(0, 25) + "..." : initial_point_route) || `Выбрать начальную точку`}</div>
                {(initial_point_route?.length > 25) ? <Tooltip
                    id={`client-info_routeOptimizationInitPoint`}
                    content={initial_point_route}
                    place="right"
                    style={{zIndex: 5}}
                /> : null}
            </div>


            <PopupRouting
                isOpen={popup_show && popup_type === "selectInitialPoint_General"}
                onClose={() => optimizationRouteSheetStore.closePopupInitPoints()}
                scrollPosition={popup_scroll_position}
                styles={{overflow: "hidden"}}
            >
                <InitialPointPopupList
                    setNewData={(addressData) => optimizationRouteSheetStore.setGlobalInitialPoint(addressData)}/>
            </PopupRouting>
        </div>
    );
});

export default memo(RouteOptimizationInitPoint);