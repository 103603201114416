import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ButtonGroup,
  Divider,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useContext, useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import DatePickerReportsFinish from "../DatePickerReportsFinish/DatePickerReportsFinish";
import DatePickerReportsStart from "../DatePickerReportsStart/DatePickerReportsStart";
import ReportDistributorList from "../ReportDistributorList/ReportDistributorList";
import ReportDistributorNome from "../ReportDistributorNome/ReportDistributorNome";
import ReportManufacturerNome from "../ReportManufacturerNome/ReportManufacturerNome";
import ReportManufacturersList from "../ReportManufacturersList/ReportManufacturersList";

export default function VerticalTabs() {
  const {
    apiSummaryNomReportExel,
    apiEvolutionOfDemandReportExel,
    apiABCReportExel,
    apiCheckAnalysisReportExel,
    apiXYZReportReportExel,
  } = useAxiosAPI();
  const { excelData } = useContext(GlobalContext);

  const [typeReport, setTypeReport] = React.useState({
    name: "Продажи по дистрибьюторам",
    index: 0,
  });
  let { user } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loader, setLoader] = useState(false); // Добавляем состояние loader

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [
    distributorStartDateReportsStart,
    setDistributorStartDateReportsStart,
  ] = useState(null);
  const [
    distributorFinishDateReportsStart,
    setDistributorFinishDateReportsStart,
  ] = useState(null);
  const [
    distributorStartDateReportsFinish,
    setDistributorStartDateReportsFinish,
  ] = useState(null);
  const [
    distributorFinishDateReportsFinish,
    setDistributorFinishDateReportsFinish,
  ] = useState(null);

  const [selectedItemsButton, setSelectedItemsButton] = useState(false);
  const [selectedItemsButtonNome, setSelectedItemsButtonNome] = useState(false);

  const [selectedItemsNome, setSelectedItemsNome] = useState(null);
  const [selectedItemsDistributor, setSelectedItemsDistributor] =
    useState(null);

  const downloadExcel = () => {
    return typeReport.index === 0
      ? apiSummaryNomReportExel(
          "excel",
          Boolean(
            distributorStartDateReportsStart &&
              distributorFinishDateReportsStart &&
              distributorStartDateReportsFinish &&
              distributorFinishDateReportsFinish
          ),
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          distributorStartDateReportsFinish,
          distributorFinishDateReportsFinish,
          selectedItemsDistributor,
          true
        )
      : typeReport.index === 1
      ? apiEvolutionOfDemandReportExel(
          "excel",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          selectedItemsNome,
          true
        )
      : typeReport.index === 2
      ? apiABCReportExel(
          "excel",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          true
        )
      : typeReport.index === 3
      ? apiCheckAnalysisReportExel(
          "excel",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          selectedItemsNome,
          true
        )
      : typeReport.index === 4
      ? apiXYZReportReportExel(
          "json",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          false
        )
      : null;
  };

  const generateReport = () => {
    setLoader(true); // Установите loader в true перед началом запроса

    return typeReport.index === 0
      ? apiSummaryNomReportExel(
          "json",
          Boolean(
            distributorStartDateReportsStart &&
              distributorFinishDateReportsStart &&
              distributorStartDateReportsFinish &&
              distributorFinishDateReportsFinish
          ),
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          distributorStartDateReportsFinish,
          distributorFinishDateReportsFinish,
          selectedItemsDistributor,
          false
        )
          .then(() => {
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          })
      : typeReport.index === 1
      ? apiEvolutionOfDemandReportExel(
          "json",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          selectedItemsNome,
          false
        )
          .then(() => {
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          })
      : typeReport.index === 2
      ? apiABCReportExel(
          "json",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          false
        )
          .then(() => {
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          })
      : typeReport.index === 3
      ? apiCheckAnalysisReportExel(
          "json",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          selectedItemsNome,
          false
        )
          .then(() => {
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          })
      : typeReport.index === 4
      ? apiXYZReportReportExel(
          "json",
          distributorStartDateReportsStart,
          distributorFinishDateReportsStart,
          selectedItemsDistributor,
          false
        )
          .then(() => {
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          })
      : null;
  };

  return (
    <Box
      sx={{
        borderRadius: "5px",
        width: "100%",
        flexGrow: 1,
        bgcolor: "background.paper",
        height: "96vh",
        boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
      }}
    >
      <Box style={{ padding: "1vw" }}>
        <Box sx={{display: "flex"}}>
          <Button
            //   style={{ margin: "1vw" }}
            variant="outlined"
            sx={{ marginRight: "10px" }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {typeReport.name} <ArrowDropDownIcon/>
          </Button>
          <DatePickerReportsStart
            distributorStartDateReportsStart={distributorStartDateReportsStart}
            setDistributorStartDateReportsStart={
              setDistributorStartDateReportsStart
            }
            distributorFinishDateReportsStart={
              distributorFinishDateReportsStart
            }
            setDistributorFinishDateReportsStart={
              setDistributorFinishDateReportsStart
            }
          />
          {typeReport.index === 0 ? (
            <DatePickerReportsFinish
              distributorStartDateReportsFinish={
                distributorStartDateReportsFinish
              }
              setDistributorStartDateReportsFinish={
                setDistributorStartDateReportsFinish
              }
              distributorFinishDateReportsFinish={
                distributorFinishDateReportsFinish
              }
              setDistributorFinishDateReportsFinish={
                setDistributorFinishDateReportsFinish
              }
            />
          ) : null}
          <ButtonGroup
            disabled={
              typeReport.index === 0 &&
              !Boolean(
                distributorStartDateReportsStart &&
                  distributorFinishDateReportsStart &&
                  distributorStartDateReportsFinish &&
                  distributorFinishDateReportsFinish &&
                  selectedItemsDistributor
              )
                ? true
                : (typeReport.index === 1 || typeReport.index === 3) &&
                  !Boolean(
                    distributorStartDateReportsStart &&
                      distributorFinishDateReportsStart &&
                      selectedItemsDistributor &&
                      selectedItemsNome
                  )
                ? true
                : (typeReport.index === 2 || typeReport.index === 4) &&
                  !Boolean(
                    distributorStartDateReportsStart &&
                      distributorFinishDateReportsStart &&
                      selectedItemsDistributor
                  )
            }
          >
            <Box sx={{
                  marginLeft: "10px",
                  // backgroundColor: "#fde910",
                  color: "#fff",
                }}>
              <Button
                variant="contained"
                sx={{
                  // marginRight: "10px",
                  backgroundColor: "#1e6e41",
                  color: "#ffffff",
                }}
                onClick={downloadExcel}
              >
                Скачать Excel
              </Button>
            </Box>

            <Box>
              <Button
                variant="contained"
                sx={{
                  marginRight: "10px",
                  // backgroundColor: "#fde910",
                  color: "#fff",
                }}
                onClick={generateReport}
              >
                Cформировать
              </Button>
            </Box>
          </ButtonGroup>
          
        </Box>
        
        <Box sx={{ marginTop: "10px", display: "flex" }}>
         
          <Button
            sx={{
              maxWidth: "30%",
            }}
            onClick={() => setSelectedItemsButton(!selectedItemsButton)}
            variant="contained"
          >
            <Typography
              sx={{
                textTransform: "none",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {selectedItemsDistributor
                ? selectedItemsDistributor.name
                : "Контрагент"}
            </Typography>
          </Button>
          {typeReport.index === 1 || typeReport.index === 3 ? (
            <Button
              title={
                selectedItemsNome ? selectedItemsNome.name : "Номенклатура"
              }
              sx={{
                marginLeft: "1vw",
                maxWidth: "30%",
              }}
              onClick={() =>
                setSelectedItemsButtonNome(!selectedItemsButtonNome)
              }
              variant="contained"
            >
              <Typography
                sx={{
                  textTransform: "none",

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {selectedItemsNome ? selectedItemsNome.name : "Номенклатура"}
              </Typography>
            </Button>
          ) : null}
        </Box>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() =>
              setTypeReport({ name: "Продажи по дистрибьюторам", index: 0 })
            }
          >
            Продажи по дистрибьюторам
          </MenuItem>
          <MenuItem
            onClick={() =>
              setTypeReport({ name: "Оценка равномерности спроса", index: 1 })
            }
          >
            Оценка равномерности спроса
          </MenuItem>
          <MenuItem
            onClick={() =>
              setTypeReport({ name: "ABC по номенклатуре", index: 2 })
            }
          >
            ABC по номенклатуре
          </MenuItem>
          <MenuItem
            onClick={() =>
              setTypeReport({ name: "Анализ структуры чека", index: 3 })
            }
          >
            Анализ структуры чека
          </MenuItem>
          <MenuItem
            onClick={() =>
              setTypeReport({ name: "XYZ отчёт по номенклатуре", index: 4 })
            }
          >
            XYZ отчёт по номенклатуре
          </MenuItem>
        </Menu>
      </Box>

      <Divider />

      <Box sx={{ padding: "10px" }}>
        <Box sx={{ width: "100%", overflow: "auto", height: "75vh" }}>
          {loader ? (
            <Box>Загрузка...</Box>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: excelData }} />
          )}
        </Box>
        {user.accessLevel == 1 ? (
          <>
            <ReportDistributorList
              open={selectedItemsButton}
              setOpen={setSelectedItemsButton}
              setSelectedItemsDistributor={setSelectedItemsDistributor}
            />
            <ReportDistributorNome
              open={selectedItemsButtonNome}
              setOpen={setSelectedItemsButtonNome}
              setSelectedItemsNome={setSelectedItemsNome}
            />
          </>
        ) : user.accessLevel == 0 ? (
          <>
            <ReportManufacturersList
              open={selectedItemsButton}
              setOpen={setSelectedItemsButton}
              setSelectedItemsDistributor={setSelectedItemsDistributor}
            />
            <ReportManufacturerNome
              open={selectedItemsButtonNome}
              setOpen={setSelectedItemsButtonNome}
              setSelectedItemsNome={setSelectedItemsNome}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
}
