import React, {useContext} from 'react';
import style from './InitialPointCreate.module.css'
import {observer} from "mobx-react-lite";
import GlobalContext from "../../../context/GlobalContext";
import editInitialPoints from "../../../store/transportationLogisticsService/pageEditInitialPoints/editInitialPoints";

const InitialPointCreate = observer(({mapRef}) => {
    const {state, authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {createDataInitialPoint} = editInitialPoints;
    return (
        <>
            <div className={style.initialPointEditHeaderMenu}>
                Создание начальной точки
            </div>

            <div
                className={style.initPointEditContent}>
                <div>
                    <span>Наименование:</span>
                    <input
                        type="text"
                        value={createDataInitialPoint.initial_route_point_name}
                        onChange={
                            (e) => {
                                editInitialPoints.changeNewInitialPointData("point_name", e.target.value, mapRef, "createDataInitialPoint")
                            }
                        }
                    />
                </div>
            </div>

            <div
                className={style.initPointEditContent}>
                <div>
                    <span>Адрес:</span>
                    <input
                        type="text"
                        value={createDataInitialPoint.address}
                        onChange={
                            (e) => {
                                editInitialPoints.changeNewInitialPointData("address", e.target.value, mapRef,"createDataInitialPoint")
                            }
                        }
                    />
                </div>
            </div>

            <div className={style.initPointEditCoordinates}>
                <div id={"settingDeliveryZoneNavNameZone"}>
                    <span>Широта:</span>
                    <input
                        type="number"
                        value={createDataInitialPoint.lat}
                        onChange={
                            (e) => {
                                editInitialPoints.changeNewInitialPointData("lat", e.target.value, mapRef,"createDataInitialPoint")
                            }
                        }
                    />
                </div>
            </div>

            <div className={style.initPointEditCoordinates}>
                <div id={"settingDeliveryZoneNavNameZone"}>
                    <span>Долгота:</span>
                    <input
                        type="number"
                        value={createDataInitialPoint.lon}
                        onChange={
                            (e) => {
                                editInitialPoints.changeNewInitialPointData("lon", e.target.value, mapRef,"createDataInitialPoint")
                            }
                        }
                    />
                </div>
            </div>

            <div
                id={"cancelEditCoordinate_AddressCoordinateEdit"}
                className={style.btnCancelEditInitPoint}
                onClick={() => editInitialPoints.cancelCreatePointData()}
            >
                Отменить создание
            </div>
            <div
                id={"saveEditCoordinate_AddressCoordinateEdit"}
                className={style.btnSaveEditInitPoint}
                onClick={ async () => {
                        await editInitialPoints.createNewInitialPointData(authTokens, setUser, setAuthTokens, logoutUser, state)
                }}
            >
                Создать
            </div>

            <div className={style.informationEditInitPoint}>
                <div className={style.informationEditHeader}>FAQ: Как можно корректировать координаты?</div>
                <ul>
                    <li>
                        <div>1) Вносить координаты вручную в поля "Широта" и "Долгота".</div>
                        <div>Пример вводимых кординат:</div>
                        <div>Широта: 55.755863</div>
                        <div>Долгота: 37.617700</div>
                    </li>
                    <li>2) <span>Двойным кликом мыши</span> в необходимую область на карте. После этого в полях "Широта"
                        и "Долгота" появятся координаты.
                    </li>
                    <li>3) Если точка адреса уже существует на карте, можно её зажать (левой кнопкой мыши) и переместить
                        в нужное место.
                    </li>
                </ul>
            </div>

        </>
    );
});

export default InitialPointCreate;