import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 470,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingTop: 1,
    paddingBottom: 6,
};
const redButton = {
    redButtonClass: {
        marginLeft: "3vw", marginTop: "2vw", backgroundColor: "#eb2746", '&:hover': {
            backgroundColor: "#c92842",
        }
    },
};



export default function ReportManufacturerNome(props) {
    const handleClose = () => props.setOpen(false);
    const { state } = useContext(GlobalContext);


    const handlePost = (obj) => {
        props.setSelectedItemsNome({"id": obj.id, "name": obj.name})
        props.setOpen(false)
    };
  

    return (
        <div>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton sx={{marginLeft: "95%"}} onClick={handleClose} aria-label="delete">
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{marginBottom: "1vw"}} id="modal-modal-title" variant="h6" component="h2">
                      Выберите номенклатуру для сопоставления
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                            <nav aria-label="main mailbox folders">
                                <List sx={{overflow: "auto", height: "50vh"}}>
                                    {state.distibutorNomenclature.map((nome, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemButton>
                                                <ListItemText onClick={() => {
                                                    handlePost(nome)
                                                }} primary={nome.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </nav>
                        </Box>
                    
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
