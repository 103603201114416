import Box from "@mui/material/Box";
import * as React from 'react';
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import "./RemainsPage.css";
import DistributorCurrentRemainsCard
    from "../../components/ManufacturerComponents/DistributorCurrentRemainsCard/DistributorCurrentRemainsCard";
import DistributorRealizationCard
    from "../../components/ManufacturerComponents/DistributorRealizationCard/DistributorRealizationCard";
import ManufacturerRealizationCard
    from "../../components/DistributorComponents/ManufacturerRealizationCard/ManufacturerRealizationCard";
import ManufacturersCurrentRemainsCard
    from "../../components/DistributorComponents/ManufacturersCurrentRemainsCard/ManufacturersCurrentRemainsCard";
import IsLockedPage from "../IsLockedPage/IsLockedPage";

const RemainsPage = () => {

    const {state, user} = useContext(GlobalContext);

    const distributors = state.distributors;
    const manufacturer = state.profileManufacturersList;
 

    return (
        <>
        {state.subscriptions ? (
          <>
            {state.subscriptions.filter((item) => item.code === 2)[0].active ? (
        <Box className="realizationPage">
            {user.accessLevel == 1 ?

                <Box className="realization-content">
                    {distributors != null ?
                        <DistributorCurrentRemainsCard distributors={distributors}/>
                        : null}
                </Box>
                : user.accessLevel == 0 ?
                    <Box className="realization-content">
                        {manufacturer != null ?
                            <ManufacturersCurrentRemainsCard manufacturer={manufacturer}/>
                            : null}
                    </Box>
                    : null
            }
      </Box>
          ) : (
            <IsLockedPage nameService={"САВП"} />
          )}
        </>
      ) : null}
    </>
    )
}
export default RemainsPage