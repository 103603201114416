import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import style from "../../../components/transportationLogisticsService/AddressCoordinateList/AddressCoordinateList.module.css"
import editCoordinatesAddress
    from "../../../store/transportationLogisticsService/pageEditCoordinatesAddress/editCoordinatesAddress";
import {ReactComponent as ImgNoneData} from '../../../imges/noneData.svg';
import {AutoSizer, List} from "react-virtualized";
import AddressCoordinateItem from "../AddressCoordinateItem/AddressCoordinateItem";
import {v4 as uuidv4} from "uuid";
import GlobalContext from "../../../context/GlobalContext";
import {observer} from "mobx-react-lite";
import Skeleton from '@mui/material/Skeleton';
import {useNavigate, useParams} from "react-router-dom";
import deliveredStatusList from "../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";

const AddressCoordinateList = observer(({showSelectAddressToMap}) => {
    const { authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const {addressList, statePage, listScrollToAddress} = editCoordinatesAddress;
    const listRef = useRef(null);
    const navigate = useNavigate();
    const {typeAddressList} = useParams();

    // useMemo(()=>{
    //     editCoordinatesAddress.getAllCoordinateAddress(authTokens, setUser, setAuthTokens, logoutUser)
    // },[])

    useEffect(() => {
        async function getAddressList() {
            switch (typeAddressList) {
                case "all":
                    await editCoordinatesAddress.getAllCoordinateAddress(authTokens, setUser, setAuthTokens, logoutUser);
                    break;
                case "noCoords":
                    await editCoordinatesAddress.getAllErrorCoordinateAddress(authTokens, setUser, setAuthTokens, logoutUser);
                    break;
            }
        }
        getAddressList();


        return () => {
            editCoordinatesAddress.changeDefaultValueEditAddressList();
        }

    }, [typeAddressList]);

    const changeNewPath = (newTypeAddressList) => {
        navigate(`/logisticService/addressCoordinateEditing/addressList/${newTypeAddressList}`, {relative: 'path'});
    }

    /**
     * Функция для установки ссылки на список.
     */
    const setListRef = useCallback(node => {
        if (node !== null){
            listRef.current = node;
            if (statePage.setting === false && listScrollToAddress.address != null && listScrollToAddress.client != null){
                if (listRef.current){
                    listRef.current.scrollToRow(statePage.index_edit_address)
                }
            }
        }
    },[typeAddressList])

    const rowRenderer = ({index, key, style, isVisible, isScrolling}) => {
        const content = !isVisible ? <Skeleton
            animation="wave"
            variant="rounded"
            width={"100%"}
            height={100}
        /> : <AddressCoordinateItem
            addressData={addressList[index]}
            showSelectAddressToMap={showSelectAddressToMap}
            key={uuidv4()}
            uid={uuidv4()}
            indexElem={index}
        />;

        return (
            <div key={key} style={style}>
                {content}
            </div>
        )
            ;
    };

    return (
        <>
            <div className={style.CoordsEditHeaderMenu}>
                Адреса
            </div>

            <div className={style.btnBlock}>
                <div
                    id={"uploadAllAddressCoordinates_AddressCoordinateList"}
                    className={style.btnShowAllAddress}
                    onClick={
                        () => changeNewPath("all")
                    }
                >
                    Все адреса
                </div>
                <div
                    id={"uploadAllErrorAddressCoordinates_AddressCoordinateList"}
                    className={style.btnShowErrorAddress}
                    onClick={
                        () => changeNewPath("noCoords")
                    }
                >
                    Адреса без координат
                </div>
            </div>

            <div
                id={"searchInputAddress_AddressCoordinateList"}
                className={style.inputSearchNumberAddress}>
                <input
                    type="text"
                    placeholder="Поиск по адресу"
                    value={editCoordinatesAddress.statePage.value_search}
                    onChange={(e) => editCoordinatesAddress.changeSearchAddress(e.target.value)}
                />
                <span
                    id={"clearSearchInputNumberDocument_RouteDisplayMap"}
                    onClick={()=>editCoordinatesAddress.clearSearchValue()}
                >Очистить</span>
            </div>

            <div className={style.addressListHeader}>
                <div>
                    <div>Клиент</div>
                    <div>Адрес</div>
                </div>
                <div>
                    <div>Широта</div>
                    <div>Долгота</div>
                </div>
                <div>
                    <div>Редактировать</div>
                </div>
            </div>
            <div id={"addressList_AddressCoordinateList"}>
                {
                    (addressList.length === 0)?
                        <>
                            <div className={style.noDataImg}>
                                <ImgNoneData/>
                                <div>Нет координат адресов</div>
                            </div>
                        </>
                        :

                    <AutoSizer disableHeight>
                        {({width}) => (
                            <List
                                ref={setListRef}
                                width={width}
                                height={505}
                                rowCount={addressList.length} // Количество элементов в списке
                                rowHeight={101} // Высота отдельного элемента списка
                                rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                className={style.popupInitPointListBlock}
                                overscanRowCount={10}
                            />
                        )}
                    </AutoSizer>
                }
            </div>
        </>
    );
})

export default memo(AddressCoordinateList);