import List from '@mui/material/List';
import * as React from 'react';

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Box, CircularProgress, Collapse, IconButton, TableContainer, Typography} from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {useState} from "react";
import {useAxiosAPI} from '../../../apiRequest/apiRequest';
import SearchSKU from "../../SearchSKU/SearchSKU";
// import "./DistributorNomenclatureTable.css";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Search from "../../ui/Search/Search";

function filterNomenclatureTable(data, searchText) {
    return data.filter((item) => {
        const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
        const itemArticle = item.article.toLowerCase(); // Получаем поле "article" объекта
        return itemName.includes(searchText.toLowerCase()) || itemArticle.includes(searchText.toLowerCase());
    });
}

export default function ManufacturerNomenclatureTable({ docs, items, distr}) {
    const {apiDistrGetRetailOutletsByNomSums} = useAxiosAPI();
    const [load, setLoad] = React.useState(false)
    const [data, setData] = React.useState([]);
    const [searchComplete, setSearchComplete] = useState("")
    const [selectedId, setSelectedId] = React.useState(null); // Состояние для хранения id выбранного элемента
    const toggleRow = (id) => {
        setLoad(true)
        // Сохраняем id выбранного элемента
        if (selectedId === id) {
            setSelectedId(null)
        } else {
            setSelectedId(id)
            fetchData(id)
        }

    };

    const fetchData = (id) => {

        apiDistrGetRetailOutletsByNomSums(distr, id, items)
            .then((resolve) => {
                setData(resolve.data);
                setLoad(false)

            })
            .catch(() => {
                alert("Ошибка")
                setLoad(false)
            });
    };



    const resultMerged = docs
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [searchResults, setSearchResults] = useState(resultMerged);
    return (
        <List sx={{width: '100%', bgcolor: 'background.paper', overflow: 'hidden'}}>
            <TableContainer sx={{height: "70vh"}}>
                <Table stickyHeader aria-label="collapsible table" sx={{minWidth: 650}}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Search
                                    searchComplete={searchComplete}
                                    setSearchComplete={setSearchComplete}
                                />
                            </TableCell>
                            <TableCell sx={{fontWeight: 700}}>Артикул</TableCell>
                            <TableCell sx={{fontWeight: 700}}>Количество</TableCell>
                            {/*<TableCell sx={{fontWeight: 700}}>Цена (руб.)</TableCell>*/}
                            <TableCell sx={{fontWeight: 700}}>Сумма (руб.)</TableCell>
                        </TableRow>
                    </TableHead>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{width: '100%'}}
                        >
                            Данная номенклатура не сопоставлена!
                        </Alert>
                    </Snackbar>
                    <TableBody>
                        {filterNomenclatureTable(resultMerged, searchComplete).map((tag) => (
                            <>

                                <TableRow
                                    sx={{backgroundColor: selectedId == tag.nomenclatureId ? "#a5ed9d" : "default"}}
                                    key={tag.nomenclatureId}>
                                    <TableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => tag.comparison ? toggleRow(tag.nomenclatureId) : handleClick()} // Передаем id элемента
                                        >
                                            {!tag.comparison ?
                                                <ErrorOutlineIcon color={"error"}/>
                                                :
                                                selectedId == tag.nomenclatureId ?
                                                    <KeyboardArrowUpIcon sx={{color: "#1976d0"}}/> :
                                                    <KeyboardArrowDownIcon sx={{color: "#1976d0"}}/>
                                            }
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{tag.name}</TableCell>
                                    <TableCell>{tag.article}</TableCell>
                                    <TableCell>{tag.quantity} {tag.packing}</TableCell>
                                    {/*<TableCell>{Math.floor(tag.price)}</TableCell>*/}
                                    <TableCell>{Math.floor(tag.sum)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                        <Collapse in={selectedId == tag.nomenclatureId} timeout="auto" unmountOnExit>
                                            {!load ?

                                                <Box sx={{margin: 1}}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Торговые точки
                                                    </Typography>
                                                    <Table aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell
                                                                    sx={{fontWeight: 700}}>Найимнование</TableCell>
                                                                <TableCell sx={{fontWeight: 700}}>Количество</TableCell>
                                                                <TableCell sx={{fontWeight: 700}}>Цена (руб)</TableCell>
                                                                <TableCell sx={{fontWeight: 700}}>Сумма
                                                                    (руб)</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {data.map((item) => (
                                                                <TableRow key={item.id}>
                                                                    <TableCell>{item.name}</TableCell>
                                                                    <TableCell>{item.quantity} {item.packing}</TableCell>
                                                                    <TableCell>{Math.floor(item.price)}</TableCell>
                                                                    <TableCell>{Math.floor(item.sum)}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>

                                                :
                                                <Box sx={{position: 'relative', height: "40vh"}}>

                                                    <CircularProgress className="load"/>

                                                </Box>
                                            }
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </List>
    );
}
