import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DistributorDiagram = (props) => {
    // Передать в рейтинг и сравнить названия

    const state = {
        data: (props.age === 10) ? props.sum : (props.age === 20) ? props.sumRetail : (props.age === 30) ? props.sumSku : null,
        options: {
            chart: {
                type: 'donut',
            },

            colors: props.colorsArray,
            labels: props.labels,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            tooltip: {
                y: {
                    formatter: (value, {  dataPointIndex, w }) => {
                        props.setSelectedLabel(props.labels[dataPointIndex]);
                        return value;
                    }
                }
            },
            legend: {
                show: false // Установите это значение в false, чтобы скрыть легенду
            }
        },
    };


    return (
            // <div >
                <ReactApexChart options={state.options} series={state.data} type="donut"/>
            // </div>
    );
};

export default DistributorDiagram;