import React, {memo, useContext} from 'react';
import style from './DriverAndForwarderListItem.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {Link, useParams} from "react-router-dom";
import {ReactComponent as SettingIcon} from '../../../../imges/SettingIcon.svg';
import GlobalContext from "../../../../context/GlobalContext";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import pageDriverAndForwarderStore
    from "../../../../store/transportationLogisticsService/pageDriverAndForwarderStore/pageDriverAndForwarderStore";

const DriverAndForwarderListItem = ({docData}) => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const params = useParams();
    const {forwarder, driver_name, checked} = docData
    console.log(docData)

    const deleteTemplateById = async (cardId) => {
        try {
            await pageDriverAndForwarderStore.deleteCardById(cardId, authTokens, setUser, setAuthTokens, logoutUser);
            await pageDriverAndForwarderStore.getCardsByTypeList(params.typeList,authTokens, setUser, setAuthTokens, logoutUser, false)
        } catch (e) {

        }
    }

    const changeSelectedTemp = (idDoc, value) => {
        pageDriverAndForwarderStore.changeSelectedDocumentById(idDoc, value);
    }

    const getTypeDriver = (forwarder) => {
        if (forwarder){
            return "Экспедитор"
        } else {
            return "Водитель"
        }
    }

    return (
        <div className={style.templateListItem}>
            <div
                onClick={() => {
                    changeSelectedTemp(docData.id, !checked);
                }}
            >
                <input
                    type="checkbox"
                    className={style.selectAllTemplate}
                    id="selectTemplate"
                    name="selectTemplate"
                    checked={checked}
                    value={checked}
                    onChange={() =>{}}
                />
                <label htmlFor="selectTemplate"></label>
            </div>
            <div>{driver_name}</div>
            <div>{getTypeDriver(forwarder)}</div>
            <div><DeleteIcon onClick={() => deleteTemplateById(docData?.id)}/></div>
            <div><Link to={`/logisticService/driverAndForwarder/editCard/${docData?.id}`}><SettingIcon/></Link>
            </div>
        </div>
    );
};

export default memo(DriverAndForwarderListItem);