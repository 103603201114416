import React, {memo, useContext, useEffect, useRef} from 'react';
import style from './CustomisationRouteList.module.css'
import {observer} from 'mobx-react-lite';
import SettingOptimizationRouteByVehicle
    from "../SettingOptimizationRouteByVehicle/SettingOptimizationRouteByVehicle";
import DocumentOrderCustomer from "../../DocumentOrderCustomer/DocumentOrderCustomer";
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import settingOptimizationRouteSheetStore
    from '../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore'
import {v4 as uuidv4} from 'uuid';
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import {Tooltip} from "react-tooltip";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {ReactComponent as SettingIcon_v2} from '../../../../imges/SettingIcon_v2.svg';
import toast from "react-hot-toast";
import InitialPointPopupList from "../../InitialPointPopupList/InitialPointPopupList";
import GlobalContext from "../../../../context/GlobalContext";
import DeliversPopupList from "../../DeliversPopupList/DeliversPopupList";
import CustomisationRouteListHeaderTable from "../CustomisationRouteListHeaderTable/CustomisationRouteListHeaderTable";
import {useNavigate, useParams} from "react-router-dom";
import SkeletonEditRouteSheet from "../SkeletonEditRouteSheet/SkeletonEditRouteSheet";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';

const CustomisationRouteList = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {editedRouteSheet} = settingOptimizationRouteSheetStore;
    const {initial_point_route} = settingOptimizationRouteSheetStore.generalSettingForDocuments;
    const {pageState, routingSheets} = optimizationRouteSheetStore;
    const navigate = useNavigate();
    const {docId, UID} = useParams();
    const cache = useRef(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 51
    }))
    useEffect(() => {
        async function getRouteSheet() {
            await optimizationRouteSheetStore.getRouteSheetById(UID, docId, authTokens, setUser, setAuthTokens, logoutUser);
        }

        getRouteSheet();

        return () => {
            settingOptimizationRouteSheetStore.changeDefaultValueSettingDoc();
        }

    }, [docId]);

    const saveEditDocument = async () => {
        if (editedRouteSheet.in_route === true) {
            toastMassage(toast.error, "Документы которые в маршруте нельзя редактировать!", 10000, "bottom-right")
        } else {
            await settingOptimizationRouteSheetStore.saveEditRouteDocument(authTokens, setUser, setAuthTokens, logoutUser, navigate);
        }
    }

    const rowRenderer = ({index, key, style, parent}) => {
        const content =   <DocumentOrderCustomer
        key={uuidv4()}
        order={editedRouteSheet.order_list[index]}
        document={editedRouteSheet.order_list}
    />
        return (
            <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
            <div key={key} style={style}>
            {content}
            </div>
            </CellMeasurer>
        )
            ;
    };

    return (
        editedRouteSheet ?
        <div className={style.collapseSettingDocument}>
            <div className={style.goBackToList}
                 onClick={() => {
                     navigate(`/logisticService/routingByRouteList/routeSheetsList/${editedRouteSheet.document_date}`)
                 }}>Назад
            </div>
            <div className={style.collapseSettingDocument_header}>
                Индивидуальная настройка маршрутного листа:
                {editedRouteSheet?.document_number}
            </div>

            {/*Блок с настройками*/}
            <div className={style.collapseSettingVehicle}>
                {/*Блок с настройками для авто*/}
                {
                    !editedRouteSheet?.in_route ?
                        <>
                            <div className={style.customisationRouteBlockParams}>
                                <SettingOptimizationRouteByVehicle typeSetting={"settingForDocuments"}/>
                            </div>
                            {/*{Блок для выбора параметра оптимизации}*/}
                            <div className={style.customisationOptimizationRouteBlockParams}>
                                <div className={style.btnRouteSettings}
                                     onClick={() => {
                                         settingOptimizationRouteSheetStore.openPopupSettingOptimization(window.pageYOffset, "settingForDocuments_OptimizationParams")
                                     }}>
                                    <SettingIcon_v2/>
                                    <div>Параметры оптимизации маршрута</div>
                                </div>
                                <PopupRouting
                                    isOpen={pageState.popup_show && pageState.popup_type === "settingForDocuments_OptimizationParams"}
                                    onClose={() => settingOptimizationRouteSheetStore.closePopupSettingOptimization()}
                                    scrollPosition={pageState.popup_scroll_position}
                                >
                                    <div className={style.popupHeader}>
                                        Параметры оптимизации маршрута
                                        <div
                                            className={style.btnClosePopup}
                                            onClick={() => {
                                                optimizationRouteSheetStore.closePopupInitPoints()
                                            }}
                                        ><DeleteIcon/></div>
                                    </div>
                                    <div className={style.routeOptimizationParameters}>
                                        <div className={style.routeOptimizationParametersSelector}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    className={style.routeOptimizationOptionsCheckbox} с
                                                    id="optimizationForTime_settingForDocuments"
                                                    name="optimization_for_time"
                                                    value={settingOptimizationRouteSheetStore["settingForDocuments"]?.optimization_parameters_checked.optimization_for_time}
                                                    checked={settingOptimizationRouteSheetStore["settingForDocuments"]?.optimization_parameters_checked.optimization_for_time}
                                                    onChange={(e) => settingOptimizationRouteSheetStore.selectOptimizationParameter(e.target.name, true, "settingForDocuments")}
                                                />
                                                <label htmlFor="optimizationForTime_settingForDocuments">Оптимизировать
                                                    по
                                                    времени</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    className={style.routeOptimizationOptionsCheckbox}
                                                    id="optimizationForDistance_settingForDocuments"
                                                    name="optimization_for_distance"
                                                    value={settingOptimizationRouteSheetStore["settingForDocuments"]?.optimization_parameters_checked.optimization_for_distance}
                                                    checked={settingOptimizationRouteSheetStore["settingForDocuments"]?.optimization_parameters_checked.optimization_for_distance}
                                                    onChange={(e) => settingOptimizationRouteSheetStore.selectOptimizationParameter(e.target.name, true, "settingForDocuments")}
                                                />
                                                <label htmlFor="optimizationForDistance_settingForDocuments">Оптимизировать
                                                    по
                                                    расстоянию</label>
                                            </div>
                                        </div>
                                    </div>
                                </PopupRouting>
                            </div>
                        </>
                        : null
                }

                {/*Блок для выбора начальной точки маршрута для документа*/}
                <div className={style.routeOptimizationInitPoint}>
                    <div className={style.headerOptimizationInitPoint}>Начальная точка маршрута:</div>
                    <div
                        data-tooltip-id={`client-info_routeOptimizationInitPoint`}
                        data-tooltip-variant="info"
                        className={style.btnSelectInitPoint}
                        onClick={() => {
                            if (editedRouteSheet.in_route === true) {
                                toastMassage(toast.error, "Документы которые в маршруте нельзя редактировать!", 10000, "bottom-right")
                            } else {
                                optimizationRouteSheetStore.openPopupInitPoints(authTokens?.access, window.pageYOffset, "selectInitialPoint_EditDoc")
                            }

                        }}
                    >
                        <SettingIcon_v2/>
                        <div>{((editedRouteSheet.initial_route_point?.name?.length > 25) ? editedRouteSheet.initial_route_point?.name.slice(0, 25) + "..." : editedRouteSheet.initial_route_point?.name) || `Выбрать начальную точку`}</div>
                        {(editedRouteSheet.initial_route_point?.name?.length > 25) ? <Tooltip
                            id={`client-info_routeOptimizationInitPoint`}
                            content={initial_point_route}
                            place="right"
                            style={{zIndex: 5}}
                        /> : null}
                    </div>


                    <PopupRouting
                        isOpen={pageState.popup_show && pageState.popup_type === "selectInitialPoint_EditDoc"}
                        onClose={() => optimizationRouteSheetStore.closePopupInitPoints()}
                        scrollPosition={pageState.popup_scroll_position}
                        styles={{overflow: "hidden"}}
                    >
                        <InitialPointPopupList
                            setNewData={(addressData) => optimizationRouteSheetStore.setCustomInitialPointForDocument(addressData)}/>
                    </PopupRouting>
                </div>
                {/*Блок для выбора водителя для документа*/}
                <div className={style.routeOptimizationDrivers}>
                    <div className={style.headerOptimizationInitPoint}>Водитель:</div>
                    <div
                        className={style.btnSelectInitPoint}
                        onClick={() => {
                            if (editedRouteSheet.in_route === true) {
                                toastMassage(toast.error, "Документы которые в маршруте нельзя редактировать!", 10000, "bottom-right")
                            } else {
                                optimizationRouteSheetStore.openPopupInitPoints(authTokens?.access, window.pageYOffset, "selectDrivers_EditDoc")
                            }
                        }}
                    >
                        <SettingIcon_v2/>
                        <div>
                            {editedRouteSheet?.drivers_info[0]?.driver_name || `Выбрать водителя`}
                        </div>
                    </div>


                    <PopupRouting
                        isOpen={pageState.popup_show && pageState.popup_type === "selectDrivers_EditDoc"}
                        onClose={() => optimizationRouteSheetStore.closePopupInitPoints()}
                        scrollPosition={pageState.popup_scroll_position}
                        styles={{overflow: "hidden"}}
                    >
                        <DeliversPopupList
                            setNewData={(addressData) => optimizationRouteSheetStore.setCustomDeliveryForDocument(addressData)}/>
                    </PopupRouting>
                </div>
            </div>
            {/*Блок с кнопками для сохранения/отмены изменений*/}
            <div className={style.actionButtons}>
                <div
                    className={style.actionButtonsSave}
                    onClick={() => saveEditDocument()}
                >Сохранить
                </div>
                <div
                    className={style.actionButtonsCancelEdit}
                    onClick={() => {
                        if (editedRouteSheet.in_route === true) {
                            toastMassage(toast.error, "Документы которые в маршруте нельзя редактировать!", 10000, "bottom-right")
                        } else {
                            navigate(`/logisticService/routingByRouteList/routeSheetsList/${editedRouteSheet.document_date}`);
                        }
                    }}
                >Отменить изменения
                </div>
            </div>

            <CustomisationRouteListHeaderTable/>

            <>
                {
                    JSON.stringify(editedRouteSheet) &&
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <List
                                width={width}
                                height={712}
                                rowCount={editedRouteSheet.order_list.length} // Количество элементов в списке
                                deferredMeasurementCache={cache.current}
                                rowHeight={cache.current.rowHeight} 
                                // rowHeight={71} // Высота отдельного элемента списка
                                rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                className={style.customerDocumentListBlock}
                                overscanRowCount={10}

                            />
                        )}
                    </AutoSizer>

                }
            </>
        </div>
            :
            <SkeletonEditRouteSheet/>
    );
});

export default memo(CustomisationRouteList);