import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  Switch,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { generateLocalColorFromText } from "../../../helpers/helpers";
import GlobalColoredRoomIcon from "../DistributorGlobalGeographicalMap/GlobalColoredRoomIcon";
const DistributoeMapList = (props) => {
  const [topLevelOpenStates, setTopLevelOpenStates] = useState(
    Array(props.docs.length).fill(false)
  );
  const [localityOpenStates, setLocalityOpenStates] = useState(
    Array(props.docs.length).fill([])
  );
  const [districtOpenStates, setDistrictOpenStates] = useState(
    Array(props.docs.length).fill([])
  );

  const { setRegion, region } = useContext(GlobalContext);
  const [regionCheck, setRegionCheck] = useState(false);

  useEffect(() => {
    if (!regionCheck) {
      setRegion([]);
    }
  }, [regionCheck]);

  const [localityFilter, setLocalityFilter] = useState("");
  const [districtFilter, setDistrictFilter] = useState("");
  const [retailOutletFilter, setRetailOutletFilter] = useState("");

  const addRegionToState = (distributor) => {
    const isRegionInArray = region.some(
      (regionObj) => regionObj.id === distributor.distrId
    );
    if (!isRegionInArray) {
      setRegion([
        ...region,
        {
          id: distributor.distrId,
          distrName: distributor.distrName,
          distrRegion: distributor.distrRegion,
          sum: distributor.docSum,
        },
      ]);
    }
  };

  const handleToggleTopLevel = (index) => {
    setTopLevelOpenStates((prevStates) => {
      const newOpenStates = [...prevStates];
      newOpenStates[index] = !newOpenStates[index];
      return newOpenStates;
    });
  };

  const handleToggleLocality = (topIndex, subIndex) => {
    setLocalityOpenStates((prevStates) => {
      const newOpenStates = [...prevStates];
      newOpenStates[topIndex] = newOpenStates[topIndex] || [];
      newOpenStates[topIndex][subIndex] = !newOpenStates[topIndex][subIndex];
      return newOpenStates;
    });
  };

  const handleToggleDistrict = (topIndex, subIndex) => {
    setDistrictOpenStates((prevStates) => {
      const newOpenStates = [...prevStates];
      newOpenStates[topIndex] = newOpenStates[topIndex] || [];
      newOpenStates[topIndex][subIndex] = !newOpenStates[topIndex][subIndex];
      return newOpenStates;
    });
  };

  const areAllChecked = (array) => {
    return (
      array &&
      array.length > 0 &&
      array.every((item) => {
        // Предположим, что каждый элемент массива имеет свойство id
        return props.checked.some((checkedItem) => checkedItem.id === item.id);
      })
    );
  };

  const areAllCheckedCity = (array) => {
    return (
      array &&
      array.length > 0 &&
      array.every((item) =>
        item.retailOutletObjList.every((subItem) =>
          props.checked.some((checkedItem) => checkedItem.id === subItem.id)
        )
      )
    );
  };


  return (
    <>
        
          {/*<FormControlLabel*/}
          {/*  sx={{ marginLeft: "1vw" }}*/}
          {/*  checked={regionCheck}*/}
          {/*  required*/}
          {/*  control={<Switch onClick={() => setRegionCheck(!regionCheck)} />}*/}
          {/*  label="Регионы"*/}
          {/*/>*/}
       {props.docs.length > 0   ?
       <>
      {props.docs.map((distributor, topLevelIndex) => {
        if (regionCheck) {
          addRegionToState(distributor);
        }

        const labelId = `checkbox-list-secondary-label-${distributor.distrId}`;
        return (
          <>
          
            {!props.modal ? <Divider sx={{ width: 290 }} /> : null}
            <>
            <List
              sx={{
                overflow: !props.modal ? "auto" : "none",
                maxHeight: "84vh",
                
                paddingRight: "10px",
              }}
              key={distributor.distrId}
            >
              <ListItem
                sx={{
                  padding: "0",
                  // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                  borderRadius: "8px",
                  marginBottom: "5px",
                  height: "50px",
                }}
              >
                <GlobalColoredRoomIcon
                  color={generateLocalColorFromText(distributor.distrName)}
                />
                <ListItemText primary={distributor.distrName} />
                <IconButton
                  button
                  onClick={() => handleToggleTopLevel(topLevelIndex)}
                >
                  {topLevelOpenStates[topLevelIndex] ? (
                    <ExpandLess
                      sx={{
                        color: generateLocalColorFromText(
                          distributor.distrName
                        ),
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        color: generateLocalColorFromText(
                          distributor.distrName
                        ),
                      }}
                    />
                  )}
                </IconButton>
              </ListItem>
              <Divider/>
              <Collapse
                in={topLevelOpenStates[topLevelIndex]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListSubheader
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      border: "2px solid #52AB01",
                      // borderTopRightRadius: "8px",
                      // borderBottomRightRadius: "8px",
                      // marginBottom: "5px",
                    }}
                  >
                    <TextField
                      label={`Населенные пункты: ` + distributor.distrName}
                      value={localityFilter}
                      onChange={(e) => setLocalityFilter(e.target.value)}
                      fullWidth
                      // margin="normal"
                      variant="standard"
                    />
                  </ListSubheader>

                  {distributor.localityArr
                    .filter((localityArr) =>
                      localityArr.localityName
                        .toLowerCase()
                        .includes(localityFilter.toLowerCase())
                    )
                    .map((localityArr, subIndex) => {
                      if (
                        !localityArr ||
                        localityArr.localityName === "" ||
                        localityArr.localityName === "Null"
                      ) {
                        return null;
                      }

                      const localityStates =
                        localityOpenStates[topLevelIndex] || [];
                      const isLocalityOpen = localityStates[subIndex] || false;

                      return (
                        <React.Fragment key={subIndex}>
                          <ListItem
                            sx={{
                              padding: "0",
                              // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                              borderRadius: "8px",
                              marginBottom: "5px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#52AB01",
                                width: "5px",
                                height: "50px",
                                marginRight: props.modal ? "5px" : 0,
                              }}
                            />

                            {/* ///////////////////////////////////////// */}
                            {!props.modal ? (
                              <Checkbox
                                disabled={districtFilter != ""}
                                sx={{ marginRight: "5px" }}
                                edge="end"
                                onChange={props.handleToggleAllCity(
                                  localityArr,
                                  distributor.distrName
                                )}
                                checked={areAllCheckedCity(
                                  localityArr.districtsArr
                                )}
                              />
                            ) : null}
                            {/* ///////////////////////////////////////// */}
                            <ListItemText primary={localityArr.localityName} />
                            <IconButton
                              button
                              onClick={() =>
                                handleToggleLocality(topLevelIndex, subIndex)
                              }
                            >
                              {isLocalityOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </ListItem>
                          <Divider/>

                          <Collapse
                            in={isLocalityOpen}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListSubheader
                                sx={{
                                  // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                  border: "2px solid #3382BC",
                                  // borderTopRightRadius: "8px",
                                  // borderBottomRightRadius: "8px",
                                  marginBottom: "5px",
                                }}
                              >
                                <TextField
                                  label={`Районы: ` + localityArr.localityName}
                                  value={districtFilter}
                                  onChange={(e) =>
                                    setDistrictFilter(e.target.value)
                                  }
                                  fullWidth
                                  // margin="normal"
                                  variant="standard"
                                />
                              </ListSubheader>
                              {localityArr.districtsArr
                                .filter((districtsArr) =>
                                  districtsArr.districtName
                                    .toLowerCase()
                                    .includes(districtFilter.toLowerCase())
                                )
                                .map((districtsArr, subIndex) => {
                                  if (
                                    !districtsArr ||
                                    districtsArr.districtName === "" ||
                                    districtsArr.districtName === "Null"
                                  ) {
                                    return null;
                                  }

                                  const localityStates =
                                    districtOpenStates[topLevelIndex] || [];
                                  const isLocalityOpen =
                                    localityStates[subIndex] || false;

                                  return (
                                    <React.Fragment key={subIndex}>
                                      <ListItem
                                        sx={{
                                          padding: "0",
                                          // boxShadow:
                                          //   "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                                          // borderRadius: "8px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#3382BC",
                                            width: "5px",
                                            height: "50px",
                                            marginRight: props.modal
                                              ? "5px"
                                              : 0,
                                          }}
                                        />
                                        {!props.modal ? (
                                          <Checkbox
                                            sx={{ marginRight: "5px" }}
                                            edge="end"
                                            onChange={props.handleToggleAll(
                                              districtsArr.retailOutletObjList,
                                              distributor.distrName
                                            )}
                                            checked={areAllChecked(
                                              districtsArr.retailOutletObjList
                                            )}
                                          />
                                        ) : null}
                                        <ListItemText
                                          primary={districtsArr.districtName}
                                        />
                                        <IconButton
                                          button
                                          onClick={() =>
                                            handleToggleDistrict(
                                              topLevelIndex,
                                              subIndex
                                            )
                                          }
                                        >
                                          {isLocalityOpen ? (
                                            <ExpandLess />
                                          ) : (
                                            <ExpandMore />
                                          )}
                                        </IconButton>
                                      </ListItem>
                                      <Divider/>

                                      <Collapse
                                        in={isLocalityOpen}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List component="div" disablePadding>
                                          <ListSubheader
                                            sx={{
                                              // boxShadow:
                                              //   "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                              border: "2px solid #D76701",
                                              // borderTopRightRadius: "8px",
                                              // borderBottomRightRadius: "8px",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <TextField
                                              label={
                                                `Торговые точки: ` +
                                                districtsArr.districtName
                                              }
                                              value={retailOutletFilter}
                                              onChange={(e) =>
                                                setRetailOutletFilter(
                                                  e.target.value
                                                )
                                              }
                                              fullWidth
                                              // margin="normal"
                                              variant="standard"
                                            />
                                          </ListSubheader>

                                          {districtsArr.retailOutletObjList
                                            .filter((retailOutletObjList) =>
                                              retailOutletObjList.name
                                                .toLowerCase()
                                                .includes(
                                                  retailOutletFilter.toLowerCase()
                                                )
                                            )
                                            .map(
                                              (
                                                retailOutletObjList,
                                                subIndex
                                              ) => (
                                                <>
                                                <ListItem
                                                  key={subIndex}
                                                  sx={{
                                                    padding: "0",
                                                    // boxShadow:
                                                    //   "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                                                    // borderRadius: "8px",
                                                    marginBottom: "5px",
                                                    display:
                                                      retailOutletObjList &&
                                                      retailOutletObjList.coordinates ===
                                                        "null"
                                                        ? "none"
                                                        : "flex",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        "#D76701",
                                                      width: "5px",
                                                      height: "50px",
                                                      marginRight: props.modal
                                                        ? "5px"
                                                        : 0,
                                                    }}
                                                  />
                                                  {!props.modal ? (
                                                    <Checkbox
                                                      sx={{
                                                        marginRight: "5px",
                                                      }}
                                                      edge="end"
                                                      onChange={props.handleToggle(
                                                        {
                                                          ...(retailOutletObjList ||
                                                            {}),
                                                          color:
                                                            generateLocalColorFromText(
                                                              distributor.distrName
                                                            ),
                                                          distributorId:
                                                            distributor.distrId,
                                                        }
                                                      )}
                                                      checked={props.checked.some(
                                                        (item) =>
                                                          item.id ===
                                                          (
                                                            retailOutletObjList ||
                                                            {}
                                                          ).id
                                                      )}
                                                      inputProps={{
                                                        "aria-labelledby":
                                                          labelId,
                                                      }}
                                                    />
                                                  ) : null}
                                                  <ListItemText
                                                    primary={
                                                      (
                                                        retailOutletObjList ||
                                                        {}
                                                      ).name
                                                    }
                                                  />
                                                </ListItem>
                                                <Divider/>

                                                </>
                                                
                                              )
                                            )}
                                        </List>
                                      </Collapse>
                                    </React.Fragment>
                                  );
                                })}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      );
                    })}
                </List>
              </Collapse>
            </List>
            </>
          </>
        );
      })}
      </>
      : <Box>
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        <Skeleton sx={{margin: "5px"}} height={50} />
        </Box>}
    </>
  );
};

export default DistributoeMapList;
