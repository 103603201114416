import React, {useMemo, useState} from 'react';
import style from './PopoverSelector.module.css'
import "./PopoverSelector.css"
import {ReactComponent as ArrowUp} from '../../../imges/ArrowUp.svg';
import {ReactComponent as ArrowDown} from '../../../imges/ArrowDown.svg';

const PopoverSelector = ({ options, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useMemo(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !(event.target).closest('.PopoverSelector_popoverSelector')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    const handleOptionClick = (option) => {
        setIsOpen(false);
        setSelectedOption(option);
        onChange(option);
    };

    return (
        <div className={`PopoverSelector_customSelect PopoverSelector_popoverSelector`}>
            <div
                className={(!isOpen)? "PopoverSelector_selectSelected" : "PopoverSelector_selectSelected PopoverSelector_active"}
                 onClick={() => setIsOpen(!isOpen)}>
                {selectedOption || options[0]}
                <span>
                    {(isOpen)?<ArrowUp/>:<ArrowDown/>}
                </span>
            </div>
            {isOpen && (
                <div className={"PopoverSelector_selectItems"}>
                    {options.map((option, index) => (
                        <div key={index} onClick={() => handleOptionClick(option)}>
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PopoverSelector;