import React, {useCallback, useState} from 'react';
import style from './DeliveryVehiclesCreateForm.module.css'
import pageDeliveryVehiclesStore
    from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
import {observer} from "mobx-react-lite";
import debounce from "lodash/debounce";

const DeliveryVehiclesCreateForm = observer(() => {
    const [appellationValue, setAppellationValue] = useState('');
    const [licensePlateValue, setLicensePlateValue] = useState('');
    const [tonnageValue, setTonnageValue] = useState();
    const [vehicleBrandValue, setVehicleBrandValue] = useState('');

    const updateTemplate = useCallback(
        debounce((nameValue, newValue) => {
            pageDeliveryVehiclesStore.changeNewInputValue(nameValue, newValue, "createVehicleData");
        }, 200), // Задержка в миллисекундах
        []
    );

    const changeTemplateName = (nameValue, newValue) => {

        switch (nameValue) {
            case "appellation":
                setAppellationValue(newValue);
                break;
            case "license_plate":
                setLicensePlateValue(newValue);
                break;
            case  "tonnage":
                setTonnageValue(newValue);
                break;
            case "vehicle_brand":
                setVehicleBrandValue(newValue);
                break
            default:
                return
        }

        updateTemplate(nameValue, newValue)
    }


    return (
        <div className={style.createForm}>
            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                <span>Наименование:</span>
                <input type="text" required={true} value={appellationValue}
                       maxLength={100}
                       placeholder="Наименование..."
                       onChange={(e) => changeTemplateName("appellation", e.target.value)}/>
            </div>

            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                <span>Гос. номер:</span>
                <input type="text" required={true} value={licensePlateValue}
                       maxLength={100}
                       placeholder="Гос. номер..."
                       onChange={(e) => changeTemplateName("license_plate", e.target.value)}/>
            </div>

            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                <span>Марка автомобиля:</span>
                <input type="text" required={true} value={vehicleBrandValue}
                       maxLength={100}
                       placeholder="Марка автомобиля..."
                       onChange={(e) => changeTemplateName("vehicle_brand",e.target.value)}/>
            </div>

            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                <span>Грузоподъемность (тонны):</span>
                <input type="number" required={true} value={tonnageValue}
                       placeholder="Грузоподъемность..."
                       onChange={(e) => changeTemplateName("tonnage", e.target.value)}/>
            </div>
        </div>
    );
});

export default DeliveryVehiclesCreateForm;