import React, { useEffect, useState } from "react";
import {
    Badge,
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';

import Search from "../../ui/Search/Search";
import DistributorRetailChainsTable
    from "../../ManufacturerComponents/DistributorRetailChainsTable/DistributorRetailChainsTable";
function filterChainsTable(data, searchText) {
    return data.filter((item) => {
        const itemName = item.counteragentName.toLowerCase();
        // const itemArticle = item.nomenclatureObj.article.toLowerCase();
        // const itemBrand = item.nomenclatureObj.manBrand.toLowerCase();
        return (
            itemName.includes(searchText.toLowerCase())
            // ||
            //   itemArticle.includes(searchText.toLowerCase()) ||
            //   itemBrand.includes(searchText.toLowerCase())
        );
    });
}

const ManufacturerRetailChainsTable = ({
                                    selectAll,
                                    handleSelectAll,
                                    items,
                                    handleClick,
                                    handleSelectLocal,
                                }) => {

    const [searchComplete, setSearchComplete] = useState("")

    const checkAllChecked = (outletsList) => {
        return outletsList.every((check) => check.checked);
    };

    const countCheckedOutlets = (outletsList) => {
        return checkAllChecked(outletsList) ? outletsList.length : 0;
    };





    return (
        <Box sx={{ width: "40%", overflow: "hidden" }}>
            <TableContainer sx={{ height: "73vh" }}>
                <Table stickyHeader aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormControlLabel
                                    sx={{marginLeft: "10px"}}
                                    control={<Checkbox
                                        edge="start"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                        disableRipple
                                        // lable={""}
                                    />} label="Все" />


                            </TableCell>
                            <TableCell>
                                <Search
                                    searchComplete={searchComplete}
                                    setSearchComplete={setSearchComplete}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterChainsTable(items, searchComplete).map((sectionId) => (
                            <TableRow
                            onClick={() => handleSelectLocal(sectionId.retailOutletsList)}

                                key={sectionId.id} // Гарантирует уникальный ключ для каждой строки
                                className="hoverable-row"
                                // onClick={() => handleClick(sectionId)}
                            >
                                <TableCell>
                                    <Badge
                                        badgeContent={countCheckedOutlets(sectionId.retailOutletsList)}
                                        color="success"
                                    >
                                        <Checkbox
                                            sx={{ marginLeft: "5px" }}
                                            edge="start"
                                            checked={checkAllChecked(sectionId.retailOutletsList)}
                                            // onChange={() => handleSelectLocal(sectionId.retailOutletsList)}
                                            disableRipple
                                        />
                                    </Badge>
                                </TableCell>
                                <TableCell sx={{ fontSize: "80%" }}>
                                    {sectionId.counteragentName}
                                </TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ManufacturerRetailChainsTable;
