import React, {memo} from 'react';
import style from './CustomisationRouteListHeaderTable.module.css'
import {observer} from "mobx-react-lite";
import {ReactComponent as ArrowUp} from '../../../../imges/ArrowUp.svg';
import {ReactComponent as ArrowDown} from '../../../../imges/ArrowDown.svg';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import settingOptimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';

const CustomisationRouteListHeaderTable = observer(() => {
    const {editedRouteSheet} = settingOptimizationRouteSheetStore
    const {sort_name, sort_value} = settingOptimizationRouteSheetStore.orderListTableSortState;
    const {setting_document_id, checked_all_document_order} = optimizationRouteSheetStore.pageState;

    return (
        <>
            <div className={style.collapseSettingDocument_header}>
                <div className={style.searchTable}>
                    <input
                        type="text"
                        placeholder="Поиск..."
                        value={settingOptimizationRouteSheetStore.searchState.table_search_value}
                        onChange={(e) => settingOptimizationRouteSheetStore.changeSearchAddress(e.target.value,  editedRouteSheet.document_id)}
                    />
                    <span
                        onClick={
                            (e) => settingOptimizationRouteSheetStore.changeSearchAddress("",  editedRouteSheet.document_id)
                        }
                    >
                    <DeleteIcon/>
                </span>
                </div>

                Список заказов
            </div>

            <div className={style.routeListHeaderSelectDocument}>
                <div>
                    <input
                        type="checkbox"
                        className={style.selectDocumentOrderAll}
                        id="selectDocumentOrderAll"
                        name="selectDocumentOrderAll"
                        checked={checked_all_document_order}
                        value={checked_all_document_order}
                        onChange={() => {
                            optimizationRouteSheetStore.selectAllDocumentOrder(!checked_all_document_order, setting_document_id)
                        }}
                    />
                    <label htmlFor="selectDocumentOrderAll"></label>
                </div>
                <div
                    onClick={() => {
                        if (editedRouteSheet.order_list.length > 0) {
                            settingOptimizationRouteSheetStore.changeSortCollTableRouteList("order_number");
                            settingOptimizationRouteSheetStore.selectSortedOrderList("order_number", editedRouteSheet.document_id)
                        } else {
                            toastMassage(toast.error, "Отсутствуют заказы в документе", 10000, "bottom-right")
                        }

                    }}
                >
                    Номер
                    <div>
                    <span
                        style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "order_number" || sort_name !== "order_number") ? "visible" : "hidden"}}
                        className={(sort_value === 1 && sort_name === "order_number") ? style.activeArrow : ""}
                    >
                        <ArrowUp/>
                    </span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "order_number" || sort_name !== "order_number") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "order_number") ? style.activeArrow : ""}
                        >
                        <ArrowDown/>
                    </span>
                    </div>
                </div>
                <div
                    onClick={() => {
                        if (editedRouteSheet.order_list.length > 0) {
                            settingOptimizationRouteSheetStore.changeSortCollTableRouteList("order_client");
                            settingOptimizationRouteSheetStore.selectSortedOrderList("order_client", editedRouteSheet.document_id)
                        } else {
                            toastMassage(toast.error, "Отсутствуют заказы в документе", 10000, "bottom-right")
                        }
                    }}
                >Клиент
                    <div>
                    <span
                        style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "order_client" || sort_name !== "order_client") ? "visible" : "hidden"}}
                        className={(sort_value === 1 && sort_name === "order_client") ? style.activeArrow : ""}
                    >
                        <ArrowUp/>
                    </span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "order_client" || sort_name !== "order_client") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "order_client") ? style.activeArrow : ""}
                        >
                        <ArrowDown/>
                    </span>
                    </div>
                </div>
                <div
                    onClick={() => {
                        if (editedRouteSheet.order_list.length > 0) {
                            settingOptimizationRouteSheetStore.changeSortCollTableRouteList("order_adress_delivery");
                            settingOptimizationRouteSheetStore.selectSortedOrderList("order_adress_delivery", editedRouteSheet.document_id)
                        } else {
                            toastMassage(toast.error, "Отсутствуют заказы в документе", 10000, "bottom-right")
                        }
                    }}
                >Адрес доставки
                    <div>
                    <span
                        style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "order_adress_delivery" || sort_name !== "order_adress_delivery") ? "visible" : "hidden"}}
                        className={(sort_value === 1 && sort_name === "order_adress_delivery") ? style.activeArrow : ""}
                    >
                        <ArrowUp/>
                    </span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "order_adress_delivery" || sort_name !== "order_adress_delivery") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "order_adress_delivery") ? style.activeArrow : ""}
                        >
                        <ArrowDown/>
                    </span>
                    </div>
                </div>
                <div
                    onClick={() => {
                        if (editedRouteSheet.order_list.length > 0) {
                            settingOptimizationRouteSheetStore.changeSortCollTableRouteList("vehicle_tonnage");
                            settingOptimizationRouteSheetStore.selectSortedOrderList("vehicle_tonnage", editedRouteSheet.document_id)
                        } else {
                            toastMassage(toast.error, "Отсутствуют заказы в документе", 10000, "bottom-right")
                        }
                    }}
                >Тоннаж
                    <div>
                    <span
                        style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "vehicle_tonnage" || sort_name !== "vehicle_tonnage") ? "visible" : "hidden"}}
                        className={(sort_value === 1 && sort_name === "vehicle_tonnage") ? style.activeArrow : ""}
                    >
                        <ArrowUp/>
                    </span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "vehicle_tonnage" || sort_name !== "vehicle_tonnage") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "vehicle_tonnage") ? style.activeArrow : ""}
                        >
                        <ArrowDown/>
                    </span>
                    </div>
                </div>
                <div>Удалить <span>Скоро</span></div>
                <div>Перенести <span>Скоро</span></div>
            </div>
        </>);
});

export default memo(CustomisationRouteListHeaderTable);