import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import ProfileDistributorList from "../../components/ProfileDistributorList/ProfileDistributorList";
import ProfileNomenclatureList from "../../components/ProfileNomenclatureList/ProfileNomenclatureList";
import "./UserPage.css";
// import DistributorReloadData from "../../components/DistributorReloadData/DistributorReloadData";
import {
    Avatar,
    List,
    ListItemText,
    Typography
} from "@mui/material";
import {useContext} from "react";
import ProfileDistributorNomenclatureList
    from "../../components/ProfileDistributorNomenclatureList/ProfileDistributorNomenclatureList";
import ProfileDistributorRegisterDate
    from "../../components/ProfileDistributorRegisterDate/ProfileDistributorRegisterDate";
import ProfileDistributorUserList from "../../components/ProfileDistributorUserList/ProfileDistributorUserList";
import ProfileManufacturerUserList from "../../components/ProfileManufacturerUserList/ProfileManufacturerUserList";
import ProfileSettingsPassword from "../../components/ProfileSettingsPassword/ProfileSettingsPassword";
import ProfiledManufacturersList from "../../components/ProfiledManufacturersList/ProfiledManufacturersList";
import GlobalContext from "../../context/GlobalContext";
import {useAxiosAPI} from "../../apiRequest/apiRequest";
import ProfileInfoUserLeftBox from "../../components/ProfileInfoUserLeftBox/ProfileInfoUserLeftBox";
import ProfileTarifs from "../../components/ProfileTarifs/ProfileTarifs";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const UserPage = () => {
    const [value, setValue] = React.useState(0);
    let {user} = useContext(GlobalContext);
    const {state} = useContext(GlobalContext);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className={"user-page"}>
            {user.accessLevel == 1 ? (
                <>
                    {state.manufacturer ?
                        <Box className={"profile-card"}>
                            <ProfileInfoUserLeftBox userProfile={state.manufacturer}/>
                            <Box className={"profile-content-user"}>
                                <Box sx={{display: "flex", height: "8vh"}}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        {user.mainUser === "true" ? (
                                            <Tab label="Пользователи" {...a11yProps(0)} />
                                        ) : null}
                                        <Tab label="Дистрибьюторы" {...a11yProps(1)} />
                                        <Tab label="Номенклатура" {...a11yProps(2)} />
                                        <Tab label="Настройки" {...a11yProps(3)} />
                                        <Tab label="Мои сервисы" {...a11yProps(4)} />


                                    </Tabs>
                                </Box>
                                <Divider/>
                                <CardContent sx={{height: "87vh"}}>

                                    {user.mainUser === "true" ? (
                                        <TabPanel value={value} index={0}>
                                            <Box>
                                                <ProfileManufacturerUserList/>
                                            </Box>
                                        </TabPanel>
                                    ) : null}
                                    <TabPanel value={value} index={1}>
                                        <ProfileDistributorList/>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <ProfileNomenclatureList/>
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <ProfileSettingsPassword/>
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        <ProfileTarifs/>
                                    </TabPanel>
                                </CardContent>
                            </Box>
                        </Box>
                        : null}
                </>
            ) : user.accessLevel == 0 ? (
                <>
                    {state.profileDistributors ?
                        <Box className={"profile-card"}>
                            <ProfileInfoUserLeftBox userProfile={state.profileDistributors}/>
                            <Box className={"profile-content-user"}>
                                <Box sx={{display: "flex", height: "8vh"}}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        {user.mainUser === "true" ? (
                                            <Tab label="Пользователи" {...a11yProps(0)} />
                                        ) : null}
                                        <Tab label="Регистр выгрузок" {...a11yProps(1)} />
                                        <Tab label="Производители" {...a11yProps(2)} />
                                        <Tab label="Номенклатура" {...a11yProps(3)} />
                                        <Tab label="Настройки" {...a11yProps(4)} />
                                        <Tab label="Мои сервисы" {...a11yProps(5)} />

                                    </Tabs>
                                </Box>
                                <Divider/>
                                <CardContent sx={{height: "87vh"}}>
                                    {user.mainUser === "true" ? (
                                        <TabPanel value={value} index={0}>
                                            <Box>
                                                <ProfileDistributorUserList/>
                                            </Box>
                                        </TabPanel>
                                    ) : null}
                                    <TabPanel value={value} index={1}>
                                        <ProfileDistributorRegisterDate/>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <ProfiledManufacturersList/>
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <ProfileDistributorNomenclatureList/>
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        <ProfileSettingsPassword/>
                                    </TabPanel>
                                    <TabPanel value={value} index={5}>
                                        <ProfileTarifs/>
                                    </TabPanel>
                                    
                                </CardContent>
                            </Box>
                        </Box>
                        : null}
                </>
            ) : null}
        </Box>
    );
};
export default UserPage;
