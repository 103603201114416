import React, {memo, useContext, useEffect} from 'react';
import style from "./DeliversRoutesPage.module.css";
import {observer} from "mobx-react-lite";
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import deliveryRoutes from '../../store/transportationLogisticsService/deliversRoutes/deliversRoutes'
import DateSelectionField from "../../inputs/logisticService/DatePicker/DatePicker";
import DeliversRoutesList from "../../components/transportationLogisticsService/DeliversRoutesPageComponents/DeliversRoutesList/DeliversRoutesList";
import GlobalContext from "../../context/GlobalContext";
import deliversRoutes from "../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import {Toaster} from "react-hot-toast";
import DeliversRoutesSelectedItem
    from "../../components/transportationLogisticsService/DeliversRoutesPageComponents/DeliversRoutesSelectedItem/DeliversRoutesSelectedItem";
import {ReactComponent as UpdateIcon} from '../../imges/UpdateIcon.svg';
import optimizationRouteSheetStore
    from "../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import {animateScroll} from "react-scroll";


const DeliversRoutesPage = observer(() => {
    const {deliversRoutesPage: {uploadDate, viewDeliver}} = deliversRoutes;
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {popup_type, popup_show, popup_scroll_position} = deliversRoutes.deliversRoutesPage
    const uploadDocuments = async () => {
        await deliveryRoutes.uploadDeliversDocuments(authTokens, setUser, setAuthTokens, logoutUser);
    }

    useEffect(() => {
        const intervalId = setInterval(uploadDocuments, 5 * 60 * 1000); // 5 минут в миллисекундах
        uploadDocuments();
        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    // useEffect(()=> {
    //     console.log(window.innerHeight, "max-height")
    //     // Здесь можно выполнить скролл после полной загрузки
    //        setTimeout(()=>{
    //            window.scrollBy(0, 400);
    //        }, 1)
    //     // console.log(window.scrollTo(0, popup_scroll_position))
    // }, [viewDeliver])

    const uploadsDeliversDocs = async (newData) => {
        if (newData !== undefined && newData !== uploadDate) {
            deliversRoutes.changeUpLoadDocumentDate(newData);
            await deliversRoutes.uploadDeliversDocuments(authTokens, setUser, setAuthTokens, logoutUser);
        }
    }

    return (
        <>
            <div className={style.menuBlock}>
                <MainMenuLogisticsService namePage={"Мониторинг маршрутов"}/>
            </div>
            <div className={style.deliversRoutesPage}>
                <Toaster
                    containerStyle={{
                        position: 'fixed',
                        top: '80px'
                    }}
                />

                <div className={style.pageParams}>

                    <div className={style.selectedDate}>
                        <div className={style.contentSelectedDate}>
                            <span>Маршруты за:</span>
                            <DateSelectionField
                                value={uploadDate}
                                onChange={async (newData) => {
                                    await uploadsDeliversDocs(newData)
                                }}
                            />
                        </div>
                    </div>
                    <button
                        className={style.updateCoordinateDocument}
                        onClick={() => uploadDocuments()}
                    >
                        <UpdateIcon/>
                        Синхронизировать данные
                    </button>

                </div>

                {
                    (viewDeliver) ? <DeliversRoutesSelectedItem/> : <DeliversRoutesList/>
                }

            </div>
        </>
    );
});

export default memo(DeliversRoutesPage);