import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Search from "../../ui/Search/Search";
import { useAxiosAPI } from "../../../apiRequest/apiRequest";
import { dateConversion } from "../../../helpers/helpers";

// Фильтр для поиска в главной таблице
function filterChainsTable(data, searchText) {
  const lowerSearchText = searchText.toLowerCase();
  return data.filter((item) => {
    const documentNumber = item.documentNumber.toLowerCase();
    return documentNumber.includes(lowerSearchText);
  });
}

// Фильтр для поиска в дочерней таблице
function filterSubTable(data, searchText) {
  const lowerSearchText = searchText.toLowerCase();
  return data.filter((item) => {
    const name = item.nomenclatureObj.manNomName.toLowerCase();
    const article = item.nomenclatureObj.article.toLowerCase();
    return name.includes(lowerSearchText) || article.includes(lowerSearchText);


  });
}

const DistributorReatailDocsList = ({ retailId, distr }) => {
  const [searchComplete, setSearchComplete] = useState("");
  const [docs, setDocs] = useState([]);
  const [load, setLoad] = useState(true);
  const { apiGetRetailDocs } = useAxiosAPI();

  useEffect(() => {
    setLoad(true);
    apiGetRetailDocs(distr, retailId)
      .then((resolve) => {
        setDocs(resolve.data);
        setLoad(false);
      })
      .catch(() => {
        console.log("Ошибка");
        setLoad(false);
      });
  }, [retailId]);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [subTableSearch, setSubTableSearch] = useState("");

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.documentNumber}</TableCell>
          <TableCell>{dateConversion(row.documentDate)}</TableCell>
          <TableCell colSpan={2}>{row.typeOperation}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Search
                  searchComplete={subTableSearch}
                  setSearchComplete={setSubTableSearch}
                />
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Найминование</TableCell>
                      <TableCell>Артикль</TableCell>
                      <TableCell>Группа</TableCell>
                      <TableCell>Бренд</TableCell>
                      <TableCell>Количество</TableCell>
                      <TableCell>Цена</TableCell>
                      <TableCell>Сумма</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterSubTable(row.docTable, subTableSearch).map((rowList) => (
                      <TableRow key={rowList.id}>
                        <TableCell component="th" scope="row">
                          {rowList.nomenclatureObj.manNomName}
                        </TableCell>
                        <TableCell>{rowList.nomenclatureObj.article}</TableCell>
                        <TableCell>{rowList.nomenclatureObj.manGroup_name}</TableCell>
                        <TableCell>{rowList.nomenclatureObj.manBrand}</TableCell>
                        <TableCell>{rowList.quantity}</TableCell>
                        <TableCell>{rowList.price}</TableCell>
                        <TableCell>{rowList.sum}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
      <Box sx={{ display: "flex" }}>
      </Box>
      <TableContainer sx={{ height: "75vh" }}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Номер документа</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Дата документа</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Тип операции</TableCell>
              <TableCell>
                <Search
                  searchComplete={searchComplete}
                  setSearchComplete={setSearchComplete}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterChainsTable(docs, searchComplete).map((row) => (
              <Row key={row.documentNumber} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </List>
  );
};

export default DistributorReatailDocsList;
