import React, {memo, useCallback, useEffect, useState} from 'react';
import style from './DeliveryAreaMapPopup.module.css'
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';

const DeliveryAreaMapPopup = observer(({selectedAreaId}) => {
    const { popupShow } = createRouteSheetsStore.popupState
    const {areaSelectOrdersTotalData:{areaId, totalOrders, totalOrdersInRoute, totalAvailableRoute,totalVehicleTonnage, totalVolumeGoods}, } = createRouteSheetsStore;
    const [height, setHeight] = useState(null);

    useEffect(() => {
        if (totalAvailableRoute > 0){
            setHeight(190)
        } else {
            setHeight(100)
        }

    }, [areaId])

    useEffect(() => {
        console.log(selectedAreaId, "selectedAreaId")
        async function getAreaData(){
            await  createRouteSheetsStore.initializationDeliveryAreaData(selectedAreaId);
        }

        getAreaData();


        return () => {
            createRouteSheetsStore.changeDefaultPopupValue()
        }

    }, []);

    const createRouteSheet = useCallback(() => {
        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "createRouteSheetModal");
    }, []);

    const addToTheRouteSheet = () => {
        const {routeSheetsList} = createRouteSheetsStore
        if (routeSheetsList.length === 0){
            toastMassage(toast.error, "Нет созданных маршрутных листов!", 10000, "bottom-right")
            return
        }

        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "addInRouteSheet");
    }

    const showPopup = () => {
        if(selectedAreaId === areaId){
            return true
        } else {
            return false
        }
    }

    return (
        <>
            {
                showPopup() && <div style={{
                    minHeight: height
                }} className={style.popupContentMap}>
                    <div className={style.popupContentHeader}>Информация по зоне доставки</div>
                    <div className={style.countingOrder}>
                        <div className={style.popupContentName}>Заказов в
                            зоне: <span>{totalOrders || 0}</span></div>
                        <div className={style.popupContentName}>Заказов в
                            маршруте: <span>{totalOrdersInRoute || 0}</span></div>
                        <div className={style.popupContentName}>Заказов не в
                            маршруте: <span>{totalAvailableRoute || 0}</span></div>
                    </div>
                    <div className={style.countingOrder}>
                        <div className={style.popupContentName}>Общий вес
                            заказов: <span>{Number(totalVehicleTonnage || 0).toFixed(2)} кг</span>
                        </div>
                        <div className={style.popupContentName}>Общий объем
                            заказов: <span>{Number(totalVolumeGoods || 0).toFixed(3)} м3</span></div>
                    </div>
                    {
                        (totalOrders > 0 && totalOrders !== totalOrdersInRoute) &&
                        <div className={style.buttonsActive}>
                            <button className={style.buttonActive}
                                    onClick={() => createRouteSheet()}
                            >Создать маршрутный лист c заказами
                            </button>
                            <button className={style.buttonActive}
                                    onClick={() => addToTheRouteSheet()}
                            >Добавить заказы в маршрутный лист
                            </button>
                        </div>
                    }
                </div>
            }
        </>
    );
});

export default memo(DeliveryAreaMapPopup);