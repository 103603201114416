import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Alert, Button, Checkbox, FormControlLabel, TableHead } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import Loader from "../Loader/Loader";
import ProfileDistributorModalMapping from "../ProfileDistributorModalMapping/ProfileDistributorModalMapping";
import ProfileManufacturerMenuNomen from "../ProfileManufacturerMenuNomen/ProfileManufacturerMenuNomen";
import Search from "../ui/Search/Search";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

function filterNomenclatureTable(data, searchText) {
  return data.filter((item) => {
    const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
    const itemArticle = item.article.toLowerCase(); // Получаем поле "article" объекта
    return itemName.includes(searchText.toLowerCase()) || itemArticle.includes(searchText.toLowerCase());
  });
}

function filterNomenclatureСomparison(data, searchText) {
  return data.filter((item) => {
    const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
    const itemArticle = item.article.toLowerCase(); // Получаем поле "article" объекта
    return itemName.includes(searchText.toLowerCase()) || itemArticle.includes(searchText.toLowerCase());
  });
}


export default function ProfileDistributorNomenclatureList() {
  const { state } = useContext(GlobalContext);
  const { apiDistibutorNomenclature } = useAxiosAPI();
  const [loader, setLoader] = useState(true);
  const [nomenclature, setNomenclature] = useState([]);
  const [selectedItemsButton, setSelectedItemsButton] = useState(false);
  const [dataNumDistributor, setDataNumDistributor] = useState([]);
  // console.log(dataNumDistributor)
  const [open, setOpen] = useState(false)
  const [checkedNomenclatureМatched, setCheckedNomenclatureМatched] = React.useState(false);
  const [manufacturerNomenclature, setManufacturerNomenclature] = useState(null);
  const [distributorNomenclature, setDistributorNomenclature] = useState(null);
  const [activeRowDistributor, setActiveRowDistributor] = useState(null);
  const [searchComplete, setSearchComplete] = useState("")
  const [activDistributor, setActivDistributor] = useState("")
  const manufacturers = state.profileManufacturersList;
  const handleTableRowClickDistributor = (row, manNom) => {
    setActiveRowDistributor(row); // Записать активную строку в состояние

    setDistributorNomenclature(row);
    setManufacturerNomenclature(manNom);

    setOpen(true)
  };

  const [dataNumDistributorList, setDataNumDistributorList] = useState(dataNumDistributor);
  const [dataNumManufacturerList, setDataNumManufacturerList] = useState(dataNumDistributor);

  useEffect(() => {
    setDataNumDistributorList(dataNumDistributor);
  }, [dataNumDistributor]);

  const handleChangeNomenclatureМatched = (event) => {
    setCheckedNomenclatureМatched(event.target.checked);
    if (event.target.checked && dataNumDistributor) {
      setDataNumDistributorList(
        dataNumDistributor.filter(
          (element) => element.isFoundRelativeManNom === false
        )
      );
    } else {
      setDataNumDistributorList(dataNumDistributor);
    }
  };

  function isRelativeManNomNotFound() {
    // Проверяем каждый элемент массива
    for (let i = 0; i < dataNumDistributor.length; i++) {
      // Если параметр isFoundRelativeManNom равен false, возвращаем true
      if (dataNumDistributor[i].isFoundRelativeManNom === false) {
        return true;
      }
    }
    // Если не найдено ни одного элемента с isFoundRelativeManNom === false, возвращаем false
    return false;
  }

  const handleClear = () => {
    setDataNumDistributor([]);
    setActiveRowDistributor(null);
    setManufacturerNomenclature(null);
    setCheckedNomenclatureМatched(false)
    setSelectedItemsButton(true)
  };
  useEffect(() => {
    setLoader(true);
    apiDistibutorNomenclature()
      .then(() => {

        setNomenclature(state.distibutorNomenclature);
        setLoader(false);
      })
      .catch(() => {
        setNomenclature(state.distibutorNomenclature);
        setLoader(false);
      });
  }, [apiDistibutorNomenclature, state.profileDistributors]);

  function matchedNomenclature (id, manufacturerNomenclatures) {
    const result = manufacturerNomenclatures.filter((value) => value.id === id)
    return (result[0])
  }

  return (
      <Box sx={{width: "100%", height: "85vh"}}>
        {dataNumDistributor.length < 1 ? (
            <>
              {!selectedItemsButton ?
                  <Paper sx={{width: "100%", borderRadius: 0, boxShadow: 0, maxHeight: "85vh"}}>
                    <TableContainer
                        sx={{
                          maxHeight: "calc(84vh - 16px)",
                          overflow: "auto",
                        }}>
                      <Table stickyHeader aria-label="sticky table">

                        <TableHead>
                          <TableRow>

                            <TableCell colSpan={2}>
                              <Box sx={{display: "flex"}}>
                                <Search
                                    searchComplete={searchComplete}
                                    setSearchComplete={setSearchComplete}
                                />
                                <Button
                                    sx={{marginLeft: "1vw"}}
                                    onClick={() =>
                                        setSelectedItemsButton(!selectedItemsButton)
                                    }
                                    variant="contained"
                                >
                                  Сопоставить
                                </Button>
                              </Box>

                            </TableCell>
                            <TableCell sx={{fontWeight: 700}}>Бренд</TableCell>
                            <TableCell sx={{fontWeight: 700}}> Артикул</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filterNomenclatureTable(nomenclature, searchComplete).map((row) => {
                            const labelId = row.id;
                            return (
                                <TableRow key={row.id}>
                                  <TableCell><LabelImportantIcon color="primary"/></TableCell>
                                  <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                  >

                                    {row.name}
                                  </TableCell>
                                  <TableCell align="left">{row.brand}</TableCell>
                                  <TableCell align="left">{row.article}</TableCell>
                                </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Loader open={loader}/>
                  </Paper>
                  :
                  <ProfileManufacturerMenuNomen
                      setActivDistributor={setActivDistributor}
                      open={selectedItemsButton}
                      setOpen={setSelectedItemsButton}
                      manufacturers={manufacturers}
                      setDataNumDistributor={setDataNumDistributor}
                  />
              }
            </>
        ) : (
            <Paper sx={{width: "100%", borderRadius: 0, boxShadow: 0}}>
              <Box>
                <Box sx={{display: "flex"}}>

                  {isRelativeManNomNotFound() ?
                      <Alert severity="error">Некоторые элементы не сопоставлены, это может исказить
                        информацию
                        при выводе данных. Пожалуйста, проведите ручное сопоставление.</Alert>
                      : null}
                </Box>
                <Box sx={{display: "flex", margin: "10px"}}>
                  <Search
                      searchComplete={searchComplete}
                      setSearchComplete={setSearchComplete}
                  />
                  {isRelativeManNomNotFound() ?
                      <FormControlLabel
                          sx={{marginLeft: "10px"}}
                          control={
                            <Checkbox
                                checked={checkedNomenclatureМatched}
                                onChange={handleChangeNomenclatureМatched}
                                inputProps={{"aria-label": "controlled"}}
                            />
                          }
                          label="Только не сопоставленная"
                      />
                      : null}
                </Box>

                {distributorNomenclature ? (
                    <ProfileDistributorModalMapping
                        setDataNumDistributor={setDataNumDistributor}
                        setNomenclature={setNomenclature}
                        nomenclature={nomenclature}
                        open={open}
                        setOpen={setOpen}
                        setLoader={setLoader}
                        activeRow={activDistributor}

                        distributorNomenclature={distributorNomenclature}
                        manufacturerNomenclature={manufacturerNomenclature}/>
                ) : null}
              </Box>

              <Divider sx={{width: "100%"}}/>
              <Box sx={{display: "flex", width: "100%"}}>

                <TableContainer
                    sx={{
                      maxHeight: "calc(74vh - 16px)",
                      overflow: "auto",
                    }}
                >

                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>

                        <TableCell sx={{fontWeight: 700, width: "25%"}}>
                          <IconButton sx={{marginRight: "10px"}}
                                      onClick={() => handleClear([])}>
                            <ArrowCircleLeftIcon color="primary"/>
                          </IconButton>

                          {state.profileDistributors.name}

                        </TableCell>

                        <TableCell sx={{fontWeight: 700, width: "12.5%"}}>Бренд</TableCell>
                        <TableCell sx={{fontWeight: 700, width: "12.5%"}}>Артикул</TableCell>
                        <TableCell sx={{fontWeight: 700, width: "5%"}}></TableCell>
                        <TableCell sx={{fontWeight: 700, width: "20%"}}>
                          {activDistributor.name}
                        </TableCell>
                        <TableCell sx={{fontWeight: 700, width: "12.5%"}}>Бренд</TableCell>
                        <TableCell sx={{fontWeight: 700, width: "12.5%"}}>Артикул</TableCell>
                      </TableRow>
                    </TableHead>
                    {dataNumDistributorList.length > 0 ?
                    <TableBody>
                      {filterNomenclatureСomparison( nomenclature, searchComplete).map((row) => {
                        const labelId = row.id;
                        const isActive = activeRowDistributor === row;
                        return (
                            <TableRow
                                key={row.id}
                                onClick={() => handleTableRowClickDistributor(row, matchedNomenclature(row.relativeManNomId, dataNumDistributorList))} // Добавьте обработчик клика здесь
                                sx={{
                                  backgroundColor: isActive ? "#84e084" : "#ffffff",
                                  "&:hover": {
                                    backgroundColor: !isActive ? "#f0f0f0" : null, // Цвет при наведении, если не активна
                                  },
                                }}
                            >

                              <TableCell
                                  sx={{paddingLeft: "1vw"}}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell align="left">{row.brand}</TableCell>
                              <TableCell align="left">{row.article}</TableCell>

                              <TableCell>
                                {!row.isFoundRelativeManNom ? (
                                    <ErrorOutlineIcon sx={{color: "#ff0000"}}/>
                                ) : (
                                    <CheckCircleIcon sx={{color: "#0bda51"}}/>
                                )}
                              </TableCell>
                              <TableCell
                                  align="left">{row.relativeManNomId  ? matchedNomenclature(row.relativeManNomId, dataNumDistributorList).name : "Неопределенно!"}</TableCell>
                              <TableCell
                                  align="left">{row.relativeManNomId  ? matchedNomenclature(row.relativeManNomId, dataNumDistributorList).brand : null}</TableCell>
                              <TableCell
                                  align="left">{row.relativeManNomId  ? matchedNomenclature(row.relativeManNomId, dataNumDistributorList).article : null}</TableCell>
                            </TableRow>
                        );
                      })}
                    </TableBody>
                        : null}
                  </Table>
                </TableContainer>
                <Loader open={loader}/>
              </Box>
            </Paper>
        )}

      </Box>
  );
}

