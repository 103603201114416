import React, {useCallback, useEffect} from 'react';
import style from './CreateRouteSheetsOrderListActionBtns.module.css'
import DateSelectionField from "../../../../inputs/logisticService/DatePicker/DatePicker";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import CreateRouteSheetsOrderListAreaList
    from "../CreateRouteSheetsOrderListAreaList/CreateRouteSheetsOrderListAreaList";
import {observer} from "mobx-react-lite";
import MapData_CreateRouteSheets
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/mapData_CreateRouteSheets";

const CreateRouteSheetsOrderListActionBtns = observer(() => {
    const {orderListPanelState} = createRouteSheetsStore
    const {popupType, popupShow, popupScrollPosition} = createRouteSheetsStore.popupState
    const axiosInstance = AxiosConfig_LogisticService();

    useEffect(() => {
        async function getOrders() {
            const map = MapData_CreateRouteSheets.mapData;

            if (map && map.isStyleLoaded()) {
                await createRouteSheetsStore.getOrderListByDate(axiosInstance);
            } else {
                setTimeout(() => {
                    getOrders()
                }, 1000)
            }
        }
        getOrders();
    }, [orderListPanelState.uploadDate]);

    const changeUploadDateOrders = useCallback(async (newDate) => {
        createRouteSheetsStore.changeUploadDate(newDate)
    }, [orderListPanelState.uploadDate])

    const changePopupShow = (value, typePopup) => {
        createRouteSheetsStore.changePopupShow(window.pageYOffset, value, typePopup);
    }

    return (
        <div className={style.actionsBtns}>
            <div className={style.btnGroup}>
                <div className={style.selectDataContent}>
                    <div className={style.selectDataText}>Заказы за:</div>
                    <DateSelectionField
                        value={orderListPanelState.uploadDate}
                        onChange={(newDate) => changeUploadDateOrders(newDate)}
                    />
                </div>

                <button
                    id={'createBtn'}
                    className={style.showArea_btn}
                    onClick={() => changePopupShow(!popupShow, "selectArea")}
                >
                    Показать зоны
                </button>
            </div>


            <PopupRouting
                isOpen={popupShow && popupType === "selectArea"}
                onClose={() => createRouteSheetsStore.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <CreateRouteSheetsOrderListAreaList/>
            </PopupRouting>

        </div>
    );
});

export default CreateRouteSheetsOrderListActionBtns;