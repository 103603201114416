import React, {memo, useEffect} from 'react';
import style from './DeliveredStatusSubHeader.module.css'
import {Link, useNavigate, useParams} from "react-router-dom";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import {observer} from "mobx-react-lite";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';


const DeliveredStatusSubHeader = observer(() => {
    const {
        popupState:{popupShow},
        copyTemplateList
    } = deliveredStatusList
    const navigate = useNavigate();
    const {typeStatus} = useParams();

    const changeNewPath = (newTypeStatus) => {
        navigate(`/logisticService/deliveredStatusList/templateList/${newTypeStatus}`, {relative: 'path'});
    }

    function isChecked(array) {
        return array.some(temp => temp.checked === true);
    }

    const changePopupShow = (value, typePopup) => {
        if (isChecked(copyTemplateList)){
            deliveredStatusList.changePopupShow(window.pageYOffset, value, typePopup);
        } else {
            toastMassage(toast.error, "Выберите хотя бы один шаблон!", 10000, "bottom-right")
        }
    }


    return (
        <div className={style.headerPage}>
            <div className={style.selectStatusHeader}>Статус доставки в приложении</div>

            <div className={style.selectedStatusBtns}>

                <button className={(String(typeStatus) === "partiallyStatus") ? `${style.partiallyStatusBtn} ${style.active}` : style.partiallyStatusBtn}
                        onClick={() => changeNewPath("partiallyStatus")}
                >Отгружено частично
                </button>
                <button
                    className={(String(typeStatus) === "cancellationStatus") ? `${style.cancellationStatusBtn} ${style.active}` : style.cancellationStatusBtn}
                    onClick={() => changeNewPath("cancellationStatus")}
                >Отказ
                </button>
                <button
                    className={(String(typeStatus) === "all") ? `${style.allStatusBtn} ${style.active}` : style.allStatusBtn}
                    onClick={() => changeNewPath("all")}
                >Все
                </button>

            </div>

            <div className={style.actionTemplateBtns}>
                <Link to={"/logisticService/deliveredStatusList/createTemplate"}>
                    <button className={style.createTemplateBtn}>Создать шаблон ответа</button>
                </Link>
                <button className={style.deleteSelectTemplateBtn}
                onClick={() => changePopupShow(!popupShow, "askDeleteSelectedTemp")}
                >Удалить выбранные шаблоны</button>
            </div>
        </div>
    );
});

export default memo(DeliveredStatusSubHeader);