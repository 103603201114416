import {makeAutoObservable} from 'mobx'
import axios from "axios";
import ReactDOM from "react-dom/client";
import MapMarketPoint from "../../../components/transportationLogisticsService/MapMarketPoint/MapMarketPoint";
import mmrgl from "mmr-gl";
import React from "react";
import globalState from "../globalState";

class EditCoordinatesAddress {

    addressList = []; // Список адресов с координатами

    copyAddressList = null; // Список адресов с координатами

    editDataAddressCoordinates = null; // Объект редактируемого адреса

    markersList = [] // Массив маркеров на карте

    statePage = { // Состояния на странице
        setting: false,
        address_upload: false,
        value_search: "",
        show_faq: false,
        ref_list_items: null,
        type_list_item: null,
        index_edit_address: null
    }

    infoAlertState = {
        type: "warning",
        alert_text: null,
        show_alert: false
    }

    listScrollToAddress = {
        address_id: null,
        client: null,
        address: null
    }

    constructor() {
        makeAutoObservable(this,)
    }

    // Получаем все адреса с координатами по пользователю
    async getAllCoordinateAddress(authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)
        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getListCoordinatesAddress/`, {headers}
        )
            .then(response => {
                if (response.data.length > 0) {
                    this.addressList = response.data;
                    this.statePage.type_list_item = "allAddress";

                    if (this.listScrollToAddress.client != null && this.listScrollToAddress.address != null) {
                        this.statePage.index_edit_address = response.data.findIndex(obj => {
                            return obj.id === this.listScrollToAddress.address_id
                        });
                    }
                }
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });


    }

    // Получаем все адреса с незаполненными координатами
    async getAllErrorCoordinateAddress(authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)
        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getListErrorCoordinatesAddress/`, {headers}
        )
            .then(response => {
                if (response.data.length > 0) {
                    this.addressList = response.data;
                    this.statePage.type_list_item = "allErrorAddress";
                }
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });

    }

    // Обрабатываем значение в поисковой строке, для отображение нужных адресов
    changeSearchAddress(newSearValue) {
        if (this.statePage.value_search === "") {
            this.copyAddressList = this.addressList.slice();
        }

        this.statePage.value_search = newSearValue;
        const regex = new RegExp(`^.*${newSearValue.split(' ').join('.*')}.*$`, 'i');

        this.addressList = this.copyAddressList.filter(address =>
            regex.test(address.address) || regex.test(address.client)
        )
    }

    // Очищаем введенное значение в поисковой строке адреса
    clearSearchValue() {
        this.statePage.value_search = "";

        if (this.copyAddressList != null) {
            this.addressList = this.copyAddressList;
        }
    }

    // Устанавливаем статус редактирования на странице
    setEditAddressCoordinates(addressData) {
        this.editDataAddressCoordinates = addressData;
        this.statePage.setting = true;
        this.clearSearchValue()

        this.listScrollToAddress = {
            client: addressData.client,
            address: addressData.address,
        }
    }

    // Отменяем редактирование адресса
    cancelEditAddressCoordinate() {
        this.statePage.setting = false;
        this.clearSearchValue()

        // Определяем индекс редакрируемого адреса
        if (this.listScrollToAddress.client != null && this.listScrollToAddress.address != null) {
            this.statePage.index_edit_address = this.addressList.findIndex(obj => obj.address === this.listScrollToAddress.address && obj.client === this.listScrollToAddress.client);
        }

    }

    // Обновляем координаты Адреса после перемещения точки Адреса в другой место
    dragEditAddressCoordinate(dragData) {
        this.editDataAddressCoordinates = {
            ...this.editDataAddressCoordinates,
            coordinates_given: true,
            lat: dragData._lngLat.lat,
            lon: dragData._lngLat.lng
        }
    }

    // Обновляем координаты Адреса по нажатию на область на карте
    clickEditAddressCoordinate(clickData) {
        this.editDataAddressCoordinates = {
            ...this.editDataAddressCoordinates,
            coordinates_given: true,
            lat: Number(clickData.lat),
            lon: Number(clickData.lng)
        }

        this.markersList[0].setLngLat([clickData.lng, clickData.lat]);
    }

    createNewAddressCoordinates(coordinates) {
        this.editDataAddressCoordinates = {
            ...this.editDataAddressCoordinates,
            coordinates_given: true,
            lat: Number(coordinates.lat),
            lon: Number(coordinates.lng)
        }
    }

    // Обрабатываем input поля, где пользователь вводить координаты
    changeNewAddressCoordinate(typeCoordinate, newValueCoordinate, mapData) {
        const map = mapData.current;
        const pattern = /^(-?\d+)\.(\d+)$/;
        this.editDataAddressCoordinates = {
            ...this.editDataAddressCoordinates,
            lat: (typeCoordinate === "lat") ? newValueCoordinate : this.editDataAddressCoordinates.lat,
            lon: (typeCoordinate === "lon") ? newValueCoordinate : this.editDataAddressCoordinates.lon,
            unload_time: (typeCoordinate === "time") ? newValueCoordinate : this.editDataAddressCoordinates.unload_time
        }

        if (this.editDataAddressCoordinates.lat != null && this.editDataAddressCoordinates.lon != null) {

            if (this.markersList.length === 0 && (pattern.test(this.editDataAddressCoordinates.lat) && pattern.test(this.editDataAddressCoordinates.lon))) {
                this.showNewCoordinatesAddressToMap({
                    lat: this.editDataAddressCoordinates.lat,
                    lng: this.editDataAddressCoordinates.lon
                }, mapData)
            }

            if (this.markersList.length !== 0) {
                this.markersList[0].setLngLat([this.editDataAddressCoordinates.lon, this.editDataAddressCoordinates.lat]);
            }
        }

        if (typeCoordinate !== "time") {
            const coordsFocusToMarker = [[this.editDataAddressCoordinates.lon, this.editDataAddressCoordinates.lat], [this.editDataAddressCoordinates.lon, this.editDataAddressCoordinates.lat]]

            map.fitBounds(coordsFocusToMarker, {zoom: 12});
        }


    }

    // Очищаем карту от маркеров
    clearMarkers() {
        if (this.markersList.length > 0) {
            this.markersList.map(marker => {
                marker.remove();
            })
            this.markersList = [];
        }
    }

    // Добавляем маркер на карту
    addNewMarkerToMap(dataMarket) {
        this.markersList.push(dataMarket)
    }

    // Отправляем отредактированный адрес в БД для обновления данных
    async updateNewCoordinateAddress(typeRedirect, authTokens, setUser, setAuthTokens, logoutUser, navigate) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        if (this.editDataAddressCoordinates.lat === null || this.editDataAddressCoordinates.lon === null) {
            this.showAlertBlock("info",
                "Заполните координаты перед сохранением!")
            return
        }

        const pattern = /^(-?\d+)\.(\d+)$/;
        // Проверяем что координаты нужного формата
        if (!pattern.test(this.editDataAddressCoordinates.lat) && !pattern.test(this.editDataAddressCoordinates.lon)) {
            this.showAlertBlock("error",
                "Вы указали неверный формат координат. Исправьте и повторите снова!")
            return
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            newAddressData: this.editDataAddressCoordinates,
            typeRedirect: typeRedirect
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/updateCoordinatesAddress/`, data, {headers}
        )
            .then(async response => {
                if (response.status === 200) {
                    // navigate(`/logisticService/addressCoordinateEditing/addressList/all`, {relative: 'path'});
                    navigate(-1);
                    this.statePage.setting = false;
                    this.showAlertBlock("success",
                        "Координаты успешно записаны!")
                }
            })
            .catch(error => {
                this.showAlertBlock("error",
                    "Координаты не записались! Обратитесь к администрации сервиса!")
                console.error('Ошибка:', error);
            });


    }

    // Показываем уведомление необходимого типа и текста
    showAlertBlock(typeAlert, textAlert) {
        this.infoAlertState.show_alert = true;
        this.infoAlertState.alert_text = textAlert;
        this.infoAlertState.type = typeAlert;

        setTimeout(() => {
            this.infoAlertState.show_alert = false;
        }, 3000)
    }

    showFaqBlock(value) {
        this.statePage.show_faq = value;
    }

    // Создаем маркер с новыми координатами
    showNewCoordinatesAddressToMap = (coordinates, mapData) => {
        const coordsArray = [Number(coordinates.lng), Number(coordinates.lat)];

        const map = mapData.current;

        if (map) {
            this.clearMarkers()
            // Выводим маркеры точек на карту
            const markerElement = document.createElement('div');
            const root = ReactDOM.createRoot(markerElement);
            root.render(<MapMarketPoint idDoc={1}/>);

            this.addNewMarkerToMap(new mmrgl.Marker({
                element: markerElement,
            }).setLngLat(coordsArray).addTo(map).setDraggable(true)
                .on("dragend", (dragData) => {
                    this.dragEditAddressCoordinate(dragData.target)
                }))

            const coordsFocusToMarker = [coordsArray, coordsArray]
            map.fitBounds(coordsFocusToMarker, {zoom: 18});
        }
    }

    async getCoordinateAddressById(addressId, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getCoordinatesAddressById/${addressId}`, {headers}
        ).then(res => {
            this.editDataAddressCoordinates = res.data;
            this.statePage.setting = true;
            this.clearSearchValue();

            this.listScrollToAddress = {
                address_id: res.data.id,
                client: res.data.address,
                address: res.data.client,
            }
        }).catch(error => {
            console.error(error)
        })

    }

    changeDefaultValueEditAddress() {
        this.editDataAddressCoordinates = null;
        this.statePage.setting = false;
        this.clearSearchValue();
    }

    changeDefaultValueEditAddressList() {
        this.addressList = [];
        this.copyAddressList = null;
        this.clearSearchValue();

        this.listScrollToAddress = {
            address_id: null,
            client: null,
            address: null,
        }
    }

}

export default new EditCoordinatesAddress();