import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, TextField } from "@mui/material";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import "./style.css"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AdminFormGetToken({selectedUser}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [quantityKey, setQuantityKey] = React.useState(0);
  const [authTokens, setAuthTokens] =  React.useState(null);
  const { apiGetToken1C } = useAxiosAPI();

  
  

  // const createToken = () => {
    apiGetToken1C(selectedUser.id)
    .then((resolve) => {
      setAuthTokens(resolve.data.authToken)
  })
  .catch(() => {
      alert('Что-то пошло не так :(' )
  });
  // }
  return (
    <div>
      
      <Button             sx={{ marginTop: "20px" }}
 variant="contained" onClick={handleOpen}>
       Показать токен 1С
      </Button>
      

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
<Box sx={{display: "flex"}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Токен для 1С
          </Typography>
          <IconButton sx={{marginLeft: "10px"}} aria-label="delete">
  <ContentCopyIcon onClick={() => {navigator.clipboard.writeText(authTokens)}} />
</IconButton>
</Box>

          <Typography className="tokenText">{authTokens}</Typography>

          <Button
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={() => setOpen(!open)}
          >
           Назад
          </Button>
          
         

        </Box>
      </Modal>
    </div>
  );
}