import {
    Checkbox,
    FormGroup
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import "./ProfilePatchRegionUser.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useEffect, useState} from "react";
import Search from "../ui/Search/Search";


function regionFilter(data, searchText, all) {
    return data.filter((item) => {
        const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
        const itemCheck = item.check; // Получаем поле "name" объекта

        return itemName.includes(searchText.toLowerCase()) && all ? itemCheck === all : itemName.includes(searchText.toLowerCase());
    });
}

export default function ProfilePatchRegionUser(props) {
    const RU =
        [
            {
                name: "Республика Адыгея (Адыгея)",
                check: false
            },
            {
                name: "Республика Башкортостан",
                check: false
            },
            {
                name: "Республика Бурятия",
                check: false
            },
            {
                name: "Республика Алтай",
                check: false
            },
            {
                name: "Республика Дагестан",
                check: false
            },
            {
                name: "Республика Ингушетия",
                check: false
            },
            {
                name: "Кабардино-Балкарская Республика",
                check: false
            },
            {
                name: "Республика Калмыкия",
                check: false
            },
            {
                name: "Карачаево-Черкесская Республика",
                check: false
            },
            {
                name: "Республика Карелия",
                check: false
            },
            {
                name: "Республика Коми",
                check: false
            },
            {
                name: "Республика Марий Эл",
                check: false
            },
            {
                name: "Республика Мордовия",
                check: false
            },
            {
                name: "Республика Саха (Якутия)",
                check: false
            },
            {
                name: "Республика Северная Осетия — Алания",
                check: false
            },
            {
                name: "Республика Татарстан (Татарстан)",
                check: false
            },
            {
                name: "Республика Тыва",
                check: false
            },
            {
                name: "Удмуртская Республика",
                check: false
            },
            {
                name: "Республика Хакасия",
                check: false
            },
            {
                name: "Чеченская Республика",
                check: false
            },
            {
                name: "Чувашская Республика — Чувашия",
                check: false
            },
            {
                name: "Алтайский край",
                check: false
            },
            {
                name: "Краснодарский край",
                check: false
            },
            {
                name: "Красноярский край",
                check: false
            },
            {
                name: "Приморский край",
                check: false
            },
            {
                name: "Ставропольский край",
                check: false
            },
            {
                name: "Хабаровский край",
                check: false
            },
            {
                name: "Амурская область",
                check: false
            },
            {
                name: "Архангельская область",
                check: false
            },
            {
                name: "Астраханская область",
                check: false
            },
            {
                name: "Белгородская область",
                check: false
            },
            {
                name: "Брянская область",
                check: false
            },
            {
                name: "Владимирская область",
                check: false
            },
            {
                name: "Волгоградская область",
                check: false
            },
            {
                name: "Вологодская область",
                check: false
            },
            {
                name: "Воронежская область",
                check: false
            },
            {
                name: "Ивановская область",
                check: false
            },
            {
                name: "Иркутская область",
                check: false
            },
            {
                name: "Калининградская область",
                check: false
            },
            {
                name: "Калужская область",
                check: false
            },
            {
                name: "Камчатский край",
                check: false
            },
            {
                name: "Кемеровская область",
                check: false
            },
            {
                name: "Кировская область",
                check: false
            },
            {
                name: "Костромская область",
                check: false
            },
            {
                name: "Курганская область",
                check: false
            },
            {
                name: "Курская область",
                check: false
            },
            {
                name: "Ленинградская область",
                check: false
            },
            {
                name: "Липецкая область",
                check: false
            },
            {
                name: "Магаданская область",
                check: false
            },
            {
                name: "Московская область",
                check: false
            },
            {
                name: "Мурманская область",
                check: false
            },
            {
                name: "Нижегородская область",
                check: false
            },
            {
                name: "Новгородская область",
                check: false
            },
            {
                name: "Новосибирская область",
                check: false
            },
            {
                name: "Омская область",
                check: false
            },
            {
                name: "Оренбургская область",
                check: false
            },
            {
                name: "Орловская область",
                check: false
            },
            {
                name: "Пензенская область",
                check: false
            },
            {
                name: "Пермский край",
                check: false
            },
            {
                name: "Псковская область",
                check: false
            },
            {
                name: "Ростовская область",
                check: false
            },
            {
                name: "Рязанская область",
                check: false
            },
            {
                name: "Самарская область",
                check: false
            },
            {
                name: "Саратовская область",
                check: false
            },
            {
                name: "Сахалинская область",
                check: false
            },
            {
                name: "Свердловская область",
                check: false
            },
            {
                name: "Смоленская область",
                check: false
            },
            {
                name: "Тамбовская область",
                check: false
            },
            {
                name: "Тверская область",
                check: false
            },
            {
                name: "Томская область",
                check: false
            },
            {
                name: "Тульская область",
                check: false
            },
            {
                name: "Тюменская область",
                check: false
            },
            {
                name: "Ульяновская область",
                check: false
            },
            {
                name: "Челябинская область",
                check: false
            },
            {
                name: "Забайкальский край",
                check: false
            },
            {
                name: "Ярославская область",
                check: false
            },
            {
                name: "г. Москва",
                check: false
            },
            {
                name: "Санкт-Петербург",
                check: false
            },
            {
                name: "Еврейская автономная область",
                check: false
            },
            {
                name: "Ненецкий автономный округ",
                check: false
            },
            {
                name: "Ханты-Мансийский автономный округ — Югра",
                check: false
            },
            {
                name: "Чукотский автономный округ",
                check: false
            },
            {
                name: "Ямало-Ненецкий автономный округ",
                check: false
            },
            {
                name: "Республика Крым и г.Севастополь",
                check: false
            },
        ]
    const [all, setAll] = useState(false)

    const [searchComplete, setSearchComplete] = useState("")

    const updatedArray = RU.reduce((acc, region) => {
        // Проверяем, нужно ли обновить возраст
        if (props.regions.includes(region.name)) {
            // Обновляем возраст для совпадающих имен
            acc.push({...region, check: true});
        } else {
            // Добавляем объект без изменений в аккумулятор
            acc.push(region);
        }
        return acc;
    }, []);

    const [allRegions, setAllRegions] = useState(updatedArray)
    const [checkedAll, setCheckedAll] = useState(allRegions.filter((item) => item.check === true).length === allRegions.length)


    function regionCheck(index) {
        setAllRegions(prevArray => {
            const newState = [...allRegions]
            newState[index] = {...newState[index], check: !newState[index].check}
            return newState
        })
    }

    function checkAll() {

        setAllRegions((prevItems) =>
            prevItems.map((section) => ({
                ...section,
                check: checkedAll
            }))
        );

        setCheckedAll(!checkedAll)

    }

    useEffect(() => {
        props.setRegions(
            allRegions.filter(item => item.check).map(item => item.name)
        )
    }, [allRegions]);

    return (

        <Box sx={{border: "1px solid rgba(0, 0, 0, 0.26)", width: "90%", padding: "10px", borderRadius: "5px"}}>
            <Box>
                <Search
                    searchComplete={searchComplete}
                    setSearchComplete={setSearchComplete}
                />
                <FormControlLabel control={<Checkbox onClick={() => checkAll()}
                                                     checked={allRegions.filter((item) => item.check === true).length === allRegions.length}/>}
                                  label={"Выбрать все"}/>
                <FormControlLabel control={<Checkbox onClick={() => setAll(!all)} checked={all}/>} label={"Выбранные"}/>


            </Box>
            <Box sx={{height: "25vh", overflow: "auto", padding: "10px", marginTop: "10px", backgroundColor: "#f3f4f9", borderRadius: "5px"}}>
                <FormGroup sx={{overflow: "auto"}}>
                    {regionFilter(allRegions, searchComplete, all).map((option, index) => {
                        return (
                            <FormControlLabel
                                control={<Checkbox onClick={() => regionCheck(index)} checked={option.check}/>}
                                label={option.name}/>
                        )
                    })}
                </FormGroup>

            </Box>
        </Box>
    );
}
