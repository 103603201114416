import {makeAutoObservable} from 'mobx'
import {v4 as uuidv4} from "uuid";
import globalState from "../globalState";
import axios from "axios";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../components/ToastMassage/ToastMassage';

const DEFAULT_TYPE_CARD = [
    {
        status_name: "drivers",
        status_text: "водитель"
    },
    {
        status_name: "forwarders",
        status_text: "экспедитор"
    }
]

class PageDriverAndForwarderStore {

    documentList = [];
    documentListCopy = [];

    selectedDocument = [];

    tableDocumentListState = {
        allChecked: false
    }

    createCardState = {
        appellation: "",
        typeCard: null
    }

    editCardState = {
        appellation: "",
        typeCard: null,
        cardId: null
    }

    editPageState = {
        loading: true
    }

    paginationState = {
        page: 1,
        rowsPerPage: 10
    }

    popoverSelectTypeCard = {
        selected_status: {
            status_name: null,
            status_text: "выберите тип"
        },
        cardTypeList: DEFAULT_TYPE_CARD,
    }

    popupState = {
        popupShow: false,
        popupType: "",
        popupScrollPosition: 0
    }

    constructor() {
        makeAutoObservable(this,)
    }

    changePopupShow(position, value, typePopup) {
        this.popupState.popupShow = value;
        this.popupState.popupType = typePopup;
        this.popupState.popupScrollPosition = position;
    }

    closePopup() {
        this.popupState.popupShow = false;
        this.popupState.popupType = "";
    }

    showCurrentAmountDocument() {
        // Вычисляем начальный индекс элементов для текущей страницы
        const startIndex = (this.paginationState.page - 1) * this.paginationState.rowsPerPage;
        // Вычисляем конечный индекс элементов для текущей страницы
        const endIndex = startIndex + this.paginationState.rowsPerPage;

        this.documentListCopy = this.documentList.slice(startIndex, endIndex);
    }

    handleChangePage = (newPage) => {
        this.paginationState.page = newPage;

        this.showCurrentAmountDocument();
    };

    handleChangeRowsPerPage = (rowsPerPage) => {
        if (rowsPerPage != null) {
            this.paginationState.rowsPerPage = rowsPerPage;
            this.paginationState.page = 1;

            this.showCurrentAmountDocument();
        }
    };

    changeSelectedAllDocument(value){
        this.tableDocumentListState.allChecked = value;
        this.selectedDocument = [];

        const checkedDoc = this.documentList.slice().map(doc => {
            doc.checked = value
            if (value){
                this.selectedDocument = [...this.selectedDocument, doc.id]
            }
            return doc
        })

        this.documentList = checkedDoc;
        this.documentListCopy = checkedDoc;
        this.showCurrentAmountDocument();
    }

    changeSelectedDocumentById(idDoc, value){
        const checkedDoc = this.documentList.slice().map(doc => {
            if (doc.id === idDoc){
                doc.checked = value
                if (value){
                    this.selectedDocument = [...this.selectedDocument, doc.id]
                } else {
                    this.selectedDocument = this.selectedDocument.slice().filter(sDoc => doc.id !== sDoc)
                }
            }
            return doc
        })

        this.documentList = checkedDoc;
        this.documentListCopy = checkedDoc;
        this.showCurrentAmountDocument();
    }

    setSelectedValue(documentList){

        if (this.selectedDocument.length === 0) {
            return documentList;
        }

        // Преобразование массива в объект для быстрого доступа
        const selectedVehMap = {};
        this.selectedDocument.forEach(id => {
            selectedVehMap[id] = true;
        });

        return documentList.map(docObj => {
            // Проверка наличия id в объекте
            if (selectedVehMap[docObj.id]) {
                docObj.checked = true;
            }
            return docObj;
        });
    }

    changeSelectTypeCard(selectData, typeAction = "create") {
        this.popoverSelectTypeCard.selected_status = selectData;

        switch (typeAction) {
            case "create":
                this.createCardState.typeCard = selectData;
                break;
            case "edit":
                this.editCardState.typeCard = selectData;
                break;
        }

    }

    changeDefaultValueCreateCard() {
        this.createCardState = {
            appellation: "",
            typeCard: null
        }

        this.popoverSelectTypeCard = {
            selected_status: {
                status_name: null,
                status_text: "выберите тип"
            },
            cardTypeList: DEFAULT_TYPE_CARD,
        }
    }

    changeCardAppellation(newText, typeAction = "create") {
        switch (typeAction) {
            case "create":
                this.createCardState.appellation = newText;
                break;
            case "edit":
                this.editCardState.appellation = newText;
                break;
        }
    }

    changeDefaultValueEditCard(){
        this.editPageState.loading = true;

        this.editCardState = {
            appellation: "",
            typeCard: null,
            cardId: null
        }

        this.popoverSelectTypeCard = {
            selected_status: {
                status_name: null,
                status_text: "выберите тип"
            },
            cardTypeList: DEFAULT_TYPE_CARD,
        }
    }

    changeDefaultValue() {
        // this.documentList = [];
        // this.documentListCopy = [];
    }

    uploadChangeTypeCard(valueForward) {
        if (valueForward) {
            return {
                status_name: "forwarders",
                status_text: "экспедитор"
            }
        } else {
            return {
                status_name: "drivers",
                status_text: "водитель"
            }
        }
    }

    async getCardsByTypeList(typeList, authTokens, setUser, setAuthTokens, logoutUser, newList = false) {
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getDriversOrForwarders/typeList=${typeList}`, {headers}
        ).then(response => {
            if (newList) this.paginationState.page = 1;

            this.documentList = this.setSelectedValue(response.data)
            this.documentListCopy = this.documentList

            // this.changeCheckedDefaultValue(response.data);
            this.showCurrentAmountDocument();

        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить карточки водителей/экспедиторов!", 10000, "bottom-right")
            this.documentList = [];
            this.documentListCopy = [];
            console.error('Ошибка:', error);
        })

    }

    async getCardsByTypeListById(cardId, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getDriversOrForwardersById/cardId=${cardId}`, {headers}
        ).then(res => {
            this.editCardState = {
                appellation: res.data.driver_name,
                typeCard: this.uploadChangeTypeCard(res.data?.forwarder),
                cardId: res.data.id
            }

            this.editPageState.loading = false;

            this.popoverSelectTypeCard.selected_status = this.uploadChangeTypeCard(res.data?.forwarder)
        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить карточку водителей/экспедиторов!", 10000, "bottom-right")
            this.editCardState = {
                appellation: "",
                typeCard: null,
                cardId: null
            }
            console.error('Ошибка:', error);
        })
    }

    async createCard(authTokens, setUser, setAuthTokens, logoutUser, navigate) {

        if (this.createCardState.appellation === "") {
            toastMassage(toast.error, "Заполните поле ФИО!", 10000, "bottom-right")
            return
        } else if (this.createCardState.typeCard === null) {
            toastMassage(toast.error, "Выберите тип карточки!", 10000, "bottom-right")
            return
        }

        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            ...this.createCardState
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/createDriversOrForwarders`, DATA, {headers}
        ).then(response => {
            toastMassage(toast.success, "Новая карточка успешно создана!", 2500, "bottom-right")
            const navValue = (this.popoverSelectTypeCard.selected_status.status_name === null) ? "all" : this.popoverSelectTypeCard.selected_status.status_name;
            navigate(`/logisticService/driverAndForwarder/list/${navValue}`);

        }).catch(error => {

            if (error.response.status === 409) {
                toastMassage(toast.error, "Подобная карточка уже существует!", 10000, "bottom-right")  
            } else {
                toastMassage(toast.error, "Не удалось создать новую карточку!", 10000, "bottom-right")  
            }

            console.error('Ошибка:', error);
        })

    }

    async updateCard(cardId, authTokens, setUser, setAuthTokens, logoutUser, navigate) {
        if (this.editCardState.appellation === "") {
            toastMassage(toast.error, "Заполните поле ФИО!", 10000, "bottom-right")  
            return
        } else if (this.editCardState.typeCard === null) {
            toastMassage(toast.error, "Выберите тип карточки!", 10000, "bottom-right")  
            return
        }

        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            ...this.editCardState
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/updateDriversOrForwarders`, DATA, {headers}
        ).then(res => {
            toastMassage(toast.success, "Карточка успешно обновлена!", 2500, "bottom-right")
            navigate(-1);
        }).catch(error => {
            toastMassage(toast.error, "Не удалось обновить карточку!", 10000, "bottom-right")  
            console.error('Ошибка:', error);
        })

    }

    async deleteCardById(cardId, authTokens, setUser, setAuthTokens, logoutUser){
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            ...this.editCardState
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteDriverOrForwarderById/cardId=${cardId}`, DATA, {headers}
        ).then(res => {
            toastMassage(toast.success, "Карточка успешно обновлена!", 2500, "bottom-right")
        }).catch(error => {
            toastMassage(toast.error, "Не удалось удалить карточку!", 10000, "bottom-right")  
            console.error('Ошибка:', error);
        })
    }

    async deleteSelectCardById(authTokens, setUser, setAuthTokens, logoutUser){
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            selectCardList: this.selectedDocument
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteDriversOrForwarders/`, DATA, {headers}
        ).then(res => {
            toastMassage(toast.success, "Выбранные карточки успешно удалены!", 2500, "bottom-right")
            this.closePopup()
            this.getCardsByTypeList("all", authTokens, setUser, setAuthTokens, logoutUser)
        }).catch(e => {
            toastMassage(toast.error, "Не удалось удалить карточки!", 10000, "bottom-right")  
            console.error('Ошибка:', e);
        })
    }

}


export default new PageDriverAndForwarderStore();