import React, {useCallback, useEffect, useState} from 'react';
import style from './ModalCreateRouteSheet.module.css'
import "./modalCreateRouteSheet.css"
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import ModalChangeRouteParam
    from "../ModalChangeRouteParam/ModalChangeRouteParam";
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {CSSTransition} from "react-transition-group";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import routeParamSelectorHOC from "../hoc/RouteParamSelectorHOC";
import TimeInput from "../../../../inputs/logisticService/TimeInput/TimeInput";
import DateSelectionField from "../../../../inputs/logisticService/DatePicker/DatePicker";
import {observer} from "mobx-react-lite";
import DatePicker from "../../../../inputs/logisticService/DatePicker/DatePicker";
import globalState from "../../../../store/transportationLogisticsService/globalState";

const CreateRouteSheetsOrderListModalSelectedInitialPointWithHOC = routeParamSelectorHOC(
    ModalChangeRouteParam,
    "Выбрать начальную точку",
    "changeInitPoint",
    "createRoute"
);

const CreateRouteSheetsOrderListModalSelectedDriverWithHOC = routeParamSelectorHOC(
    ModalChangeRouteParam,
    "Выбрать водителя",
    "changeDriver",
    "createRoute"
);

const CreateRouteSheetsOrderListModalSelectedVehicleWithHOC = routeParamSelectorHOC(
    ModalChangeRouteParam,
    "Выбрать транспорт",
    "changeVehicle",
    "createRoute"
);

const ModalCreateRouteSheet = observer(() => {
    const [errorText, setErrorText] = useState("");
    const [showError, setShowError] = useState(false);
    const axiosInstance = AxiosConfig_LogisticService();
    const {delivery_date, delivery_time} = createRouteSheetsStore.creatingRouteSheetsModalData
    const [inputValueTime, setInputValueTime] = useState(delivery_time || "00:00");

    const handleInputChange = useCallback((newTime) => {
        setInputValueTime(newTime);
        createRouteSheetsStore.changeDeliveryTime(newTime, "create");
    }, []);

    const createNewRouteSheet = async () => {
        const {initial_route_point, drivers_info, vehicle_data, vehicle_number, delivery_date} = createRouteSheetsStore.creatingRouteSheetsModalData
        const {areaId} = createRouteSheetsStore.areaSelectOrdersTotalData
        const {typeCreatingRouteSheet} = createRouteSheetsStore;

        if (initial_route_point?.name === ""){
            setErrorText('Укажите начальную точку маршрута!')
            setShowError(true)
            return
        } else if (drivers_info[0]?.driver_name === ""){
            setErrorText('Укажите водителя!')
            setShowError(true)
            return
        } else if (vehicle_number === ""){
            setErrorText('Укажите транспорт!')
            setShowError(true)
            return
        }

        switch (typeCreatingRouteSheet) {
            case "deliveryArea":
                await createRouteSheetsStore.createRouteSheetBasedDeliveryArea(areaId);
                break
            case "selectPoint":
                await createRouteSheetsStore.createRouteSheetBasedSelectOrder();
                break
            case "selectedOrderList":
                await createRouteSheetsStore.createRouteSheetBasedSelectedOrdersList();
                break
            default:
                return
        }

        try {
            await createRouteSheetsStore.createRouteSheet(axiosInstance);
            createRouteSheetsStore.changeDefaultValueCreatingRouteSheets();
            createRouteSheetsStore.closePopup()
        }catch (e) {}


    }

    const changeDeliveryDate = (newDate) => {
        createRouteSheetsStore.changeDeliveryDate(newDate, "create")
    }

    return (
        <>
            <div className={style.popupHeader}>
                Создание маршрутного листа
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup();
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>

            <div className={style.selectProperty}>
                <div className={style.headerOptimizationInitPoint}>Начальная точка маршрута:</div>
                {/*<ModalChangeRouteParam/>*/}
                <CreateRouteSheetsOrderListModalSelectedInitialPointWithHOC/>
                <div className={style.headerOptimizationInitPoint}>Водитель:</div>
                <CreateRouteSheetsOrderListModalSelectedDriverWithHOC/>
                <div className={style.headerOptimizationInitPoint}>Транспорт:</div>
                <CreateRouteSheetsOrderListModalSelectedVehicleWithHOC/>
            </div>

            <div className={style.deliveryDateContent}>
                <div className={style.changeDate}>
                    <div className={style.selectDataText}>Дата доставки:</div>
                    <DatePicker
                        value={delivery_date || globalState.getISONowDate()}
                        onChange={(newDate) => changeDeliveryDate(newDate)}
                    />
                </div>
                <div className={style.changeTime}>
                    <div className={style.selectDataText}>Время доставки:</div>
                    <TimeInput value={inputValueTime} onChange={(newTime) => handleInputChange(newTime)}/>
                </div>
            </div>

            <CSSTransition in={showError} timeout={3000} classNames="my-node" unmountOnExit>
                <div
                    className={
                        style.errorMessage
                    }>
                    {errorText}
                </div>
            </CSSTransition>


            <div className={style.actionBtns}>
                <button className={style.showSelectedAreaBtn}
                        onClick={() => createNewRouteSheet()}
                >
                    Создать
                </button>
                <button
                    className={style.cancelAskBtn}
                    onClick={() => {
                        createRouteSheetsStore.changeDefaultValueCreatingRouteSheets();
                        createRouteSheetsStore.closePopup()
                    }}
                >
                    Отмена
                </button>
            </div>
        </>
    );
});

export default ModalCreateRouteSheet;