import React from 'react';
import style from './ModalEditRouteSheetTable.module.css';
import ModalEditRouteSheetTableHeader from "../ModalEditRouteSheetTableHeader/ModalEditRouteSheetTableHeader";
import ModalEditRouteSheetTableList from "../ModalEditRouteSheetTableList/ModalEditRouteSheetTableList";

const ModalEditRouteSheetTable = () => {
    return (
        <div className={style.table}>
            <ModalEditRouteSheetTableHeader/>
            <ModalEditRouteSheetTableList/>
        </div>
    );
};

export default ModalEditRouteSheetTable;