import React, {useEffect} from 'react';
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import style from './DeliversPopupItemList.module.css';
import {Tooltip} from 'react-tooltip';
import {observer} from "mobx-react-lite";
import editInitialPoints
    from "../../../store/transportationLogisticsService/pageEditInitialPoints/editInitialPoints"
import optimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";


const DeliversPopupItemList = observer(({deliverData, showSelectAddressToMap,uid, indexElem, setNewData}) => {

    return (
        <div
            onClick={() => setNewData(deliverData)}
            className={style.addressCoordsItem}
        >
                <div>
                    {deliverData.driver_name}
                </div>
        </div>
    );
})

export default DeliversPopupItemList;