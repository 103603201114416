import React from 'react';
import {List} from "react-virtualized";
import style from "../RouteSheetTableList/RouteSheetTableList.module.css";
import RouteSheetTableItem from "../RouteSheetTableItem/RouteSheetTableItem";
import {v4 as uuidv4} from "uuid";
import ModalRouteSheetTableItem from "../ModalRouteSheetTableItem/ModalRouteSheetTableItem";
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const ModalRouteSheetTableList = observer(() => {
    const {routeSheetsList} = createRouteSheetsStore

    const rowRenderer = ({ key, index, style }) => (
        <div key={key} style={{...style, display: 'flex', minWidth: '600px'}}>
            <ModalRouteSheetTableItem docData={routeSheetsList[index]}/>
        </div>
    );

    return (
        <div style={{width: "100%", height: '100%', overflowX: 'auto'}}>
            {
                routeSheetsList && <List
                width={760} // Используем переданную ширину
                height={500 - 200} // Используем переданную высоту
                rowCount={routeSheetsList.length} // Количество строк
                rowHeight={81} // Высота одной строки
                rowRenderer={rowRenderer} // Функция для рендеринга строки
                overscanRowCount={3} // Количество строк, которые будут предварительно загружены
                className={style.tableList}
            />}
        </div>
    );
});

export default ModalRouteSheetTableList;