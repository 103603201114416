import React, {useContext, useEffect, useMemo, useState} from 'react';
import style from './DeliveryAreaEdit.module.css'
import './DeliveryAreaEdit.css'
import {observer} from 'mobx-react-lite';
import "driver.js/dist/driver.css";
import GlobalContext from "../../../../context/GlobalContext";
import {useNavigate, useParams} from "react-router-dom";
import EditDeliveryAreaStore from '../../../../store/transportationLogisticsService/PageDeliveryZone/editDeliveryArea'
import DeliveryAreaEditForm from "../DeliveryAreaEditForm/DeliveryAreaEditForm";
import DeliveryAreaEditActionBtnsGroup from "../DeliveryAreaEditActionBtnsGroup/DeliveryAreaEditActionBtnsGroup";
import DeliveryAreaEditHeaderList from "../DeliveryAreaEditHeaderList/DeliveryAreaEditHeaderList";
import DeliveryAreaEditPointList from "../DeliveryAreaEditPointList/DeliveryAreaEditPointList";
import MapData_DeliveryArea
    from "../../../../store/transportationLogisticsService/PageDeliveryZone/mapData_DeliveryArea";


const DeliveryAreaEdit = observer(({typeAction}) => {
    const {mapData} = MapData_DeliveryArea;
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);
    const navigate = useNavigate();
    const {areaId} = useParams();

    useEffect(() => {

        async function getAreaById() {

            if (!mapData.isStyleLoaded()){
                setTimeout(()=>{
                    getAreaById();
                }, 1000)
                return
            }

            await EditDeliveryAreaStore.getAreaById(areaId, authTokens, setUser, setAuthTokens, logoutUser);
        }

        if (mapData){
            getAreaById();
        }

    }, [areaId, mapData]);

    useEffect(() => {
        return () => {
            EditDeliveryAreaStore.clearMap();
            EditDeliveryAreaStore.clearUserPointMap();
            EditDeliveryAreaStore.changeDefaultValue();
        }
    }, [])

    return (
        <div className={style.settingDeliveryZoneNavCreateZone}>

            <div className={style.settingDeliveryZoneNavHeader}>
                <div className={style.goBackToList}
                     onClick={() => navigate(-1)}>Назад
                </div>
                <div>Редактирование зоны</div>
            </div>

            <DeliveryAreaEditForm/>

            <DeliveryAreaEditActionBtnsGroup/>

            <DeliveryAreaEditHeaderList/>

            <DeliveryAreaEditPointList/>

        </div>);
});

export default DeliveryAreaEdit;