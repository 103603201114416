import React, {useEffect, useState} from 'react';
import style from './ModalSelectedDriver.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {observer} from "mobx-react-lite";
import {AutoSizer, List} from "react-virtualized";
import {v4 as uuidv4} from "uuid";
import ModalSelectedDriverItem from "../ModalSelectedDriverItem/ModalSelectedDriverItem";
import routeParamHandlerHoc from "../hoc/RouteParamHandlerHOC";
import ModalSelectedInitialPointItem from "../ModalSelectedInitialPointItem/ModalSelectedInitialPointItem";



const ModalSelectedDriver = observer(({typeAction, newSearchValue, nameArrayData, nameChange}) => {
    const listData = createRouteSheetsStore[nameArrayData];
    const [driverListData, setDriverListData] = useState([])
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {



        return () => {
            createRouteSheetsStore.changeDefaultModalData(nameArrayData)
        }
    }, [])

    useEffect(() => {
        if (listData && listData.length > 0){
            const {routeSheetsList} = createRouteSheetsStore
            const driverListData = listData.slice().map(driver => {
                driver.usedInRouteSheet = false
                return driver
            });

            for (const driver of driverListData) {
                for (const routeSheets of routeSheetsList) {
                    if (routeSheets?.drivers_info[0]?.driver_uid === driver?.driver_id) {
                        console.log("true");
                        driver.usedInRouteSheet = true;
                    }
                }
            }

            setDriverListData(driverListData);
        }
    }, [listData])

    const ModalSelectedDriverItemHOC = routeParamHandlerHoc(
        ModalSelectedDriverItem,
        "changeDriver",
        typeAction
    )

    const rowRenderer = ({index, key, style, }) => {

        return (
            <div key={key} style={style}>
                <ModalSelectedDriverItemHOC
                    paramRowData={driverListData[index]}
                    key={uuidv4()}
                />
            </div>
        )
            ;
    };

    const changeNewSearchValue = (newValue) => {
        setSearchValue(newValue);
        newSearchValue(newValue);
    }

    return (
        <div className={style.selectInitialPointContent}>
            <div className={style.popupHeader}>
                <div className={style.goBackToList}
                     onClick={() => createRouteSheetsStore.changeRouteSheetDataModal(false, nameChange)}>Назад
                </div>
                Выбор водителя
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup();
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>

            <div
                id={"searchInputZone"}
                className={style.inputSearchNameZona}>
                <input
                    type="text"
                    maxLength={50}
                    value={searchValue}
                    onChange={(e) => changeNewSearchValue(e.target.value)}
                    placeholder="Поиск.."
                />
                <span onClick={
                    () => {
                        setSearchValue("")
                        changeNewSearchValue("")
                    }
                }>Очистить</span>
            </div>

            <div className={style.popupHeaderList}>
                <div>ФИО Водителя</div>
            </div>


            {
                driverListData.length > 0 && <div>
                    <AutoSizer>
                        {({width, height}) => (
                            <List
                                width={width}
                                height={405}
                                rowCount={driverListData?.length || 0} // Количество элементов в списке
                                rowHeight={101} // Высота отдельного элемента списка
                                rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                className={style.popupInitPointListBlock}
                            />
                        )}
                    </AutoSizer>
                </div>
            }
        </div>
    );
});

export default ModalSelectedDriver;