import React, {memo, useEffect} from 'react';

import style from './DeliveredStatusListPage.module.css'
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import {Outlet} from "react-router-dom";
import {Toaster} from "react-hot-toast";

const DeliveredStatusListPage = () => {

    return (
        <div className={style.contentPage}>

            {/*Уведомления*/}
            <Toaster containerStyle={{
                position: 'fixed',
                top: '80px'
            }} />
            <div className={style.menuBlock}>
                <MainMenuLogisticsService namePage={"Шаблоны ответов для водителей"}/>
            </div>
            {
                <Outlet/>
            }
        </div>
    );
};

export default memo(DeliveredStatusListPage); 