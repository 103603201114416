import { Box } from "@mui/material";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Divider } from "@mui/material";
import { Typography } from "@mui/material";
import { dateConversion } from "../../helpers/helpers";
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';

const ProfileTarifs = () => {
  const { state } = useContext(GlobalContext);
  const savp = state.subscriptions.filter((item) => item.code === 2)[0];
  const optimiztion = state.subscriptions.filter((item) => item.code === 3)[0];
  const logistick = state.subscriptions.filter((item) => item.code === 1)[0];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          backgroundColor: savp.active ? "#91ed91" : "#ff6666",
          borderRadius: "8px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
      
        <Box>
          <Typography>Тип</Typography>
          <Typography>{savp.name}</Typography>
        </Box>
        <Box>
          <Typography>Дата начала</Typography>
          <Typography>{savp.rangeStart ? dateConversion(savp.rangeStart) : "Пусто"}</Typography>
        </Box>
        <Box>
          <Typography>Дата конца</Typography>
          <Typography>{savp.rangeFinish ? dateConversion(savp.rangeFinish) : "Пусто"}</Typography>
        </Box>
        <Box>
          <Typography>Остановить</Typography>
          <Typography>
            {savp.dateEnd
              ? dateConversion(savp.dateEnd)
              : "Пусто"}
          </Typography>
        </Box>
        <Box>
          <Typography>Маx. дистр</Typography>
          <Typography>{savp.maxDistributors}</Typography>
        </Box>
        <Box>
          <Typography>Тариф</Typography>
          <Typography>{savp.active ? "Активен" : "Не активен"}</Typography>
        </Box>
      </Box>
      <Divider/>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          backgroundColor: optimiztion.active ? "#91ed91" : "#ff6666",
          borderRadius: "8px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Typography>Тип</Typography>
          <Typography>{optimiztion.name}</Typography>
        </Box>
        <Box>
          <Typography>Дата начала</Typography>
          <Typography>
            {optimiztion.rangeStart
              ? dateConversion(optimiztion.rangeStart)
              : "Пусто"}
          </Typography>
        </Box>
        <Box>
          <Typography>Дата конца</Typography>
          <Typography>
            {optimiztion.rangeFinish
              ? dateConversion(optimiztion.rangeFinish)
              : "Пусто"}
          </Typography>
        </Box>
        <Box>
          <Typography>Остановить</Typography>
          <Typography>
            {optimiztion.dateEnd
              ? dateConversion(optimiztion.dateEnd)
              : "Пусто"}
          </Typography>
        </Box>
        <Box>
          <Typography>Маx. запросов</Typography>
          <Typography>{optimiztion.mapMaxСount}</Typography>
        </Box>

        <Box>
          <Typography>Тариф</Typography>
          <Typography>
            {optimiztion.active ? "Активен" : "Не активен"}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          backgroundColor: logistick.active ? "#91ed91" : "#ff6666",
          borderRadius: "8px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Typography>Тип</Typography>
          <Typography>{logistick.name}</Typography>
        </Box>
        <Box>
          <Typography>Дата начала</Typography>
          <Typography>
            {logistick.rangeStart
              ? dateConversion(logistick.rangeStart)
              : "Пусто"}
          </Typography>
        </Box>
        <Box>
          <Typography>Дата конца</Typography>
          <Typography>
            {logistick.rangeFinish
              ? dateConversion(logistick.rangeFinish)
              : "Пусто"}
          </Typography>
        </Box>
        <Box>
          <Typography>Остановить</Typography>
          <Typography>
            {logistick.dateEnd
              ? dateConversion(logistick.dateEnd)
              : "Пусто"}
          </Typography>
        </Box>
        <Box>
          <Typography>Маx. запросов</Typography>
          <Typography>{logistick.mapMaxСount}</Typography>
        </Box>
        
        <Box>
          <Typography>Тариф</Typography>
          <Typography>{logistick.active ? "Активен" : "Не активен"}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileTarifs;
