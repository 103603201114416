import React, {useCallback, useState} from 'react';
import style from './CreateRouteSheetsOrderList.module.css';
import {ReactComponent as FilterTableIcon} from '../../../../imges/FilterTableIcon.svg';
import CreateRouteSheetsOrderListActionBtns
    from "../CreateRouteSheetsOrderListActionBtns/CreateRouteSheetsOrderListActionBtns";
import CreateRouteSheetsOrderListTableHeader
    from "../CreateRouteSheetsOrderListTableHeader/CreateRouteSheetsOrderListTableHeader";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import debounce from "lodash/debounce";
import {observer} from "mobx-react-lite";
import CreateRouteSheetsOrderListTableList from "../CreateRouteSheetsOrderListTableList/CreateRouteSheetsOrderListTableList";
import SearchInput from "../../../../inputs/logisticService/SearchInput/SearchInput";
import OrderTransferPopup from "../../../../popups/logisticService/OrderTransferPopup/OrderTransferPopup";
import OrderListFilterOptionPopup
    from "../../../../popups/logisticService/OrderListFilterOptionPopup/OrderListFilterOptionPopup";


const CreateRouteSheetsOrderList = observer(() => {
    const {popupType, popupShow, popupScrollPosition} = createRouteSheetsStore.popupState
    const {deliveryArea:{deliveryAreaId}} = createRouteSheetsStore.tableOrderListFilters
    const [widthHeader, setWidthHeader] = useState(0)
    const [searchText, setSearchText] = useState("")

    const setNewWidthHeader = (newValue) => {
        setWidthHeader(newValue)
    }

    const updateAreaName = useCallback(
        debounce((value) => {
            createRouteSheetsStore.changeSearchText(value, "orderList");
        }, 200), // Задержка в миллисекундах
        []
    );

    const changeNewSearchValue = (newValue) => {
        setSearchText(newValue);
        updateAreaName(newValue);
    }

    const clearSearchPanel = () => {
        setSearchText("")
        changeNewSearchValue("")
    }

    return (
        <section className={style.orderListContent}>

            <CreateRouteSheetsOrderListActionBtns/>

            <SearchInput
                onChange={(value) => changeNewSearchValue(value)}
                placeholder={"Поиск заказов..."}
                maxLength={50}
                searchValue={searchText}
                onClear={clearSearchPanel}
            />

            <div className={`
            ${style.filters}
             ${(deliveryAreaId)? style.onFilter : ""}
             `}
            onClick={() => {
                createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "orderListFilterOption");
            }}
            ><FilterTableIcon/><span>Фильтры</span></div>

            <div className={style.tableOrderList}>
                <CreateRouteSheetsOrderListTableHeader widthHeader={setNewWidthHeader}/>
                <CreateRouteSheetsOrderListTableList width={widthHeader}/>
            </div>

            <OrderListFilterOptionPopup
                isOpen={popupShow && popupType === "orderListFilterOption"}
                onClose={() => createRouteSheetsStore.closePopup()}
                scrollPosition={popupScrollPosition}
                onTransfer={() => {}}
            />

        </section>
    );
});

export default CreateRouteSheetsOrderList;