import React from 'react';
import style from './OrderInfoOrCheckbox.module.css';
import {Tooltip} from "react-tooltip";
import {ReactComponent as InfoIcon} from '../../../../imges/InfoIcon.svg';
import {ReactComponent as SuccessIcon} from '../../../../imges/SuccessIcon.svg';

const OrderInfoOrCheckbox = ({orderData, onChangeOrder, onEditOrder, onOpenRoute}) => {

    if(!orderData.coordinates_given){
        return (
            <div className={style.buttonInfo} onClick={()=> onEditOrder(orderData?.order_address_id)}>
                <div
                    data-tooltip-id={`tooltip_${orderData.id}`}
                    data-tooltip-content={"У заказа нет координат. Нажмите на знак ошибки и скорректируйте координаты."}
                    className="my-anchor-element"
                >
                    <InfoIcon/>
                </div>
                <Tooltip
                    id={`tooltip_${orderData.id}`}
                    style={{
                        zIndex: 9999
                    }}
                />
            </div>
        )
    }

    if(orderData.in_route_sheets){
        return (
            <div className={style.buttonSuccess} onClick={(event) => onOpenRoute(event,orderData?.id)}>
                <SuccessIcon/>
            </div>
        )
    }


    return (
        <div
            onClick={(event) => onChangeOrder(event)}
        >
            <input
                type="checkbox"
                className={style.selectDocumentML}
                id={`select_${orderData?.id}`}
                name={`select_${orderData?.id}`}
                value={orderData?.checked || false}
                checked={orderData?.checked || false}
                onChange={() => {
                }}
            />
            <label htmlFor={`select_${orderData?.id}`}></label>
        </div>
    );
};

export default OrderInfoOrCheckbox;