import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

const baseURL = "http://176.99.11.148:5000/api/MVD/";


const useAxios = () => {
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);
 // console.log(authTokens)
    const axiosInstance = axios.create({
        baseURL,
        headers: { Authorization: `Bearer ${authTokens?.access}` }
    });

    
    
    axiosInstance.interceptors.request.use(async (req) => {
        // try {
            const user = jwt_decode(authTokens.access);
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

            if (!isExpired) {
                return req
            } else {
                logoutUser()
            }
            ;            
            const response = await axios.post(`${baseURL}RefreshToken/`, {
                refresh: authTokens.refresh
            });

            localStorage.setItem("authTokens", JSON.stringify(response.data));
            setAuthTokens(response.data);
            setUser(jwt_decode(response.data.access));
            req.headers.Authorization = `Bearer ${response.data.access}`;

            return req;
        // } catch (error) {
        //     setAuthTokens(null);
        //     setUser(null);
        //     localStorage.removeItem("authTokens");
        //     localStorage.clear();
        // //     return req;
        // }
    });

    return axiosInstance;
};

export default useAxios;
