import React, {useContext} from 'react';
import style from './DeliveryAreaCreateActionBtnsGroup.module.css'
import createNewZona from "../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone";
import GlobalContext from "../../../../context/GlobalContext";
import {observer} from "mobx-react-lite";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import DeliveryAreaCreateSelectAreaList from "../DeliveryAreaCreateSelectAreaList/DeliveryAreaCreateSelectAreaList";
import {useNavigate} from "react-router-dom";

const DeliveryAreaCreateActionBtnsGroup = observer(() => {
    const {popupShow,popupType,popupScrollPosition} = createNewZona.popupState
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);
    const navigate = useNavigate();

    const changePopupShow = (value, typePopup) => {
        createNewZona.changePopupShow(window.pageYOffset, value, typePopup);
    }

    return (
        <div className={style.actionBtns}>
            <button
                onClick={() => {
                    createNewZona.deleteSelectPoints()
                }}
                className={style.deleteSelectPoints_btn}>
                Удалить выбранные
            </button>
            <button
                id={'createBtn'}
                className={style.createNewArea_btn}
                onClick={() => createNewZona.createDeliveryArea(authTokens, setUser, setAuthTokens, logoutUser, navigate)}
            >
                Создать
            </button>
            <button
                id={'createBtn'}
                className={style.showCreatingArea_btn}
                onClick={() => {
                    changePopupShow(!popupShow, "selectCreateArea")
                }}
            >
                Показать созданные зоны
            </button>

            <PopupRouting
                isOpen={popupShow && popupType === "selectCreateArea"}
                onClose={() => createNewZona.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <DeliveryAreaCreateSelectAreaList/>
            </PopupRouting>
        </div>
    );
});

export default DeliveryAreaCreateActionBtnsGroup;