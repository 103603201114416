import React, {memo} from 'react';
import style from "./RouteDateAndTimeStart.module.css";
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import {observer} from "mobx-react-lite";

const RouteDateAndTimeStart = observer(() => {
    const {pageState} = optimizationRouteSheetStore;
    const changeUnloadTime = (value) => {
        optimizationRouteSheetStore.changeNewUnloadTime(value);
    }


    return (
        <div className={style.dateAndTimeRouteStart}>
            <div className={style.departureDateTimeSelector}>
                Время на выгрузку в торговых точках (минуты)
            </div>

            <div className={style.selectBlockValueBuildingRoute}>
                <div className={style.selectUnloadTime}>
                    <input
                        type="number"
                        placeholder="15"
                        value={pageState.unload_time}
                        onChange={
                            (e) => {
                                changeUnloadTime(e.target.value)
                            }
                        }
                    />
                </div>
            </div>

        </div>
    );
});

export default memo(RouteDateAndTimeStart);