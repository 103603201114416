import React, {memo} from 'react';
import style from './PaginationRouteSheetsTable.module.css';
import TablePaginationRouteSheets from "../../TablePaginationRouteSheets/TablePaginationRouteSheets";
import {observer} from "mobx-react-lite";
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";

const PaginationRouteSheetsTable = observer(() => {
    const {routingSheets, filteredArrayDoc, tableFilterRouteSheetsState} = optimizationRouteSheetStore;
    const {setting_document} = optimizationRouteSheetStore.pageState
    const {page} = optimizationRouteSheetStore.paginationState;

    const handleChangePage = (newPage) => {
        optimizationRouteSheetStore.handleChangePage(newPage)
    }

    const handleChangeRowsPerPage = (rowsPerPage) => {
        optimizationRouteSheetStore.handleChangeRowsPerPage(rowsPerPage)
    }

    return (
        <div className={
            style.settingListDocument
        }>
            <div className={style.searchTable}>
                <input
                    type="text"
                    placeholder="Поиск..."
                    value={optimizationRouteSheetStore.pageState.table_search_value}
                    onChange={(e) => optimizationRouteSheetStore.changeSearchAddress(e.target.value)}
                />
                <span
                    onClick={
                        (e) => optimizationRouteSheetStore.changeSearchAddress("")
                    }
                >
                    <DeleteIcon/>
                </span>
            </div>

            <div className={style.paginationTable}>
                <TablePaginationRouteSheets
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    count={
                        (tableFilterRouteSheetsState.select_filter) ? filteredArrayDoc.length :
                            (routingSheets === null) ? 0 : routingSheets.length
                    }
                    paginationText={"Документов на странице:"}
                />
            </div>
        </div>
    );
});

export default memo(PaginationRouteSheetsTable);