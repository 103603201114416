import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {useContext, useState} from "react";
import {
    dateConversion,
    generateLocalColorFromText,
    getObjectById
} from "../../../helpers/helpers";
import DistributorCardNavigation from "../DistributorCardNavigation/DistributorCardNavigation";
import {DistributorProgressTab} from "../DistributorProgressTab/DistributorProgressTab";
// import DistributorReloadData from '../DistributorReloadData/DistributorReloadData';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import GlobalContext from "../../../context/GlobalContext";
import DistributorInfoDataForm from "../DistributorInfoDataForm/DistributorInfoDataForm";
import "./DistributorTable.css";

function DistributorTable(props) {
    const {state} = useContext(GlobalContext);
    const distributors = props.distributors;
    const [activeString, setActiveString] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const itemsPerPage = 10; // Number of items to display per page
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <Box className={"distributor-table"}>
            <Card sx={{boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)"}}>

                {!activeString ?
                    <CardContent sx={{padding: 0}}>
                        <Table stickyHeader aria-label="table in dashboard">
                            <TableHead>
                                <TableRow sx={{fontWeight: 800}}>
                                    <TableCell colSpan={2}>Дистрибьютор</TableCell>
                                    <TableCell>Регион</TableCell>
                                    <TableCell align="left">Дата выгрузки</TableCell>
                                    <TableCell align="left">Доля по РФ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {distributors
                                    .slice(
                                        (currentPage - 1) * itemsPerPage,
                                        currentPage * itemsPerPage
                                    )
                                    .map((row) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow
                                                onClick={() => setActiveString(row)}
                                                className="hoverable-row"
                                            >
                                                <TableCell sx={{paddingRight: 0, width: "1vw"}}>

                                                    <Avatar
                                                        sx={{
                                                            bgcolor: generateLocalColorFromText(row.name),
                                                            marginRight: "10px"
                                                        }}
                                                    > {row.name[0]}</Avatar>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    sx={{paddingLeft: 0}}
                                                    scope="row"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.region}</TableCell>
                                                <TableCell align="left">
                                                    {state.registerDates[row.id] ? (
                                                        dateConversion(
                                                            state.registerDates[row.id][0].dateOfUpload
                                                        )
                                                    ) : (
                                                        <CircularProgress/>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <DistributorProgressTab
                                                        value={
                                                            getObjectById(state.docsSum, row.id)
                                                                ? getObjectById(state.docsSum, row.id)
                                                                    .distrpercent
                                                                : 0
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {/*Якорь прокрутки при клике на список */}
                                        </React.Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vw",
                            }}
                        >
                            <Pagination
                                sx={{marginTop: "1vw"}}
                                color="primary"
                                shape="rounded"
                                count={Math.ceil(distributors.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    </CardContent>
                    :
                    <CardContent sx={{padding: 0}}>
                        <IconButton onClick={() => setActiveString(null)}><ArrowCircleLeftIcon
                            color="primary"/></IconButton>
                        <DistributorCardNavigation setActiveString={setActiveString} registerDates={props.registerDates}
                                                   distributor={activeString}/>
                    </CardContent>
                }
            </Card>
            <DistributorInfoDataForm registerDates={props.registerDates} activeString={activeString}/>
        </Box>
    );
}

export default DistributorTable;
