import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { GlobalProvider } from "./context/GlobalContext";
import ContentMenu from "./layout/Content&Menu/Content&Menu";
import AddressCoordinateEditingPage from "./page/AddressCoordinateEditingPage/AddressCoordinateEditingPage";
import AuthorizationPage from "./page/AuthorizationPage/AuthorizationPage";
import ConstructorPage from "./page/ConstructorPage/ConstructorPage";
import DeliveryAreasPage from "./page/DeliveryAreasPage/DeliveryAreasPage";
import DistributorPage from "./page/DistributorPage/DistributorPage";
import InitialPointEditingPage from "./page/InitialPointEditingPage/InitialPointEditingPage";
import RealizationPage from "./page/RealizationPage/RealizationPage";
import RemainsPage from "./page/RemainsPage/RemainsPage";
import ReportsPage from "./page/ReportsPage/ReportsPage";
import RouteDisplayMapPage from "./page/RouteDisplayMapPage/RouteDisplayMapPage";
import RoutingByRouteListPage from "./page/RoutingByRouteListPage/RoutingByRouteListPage";
import SoonPage from "./page/SoonPage/SoonPage";
import UserPage from "./page/UserPage/UserPage";
import PrivateRoute from "./utils/PrivateRoute";
import DeliversRoutesPage from "./page/DeliversRoutesPage/DeliversRoutesPage";
import AdminPage from "./page/AdminPage/AdminPage";
import AdminUserPage from "./page/AdminUserPage/AdminUserPage";
import IsLockedPage from "./page/IsLockedPage/IsLockedPage";
import NotFoundPage from "./page/NotFoundPage/NotFoundPage"; // импортируем компонент страницы 404
import DeliveredStatusListPage from "./page/DeliveredStatusListPage/DeliveredStatusListPage";
import CreateTemplateItem from "./components/transportationLogisticsService/DeliveredStatusListPageComponents/CreateTemplateItem/CreateTemplateItem";
import TemplateList from "./components/transportationLogisticsService/DeliveredStatusListPageComponents/TemplateList/TemplateList";
import DeliveredStatusSubHeader from "./components/transportationLogisticsService/DeliveredStatusListPageComponents/DeliveredStatusSubHeader/DeliveredStatusSubHeader";
import EditTemplateItem from "./components/transportationLogisticsService/DeliveredStatusListPageComponents/EditTemplateItem/EditTemplateItem";
import DeliveryAreaList from "./components/transportationLogisticsService/DeliveryAreasPageComponents/DeliveryAreaList/DeliveryAreaList";
import DeliveryAreaCreate from "./components/transportationLogisticsService/DeliveryAreasPageComponents/DeliveryAreaCreate/DeliveryAreaCreate";
import DeliveryAreaEdit from "./components/transportationLogisticsService/DeliveryAreasPageComponents/DeliveryAreaEdit/DeliveryAreaEdit";
import DeliveryVehiclesPage from "./page/DeliveryVehiclesPage/DeliveryVehiclesPage";
import DeliveryVehiclesList from "./components/transportationLogisticsService/DeliveryVehiclesPageComponents/DeliveryVehiclesList/DeliveryVehiclesList";
import DeliveryVehiclesCreate from "./components/transportationLogisticsService/DeliveryVehiclesPageComponents/DeliveryVehiclesCreate/DeliveryVehiclesCreate";
import DeliveryVehiclesEdit from "./components/transportationLogisticsService/DeliveryVehiclesPageComponents/DeliveryVehiclesEdit/DeliveryVehiclesEdit";
import DriverAndForwarderPage from "./page/DriverAndForwarderPage/DriverAndForwarderPage";
import DriverAndForwarderList from "./components/transportationLogisticsService/DriverAndForwarderPageComponents/DriverAndForwarderList/DriverAndForwarderList";
import DriverAndForwarderCreate from "./components/transportationLogisticsService/DriverAndForwarderPageComponents/DriverAndForwarderCreate/DriverAndForwarderCreate";
import DriverAndForwarderEdit from "./components/transportationLogisticsService/DriverAndForwarderPageComponents/DriverAndForwarderEdit/DriverAndForwarderEdit";
import CreateRouteSheetsPage from "./page/CreateRouteSheetsPage/CreateRouteSheetsPage";
import SubscriptionNotActivePage from "./page/SubscriptionNotActivePage/SubscriptionNotActivePage";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <GlobalProvider>
       
          <ContentMenu
            child={
              <Routes>
                <Route path="/login" element={<AuthorizationPage />} />
                <Route path="/admin" element={<AdminPage />} />
                {/* <Route
                                    path="/admin/users"
                                    element={
                                        <PrivateRoute>
                                            <AdminUserPage/>
                                        </PrivateRoute>
                                    }
                                /> */}
                <Route
                  path="/notSubscription"
                  element={
                    <PrivateRoute>
                      <SubscriptionNotActivePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <UserPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <DistributorPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/realization"
                  element={
                    <PrivateRoute>
                      <RealizationPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/remains"
                  element={
                    <PrivateRoute>
                      <RemainsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <PrivateRoute>
                      <ReportsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/сonstructor"
                  element={
                    <PrivateRoute>
                      <ConstructorPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/logisticService/routingByRouteList/*"
                  element={
                    <PrivateRoute>
                      <RoutingByRouteListPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/logisticService/deliveryAreas/"
                  element={
                    <PrivateRoute>
                      <DeliveryAreasPage />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="areaList/:typeList"
                    element={
                      <PrivateRoute>
                        <DeliveryAreaList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="createArea"
                    element={
                      <PrivateRoute>
                        <DeliveryAreaCreate />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editArea/:areaId"
                    element={
                      <PrivateRoute>
                        <DeliveryAreaEdit />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/logisticService/mapRouteView/"
                  element={
                    <PrivateRoute>
                      <RouteDisplayMapPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/logisticService/initialsPointsEditing/"
                  element={
                    <PrivateRoute>
                      <InitialPointEditingPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/logisticService/deliversRoutes/"
                  element={
                    <PrivateRoute>
                      <DeliversRoutesPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/logisticService/addressCoordinateEditing/*"
                  element={
                    <PrivateRoute>
                      <AddressCoordinateEditingPage />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/logisticService/createRouteSheets/"
                  element={
                    <PrivateRoute>
                      <CreateRouteSheetsPage />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/logisticService/deliveredStatusList/*"
                  element={
                    <PrivateRoute>
                      <DeliveredStatusListPage />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path={"templateList/:typeStatus"}
                    element={
                      <PrivateRoute>
                        <DeliveredStatusSubHeader />
                        <TemplateList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={"createTemplate"}
                    element={
                      <PrivateRoute>
                        <CreateTemplateItem />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={"editTemplate/:idTemplate"}
                    element={
                      <PrivateRoute>
                        <EditTemplateItem />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/logisticService/vehicleList/"
                  element={
                    <PrivateRoute>
                      <DeliveryVehiclesPage />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path={"all"}
                    element={
                      <PrivateRoute>
                        <DeliveryVehiclesList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={"createVehicle"}
                    element={
                      <PrivateRoute>
                        <DeliveryVehiclesCreate />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={"editVehicle/:vehId"}
                    element={
                      <PrivateRoute>
                        <DeliveryVehiclesEdit />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/logisticService/driverAndForwarder/"
                  element={
                    <PrivateRoute>
                      <DriverAndForwarderPage />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path={"list/:typeList"}
                    element={
                      <PrivateRoute>
                        <DriverAndForwarderList />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path={"create/"}
                    element={
                      <PrivateRoute>
                        <DriverAndForwarderCreate />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path={"editCard/:cardId"}
                    element={
                      <PrivateRoute>
                        <DriverAndForwarderEdit />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/soon"
                  element={
                    <PrivateRoute>
                      <SoonPage />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<NotFoundPage />} />{" "}
                {/* маршрут для страницы 404 */}
              </Routes>
            }
          />
        </GlobalProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
