import React, {memo} from 'react';
import style from "./RoutingByRouteListPage.module.css";
import GeneralRouteOptimizationSettings
    from "../../components/transportationLogisticsService/RoutingByRouteListComponents/GeneralRouteOptimizationSettings/GeneralRouteOptimizationSettings";
import ListOfDocumentsOfRouteSheets
    from "../../components/transportationLogisticsService/RoutingByRouteListComponents/ListOfDocumentsOfRouteSheets/ListOfDocumentsOfRouteSheets";
import {Toaster} from "react-hot-toast";
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import HeaderButtonGroup
    from "../../components/transportationLogisticsService/RoutingByRouteListComponents/HeaderButtonGroup/HeaderButtonGroup";
import PaginationRouteSheetsTable
    from "../../components/transportationLogisticsService/RoutingByRouteListComponents/PaginationRouteSheetsTable/PaginationRouteSheetsTable";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "../../utils/PrivateRoute";
import RouteUpdateOrUnloadButtonGroup from '../../components/transportationLogisticsService/RoutingByRouteListComponents/RouteUpdateOrUnloadButtonGroup/RouteUpdateOrUnloadButtonGroup';

const RoutingByRouteListPage = () => {

    return (
        <>
            {/*Уведомления*/}
            <Toaster containerStyle={{
                    position: 'fixed',
                    top: '80px'
                }} />

            <div className={style.mainMenu}>
                <MainMenuLogisticsService namePage={"Маршрутизация по маршрутному листу"}/>
            </div>


            <Routes>
                <Route path='routeSheetsList/:uploadDate?'
                       element={
                           <PrivateRoute>
                               <HeaderButtonGroup/>
                           </PrivateRoute>
                       }
                />
            </Routes>

            <div className={style.routeOptimizationPageStyleSelect}>
                {/*<div className={style.btnFaq}>FAQ</div>*/}

                <Routes>
                    <Route path='routeSheetsList/:uploadDate?'
                           element={
                               <PrivateRoute>
                                   <GeneralRouteOptimizationSettings/>
                                       <div className={style.tableListHeader}>
                                           Маршрутные листы
                                       </div>
                                   <PaginationRouteSheetsTable/>
                               </PrivateRoute>
                           }
                    />
                </Routes>

                <ListOfDocumentsOfRouteSheets/>

            </div>
        </>
    )
};

export default memo(RoutingByRouteListPage);