import React, {useState} from 'react';
import style from "./OrderListFilterOptionPopup.module.css";
import createRouteSheetsStore
    from "../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import {ReactComponent as SettingIcon_v2} from '../../../imges/SettingIcon_v2.svg';
import {observer} from "mobx-react-lite";
import {toastMassage} from "../../../components/ToastMassage/ToastMassage";
import toast from "react-hot-toast";
import {CSSTransition} from "react-transition-group";

const FilterChangeOptions = observer(({setChangeDeliveryArea, filterOption}) => {
    const {deliveryAreaName} = filterOption
    const [errorText, setErrorText] = useState("");
    const [showError, setShowError] = useState(false);

    const onFilterOrderList = (optionData) => {
        createRouteSheetsStore.setFilterOrderList(optionData);
    }

    return (
        <div>
            <div className={style.popupHeader}>
                Фильтры списка заказов
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup();
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>

            <div className={style.selecteredArea}>
                <div>
                    По выбранной зоне доставки:
                </div>
                <div className={style.routeOptimizationInitPoint}>
                    <div
                        className={style.btnSelectInitPoint}
                        onClick={() => {
                            const {selectArea} = createRouteSheetsStore
                            if (selectArea.length === 0) {
                                setErrorText("Нет выбранных зон доставки");
                                setShowError(true);
                                return
                            }
                            setChangeDeliveryArea(true)
                        }}
                    >
                        <SettingIcon_v2/>
                        <div>{((deliveryAreaName?.length > 25) ? deliveryAreaName.slice(0, 25) + "..." : deliveryAreaName) || "Выбрать зону доставки"}</div>
                    </div>

                </div>
            </div>

            <CSSTransition in={showError} timeout={2500} classNames="my-node" unmountOnExit >
                <div
                    className={
                        style.errorMessage
                    }>
                    {errorText}
                </div>
            </CSSTransition>

            <div className={style.actionAskBtns}>
                <button
                    className={style.acceptAskBtn}
                    onClick={() => onFilterOrderList(filterOption)}
                >Сохранить
                </button>
                <button
                    className={style.acceptAskBtn}
                    onClick={() => onFilterOrderList({
                        deliveryAreaId: null,
                        deliveryAreaName: null
                    })}
                >Сбросить
                </button>
                <button
                    className={style.cancelAskBtn}
                    onClick={() => createRouteSheetsStore.closePopup()}
                >Отменить
                </button>
            </div>

        </div>
    );
});

export default FilterChangeOptions;