import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import ResizeObserver from "resize-observer-polyfill";
import style from './RouteSheetTableHeader.module.css'
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
// import style from "../CreateRouteSheetsOrderListTableHeader/CreateRouteSheetsOrderListTableHeader.module.css";

const RouteSheetTableHeader = observer(({widthHeader}) => {
    const {tableRouteSheetsState:{selectAll}} = createRouteSheetsStore
    const containerRef = useRef(null);

    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                widthHeader(containerRef.current.offsetWidth)
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateSize();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Установить размеры при первой отрисовке
        updateSize();

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [containerRef.current]);


    const changeSelectAll = (e) => {
        e.preventDefault()
        createRouteSheetsStore.changeSelectedAllInTable(!selectAll, "routeSheetList")
    }

    return (
        <div ref={containerRef} className={style.tableHeader}>
            <div
            onClick={(e) => changeSelectAll(e)}
            >
                <input
                    type="checkbox"
                    className={style.selectDocumentML}
                    id="selectAll_RouteSheetList"
                    name="selectAll_RouteSheetList"
                    value={selectAll}
                    checked={selectAll}
                    onChange={(e) => {}}
                />
                <label htmlFor="selectAll_RouteSheetList"></label>
            </div>
            <div>Грузоподъёмность (т)</div>
            <div>Транспорт (Наименование)</div>
            <div>Вес заказов (кг)</div>
            <div>Заказов</div>
            <div>Водитель</div>
            <div>Свободный вес (кг)</div>
            <div>Номер маршрутного листа</div>
        </div>
    );
});

export default RouteSheetTableHeader;