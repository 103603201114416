import React from 'react';
import style from "./OrderListFilterOptionPopup.module.css";
import createRouteSheetsStore
    from "../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import {AutoSizer, List} from "react-virtualized";
import {observer} from "mobx-react-lite";
import {v4 as uuidv4} from "uuid";
import DeliveryArea from "./DeliveryArea";

const ChangeDeliveryArea = observer(({setChangeDeliveryArea, setFilterOption}) => {
    const {selectArea} = createRouteSheetsStore

    const rowRenderer = ({index, key, style}) => {
        return (
            <div key={key} style={style}>
                <DeliveryArea
                    key={uuidv4()}
                    number={index}
                    areaData={selectArea[index]}
                    setFilterOption={(value) => setFilterOption(value)}
                    setChangeDeliveryArea={(value) => setChangeDeliveryArea(value)}
                />
            </div>
        );
    };

    return (
        <div>
            <div className={style.popupHeader}>
                Выберите зону доставки
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        setChangeDeliveryArea(false)
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>
            <div>
                {selectArea.length > 0 && <AutoSizer disableHeight>
                    {({width}) => (
                        <List
                            // ref={setListRef}
                            width={width}
                            height={255}
                            rowCount={selectArea.length} // Количество элементов в списке
                            rowHeight={51} // Высота отдельного элемента списка
                            rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                            className={style.popupInitPointListBlock}
                            overscanRowCount={10}
                        />
                    )}
                </AutoSizer>}
            </div>
        </div>
    );
});

export default ChangeDeliveryArea;