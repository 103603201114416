import React, {useContext, useEffect, useRef, useState} from 'react';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {addDays, format} from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import "./DatePickerTwoRange.css"
import {Box, Button} from "@mui/material";
import {dateConversion, dateConvertSelection} from "../../../helpers/helpers";
import GlobalContext from "../../../context/GlobalContext";

const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timezone: 'UTC',
    // hour: 'numeric',
    // minute: 'numeric',
};
const DatePickerTwoRange = ({   type,
                                distributorStartDateReportsStart,
                                setDistributorStartDateReportsStart,
                                distributorFinishDateReportsStart,
                                setDistributorFinishDateReportsStart
                            }) => {


    const [state, setState] = useState([
        {
            startDate: distributorStartDateReportsStart,
            endDate: distributorFinishDateReportsStart,
            key: 'selection'
        }
    ]);

    const [open, setOpen] = useState(false)

    const closeDate = () => {
        setState([{
            startDate: distributorStartDateReportsStart,
            endDate: distributorFinishDateReportsStart,
            key: 'selection'
        },

        ])
        setOpen(false)
    }

    const saveDate = () => {
        setDistributorStartDateReportsStart(state[0].startDate)
        setDistributorFinishDateReportsStart(state[0].endDate)
        setOpen(false)
    }

    const clearDate = () => {
        setDistributorStartDateReportsStart(null)
        setDistributorFinishDateReportsStart(null)
        setDistributorStartDateReportsStart(state[0].startDate)
        setDistributorFinishDateReportsStart(state[0].endDate)
        setState([{
            startDate: null,
            endDate: null,
            key: 'selection'
        }])
        setOpen(false)
        setOpen(false)
    }

    const calendarRef = useRef(null);

    const handleToggleCalendar = () => {
        setOpen(!open);
    };

    const handleClickOutsideCalendar = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideCalendar);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideCalendar);
        };
    }, []);
    return (
        <>
            {!open ?
                <div className="rdrDateDisplay" style={{width: 200, color: "rgb(61, 145, 255)}}"}}>
                        <span className="rdrDateInput rdrDateDisplayItem rdrDateDisplayItemActive">
                        <input onClick={handleToggleCalendar}
                               value={`${state[0].startDate ? new Date((state[0].startDate)).toLocaleString("ru", options) : "Начало"}` + " / " + `${state[0].endDate ? new Date((state[0].endDate)).toLocaleString("ru", options) : "Конец"}`}/>
                        </span>
                </div>
                :
                <>
                    <div className="rdrDateDisplay" style={{width: 200, color: "rgb(61, 145, 255)}}"}}>

                    </div>
                    <Box className="dataPickerBox" ref={calendarRef}>


                        <DateRange
                            showMonthAndYearPickers={true}
                            editableDateInputs={true}
                            onChange={item => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            maxDate={addDays(new Date(), 0)}
                            ranges={state}
                            locale={ruLocale}
                            rangeColors={type == "left" ? ["#2476cc"] : ["#17bd2e"]}
                            startDatePlaceholder="Начальная дата"
                            endDatePlaceholder="Конечная дата"
                            dateDisplayFormat="dd.MM.yyyy" // изменение формата вывода даты

                        />
                        <Box className="buttonPicker">

                            <Button
                                onClick={() => clearDate()}>Очистить
                            </Button>
                            <Button
                                onClick={() => closeDate()}>Отмена
                            </Button>
                            <Button
                                onClick={() => saveDate()}>Применить
                            </Button>
                        </Box>
                    </Box>
                </>
            }
        </>
    );
};

export default DatePickerTwoRange;
