import {makeAutoObservable} from "mobx";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import axios from "axios";
import toast from "react-hot-toast";

class GlobalState {

    constructor() {
        makeAutoObservable(this)
    }

    async useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser) {
        const user = jwt_decode(authTokens.access);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

        if (!isExpired) {
            return authTokens.access;
        } else {
            logoutUser();
        }

        try {
            const response = await axios.post(`http://192.168.1.14:5001/api/MVD/RefreshToken/`, {
                refresh: authTokens.refresh
            });

            localStorage.setItem("authTokens", JSON.stringify(response.data));
            setAuthTokens(response.data);
            setUser(jwt_decode(response.data.access));
            return response.data.access;
        } catch (e) {
            console.log(e)
        }
    }

    async checkCountOrTariff(typeCounter, authTokens, setUser, setAuthTokens, logoutUser){
        let userAccessToken;
        let resultRequest = {
            status: 205,
            textStatus: "Тариф активен"
        }

        await this.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/checkOrCountingVKAPI/${typeCounter}`, {headers}
        ).then(res => {
            resultRequest.status = res.data.status;
            resultRequest.textStatus = res.data.textStatus
        }).catch(error => {
            const {status, textStatus} = error.response.data;
            console.log(error.response.data)
            resultRequest.status = status;
            resultRequest.textStatus = textStatus
        })
        return resultRequest
    }

    getISONowDate() {
        const currentDate = new Date();
        const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

        // Увеличиваем дату на один день
        const destinationDateT = new Date();
        destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);
        return destinationDateT.toISOString().slice(0, 16)
    }
}

export default new GlobalState();