import EventBusyIcon from '@mui/icons-material/EventBusy';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { CardHeader, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { getObjectByManId } from "../../../helpers/helpers";
import "./ManufacturersInfoDataForm.css";
import RegisterDates from "../../ui/RegisterDates/RegisterDates";
function ManufacturersInfoDataForm(props) {
  const { state } = useContext(GlobalContext);

  const profileDistibutorRegisterDate = state.profileDistibutorRegisterDate
  return (
    <Box sx={{}}>
      <Box className="distributorInfoDataForm">
        <Card sx={{ boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)" }}>

         
          <CardContent sx={{ padding: "2%" }}>
            <Box className="iconNome">
              <StorefrontTwoToneIcon color={"primary"} fontSize="large" />
              {/* {props.activeString ? */}
              <Typography variant="h6">
              {props.activeString && Boolean(getObjectByManId(state.distributorDocsSum, props.activeString.id))
              ? getObjectByManId(state.distributorDocsSum, props.activeString.id).retOutsCount
              : 0}
              </Typography>
              {/* : null} */}

              <Typography sx={{ fontSize: "10pt" }}>Кол-во ТТ</Typography>
            </Box>
          </CardContent>
        </Card>
        
        <Card sx={{ boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)" }}>
          <CardContent sx={{ padding: "2%" }}>
            <Box className="iconNome">
              <Inventory2TwoToneIcon color={"primary"} fontSize="large" />
              <Typography variant="h6">
                {props.activeString && Boolean(getObjectByManId(state.distributorDocsSum, props.activeString.id))
                  ? getObjectByManId(state.distributorDocsSum, props.activeString.id).nomsCount
                  : 0}
              </Typography>
              <Typography sx={{ fontSize: "10pt" }}>Кол-во SKU</Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box className="infoData">
        <Card
          sx={{
            boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
            height: "35vh",
          }}
        >
          <CardHeader sx={{padding: "2%", color: "#ffffff", backgroundColor: "#006fcb"}} title={<Typography sx={{fontSize: "15px"}}>Календарь выгрузок: {props.activeString ? props.activeString.name : null}</Typography>} />
          {props.activeString ?
          <CardContent sx={{padding: "0"}}>
          <RegisterDates
            dateArr={profileDistibutorRegisterDate.filter( (item) => item.manufacturerInn === props.activeString.inn)}
          />
          
          </CardContent>
          : 
       
          <CardContent className="ifLessDate">
                      <EventBusyIcon sx={{fontSize: "110px"}} color="primary"/>

          <Typography className="ifLessTwoText">
          Дистрибьютор не выбран
          </Typography>
          
          </CardContent>
           }
        </Card>
      </Box>
    </Box>
  );
}

export default ManufacturersInfoDataForm;
