import React, { memo, useContext } from "react";
import style from "./RouteUpdateOrUnloadButtonGroup.module.css";
import optimizationRouteSheetStore from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import { ReactComponent as UpdateIcon } from "../../../../imges/UpdateIcon.svg";
import { ReactComponent as UploadPhoneIcon } from "../../../../imges/UploadPhoneIcon.svg";
import { ReactComponent as QRIcon } from "../../../../imges/QRIcon.svg";
import GlobalContext from "../../../../context/GlobalContext";
import toast from "react-hot-toast";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toastMassage } from '../../../ToastMassage/ToastMassage';

const RouteUpdateOrUnloadButtonGroup = observer(() => {
    const axiosInstance = AxiosConfig_LogisticService();
    const {routingSheets} = optimizationRouteSheetStore;
    const {uploadDate} = useParams();

  const handlePrint = () => {
    if (!routingSheets) {
      toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
      return;
    }
    if (
      !routingSheets.some((obj) => obj.checked && obj.in_route) ||
      routingSheets.some((obj) => obj.checked && !obj.in_route)
    ) {
      toastMassage(toast.error, "Документы должны быть в маршруте, для того чтобы сформировать доступ.", 10000, "bottom-right")
      return;
    }

    optimizationRouteSheetStore.changeCreateMobAuthData();
  };

    return (
        <div className={style.updateOrUploadBtns}>
            <button
                className={style.updateCoordinateDocument}
                onClick={async () => {
                    await optimizationRouteSheetStore.updateAddressCoordinateOnOrders(uploadDate,axiosInstance)
                }}
            >
                <UpdateIcon/>
                Обновить координаты документов
            </button>
            <button
                className={style.uploadDocForMobileApp}
                onClick={async () => {
                    await optimizationRouteSheetStore.unloadRouteSheetsInMobileApp(uploadDate, axiosInstance)
                }}
            >
                <UploadPhoneIcon/>
                Выгрузить в мобильное приложение
            </button>

      <button className={style.generateQr} onClick={handlePrint}>
        <QRIcon />
        Печать доступа к приложению
      </button>
    </div>
  );
});

export default memo(RouteUpdateOrUnloadButtonGroup);
