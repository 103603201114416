import { Box, Card } from '@mui/material';
import * as React from 'react';
import VerticalStepperInfo from '../../components/VerticalStepperInfo/VerticalStepperInfo';
import "./SoonPage.css";
const SoonPage = () => {
    return (
        <Box className={"soon-page"}>
          <Card className={"soon-card"}>
            <Box className={"soon-card-box"}>
              <VerticalStepperInfo/>
          </Box>
          </Card>
        </Box>
    );
}
export default SoonPage