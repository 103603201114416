import React, {useCallback, useEffect, useState} from 'react';
import style from './ModalSelectedVehicle.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import CreateRouteSheetsOrderListModalSelectedInitialPoint
    from "../ModalChangeRouteParam/ModalChangeRouteParam";
import {observer} from "mobx-react-lite";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import {AutoSizer, List} from "react-virtualized";
import InitialPointPopupItem from "../../InitialPointPopupItem/InitialPointPopupItem";
import {v4 as uuidv4} from "uuid";
import ModalSelectedInitialPointItem from "../ModalSelectedInitialPointItem/ModalSelectedInitialPointItem";
import ModalSelectedDriverItem from "../ModalSelectedDriverItem/ModalSelectedDriverItem";
import ModalSelectedInitialVehicleItem from "../ModalSelectedInitialVehicleItem/ModalSelectedInitialVehicleItem";
import debounce from "lodash/debounce";
import routeParamHandlerHoc from "../hoc/RouteParamHandlerHOC";




const ModalSelectedVehicle = observer(({typeAction, newSearchValue, nameArrayData, nameChange}) => {
    const listData = createRouteSheetsStore[nameArrayData]
    const [searchValue, setSearchValue] = useState("")


    useEffect(() => {
        return () => {
            createRouteSheetsStore.changeDefaultModalData(nameArrayData)
        }
    }, [])

    const ModalSelectedInitialVehicleItemHOC = routeParamHandlerHoc(
        ModalSelectedInitialVehicleItem,
        "changeVehicle",
        typeAction
    )


    const rowRenderer = ({index, key, style}) => {
        return (
            <div key={key} style={style}>
                <ModalSelectedInitialVehicleItemHOC
                    paramRowData={listData[index]}
                    key={uuidv4()}
                />
            </div>
        )
            ;
    };

    const changeNewSearchValue = (newValue) => {
        setSearchValue(newValue);
        newSearchValue(newValue);
    }

    return (
        <div className={style.selectInitialPointContent}>
            <div className={style.popupHeader}>
                <div className={style.goBackToList}
                     onClick={() => createRouteSheetsStore.changeRouteSheetDataModal(false, nameChange)}>Назад
                </div>
                Выбор транспорта
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup();
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>

            <div
                id={"searchInputZone"}
                className={style.inputSearchNameZona}>
                <input
                    type="text"
                    maxLength={50}
                    value={searchValue}
                    onChange={(e) => changeNewSearchValue(e.target.value)}
                    placeholder="Поиск.."
                />
                <span onClick={
                    () => {
                        setSearchValue("")
                        changeNewSearchValue("")
                    }
                }>Очистить</span>
            </div>

            <div className={style.popupHeaderList}>
                <div>Наименование</div>
                <div>
                    <div>Гос. номер</div>
                    <div>Марка</div>
                </div>
                <div>
                    <div>Грузоподъемность</div>
                    <div>Вес заказов</div>
                </div>
            </div>


            {
                listData && <div>
                    <AutoSizer>
                        {({width, height}) => (
                            <List
                                width={width}
                                height={405}
                                rowCount={listData?.length || 0} // Количество элементов в списке
                                rowHeight={101} // Высота отдельного элемента списка
                                rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                className={style.popupInitPointListBlock}
                            />
                        )}
                    </AutoSizer>
                </div>}
        </div>
    );
});

export default ModalSelectedVehicle;