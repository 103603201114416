import React, {memo} from 'react';
import style from './DeliveryAreaCoordinate.module.css'
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {observer} from 'mobx-react-lite';
import createNewZona from '../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone'
import EditDeliveryAreaStore from '../../../../store/transportationLogisticsService/PageDeliveryZone/editDeliveryArea'


const DeliveryAreaCoordinate = observer(({typeAction, number, coordinate, onChecked, zona}) => {

    return (
        <div  className={style.deliveryAreaPoint} >
            <div>
                {/* Флажок для выбора зоны доставки */}
                <input type="checkbox"
                       className={style.selectAreaPoint}
                       id={`Point_${number}`}
                       name="selectedDeliveryZone"
                       checked={onChecked}
                       onChange={(e) => {
                           if (typeAction === "create") {
                               createNewZona.changeCheckboxPoint(coordinate,e.target.checked)
                           } else if (typeAction === "update"){
                               EditDeliveryAreaStore.setCheckedPointArea(coordinate,e.target.checked)
                           }
                       }}
                />
                <label htmlFor={`Point_${number}`}></label>
            </div>
            <div>
                Точка № {number}
            </div>
            <div className={style.deleteAreaPoint} onClick={() => {
                if (typeAction === "create") {
                    createNewZona.deleteCoordinateForArr(coordinate)
                } else if (typeAction === "update"){
                    EditDeliveryAreaStore.deleteThisPoint(coordinate)
                }
            }}>
                <DeleteIcon/>
            </div>
        </div>
    );
});

export default memo(DeliveryAreaCoordinate);