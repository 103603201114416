import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import "./AdminUpdateUserForm.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { dateConvertSelection } from "../../helpers/helpers";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import { styled } from "@mui/material/styles";
import AdminFormMapKey from "../AdminFormMapKey/AdminFormMapKey";
import AdminFormGetToken from "../AdminFormGetToken/AdminFormGetToken";

const Label = styled("label")`
  margin-left: 20px;
  line-height: 1.5;
  display: block;
`;
export default function AdminUpdateUserForm({ selectedUser, setSelectedUser }) {
  const { apiAdminUsersPatchTariff, apiAdminUsersPatch } = useAxiosAPI();

  const saveTariff = (data) => {
    apiAdminUsersPatchTariff(data)
      .then((resolve) => {
        alert("Тариф обновлен");
      })
      .catch(() => {
        alert("Что-то пошло не так :(");
      });
  };

  const saveUser = (data) => {
    apiAdminUsersPatch(data)
      .then((resolve) => {
        alert("Пользователь обновлен");
      })
      .catch(() => {
        alert("Что-то пошло не так :(");
      });
  };
  const [savpRate, setSavpRate] = useState(
    selectedUser.tarifs.filter((item) => item.code === 2)[0]
  );

  console.log(savpRate.dateEnd)

  
  const [logisticsRate, setlogisticsRate] = useState(
    selectedUser.tarifs.filter((item) => item.code === 1)[0]
  );
  const [routeRate, setRouteRate] = useState(
    selectedUser.tarifs.filter((item) => item.code === 3)[0]
  );



  const [showPassword, setShowPassword] = React.useState(false);

  const [userData, setUserData] = React.useState(selectedUser);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Paper className={"adminFormPaper"} elevation={2}>
      <Button onClick={() => setSelectedUser(null)}>
        Назад
      </Button>
      <Typography
        sx={{ textAlign: "center", padding: "20px" }}
        variant="h5"
        gutterBottom
      >
        Пользователь
      </Typography>
      <Divider />
      <Box sx={{ padding: "20px" }}>
        <TextField
          sx={{ margin: "20px" }}
          className={"adminTextField"}
          id="standard-basic"
          defaultValue={selectedUser.name}
          disabled={true}
          label="ФИО"
          variant="standard"
        />
        <TextField
          sx={{ margin: "20px" }}
          className={"adminTextField"}
          id="standard-basic"
          defaultValue={selectedUser.organization}
          disabled={true}
          label="Организация"
          variant="standard"
        />

        <TextField
          sx={{ margin: "20px" }}
          className={"adminTextField"}
          id="standard-basic"
          defaultValue={selectedUser.inn}
          disabled={true}
          label="ИНН"
          variant="standard"
        />
        <TextField
          sx={{ margin: "20px" }}
          className={"adminTextField"}
          id="standard-basic"
          defaultValue={selectedUser.mail}
          disabled={true}
          label="Почта"
          variant="standard"
        />
        <TextField
          sx={{ margin: "20px" }}
          className={"adminTextField"}
          id="standard-basic"
          defaultValue={
            selectedUser.accessLevel === 0 ? "Дистрибьютор" : "Производитель"
          }
          disabled={true}
          label="Тип пользователя"
          variant="standard"
        />

        <FormControl
          sx={{ margin: "20px" }}
          className={"adminTextField"}
          variant="standard"
        >
          <InputLabel id="demo-simple-select-filled-label">Доступ</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={userData.access}
            onChange={(event) =>
              setUserData((prev) => ({
                ...userData,
                access: event.target.value,
              }))
            }
          >
            <MenuItem value={true}>
              Разрешен <DoneOutlineIcon />
            </MenuItem>
            <MenuItem value={false}>
              Запрещен <CancelIcon />
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{ margin: "20px" }}
          className={"adminTextField"}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
          <OutlinedInput
            onChange={(event) =>
              setUserData((prev) => ({
                ...userData,
                password: event.target.value,
              }))
            }
            value={userData.password}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Пароль"
          />
        </FormControl>
        <Box sx={{display: "flex"}}>
        <AdminFormGetToken selectedUser={selectedUser}/>
        <Button
          sx={{ margin: "20px" }}
          variant={"contained"}
          onClick={() => saveUser(userData)}
          className={"adminTextField"}
        >
          Сохранить
        </Button>
        </Box>
      </Box>

      <Divider />
      <Box sx={{ padding: "20px" }}>
        <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom>
          Тарифы
        </Typography>
        <Box sx={{ padding: "20px" }} className={"adminFormPricePaper"}>
          <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom>
            САВП
          </Typography>
          <FormControl
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            variant="standard"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Активен
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={savpRate.active}
              onChange={(event) =>
                setSavpRate((prev) => ({
                  ...savpRate,
                  active: event.target.value,
                }))
              }
            >
              <MenuItem value={true}>
                ДА <DoneOutlineIcon />
              </MenuItem>
              <MenuItem value={false}>
                НЕТ <CancelIcon />
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            onChange={(event) =>
              setSavpRate((prev) => ({
                ...savpRate,
                maxDistributors: event.target.value,
              }))
            }
            id="standard-basic"
            value={savpRate.maxDistributors}
            label="Мах. Дистр."
            variant="standard"
          />
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            onChange={(event) =>
              setSavpRate((prev) => ({
                ...savpRate,
                price: event.target.value,
              }))
            }
            id="standard-basic"
            value={savpRate.price}
            label="Цена"
            variant="standard"
          />
          <Box sx={{ display: "flex" }}>
            <div>
              <Label>Дата начала</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setSavpRate((prev) => ({
                    ...savpRate,
                    rangeStart: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={savpRate.rangeStart}
              />
            </div>
            <div>
              <Label>Дата конца</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setSavpRate((prev) => ({
                    ...savpRate,
                    rangeFinish: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={savpRate.rangeFinish}
              />
            </div>
            <div>
              <Label>Дата приостановки</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setSavpRate((prev) => ({
                    ...savpRate,
                    dateEnd: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={savpRate.dateEnd}
              />
            </div>
          </Box>
          <FormControl
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            variant="standard"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Тариф план
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={savpRate.typeRate}
              onChange={(event) =>
                setSavpRate((prev) => ({
                  ...savpRate,
                  typeRate: event.target.value,
                }))
              }
            >
              <MenuItem
                value={
                  "Базовая цена, руб/мес за 1 дистр, при ежемесячной оплате"
                }
              >
                Базовая цена, руб/мес за 1 дистр
              </MenuItem>
              <MenuItem
                value={
                  "При оплате за 6 месяецв, цена за 1 дистрибьютора" +
                  "делится на: Базовая цена и Тариф для миграции/ПРОМО"
                }
              >
                При оплате за 6 месяецв
              </MenuItem>
              <MenuItem
                value={
                  "При оплате за 12 месяецв, цена за 1 дистрибьютора/мес" +
                  "делится на: Базовая цена и Тариф для миграции/ПРОМО"
                }
              >
                При оплате за 12 месяецв
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            sx={{ margin: "20px" }}
            variant={"contained"}
            onClick={() => saveTariff(savpRate)}
            className={"adminTextField"}
          >
            Сохранить
          </Button>
        </Box>

        <Box
          sx={{ padding: "20px", marginTop: "20px" }}
          className={"adminFormPricePaper"}
        >
          <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom>
            Мобильное приложение
          </Typography>
          <FormControl
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            variant="standard"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Активен
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={logisticsRate.active}
              onChange={(event) =>
                setlogisticsRate((prev) => ({
                  ...logisticsRate,
                  active: event.target.value,
                }))
              }
            >
              <MenuItem value={true}>
                ДА <DoneOutlineIcon />
              </MenuItem>
              <MenuItem value={false}>
                НЕТ <CancelIcon />
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            onChange={(event) =>
              setlogisticsRate((prev) => ({
                ...logisticsRate,
                queryMaxСount: event.target.value,
              }))
            }
            id="standard-basic"
            value={logisticsRate.queryMaxСount}
            label="Мах. Запрсов."
            variant="standard"
          />
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            disabled={true}
            id="standard-basic"
            value={logisticsRate.mapСount}
            label="Использовано. Запрсов."
            variant="standard"
          />
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            onChange={(event) =>
              setlogisticsRate((prev) => ({
                ...logisticsRate,
                price: event.target.value,
              }))
            }
            id="standard-basic"
            value={logisticsRate.price}
            label="Цена"
            variant="standard"
          />
          <FormControl
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            variant="standard"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Тариф план
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={logisticsRate.typeRate}
              onChange={(event) =>
                setlogisticsRate((prev) => ({
                  ...logisticsRate,
                  typeRate: event.target.value,
                }))
              }
            >
              <MenuItem value={"Тариф 1"}>Тариф 1</MenuItem>
              <MenuItem value={"Тариф 2"}>Тариф 2</MenuItem>
              <MenuItem value={"Тариф 3"}>Тариф 3</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex" }}>
            <div>
              <Label>Дата начала</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setlogisticsRate((prev) => ({
                    ...logisticsRate,
                    rangeStart: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={logisticsRate.rangeStart}
              />
            </div>
            <div>
              <Label>Дата конца</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setlogisticsRate((prev) => ({
                    ...logisticsRate,
                    rangeFinish: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={logisticsRate.rangeFinish}
              />
            </div>
            <div>
              <Label>Дата приостановки</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setlogisticsRate((prev) => ({
                    ...logisticsRate,
                    dateEnd: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={logisticsRate.dateEnd}
              />
            </div>
          </Box>
          <Box sx={{display: "flex"}}>
          <TextField
          disabled={true}
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}


            id="standard-basic"
            defaultValue={logisticsRate.quantityKey ? logisticsRate.quantityKey : 0 }
            label="Кол-во лицензий"
            variant="standard"
          />
          <AdminFormMapKey selectedUser={selectedUser}/>
          </Box>
          <Button
            sx={{ margin: "20px" }}
            variant={"contained"}
            onClick={() => saveTariff(logisticsRate)}
            className={"adminTextField"}
          >
            Сохранить
          </Button>
        </Box>

        <Box
          sx={{ padding: "20px", marginTop: "20px" }}
          className={"adminFormPricePaper"}
        >
          <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom>
            Оптимизация маршрутов
          </Typography>
          <FormControl
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            variant="standard"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Активен
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={routeRate.active}
              onChange={(event) =>
                setRouteRate((prev) => ({
                  ...routeRate,
                  active: event.target.value,
                }))
              }
            >
              <MenuItem value={true}>
                ДА <DoneOutlineIcon />
              </MenuItem>
              <MenuItem value={false}>
                НЕТ <CancelIcon />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            variant="standard"
          >
            <InputLabel id="demo-simple-select-filled-label">
              Тариф план
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={routeRate.typeRate}
              onChange={(event) =>
                setRouteRate((prev) => ({
                  ...routeRate,
                  typeRate: event.target.value,
                }))
              }
            >
              <MenuItem value={"Тариф 1"}>Тариф 1</MenuItem>
              <MenuItem value={"Тариф 2"}>Тариф 2</MenuItem>
              <MenuItem value={"Тариф 3"}>Тариф 3</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            onChange={(event) =>
              setRouteRate((prev) => ({
                ...routeRate,
                queryMaxСount: event.target.value,
              }))
            }
            id="standard-basic"
            value={routeRate.queryMaxСount}
            label="Мах. Запрсов."
            variant="standard"
          />
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            disabled={true}
            id="standard-basic"
            value={routeRate.mapСount}
            label="Использовано. Запрсов."
            variant="standard"
          />
          <TextField
            sx={{ margin: "20px" }}
            className={"adminTextField"}
            type={"number"}
            onChange={(event) =>
              setRouteRate((prev) => ({
                ...routeRate,
                price: event.target.value,
              }))
            }
            id="standard-basic"
            value={routeRate.price}
            label="Цена"
            variant="standard"
          />
          <Box sx={{ display: "flex" }}>
            <div>
              <Label>Дата начала</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setRouteRate((prev) => ({
                    ...routeRate,
                    rangeStart: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={routeRate.rangeStart}
              />
            </div>
            <div>
              <Label>Дата конца</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setRouteRate((prev) => ({
                    ...routeRate,
                    rangeFinish: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={routeRate.rangeFinish}
              />
            </div>
            <div>
              <Label>Дата приостановки</Label>
              <TextField
                sx={{ margin: "20px", width: "300px" }}
                className={"adminTextField"}
                type={"date"}
                onChange={(event) =>
                  setRouteRate((prev) => ({
                    ...routeRate,
                    dateEnd: dateConvertSelection(event.target.value) === "Invalid Date" ? null : dateConvertSelection(event.target.value),
                  }))
                }
                id="standard-basic"
                value={routeRate.dateEnd}
              />
            </div>
          </Box>
         
          <Button
            sx={{ margin: "20px" }}
            variant={"contained"}
            onClick={() => saveTariff(routeRate)}
            className={"adminTextField"}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
