import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import * as React from 'react';
import Trophy from "../../../imges/trophy.png";
import {useContext} from "react";
import GlobalContext from "../../../context/GlobalContext";
import "../DistributorRatingBox/DistributorRatingBox.css";
import RotatingLoadIcon from "../../RotatingLoadIcon/RotatingLoadIcon";
import {Box} from "@mui/material";

export default function DistributorSumCard(props) {
    const {state} = useContext(GlobalContext);
    // Общая сумма
    const totalSum = state.docsSum.reduce((accumulator, obj) => accumulator + obj.manSum, 0).toLocaleString();
    const TrophyImg = styled('img')({
        right: 36,
        bottom: 20,
        height: 98,
        position: 'absolute'
    })
    return (
        <Card sx={{boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)", position: 'relative'}}>
            {!props.loadSum ?
                <CardContent>
                    <Typography variant='h5'>{state.manufacturer.name}</Typography>
                    <Typography variant='body2' sx={{letterSpacing: '0.25px'}}>
                        Сумма реализации по дистрибьюторам
                    </Typography>
                    <Typography variant='h5' sx={{my: 4, color: 'primary.main'}}>
                        ₽ {totalSum}
                    </Typography>
                    <TrophyImg src={Trophy}/>
                </CardContent>
                :
                <Box sx={{height: "15vw"}}>
                    <RotatingLoadIcon/>
                </Box>
            }
        </Card>
    );
}

