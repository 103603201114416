// Эта функция предназначена для запуска в Web Worker.
// Функция для изменения выбранных элементов в списке заказов

const changeCheckedAllOrderList = () => {
    onmessage = function (event) {
        const { orderList, value } = event.data;

        let resData = {
            orderList: [],
            selectedOrderList: [],
            value: value
        }

        orderList.forEach(order => {
            if (order?.coordinates_given && !order?.in_route_sheets) {
                order.checked = value;

                const orderIndex = resData.selectedOrderList.findIndex(sOrder => sOrder.id === order.id);
                if (orderIndex > -1) {
                    resData.selectedOrderList.splice(orderIndex, 1);
                } else {
                    if (value){
                        resData.selectedOrderList.push(order);
                    }
                }
            }

            resData.orderList.push(order);
        });

        postMessage(resData);
        // eslint-disable-next-line no-restricted-globals
        close();
    };
}

// Преобразуем функцию в строку.
let code = changeCheckedAllOrderList.toString()
// Удаляем объявление функции, чтобы осталось только тело функции.
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
// Создаем Blob, содержащий тело функции.
const blob = new Blob([code], { type: 'application/javascript' })
// Создаем URL для Blob.
const workerScript = URL.createObjectURL(blob)
// Экспортируем URL.
module.exports = workerScript;

