import {
  Button,
  Checkbox,
  Divider,
  Fade,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import ConstructorPatternModal from "../ConstructorPatternModal/ConstructorPatternModal";
import ConstructorTemplates from "../ConstructorTemplates/ConstructorTemplates";
import DatePickerReportsFinish from "../DatePickerReportsFinish/DatePickerReportsFinish";
import DatePickerReportsStart from "../DatePickerReportsStart/DatePickerReportsStart";
import DatePickerTwoRange from "../ui/DatePickerTwoRange/DatePickerTwoRange";
import "./Constructor.css"
function Row({
  value,
  index,
  toggleCheck,
  setParams,
  onDragStart,
  onDragOver,
  onDragEnd,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const [anchorElTypeDate, setAnchorElTypeDate] = React.useState(null);
  const openDateTypeDate = Boolean(anchorElTypeDate);

  const toggleDateView = (newType) => {
    setParams((prevParams) => {
      const newParams = [...prevParams];
      newParams[index].dateView = newType;
      return newParams;
    });
  }

  const handleClickTypeDate = (event) => {
    setAnchorElTypeDate(event.currentTarget);
  };
  const handleCloseTypeDate = () => {
    setAnchorElTypeDate(null);
  };
  

  const handleClose = () => {
    setAnchorEl(null);
  };


  const toggleType = (newType) => {
    setParams((prevParams) => {
      const newParams = [...prevParams];

      newParams[index].type = newType;
      if (newParams[index].id == 7 && newParams[index].type == "data") {
        [...prevParams].find((item) => item.id === 8).type = "data";
      } else if (newParams[index].id == 8 && newParams[index].type == "data") {
        [...prevParams].find((item) => item.id === 7).type = "data";
      }
      return newParams;
    });
    handleClose(); // Закрываем меню после выбора
  };

  const [anchorElDate, setAnchorElDate] = React.useState(null);
  const openDate = Boolean(anchorElDate);

  const handleClickData = (event) => {
    setAnchorElDate(event.currentTarget);
  };

  const handleCloseData = () => {
    setAnchorElDate(null);
  };

  const toggleDateType = (newType) => {
    setParams((prevParams) => {
      const newParams = [...prevParams];
      newParams[index].dataType = newType;
      return newParams;
    });
    handleCloseData(); // Закрываем меню после выбора
  };

  const handleDragStart = (e, index) => {
    onDragStart(index);
    e.dataTransfer.setData("index", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    const draggedIndex = e.dataTransfer.getData("index");
    onDragEnd(draggedIndex, index);
  };

  return (
    <TableRow
      key={`row-${value.id}`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      draggable
      onDragStart={(e) => handleDragStart(e, index)}
    >
      <TableCell>
        <Checkbox
          onClick={() => toggleCheck(index)}
          edge="start"
          checked={value.check}
          tabIndex={-1}
          disableRipple
        />{" "}
        {value.property}
      </TableCell>
      <TableCell>
        {value.id == 1 ?
        <>
        <Button
        aria-controls={openDateTypeDate ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openDateTypeDate ? "true" : undefined}
        onClick={handleClickTypeDate}
      >
        {value.dateView}
      </Button>
       <Menu
       id="fade-menu"
       MenuListProps={{
         "aria-labelledby": "fade-button",
       }}
       anchorEl={anchorElTypeDate}
       open={openDateTypeDate}
       onClose={handleCloseTypeDate}
       TransitionComponent={Fade}
     >
       <MenuItem onClick={() => toggleDateView("Дни")}>Дни</MenuItem>
       <MenuItem onClick={() => toggleDateView("Декады")}>Декады</MenuItem>
       <MenuItem onClick={() => toggleDateView("Месяцы")}>Месяцы</MenuItem>
       <MenuItem onClick={() => toggleDateView("Кварталы")}>Кварталы</MenuItem>
       <MenuItem onClick={() => toggleDateView("Годы")}>Годы</MenuItem>

     </Menu>
     </>
        : 
        null}
      </TableCell>
      <TableCell>
        <Button
          disabled={!value.check}
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {value.type === "row"
            ? "Названия строк"
            : value.type === "column"
            ? "Названия столбцов"
            : value.type === "data"
            ? "Значения"
            : "Пусто"}
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => toggleType("row")}>Названия строк</MenuItem>
          <MenuItem onClick={() => toggleType("column")}>Названия столбцов</MenuItem>
          {value.id != 1 && value.id != 2 && value.id != 6 ?
          <MenuItem onClick={() => toggleType("data")}>Значения</MenuItem>
          : null}
        </Menu>
      </TableCell>
      

      <TableCell>
        <Button
          disabled={value.type !== "data" || !value.check}
          id="fade-button"
          aria-controls={openDate ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openDate ? "true" : undefined}
          onClick={handleClickData}
        >
          {value.dataType ? value.dataType : "пусто"}
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorElDate}
          open={openDate}
          onClose={handleCloseData}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => toggleDateType("Сумма")}>Сумма</MenuItem>
          <MenuItem onClick={() => toggleDateType("Количество")}>
            Количество
          </MenuItem>
          <MenuItem onClick={() => toggleDateType("Среднее")}>Среднее</MenuItem>
          <MenuItem onClick={() => toggleDateType("Максимум")}>
            Максимум
          </MenuItem>
          <MenuItem onClick={() => toggleDateType("Минимум")}>Минимум</MenuItem>
          <MenuItem onClick={() => toggleDateType("Произведение")}>
            Произведение
          </MenuItem>
          <MenuItem onClick={() => toggleDateType("Количество чисел")}>
            Количество чисел
          </MenuItem>
          <MenuItem onClick={() => toggleDateType("Смещенное отклонение")}>
            Смещенное отклонение
          </MenuItem>
          <MenuItem onClick={() => toggleDateType("Несмещенное отклонение")}>
            Несмещенное отклонение
          </MenuItem>
          <MenuItem onClick={() => toggleDateType("Смещенная дисперсия")}>
            Смещенная дисперсия
          </MenuItem>
          <MenuItem onClick={() => toggleDateType("Несмещенная дисперсия")}>
            Несмещенная дисперсия
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

export default function Constructor() {
  const { apiConstructorExel, apiPivotGet } = useAxiosAPI();
  const [openSave, setOpenSave] = React.useState(false);
  const [openTemplates, setOpenTemplates] = React.useState(false);
 

  const [params, setParams] = useState([
    {
      id: 1,
      property: "Дата",
      type: "row",
      dataType: null,
      check: true,
      dateView: "Кварталы"
    },
    {
      id: 2,
      property: "Дистрибьютор",
      type: "row",
      dataType: null,
      check: true,
      dateView: null

    },
    {
      id: 3,
      property: "Торговая точка",
      type: "row",
      dataType: null,
      check: true,
      dateView: null
    },
    {
      id: 4,
      property: "Номенклатура",
      type: "row",
      dataType: null,
      check: true,
      dateView: null
    },
    {
      id: 5,
      property: "Группа товаров",
      type: "row",
      dataType: null,
      check: true,
      dateView: null
    },
    {
      id: 6,
      property: "Бренд",
      type: "row",
      dataType: null,
      check: true,
      dateView: null
    },
    {
      id: 7,
      property: "Количество реализованных единиц",
      type: "row",
      dataType: null,
      check: true,
      dateView: null
    },
    {
      id: 8,
      property: "Сумма выручки по номенклатуре",
      type: "row",
      dataType: null,
      check: true,
      dateView: null
    },
  ]);


  const toggleCheck = (index) => {
    setParams((prevParams) => {
      const newParams = [...prevParams];
      const { check, ...rest } = newParams[index];
      newParams[index] = { ...rest, check: !check };
      return newParams;
    });
  };
  const [
    distributorStartDateReportsStart,
    setDistributorStartDateReportsStart,
  ] = useState(null);
  const [
    distributorFinishDateReportsStart,
    setDistributorFinishDateReportsStart,
  ] = useState(null);
  const [
    distributorStartDateReportsFinish,
    setDistributorStartDateReportsFinish,
  ] = useState(null);
  const [
    distributorFinishDateReportsFinish,
    setDistributorFinishDateReportsFinish,
  ] = useState(null);

  const downloadExcel = () => {
    apiConstructorExel(
      "excel",
      distributorStartDateReportsStart,
      distributorFinishDateReportsStart,
      distributorStartDateReportsFinish,
      distributorFinishDateReportsFinish,
      params.filter((index) => {
        return index.check === true;
      }),
    );
  };



  const onDragStart = (index) => {
    // Do something when drag starts
  };

  const onDragEnd = (sourceIndex, destinationIndex) => {
    const updatedParams = [...params];
    const [removed] = updatedParams.splice(sourceIndex, 1);
    updatedParams.splice(destinationIndex, 0, removed);
    setParams(updatedParams);
  };

  return (
    <Box
      sx={{
        borderRadius: "5px",
        width: "95%",
        flexGrow: 1,
        bgcolor: "background.paper",
        height: "96vh",
        boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)",
      }}
    >
      <Box style={{ padding: "1vw" }}>
        <Box className="buttonTool">
          <Box sx={{display: "flex"}}>
          <DatePickerTwoRange
              type={"right"}
              distributorStartDateReportsStart={distributorStartDateReportsFinish}
              setDistributorStartDateReportsStart={
                setDistributorStartDateReportsStart
              }
              distributorFinishDateReportsStart={
                distributorFinishDateReportsStart
              }
              setDistributorFinishDateReportsStart={
                setDistributorFinishDateReportsStart
              }
          />
          <DatePickerTwoRange
              type={"left"}
              distributorStartDateReportsStart={distributorStartDateReportsStart}
              setDistributorStartDateReportsStart={
                setDistributorStartDateReportsFinish
              }
              distributorFinishDateReportsStart={
                distributorFinishDateReportsFinish
              }
              setDistributorFinishDateReportsStart={
                setDistributorFinishDateReportsFinish
              }
          />
          </Box>
          <Box sx={{display: "flex"}}>
          <Button
              variant="contained"
              sx={{
                backgroundColor: "#1e6e41",
                color: "#ffffff",
                "&:hover": {
                  background: "#1c542d",
                },
              }}
              onClick={downloadExcel}
          >
            Скачать Excel
          </Button>
          <ConstructorPatternModal params={params} open={openSave} setOpen={setOpenSave}/>
          </Box>
          <ConstructorTemplates  setParams={setParams} open={openTemplates} setOpen={setOpenTemplates}/>

        </Box>
      </Box>
      <Divider />
      <Box sx={{ padding: "10px" }}>
        <Box sx={{ width: "100%", overflow: "auto" }}>
          <Box sx={{ width: "100%" }}>
          <TableContainer sx={{ height: "80vh" }}>

            <Table stickyHeader aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell>Наименование</TableCell>
                  <TableCell>Тип даты</TableCell>
                  <TableCell>Тип</TableCell>
                  <TableCell>Доп. значение</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {params.map((value, index) => (
                  <Row
                    key={value.id}
                    value={value}
                    index={index}
                    toggleCheck={toggleCheck}
                    setParams={setParams}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                  />
                ))}
              </TableBody>
            </Table>
            </TableContainer>

          </Box>
        </Box>
      </Box>
    </Box>
  );
}
