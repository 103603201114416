import * as React from "react";
import { useContext, useEffect, useState } from "react";
// import "./DistributorCardСounterparty.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GlobalContext from "../../../context/GlobalContext";
import "../DistributorRatingBox/DistributorRatingBox.css";

import { Box, Skeleton } from "@mui/material";
import { useAxiosAPI } from "../../../apiRequest/apiRequest";
import SearchSKU from "../../SearchSKU/SearchSKU";
import Search from "../../ui/Search/Search";
import RotatingLoadIcon from "../../RotatingLoadIcon/RotatingLoadIcon";
function filterNomenclatureTable(data, searchText) {
  return data.filter((item) => {
    const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
    const itemArticle = item.article.toLowerCase(); // Получаем поле "article" объекта
    return itemName.includes(searchText.toLowerCase()) || itemArticle.includes(searchText.toLowerCase());
  });
}
export default function DistributorTableNomenclature(props) {
  const { apiGetRetailOutletsAndDistrNoms } = useAxiosAPI();
  const [load, setLoad] = useState(false);
  const {
   
    distributorFinishDate,
 
  } = useContext(GlobalContext);
  // Массив документов
  const [nom, setNom] = useState([]);
  // Выбранный документ
  const [listOpen, setListOpen] = useState(true);
  const [searchComplete, setSearchComplete] = useState("")
  const [allSum ,setAllSum] = useState(0)
  // Добавляем состояние для хранения информации о нажатых кнопках

  useEffect(() => {
    setLoad(true);

    apiGetRetailOutletsAndDistrNoms(props.id, [], true)
      .then((resolve) => {
        setNom(resolve.data.nomenclatures);
        setAllSum(resolve.data.allSum)
        setListOpen(!listOpen);
        setLoad(false);

      })
      .catch(() => {
        console.log("Ошибка");
        setLoad(false);
      });
  }, [distributorFinishDate]);


  return (
    <Box sx={{ overflow: "auto", height: "25vw" }}>
      {!load?
          <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Search
                  searchComplete={searchComplete}
                  setSearchComplete={setSearchComplete}
              />
            </TableCell>
            <TableCell>Артикул</TableCell>
            <TableCell>Количество</TableCell>
            <TableCell>Цена (руб.)</TableCell>
            <TableCell>Сумма (руб.)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
                    {filterNomenclatureTable(nom, searchComplete).map((tag) => (
                        <TableRow key={tag.nomenclatureId}>
                            <TableCell>{tag.name}</TableCell>
                            <TableCell>{tag.article}</TableCell>
                            <TableCell>{tag.quantity} {tag.packing}</TableCell>
                            <TableCell>{Math.floor(tag.price)}</TableCell>
                            <TableCell>{Math.floor(tag.sum)}</TableCell>
                        </TableRow>
                    ))}


                </TableBody>
      </Table>
            <Box sx={{fontWeight: 700, padding: "5px", textAlign: "right", marginRight: "10px"}}>
              Итого: {Math.floor(allSum)} руб.
            </Box>
          </>
                :

            <RotatingLoadIcon/>
}

    </Box>
  );
}
