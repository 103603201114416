import React from 'react';
import style
    from "./OrderListFilterOptionPopup.module.css";

const DeliveryArea = ({areaData, setFilterOption, setChangeDeliveryArea}) => {
    return (
        <div
            className={style.settingDeliveryZoneNavZone}
            onClick={() => {
                setFilterOption({
                    deliveryAreaId: areaData?.id,
                    deliveryAreaName: areaData?.name_area
                })
                setChangeDeliveryArea(false)
            }}
        >
            <div className={style.settingDeliveryZoneNavZoneName}>{areaData?.name_area}</div>
        </div>
    );
};

export default DeliveryArea;