import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import style from './HeaderButtonGroup.module.css'
import {ReactComponent as MapLegendIcon} from '../../../../imges/MapLegendIcon.svg';
import {ReactComponent as MapControlIcon} from '../../../../imges/MapControlIcon.svg';
import {ReactComponent as MapRouteIcon} from '../../../../imges/MapRouteIcon.svg';
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import {observer} from "mobx-react-lite";
import GlobalContext from "../../../../context/GlobalContext";
import {useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {ReactComponent as UploadPhoneIcon} from '../../../../imges/UploadPhoneIcon.svg';
import {ReactComponent as QRIcon} from '../../../../imges/QRIcon.svg';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import { toastMassage } from '../../../ToastMassage/ToastMassage';
const HeaderButtonGroup = observer(() => {
    const axiosInstance = AxiosConfig_LogisticService();
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const elementRef = useRef(null);
    const stickyTop = 260; // Значение, при котором элемент становится фиксированным
    const [showHeaderBtn, setShowHeaderBtn] = useState(false);
    const {setting_document} = optimizationRouteSheetStore.pageState
    const navigate = useNavigate();
    const {uploadDate} = useParams();

    useEffect(() => {
        const handleScroll = () => {
            const rect = window.scrollY;
            const isSticky = rect >= stickyTop;

            if (isSticky) {
                setShowHeaderBtn(!showHeaderBtn)
            } else {
                setShowHeaderBtn(false)
            }
        };

        if (setting_document === false){
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            setShowHeaderBtn(false)
        }

    }, [stickyTop, setting_document]);
    const {routingSheets} = optimizationRouteSheetStore;


    const handlePrint = () => {
        if (!routingSheets) {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
            return
        }
        if(!routingSheets.some(obj => obj.checked && obj.in_route) || routingSheets.some(obj => obj.checked && !obj.in_route)) {
            toastMassage(toast.error, "Документы должны быть в маршруте, для того чтобы сформировать доступ.", 10000, "bottom-right")
            return
        }

        optimizationRouteSheetStore.changeCreateMobAuthData();
    }

    return (
        <div
            ref={elementRef}
            className={(showHeaderBtn) ? `${style.accessButtonGroupHeader} ${style.accessButtonGroupHeaderActive}` : style.accessButtonGroupHeader}>
            <div>
                <button
                    onClick={async () => await optimizationRouteSheetStore.createOptimizedRouteObjectForAPI(uploadDate, axiosInstance)}>
                    <MapControlIcon/>
                    Оптимизировать маршрут
                </button>
                <button
                    onClick={async () => await optimizationRouteSheetStore.createBuildingRouteObjectForAPI(uploadDate, axiosInstance)}>
                    <MapRouteIcon/>
                    Построить маршрут
                </button>
                <button
                    onClick={async () => {
                        await optimizationRouteSheetStore.createBuildingAndOptimizationRouteObjectForAPI(uploadDate, axiosInstance)
                    }}
                >
                    <MapLegendIcon/>
                    Оптимизировать и построить маршрут
                </button>
            </div>
            <div className={style.updateOrUploadBtns}>
           
            <button
                className={style.uploadDocForMobileApp}
                onClick={async () => {
                    await optimizationRouteSheetStore.unloadRouteSheetsInMobileApp(uploadDate, axiosInstance)
                }}
            >
                <UploadPhoneIcon/>
                Выгрузить в мобильное приложение
            </button>

            <button
                className={style.generateQr}
                onClick={handlePrint}
            >
                <QRIcon/>
                Печать доступа к приложению
            </button>
        </div>
        </div>
    );
});

export default memo(HeaderButtonGroup);