import React, {memo, useRef, useState} from 'react';
import style from './LogisticServiceResizableBox.module.css'

const LogisticServiceResizableBox = ({
                                         minWidth,
                                         minHeight,
                                         maxWidth,
                                         maxHeight,
                                         resizableDirections = ['both', 'right', 'bottom', 'bottom-right', 'left'],
                                         children,
                                         style: inlineStyle
                                     }) => {
    const boxRef = useRef(null);
    const resizerRef = useRef(null);
    const resizerRightRef = useRef(null);
    const resizerBottomRef = useRef(null);
    const resizerLeftRef = useRef(null);

    const [dimensions, setDimensions] = useState({width: minWidth, height: maxHeight});

    // Функция для преобразования значения в пиксели на основе размера родителя
    const convertToPixels = (value, parentSize) => {
        if (typeof value === 'string' && value.endsWith('%')) {
            return (parseFloat(value) / 100) * parentSize;
        }
        return value;
    };

    // Функция для начала изменения размера элемента
    const startResizing = (e) => {
        e.preventDefault();
        resizerRef.current.setPointerCapture(e.pointerId);
        document.body.style.cursor = 'se-resize';
        document.addEventListener('pointermove', resize);
        document.addEventListener('pointerup', stopResizing);
    };

    // Функция для начала изменения размера справа элемента
    const startResizingRight = (e) => {
        e.preventDefault();
        resizerRightRef.current.setPointerCapture(e.pointerId);
        document.body.style.cursor = 'ew-resize';
        document.addEventListener('pointermove', resizeRight);
        document.addEventListener('pointerup', stopResizingRight);
    };

    // Функция для начала изменения размера снизу элемента
    const startResizingBottom = (e) => {
        e.preventDefault();
        resizerBottomRef.current.setPointerCapture(e.pointerId);
        document.body.style.cursor = 'ns-resize';
        document.addEventListener('pointermove', resizeBottom);
        document.addEventListener('pointerup', stopResizingBottom);
    };

    // Функция для начала изменения размера слева элемента
    const startResizingLeft = (e) => {
        e.preventDefault();
        resizerLeftRef.current.setPointerCapture(e.pointerId);
        document.body.style.cursor = 'ew-resize';
        document.addEventListener('pointermove', resizeLeft);
        document.addEventListener('pointerup', stopResizingLeft);
    };

    // Функция для изменения размера слева элемента
    const resizeLeft = (e) => {
        const box = boxRef.current;
        const parent = box.parentElement;
        const parentWidth = parent.offsetWidth;
        if (box) {
            const newWidth = Math.min(Math.max(box.getBoundingClientRect().right - e.clientX, convertToPixels(minWidth, parentWidth)), convertToPixels(maxWidth, parentWidth));
            setDimensions((prevDimensions) => ({...prevDimensions, width: newWidth}));
        }
    };

    // Функция для остановки изменения размера слева элемента
    const stopResizingLeft = (e) => {
        // Сброс стиля курсора
        document.body.style.cursor = 'default';

        // Освобождение захвата указателя на ссылке левого изменяющего размер элемента
        resizerLeftRef.current.releasePointerCapture(e.pointerId);

        // Удаление слушателей событий для перемещения указателя и отпускания указателя
        document.removeEventListener('pointermove', resizeLeft);
        document.removeEventListener('pointerup', stopResizingLeft);
    };

    // Функция для изменения размера элемента
    const resize = (e) => {
        const box = boxRef.current;
        const parent = box.parentElement;
        const parentWidth = parent.offsetWidth;
        const parentHeight = parent.offsetHeight;
        if (box) {
            const newWidth = Math.min(Math.max(e.clientX - box.getBoundingClientRect().left, convertToPixels(minWidth, parentWidth)), convertToPixels(maxWidth, parentWidth));
            const newHeight = Math.min(Math.max(e.clientY - box.getBoundingClientRect().top, convertToPixels(minHeight, parentHeight)), convertToPixels(maxHeight, parentHeight));
            setDimensions({width: newWidth, height: newHeight});
        }
    };

    // Функция для изменения размера справа элемента
    const resizeRight = (e) => {
        const box = boxRef.current;
        const parent = box.parentElement;
        const parentWidth = parent.offsetWidth;
        if (box) {
            const newWidth = Math.min(Math.max(e.clientX - box.getBoundingClientRect().left, convertToPixels(minWidth, parentWidth)), convertToPixels(maxWidth, parentWidth));
            setDimensions((prevDimensions) => ({...prevDimensions, width: newWidth}));
        }
    };

    // Функция для изменения размера снизу элемента
    const resizeBottom = (e) => {
        const box = boxRef.current;
        const parent = box.parentElement;
        const parentHeight = parent.offsetHeight;
        if (box) {
            const newHeight = Math.min(Math.max(e.clientY - box.getBoundingClientRect().top, convertToPixels(minHeight, parentHeight)), convertToPixels(maxHeight, parentHeight));
            setDimensions((prevDimensions) => ({...prevDimensions, height: newHeight}));
        }
    };

    // Функция для остановки изменения размера элемента
    const stopResizing = (e) => {
        // Сброс стиля курсора
        document.body.style.cursor = 'default';

        // Освобождение захвата указателя на ссылке изменяющего размер элемента
        resizerRef.current.releasePointerCapture(e.pointerId);

        // Удаление слушателей событий для перемещения указателя и отпускания указателя
        document.removeEventListener('pointermove', resize);
        document.removeEventListener('pointerup', stopResizing);
    };

    // Функция для остановки изменения размера справа элемента
    const stopResizingRight = (e) => {
        // Сброс стиля курсора
        document.body.style.cursor = 'default';

        // Освобождение захвата указателя на ссылке правого изменяющего размер элемента
        resizerRightRef.current.releasePointerCapture(e.pointerId);

        // Удаление слушателей событий для перемещения указателя и отпускания указателя
        document.removeEventListener('pointermove', resizeRight);
        document.removeEventListener('pointerup', stopResizingRight);
    };

    // Функция для остановки изменения размера снизу элемента
    const stopResizingBottom = (e) => {
        // Сброс стиля курсора
        document.body.style.cursor = 'default';

        // Освобождение захвата указателя на ссылке нижнего изменяющего размер элемента
        resizerBottomRef.current.releasePointerCapture(e.pointerId);

        // Удаление слушателей событий для перемещения указателя и отпускания указателя
        document.removeEventListener('pointermove', resizeBottom);
        document.removeEventListener('pointerup', stopResizingBottom);
    };

    return (<section
        ref={boxRef}
        className={style.resizableBox}
        style={{...inlineStyle, width: `${dimensions.width}px`, height: `${dimensions.height}px`}}
    >
        {children}
        {resizableDirections.includes('both') || resizableDirections.includes('bottom-right') ? (<div
            ref={resizerRef}
            className={style.resizer}
            onPointerDown={startResizing}
        />) : null}
        {resizableDirections.includes('both') || resizableDirections.includes('right') ? (<div
            ref={resizerRightRef}
            className={style.resizerRight}
            onPointerDown={startResizingRight}
        />) : null}
        {resizableDirections.includes('both') || resizableDirections.includes('bottom') ? (<div
            ref={resizerBottomRef}
            className={style.resizerBottom}
            onPointerDown={startResizingBottom}
        />) : null}
        {resizableDirections.includes('both') || resizableDirections.includes('left') ? (
            <div
                ref={resizerLeftRef}
                className={style.resizerLeft}
                onPointerDown={startResizingLeft}
            />) : null}
    </section>);
};

export default memo(LogisticServiceResizableBox);