import React, {memo, useEffect, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';

import style from './ModalMaster.module.css'

const ModalMaster = ({ isOpen, onClose, children, scrollPosition, styles }) => {
    const rootElementRef = useRef(document.getElementById('root'));

    const disableScroll = (() => {
        rootElementRef.current.classList.add(style.noScroll);
        rootElementRef.current.style.top = `-${scrollPosition}px`;
    });

    const enableScroll = (() => {
        rootElementRef.current.classList.remove(style.noScroll);
        rootElementRef.current.style.top = '';
        window.scrollTo(0, scrollPosition); // Восстанавливаем позицию скролла
    });

    useMemo(() => {
        if (isOpen) {
            disableScroll();
        } else {
            enableScroll();
        }
    }, [isOpen]);

    const popupContent = isOpen ? (
        <div className={style.popupOverlay} onClick={onClose}>
            <div className={style.popupContent} onClick={(e) => e.stopPropagation()} style={styles}>
                {children}
            </div>
        </div>
    ) : null;

    return ReactDOM.createPortal(popupContent, document.getElementById('root'));
};

export default memo(ModalMaster);