import React, {useContext, useEffect} from 'react';
import style from './DeliveryVehiclesList.module.css'
import DeliveryVehiclesActiveBlock from "../DeliveryVehiclesActiveBlock/DeliveryVehiclesActiveBlock";
import DeliveryVehiclesTableList from "../DeliveryVehiclesTableList/DeliveryVehiclesTableList";
import PageDeliveryVehiclesStore from '../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore'
import GlobalContext from "../../../../context/GlobalContext";
const DeliveryVehiclesList = () => {
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);

    useEffect(() => {

        async function getAllVehicles(){
            await PageDeliveryVehiclesStore.getAllVehicle(authTokens, setUser, setAuthTokens, logoutUser);
        }

        getAllVehicles();

        return () => {

        }
    }, [])


    return (
        <>
            <DeliveryVehiclesActiveBlock/>
            <DeliveryVehiclesTableList/>
        </>
    );
};

export default DeliveryVehiclesList;