import React, {memo} from 'react';
import style from './DeliveryAreaEditForm.module.css'
import EditDeliveryAreaStore from "../../../../store/transportationLogisticsService/PageDeliveryZone/editDeliveryArea";
import {observer} from "mobx-react-lite";

const DeliveryAreaEditForm = observer(() => {
    return (
        <div className={style.createForm}>
            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                <span>Наименование зоны:</span>
                <input type="text" required={true} value={EditDeliveryAreaStore.editAreaData?.name_area || ""}
                       placeholder="Наименование зоны..."
                       onChange={(e) => EditDeliveryAreaStore.updateNameArea(e.target.value)}/>
            </div>

            <div className={style.settingDeliveryZoneNavCreateColorZone}>
                <span>Цвет зоны:</span>
                <input
                    type="color" required={true} value={EditDeliveryAreaStore.editAreaData?.color_area || "#6ea5e8"}
                    onChange={(e) => EditDeliveryAreaStore.updateColorArea(e.target.value)}/>
            </div>
        </div>
    );
});

export default memo(DeliveryAreaEditForm);