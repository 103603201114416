import jwt_decode from "jwt-decode";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { reducers } from "../apiRequest/reducers";

// Вспомогательная функция для парсинга токенов
const parseTokens = (storedTokens) => {
  try {
    return JSON.parse(storedTokens);
  } catch (error) {
    console.error("Ошибка при разборе authTokens:", error);
    localStorage.removeItem("authTokens");
    return null;
  }
};

// Вспомогательная функция для декодирования пользователя
const decodeUser = (tokens) => {
  try {
    return jwt_decode(tokens.access);
  } catch (error) {
    console.error("Ошибка при декодировании пользователя:", error);
    localStorage.removeItem("authTokens");
    return null;
  }
};

function apiReducer(state, action) {
  if (!reducers[action.type]) {
    throw new Error(`Необработанный тип действия: ${action.type}`);
  }
  return {
    ...state,
    ...reducers[action.type](state, action),
  };
}

const GlobalContext = createContext();
export default GlobalContext;

export const GlobalProvider = ({ children }) => {
  const initialState = {
    subscriptions: null,
    manufacturer: null,
    distributors: null,
    counteragentsAndRetailOutlets: [],
    counterparties: null,
    retailOutlets: null,
    docsSum: [],
    sku: [],
    registerDates: null,
    currentRemains: [],
    historyRemains: [],
    distributorUsers: [],
    manufacturerNomenclature: [],
    totalSells: [],
    profileDistributors: {},
    profileManufacturersList: [],
    distibutorNomenclature: [],
    profileDistibutorRetail: [],
    profileDistibutorRegisterDate: [],
    distributorDocsSum: [],
    distributorCounteragentsAndRetailOutlets: [],
    manufacturerSKU: [],
    manufacturerCurrentRemains: [],
    manufacturerHistoryRemains: []
  };

  const [state, dispatch] = useReducer(apiReducer, initialState);

  const [authTokens, setAuthTokens] = useState(() => {
    const storedTokens = localStorage.getItem("authTokens");
    return storedTokens ? parseTokens(storedTokens) : null;
  });

  // console.log(authTokens)
  const [user, setUser] = useState(() => {
    const storedTokens = localStorage.getItem("authTokens");
    return storedTokens ? decodeUser(parseTokens(storedTokens)) : null;
  });

  const [loading, setLoading] = useState(true);

  const loginUser = async (userName, password) => {
    try {
      const response = await fetch("http://176.99.11.148:5000/api/MVD/AuthToken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mail: userName, password: password }),
      });

      const data = await response.json();

      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
      } else {
        console.error("Токены отсутствуют в ответе сервера:", data);
        // Обработайте ошибку, например, покажите дружелюбное сообщение
      }
    } catch (error) {
      console.error("Ошибка при входе:", error);
      // Обработайте ошибку, например, покажите дружелюбное сообщение
    }
  };

  function apiReducer(state, action) {
    switch (action.type) {
      case "RESET_STATE":
        return initialState;
      default:
        if (!reducers[action.type]) {
          throw new Error(`Необработанный тип действия: ${action.type}`);
        }
        return {
          ...state,
          ...reducers[action.type](state, action),
        };
    }
  }

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.clear();
    dispatch({ type: "RESET_STATE" });
  };
  

  const [distributorStartDate, setDistributorStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [distributorFinishDate, setDistributorFinishDate] = useState(new Date());
  const [searchName, setSearchName] = useState("");
  const [sharePercentageRF, setSharePercentageRF] = useState({});
  const [age, setAge] = useState(20);
  const [marker, setMarker] = useState([]);
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [region, setRegion] = useState([]);

  const contextData = {
    state, 
    dispatch,
    region, setRegion,
    excelData, setExcelData,
    open, setOpen,
    age, setAge,
    marker, setMarker,
    sharePercentageRF, setSharePercentageRF,
    searchName, setSearchName,
    distributorStartDate, setDistributorStartDate,
    distributorFinishDate, setDistributorFinishDate,
    user, setUser,
    authTokens, setAuthTokens,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens]);

  return (
    <GlobalContext.Provider value={contextData}>
      {loading ? null : children}
    </GlobalContext.Provider>
  );
};
