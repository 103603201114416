import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const routeParamHandlerHoc = (WrappedComponent, nameChange, typeAction) => {
    return observer(({paramRowData}) => {
            const {totalVehicleTonnage} = createRouteSheetsStore.areaSelectOrdersTotalData;
            const {selectOrderData, typeCreatingRouteSheet, selectOrderListData} = createRouteSheetsStore;
            const [vehicleTonnage, setVehicleTonnage] = useState(0);


            useEffect(() => {
                if (nameChange === "changeVehicle" && typeAction === "create") {
                    switch (typeCreatingRouteSheet) {
                        case "deliveryArea":
                            setVehicleTonnage(totalVehicleTonnage);
                            break
                        case "selectPoint":
                            setVehicleTonnage(selectOrderData?.vehicle_tonnage);
                            break
                        case "selectedOrderList":
                            setVehicleTonnage(getTotalValueToSelectedOrders(selectOrderListData));
                            break
                        default:
                            setVehicleTonnage(0)
                    }
                } else if (nameChange === "changeVehicle" && typeAction === "edit") {
                    setVehicleTonnage(getTotalTonnageEditRoute())
                }
            }, [])

            const getTotalTonnageEditRoute = () => {
                const {order_list} = createRouteSheetsStore.editRouteSheetsData
                let totalTonnage = 0
                for (const order of order_list) {
                    totalTonnage += order?.vehicle_tonnage
                }
                return totalTonnage
            }

            const getTotalValueToSelectedOrders = (selectOrderListData) => {
                let totalTonnage = 0
                if (selectOrderListData.length === 0) {
                    return totalTonnage
                } else {
                    for (const order of selectOrderListData) {
                        totalTonnage += order?.vehicle_tonnage;
                    }
                    return totalTonnage
                }
            }

            const changeParam = (paramData) => {
                switch (nameChange) {
                    case "changeInitPoint":
                        createRouteSheetsStore.changeInitPointCreatingRouteSheet(paramData, typeAction);
                        break;
                    case "changeDriver":
                        createRouteSheetsStore.changeDriverCreatingRouteSheet(paramData, typeAction)
                        break
                    case "changeVehicle":
                        createRouteSheetsStore.changeVehicleCreatingRouteSheet(paramData, typeAction);
                        break;
                    default:
                        break
                }
            }

            return (<WrappedComponent
                paramRowData={paramRowData}
                onClick={(paramData) => changeParam(paramData)}
                {...(nameChange === "changeVehicle" && {vehicleTonnage})}
            />)
        }
    );
};

export default routeParamHandlerHoc;