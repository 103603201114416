import React, {memo, useEffect} from 'react';
import style from './ModalSelectedDriverItem.module.css';
import {Tooltip} from 'react-tooltip';
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";


const ModalSelectedDriverItem = observer(({paramRowData, onClick}) => {

    const changeInitPoint = () => {
        onClick(paramRowData)
    }

    return (
        <div
            onClick={() => changeInitPoint()}
            className={`${style.addressCoordsItem}
            ${(paramRowData?.usedInRouteSheet)? style.used : ""}`}
        >
            <div>
                <div data-tooltip-id={`client-info_${paramRowData.id}`}
                     data-tooltip-variant="info">
                    {(paramRowData?.driver_name.length > 50) ? paramRowData?.driver_name.slice(0, 50) + "..." : paramRowData?.driver_name}
                    {(paramRowData?.driver_name.length > 50) ? <Tooltip
                        id={`client-info_${paramRowData.id}`}
                        content={paramRowData?.driver_name}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
            </div>
        </div>
    );
})

export default memo(ModalSelectedDriverItem);