import React from 'react';
import style from './MainMenuLogisticsService.module.css';

const MainMenuLogisticsService = ({namePage}) => {
    return (
        <div className={style.headerOptimizationPage}>
            <div className={style.activeNamePage}>{namePage}</div>
        </div>
    );
};

export default MainMenuLogisticsService;