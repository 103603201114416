import DeleteIcon from "@mui/icons-material/Delete";
import {Avatar, Box, Button, Card, CardHeader, IconButton, Switch, TextField, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useAxiosAPI} from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import DelModal from "../DelModal/DelModal";
import Loader from "../Loader/Loader";
import ProfileRegistrationModalUserDistributor
    from "../ProfileRegistrationModalUserDistributor/ProfileRegistrationModalUserDistributor";
import SearchDistributorList from "../SearchDistributorList/SearchDistributorList";
import Search from "../ui/Search/Search";
import ProfileRegistrationModalUserManufacturer
    from "../ProfileRegistrationModalUserManufacturer/ProfileRegistrationModalUserManufacturer";
import {dateConversion, generateLocalColorFromText} from "../../helpers/helpers";
import CardContent from "@mui/material/CardContent";
import ProfilePatchRegionUser from "../ProfilePatchRegionUser/ProfilePatchRegionUser";


function UserCard({
                      setActiveString,
                      activeString,
                      setManufacturerUser,
                      setSearchResults,
                  }) {
    const [open, setOpen] = React.useState(false);
    const [regions, setRegions] = useState(activeString.regions);
    const [access, setAccess] = useState(activeString.access);


    const {apiDelete, apiManufacturerUsers, apiPatchManufacturerList} =
        useAxiosAPI();

    const handleClickTrue = () => {
        apiDelete(activeString.id)
            .then((resolve) => {
                apiManufacturerUsers()
                    .then((resolve) => {
                        setManufacturerUser(resolve.data);
                        setSearchResults(resolve.data);
                        setActiveString(null);
                        // props.setLoader(false);
                    })
                    .catch(() => {
                        setManufacturerUser([]);
                        setSearchResults([]);
                        // props.setLoader(false);
                    });
            });

        // props.setOpen(false);
    };

    const patchUser = () => {
        apiPatchManufacturerList(
            {
                access: access,
                regions: regions,
            },
            activeString.id
        )
            .then(() => {
                apiManufacturerUsers()
                    .then((resolve) => {
                        setManufacturerUser(resolve.data);
                        setSearchResults(resolve.data);
                        // props.setLoader(false);
                        setActiveString(null);
                    })
                    .catch(() => {
                        setManufacturerUser([]);
                        setSearchResults([]);
                        // props.setLoader(false);
                    });
                // props.setOpenRegionPatchUser(false);
            })
            .catch(() => {
                alert("Ошибка");
            });
    };
    return (
        <Card sx={{width: "100%", boxShadow: 0, height: "80vh"}}>
            <CardHeader
                title={
                    <Box sx={{display: "flex"}}>
                        <Box sx={{display: "flex", width: "50%"}}>
                            <Box>
                                <Avatar
                                    sx={{
                                        backgroundImage:
                                            "linear-gradient(98deg, #ff9900, #ffa500 94%)",
                                        width: "5vw",
                                        height: "5vw",
                                        fontSize: "2vw",
                                    }}
                                    variant="square"
                                >
                                    {activeString.name[0]}
                                </Avatar>
                            </Box>

                            <Box sx={{marginLeft: "1vw"}}>
                                <Typography variant="h5">{activeString.name}</Typography>
                                <Typography>Пользователь</Typography>
                            </Box>
                        </Box>

                    </Box>
                }
                action={
                    <Box>
                        <Box style={{display: "flex"}}>
                            <Typography>
                                Доступ:
                                <Switch
                                    checked={access}
                                    onChange={() => setAccess(!access)}
                                    inputProps={{"aria-label": "controlled"}}
                                />
                            </Typography>
                        </Box>

                        <Box style={{display: "flex"}}>
                            <Typography>
                                Удалить:
                                <IconButton onClick={() => setOpen(true)} aria-label="delete">
                                    <DeleteIcon color="error"/>
                                </IconButton>
                            </Typography>

                        </Box>
                    </Box>
                }
            />

            <CardContent>
                <Box>
                    <DelModal
                        open={open}
                        setOpen={setOpen}
                        handleClickTrue={handleClickTrue}
                    />
                    {/* Нижний блок */}
                    <Box sx={{display: "flex", width: "100%"}}>

                        <Box sx={{width: "50%"}}>
                            <Box sx={{display: "flex"}}>
                                <Box className="leftString">
                                    <Typography>ФИО</Typography>
                                    <TextField
                                        sx={{width: "90%"}}
                                        disabled
                                        id="outlined-disabled"
                                        // label="Disabled"
                                        defaultValue={activeString.name}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{marginTop: "10px", display: "flex"}}>
                                <Box className="leftString">
                                    <Typography>Дата создания</Typography>
                                    <TextField
                                        sx={{width: "90%"}}
                                        disabled
                                        id="outlined-disabled"
                                        // label="Disabled"
                                        defaultValue={dateConversion(activeString.userCreationDate)}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{marginTop: "10px", display: "flex"}}>
                                <Box className="leftString">
                                    <Typography>Должность</Typography>
                                    <TextField
                                        sx={{width: "90%"}}
                                        disabled
                                        id="outlined-disabled"
                                        // label="Disabled"
                                        defaultValue={activeString.position}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{marginTop: "10px", display: "flex"}}>

                                <Button
                                    disabled={
                                        regions === activeString.regions && access === activeString.access
                                    }
                                    onClick={patchUser}
                                    sx={{marginRight: "1vw", marginTop: "10px"}}
                                    variant="contained"
                                    // disabled={selectedValues.length < 1}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    onClick={() => setActiveString(null)}
                                    sx={{marginRight: "1vw", marginTop: "10px"}}
                                    variant="contained"
                                    // disabled={selectedValues.length < 1}
                                >
                                    Отмена
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{width: "50%"}}>
                            <Box>
                                <ProfilePatchRegionUser
                                    setActiveString={setActiveString}
                                    activeString={activeString}
                                    setRegions={setRegions}
                                    regions={regions}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}
function filterUser(data, searchText) {
    return data.filter((item) => {
        const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
        const itemPosition = item.position.toLowerCase(); // Получаем поле "article" объекта
        const itemMail = item.mail.toLowerCase(); // Получаем поле "article" объекта

        return itemName.includes(searchText.toLowerCase()) || itemPosition.includes(searchText.toLowerCase()) || itemMail.includes(searchText.toLowerCase())
            ;
    });
}

export default function ProfileDistributorUserList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [manufacturerUser, setManufacturerUser] = useState([]);
    const {apiManufacturerUsers, apiPatchManufacturerList} = useAxiosAPI();
    const [loader, setLoader] = useState(true); // Добавляем состояние loader
    const {state} = useContext(GlobalContext);
    const [rows, setSearchResults] = useState(manufacturerUser);
    const [activeString, setActiveString] = useState(null);
    const [searchComplete, setSearchComplete] = useState("")

    useEffect(() => {
        setLoader(true); // Установите loader в true перед началом запроса
        apiManufacturerUsers()
            .then((resolve) => {
                setManufacturerUser(resolve.data);
                setSearchResults(resolve.data);
                setLoader(false);
            })
            .catch(() => {
                setManufacturerUser([]);
                setSearchResults([]);
                setLoader(false);
            });
    }, [apiManufacturerUsers, state.profileDistributors]);

    const [selectedRow, setSelectedRow] = useState(null);
    const [openModalRegister, setOpenModalRegister] = useState(false);

    useEffect(() => {
        if (selectedRow) {
            const updatedSelectedRow = rows.find((row) => row.id === selectedRow.id);
            setSelectedRow(updatedSelectedRow || null);
        }
    }, [rows]);

    const handleRowClickRegisterModal = (row) => {
        setOpenModalRegister(true);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    return (
        <Box>
            {!activeString ? (
                    <>

                    <Loader open={loader}/>
                    <TableContainer sx={{padding: 0, maxHeight: "calc(84vh - 16px)"}}>
                        <Table
                            sx={{borderRadius: 0, boxShadow: 0, width: "100%"}}
                            stickyHeader
                            aria-label="sticky table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" colSpan={7}>
                                        <Box sx={{display: "flex"}}>
                                            <Search
                                                searchComplete={searchComplete}
                                                setSearchComplete={setSearchComplete}
                                            />
                                            <Button
                                                onClick={() => handleRowClickRegisterModal()}
                                                sx={{marginLeft: "2vw"}}
                                                variant="contained"
                                            >
                                                Добавить пользователя
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>

                                    <TableCell sx={{fontWeight: 700}} colSpan={2}>ФИО</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Должность</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Почта</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Дата создания</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Регионы</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Доступ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <ProfileRegistrationModalUserManufacturer
                                    openModalRegister={openModalRegister}
                                    setOpenModalRegister={setOpenModalRegister}
                                    setLoader={setLoader}
                                    setSearchResults={setSearchResults}
                                    setManufacturerUser={setManufacturerUser}
                                />
                                {filterUser(rows, searchComplete).map((user) => {
                                    return (
                                        <TableRow
                                            className="hoverable-row"
                                            onClick={() => setActiveString(user)}
                                            key={user.id}
                                        >
                                            <TableCell>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: generateLocalColorFromText(
                                                            user.name
                                                        ),
                                                    }}
                                                    alt={user.name}
                                                    src="/broken-image.jpg"
                                                />
                                            </TableCell>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.position}</TableCell>
                                            <TableCell>{user.mail}</TableCell>
                                            <TableCell>{user.userCreationDate}</TableCell>
                                            <TableCell>{user.regions.length}</TableCell>

                                            <TableCell>{user.access ? "да" : "нет"}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </>
                ) : (
                <UserCard
                setActiveString={setActiveString}
            activeString={activeString}
            setManufacturerUser={setManufacturerUser}
            setSearchResults={setSearchResults}
        />
    )}
</Box>
    );
}
