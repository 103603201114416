import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  memo,
} from "react";
import style from "./TemplateList.module.css";
import TemplateListItem from "../TemplateListItem/TemplateListItem";
import { ReactComponent as DeleteIcon } from "../../../../imges/DeleteIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import deliveredStatusList from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import { ReactComponent as ImgNoneData } from "../../../../imges/noneData.svg";
import GlobalContext from "../../../../context/GlobalContext";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as ArrowUp } from "../../../../imges/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../../imges/ArrowDown.svg";
import TablePaginationRouteSheets from "../../TablePaginationRouteSheets/TablePaginationRouteSheets";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import settingOptimizationRouteSheetStore from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import optimizationRouteSheetStore from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";

const TemplateList = observer(() => {
  const { authTokens, setUser, setAuthTokens, logoutUser } =
    useContext(GlobalContext);
  const {
    copyTemplateList,
    templateList,
    paginationState: { page },
    tableTemplateListState: { allChecked },
    popupState: { popupShow, popupType, popupScrollPosition },
  } = deliveredStatusList;
  const navigate = useNavigate();
  const { typeStatus } = useParams();

  const [sortOrder, setSortOrder] = useState({
    templateText: "asc",
    typeStatus: "asc",
  });

  useEffect(() => {
    async function getTemplate() {
      const response = await deliveredStatusList.getTemplateByTypeStatus(
        typeStatus,
        authTokens,
        setUser,
        setAuthTokens,
        logoutUser
      );
    }

    getTemplate();

    return () => {
      deliveredStatusList.changeDefaultValueTempList();
    };
  }, [typeStatus]);

  const handleChangePage = (newPage) => {
    deliveredStatusList.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    deliveredStatusList.handleChangeRowsPerPage(rowsPerPage);
  };

  const changeSelectedAllTemp = (value) => {
    deliveredStatusList.changeSelectedAllTemplate(value);
  };

  const deleteSelectedTemp = async () => {
    try {
      await deliveredStatusList.deleteTemplates(
        authTokens,
        setUser,
        setAuthTokens,
        logoutUser
      );
      deliveredStatusList.closePopup();
      await deliveredStatusList.getTemplateByTypeStatus(
        typeStatus,
        authTokens,
        setUser,
        setAuthTokens,
        logoutUser
      );
    } catch (e) {}
  };

  const sortTemplates = (field) => {
    // Определяем новый порядок сортировки (asc, desc или none)
    const newOrder = sortOrder[field] === "asc" ? "desc" 
                   : sortOrder[field] === "desc" ? "none" 
                   : "asc";
    
    // Обновляем состояние сортировки с новым порядком для данного поля
    setSortOrder((prevOrder) => ({
        ...prevOrder,
        [field]: newOrder,
    }));

    // Проверяем, требуется ли вообще сортировка
    if (newOrder === "none") {
        // Если сортировка отсутствует, возвращаем список к исходному состоянию
        deliveredStatusList.copyTemplateList = deliveredStatusList.copyTemplateList.slice();
    } else {
        // Если сортировка задана, выполняем сортировку
        deliveredStatusList.copyTemplateList = deliveredStatusList.copyTemplateList
            .slice()
            .sort((a, b) => {
                if (field === "templateText") {
                    if (a.templateText < b.templateText)
                        return newOrder === "asc" ? -1 : 1;
                    if (a.templateText > b.templateText)
                        return newOrder === "asc" ? 1 : -1;
                    return 0;
                } else if (field === "typeStatus") {
                    if (a.typeStatus?.status_text < b.typeStatus?.status_text)
                        return newOrder === "asc" ? -1 : 1;
                    if (a.typeStatus?.status_text > b.typeStatus?.status_text)
                        return newOrder === "asc" ? 1 : -1;
                    return 0;
                }
                return 0;
            });
    }
};



  return (
    <div className={style.templateList}>
      <div className={style.templateListHeader}>Шаблоны ответов</div>

      <div className={style.pagination}>
        <TablePaginationRouteSheets
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={templateList.length}
          paginationText={"Шаблонов на странице:"}
        />
      </div>

      <div className={style.templateListTableHeader}>
        <div>
          <input
            type="checkbox"
            className={style.selectAllTemplate}
            id="selectAllTemplate"
            name="selectAllTemplate"
            checked={allChecked}
            value={allChecked}
            onChange={(event) => {
              event.stopPropagation();
              changeSelectedAllTemp(!allChecked);
            }}
          />
          <label htmlFor="selectAllTemplate"></label>
        </div>
        <div
          className={style.documentDelivery}
          onClick={() => sortTemplates("templateText")}
        >
          Наименование шаблона
          <div>
                <span className = {sortOrder.templateText === "asc" ? style.activeArrow : ""}
                 >
                  <ArrowUp />
                </span>
                <span className={sortOrder.templateText === "desc" ? style.activeArrow : ""}>
                  <ArrowDown />
                </span>
          </div>
        </div>
        <div           className={style.documentDelivery}
 onClick={() => sortTemplates("typeStatus")}>
          Статус в МП
          <div>
                <span className = {sortOrder.typeStatus === "asc" ? style.activeArrow : ""}
                 >
                  <ArrowUp />
                </span>
                <span className={sortOrder.typeStatus === "desc" ? style.activeArrow : ""}>
                  <ArrowDown />
                </span>
          </div>
          {/* <span className={style.iconAsc}>
            {sortOrder.typeStatus === "asc" ? <ArrowUp /> : <ArrowDown />}
          </span> */}
        </div>
        <div>Удалить</div>
        <div>Редактирование</div>
      </div>

      {copyTemplateList.length > 0 ? (
        copyTemplateList.map((temp) => {
          return <TemplateListItem key={uuidv4()} tempData={temp} />;
        })
      ) : (
        <div className={style.noDataImg}>
          <ImgNoneData />
          <div>Нет созданных шаблонов</div>
        </div>
      )}

      <PopupRouting
        isOpen={popupShow && popupType === "askDeleteSelectedTemp"}
        onClose={() => deliveredStatusList.closePopup()}
        scrollPosition={popupScrollPosition}
      >
        <div className={style.popupHeader}>
          Уточнение
          <div
            className={style.btnClosePopup}
            onClick={() => {
              deliveredStatusList.closePopup();
            }}
          >
            <DeleteIcon />
          </div>
        </div>
        <div className={style.askText}>
          Вы точно хотите удалить все выделенные шаблоны, действие будет
          невозможно отменить?
        </div>
        <div className={style.actionAskBtns}>
          <button
            className={style.acceptAskBtn}
            onClick={() => deleteSelectedTemp()}
          >
            Подтвердить
          </button>
          <button
            className={style.cancelAskBtn}
            onClick={() => deliveredStatusList.closePopup()}
          >
            Отменить
          </button>
        </div>
      </PopupRouting>
    </div>
  );
});

export default memo(TemplateList);
