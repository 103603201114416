import React, {memo, useContext, useEffect, useState} from 'react';
import style from './SelectUploadDataDocuments.module.css';
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import DateSelectionField from "../../../../inputs/logisticService/DatePicker/DatePicker";
import {observer} from "mobx-react-lite";
import GlobalContext from "../../../../context/GlobalContext";
import {useNavigate, useParams} from "react-router-dom";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";

const SelectUploadDataDocuments = observer(() => {
    const navigate = useNavigate();
    const {uploadDate} = useParams();
    const axiosInstance = AxiosConfig_LogisticService();


    useEffect(() => {
        async function getRouteSheets() {
            await optimizationRouteSheetStore.upLoadDocumentRouteSheet(uploadDate, axiosInstance)
        }

        if (uploadDate === undefined){
            navigate(`/logisticService/routingByRouteList/routeSheetsList/${optimizationRouteSheetStore.getISONowDate().split('T')[0]}`)
        } else {
            getRouteSheets()
        }

    }, [uploadDate]);

    const uploadDocumentForDate = async(newData) =>{
            optimizationRouteSheetStore.changeUpLoadDocumentDate(navigate,newData);
    }

    return (
        <div className={style.selectDataDocumentBlock}>
                <span>
                    <div>Маршрутные листы за:</div>
                    <DateSelectionField
                        value={(uploadDate === undefined)? optimizationRouteSheetStore.getISONowDate().split('T')[0]: uploadDate}
                        onChange={async (newData) => {
                            await uploadDocumentForDate(newData);
                        }}
                    />
                </span>
        </div>
    );
});

export default memo(SelectUploadDataDocuments);