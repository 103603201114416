import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
    Badge,
    Box,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useState } from 'react';
import { useAxiosAPI } from '../../../apiRequest/apiRequest';
import GlobalContext from '../../../context/GlobalContext';
import { formatAndPrintDate } from '../../../helpers/helpers';
import DistributorCurrentHistory from '../DistributorCurrentHistory/DistributorCurrentHistory';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '80',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    borderRadius: '5px',
    bgcolor: '#ffffff',
    border: '1px solid #ccc',
    p: 2,
};


function filterDataByMonthAndYear(data, startMonth, endMonth, year, onlyHistoryCheck) {
    return data.filter((item) => {
        const reportingDate = new Date(item.reportingDay);
        const reportingMonth = reportingDate.getMonth() + 1;
        const reportingYear = reportingDate.getFullYear();
        if (onlyHistoryCheck) {
            return (
                reportingYear === year &&
                reportingMonth >= startMonth &&
                reportingMonth <= endMonth && parseInt(item.historyCount) > 0)
        } else {
            return (
                reportingYear === year &&
                reportingMonth >= startMonth &&
                reportingMonth <= endMonth
            )
        }
    });
}

const ChildModal = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button onClick={handleOpen}>{props.data[0].docNum}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 600}}>
                    <Typography gutterBottom variant="h5" component="div">
                        Документы
                    </Typography>
                    <Divider/>
                    <List component="nav" aria-label="mailbox folders">
                        {props.data.map((doc) => (
                            <>
                                <ListItem>
                                    <ListItemText primary={doc.docName}/>
                                </ListItem>
                                <Divider/>
                            </>
                        ))}
                    </List>
                </Box>
            </Modal>
        </React.Fragment>
    );
}


function Row({row}) {
    const {state} = useContext(GlobalContext);
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow  className={row.historyCount > 0 ? "hoverable-row" : null} sx={{cursor: row.historyCount > 0 ? "pointer" : "auto"}}  onClick={row.historyCount > 0 ? () => setOpen(!open) : null}>
                <TableCell>{formatAndPrintDate(row.reportingDay)}</TableCell>
                <TableCell>{row.productSales}</TableCell>
                <TableCell>{row.productPurchases}</TableCell>
                <TableCell>{row.productCustomerReturns}</TableCell>
                <TableCell>{row.productSellerReturns}</TableCell>
                <TableCell>{row.remainsDayStart}</TableCell>
                <TableCell>{row.remainsDayEnd}</TableCell>
                {row.other.length > 0 ?
                    <TableCell>
                        <ChildModal data={row.other}/>
                    </TableCell>
                    : <TableCell>Отсутствует</TableCell>}
                    <TableCell>
                    {row.historyCount > 0 ? (
                        <Typography
                            sx={{fontSize: '1vw', marginLeft: 1}}
                            gutterBottom
                        >
                            <IconButton>

                                <Badge sx={{cursor: "pointer"}} onClick={() => setOpen(!open)}
                                       color={"error"} badgeContent={row.historyCount}>
                                </Badge>
                            </IconButton>
                        </Typography>
                    ) : 0}
                </TableCell>
            </TableRow>
            <DistributorCurrentHistory history={row.historyCount > 0 ? state.historyRemains[row.id] : []} current={row}
                                       open={open}/>
        </React.Fragment>
    );
}

export default function DistributorCurrentRemainsDataList(props) {
    const handleOpen = () => props.setOpenModal(true);
    const {apiDistributorHistoryRemains} = useAxiosAPI();
    const {state} = useContext(GlobalContext);
    const handleClose = () => props.setOpenModal(false);
    const [ageStart, setAgeStart] = useState(1);
    const [ageFinish, setAgeFinish] = useState(12);
    const [onlyHistoryCheck, setOnlyHistoryCheck] = useState(false)
    const [year, setYear] = useState(
        Number(props.currentAll[0].reportingDay.substring(0, 4))
    );

    return (
        <React.Fragment>
         
                        <Table stickyHeader aria-label="collapsible table">
                            <TableHead className={'tableHeadModal'}>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 700}}>Дата</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Продажи</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Поставки</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Возвраты поставщику</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Возвраты покупателей</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Остаток начало дня</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Остаток конец дня</TableCell>
                                    <TableCell sx={{fontWeight: 700}}>Прочее</TableCell>
                                    <TableCell sx={{fontWeight: 700}}><IconButton onClick={() => setOnlyHistoryCheck(!onlyHistoryCheck)}><NotificationsActiveIcon color={onlyHistoryCheck ? 'error' : ""}/></IconButton></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterDataByMonthAndYear(
                                    props.currentAll,
                                    ageStart,
                                    ageFinish,
                                    year,
                                    onlyHistoryCheck
                                ).map((row) => {
                                    if (Number(row.historyCount) !== 0 && !Boolean(state.historyRemains[row.id])) {
                                        apiDistributorHistoryRemains(row.id, row.nomenclatureId, row.reportingDay);
                                    }

                                    return (
                                        <Row key={row.id} row={row}/>
                                    )
                                })}
                            </TableBody>
                        </Table>
        </React.Fragment>
    );
}
