import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from "uuid";
import style from './CreateRouteSheetsOrderListTableList.module.css';
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import CreateRouteSheetsOrderListTableListItem
    from "../CreateRouteSheetsOrderListTableListItem/CreateRouteSheetsOrderListTableListItem";

const CreateRouteSheetsOrderListTableList = observer(({width}) => {
    const {orderList} = createRouteSheetsStore;
    const cache = useRef(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 51
    }))


    const rowRenderer = useCallback(({key, index, style, parent}) => {
        return (
            <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                {({ measure }) => (
                    <div key={key} style={style} ref={measure}>
                        <CreateRouteSheetsOrderListTableListItem key={uuidv4()} orderData={orderList[index]} orderIndex={index} />
                    </div>
                )}
            </CellMeasurer>
        )
    }, [orderList]);

    return (
        <div style={{width: width, height: '100%'}}>
            <AutoSizer disableWidth>
                {({height}) => (
                    <List
                        key={orderList.length}
                        width={width} // Используем переданную ширину
                        height={height} // Используем переданную высоту
                        rowCount={orderList.length} // Количество строк
                        deferredMeasurementCache={cache.current}
                        rowHeight={cache.current.rowHeight} // Высота одной строки
                        rowRenderer={rowRenderer} // Функция для рендеринга строки
                        overscanRowCount={5} // Количество строк, которые будут предварительно загружены
                        className={style.tableList}
                    />
                )}
            </AutoSizer>
        </div>
    );
});

export default memo(CreateRouteSheetsOrderListTableList);
