import {makeAutoObservable} from 'mobx'
import optimizationRouteSheetStore from "./optimizationRouteSheetStore";
import axios from "axios";
import toast from "react-hot-toast";
import globalState from "../globalState";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../components/ToastMassage/ToastMassage';

const DEFAULT_SETTING_DOCUMENT = {
    id: null,
    costing: "truck",   // Тип транспорта для построения маршрута
    units: "kilometers",    // Единица измерения расстояния в ответе
    language: "ru-RU",  // Язык ответа
    fix_destination: "true",   // Флаг, указывающий на то, нужно ли фиксировать последнюю из перечисленных координат в locations в качестве финиша
    alternates: 0,  // Максимальное количество альтернативных маршрутов в дополнение к основному
    date_time: null,    // Дата и время в точке отправления или назначения для определения более точных результатов построения
    costing_options: {
        "auto": {
            shortest: false, // Если shortest=false, то маршрут оптимизируется по времени. Если shortest=true, то маршрут оптимизируется по расстоянию.
            use_unpaved: 0.5, // Использовать грунтовые дороги при построении маршрута
            use_highways: 1,    // Использовать автомагистрали при построении маршрута.
            use_tolls: 0.5, // Использовать платные дороги при построении маршрута
            use_ferry: 0.5, // Использовать паромные переправы при построении маршрута
            use_border_crossing: 1, // Использовать при построении маршрута дороги, пересекающие границы других государств
            traffic: true // Учитывать пробки и дорожные события при построении маршрута
        },
        "truck": {
            shortest: false, // Если shortest=false, то маршрут оптимизируется по времени. Если shortest=true, то маршрут оптимизируется по расстоянию.
            weight: 21.77,  // Указание массы автомобиля в тоннах, для построения маршрута с учётом допустимых весовых нагрузок на дорожную сеть. По умолчанию — 21.77
            height: 4.11,   // Высота грузовика в метрах. По умолчанию — 4.11 м.
            width: 9.07,    // Ширина грузовика в метрах. По умолчанию — 9.07 м.
            length: 21.64,  // Длина грузовика в метрах. По умолчанию — 21.64 м.
            axle_load: 9.07,    // Нагрузка на ось в тоннах. По умолчанию — 9.07 тонн.
            hazmat: false,  // Флаг, определяющий, везет ли грузовик опасные материалы
            use_unpaved: 0.5, // Использовать грунтовые дороги при построении маршрута
            use_highways: 1,    // Использовать автомагистрали при построении маршрута.
            use_tolls: 0.5, // Использовать платные дороги при построении маршрута
            use_ferry: 0.5, // Использовать паромные переправы при построении маршрута
            use_border_crossing: 1, // Использовать при построении маршрута дороги, пересекающие границы других государств
            // traffic: true,  // Учитывать пробки и дорожные события при построении маршрута
        }
    },
    destinationDateTime: {
        date: null,
        type: 1,
        valueType: "отправления",
        time: "00:00"
    },
    optimization_parameters_checked: {
        optimization_for_time: true,
        optimization_for_distance: false
    },
    costing_options_checked: {
        use_unpaved: false,
        use_highways: false,
        use_tolls: false,
        use_ferry: false,
        use_border_crossing: false
    },
    initial_point_route: null
}

class settingOptimizationRouteSheetStore {

    // Основные параметры конфигурации (для оптимизации и построения маршрута)
    generalSettingForDocuments = DEFAULT_SETTING_DOCUMENT;

    // Параметры к конкретному документу
    settingForDocuments = null;

    // Копия редактируемого маршрутного листа (Для механизма отмены изменений)
    editedRouteSheet = null;
    editedRouteSheetCopy = null;

    // Список начальных точек маршрута
    popupInitialPointList = [];

    // Список водителей
    popupDriversList = [];

    // Список ТТ по документу
    orderListTableSortState = {
        sort_name: "order_number",
        sort_value: 0
    }

    searchState = {
        table_search_value: "",
        editedRouteSheet: null
    }

    constructor() {
        makeAutoObservable(this,)
    }

    /*
    * Функция срабатывает при выборе типа автомобиля, для оптимизации/построения маршрута
    * */
    handleCheckboxChangeTypeCar(eventValue, settingsObjectName) {
        const {
            use_unpaved,
            use_highways,
            use_tolls,
            use_ferry,
            use_border_crossing
        } = this[settingsObjectName].costing_options[eventValue];

        // Очищаем настройки
        this[settingsObjectName] = {
            ...this[settingsObjectName],
            costing_options_checked: {
                use_unpaved: use_unpaved !== 0.5,
                use_highways: use_highways !== 1,
                use_tolls: use_tolls !== 0.5,
                use_ferry: use_ferry !== 0.5,
                use_border_crossing: use_border_crossing !== 1
            }
        }

        // Устанавливаем тип автомобиля
        this[settingsObjectName].costing = eventValue;
    }

    /**
     * Функция для выбора исключения маршрута на основе предоставленного значения и имени.
     *
     * @param {type} value - описание параметра значения
     * @param {type} name - описание параметра имени
     * @return {type} описание возвращаемого значения
     */
    selectRouteExclusion(value, name, settingsObjectName) {

        if (name === "hazmat") {
            this[settingsObjectName].costing_options[this[settingsObjectName].costing][name] = value;
        } else if (this[settingsObjectName].costing_options[this[settingsObjectName].costing][name] === 0) {
            this[settingsObjectName].costing_options_checked[name] = false
            this[settingsObjectName].costing_options[this[settingsObjectName].costing][name] = Number(value);
        } else {
            this[settingsObjectName].costing_options_checked[name] = true
            this[settingsObjectName].costing_options[this[settingsObjectName].costing][name] = Number(0);
        }

    }

    /**
     * Функция для изменения типа значения назначения и установки соответствующего типа даты назначения.
     *
     * @param {type} value - новое значение, которое нужно установить для назначения
     * @return {type} undefined
     */
    changeDestination(value, settingsObjectName) {
        // Устанавливаем тип значения назначения
        this[settingsObjectName].destinationDateTime.valueType = value;

        // Получаем текущую дату и сравниваем с датой назначения
        const dateDestination = this[settingsObjectName].destinationDateTime.date;
        const currentDate = new Date();
        const comparisonDate = new Date(dateDestination);

        // Проверяем условия для установки типа назначения
        if (currentDate.getDate() === comparisonDate.getDate() && this[settingsObjectName].destinationDateTime.time === "" && this[settingsObjectName].destinationDateTime.valueType === "отправления") {
            this[settingsObjectName].destinationDateTime.type = 0;
        } else if (value === "отправления") {
            this[settingsObjectName].destinationDateTime.type = 1;
        } else if (value === "прибытия") {
            this[settingsObjectName].destinationDateTime.type = 2;
        }
    }

    /**
     * Изменяет дату назначения
     *
     * @param {Date} date - дата, которую нужно изменить
     * @return {void}
     */
    changeDestinationDate(date, settingsObjectName) {

        if (date === null) {
            // Преобразуем дату в формат ISO и обрезаем до минут
            this[settingsObjectName].destinationDateTime.date = null;
        } else {
            const currentDate = new Date();
            const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

            // Увеличиваем дату на один день
            const destinationDateT = new Date(date);
            destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);

            // Преобразуем дату в формат ISO и обрезаем до минут
            this[settingsObjectName].destinationDateTime.date = destinationDateT.toISOString().slice(0, 16);
            // Вызываем функцию изменения пункта назначения с передачей типа значения
            this.changeDestination(this[settingsObjectName].destinationDateTime.valueType, settingsObjectName);
        }


    }

    /**
     * Изменить время прибытия/отправления в пункт назначения.
     *
     * @param {type} time - новое время для пункта назначения
     * @return {type} undefined
     */
    changeDestinationTime(time, settingsObjectName) {
        this[settingsObjectName].destinationDateTime.time = time;
        this.changeDestination(this[settingsObjectName].destinationDateTime.valueType, settingsObjectName);
    }

    /**
     * Функция для изменения параметров грузовика.
     *
     * @param {type} value - описание параметра value
     * @param {type} name - описание параметра name
     * @return {type} описание возвращаемого значения
     */
    changeParamsTruck(value, name, settingsObjectName) {
        this[settingsObjectName].costing_options[this[settingsObjectName].costing][name] = Number(value);
    }

    /**
     * selectOptimizationParameter - Обновляет состояние страницы на основе выбранного параметра оптимизации
     *
     * @param {string} nameParam - имя параметра оптимизации
     * @param {boolean} value - значение для установки параметра оптимизации
     * @return {void}
     */
    selectOptimizationParameter(nameParam, value, settingsObjectName) {

        for (const nameParamKey in this[settingsObjectName].optimization_parameters_checked) {
            if (nameParamKey !== nameParam) {
                this[settingsObjectName].optimization_parameters_checked[nameParamKey] = false;
            } else {
                this[settingsObjectName].optimization_parameters_checked[nameParamKey] = value;
            }
        }

        if (nameParam === "optimization_for_time") {
            this[settingsObjectName].costing_options[this[settingsObjectName].costing].shortest = false;
        } else if (nameParam === "optimization_for_distance") {
            this[settingsObjectName].costing_options[this[settingsObjectName].costing].shortest = true;
        }
    }


    async saveEditRouteDocument(authTokens, setUser, setAuthTokens, logoutUser, navigate) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            docSetting: this.settingForDocuments,
            docData: this.editedRouteSheet
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/updateTransportOrderById`, data, {headers}
        ).then(res => {
            navigate(`/logisticService/routingByRouteList/routeSheetsList/${this.editedRouteSheet.document_date}`)
            toastMassage(toast.success, "Изменения сохранены успешно!", 2500, "bottom-center")
        }).catch(error => {
            toastMassage(toast.error, "Изменения не сохранены!", 10000, "bottom-right")
            console.error(error)
        })
    }

    changeSettingDocument(documentId = null) {
        const {pageState} = optimizationRouteSheetStore;
        pageState.setting_document = true; // Устанавливаем новое значение настройки документа
        pageState.setting_document_id = documentId; // Устанавливаем ID редактируемого документа

        const currentSettingForDocument = this.getCurrentSettingForDocument();
        this.editedRouteSheet = {
            ...this.editedRouteSheet,
            setting: true
        }

        this.settingForDocuments = Object.keys(this.editedRouteSheet.routing_settings).length !== 0 ? this.editedRouteSheet.routing_settings : currentSettingForDocument; // Устанавливаем настройки маршрутизации
    }

    changeDefaultValueSettingDoc() {
        optimizationRouteSheetStore.pageState.setting_document_id = null;
        optimizationRouteSheetStore.pageState.setting_document = false;
        this.editedRouteSheet = null;
        this.editedRouteSheetCopy = null;
        this.settingForDocuments = null;
    }

    getCurrentSettingForDocument() {
        const deliveryDate = this.editedRouteSheet.delivery_date;

        return {
            ...DEFAULT_SETTING_DOCUMENT,
            destinationDateTime: {
                date: deliveryDate,
                type: 1,
                valueType: "отправления",
                time: deliveryDate.slice(11)
            }
        }
    }

    async uploadPopupInitPointList(userAccessToken) {
        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getListInitialPointRoute`, {headers}
        )
            .then(response => {
                if (response.data.length > 0) {
                    this.popupInitialPointList = response.data;
                }
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });

    }

    async uploadPopupDriversList(userAccessToken) {
        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getListDrivers`, {headers}
        )
            .then(response => {
                if (response.data.length > 0) {
                    this.popupDriversList = response.data;
                }
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });

    }

    openPopupSettingOptimization(scrollY, typePopup) {
        const {pageState} = optimizationRouteSheetStore;
        pageState.popup_scroll_position = scrollY;
        pageState.popup_type = typePopup;
        pageState.popup_show = true;
    }

    closePopupSettingOptimization() {
        const {pageState} = optimizationRouteSheetStore;
        pageState.popup_show = false;
        pageState.popup_type = null;
    }


    changeSortCollTableRouteList(sortName) {
        const {sort_value, sort_name} = this.orderListTableSortState;

        if (sortName !== sort_name) {
            this.orderListTableSortState.sort_value = 1
        } else {

            if (this.orderListTableSortState.sort_value < 2) {
                this.orderListTableSortState.sort_value = Number(sort_value) + 1;
            } else {
                this.orderListTableSortState.sort_value = 0;
            }

        }

        this.orderListTableSortState.sort_name = sortName;
    }

    selectSortedOrderList(sortValue, documentId) {
        const {sort_value, sort_name} = this.orderListTableSortState;

        if (sort_value === 0) {
            // Восстанавливаем исходный порядок значений
            this.editedRouteSheet.order_list = JSON.parse(JSON.stringify(this.editedRouteSheetCopy.order_list));
        } else if (sort_value === 1) {
            // Cортируем по возрастанию
            this.editedRouteSheet.order_list = this.editedRouteSheet.order_list.sort((a, b) => a[sort_name].localeCompare(b[sort_name]))
        } else if (sort_value === 2) {
            // Cортируем по убыванию
            this.editedRouteSheet.order_list = this.editedRouteSheet.order_list.sort((a, b) => b[sort_name].localeCompare(a[sort_name]))
        }

    }

    changeSearchAddress(newSearValue, documentId) {

        if (newSearValue === "") {
            this.editedRouteSheet.order_list = JSON.parse(JSON.stringify(this.editedRouteSheetCopy.order_list));
        }
        //
        this.searchState.table_search_value = newSearValue;
        const regex = new RegExp(`^.*${newSearValue.split(' ').join('.*')}.*$`, 'i');

        this.editedRouteSheet.order_list = this.editedRouteSheetCopy.order_list.slice().filter(orderObj =>
            regex.test(orderObj.order_number.toString()) || regex.test(orderObj.order_client.toString()) || regex.test(orderObj.order_adress_delivery.toString())
        )
    }

}

export default new settingOptimizationRouteSheetStore();