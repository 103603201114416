import Box from "@mui/material/Box";
import React, { useContext } from 'react';
import { Navigate } from "react-router-dom";
import AuthorizationForm from "../../components/AuthorizationForm/AuthorizationForm";
import GlobalContext from "../../context/GlobalContext";
import "./AuthorizationPage.css";

const AuthorizationPage = () => {
    let { user } = useContext(GlobalContext);

    return (
        <>
            {user ? (
                // Redirect to the home page if the user is already authenticated
                <Navigate to="/" />
            ) : (
                <Box>
                    <Box>
                        <AuthorizationForm />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default AuthorizationPage;
