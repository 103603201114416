import React from 'react';
import style from './ModalRouteSheetAskDelete.module.css'
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";

const ModalRouteSheetAskDelete = observer(() => {
    const {editRouteSheetsData} = createRouteSheetsStore
    const axiosInstance = AxiosConfig_LogisticService();

    const closeModal = () => {
        createRouteSheetsStore.changeRouteSheetDataModal(false, "changeDeleteRoute")
    }

    const deleteRouteSheet = async () => {
        await createRouteSheetsStore.deleteRouteSheetById(axiosInstance, editRouteSheetsData.id)
    }

    return (
        <div>
            <div className={style.popupHeader}>
                Уточнение
                <div
                    className={style.btnClosePopup}
                    onClick={() => closeModal()}
                ><DeleteIcon/></div>
            </div>
            <div className={style.askText}>В документе не остается заказов, он будет удален!</div>
            <div className={style.actionAskBtns}>
                <button
                    className={style.acceptAskBtn}
                    onClick={() => deleteRouteSheet()}
                >Продолжить
                </button>
                <button
                    className={style.cancelAskBtn}
                    onClick={() => closeModal()}
                >Отменить
                </button>
            </div>
        </div>
    );
});

export default ModalRouteSheetAskDelete;