import React from 'react';
import style from './SearchInput.module.css'

const SearchInput = ({ placeholder = "Поиск..", onChange, onClear, searchValue, maxLength = 50, className }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    const handleClear = () => {
        onClear("");
    };

    return (
        <div  className={`${style.inputSearchOrder} ${className}`}>
            <input
                type="text"
                maxLength={maxLength}
                value={searchValue}
                onChange={handleChange}
                placeholder={placeholder}
            />
            <span onClick={handleClear}>Очистить</span>
        </div>
    );
};

export default SearchInput;
