import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import style from './AddressCoordinateEditingPage.module.css';
import mmrgl from "mmr-gl";
import {Link, Outlet, Route, Routes} from "react-router-dom";
import editCoordinatesAddress
    from "../../store/transportationLogisticsService/pageEditCoordinatesAddress/editCoordinatesAddress";
import {observer} from "mobx-react-lite";
import ReactDOM from "react-dom/client";
import MapMarketPoint from "../../components/transportationLogisticsService/MapMarketPoint/MapMarketPoint";
import AddressCoordinateList
    from "../../components/transportationLogisticsService/AddressCoordinateList/AddressCoordinateList";
import AddressCoordinateEdit
    from "../../components/transportationLogisticsService/AddressCoordinateEdit/AddressCoordinateEdit";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {driver} from "driver.js";
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import PrivateRoute from "../../utils/PrivateRoute";
import DeliveredStatusSubHeader
    from "../../components/transportationLogisticsService/DeliveredStatusListPageComponents/DeliveredStatusSubHeader/DeliveredStatusSubHeader";
import TemplateList
    from "../../components/transportationLogisticsService/DeliveredStatusListPageComponents/TemplateList/TemplateList";
import globalState from "../../store/transportationLogisticsService/globalState";
import GlobalContext from "../../context/GlobalContext";
import RequestLimitLogistics from '../../components/RequestLimitLogistics/RequestLimitLogistics';

const AddressCoordinateEditingPage = observer(() => {
    const {markersList, statePage, editDataAddressCoordinates, infoAlertState} = editCoordinatesAddress;
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const mapRef = useRef(null);
    const [isHidden, setIsHidden] = useState(false);
    let clickHandler = (clickData) => {
        editCoordinatesAddress.clickEditAddressCoordinate(clickData.lngLat);
    };

    useMemo(() => {
        if (statePage.show_faq) {

            const mainSteps = [
                {
                    element: `#returnToLastPage_AddressCoordinateList`,
                    popover: {
                        title: 'Вернуться на страницу маршрутизации',
                        description: 'Кнопка для перехода на страницу маршрутизации.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#uploadAllAddressCoordinates_AddressCoordinateList`,
                    popover: {
                        title: 'Получить все адреса',
                        description: 'Кнопка, по нажатию на которую вы получаете в табличной части все адреса, которые ранее выгружали из 1С.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#uploadAllErrorAddressCoordinates_AddressCoordinateList`,
                    popover: {
                        title: 'Получить адреса без координат',
                        description: 'Кнопка, по нажатию на которую вы получаете в табличной части все адреса без координат, которые ранее выгружали из 1С.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#searchInputAddress_AddressCoordinateList`,
                    popover: {
                        title: 'Поиск адресов',
                        description: 'В данном поле указывается адрес который вы хотите получить из списка. По кнопке "Очистить, поиск отменяется."',
                        position: 'bottom'
                    }
                },
                {
                    element: `#addressList_AddressCoordinateList`,
                    popover: {
                        title: 'Список выгруженных адресов.',
                        description: 'В данном списке отображаются адреса, которые выгружались из 1С. По нажатию на адрес, он отображается на карте. Для редактирования координат, необходимо нажать на шестеренку.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#map`,
                    popover: {
                        title: 'Карта',
                        description: 'Карта, на которой отображается адрес.',
                        position: 'bottom'
                    }
                }
            ];

            const settingSteps = [
                {
                    element: `#returnToLastPage_AddressCoordinateList`,
                    popover: {
                        title: 'Вернуться на страницу маршрутизации',
                        description: 'Кнопка для перехода на страницу маршрутизации.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#addressContext_AddressCoordinateEdit`,
                    popover: {
                        title: 'Адрес',
                        description: 'Информативное поле, которое указывает, для какого адреса корректируются координаты.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#editCoordinatesBlock_AddressCoordinateEdit`,
                    popover: {
                        title: 'Поля для ввода координат Широты и Долготы.',
                        description: 'Два поля ввода координат. Есть три возможности внести координаты: 1) Вручную указать координаты в полях, в корректно формате. 2) Нажать в область на карте, где должна находиться точка адреса. 3) Есть точка на карте уже существует, можно ее зажать и перетащить в другое место. ',
                        position: 'bottom'
                    }
                },
                {
                    element: `#map`,
                    popover: {
                        title: 'Карта',
                        description: 'Карта, на которой отображается редактируемый адрес.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#cancelEditCoordinate_AddressCoordinateEdit`,
                    popover: {
                        title: 'Отменить изменения/выйти с редактирования',
                        description: 'Кнопка, для отмены изменений или для возврата к списку адресов.',
                        position: 'bottom'
                    }
                },
                {
                    element: `#saveEditCoordinate_AddressCoordinateEdit`,
                    popover: {
                        title: 'Сохранить изменения',
                        description: 'Кнопка, для сохранений внесенных изменений.',
                        position: 'bottom'
                    }
                },

            ];

            const viewSteps = (statePage.setting) ? settingSteps : mainSteps;

            editCoordinatesAddress.showFaqBlock(false)
            const driverObj = driver({
                showProgress: true,
                progressText: `{{current}} из {{total}}`,
                nextBtnText: 'Далее',
                prevBtnText: 'Назад',
                doneBtnText: 'Закрыть',
                steps: viewSteps
            });

            driverObj.drive();
        }
    }, [statePage.show_faq])

    const [tarif, setTarif] = useState(true);

    // Инициализация карты на странице
    useEffect(() => {
        async function initMap() {
            try {
                const resultCheck = await globalState.checkCountOrTariff("mapСount", authTokens, setUser, setAuthTokens, logoutUser);
                setTarif(resultCheck.status === 200 ? true : false)

                if (resultCheck.status === 200) {
                    mmrgl.accessToken = process.env.REACT_APP_ACCES_TOKEN_FOR_VKMAP;
                    // mmrgl.accessToken = "";

                    const map = new mmrgl.Map({
                        container: 'map',
                        zoom: 12,
                        center: [34.11823, 44.94554],
                        style: 'mmr://api/styles/main_style.json',
                        hash: false,
                        doubleClickZoom: false
                    });

                    const scale = new mmrgl.ScaleControl({
                        maxWidth: 180,
                        unit: 'imperial',
                    });

                    map.addControl(scale);
                    scale.setUnit('metric');

                    const nav = new mmrgl.NavigationControl();
                    map.addControl(nav, 'bottom-right');

                    mapRef.current = map;

                    editPointMap();
                }
            } catch (e) {
                console.error(e)
            }
        }

        initMap();

        // Функция очистки для удаления карты при размонтировании компонента
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    /**
     * Функция showSelectAddressToMap для отображения выбранного адресса на карте
     * @param addressData
     */
    const showSelectAddressToMap = (addressData) => {
        // Очищаем карту перед отображением нового маршрута
        editCoordinatesAddress.clearMarkers()
        if (addressData.lat === null || addressData.lon === null) {
            !statePage.setting && editCoordinatesAddress.showAlertBlock("warning",
                "Отсутствуют координаты! Добавьте их через панель редактирования.")
            return
        }

        const coordsArray = [Number(addressData.lon), Number(addressData.lat)];

        const map = mapRef.current;

        if (map) {

            // Выводим маркеры точек на карту
            const markerElement = document.createElement('div');
            const root = ReactDOM.createRoot(markerElement);
            root.render(<MapMarketPoint idDoc={1}/>);

            // // Создаем элемент для Popup
            // const popupElement = document.createElement('div');
            // const popupRoot = ReactDOM.createRoot(popupElement);
            // // Рендерим компонент для Popup
            // const orderInfoObj = routeOnMap.getOrderByCoords(idDocument, coords)
            // popupRoot.render(<OrderMapPopup orderDoc={orderInfoObj}/>);

            // let popup = new mmrgl.Popup({
            //     anchor: "bottom",
            //     maxWidth: "none",
            //     className: style.mainPopupBlock
            // }).setDOMContent(popupElement);

            editCoordinatesAddress.addNewMarkerToMap(new mmrgl.Marker({
                element: markerElement,
            }).setLngLat(coordsArray).addTo(map))

            const coordsFocusToMarker = [coordsArray, coordsArray]

            map.fitBounds(coordsFocusToMarker, {zoom: 18});
        }
    };

    const editPointMap = () => {
        const map = mapRef.current;
        if (statePage.setting === true) {
            if (map && markersList.length === 0) {
                console.log("tes")
                map.on("dblclick", ({lngLat}) => {
                    editCoordinatesAddress.showNewCoordinatesAddressToMap(lngLat, mapRef)
                    editCoordinatesAddress.createNewAddressCoordinates(lngLat)
                })
            }
            if (map && markersList.length > 0) {
                markersList[0].setDraggable(true).on("dragend", (dragData) => {
                    editCoordinatesAddress.dragEditAddressCoordinate(dragData.target)
                });
                map.on("dblclick", clickHandler)
            }
        } else if (statePage.setting === false) {
            if (map && markersList.length > 0) {
                map._listeners = null
                editCoordinatesAddress.clearMarkers()
            }
        }
    }

    /**
     * Хук useMemo для управления перетаскиванием маркера на основе значения 'setting' в состоянии страницы.
     *
     * @param {Object} statePage - Объект текущего состояния страницы.
     * @param {boolean} statePage.setting - Булево значение, указывающее, должен ли маркер быть перетаскиваемым или нет.
     *
     * @returns {void}
     */
    useMemo(() => {
        editPointMap();
    }, [statePage.setting, markersList])

    return (
        <div className={style.coordinateEditingPage}>

            <>
                <Snackbar sx={{position: 'absolute'}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                          open={infoAlertState.show_alert}
                          autoHideDuration={6000}>
                    <Alert
                        severity={infoAlertState.type}
                        variant="filled"
                        sx={{width: '100%', zIndex: '99'}}
                    >
                        {infoAlertState.alert_text}
                    </Alert>
                </Snackbar>
            </>

            <MainMenuLogisticsService namePage={"Редактирование координат адресов"}/>

            <div className={style.coordinateEditingContent}>
                <div
                    className={style.coordinateEditingNavigate}
                >
                    <Routes>
                        <Route path={"addressList/:typeAddressList"} element={
                            <PrivateRoute>
                                <AddressCoordinateList showSelectAddressToMap={showSelectAddressToMap}/>
                            </PrivateRoute>
                        }/>
                    </Routes>

                    <Routes>
                        <Route path={"editAddress/:addressId/:typeRedirect?"} element={
                            <PrivateRoute>
                                <AddressCoordinateEdit mapRef={mapRef} showSelectAddressToMap={showSelectAddressToMap}/>
                            </PrivateRoute>
                        }/>
                    </Routes>

                    {/*{*/}
                    {/*    (statePage.setting)*/}
                    {/*        ?*/}
                    {/*        <AddressCoordinateEdit mapRef={mapRef}/>*/}
                    {/*        :*/}
                    {/*        <AddressCoordinateList showSelectAddressToMap={showSelectAddressToMap}/>*/}
                    {/*}*/}
                </div>



                <div className={style.viewAddressMapBlock}>
                    {tarif ?
                    <div
                        id="map"
                        style={{width: '100%', height: '95vh'}}
                    >
                    </div>
                    : 
                    <div style={{backgroundColor: "#f3f4f9", width: "100%", height: "100%", border: "1px solid #fcfcfcfc"}}>

                    <RequestLimitLogistics/>
                    </div>
                    }
                </div>

                {/*<div*/}
                {/*    className={style.btnFaq}*/}
                {/*    onClick={()=> editCoordinatesAddress.showFaqBlock(true)}*/}
                {/*>*/}
                {/*    FAQ*/}
                {/*</div>*/}
            </div>
        </div>
    );
});

export default AddressCoordinateEditingPage;