import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination'; // Import Pagination
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, {useContext, useState} from 'react';
import {
    generateLocalColorFromText
} from '../../../helpers/helpers';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {Avatar} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import GlobalContext from '../../../context/GlobalContext';
import ManufacturersCardNavigation from '../ManufacturersCardNavigation/ManufacturersCardNavigation';
import ManufacturersInfoDataForm from '../ManufacturersInfoDataForm/ManufacturersInfoDataForm';
import "./ManufacturersTable.css";

function ManufacturersTable(props) {
    const {state} = useContext(GlobalContext);
    const manufacturers = props.manufacturers;
    const [activeString, setActiveString] = useState(null);

    function getObjectById(data, desiredId) {
        return data.find(item => item.manId === desiredId);
    }

    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const itemsPerPage = 10; // Number of items to display per page
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    return (
        <Box className={"distributor-table"}>
            <Card sx={{boxShadow: '0px 2px 10px 0px rgba(58, 53, 65, 0.1)'}}>
                {!activeString ?
                    <CardContent sx={{padding: 0}}>
                        <Table stickyHeader aria-label='table in dashboard'>
                            <TableHead>
                                <TableRow sx={{fontWeight: 800}}>

                                    <TableCell colSpan={2}>Производитель</TableCell>
                                    <TableCell>ИНН</TableCell>
                                    <TableCell align="left">Кол-во ТТ</TableCell>
                                    <TableCell align="left">Кол-во SKU</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {manufacturers
                                    .slice(
                                        (currentPage - 1) * itemsPerPage,
                                        currentPage * itemsPerPage
                                    )
                                    .map((row) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow
                                                onClick={() => setActiveString(row)}
                                                className="hoverable-row"
                                            >
                                                <TableCell sx={{paddingRight: 0, width: "1vw"}}>
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: generateLocalColorFromText(row.name),
                                                            marginRight: "10px"
                                                        }}
                                                    > {row.name[0]}
                                                    </Avatar>
                                                </TableCell>
                                                <TableCell component="th"
                                                           sx={{paddingLeft: 0}}
                                                           scope="row"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell component="th" sx={{paddingLeft: 0}} scope="row">
                                                    {row.inn}
                                                </TableCell>
                                                <TableCell
                                                    align="left">{getObjectById(state.distributorDocsSum, row.id) ? getObjectById(state.distributorDocsSum, row.id).retOutsCount : 0}</TableCell>
                                                <TableCell
                                                    align="left">{getObjectById(state.distributorDocsSum, row.id) ? getObjectById(state.distributorDocsSum, row.id).nomsCount : 0}</TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '1vw'}}>
                            <Pagination
                                sx={{marginTop: "1vw"}}
                                color="primary"
                                shape="rounded"
                                count={Math.ceil(manufacturers.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    </CardContent>
                    :
                    <CardContent sx={{padding: 0}}>
                        <IconButton onClick={() => setActiveString(null)}><ArrowCircleLeftIcon
                            color="primary"/></IconButton>
                        <ManufacturersCardNavigation setActiveString={setActiveString} manufacturer={activeString}/>
                    </CardContent>
                }
            </Card>
            <ManufacturersInfoDataForm activeString={activeString}/>
        </Box>
    );
}

export default ManufacturersTable;
