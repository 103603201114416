import React from 'react';
import style from './DeliversPopupList.module.css';
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import {v4 as uuidv4} from "uuid";
import {observer} from "mobx-react-lite";
import optimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import settingOptimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import DeliversPopupItemList from "../DeliversPopupItemList/DeliversPopupItemList";
import InitialPointPopupItem from "../InitialPointPopupItem/InitialPointPopupItem";
import {AutoSizer, List} from "react-virtualized";

const DeliversPopupList = observer(({setNewData}) => {
    const {pageState} = optimizationRouteSheetStore;
    const {popupDriversList} = settingOptimizationRouteSheetStore;

    const rowRenderer = ({index, key, style}: any) => {
        return (
            <div key={key} style={style}>
                <DeliversPopupItemList
                    setNewData={(addressData) => setNewData(addressData)}
                    deliverData={popupDriversList[index]}
                    key={uuidv4()}
                    uid={uuidv4()}
                    indexElem={index}
                />
            </div>
        )
            ;
    };

    return (
        <div className={style.selectInitialPointContent}>
            <div className={style.popupHeader}>
                Выберите водителя для маршрута
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        optimizationRouteSheetStore.closePopupInitPoints()
                    }}
                ><DeleteIcon/></div>
            </div>
            <div className={style.popupHeaderList}>
                <div>Имя водителя</div>
            </div>
            <div>
                <AutoSizer>
                    {({width, height}) => (
                        <List
                            width={width}
                            height={520}
                            rowCount={popupDriversList.length} // Количество элементов в списке
                            rowHeight={65} // Высота отдельного элемента списка
                            rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                            className={style.popupInitPointListBlock}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
});

export default DeliversPopupList;