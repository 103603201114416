import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { center } from "@turf/turf";

export function toastMassage(type, massage, duration, position) {
  type(
    (t) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <span>{massage}</span>
         <IconButton onClick={() => toast.dismiss(t.id)}>
           <CloseIcon />
         </IconButton>
      </div>
    ),
    {
      duration: duration,
      position: position,
    }
  );
}
