import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './DriverAndForwarderCreate.module.css'
import {Navigate, useNavigate} from "react-router-dom";
import Popover_SelectMobileStatusDelivered
    from "../../UI/Popover_SelectMobileStatusDelivered/Popover_SelectMobileStatusDelivered";
import {observer} from "mobx-react-lite";
import debounce from "lodash/debounce";
import GlobalContext from "../../../../context/GlobalContext";
import pageDriverAndForwarderStore
    from "../../../../store/transportationLogisticsService/pageDriverAndForwarderStore/pageDriverAndForwarderStore";

const DriverAndForwarderCreate = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {selected_status, cardTypeList} = pageDriverAndForwarderStore.popoverSelectTypeCard
    const navigate = useNavigate();

    const [cardAppellation, setCardAppellation] = useState("")

    useEffect(() => {

        return () => {
            pageDriverAndForwarderStore.changeDefaultValueCreateCard();
        }
    }, []);

    const getSelectedValueDestination = (newValue) => {
        pageDriverAndForwarderStore.changeSelectTypeCard(newValue, "create");
    };

    const updateTemplate = useCallback(
        debounce((value) => {
            pageDriverAndForwarderStore.changeCardAppellation(value, "create")
        }, 200), // Задержка в миллисекундах
        []
    );

    const changeTemplateName = (newValue) => {
        setCardAppellation(newValue)
        updateTemplate(newValue)
    }

    const createNewTemplate = async () => {
        try {
            await pageDriverAndForwarderStore.createCard(authTokens, setUser, setAuthTokens, logoutUser, navigate);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={style.createTemplate}>
            <div className={style.createTemplateHeader}>
                <div className={style.goBackToList}
                     onClick={() => navigate(-1)}>Назад
                </div>
                Создание карточки
            </div>

            <div className={style.createTemplateForm}>
                <div className={style.selectTypeStatus}>
                    <div>Тип карточки:</div>
                    <Popover_SelectMobileStatusDelivered selectOptions={selected_status}
                                                         options={cardTypeList}
                                                         onChange={(newValue) => getSelectedValueDestination(newValue)}/>
                </div>

                <div className={style.nameTemplateInput}>
                    <div>Наименование (ФИО):</div>
                    <textarea
                        // type="text"
                        placeholder="Наименование (ФИО)..."
                        value={cardAppellation}
                        maxLength={80}
                        rows={5}

                        onChange={
                            (e) => {
                                changeTemplateName(e.target.value)
                            }
                        }
                    ></textarea>
                </div>
            </div>

            <div className={style.actionTemplateBtns}>
                <button
                    className={style.createTemplateBtn}
                    onClick={() => createNewTemplate()}
                >Создать карточку
                </button>
                <button
                    className={style.cancelCreateTemplateBtn}
                    onClick={() =>  navigate(-1)}
                >Отменить</button>
            </div>
        </div>
    );
});

export default memo(DriverAndForwarderCreate);