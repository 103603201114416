import { Box } from '@mui/material';
import "./IsLockedPage.css"
import LockImg from "../../imges/lock.svg"

import { Typography } from '@mui/material';
const IsLockedPage = ({nameService}) => {
  


    return (
        <Box className={"locked-page"}>
            <Box className={'locked-card'}>
                <Box sx={{paddingTop: "20px"}}>
            <Typography sx={{textAlign: 'center'}} variant="h5" component="h2">
            Отсутствует подписка на сервис {nameService}
</Typography>
<Typography sx={{textAlign: 'center'}} variant="h6" component="h2">
Чтобы получить доступ к сервису активируйте подписку
</Typography>
</Box>
            <Box sx={{display: 'flex' }}>
                <img style={{width: "50%", margin: '0 auto'}} src={LockImg}/>
            </Box>
            </Box>

        </Box>
    )
}
export default IsLockedPage