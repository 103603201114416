import React, {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import style from './RouteSheetTableList.module.css'
import {observer} from "mobx-react-lite";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import RouteSheetTableItem from "../RouteSheetTableItem/RouteSheetTableItem";
import {v4 as uuidv4} from "uuid";

const RouteSheetTableList = observer(({width, height}) => {
    const {routeSheetsList} = createRouteSheetsStore
    const cache = useRef(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 91
    }))

    const rowRenderer = useMemo(() => {
        return ({key, index, style, parent}) => {
            return <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                {({measure}) => (
                    <div key={key} style={{...style, display: 'flex', minWidth: '600px'}} ref={measure}>
                        <RouteSheetTableItem key={routeSheetsList[index].id} docId={routeSheetsList[index].id} docIndex={index}/>
                    </div>
                )}
            </CellMeasurer>
        }
    }, [routeSheetsList]);


    return (
        <div style={{width: width, height: '100%', overflowX: 'auto'}}>
            <List
                width={width} // Используем переданную ширину
                height={height} // Используем переданную высоту
                rowCount={routeSheetsList.length} // Количество строк
                deferredMeasurementCache={cache.current}
                rowHeight={cache.current.rowHeight}
                rowRenderer={rowRenderer} // Функция для рендеринга строки
                overscanRowCount={3} // Количество строк, которые будут предварительно загружены
                className={style.tableList}
            />
        </div>
    );
});

export default memo(RouteSheetTableList);