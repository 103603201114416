import React from 'react';
import style from './CreateRouteSheets.module.css'
import CreateRouteSheetsOrderList from "../CreateRouteSheetsOrderList/CreateRouteSheetsOrderList";
import CreateRouteSheetsMap from "../CreateRouteSheetsMap/CreateRouteSheetsMap";
import CreateRouteSheetsRightSettingBlock
    from "../CreateRouteSheetsRightSettingBlock/CreateRouteSheetsRightSettingBlock";
import LogisticServiceResizableBox from "../../UI/LogisticServiceResizableBox/LogisticServiceResizableBox";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {observer} from "mobx-react-lite";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import CreateRouteSheetsOrderModalSelectedData
    from "../CreateRouteSheetsOrderListModalSelectedData/CreateRouteSheetsOrderListModalSelectedData";
import ModalAddInRouteSheet from "../ModalAddInRouteSheet/ModalAddInRouteSheet";
import ModalEditRouteSheet from "../ModalEditRouteSheet/ModalEditRouteSheet";
import CreateRouteSheetsSelectOrdersPanel from "../CreateRouteSheetsSelectOrdersPanel/CreateRouteSheetsSelectOrdersPanel"
import OrderTransferPopup from "../../../../popups/logisticService/OrderTransferPopup/OrderTransferPopup";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";

const CreateRouteSheets = observer(() => {
    const axiosInstance = AxiosConfig_LogisticService();
    const {popupType, popupShow, popupScrollPosition} = createRouteSheetsStore.popupState
    const {rightBlock, leftBlock} = createRouteSheetsStore.sideBlocksContentView


    const transferOrderInRouteSheet = async (routeId) =>{
       createRouteSheetsStore.setTransferOrderData(routeId, "toRouteSheetId");

       await createRouteSheetsStore.transferOrderAnotherRoute(axiosInstance, createRouteSheetsStore.transferOrderState);
    }

    return (
        <section className={style.contentPage}>
            <LogisticServiceResizableBox
                minWidth={450}
                minHeight={"100%"}
                maxWidth={"40%"}
                maxHeight={"100%"}
                resizableDirections={['right']}
                style={{display: (leftBlock)? "flex" : "none"}}
            >
                <CreateRouteSheetsOrderList/>
            </LogisticServiceResizableBox>

            <CreateRouteSheetsMap/>
            <CreateRouteSheetsSelectOrdersPanel/>

            <LogisticServiceResizableBox
                minWidth={430}
                minHeight={"100%"}
                maxWidth={"40%"}
                maxHeight={"100%"}
                resizableDirections={['left']}
                style={{display: (rightBlock)? "flex" : "none"}}
            >
                <CreateRouteSheetsRightSettingBlock/>
            </LogisticServiceResizableBox>

            <PopupRouting
                isOpen={popupShow && popupType === "createRouteSheetModal"}
                onClose={() => createRouteSheetsStore.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <CreateRouteSheetsOrderModalSelectedData/>
            </PopupRouting>

            <PopupRouting
                isOpen={popupShow && popupType === "addInRouteSheet"}
                onClose={() => createRouteSheetsStore.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <ModalAddInRouteSheet/>
            </PopupRouting>

            <PopupRouting
                isOpen={popupShow && popupType === "editRouteSheet"}
                onClose={() => createRouteSheetsStore.closePopup()}
                scrollPosition={popupScrollPosition}
            >
                <ModalEditRouteSheet/>
            </PopupRouting>

            <OrderTransferPopup
                isOpen={popupShow && popupType === "transferOrder"}
                onClose={() => createRouteSheetsStore.closePopup()}
                scrollPosition={popupScrollPosition}
                onTransfer={(routeId) => transferOrderInRouteSheet(routeId)}
            />

        </section>
    );
});

export default CreateRouteSheets;