import { TextField } from "@mui/material";
import ru from "date-fns/locale/ru";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import "./DatePickerReportsFinish.css";
const InputDate = styled(TextField)`
  width: 230px;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  input {
    height: 15px; /* Уменьшаем высоту поля ввода */
  }
`;
const DatePickerReportsFinish = (props) => {
  const [dateRange, setDateRange] = useState([props.distributorStartDateReportsFinish, props.distributorFinishDateReportsFinish]);
  const [startDate, endDate] = dateRange;
  const isBothFieldsFilled = startDate && endDate;
  const datePickerRef = useRef(null);


  const handleDateChange = (dates) => {
    if (!dates) {
      props.setDistributorStartDateReportsFinish(null);
      props.setDistributorFinishDateReportsFinish(null);
      setDateRange([null, null]);
      return;
    }

    const threeMonthsLater = new Date(dates[0]);
    threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

    // Ensure that the end date is within three months from the start date
    const adjustedEndDate = dates[1] > threeMonthsLater ? threeMonthsLater : dates[1];

    props.setDistributorStartDateReportsFinish(dates[0]);
    props.setDistributorFinishDateReportsFinish(adjustedEndDate);
    setDateRange([dates[0], adjustedEndDate]);
  };

  const clearForm = () => {
    props.setDistributorStartDateReportsStart(null);
    props.setDistributorFinishDateReportsStart(null);
    setDateRange([null, null]);
  };

  const years = [];
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1960; year--) {
    years.push(year);
  }

  const filterDate = (date) => {
    if (!startDate) {
      // If start date is not selected, allow any date to be selectable
      return true;
    }

    const threeMonthsLater = new Date(startDate);
    threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

    return date >= startDate && date <= threeMonthsLater;
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      const target = e.target;

      if (isBothFieldsFilled && datePickerRef.current.contains(target)) {
        // Clicked inside the date picker while both fields are filled
        clearForm();
      } else {
        // Check if the clicked element is a disabled date
        if (
          target.classList.contains("react-datepicker__day--disabled") ||
          target.closest(".react-datepicker__day--disabled")
        ) {
          props.setTooltipVisible(true);
        } else props.setTooltipVisible(false);
      }
    };

    document.body.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.body.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [isBothFieldsFilled]);

  return (
    <div style={{ display: "flex", zIndex: 100 }} ref={datePickerRef}>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        isClearable={true}
        placeholderText="Выберите период"
        customInput={<InputDate/>}
        locale={ru}
        dateFormat="dd.MM.yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        yearDropdownItemNumber={10}
        scrollableYearDropdown
        yearDropdownItems={years}
        filterDate={filterDate}
      />
    </div>
  );
};

export default DatePickerReportsFinish;
