import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import style from './DriverAndForwarderEdit.module.css'
import {useNavigate, useParams} from "react-router-dom";
import Popover_SelectMobileStatusDelivered
    from "../../UI/Popover_SelectMobileStatusDelivered/Popover_SelectMobileStatusDelivered";
import {observer} from "mobx-react-lite";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import GlobalContext from "../../../../context/GlobalContext";
import Skeleton from '@mui/material/Skeleton';
import debounce from "lodash/debounce";
import pageDriverAndForwarderStore
    from "../../../../store/transportationLogisticsService/pageDriverAndForwarderStore/pageDriverAndForwarderStore";
import DriverAndForwarderEditSkeleton from "./DriverAndForwarderEdit.skeleton";

const DriverAndForwarderEdit = observer(() => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const {selected_status, cardTypeList} = pageDriverAndForwarderStore.popoverSelectTypeCard;
    const {loading} = pageDriverAndForwarderStore.editPageState
    const [cardAppellation, setCardAppellation] = useState("")

    const navigate = useNavigate();
    const {cardId} = useParams();

    useEffect(() => {
        async function getDocument() {
            await pageDriverAndForwarderStore.getCardsByTypeListById(cardId, authTokens, setUser, setAuthTokens, logoutUser);
        }

        getDocument();

        return () => {
            pageDriverAndForwarderStore.changeDefaultValueEditCard();
        }
    }, [cardId]);

    useEffect(() => {

        setCardAppellation(pageDriverAndForwarderStore.editCardState.appellation);

    }, [pageDriverAndForwarderStore.editCardState])


    const getSelectedValueDestination = (newValue) => {
        pageDriverAndForwarderStore.changeSelectTypeCard(newValue, "edit")
    };

    const updateTemplateText = useCallback(
        debounce((value) => {
            pageDriverAndForwarderStore.changeCardAppellation(value, "edit")
        }, 500), // Задержка в миллисекундах
        []
    );

    const changeTemplateName = (newValue) => {
        setCardAppellation(newValue)
        updateTemplateText(newValue)
    }

    const updateTemplate = async () => {
        try {
            await pageDriverAndForwarderStore.updateCard(cardId, authTokens, setUser, setAuthTokens, logoutUser, navigate);
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            {
                (loading) ? <DriverAndForwarderEditSkeleton/> :
                    <div className={style.createTemplate}>
                        <div className={style.createTemplateHeader}>
                            <div className={style.goBackToList}
                                 onClick={() => navigate(-1)}>Назад
                            </div>
                            Редактирование карточки
                        </div>

                        <div className={style.createTemplateForm}>
                            <div className={style.selectTypeStatus}>
                                <div>Тип карточки:</div>

                                <Popover_SelectMobileStatusDelivered selectOptions={selected_status}
                                                                     options={cardTypeList}
                                                                     onChange={(newValue) => getSelectedValueDestination(newValue)}
                                />

                            </div>

                            <div className={style.nameTemplateInput}>
                                <div>Наименование (ФИО):</div>
                                <textarea
                                    type="text"
                                    placeholder="Наименование (ФИО):"
                                    value={cardAppellation}
                                    maxLength={100}
                                    onChange={
                                        (e) => {
                                            changeTemplateName(e.target.value)
                                        }
                                    }
                                />
                            </div>
                        </div>

                        <div className={style.actionTemplateBtns}>
                            <button
                                className={style.createTemplateBtn}
                                onClick={() => updateTemplate()}
                            >Сохранить
                            </button>
                            <button
                                className={style.cancelCreateTemplateBtn}
                                onClick={() => navigate(-1)}
                            >Отменить
                            </button>
                        </div>
                    </div>
            }
        </>
    );
});

export default memo(DriverAndForwarderEdit);