import React, {useCallback, useContext, useEffect, useState} from 'react';
import style from './DeliveryAreaList.module.css'
import DeliveryAreaHeaderList from "../DeliveryAreaHeaderList/DeliveryAreaHeaderList";
import DeliveryAreaItemList from "../DeliveryAreaItemList/DeliveryAreaItemList";
import deliveryZone from "../../../../store/transportationLogisticsService/PageDeliveryZone/deliveryZone";
import {observer} from "mobx-react-lite";
import debounce from "lodash/debounce";
import DeliveryAreaListActionBtns from "../DeliveryAreaListActionBtns/DeliveryAreaListActionBtns";

const DeliveryAreaList = observer(() => {
    const [areaName, setAreaName] = useState("")

    useEffect(()=> {
        return () => {
            deliveryZone.clearUserPointMap();
        }
    }, [])

    const updateAreaName = useCallback(
        debounce((value) => {
            deliveryZone.changeDeliveryAreaText(value)
        }, 500), // Задержка в миллисекундах
        []
    );

    const changeNewSearchValue = (newValue) => {
        setAreaName(newValue);
        updateAreaName(newValue);
    }

    return (
        <>
            <div className={style.settingDeliveryZoneNavHeader}>
                <div>Список зон доставки</div>
            </div>

            <div
                id={"searchInputZone"}
                className={style.inputSearchNameZona}>
                <input
                    type="text"
                    maxLength={50}
                    value={areaName}
                    onChange={(e) => changeNewSearchValue(e.target.value)}
                    placeholder="Поиск по наименованию"
                />
                <span onClick={
                    () => {
                        setAreaName("")
                        changeNewSearchValue("")
                    }
                }>Очистить</span>
            </div>

            <DeliveryAreaListActionBtns/>

            <DeliveryAreaHeaderList/>

            <DeliveryAreaItemList/>
        </>
    );
});

export default DeliveryAreaList;