import React, {memo, useContext, useEffect, useMemo, useRef} from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import {v4 as uuidv4} from "uuid";
import {Button} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PopupSideMenuLogistic from "../UI/PopupSideMenuLogistic/PopupSideMenuLogistic";
import savpMenuLogisticOption
    from "../../../store/transportationLogisticsService/savpMenuLogisticOption/savpMenuLogisticOption";
import {observer} from "mobx-react-lite";
import GlobalContext from '../../../context/GlobalContext';
import { useNavigate } from 'react-router-dom';

const ContentMenuLogisticService = observer(({accessLogistics, nav, navigate, setNavigate}) => {
    const blockRef = useRef(null);
    const rightMenuRef = useRef(null);
    const {
        menuLogisticState: {showSubRightMenu, selectLogisticMenuOption, selectedSubNavId},
        logisticServiceUrl
    } = savpMenuLogisticOption;

  

    // const subscriptionNav = useNavigate();

    // useEffect(() => {
    //     console.log("Логистика", checkedLogisticService())
    //     console.log("Доступ", accessLogistics)

    //     // if(checkedLogisticService() && !accessLogistics)
    //     //      {subscriptionNav("/notSubscription")}
    // }, []);

    const showNavLogisticService = () => {
        savpMenuLogisticOption.changeLogisticMenuOption(!selectLogisticMenuOption);
        savpMenuLogisticOption.changeDefaultStateRightMenu()
        savpMenuLogisticOption.changeSubRightMenu(false);
        setNavigate("/logisticService/")
    }

    /**
     * Обрабатывает событие выбора навигации в логистическом сервисе и обновляет соответствующий элемент меню.
     *
     * @function
     * @param {Event} event - Событие, которое активировало вызов функции.
     * @param {*} selectNav - Наименование элемента меню.
     * @param {string} menuItemId - Идентификатор элемента меню, на который было выполнено действие.
     * @param {*} subNav - Данные подменю.
     */
    const selectNavLogisticService = (event, selectNav, menuItemId, subNav) => {
        // Обновляем ссылку на элемент меню на основе переданного идентификатора.
        rightMenuRef.current = document.getElementById(menuItemId);

        // Проверяем, существует ли элемент меню и соответствует ли он выбранному подменю.
        if (rightMenuRef.current && selectedSubNavId !== menuItemId) {
            // Если элемент меню существует и не соответствует выбранному подменю, вызываем метод selectNavLogisticService.
            savpMenuLogisticOption.selectNavLogisticService({
                menuItemRef: rightMenuRef,
                selectNav,
                menuItemId,
                subNavData: subNav
            })
        } else {
            // Если элемент меню не существует или соответствует выбранному подменю, вызываем методы changeSubRightMenu и changeDefaultStateRightMenu.
            savpMenuLogisticOption.changeSubRightMenu(false);
            savpMenuLogisticOption.changeDefaultStateRightMenu();
        }
    }

    // Функция для проверки наличия определенной подстроки в строке navigate.
    const checkedLogisticService = () => {
        const substr = "/routersOnTheMap";
        const substr1 = "/logisticService";
        if (navigate.includes(substr) || navigate.includes(substr1)) {
            return true
        } else {
            return false
        }
    }


    return (
        <>
            <ListItemButton
                ref={blockRef}
                sx={{
                    textTransform: "none",
                }}
                key={uuidv4()}
                component={Button}
                onClick={() => showNavLogisticService()}
                className={
                    (checkedLogisticService()) ? "navigateButton" : null
                }
            >
                <ListItemIcon
                    sx={{
                        color:
                            (checkedLogisticService())
                                ? "#ffffff"
                                : "rgba(58, 53, 65, 0.87)",
                    }}
                >
                    {nav.icon}
                </ListItemIcon>
                <ListItemText primary={nav.fulName}/>
            </ListItemButton>
            {
                showSubRightMenu &&
                <PopupSideMenuLogistic
                    onClose={() => {
                        savpMenuLogisticOption.changeDefaultStateRightMenu()
                        savpMenuLogisticOption.changeSubRightMenu(false)
                    }}
                />
            }

            

            {(selectLogisticMenuOption && accessLogistics) && logisticServiceUrl.map((navService) => {
                
                return <div key={uuidv4()} id={navService.menuItemID}>
                    <ListItemButton
                        sx={{
                            marginTop: "2px",
                            textTransform: "none",
                        }}
                        key={uuidv4()}
                        component={Button}
                        onClick={(e) => {
                            selectNavLogisticService(e, navService.fulName, navService.menuItemID, navService.subNav)
                        }}
                        className={
                            navService.select === true ? "navigateMainLogisticButton" : null
                        }
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: "36px",
                                color:
                                    navService.select === true
                                        ? "#ffffff"
                                        : "rgba(58, 53, 65, 0.87)",
                                svg: {
                                    width: "20px",
                                    height: "20px",
                                }
                            }}
                        >
                            {navService.icon}
                        </ListItemIcon>
                        <ListItemText
                            disableTypography={true}
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "normal",
                            }}
                            primary={navService.fulName}/>
                    </ListItemButton>
                </div>
            })}
        </>
    );
});

export default memo(ContentMenuLogisticService);