import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import style from './RouteDisplayMapPage.module.css'
import mmrgl from "mmr-gl";
import {ReactComponent as SettingMenuIcons} from "../../imges/SettingMenuIcons.svg";
import {ReactComponent as ImgNoneData} from '../../imges/noneData.svg';
import ReactDOM from 'react-dom/client';
import DateSelectionField from "../../inputs/logisticService/DatePicker/DatePicker";
import {observable} from "mobx";
import {FixedSizeList as List} from "react-window";
import {driver} from "driver.js";
import {observer} from "mobx-react-lite";
import routeOnMap from "../../store/transportationLogisticsService/pageRouteOnMap/routeOnMap";
import {v4 as uuidv4} from "uuid";
import RouteReadyDocument
    from "../../components/transportationLogisticsService/RouteDisplayMapPageComponents/RouteReadyDocument/RouteReadyDocument";
import MapMarketRoute from "../../components/transportationLogisticsService/MapMarketRoute/MapMarketRoute";
import MapPopupRouteInfo from "../../components/transportationLogisticsService/MapPopupRouteInfo/MapPopupRouteInfo";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import GlobalContext from "../../context/GlobalContext";
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import {Toaster} from "react-hot-toast";
import optimizationRouteSheetStore
    from "../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import globalState from "../../store/transportationLogisticsService/globalState";
import RequestLimitLogistics from '../../components/RequestLimitLogistics/RequestLimitLogistics';

const RouteDisplayMapPage = observer(() => {
    const {routeReadyDocument} = routeOnMap
    const [firstLoad, setFirstLoad] = useState(true);
    const [localStatePage] = useState(() =>
        observable({
            faqShowMainPage: false,
            searchResults: routeReadyDocument,
            valueSearch: "",
            markersList: [],
            showFaq(value) {
                this.faqShowMainPage = value
            },
            handleSearch(searchTerm) {
                this.valueSearch = searchTerm;
                this.searchResults = routeReadyDocument.filter(doc => doc.document_number.toLowerCase().includes(searchTerm.toLowerCase()));
            }
        })
    )
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const [isHidden, setIsHidden] = useState(false);
    const mapRef = useRef(null);

    // Отображение FAQ навигации по разделу
    useEffect(() => {
        if (localStatePage.faqShowMainPage) {
            localStatePage.showFaq(false)
            const driverObj = driver({
                showProgress: true,
                progressText: `{{current}} из {{total}}`,
                nextBtnText: 'Далее',
                prevBtnText: 'Назад',
                doneBtnText: 'Закрыть',
                steps: [
                    {
                        element: `#returnToLastPage_RouteDisplayMap`,
                        popover: {
                            title: 'Вернуться на страницу маршрутизации',
                            description: 'Кнопка для перехода на страницу маршрутизации.',
                            position: 'bottom'
                        }
                    },
                    {
                        element: `#selectDateDocument_RouteDisplayMap`,
                        popover: {
                            title: 'Дата доставки',
                            description: 'Поле в котором указывается дата доставки, для отображения документов с готовыми маршрутами.',
                            position: 'bottom'
                        }
                    },
                    {
                        element: `#showRouteDocuments_RouteDisplayMap`,
                        popover: {
                            title: 'Показать маршруты',
                            description: 'После нажатия на кнопку, в табличной части меню отобразятся документы с готовыми маршрутами.',
                            position: 'bottom'
                        }
                    },
                    {
                        element: `#searchInputNumberDocument_RouteDisplayMap`,
                        popover: {
                            title: 'Поиск документа по номеру',
                            description: 'В данном поле вводится номер документа, для поиска из списка. Нажмите "Очистить", чтобы отменить поиск.',
                            position: 'bottom'
                        }
                    },
                    {
                        element: `#clearSearchInputNumberDocument_RouteDisplayMap`,
                        popover: {
                            title: 'Очистка поиска',
                            description: 'После нажатия на кнопку, очищается поисковая строка',
                            position: 'bottom'
                        }
                    },
                    {
                        element: `#documentList_RouteDisplayMap`,
                        popover: {
                            title: 'Документы с готовыми маршрутами',
                            description: 'Список состоящий из документов по которым сформирован маршрут. Нажмите на документ из списка, чтобы отобразить его маршрут на карте.',
                            position: 'bottom'
                        }
                    },
                    {
                        element: `#map`,
                        popover: {
                            title: 'Карта',
                            description: 'Карта, на которой отображается маршрут по выбранному документу.',
                            position: 'bottom'
                        }
                    },
                    {
                        element: `#showMenu_RouteDisplayMap`,
                        popover: {
                            title: 'Скрыть/показать меню',
                            description: 'По нажатию на кнопку, скрывается или отображается меню со списком документов.',
                            position: 'bottom'
                        }
                    }
                ]
            });

            driverObj.drive();
        }
    }, [localStatePage.faqShowMainPage])
    const [tarif, setTarif] = useState(true);

    // Инициализация карты на странице
    useEffect(() => {
        async function initMap() {
            try {
                const resultCheck = await globalState.checkCountOrTariff("mapСount", authTokens, setUser, setAuthTokens, logoutUser);
                setTarif(resultCheck.status === 200 ? true : false)

                if (resultCheck.status === 200) {
                    mmrgl.accessToken = process.env.REACT_APP_ACCES_TOKEN_FOR_VKMAP;

                    const map = new mmrgl.Map({
                        container: 'map',
                        zoom: 12,
                        center: [34.11823, 44.94554],
                        style: 'mmr://api/styles/main_style.json',
                        hash: false
                    });

                    const scale = new mmrgl.ScaleControl({
                        maxWidth: 180,
                        unit: 'imperial',
                    });
                    map.addControl(scale);
                    scale.setUnit('metric');

                    const nav = new mmrgl.NavigationControl();
                    map.addControl(nav, 'bottom-right');

                    mapRef.current = map;
                }
            } catch (e) {
                console.error(e)
            }
        }

        initMap();

        // Функция очистки для удаления карты при размонтировании компонента
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);


    /**
     * Функция showSelectRouteToMap для отображения выбранного маршрута на карте
     * @param {number} idDocument - идентификатор документа
     */
    const showSelectRouteToMap = (idDocument = 1) => {
        const map = mapRef.current;

        // Очищаем карту перед отображением нового маршрута
        clearMap();

        routeOnMap.createRouteCoordinatesArray(idDocument);
        routeOnMap.transformCoordinates(idDocument);

        // Ваши тестовые координаты и точки для отображения на карте
        const coordinatesArray = routeOnMap.routeLineCoordinates;
        const arrayPointToMap = routeOnMap.pointToMap.slice(1, -1);
        // Дебажим нахуй
        const arrayPointStartToMap = [routeOnMap.pointToMap[0]];
        const optimizerArrayPoint = routeOnMap.orderCoordsOptimizer(idDocument, arrayPointToMap)
        console.log(optimizerArrayPoint)
        if (map) {


            // Выводим маркеры точек на карту
            optimizerArrayPoint.map((points, index) => {

                const markerElement = document.createElement('div');
                const root = ReactDOM.createRoot(markerElement);
                root.render(<MapMarketRoute idDoc={points.index}/>);

                // Создаем элемент для Popup
                const popupElement = document.createElement('div');
                const popupRoot = ReactDOM.createRoot(popupElement);

                // console.log("Содержимое заказа ",  orderInfoObj?.order_number)
                popupRoot.render(<MapPopupRouteInfo orderDoc={points.list}/>);

                let popup = new mmrgl.Popup({
                    anchor: "bottom",
                    maxWidth: "none",
                    className: style.mainPopupBlock
                }).setDOMContent(popupElement);

                localStatePage.markersList.push(new mmrgl.Marker({
                    element: markerElement,
                }).setLngLat(points.coords).setPopup(popup).addTo(map))
            });


            // Проверяем, существует ли уже источник данных с таким именем
            if (!map.getSource(`userMultiLineString_${idDocument}`)) {
                map.addSource(`userMultiLineString_${idDocument}`, {
                    type: 'geojson',
                    data: {
                        type: "FeatureCollection",
                        features: []
                    }
                });
            }

            if (!map.getSource(`userPointStart_${idDocument}`)) {
                map.addSource(`userPointStart_${idDocument}`, {
                    type: 'geojson',
                    data: {
                        type: "FeatureCollection",
                        features: []
                    }
                });
            }

            // Проверяем, существует ли уже слой с таким именем
            if (!map.getLayer(`userMultiLineString_${idDocument}`)) {
                // Добавляем слой линии маршрута
                map.addLayer({
                    'id': `userMultiLineString_${idDocument}`,
                    'type': 'line',
                    'source': `userMultiLineString_${idDocument}`,
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': '#2c7ae1',
                        'line-width': 2
                    }
                });
            }

            if (!map.getLayer(`userPointStart_${idDocument}`)) {
                // Загружаем изображение маркера
                map.loadImage(
                    'https://i.postimg.cc/85y6b6ks/warehouse-svgrepo-com-1.png',
                    function (error, image) {
                        if (error) throw error;
                        map.addImage('custom_pin_start', image);
                        // Добавляем слой маркеров
                        map.addLayer({
                            'id': `userPointStart_${idDocument}`,
                            'type': 'symbol',
                            'source': `userPointStart_${idDocument}`,
                            "layout": {
                                "icon-image": 'custom_pin_start',
                                "icon-size": 1,
                                // "text-field": "{id}", // Отображаем номера точек
                                // "icon-text-fit-padding": [2, 2, 2, 2], // Отступы вокруг текста
                                // "text-size": 12, // Размер текста
                            },
                            'paint': {
                                'text-color': 'white'
                            }
                        });
                    }
                );
            }

            // Формируем данные для слоя линии маршрута
            const userMultiLineData = {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    geometry: {
                        type: "LineString",
                        coordinates: coordinatesArray
                    }
                }]
            };

            const userPointDataStart = {
                type: "FeatureCollection",
                features: arrayPointStartToMap.map((coords, index) => ({
                    type: "Feature",
                    properties: {
                        id: index + 1 // Используем индекс как ID
                    },
                    geometry: {
                        type: "Point",
                        coordinates: coords
                    }
                }))
            };

            // Устанавливаем данные для источника данных линии маршрута
            const userMultiLineSource = mapRef.current.getSource(`userMultiLineString_${idDocument}`);
            if (userMultiLineSource) {
                userMultiLineSource.setData(userMultiLineData);

                // Фокусируем карту на маршруте с отступами
                const focusZona = true;
                if (focusZona && userMultiLineData.features[0].geometry.coordinates[0].length > 0) {
                    map.fitBounds(coordinatesArray, {zoom: 12});
                }
            }

            const userPointStartSource = mapRef.current.getSource(`userPointStart_${idDocument}`);
            if (userPointStartSource) {
                userPointStartSource.setData(userPointDataStart);
            }
        }
    };

    /**
     * Очищает все слои карты, связанные с точками пользователя и мультилиниями,
     * а также сбрасывает состояние списка зон доставки.
     */

    function clearMarkers() {
        localStatePage.markersList.forEach(marker => {
            marker.remove();
        })
    }

    const uploadReadyRouteLists = async (newDate) => {
        if (newDate !== undefined && newDate !== routeOnMap.statePage.select_route_date) {
            routeOnMap.changeDate(newDate);
            await routeOnMap.upLoadDocumentRouteSheet(authTokens, setUser, setAuthTokens, logoutUser);
        } else if (firstLoad && newDate !== undefined && newDate !== null && newDate === routeOnMap.statePage.select_route_date) {
            routeOnMap.changeDate(newDate);
            await routeOnMap.upLoadDocumentRouteSheet(authTokens, setUser, setAuthTokens, logoutUser);
            setFirstLoad(false);
        }
    }

    function clearLayers() {
        const map = mapRef.current;
        const mapStyle = map.getStyle();
        const layersToRemove = mapStyle.layers
            .filter(layer => {
                return layer.id.startsWith("userPoint_") || layer.id.startsWith("userMultiLineString_") || layer.id.startsWith("userPointStart_");
            })
            .map(layer => layer.id);

        layersToRemove.forEach(layerId => {
            map.removeLayer(layerId);
        });
    }

    const clearMap = () => {
        clearMarkers();
        clearLayers();
    }

    // Скрыть/показать меню с документами
    const handleHide = () => {
        setIsHidden(!isHidden);
    };

    return (
        <>
            <Toaster
                containerStyle={{
                    position: 'fixed',
                    top: '80px'
                }}
            />
            <div className={style.viewRouteMapPage}>

                <MainMenuLogisticsService namePage={"Готовые маршруты на карте"}/>


                <div className={style.viewRouteMapContent}>
                    <div
                        className={(isHidden) ? `${style.viewRouteMapNavigate} ${style.hidden}` : style.viewRouteMapNavigate}
                    >

                        <div className={style.viewRouteHeaderMenu}>
                            Маршруты
                        </div>

                        <div className={style.selectDataDocumentBlock}>
                            <div className={style.selectDataDocumentContent}>
                        <span>
                            <div className={style.selectDataDocumentText}>Маршруты за:</div>
                            <div
                                id={"selectDateDocument_RouteDisplayMap"}
                            >
                                <DateSelectionField
                                    value={routeOnMap.statePage.select_route_date}
                                    onChange={(newDate) => uploadReadyRouteLists(newDate)}
                                />
                            </div>
                        </span>
                            </div>
                        </div>

                        <div
                            id={"searchInputNumberDocument_RouteDisplayMap"}
                            className={style.inputSearchNumberDocument}>
                            <input
                                type="text"
                                value={localStatePage.valueSearch}
                                onChange={(e) => localStatePage.handleSearch(e.target.value)}
                                placeholder="Поиск по номеру документа"
                            />
                            <span
                                onClick={
                                    () => {
                                        localStatePage.handleSearch("")
                                    }}
                                id={"clearSearchInputNumberDocument_RouteDisplayMap"}
                            >Очистить</span>
                        </div>

                        <div className={style.routeListHeader}>
                            <div>
                                <div>Номер документа</div>
                                <div>Номер ТС</div>
                            </div>
                            <div>
                                <div>Время выезда</div>
                                <div>Время прибытия</div>
                            </div>
                            <div>
                                <div>Километраж</div>
                                <div>Кол-во точек</div>
                            </div>
                        </div>
                        <div id={"documentList_RouteDisplayMap"}>

                            {
                                (routeReadyDocument.length === 0) ?
                                    <>
                                        <div className={style.noDataImg}>
                                            <ImgNoneData/>
                                            <div>Нет маршрутов за выбранную дату</div>
                                        </div>
                                    </>
                                    :
                                    <List
                                        height={568}
                                        itemCount={routeReadyDocument.length}
                                        itemSize={71}
                                        width={'100%'}
                                    >
                                        {({index, style}) => (
                                            <div style={style}>
                                                {
                                                    <RouteReadyDocument
                                                        document={routeReadyDocument[index]}
                                                        showSelectRouteToMap={showSelectRouteToMap}
                                                        key={uuidv4()}
                                                    />
                                                }
                                                {/*{localStatePage.searchResults[index] && (*/}
                                                {/*    <RouteReadyDocument*/}
                                                {/*        document={localStatePage.searchResults[index]}*/}
                                                {/*        showSelectRouteToMap={showSelectRouteToMap}*/}
                                                {/*        key={uuidv4()}*/}
                                                {/*    />*/}
                                                {/*)}*/}
                                            </div>
                                        )}
                                    </List>
                            }
                        </div>
                    </div>
                    {tarif ?
                    <div className={style.viewRouteMapBlock}>
                        <div
                            id="map"
                            style={{width: '100%', height: '95vh'}}
                        >
                           
                            
                        </div>
                        <div
                            id={"showMenu_RouteDisplayMap"}
                            onClick={handleHide}
                            className={style.hiddenMenu}
                        >
                            <SettingMenuIcons/>
                        </div>
                           </div>
                        :
                        <div style={{backgroundColor: "#f3f4f9", width: "100%", height: "100%", border: "1px solid #fcfcfcfc"}}>
                        <RequestLimitLogistics/>
                        </div>
                        }
                 
                    

                    {/*<div*/}
                    {/*    className={style.btnFaq}*/}
                    {/*    onClick={() => localStatePage.showFaq(!localStatePage.faqShow)}*/}
                    {/*>*/}
                    {/*    FAQ*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
});


export default RouteDisplayMapPage;