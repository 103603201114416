import React from 'react';
import style from './ModalRouteSheetTableHead.module.css'


const ModalRouteSheetTableHead = () => {
    return (
        <div className={style.tableHeader}>
            <div>Грузоподъёмность (т)</div>
            <div>Транспорт (Наименование)</div>
            <div>Вес заказов (кг)</div>
            <div>Заказов</div>
            <div>Водитель</div>
            <div>Свободный вес (кг)</div>
        </div>
    );
};

export default ModalRouteSheetTableHead;