import React, {useCallback, useEffect, useState} from 'react';
import style from './DeliveryVehiclesEditForm.module.css'
import Skeleton from '@mui/material/Skeleton';
import DeliveryVehiclesEditFormSkeleton from './DeliveryVehiclesEditForm.skeleton';
import pageDeliveryVehiclesStore
    from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
import {observer} from "mobx-react-lite";
import debounce from "lodash/debounce";
import {useNavigate} from "react-router-dom";

const DeliveryVehiclesEditForm = observer(() => {
    const navigate = useNavigate();
    const {loading} = pageDeliveryVehiclesStore.editPageState;
    const {license_plate, vehicle_brand,appellation, tonnage} = pageDeliveryVehiclesStore.editVehicleData;
    const [appellationValue, setAppellationValue] = useState('');
    const [licensePlateValue, setLicensePlateValue] = useState('');
    const [tonnageValue, setTonnageValue] = useState();
    const [vehicleBrandValue, setVehicleBrandValue] = useState('');

    const updateTemplate = useCallback(
        debounce((nameValue, newValue) => {
            pageDeliveryVehiclesStore.changeNewInputValue(nameValue, newValue, "editVehicleData");
        }, 200), // Задержка в миллисекундах
        []
    );

    useEffect(() => {
        if (!loading){
            setAppellationValue(appellation);
            setLicensePlateValue(license_plate);
            setTonnageValue(tonnage);
            setVehicleBrandValue(vehicle_brand);
        }
    }, [loading]);

    const changeTemplateName = (nameValue, newValue) => {

        switch (nameValue) {
            case "appellation":
                setAppellationValue(newValue);
                break;
            case "license_plate":
                setLicensePlateValue(newValue);
                break;
            case  "tonnage":
                setTonnageValue(newValue);
                break;
            case "vehicle_brand":
                setVehicleBrandValue(newValue);
                break
            default:
                return
        }

        updateTemplate(nameValue, newValue)
    }


    return (
        <>
            {
                (loading)? <DeliveryVehiclesEditFormSkeleton/>:
                    <>
                        <div className={style.createForm}>
                            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                                <span>Наименование:</span>
                                <input type="text" required={true} value={appellationValue}
                                       maxLength={100}
                                       placeholder="Наименование..."
                                       onChange={(e) => changeTemplateName("appellation", e.target.value)}/>
                            </div>

                            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                                <span>Гос. номер:</span>
                                <input type="text" required={true} value={licensePlateValue}
                                       maxLength={100}
                                       placeholder="Гос. номер..."
                                       onChange={(e) => changeTemplateName("license_plate", e.target.value)}/>
                            </div>

                            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                                <span>Марка автомобиля:</span>
                                <input type="text" required={false} value={vehicleBrandValue}
                                       maxLength={100}
                                       placeholder="Марка автомобиля..."
                                       onChange={(e) => changeTemplateName("vehicle_brand", e.target.value)}/>
                            </div>

                            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                                <span>Грузоподъемность (тонны):</span>
                                <input type="number" required={true} value={String(tonnageValue)}
                                       placeholder="Грузоподъемность..."
                                       onChange={(e) => changeTemplateName("tonnage", e.target.value)}/>
                            </div>
                        </div>

                        <div className={style.actionTemplateBtns}>
                            <input
                                type="submit"
                                value="Сохранить"
                                className={style.createTemplateBtn}
                            />
                            <input
                                type="button"
                                value="Отменить"
                                className={style.cancelCreateTemplateBtn}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                    </>
            }
        </>
    )
        ;
});

export default DeliveryVehiclesEditForm;