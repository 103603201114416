import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import style from './InitialPointEditingPage.module.css';
import mmrgl from "mmr-gl";
import editInitialPoints
    from "../../store/transportationLogisticsService/pageEditInitialPoints/editInitialPoints";
import {observer} from "mobx-react-lite";
import ReactDOM from "react-dom/client";
import MapMarketPoint from "../../components/transportationLogisticsService/MapMarketPoint/MapMarketPoint";
import InitialPointList
    from "../../components/transportationLogisticsService/InitialPointList/InitialPointList";
import InitialPointEdit
    from "../../components/transportationLogisticsService/InitialPointEdit/InitialPointEdit";
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import InitialPointCreate from "../../components/transportationLogisticsService/InitialPointCreate/InitialPointCreate";
import globalState from "../../store/transportationLogisticsService/globalState";
import GlobalContext from "../../context/GlobalContext";
import RequestLimitLogistics from '../../components/RequestLimitLogistics/RequestLimitLogistics';


const InitialPointEditingPage = observer(() => {
    const {markersList, statePage } = editInitialPoints;
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const mapRef = useRef(null);
    let clickHandler = (clickData) => {
        editInitialPoints.clickEditAddressInitPoint(clickData.lngLat);
    };
    
    // Инициализация карты на странице
    const [tarif, setTarif] = useState(true);

    useEffect(() => {
        async function initMap() {
            try {
                const resultCheck = await globalState.checkCountOrTariff("mapСount", authTokens, setUser, setAuthTokens, logoutUser);
                setTarif(resultCheck.status === 200 ? true : false)

                if (resultCheck.status === 200) {
                    mmrgl.accessToken = process.env.REACT_APP_ACCES_TOKEN_FOR_VKMAP;
                    // mmrgl.accessToken = "";

                    const map = new mmrgl.Map({
                        container: 'map',
                        zoom: 12,
                        center: [34.11823, 44.94554],
                        style: 'mmr://api/styles/main_style.json',
                        hash: false,
                        doubleClickZoom: false
                    });

                    const scale = new mmrgl.ScaleControl({
                        maxWidth: 180,
                        unit: 'imperial',
                    });

                    map.addControl(scale);
                    scale.setUnit('metric');

                    const nav = new mmrgl.NavigationControl();
                    map.addControl(nav, 'bottom-right');

                    mapRef.current = map;
                }
            } catch (e) {
                console.error(e)
            }
        }

        initMap();

        // Функция очистки для удаления карты при размонтировании компонента
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    // Функция показывает на карте выбранную начальную точку маршрута
    const showSelectInitPointToMap = (addressData) => {
        // Очищаем карту от предыдущих маркеров
        editInitialPoints.clearMarkers()

        // Проверяем наличие координат
        if (addressData.lat === null || addressData.lon === null) {
            // !statePage.setting && editInitialPoints.showAlertBlock("warning",
            //     "Отсутствуют координаты! Добавьте их через панель редактирования.")
            return
        }

        // Преобразуем координаты в массив
        const coordsArray = [Number(addressData.lon), Number(addressData.lat)];

        // Получаем объект карты
        const map = mapRef.current;

        // Если карта существует
        if (map) {

            // **Создание маркера**
            // Создаем элемент DOM для маркера
            const markerElement = document.createElement('div');
            // Рендерим компонент MapMarketPoint в элемент DOM
            const root = ReactDOM.createRoot(markerElement);
            root.render(<MapMarketPoint idDoc={1}/>);

            // Добавляем маркер на карту
            editInitialPoints.addNewMarkerToMap(new mmrgl.Marker({
                element: markerElement,
            }).setLngLat(coordsArray).addTo(map))

            // **Определение области отображения**
            // Формируем массив с координатами для фокусировки
            const coordsFocusToMarker = [coordsArray, coordsArray]

            // Центрируем карту на маркере и устанавливаем масштаб
            map.fitBounds(coordsFocusToMarker, {zoom: 18});
        }
    };

    /**
     * Хук useMemo для управления перетаскиванием маркера на основе значения 'setting' в состоянии страницы.
     *
     * @param {Object} statePage - Объект текущего состояния страницы.
     * @param {boolean} statePage.setting - Булево значение, указывающее, должен ли маркер быть перетаскиваемым или нет.
     *
     * @returns {void}
     */
    useMemo(() => {
        const map = mapRef.current;
        if (statePage.setting === true || statePage.create === true) {
                const typeData = (statePage.setting === true)? "editDataInitialPoint":"createDataInitialPoint";
            if (map && markersList.length === 0) {
                map.on("dblclick", ({lngLat}) => {
                    editInitialPoints.showNewInitPointToMap(lngLat, mapRef, typeData)
                    editInitialPoints.createNewInitPoint(lngLat, typeData)
                })
            }
            if (map && markersList.length > 0) {

                markersList[0].setDraggable(true).on("dragend", (dragData) => {
                    editInitialPoints.dragEditInitPoint(dragData.target, typeData)
                });
                map.on("dblclick", clickHandler)
            }
        } else if (statePage.setting === false || statePage.create === false) {
            if (map && markersList.length > 0) {
                map._listeners = null
                editInitialPoints.clearMarkers()
            }
        }
    }, [statePage.setting, statePage.create])

    return (
        <div className={style.initialPointEditingPage}>

            {/*<>*/}
            {/*    <Snackbar sx={{position: 'absolute'}} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}*/}
            {/*              open={infoAlertState.show_alert}*/}
            {/*              autoHideDuration={6000}>*/}
            {/*        <Alert*/}
            {/*            severity={infoAlertState.type}*/}
            {/*            variant="filled"*/}
            {/*            sx={{width: '100%', zIndex: '99'}}*/}
            {/*        >*/}
            {/*            {infoAlertState.alert_text}*/}
            {/*        </Alert>*/}
            {/*    </Snackbar>*/}
            {/*</>*/}

            <MainMenuLogisticsService namePage={"Редактирование координат начальной точки"}/>

            <div className={style.initPointEditingContent}>
                <div
                    className={style.initPointEditingNavigate}
                >
                    {

                        (statePage.setting)
                            ?
                            <InitialPointEdit mapRef={mapRef}/>
                            : (statePage.create) ?
                                <InitialPointCreate mapRef={mapRef}/>
                                :
                                <InitialPointList showSelectInitPointToMap={showSelectInitPointToMap}/>
                    }
                </div>

                <div className={style.viewPointMapBlock}>
                {tarif ?

                    <div
                        id="map"
                        style={{width: '100%', height: '94vh'}}
                    >
                    </div>
                     : 
                     <div style={{backgroundColor: "#f3f4f9", width: "100%", height: "100%", border: "1px solid #fcfcfcfc"}}>
 
                     <RequestLimitLogistics/>
                     </div>}
                </div>

                {/*<div*/}
                {/*    className={style.btnFaq}*/}
                {/*    onClick={() => editInitialPoints.showFaqBlock(true)}*/}
                {/*>*/}
                {/*    FAQ*/}
                {/*</div>*/}
            </div>
        </div>
    );
});

export default InitialPointEditingPage;