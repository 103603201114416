import React, {memo, useContext} from 'react';
import style from './TemplateListItem.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {Link, useParams} from "react-router-dom";
import {ReactComponent as SettingIcon} from '../../../../imges/SettingIcon.svg';
import GlobalContext from "../../../../context/GlobalContext";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";

const TemplateListItem = ({tempData}) => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const params = useParams();
    const {templateText, typeStatus, checked} = tempData

    const deleteTemplateById = async (idTemplate) => {
        try {
            await deliveredStatusList.deleteTemplateById(idTemplate, authTokens, setUser, setAuthTokens, logoutUser);
            await deliveredStatusList.getTemplateByTypeStatus(params.typeStatus,authTokens, setUser, setAuthTokens, logoutUser)
        } catch (e) {

        }
    }

    const changeSelectedTemp = (idTemplate, value) => {
        deliveredStatusList.changeSelectedTemplateById(idTemplate, value);
    }

    return (
        <div className={style.templateListItem}>
            <div
                onClick={() => {
                    changeSelectedTemp(tempData.id, !checked);
                }}
            >
                <input
                    type="checkbox"
                    className={style.selectAllTemplate}
                    id="selectTemplate"
                    name="selectTemplate"
                    checked={checked}
                    value={checked}
                    onChange={() =>{}}
                />
                <label htmlFor="selectTemplate"></label>
            </div>
            <div>{templateText}</div>
            <div>{typeStatus?.status_text}</div>
            <div><DeleteIcon onClick={() => deleteTemplateById(tempData.id)}/></div>
            <div><Link to={`/logisticService/deliveredStatusList/editTemplate/${tempData.id}`}><SettingIcon/></Link>
            </div>
        </div>
    );
};

export default memo(TemplateListItem);