import React, {memo} from 'react';
import style from './DeliversRoutesList.module.css';
import {v4 as uuidv4} from "uuid";
import DeliversRoutesItemList from "../DeliversRoutesItemList/DeliversRoutesItemList";
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import {observer} from "mobx-react-lite";
import {ReactComponent as ImgNoneData} from '../../../../imges/noneData.svg';
import DeliversRoutesListPagination from "../DeliversRoutesListPagination/DeliversRoutesListPagination";
import DeliversRoutesListHeader from "../DeliversRoutesListHeader/DeliversRoutesListHeader";


const DeliversRoutesList = observer(() => {
    const {deliversList, copyDeliversList} = deliversRoutes;

    const renderRoutes = () => {
        if (deliversList.length > 0) {
            return copyDeliversList.map(objRoute => (
                <DeliversRoutesItemList key={uuidv4()} routeData={objRoute} />
            ));
        }
        return (
            <div className={style.noDataImg}>
                <ImgNoneData />
                <div>Нет маршрутов за выбранную дату</div>
            </div>
        );
    };

    return (
        <div className={style.deliversTable}>
            <div className={style.deliversHeader}>Маршруты</div>
            <DeliversRoutesListPagination/>
            <div className={style.deliversTables}>
                <DeliversRoutesListHeader/>
                {renderRoutes()}
            </div>
        </div>
    );
});

export default memo(DeliversRoutesList);