import React, {useState, memo, useEffect} from 'react';
import {ReactComponent as DeleteIcon} from "../../../imges/DeleteIcon.svg";
import {ReactComponent as TransferIcon} from '../../../imges/TransferIcon.svg';
import style from './DocumentOrderCustomer.module.css'
import PopupRouting from "../../../popups/logisticService/ModalMaster/ModalMaster";
import optimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import { Tooltip } from 'react-tooltip';
import {ReactComponent as InfoIcon} from '../../../imges/InfoIcon.svg';
import {Link, useNavigate, useParams} from "react-router-dom";

const DocumentOrderCustomer = ({order, document}) => {
    const {setting_document_id, checked_all_document_order} = optimizationRouteSheetStore.pageState;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();
    const {docId} = useParams();



    const openPopup = () => {
        // setScrollPosition(window.pageYOffset)
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };


    return (
        <div className={style.routeListSelectDocument}>
            {
                (!order.coordinates_given) ?
                    <div className={style.buttonInfo}>
                        <div data-tooltip-id="my-tooltip" className="my-anchor-element">
                            <Link to={`/logisticService/addressCoordinateEditing/editAddress/${order?.address_id}`}>
                                <InfoIcon/>
                            </Link>
                        </div>
                        <Tooltip
                            id="my-tooltip"
                            content="По данному адресу отсутствуют координаты."
                        />
                    </div>
                    :
                    <div>
                        <input type="checkbox"
                               className={style.selectDocumentML}
                               id={`selectDocumentOrder_${order.order_number}`}
                               name="selectDocumentML"
                               checked={order.checked}
                               value={order.checked}
                               onChange={() => {
                                   // optimizationRouteSheetStore.selectDocumentOrder(!order.checked, setting_document_id)
                               }}
                        />
                        <label htmlFor={`selectDocumentOrder_${order.order_number}`}></label>
                    </div>
            }

            <div>{order.order_number}</div>
            <div title={order.order_client}>{order.order_client}</div>
            <div title={order.order_adress_delivery}>{order.order_adress_delivery}</div>
            <div>{order.vehicle_tonnage}</div>
            <div>
                <div
                    className={style.selectedDocument_delete}
                    // onClick={() => optimizationRouteSheetStore.deleteSelectOrderDocument(order.order_id, document.document_id)}
                ><DeleteIcon/></div>
            </div>
            <div>
                <div className={style.selectedDocument_transfer} onClick={openPopup}><TransferIcon/></div>
            </div>
            {/*<ModalMaster isOpen={isPopupOpen} onClose={closePopup} scrollPosition={scrollPosition}>*/}
            {/*</ModalMaster>*/}

        </div>
    );
};

export default memo(DocumentOrderCustomer);