import React, {useCallback, useState} from 'react';
import style from './TimeInput.module.css'
import InputMask from "react-input-mask";


const TimeInput = ({value, onChange, onBlur}) => {

    const handleInputChange = useCallback((event) => {
        let input = event.target;
        let newValue = input.value;

        // Разделяем значение на часы и минуты
        let [hours = null, minutes = null] = newValue.split(':');

        // Проверяем каждый символ
        if (hours && parseInt(hours) > 24) {
            hours = '23';
        }
        if (minutes && parseInt(minutes) > 59) {
            minutes = '59';
        }

        // Если введены только часы, добавляем ":00"
        if (hours && minutes === '  ') {
            minutes = '00';
        }
        if (minutes && hours === '  ') {
            hours = '00';
        }

        // Если введено только часть часов, добавляем 0
        if (hours[0] === " ") {
            hours = `0${hours[1]}`;
        } else if (hours[1] === " "){
            hours = `${hours[0]}0`;
        }
        // Если введено только часть минут, добавляем 0
        if (minutes[0] === " ") {
            minutes = `0${minutes[1]}`;
        } else if (minutes[1] === " "){
            minutes = `${minutes[0]}0`;
        }

        if (hours === '  ' && minutes === '  '){
            minutes = '00';
            hours = '00';
        }

        // Формируем итоговое значение
        newValue = hours + (minutes ? ':' + minutes : '');

        onChange(newValue);
    }, []);


    return (
        <div className={style.selectTimeValue}>
            <InputMask
                mask="99:99"
                maskChar=" "
                value={value}
                placeholder="00:00"
                onChange={(event) => handleInputChange(event)}
                onBlur={onBlur}
            />
        </div>
    );
};

export default TimeInput;