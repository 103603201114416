import React, {useCallback, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import debounce from "lodash/debounce";

const routeParameterModalHOC = (WrappedComponent, nameChange, typeAction) => {
    return observer(() => {
        const axiosInstance = AxiosConfig_LogisticService();
        const [nameArrayData, setNameArrayData] = useState("")

        useEffect(() => {

            const getInitData = async () => {
                switch (nameChange) {
                    case "changeInitPoint":
                        await createRouteSheetsStore.getInitPointList(axiosInstance);
                        setNameArrayData("modalInitPointList")
                        break;
                    case "changeDriver":
                        await createRouteSheetsStore.getDrivers(axiosInstance);
                        setNameArrayData("modalDriverList")
                        break;
                    case "changeVehicle":
                        await createRouteSheetsStore.getAllVehicle(axiosInstance);
                        setNameArrayData("modalVehicleList")
                        break;
                    case "orderTransfer":
                        setNameArrayData("routeSheetsList")
                        break
                    default:
                        break;
                }
            }

            getInitData();

        }, []);

        const updateSearchValue = useCallback(
            debounce((value) => {
                switch (nameChange) {
                    case "changeInitPoint":
                        createRouteSheetsStore.changeSearchText(value, "modalInitPointList")
                        break;
                    case "changeDriver":
                        createRouteSheetsStore.changeSearchText(value, "modalDriverList")
                        break;
                    case "changeVehicle":
                        createRouteSheetsStore.changeSearchText(value, "modalVehicleList")
                        break;
                    default:
                        break;
                }
            }, 200), // Задержка в миллисекундах
            []
        );

        return ( <WrappedComponent
                    newSearchValue={(newValue) => updateSearchValue(newValue)}
                    nameArrayData={nameArrayData}
                    nameChange={nameChange}
                    typeAction={typeAction}
                />
           )
    });
};

export default routeParameterModalHOC;