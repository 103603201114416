import CloseIcon from "@mui/icons-material/Close";
import ZoomOutMapIcon from "@mui/icons-material/Map";
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { CardHeader, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MuiDivider from "@mui/material/Divider";
import List from "@mui/material/List";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useContext, useState } from "react";
import { useAxiosAPI } from "../../../apiRequest/apiRequest";
import { default as GlobalContext, default as globalContext } from "../../../context/GlobalContext";
import {
  generateColorFromText,
  generateLocalColorFromText,
} from "../../../helpers/helpers";
import ChartTypeButton from "../../ChartTypeButton/ChartTypeButton";
import DistributoeMapList from "../DistributoeMapList/DistributoeMapList";
import DistributorDiagram from "../DistributorDiagram/DistributorDiagram";
import DistributorsRating from "../DistributorsRating/DistributorsRating";
import { MapVK } from "../../MapVK/MapVK";
import "./DistributorRatingBox.css";
export default function DistributorRatingBox(props) {
  const style = {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "99%",
    height: "99%",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const { apiMapMarker } = useAxiosAPI();

  const [typeSheet, setTypeSheet] = useState(20);
  const {
    setMarker,
    open,
    setOpen,
    state,
    distributorStartDate,
    distributorFinishDate,
  } = useContext(GlobalContext);
  const distributors = state.distributors;
  const Divider = styled(MuiDivider)(({ theme }) => ({
    margin: theme.spacing(5, 0),
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      borderRight: "none",
      margin: theme.spacing(0, 5),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }));

  const [loadMap, setLoadMap] = useState(false);
  const [dataMap, setDataMap] = useState([]);

  React.useEffect(() => {
    setLoadMap(true);
    // Проверяем, есть ли данные в стейте для текущего props.id
    apiMapMarker()
      .then((resolve) => {
        setDataMap(resolve.data);
        setLoadMap(false);
      })
      .catch(() => {
        alert("Ошибка");
        setLoadMap(false);
      });
  }, [distributorStartDate, distributorFinishDate]);

  // Выделить регионы по сумме реализации
  const [amountRegion, setAmountRegion] = useState(false);
  // Общая сумма
  const totalSum = state.docsSum
    .reduce((accumulator, obj) => accumulator + obj.manSum, 0)
    .toLocaleString();
  // Названия
  const labels = state.docsSum.map((item) => item.distrName);
  // Доля в %
  const sum = state.docsSum.map((item) => parseInt(item.distrpercent));
  const retailOutletDistrpercent = state.docsSum.map((item) =>
    parseInt(item.retailOutletDistrpercent)
  );
  const nomDistrpercent = state.docsSum.map((item) =>
    parseInt(item.nomDistrpercent)
  );

  // Торговые точкиdistrpercent
  // const valuesArray = arr.map(obj => obj.value);
  const sumRetail = state.docsSum.map((item) => item.retailOutletCount);
  // Кол-во назвваний товаров
  const sumSku = state.docsSum.map((item) => parseInt(item.nomCount));
  // Цвета
  const colorsArray = generateColorFromText(distributors);
  // Параметр селектра
  const { age } = useContext(globalContext);
  const stateDistributors = [];
  // Название выделенного в диаграмме дистрибьютора
  const [selectedLabel, setSelectedLabel] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = (value) => () => {
    setOpen(false);
  };

  for (let i = 0; i < labels.length; i++) {
    const obj = {
      name: labels[i],
      sum: sum[i],
      sumRetail: sumRetail[i],
      sumSku: sumSku[i],
      color: colorsArray[i],
      retailOutletDistrpercent: retailOutletDistrpercent[i],
      nomDistrpercent: nomDistrpercent[i],
    };
    stateDistributors.push(obj);
  }

  const [checked, setChecked] = useState([]);

  // Разобрать код
  const handleToggle = (value) => () => {
    const isChecked = checked.some((item) => item.id === value.id);
    let newChecked;
    if (isChecked) {
      newChecked = checked.filter((item) => item.id !== value.id);
    } else {
      newChecked = [...checked, value];
    }
    setChecked(newChecked);
  };

  const handleToggleAll = (values, distributorColor) => () => {
    // Создайте новый массив на основе текущего состояния checked
    let newChecked = [...checked];
    let count = 0;

    const valuesWithColor = values.map((value) => ({
      ...value,
      color: generateLocalColorFromText(
        distributorColor
        ), 
        // Замените 'новый_цвет' на актуальное значение цвета
    }));

    // Проход по обновленному массиву
    valuesWithColor.forEach((value) => {
      const isChecked = newChecked.some((item) => item.id === value.id);

      if (isChecked) {
        // Если уже отмечен, уберите его из массива
        newChecked = newChecked.filter((item) => item.id !== value.id);
        count += 1;
      } else {
        // В противном случае добавьте его в массив
        newChecked.push(value);
      }
    });

    // Обновите состояние
    if (count == valuesWithColor.length || count == 0) {
      setChecked(newChecked);
    } else {
      valuesWithColor.forEach((value) => {
        // Если уже отмечен, уберите его из массива
        newChecked = newChecked.filter((item) => item.id == value.id);
      });
      // Обновите состояние
      setChecked(newChecked);
    }
  };

  const handleToggleAllCity = (values, distributorColor) => () => {
    // Создайте новый массив на основе текущего состояния checked
    let newChecked = [...checked];
    let count = 0;
    const valuesWithColor = values.districtsArr
      .map((value) =>
        value.retailOutletObjList.map((valueLocal) => ({
          ...valueLocal,
          color: generateLocalColorFromText(distributorColor),
        }))
      )
      .flat(); // Уменьшение вложенности массивов

    // Проход по обновленному массиву

    valuesWithColor.forEach((value) => {
      const isChecked = newChecked.some((item) => item.id === value.id);
      if (isChecked) {
        count += 1;
        // Если уже отмечен, уберите его из массива
        newChecked = newChecked.filter((item) => item.id !== value.id);
      } else {
        // В противном случае добавьте его в массив
        newChecked.push(value);
      }
    });

    if (count == valuesWithColor.length || count == 0) {
      setChecked(newChecked);
    } else {
      valuesWithColor.forEach((value) => {
        // Если уже отмечен, уберите его из массива
        newChecked = newChecked.filter((item) => item.id == value.id);
      });
      // Обновите состояние
      setChecked(newChecked);
    }
  };

  const filteredArray = checked.filter((obj) => obj.coordinates !== "null");

  function returnMap() {
    setMarker(filteredArray);
  }
  return (
    <>
      <Card className={"diagram-body-card"}>
          <CardHeader
            title={"Статистика"}
            titleTypographyProps={{
              variant: "h6",
              sx: {
                lineHeight: "2rem !important",
                letterSpacing: "0.15px !important",
              },
            }}
            action={
              <Box sx={{ display: "flex" }}>
                <ChartTypeButton />
              </Box>
            }
          />
          {stateDistributors.length > 2 
          ?
          <CardContent>
            <Box>
              <DistributorsRating
                selectedLabel={selectedLabel}
                arr={stateDistributors}
                age={age}
              />
            </Box>
          </CardContent>
          :
          <CardContent className="ifLessTwoStatistics">
          <NearbyErrorIcon sx={{fontSize: "110px"}} color="primary"/>
          <Typography className="ifLessTwoText">
          Доступно при наличии минимум двух дистрибьюторов
          </Typography>
          </CardContent>
          }
      </Card>


        <Card className={"diagramCard"}>

          <CardHeader
            title="Диаграмма"
            titleTypographyProps={{
              variant: "h6",
              sx: {
                lineHeight: "2rem !important",
                letterSpacing: "0.15px !important",
              },
            }}
          />
          {stateDistributors.length > 2
          ?
          <CardContent>
            <Box>
              <DistributorDiagram
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
                totalSum={totalSum}
                labels={labels}
                sum={sum}
                sumRetail={sumRetail}
                sumSku={sumSku}
                colorsArray={colorsArray}
                age={age}
              />
            </Box>
          </CardContent>
          :
           <CardContent className="ifLessTwoStatistics">
          <RunningWithErrorsIcon sx={{fontSize: "110px"}} color="primary"/>
          <Typography className="ifLessTwoText">
          Доступно при наличии минимум двух дистрибьюторов
          </Typography>
          </CardContent>
}
      </Card>

      <Card className={"mapListCard"}>
        <CardHeader
          titleTypographyProps={{
            variant: "h6",
            sx: {
              lineHeight: "2rem !important",
              letterSpacing: "0.15px !important",
            },
          }}
          action={
            <Button
              startIcon={<ZoomOutMapIcon />}
              variant="contained"
              onClick={handleOpen}
            >
              Торговые точки
            </Button>
          }
        />

        {/* <Typography sx={{textAlign: "center", lineHeight: "20"}}>123</Typography> */}
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <List
            dense
            sx={{ width: 300, bgcolor: "background.paper" }}
          >
            <Button sx={{ height: "5vh" }} onClick={handleClose()}>
              <CloseIcon />
            </Button>
            <Button onClick={returnMap}>Показать</Button>
            {/* <List sx={{ overflow: "auto", maxHeight: "90vh" }}> */}
            <DistributoeMapList
              amountRegion={amountRegion}
              setAmountRegion={setAmountRegion}
              docsSum={props.docsSum}
              docs={dataMap}
              loadMap={loadMap}
              handleToggle={handleToggle}
              handleToggleAll={handleToggleAll}
              handleToggleAllCity={handleToggleAllCity}
              checked={checked}
            />
            {/* </List> */}
          </List>
          <MapVK amountRegion={amountRegion} />
        </Box>
      </Modal>
    </>
  );
}
