import {useContext} from "react";
import GlobalContext from "../../context/GlobalContext";
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AdminCreateUserForm from '../../components/AdminCreateUserForm/AdminCreateUserForm'
import {Button} from "@mui/material";
import AdminUserTable from "../../components/AdminUserTable/AdminUserTable";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminUserPage = () => {
    const {logoutUser, user} = useContext(GlobalContext);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
<>
        
    <Box sx={{ width: '100%' }}>
    <Button sx={{margin: "20px"}} variant={"contained"} onClick={() => logoutUser()}>
           ВЫЙТИ
        </Button> 
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Производители" {...a11yProps(0)} />
                <Tab label="Дистрибьюторы" {...a11yProps(1)} />
                <Tab label="Добавить пользователя" {...a11yProps(2)} />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
           <AdminUserTable typeUser={1}/>
        </CustomTabPanel >
        <CustomTabPanel value={value} index={1}>
            <AdminUserTable typeUser={0}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <AdminCreateUserForm/>
        </CustomTabPanel>
    </Box>
</>
    )
}

export default AdminUserPage