import React, {useEffect} from 'react';
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import style from './InitialPointPopupItem.module.css';
import {Tooltip} from 'react-tooltip';
import {observer} from "mobx-react-lite";
import editInitialPoints
    from "../../../store/transportationLogisticsService/pageEditInitialPoints/editInitialPoints"
import optimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";


const InitialPointPopupItem = observer(({addressData, showSelectAddressToMap,uid, indexElem, setNewData}) => {
    const {initialPointsList, statePage, listScrollToAddress} = editInitialPoints;


    return (
        <div
            onClick={() => setNewData(addressData)}
            className={(statePage.index_edit_init_point === indexElem)?`${style.addressCoordsItem} ${style.addressCoordsItemActive}`:style.addressCoordsItem}
        >
            <div
            >
                <div data-tooltip-id={`client-info_${uid}`}
                     data-tooltip-variant="info">
                    {(addressData.initial_route_point_name.length > 50) ? addressData.initial_route_point_name.slice(0, 50) + "..." : addressData.initial_route_point_name}
                    {(addressData.initial_route_point_name.length > 50) ? <Tooltip
                        id={`client-info_${uid}`}
                        content={addressData.initial_route_point_name}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
                <div data-tooltip-id={`address-info_${uid}`}
                     data-tooltip-variant="info">
                    {(addressData.address.length > 50) ? addressData.address.slice(0, 45) + "..." : addressData.address}
                    {(addressData.address.length > 50) ? <Tooltip
                        id={`address-info_${uid}`}
                        content={addressData.address}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
            </div>
        </div>
    );
})

export default InitialPointPopupItem;