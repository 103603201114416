import {makeAutoObservable} from 'mobx'
import {v4 as uuidv4} from "uuid";
import globalState from "../globalState";
import toast from "react-hot-toast";
import axios from "axios";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../components/ToastMassage/ToastMassage';

const DEFAULT_MOBILE_STATUS = [
    {
        status_name: "partiallyStatus",
        status_text: "отгружено частично"
    },
    {
        status_name: "cancellationStatus",
        status_text: "отказ"
    }
]

class deliveredStatusList {

    templateList = []; // Список шаблонов
    copyTemplateList = []; // Копия списка шаблонов

    selectedTemplate = {}; // Выбранный шаблон (Для редактирования)


    popoverSelectStatus = {
        selected_status: {
            status_name: null,
            status_text: "выберите статус"
        },
        mobileStatusList: DEFAULT_MOBILE_STATUS,
    }

    createTempState = {
        templateText: "",
        typeStatus: null
    }

    editTempState = {
        templateText: "",
        typeStatus: null,
        loading: false
    }

    paginationState = {
        page: 1,
        rowsPerPage: 10
    }

    tableTemplateListState = {
        allChecked: false
    }

    popupState = {
        popupShow: false,
        popupType: "",
        popupScrollPosition: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    /**
     * Функция для установки значений по умолчанию для состояния создания нового шаблона.
     */
    changeDefaultValueCreateTemp() {
        this.createTempState = {
            templateText: "",
            typeStatus: null
        }

        this.popoverSelectStatus = {
            selected_status: {
                status_name: null,
                status_text: "выберите статус"
            },
            mobileStatusList: DEFAULT_MOBILE_STATUS,
        }
    }

    /**
     * Функция для установки значений по умолчанию для состояния редактирования шаблона.
     */
    changeDefaultValueEditTemp() {
        this.editTempState = {
            templateText: "",
            typeStatus: null
        }

        this.popoverSelectStatus = {
            selected_status: {
                status_name: null,
                status_text: "выберите статус"
            },
            mobileStatusList: DEFAULT_MOBILE_STATUS,
        }
    }

    changeDefaultValueTempList(){
        this.tableTemplateListState.allChecked = false;
    }

    /**
     * Функция для изменения выбранного статуса в мобильном приложении в зависимости от типа действия (создание или редактирование).
     * @param {string} selectData - Новое значение статуса.
     * @param {string} [typeAction="create"] - Тип действия: "create" для создания или "edit" для редактирования.
     */
    changeSelectMobileStatus(selectData, typeAction = "create") {
        this.popoverSelectStatus.selected_status = selectData;

        switch (typeAction) {
            case "create":
                this.createTempState.typeStatus = selectData;
                break;
            case "edit":
                this.editTempState.typeStatus = selectData;
                break;
        }

    }

    /**
     * Функция для изменения текста шаблона в зависимости от типа действия (создание или редактирование).
     * @param {string} newText - Новое значение текста шаблона.
     * @param {string} [typeAction="create"] - Тип действия: "create" для создания или "edit" для редактирования.
     */
    changeTemplateText(newText, typeAction = "create") {
        switch (typeAction) {
            case "create":
                this.createTempState.templateText = newText;
                break;
            case "edit":
                this.editTempState.templateText = newText;
                break;
        }
    }

    /**
     * Функция для изменения состояния выбора шаблона по его идентификатору.
     * @param {number} idTemplate - Идентификатор шаблона, состояние выбора которого нужно изменить.
     * @param {boolean} value - Новое состояние выбора (true - выбран, false - не выбран).
     */
    changeSelectedTemplateById(idTemplate, value){
        const checkedTemp = this.templateList.slice().map(temp => {
            if (temp.id === idTemplate){
                temp.checked = value
            }
            return temp
        })

        this.templateList = checkedTemp;
        this.copyTemplateList = checkedTemp;
        this.showCurrentAmountDocument();
    }

    /**
     * Функция для изменения состояния выбора всем шаблонам
     * @param {boolean} value - Новое состояние выбора (true - выбран, false - не выбран).
     */
    changeSelectedAllTemplate(value){
        this.tableTemplateListState.allChecked = value;

        const checkedTemp = this.templateList.slice().map(temp => {
            temp.checked = value
            return temp
        })

        this.templateList = checkedTemp;
        this.copyTemplateList = checkedTemp;
        this.showCurrentAmountDocument();
    }

    /**
     * Функция для отображения текущего количества документов на текущей странице пагинации.
     */
    showCurrentAmountDocument() {
        // Вычисляем начальный индекс элементов для текущей страницы
        const startIndex = (this.paginationState.page - 1) * this.paginationState.rowsPerPage;
        // Вычисляем конечный индекс элементов для текущей страницы
        const endIndex = startIndex + this.paginationState.rowsPerPage;

        this.copyTemplateList = this.templateList.slice(startIndex, endIndex);
    }

    handleChangePage = (newPage) => {
        this.paginationState.page = newPage;

        this.showCurrentAmountDocument();
    };

    handleChangeRowsPerPage = (rowsPerPage) => {
        if (rowsPerPage != null) {
            this.paginationState.rowsPerPage = rowsPerPage;
            this.paginationState.page = 1;

            this.showCurrentAmountDocument();
        }
    };

    /**
     * Асинхронная функция для получения шаблона по его идентификатору.
     * @param {number} idTemplate - Идентификатор шаблона, который нужно получить.
     * @param {Object} authTokens - Объект с токенами авторизации пользователя.
     * @param {Function} setUser - Функция для установки текущего пользователя.
     * @param {Function} setAuthTokens - Функция для установки токенов авторизации.
     * @param {Function} logoutUser - Функция для выхода пользователя.
     */
    async getTemplateByTempId(idTemplate, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        this.editTempState.loading = true;

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getTemplateById/tempId=${idTemplate}`, {headers}
        ).then(res => {
            this.selectedTemplate = res.data;
            this.editTempState.templateText = res.data.templateText;
            this.editTempState.typeStatus = res.data.typeStatus;
            this.popoverSelectStatus.selected_status = res.data.typeStatus
            setTimeout(() => {
                this.editTempState.loading = false;
            }, 200)
        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить шаблон!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    /**
     * Асинхронная функция для получения шаблонов по типу статуса.
     * @param {string} typeStatus - Тип статуса шаблонов, которые нужно получить.
     * @param {Object} authTokens - Объект с токенами авторизации пользователя.
     * @param {Function} setUser - Функция для установки текущего пользователя.
     * @param {Function} setAuthTokens - Функция для установки токенов авторизации.
     * @param {Function} logoutUser - Функция для выхода пользователя.
     */
    async getTemplateByTypeStatus(typeStatus, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getTemplate/typeStatus=${typeStatus}`, {headers}
        ).then(response => {

            this.changeCheckedDefaultValue(response.data);
            this.showCurrentAmountDocument();

        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить шаблон!", 10000, "bottom-right")
            this.templateList = [];
            this.copyTemplateList = [];
            console.error('Ошибка:', error);
        })

    }

    /**
     * Асинхронная функция для обновления данных шаблона.
     * @param {number} idTemplate - Идентификатор шаблона, который нужно обновить.
     * @param {Object} authTokens - Объект с токенами авторизации пользователя.
     * @param {Function} setUser - Функция для установки текущего пользователя.
     * @param {Function} setAuthTokens - Функция для установки токенов авторизации.
     * @param {Function} logoutUser - Функция для выхода пользователя.
     */
    async updateTemplateData(idTemplate, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            idTemplate: idTemplate,
            templateText: this.editTempState.templateText,
            typeStatus: this.editTempState.typeStatus,
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/updateTemplate`, DATA, {headers}
        ).then(res => {
            toastMassage(toast.success, "Шаблон обновлен!", 2500, "bottom-right")
        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить шаблон!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    /**
     * Асинхронная функция для удаления шаблона по его идентификатору.
     * @param {number} idTemplate - Идентификатор шаблона, который нужно удалить.
     * @param {Object} authTokens - Объект с токенами авторизации пользователя.
     * @param {Function} setUser - Функция для установки текущего пользователя.
     * @param {Function} setAuthTokens - Функция для установки токенов авторизации.
     * @param {Function} logoutUser - Функция для выхода пользователя.
     */
    async deleteTemplateById(idTemplate, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            idTemplate: idTemplate,
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteTemplateById`, DATA, {headers}
        ).then(res => {
            toastMassage(toast.success, "Шаблон обновлен!", 2500, "bottom-right")
        }).catch(e => {
            toastMassage(toast.error, "Не удалось удалить шаблон!", 10000, "bottom-right")
            console.error('Ошибка:', e);
        })
    }

    /**
     * Асинхронная функция для удаления выделенных шаблонов.
     * @param {number} idTemplate - Идентификатор шаблона, который нужно удалить.
     * @param {Object} authTokens - Объект с токенами авторизации пользователя.
     * @param {Function} setUser - Функция для установки текущего пользователя.
     * @param {Function} setAuthTokens - Функция для установки токенов авторизации.
     * @param {Function} logoutUser - Функция для выхода пользователя.
     */
    async deleteTemplates(authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            idTemplates: this.getSelectedTemplates(),
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteTemplates`, DATA, {headers}
        ).then(res => {
            toastMassage(toast.success, "Выбранные шаблоны успешно удалены!", 2500, "bottom-right")
        }).catch(e => {
            toastMassage(toast.error, "Не удалось удалить выбранные шаблоны!", 10000, "bottom-right")
            console.error('Ошибка:', e);
        })
    }


    /**
     * Асинхронная функция для создания нового шаблона.
     * @param {Object} authTokens - Объект с токенами авторизации пользователя.
     * @param {Function} setUser - Функция для установки текущего пользователя.
     * @param {Function} setAuthTokens - Функция для установки токенов авторизации.
     * @param {Function} logoutUser - Функция для выхода пользователя.
     */
    async createTemplate( navigate, authTokens, setUser, setAuthTokens, logoutUser) {

        if (this.createTempState.templateText === "") {
            toastMassage(toast.error, "Введите наименование шаблона!", 10000, "bottom-right")
            return
        } else if (this.createTempState.typeStatus === null) {
            toastMassage(toast.error, "Выберите статус в мобильном приложении!", 10000, "bottom-right")
            return
        }

        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const DATA = {
            templateText: this.createTempState.templateText,
            typeStatus: this.createTempState.typeStatus,
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/createTemplate`, DATA, {headers}
        ).then(response => {
            toastMassage(toast.success, "Новый шаблон создан!", 2500, "bottom-right")
            navigate(`/logisticService/deliveredStatusList/templateList/${DATA.typeStatus.status_name}`)

        }).catch(error => {

            if (error.response.status === 409) {
                toastMassage(toast.error, "Подобный шаблон уже существует!", 10000, "bottom-right")
            } else {
                toastMassage(toast.error, "Не удалось создать новый шаблон!", 10000, "bottom-right")
            }

            console.error('Ошибка:', error);
        })

    }

    /**
     * Функция для установки значения по умолчанию для свойства 'checked' в списке шаблонов.
     * @param {Array} tempData - Массив данных шаблонов, в котором нужно установить значение по умолчанию для 'checked'.
     */
    changeCheckedDefaultValue(tempData){

        const defaultChecked = tempData.map(temp => {
            temp.checked = false;

            return temp
        })

        this.templateList = defaultChecked;
        this.copyTemplateList = defaultChecked;


    }

    changePopupShow(position,value, typePopup){
        this.popupState.popupShow = value;
        this.popupState.popupType = typePopup;
        this.popupState.popupScrollPosition = position;
    }

    closePopup(){
        this.popupState.popupShow = false;
        this.popupState.popupType = "";
    }

    getSelectedTemplates(){
        return this.copyTemplateList.slice().filter(temp => temp.checked).map(temp => temp.id);
    }
}

export default new deliveredStatusList();
