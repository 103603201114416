import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import ManufacturerCardInfo from "../ManufacturerCardInfo/ManufacturerCardInfo";
import ManufacturersTableNomenclature from "../ManufacturersTableNomenclature/ManufacturersTableNomenclature";
import ManufacturersTableRetail from "../ManufacturersTableRetail/ManufacturersTableRetail";
import "./ManufacturersCardNavigation.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function ManufacturersCardNavigation(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box sx={{ borderColor: "divider", color: "success" }}>
        <Tabs
          className={"tab"}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="Информация" {...a11yProps(0)} />
          <Tab label="Номенклатура" {...a11yProps(1)} />
          <Tab label="Торговые точки" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <Box sx={{ width: "100%", display: "flex" }}>
          <ManufacturerCardInfo info={props} />
        </Box>
      </TabPanel>
     
      <TabPanel value={value} index={1}>
          <ManufacturersTableNomenclature id={props.manufacturer.id} inn={props.manufacturer.inn} />
      </TabPanel>

      <TabPanel value={value} index={2}>
            <ManufacturersTableRetail id={props.manufacturer.id}/>
      </TabPanel>
    </Box>
  );
}
