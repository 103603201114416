import React from 'react';
import style from './MapMarketRoute.module.css'

const MapMarketRoute = ({idDoc}) => {
    return (
        <div className={style.mapMarker}>
            {idDoc}
        </div>
    );
};

export default MapMarketRoute;