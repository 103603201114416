import Box from "@mui/material/Box";
import {Avatar, List, ListItemText, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {useContext} from "react";
import GlobalContext from "../../context/GlobalContext";
import AccountBoxIcon from '@mui/icons-material/AccountCircle';
const ProfileInfoUserLeftBox = ({userProfile}) => {
    const { state, user } = useContext(GlobalContext);

    return(
        <Box className={"profile-info-user"}>

            <List component="nav" aria-label="secondary mailbox folder">
                <Box sx={{ display: "flex"}}>

                        <AccountBoxIcon  sx={{
                            backgroundImage:
                                "linear-gradient(98deg, #4798e7, #006fcb 94%)",
                            width: "5vw",
                            height: "5vw",
                            color: "#ffffff",
                            fontSize: "2vw",
                            borderRadius: "5px",
                            marginLeft: "10px",
                        }}/>
                    <Box sx={{ marginTop: "0.5vw", marginLeft: "1vw" }}>
                        <Typography variant="h5">{userProfile.name}</Typography>
                        <Typography>{user.accessLevel === '0' ? "Дистрибьютор" : "Производитель" } </Typography>
                    </Box>
                </Box>
            </List>
            <Divider sx={{marginLeft: "10px", marginRight: "10px"}} />
            <List>
                <Typography sx={{margin: "10px"}}>
                    {"Название: " + userProfile.name}
                </Typography>
            </List>
            <Divider sx={{marginLeft: "10px", marginRight: "10px"}} />
            <List>
                <Typography sx={{margin: "10px"}}>
                    {"ИНН: " + userProfile.inn}
                </Typography>
            </List>
            <Divider sx={{marginLeft: "10px", marginRight: "10px"}} />
            <List>

                <Typography sx={{margin: "10px"}}>
                    {"Почта: " + userProfile.mail}
                </Typography>
            </List>
            <Divider sx={{marginLeft: "10px", marginRight: "10px"}} />
            <List>
                <Typography sx={{margin: "10px"}}>
                    {"КПП: " + userProfile.kpp}
                </Typography>
            </List>
            <Divider sx={{marginLeft: "10px", marginRight: "10px"}} />

            <List>
                <Typography sx={{margin: "10px"}}>
                    {"Адрес: " + userProfile.address}
                </Typography>
            </List>
            <Divider sx={{marginLeft: "10px", marginRight: "10px"}} />

            {/* <Divider /> */}

        </Box>

    )
}
export default ProfileInfoUserLeftBox