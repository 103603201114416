import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HistoryIcon from '@mui/icons-material/History';
import AdjustIcon from '@mui/icons-material/LensBlur';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Divider, List, ListItem, ListItemIcon, ListItemText, StepButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import "./VerticalStepperInfo.css";


const steps = 
[
  {
    label: (
      <Typography variant="h5" component="h6">
        Конструктор отчетов в платформе «САВП»
      </Typography>
    ),
    date: "01 апреля 2024 г",
    description: (
      <>
        <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
          Удобный и функциональный инструмент, который позволит вам
          самостоятельно, без опыта и знаний языков программирования, используя
          платформу «САВП» построить аналитическую отчетность в разрезе
          необходимых измерений:
          <br />
          (производитель, дистрибьютор, контрагент, ТТ, номенклатура, период) а
          также ресурсов: <br /> (единица измерения товара, рубли и т.д.)
        </Typography>
        
        <Box sx={{textAlign: "left"}}>
        <QueryStatsIcon color="primary" sx={{fontSize: "70px"}}/>
        </Box>

        

        <Typography className="textContent" variant="subtitle1" component="h6">
          - Конструктор будет доступен в личном кабинете производителя и
          дистрибьютора. <br /> - Доступ предоставляется в рамках базового
          использования платформы «САВП».
        </Typography>

        <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <Typography
            className="textContentFooter"
            variant="subtitle1"
            component="div"
          >
            - Планируемая дата релиза – 01 апреля 2024 г
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: (      <Typography variant="h5" component="h6">
     Мобильное приложение для мерчандайзеров </Typography> ),
         date: "01 июня 2024 г",
    description: (
      <>
        <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
        Планируйте работу сотрудников через платформу «САВП», в web-режиме формируйте маршруты и задачи, отслеживайте их выполнение. <br/> Встроенный в платформу «САВП» алгоритм распознавания фотографий, позволит в автоматическом режиме произвести анализ работы сотрудника. 
        </Typography>

        <Box sx={{textAlign: "center"}}>
        <AppShortcutIcon color="primary" sx={{fontSize: "70px"}}/>
        </Box>

        <List>
                <ListItem>
                  <ListItemIcon>
                      <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Создание и оптимизация маршрутов мерчандайзеров, контроль посещения торговых точек"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Анкетирование"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Контроль товарных запасов"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Фейсинг и проверка выкладки"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Контроль планограмм"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Мониторинг цен и ассортимента"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Оценка полочного пространства и дополнительных мест с использованием алгоритма распознавания фотографий"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Представленность конкурентов"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Контроль и оценка промо-акций"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Учет и мониторинг POS материалов"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Различный перечень отчетности"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Постановка задач через личный кабинет дистрибьютора и/или личный кабинет производителя"
                  />
                </ListItem>
                <Divider/>
            </List>
            
        <Typography className="textContent" variant="subtitle1" component="h6">
         - Это позволяет дистрибьютору использовать мобильное приложение автономно от производителя или в взаимодействии с производителем.
        </Typography>
        <Typography className="textContent" variant="subtitle1" component="h6">
        - Приложение предоставляется на условиях подписки по основному или дополнительному тарифу, как для сотрудников производителя, так и сотрудников дистрибьюторов. <RecentActorsIcon/>
        </Typography>

        

        <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <Typography
            className="textContentFooter"
            variant="subtitle1"
            component="div"
          >
            - Планируемая дата релиза – 01 июня 2024 г
          </Typography>
          
        </Box>
      </>
    ),
  },
  {
    label:
    (
      <Typography variant="h5" component="h6">
      Сервис и мобильное приложение для водителей (транспортной логистики)
      </Typography>),
               date: "01 июня 2024 г",

      description: (
        <>
        <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
        Простая и легкая оптимизация маршрутов, в рамках районов доставки и/или одного маршрута. Мобильное приложение, в которое выгружается оптимизированный маршрут для каждого автомобиля.        </Typography>

       

        <Box sx={{textAlign: "center"}}>
        <LocalShippingIcon color="primary" sx={{fontSize: "70px"}}/>
        </Box>

        <List>
                <ListItem>
                  <ListItemIcon>
                      <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Отображение маршрута доставки согласно произведенной оптимизации"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Вывод информации о расстоянии в километрах/метрах между ТТ, планируемое время прибытия в ТТ"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Возможность между точками построить маршрут с учетом текущей дорожной обстановки."
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Информирование транспортного логиста о возникших ситуациях и автоматическое формирование сообщений, если водитель не успевает в ТТ"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Сравнение план/факт показателей по расстоянию и времени."
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Осуществление телефонных звонков из приложения: в офис, торговому представителю, клиенту"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Отчет по кассе"
                  />
                </ListItem>
                <Divider/>
            </List>
            
        
        <Typography className="textContent" variant="subtitle1" component="h6">
        - Приложение предоставляется на условиях подписки по основному или дополнительному тарифу, как для сотрудников производителя, так и сотрудников дистрибьюторов. <RecentActorsIcon/>
        </Typography>

        

        <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <Typography
            className="textContentFooter"
            variant="subtitle1"
            component="div"
          >
            - Планируемая дата релиза –  01 июня 2024 г
          </Typography>
          
        </Box>
      </>
      ),
  },
  {
    label: (
      <Typography variant="h5" component="h6">
     Аналитика обеспеченности складов дистрибьюторов
      </Typography>),
                     date: "15 апреля 2024 г",

    description: (
      <>
      <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
      Инструмент включает в себя удобный dashboard, используя который, без формирования дополнительной отчетности, можно получить различную информацию, например          </Typography>

     

      <Box sx={{textAlign: "center"}}>
      <StorefrontIcon color="primary" sx={{fontSize: "70px"}}/>
      </Box>

      <List>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Перетаривание склада у дистрибьютора, исходя из его динамики продаж"
                />
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Общий товарный запас у дистрибьюторов в днях и рублях."
                />
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="SKU, которые находятся в дефиците или наоборот требуют внимания при реализации"
                />
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Аналитика в виде XLS отчетов"
                />
              </ListItem>
              <Divider/>
          </List>
          
      
      <Typography className="textContent" variant="subtitle1" component="h6">
      - Функционал будет доступен в личном кабинете производителя. Доступ предоставляется в рамках базового использования платформы «САВП». <RecentActorsIcon/>
      </Typography>

      

      <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
        <Typography
          className="textContentFooter"
          variant="subtitle1"
          component="div"
        >
          - Планируемая дата релиза –  15 апреля 2024 г
        </Typography>
        
      </Box>
    </>
    ),
  },
  {
    label: (
      <Typography variant="h5" component="h6">
    Отчет по SLA сервису дистрибьторов 
      </Typography>),
                           date: "30 июня 2024 г",

    description: (
      <>
        <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
        Многофункциональный инструмент, который в разрезе дистрибьютора, покажет производителю следующую аналитику:
        </Typography>

        <Box sx={{textAlign: "center"}}>
      <AssessmentIcon color="primary" sx={{fontSize: "70px"}}/>
      </Box>

      <List>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Своевременность обслуживания ТТ. Для работы данной опции, дистрибьютору необходимо использовать мобильное приложение «САВП: транспортная логистика»"
                />
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Частота посещения ТТ, оформление заказа, выполнение заказа"
                />
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="ABC/XYZ исходя из группировки ТТ или по отдельной торговой сети или торговой точке"
                />
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemIcon>
                <AdjustIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Аналитика в виде XLS отчетов"
                />
              </ListItem>
              <Divider/>
          </List>

        <Typography className="textContent" variant="subtitle1" component="h6">
          - Функционал будет доступен в личном кабинете производителя. Доступ предоставляется в рамках базового использования платформы «САВП».<RecentActorsIcon/>
        </Typography>

        <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <Typography
            className="textContentFooter"
            variant="subtitle1"
            component="div"
          >
            - Планируемая дата релиза –  30 июня 2024 г
          </Typography>
        </Box>
      </>
    ),
  },
];

const stepsDistributor = 
[
  {
    label: (
      <Typography variant="h5" component="h6">
        Конструктор отчетов в платформе «САВП»
      </Typography>
    ),
    date: "01 апреля 2024 г",
    description: (
      <>
        <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
          Удобный и функциональный инструмент, который позволит вам
          самостоятельно, без опыта и знаний языков программирования, используя
          платформу «САВП» построить аналитическую отчетность в разрезе
          необходимых измерений:
          <br />
          (производитель, дистрибьютор, контрагент, ТТ, номенклатура, период) а
          также ресурсов: <br /> (единица измерения товара, рубли и т.д.)
        </Typography>
        
        <Box sx={{textAlign: "left"}}>
        <QueryStatsIcon color="primary" sx={{fontSize: "70px"}}/>
        </Box>

        

        <Typography className="textContent" variant="subtitle1" component="h6">
          - Конструктор будет доступен в личном кабинете производителя и
          дистрибьютора. <br /> - Доступ предоставляется в рамках базового
          использования платформы «САВП».
        </Typography>

        <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <Typography
            className="textContentFooter"
            variant="subtitle1"
            component="div"
          >
            - Планируемая дата релиза – 01 апреля 2024 г
          </Typography>
        </Box>
      </>
    ),
  },
  {
    label: (      <Typography variant="h5" component="h6">
     Мобильное приложение для мерчандайзеров </Typography> ),
         date: "01 июня 2024 г",
    description: (
      <>
        <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
        Планируйте работу сотрудников через платформу «САВП», в web-режиме формируйте маршруты и задачи, отслеживайте их выполнение. <br/> Встроенный в платформу «САВП» алгоритм распознавания фотографий, позволит в автоматическом режиме произвести анализ работы сотрудника. 
        </Typography>

        <Box sx={{textAlign: "center"}}>
        <AppShortcutIcon color="primary" sx={{fontSize: "70px"}}/>
        </Box>

        <List>
                <ListItem>
                  <ListItemIcon>
                      <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Создание и оптимизация маршрутов мерчандайзеров, контроль посещения торговых точек"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Анкетирование"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Контроль товарных запасов"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Фейсинг и проверка выкладки"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Контроль планограмм"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Мониторинг цен и ассортимента"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Оценка полочного пространства и дополнительных мест с использованием алгоритма распознавания фотографий"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Представленность конкурентов"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Контроль и оценка промо-акций"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Учет и мониторинг POS материалов"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Различный перечень отчетности"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Постановка задач через личный кабинет дистрибьютора и/или личный кабинет производителя"
                  />
                </ListItem>
                <Divider/>
            </List>
            
        <Typography className="textContent" variant="subtitle1" component="h6">
         - Это позволяет дистрибьютору использовать мобильное приложение автономно от производителя или в взаимодействии с производителем.
        </Typography>
        <Typography className="textContent" variant="subtitle1" component="h6">
        - Приложение предоставляется на условиях подписки по основному или дополнительному тарифу, как для сотрудников производителя, так и сотрудников дистрибьюторов. <RecentActorsIcon/>
        </Typography>

        

        <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <Typography
            className="textContentFooter"
            variant="subtitle1"
            component="div"
          >
            - Планируемая дата релиза – 01 июня 2024 г
          </Typography>
          
        </Box>
      </>
    ),
  },
  {
    label:
    (
      <Typography variant="h5" component="h6">
      Сервис и мобильное приложение для водителей (транспортной логистики)
      </Typography>),
               date: "01 июня 2024 г",

      description: (
        <>
        <Typography sx={{ marginTop: "30px" }} variant="h6" component="span">
        Простая и легкая оптимизация маршрутов, в рамках районов доставки и/или одного маршрута. Мобильное приложение, в которое выгружается оптимизированный маршрут для каждого автомобиля.        </Typography>

       

        <Box sx={{textAlign: "center"}}>
        <LocalShippingIcon color="primary" sx={{fontSize: "70px"}}/>
        </Box>

        <List>
                <ListItem>
                  <ListItemIcon>
                      <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Отображение маршрута доставки согласно произведенной оптимизации"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Вывод информации о расстоянии в километрах/метрах между ТТ, планируемое время прибытия в ТТ"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Возможность между точками построить маршрут с учетом текущей дорожной обстановки."
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Информирование транспортного логиста о возникших ситуациях и автоматическое формирование сообщений, если водитель не успевает в ТТ"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Сравнение план/факт показателей по расстоянию и времени."
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Осуществление телефонных звонков из приложения: в офис, торговому представителю, клиенту"
                  />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                  <AdjustIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Отчет по кассе"
                  />
                </ListItem>
                <Divider/>
            </List>
            
        
        <Typography className="textContent" variant="subtitle1" component="h6">
        - Приложение предоставляется на условиях подписки по основному или дополнительному тарифу, как для сотрудников производителя, так и сотрудников дистрибьюторов. <RecentActorsIcon/>
        </Typography>

        

        <Box sx={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <Typography
            className="textContentFooter"
            variant="subtitle1"
            component="div"
          >
            - Планируемая дата релиза –  01 июня 2024 г
          </Typography>
          
        </Box>
      </>
      ),
  },
];

export default function VerticalStepperInfo() {
  const [activeStep, setActiveStep] = React.useState(0);
  let {user} = useContext(GlobalContext);

  const list = user.accessLevel == 1 ? steps : user.accessLevel == 0 ? stepsDistributor : null

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box>
      <Box sx={{textAlign: "center", marginBottom: "15px"}}>
      <Typography variant="h5" component="h6">
        Скоро в обновлении <HistoryIcon/>
      </Typography>
      </Box>

      <Box sx={{borderRadius: "8px", padding: "1vw"}}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {list.map((step, index) => (
          <Step key={step.label} >
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step.label}
            </StepButton>

            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button onClick={index === list.length - 1 ? handleReset : handleNext} sx={{ mt: 1, mr: 1 }}>
                    {index === list.length - 1 ? "В начало" : "Далее"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Назад
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      </Box>
    </Box>
  );
}
