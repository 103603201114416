import React, {useCallback, useContext, useMemo, useRef} from 'react';
import style from "./InitialPointList.module.css";
import editInitialPoints from '../../../store/transportationLogisticsService/pageEditInitialPoints/editInitialPoints'
// import {FixedSizeList as List} from "react-window";
import {ReactComponent as ImgNoneData} from '../../../imges/noneData.svg';
import {AutoSizer, List} from "react-virtualized";
import InitialPointItem from "../InitialPointItem/InitialPointItem";
import {v4 as uuidv4} from "uuid";
import GlobalContext from "../../../context/GlobalContext";
import {observer} from "mobx-react-lite";
import InitialPointPopupItem from "../InitialPointPopupItem/InitialPointPopupItem";
import editCoordinatesAddress
    from "../../../store/transportationLogisticsService/pageEditCoordinatesAddress/editCoordinatesAddress";

const InitialPointList = observer(({showSelectInitPointToMap}) => {
    const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(GlobalContext);
    const {initialPointsList, statePage, listScrollToInitPoint} = editInitialPoints;
    const listRefPoint = useRef(null);

    /* Функция для установки ссылки на список.*/
    const setListRefPoint = useCallback(node => {
        if (node !== null) {
            listRefPoint.current = node;
            if (statePage.setting === false && listScrollToInitPoint.address != null && listScrollToInitPoint.initial_route_point_name != null) {
                if (listRefPoint.current) {
                    listRefPoint.current.scrollToRow(statePage.index_edit_init_point)
                }
            }
        }
    }, [statePage.setting])

    useMemo(()=>{
        editInitialPoints.getAllInitialPoints(authTokens, setUser, setAuthTokens, logoutUser);
    },[])


    const rowRenderer = ({index, key, style}: any) => {
        return (
            <div key={key} style={style}>
                <InitialPointItem
                    addressData={initialPointsList[index]}
                    showSelectInitPointToMap={showSelectInitPointToMap}
                    key={uuidv4()}
                    uid={uuidv4()}
                    indexElem={index}
                />
            </div>
        )
    };

    return (
        <>
            <div className={style.initPointEditHeaderMenu}>
                Начальные точки
            </div>

            <div className={style.btnBlock}>
                <div
                    id={"uploadAllInitialPoints_InitialPointList"}
                    className={style.btnUploadInitPoint}
                    onClick={
                        async () => {
                           await editInitialPoints.getAllInitialPoints(authTokens, setUser, setAuthTokens, logoutUser);
                        }
                    }
                >
                    Получить начальные точки
                </div>
                <div
                    id={"createNewInitialPoint_InitialPointList"}
                    className={style.btnCreateNewInitPoint}
                    onClick={
                        () => editInitialPoints.setCreatePointData()
                    }
                >
                    Создать начальную точку
                </div>
            </div>

            <div
                id={"searchInitialPoint_InitialPointList"}
                className={style.inputSearchAddressPoints}>
                <input
                    type="text"
                    placeholder="Поиск по адресу"
                    value={editInitialPoints.statePage.value_search}
                    onChange={(e) => editInitialPoints.changeSearchNameInitPoints(e.target.value)}
                />
                <span
                    id={"clearSearchInputNamePoint_InitialPointList"}
                    onClick={() => editInitialPoints.clearSearchValue()}
                >Очистить</span>
            </div>

            <div className={style.initPointListHeader}>
                <div>
                    <div>Наименование</div>
                    <div>Адрес</div>
                </div>
                <div>
                    <div>Широта</div>
                    <div>Долгота</div>
                </div>
                <div>
                    <div>Редактировать</div>
                </div>
            </div>
            <div id={"initialPointsList_InitialPointList"}>
                {
                    (initialPointsList.length === 0)?
                        <>
                            <div className={style.noDataImg}>
                                <ImgNoneData/>
                                <div>Нет координат начальных точек</div>
                            </div>
                        </>: <AutoSizer>
                        {({width}) => (
                            <List
                                ref={setListRefPoint}
                                width={width}
                                height={505}
                                rowCount={initialPointsList.length} // Количество элементов в списке
                                rowHeight={101} // Высота отдельного элемента списка
                                rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                className={style.popupInitPointListBlock}
                            />
                        )}
                    </AutoSizer>
                }
            </div>
        </>
    );
})

export default InitialPointList;