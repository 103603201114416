import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import style from './SettingBuildingRouteByVehicle.module.css';
import settingOptimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import PopoverSelector from "../../PopoverSelector/PopoverSelector";
import DateSelectionField from "../../../../inputs/logisticService/DatePicker/DatePicker";
import InputMask from "react-input-mask";
import debounce from "lodash/debounce";
import {observer} from "mobx-react-lite";
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import editCoordinatesAddress
    from "../../../../store/transportationLogisticsService/pageEditCoordinatesAddress/editCoordinatesAddress";

const SettingBuildingRouteByVehicle = observer(({typeSetting}) => {
    const {
        costing,
        costing_options,
        costing_options_checked,
        destinationDateTime
    } = settingOptimizationRouteSheetStore[typeSetting];
    const {pageState, routingSheets} = optimizationRouteSheetStore;
    const inputValueData = useRef(null);
    const options = ['отправления', 'прибытия'];
    const [inputValueTime, setInputValueTime] = useState(destinationDateTime.time);
    const [openSettingCar, setOpenSettingCar] = useState(false)




    const handleInputChange = useCallback((event) => {
        let input = event.target;
        let newValue = input.value;

        // Разделяем значение на часы и минуты
        const [hours, minutes] = newValue.split(':');

        // Проверяем каждый символ
        if (hours && parseInt(hours) > 24) {
            newValue = '24' + (minutes ? ':' + minutes : '');
        }
        if (minutes && parseInt(minutes) > 59) {
            newValue = hours + ':59';
        }

        setInputValueTime(newValue);
    }, []);

    const handleTimeChange = useCallback(
        debounce((event) => {
            let input = event.target;
            let newValue = input.value;

            // Разделяем значение на часы и минуты
            const [hours, minutes] = newValue.split(':');

            // Проверяем каждый символ
            if (hours && parseInt(hours) > 24) {
                newValue = '24' + (minutes ? ':' + minutes : '');
            }
            if (minutes && parseInt(minutes) > 59) {
                newValue = hours + ':59';
            }

            // Проверка формата
            const timeRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])$/;
            if (timeRegex.test(newValue)) {
                settingOptimizationRouteSheetStore.changeDestinationTime(newValue, typeSetting);
                setInputValueTime(newValue);
            } else {
                settingOptimizationRouteSheetStore.changeDestinationTime("", typeSetting);
                setInputValueTime("");
            }
        }, 1000), // Задержка в миллисекундах
        []
    );

    const getSelectedValueDestination = (newValue) => {
        settingOptimizationRouteSheetStore.changeDestination(newValue, typeSetting);
    };

    const showRouteGuidanceSettings = () => {
        setOpenSettingCar(!openSettingCar);
        if (!openSettingCar) {
            settingOptimizationRouteSheetStore.openPopupSettingOptimization(window.pageYOffset);
        }
    }


    return (
        <div className={style.settingCarBlock}>
            <div className={style.routeExclusionBlock}>
                            <span
                                className={style.noteTextSettingBlock}>* Данные параметры являются необязательными
                            </span>
                <div className={style.routeExclusionBlockHeader}>
                    Исключить параметры при построении маршрута:
                </div>

                <div className={style.routeExclusionList}>
                    <div>
                        <input type="checkbox"
                               className={style.selectRouteExclusion}
                               id="use_tolls"
                               name="use_tolls"
                               checked={costing_options_checked.use_tolls}
                               value={0.5}
                               onChange={(e) => {
                                   settingOptimizationRouteSheetStore.selectRouteExclusion(e.target.value, e.target.name, typeSetting)
                               }}
                        />
                        <label htmlFor="use_tolls">Платные дороги</label>
                    </div>
                    <div>
                        <input type="checkbox"
                               className={style.selectRouteExclusion}
                               id="use_unpaved"
                               name="use_unpaved"
                               value={0.5}
                               checked={costing_options_checked.use_unpaved}
                               onChange={(e) => settingOptimizationRouteSheetStore.selectRouteExclusion(e.target.value, e.target.name, typeSetting)}/>
                        <label htmlFor="use_unpaved">Дороги с плохим
                            покрытием</label>
                    </div>
                    <div>
                        <input type="checkbox"
                               className={style.selectRouteExclusion}
                               id="use_highways"
                               name="use_highways"
                               value="1"
                               checked={costing_options_checked.use_highways}
                               onChange={(e) => settingOptimizationRouteSheetStore.selectRouteExclusion(e.target.value, e.target.name, typeSetting)}
                        />
                        <label htmlFor="use_highways">Автомагистрали</label>
                    </div>
                    <div>
                        <input type="checkbox"
                               className={style.selectRouteExclusion}
                               id="use_ferry"
                               name="use_ferry"
                               value="0.5"
                               checked={costing_options_checked.use_ferry}
                               onChange={(e) => settingOptimizationRouteSheetStore.selectRouteExclusion(e.target.value, e.target.name, typeSetting)}
                        />
                        <label htmlFor="use_ferry">Паромные переправы</label>
                    </div>
                    <div>
                        <input type="checkbox"
                               className={style.selectRouteExclusion}
                               id="use_border_crossing"
                               name="use_border_crossing"
                               value="1"
                               checked={costing_options_checked.use_border_crossing}
                               onChange={(e) => settingOptimizationRouteSheetStore.selectRouteExclusion(e.target.value, e.target.name, typeSetting)}
                        />
                        <label htmlFor="use_border_crossing">Пересечения границ
                            государств</label>
                    </div>
                </div>
                <div className={style.DateAndTimeRouteStart}>


                    <div className={style.departureDateTimeSelector}> Дата и время отправления
                    </div>

                    <div className={style.selectBlockValueBuildingRoute}>

                        <div className={style.selectDataValue}>
                            <DateSelectionField
                                value={destinationDateTime.date}
                                onChange={(newData) => settingOptimizationRouteSheetStore.changeDestinationDate(newData, typeSetting)}
                                typeSetting={typeSetting}
                            />
                        </div>

                        <div className={style.selectTimeValue}>
                            <InputMask
                                inputRef={inputValueData}
                                mask="99:99"
                                maskChar=" "
                                value={inputValueTime}
                                placeholder="00:00"
                                onChange={handleInputChange}
                                onBlur={handleTimeChange}
                            />
                        </div>
                    </div>

                </div>
            </div>

            {
                (costing === "truck" && typeSetting === "settingForDocuments" && pageState.popup_type === "settingForDocuments") ?
                    <div className={style.routeExclusionBlock}>
                        <div className={style.routeExclusionBlockHeader}>
                            Параметры для грузовика:
                        </div>

                        <div className={style.routeExclusionList}>
                            <div>
                                <input type="checkbox" className={style.selectRouteExclusion}
                                       id="hazmat"
                                       name="hazmat"
                                       value="false"
                                       checked={costing_options[costing].hazmat}
                                       onChange={(e) => settingOptimizationRouteSheetStore.selectRouteExclusion(!costing_options[costing].hazmat, e.target.name, typeSetting)}
                                />
                                <label htmlFor="hazmat">Опасный груз</label>
                            </div>
                            <div className={style.routeExclusionTruckBlock}>
                                <div className={style.inputParamsForTruck}>
                                    <div>Высота, м</div>
                                    <input
                                        type="number"
                                        name="height"
                                        step="0.1"
                                        min="0"
                                        placeholder={(costing_options[costing].height !== 4.11) && costing_options[costing].height}
                                        onChange={(e) => settingOptimizationRouteSheetStore.changeParamsTruck(e.target.value, e.target.name, typeSetting)}
                                    />
                                </div>
                                <div className={style.inputParamsForTruck}>
                                    <div>Ширина, м</div>
                                    <input
                                        type="number"
                                        name="width"
                                        step="0.1"
                                        min="0"
                                        placeholder={(costing_options[costing].width !== 9.07) && costing_options[costing].width}
                                        onChange={(e) => settingOptimizationRouteSheetStore.changeParamsTruck(e.target.value, e.target.name, typeSetting)}
                                    />
                                </div>
                                <div className={style.inputParamsForTruck}>
                                    <div>Длина, м</div>
                                    <input
                                        type="number"
                                        name="length"
                                        step="0.1"
                                        min="0"
                                        placeholder={(costing_options[costing].length !== 21.64) && costing_options[costing].length}
                                        onChange={(e) => settingOptimizationRouteSheetStore.changeParamsTruck(e.target.value, e.target.name, typeSetting)}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${style.inputParamsForTruck} ${style.inputParamsForTruckTotalWeight}`}>
                                <div>Фактическая масса (с грузом), т.</div>
                                <input
                                    name="weight"
                                    type="number"
                                    step="0.1"
                                    min="0"
                                    placeholder={(costing_options[costing].weight !== 21.77) && costing_options[costing].weight}
                                    onChange={(e) => settingOptimizationRouteSheetStore.changeParamsTruck(e.target.value, e.target.name, typeSetting)}
                                />
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    );
});

export default SettingBuildingRouteByVehicle;