import {makeAutoObservable} from 'mobx'
import axios from "axios";
import toast from "react-hot-toast";
import globalState from "../globalState";
import sortAndFilterTableColsUtils from "../../../utils/transportationLogisticsService/sortAndFilterTableCols.utils";
import { IconButton } from '@mui/material';
    import CloseIcon from '@mui/icons-material/Close';
    
const DEFAULT_PAGE_STATE = {
    viewDeliver: false,
    uploadDate: null,
    popup_show: false, // Отображать ли popup
    popup_type: null, // Тип popup: selectInitialPoint, generalSettingForDocument, settingForDocuments
    popup_scroll_position: 0,
}

const DEFAULT_DELIVERED_STATUS_FILTER = [
    {
        filter_name: "status_document",
        filter_text: "без фильтра",
        filter_params_name: "",
        filter_value: false
    },
    {
        filter_name: "status_document",
        filter_text: "В маршруте",
        filter_params_name: "route_completed",
        filter_value: false
    }, {
        filter_name: "status_document",
        filter_text: "Завершен",
        filter_params_name: "route_completed",
        filter_value: true
    }]

const DEFAULT_ORDER_STATUS_FILTER = [
    {
        filter_name: "delivered_status",
        filter_text: "без фильтра",
        filter_params_name: "",
        filter_value: false
    },
    {
        filter_name: "delivered_status",
        filter_text: "В пути",
        filter_params_name: "route_completed",
        filter_value: "В пути"
    }, {
        filter_name: "delivered_status",
        filter_text: "Отгружено",
        filter_params_name: "route_completed",
        filter_value: "Отгружено"
    }, {
        filter_name: "delivered_status",
        filter_text: "Отгружено частично",
        filter_params_name: "route_completed",
        filter_value: "Отгружено частично"
    }, {
        filter_name: "delivered_status",
        filter_text: "Отказ",
        filter_params_name: "route_completed",
        filter_value: "Отказ"
    }]

class DeliversRoutes {

    // Стейт для пагинации таблицы
    paginationState = {
        page: 1,
        rowsPerPage: 10
    }

    deliversList = []; // Список маршрутов
    copyDeliversList = []; // Копия списка маршрутов
    filteredDeliversList = []; // Отфильтрованные маршруты

    // Объект выбранного маршрута
    selectedDeliver = {}
    copySelectedDeliver = {}
    filteredSelectedDeliver = {}
    selectedRouteLoc = {}

    // Стейт для сортировки столбцов
    deliverListTableSortState = { // Таблица с маршрутами
        sort_name: "order_number",
        sort_value: 0
    }

    pointListTableSortState = { // Таблица с ТТ по маршруту
        sort_name: "order_number",
        sort_value: 0
    }

    // Стейт для поисковой строки по таблице
    searchState = {
        table_search_value: "",
        editedRouteSheetCopy: null
    }

    tableSearchDeliveredState = "";
    tableFilterDeliveredState = { // Стейт для фильтров таблицы
        select_filter: false, // Выбран ли хотябы 1 филтр в таблице
        uniqueDriversNames: null, // Список всех водителей (по 1) по выгруженным документам
        uniqueForwardersNames: null, // Список всех экспедиторов (по 1) по выгруженным документам
        statusDocumentList: DEFAULT_DELIVERED_STATUS_FILTER,
        selected_filters: [
            {
                filter_name: "status_document",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false
            },
            {
                filter_name: "driver_name",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false
            },
            {
                filter_name: "forwarder_name",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false
            }
        ], // Массив выбранных фильтров
    }

    tableFilterDeliveredPointsState = { // Стейт для фильтров таблицы
        select_filter: false, // Выбран ли хотябы 1 филтр в таблице
        uniqueOrderClientNames: null, // Список всех водителей (по 1) по выгруженным документам
        statusDeliveredPoints: DEFAULT_ORDER_STATUS_FILTER,
        selected_filters: [
            {
                filter_name: "delivered_status",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false
            },
            {
                filter_name: "order_client",
                filter_text: "без фильтра",
                filter_params_name: "",
                filter_value: false
            }
        ], // Массив выбранных фильтров
    }

    // Общие состояния на странице "Мониторинга маршрутов"
    deliversRoutesPage = {
        ...DEFAULT_PAGE_STATE,
        uploadDate: this.getISONowDate()
    }


    constructor() {
        makeAutoObservable(this,)
    }

    async uploadDeliversDocuments(authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const uploadDate = this.deliversRoutesPage.uploadDate.split('T')[0];

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getDeliveryRoutes/date=${uploadDate}`, {headers}
        )
            .then(response => {
                if (response.status === 200) {
                    if (this.deliversRoutesPage.viewDeliver) {
                        this.deliversRoutesPage = {
                            ...DEFAULT_PAGE_STATE,
                            viewDeliver: this.deliversRoutesPage.viewDeliver,
                            uploadDate: this.deliversRoutesPage.uploadDate
                        }
                    } else {
                        this.deliversRoutesPage = {
                            ...DEFAULT_PAGE_STATE,
                            uploadDate: this.deliversRoutesPage.uploadDate
                        }
                    }

                    if (Object.keys(this.selectedDeliver).length !== 0) {
                        const updateSelectedData = response.data.filter(objDoc => {
                            return objDoc.id === this.selectedDeliver.id && objDoc.document_id === this.selectedDeliver.document_id
                        })
                        this.selectedDeliver = updateSelectedData[0]
                    }
                    this.deliversList = response.data;
                    this.copyDeliversList = JSON.parse(JSON.stringify(this.deliversList));
                    this.updateSelectedDelivered();
                    this.getUniqDataByFilter();
                    this.showCurrentAmountDocument();
                }

            })
            .catch((error) => {
                console.error(error)
                toast.error ( 
                    (t) => (
                      <>
                        <span>Нет маршрутов за выбранную дату!</span>
                        <IconButton onClick={() => toast.dismiss(t.id)}><CloseIcon/></IconButton>
                      </>
                    ),
                    {
                      duration: 10000,
                      position: 'bottom-right'
        
                    }
                  );
    
                this.deliversRoutesPage = {
                    ...DEFAULT_PAGE_STATE,
                    uploadDate: this.deliversRoutesPage.uploadDate
                }
                this.deliversList = [];
                this.copyDeliversList = [];
            })

    }

    updateSelectedDelivered(){

        const newValue = this.copyDeliversList.slice().map(objDel => {
            if(objDel.document_id === this.selectedDeliver.document_id){
                this.selectedDeliver = JSON.parse(JSON.stringify(objDel))
                this.copySelectedDeliver = JSON.parse(JSON.stringify(objDel))
            }
        })


    }

    /**
     * Отображение текущего количества документов.
     *
     * Эта функция вычисляет начальный и конечный индексы для текущей страницы
     * на основе текущего состояния пагинации, а затем обновляет список документов,
     * отображаемых на текущей странице, из исходного списка документов.
     */
    showCurrentAmountDocument() {
        if (!this.tableFilterDeliveredState.select_filter && this.copyDeliversList != null) {
            const startIndex = (this.paginationState.page - 1) * this.paginationState.rowsPerPage;
            const endIndex = startIndex + this.paginationState.rowsPerPage;

            if (this.tableSearchDeliveredState === ""){
                this.copyDeliversList = this.deliversList.slice(startIndex, endIndex);
            } else {
                this.copyDeliversList = this.copyDeliversList.slice(startIndex, endIndex);
            }

        } else if (this.tableFilterDeliveredState.select_filter && this.filteredDeliversList.length >= 0) {
            this.paginationState.page = 1
            const startIndex = (this.paginationState.page - 1) * this.paginationState.rowsPerPage;
            const endIndex = startIndex + this.paginationState.rowsPerPage;

            if (this.tableSearchDeliveredState !== ""){
                this.changeSearchAddress(this.tableSearchDeliveredState);
                this.copyDeliversList = this.copyDeliversList.slice(startIndex, endIndex);
            } else {
                this.copyDeliversList = this.filteredDeliversList.slice(startIndex, endIndex);
            }

        }
    }

    /**
     * Обработчик изменения страницы.
     *
     * Эта функция изменяет текущую страницу пагинации и вызывает функцию
     * для отображения текущего количества документов на новой странице.
     *
     * @param {number} newPage - Номер новой страницы.
     */
    handleChangePage = (newPage) => {
        // Обновляем состояние пагинации, устанавливая номер текущей страницы.
        this.paginationState.page = newPage;

        // Вызываем функцию для отображения текущего количества документов.
        this.showCurrentAmountDocument();
    };

    /**
     * Обработчик изменения количества строк на странице.
     *
     * Эта функция обновляет количество строк на странице в состоянии пагинации,
     * сбрасывает номер текущей страницы на 1 и вызывает функцию для отображения
     * текущего количества документов на новой странице с новым количеством строк.
     *
     * @param {number} rowsPerPage - Новое количество строк на странице.
     */
    handleChangeRowsPerPage = (rowsPerPage) => {
        // Проверяем, что значение rowsPerPage не является null или undefined.
        if (rowsPerPage != null) {
            // Обновляем состояние пагинации, устанавливая новое количество строк на странице.
            this.paginationState.rowsPerPage = rowsPerPage;
            // Сбрасываем номер текущей страницы на 1.
            this.paginationState.page = 1;

            // Вызываем функцию для отображения текущего количества документов.
            this.showCurrentAmountDocument();
        }
    };

    /**
     * Получение текущей даты и времени в формате ISO без миллисекунд.
     *
     * Эта функция создает новый объект Date для текущей даты и время,
     * которое затем преобразуется в строку в формате ISO и обрезается до
     * часов, минут и секунд, чтобы исключить миллисекунды.
     *
     * @returns {string} - Текущая дата и время в формате ISO без миллисекунд.
     */
    getISONowDate() {
        const currentDate = new Date();
        // Вычисляем разницу во времени между местным и UTC в часах.
        const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

        const destinationDateT = new Date();
        // Устанавливаем часы назначения равными часам текущей даты плюс разницу во времени.
        destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);

        return destinationDateT.toISOString().slice(0, 16);
    }

    /**
     * Изменение даты загрузки документа.
     *
     * Эта функция принимает дату в виде строки и, если она определена,
     * преобразует ее в объект Date, корректирует часовой пояс и устанавливает
     * новую дату загрузки документа в соответствующее свойство страницы доставки.
     *
     * @param {string} date - Строка, представляющая дату в формате ISO.
     */
    changeUpLoadDocumentDate(date) {
        if (date === undefined) {
            return;
        }

        const currentDate = new Date();
        // Вычисляем разницу во времени между местным и UTC в часах.
        const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

        const destinationDateT = new Date(date);
        // Устанавливаем часы назначения равными часам переданной даты плюс разницу во времени.
        destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);

        this.deliversRoutesPage.uploadDate = destinationDateT.toISOString().slice(0, 16);
    }

    /**
     * Обработчик просмотра доставки.
     *
     * Эта функция переключает состояние просмотра доставки в свойстве страницы доставки.
     */
    handleViewDeliver() {
        this.deliversRoutesPage.viewDeliver = !this.deliversRoutesPage.viewDeliver;

        // Сбрасываем настройки по умолчанию
       this.pointListTableSortState = {
            sort_name: "order_number",
            sort_value: 0
       }
    }

    /**
     * Обработчик выбора доставки.
     *
     * Эта функция устанавливает выбранные данные доставки в свойство компонента.
     *
     * @param {Object} deliverData - Данные выбранной доставки.
     */
    handleSelectDeliver(deliverData) {
        this.selectedDeliver = JSON.parse(JSON.stringify(deliverData));
        this.copySelectedDeliver = JSON.parse(JSON.stringify(deliverData));

        this.getUniqOrderClientNamesByFilter(JSON.parse(JSON.stringify(deliverData)))

    }

    // Открытие всплывающего окна, для выбора начального пункта
    openPopupDriverInfo(route_location, scrollY, typePopup) {
        this.deliversRoutesPage.popup_scroll_position = scrollY;
        this.deliversRoutesPage.popup_type = typePopup
        this.deliversRoutesPage.popup_show = true;
        this.selectedRouteLoc = route_location
    }

    closePopupDriverInfo() {
        this.deliversRoutesPage.popup_show = false;
        this.deliversRoutesPage.popup_type = null;
        this.selectedRouteLoc = {}
    }

    getUniqDataByFilter() {
        let uniqueDriversNames = [{
            filter_name: "driver_name",
            filter_text: "без фильтра",
            filter_params_name: "",
            filter_value: false
        }];

        let uniqueForwardersNames = [{
            filter_name: "forwarder_name",
            filter_text: "без фильтра",
            filter_params_name: "",
            filter_value: false
        }];


        for (const objDoc of this.deliversList) {
            const searDoubleDriversNames = uniqueDriversNames.find(obj => obj.filter_text === objDoc.drivers_info[0].driver_name);
            const searDoubleForwardersNames = uniqueForwardersNames.find(obj => obj.filter_text === objDoc.drivers_info[1].driver_name);
            if (searDoubleDriversNames === undefined && objDoc.drivers_info[0].driver_name !== "") {
                uniqueDriversNames.push(
                    {
                        filter_name: "driver_name",
                        filter_text: objDoc.drivers_info[0].driver_name,
                        filter_params_name: "drivers_info[0].driver_name",
                        filter_value: objDoc.drivers_info[0].driver_name
                    }
                );
            }

            if (searDoubleForwardersNames === undefined && objDoc.drivers_info[1].driver_name !== "") {

                uniqueForwardersNames.push(
                    {
                        filter_name: "forwarder_name",
                        filter_text: objDoc.drivers_info[1].driver_name,
                        filter_params_name: "drivers_info[1].driver_name",
                        filter_value: objDoc.drivers_info[1].driver_name
                    }
                );
            }
        }

        this.tableFilterDeliveredState.uniqueDriversNames = uniqueDriversNames;
        this.tableFilterDeliveredState.uniqueForwardersNames = uniqueForwardersNames;
    }

    getUniqOrderClientNamesByFilter(selectedDelivered) {
        let uniqueOrderClientNames = [{
            filter_name: "order_client",
            filter_text: "без фильтра",
            filter_params_name: "",
            filter_value: false
        }];


        for (const objRoute of selectedDelivered.route_location){
            const searchValue = objRoute.thisInitialOrEndPoint === true ? objRoute.name : objRoute.order_client;

            const searchDoubleOrderClientNames = uniqueOrderClientNames.find(obj => {
                return obj.filter_text === searchValue
            });

            if (searchDoubleOrderClientNames === undefined && searchValue !== "") {
                uniqueOrderClientNames.push(
                    {
                        filter_name: "order_client",
                        filter_text: searchValue,
                        filter_params_name: "order_client",
                        filter_value: searchValue
                    }
                );
            }

        }

        this.tableFilterDeliveredPointsState.uniqueOrderClientNames = uniqueOrderClientNames;

    }


    /**
     * Изменяет состояние сортировки таблицы по заданному столбцу.
     *
     * @param {string} sortName - Название столбца для сортировки.
     * @param {string} nameSortState - Имя состояния сортировки.
     */
    changeSortCollTableRouteList(sortName, nameSortState) {
        const {sort_value, sort_name} = this[nameSortState];

        // Если новый столбец сортировки отличается от текущего
        if (sortName !== sort_name) {
            // Устанавливаем сортировку по возрастанию
            this[nameSortState].sort_value = 1;
        } else {
            // Иначе, переключаем порядок сортировки
            if (this[nameSortState].sort_value < 2) {
                // Изменяем сортировку на следующий уровень (1 -> 2)
                this[nameSortState].sort_value = Number(sort_value) + 1;
            } else {
                // Сбрасываем сортировку (2 -> 0)
                this[nameSortState].sort_value = 0;
            }
        }

        // Устанавливаем текущий столбец для сортировки
        this[nameSortState].sort_name = sortName;
    }

    /**
     * Функция для сортировки списка ТТ по маршруту
     *
     * @param {string} sort_name - имя параметра, по которому нужно сортировать массив
     * @param {string} typeSort - тип сортировка "asc"/"desc"
     */
    sortedSelectedDeliverTableCols(sort_name, sortedArrName, typeSort){
        console.log(sortedArrName)
        switch (sort_name){
            case "lengthToNextPoint":
                this.copySelectedDeliver = {
                    ...this.copySelectedDeliver,
                    route_location: sortAndFilterTableColsUtils.sortBasic_Value(this[sortedArrName].route_location.slice(), "lengthToThisPoint", typeSort)
                }
                break;
            case "order_adress_delivery":
                this.copySelectedDeliver = {
                    ...this.copySelectedDeliver,
                    route_location: sortAndFilterTableColsUtils.sortBy_OrderAdressDelivery(this[sortedArrName].route_location.slice(), sort_name, typeSort)
                }
                break;
            case "order_number":
                this.copySelectedDeliver = {
                    ...this.copySelectedDeliver,
                    route_location: sortAndFilterTableColsUtils.sortBy_OrderNumber(this[sortedArrName].route_location.slice(), sort_name, typeSort)
                }
                break;
            case "dateAndTimeThisPoint":
                this.copySelectedDeliver = {
                    ...this.copySelectedDeliver,
                    route_location: sortAndFilterTableColsUtils.sortBy_DateAndTimeThisPoint(this[sortedArrName].route_location.slice(),sort_name, typeSort)
                }
                break;
            case "dateAndTimeUnload":
                this.copySelectedDeliver = {
                    ...this.copySelectedDeliver,
                    route_location: sortAndFilterTableColsUtils.sortBy_DateAndTimeUnload(this[sortedArrName].route_location.slice(), sort_name, typeSort)
                }
                break
            default:
                return
        }
    }

    /**
     * Функция для сортировки списка маршрутов
     *
     * @param {string} sort_name - имя параметра, по которому нужно сортировать массив
     * @param {string} sortedArrName - имя массива, который нужно отсортировать
     * @param {string} typeSort - тип сортировка "asc"/"desc"
     */
    sortedDeliversRouteTableCols(sort_name, sortedArrName, typeSort){
        switch (sort_name) {
            case "document_date":
                this[sortedArrName] = sortAndFilterTableColsUtils.sortBasic_Date(this[sortedArrName].slice(), sort_name, typeSort)
                break;
            case "delivery_date":
                this[sortedArrName] = sortAndFilterTableColsUtils.sortBasic_Date(this[sortedArrName].slice(), sort_name, typeSort)
                break;
            case "totalDeliveredRoutePoint":
                this[sortedArrName] = sortAndFilterTableColsUtils.sortBy_TotalDeliveredRoutePoint(this[sortedArrName].slice(), sort_name, typeSort)
                break;
            default:
                this[sortedArrName] = sortAndFilterTableColsUtils.sortBasic_Value(this[sortedArrName].slice(),sort_name,  typeSort)
        }
    }

    /**
     * Функция для выбора и сортировки списка документов
     *
     * @param {string} sortedArrName - имя массива, который нужно отсортировать
     * @param {string} nameSortState - состояние сортировки, используемое для определения параметров сортировки
     * @param {string} nameTableFilterState - имя объекта состояния для таблицы
     * @param {string} nameFileredArray - имя массива, отсортированного
     */
    selectSortedDocList(sortedArrName, nameSortState, nameTableFilterState, nameFileredArray) {

        const {sort_value, sort_name} = this[nameSortState];

        if (this[nameTableFilterState].select_filter && this[nameFileredArray] != null) {
            sortedArrName = nameFileredArray;
        } else if (this.tableSearchDeliveredState !== ""){
            sortedArrName = "copyDeliversList";
        }

        if (sort_value === 0) {
            // Восстанавливаем исходный порядок значений
            if (this[nameTableFilterState].select_filter && this[nameFileredArray] != null){
                switch (sortedArrName) {
                    case "deliversList":
                        this.copyDeliversList = JSON.parse(JSON.stringify(this[nameFileredArray]))
                        break;
                    case "filteredSelectedDeliver":
                        this.copySelectedDeliver = JSON.parse(JSON.stringify(this[nameFileredArray]))
                        break;
                    default:
                        return
                }
            } else {
                switch (sortedArrName) {
                    case "deliversList":
                        this.copyDeliversList = JSON.parse(JSON.stringify(this[nameSortState]))
                        break;
                    case "selectedDeliver":
                        this.copySelectedDeliver = JSON.parse(JSON.stringify(this[sortedArrName]))
                        break;
                    case "filteredSelectedDeliver":
                        this.copySelectedDeliver = JSON.parse(JSON.stringify(this[nameFileredArray]))
                        break;
                    default:
                        return
                }
            }

        } else if (sort_value === 1) {
            // Cортируем по возрастанию
            switch (sortedArrName){
                case "selectedDeliver":
                    this.sortedSelectedDeliverTableCols(sort_name,"copySelectedDeliver", "asc");
                    break
                case "filteredSelectedDeliver":
                    this.sortedSelectedDeliverTableCols(sort_name,"filteredSelectedDeliver", "asc");
                    break
                default:
                    this.sortedDeliversRouteTableCols(sort_name, sortedArrName, "asc")
            }
        } else if (sort_value === 2) {
            // Cортируем по убыванию
            switch (sortedArrName){
                case "selectedDeliver":
                    this.sortedSelectedDeliverTableCols(sort_name,"copySelectedDeliver", "desc");
                    break
                case "filteredSelectedDeliver":
                    this.sortedSelectedDeliverTableCols(sort_name,"filteredSelectedDeliver", "desc");
                    break
                default:
                    this.sortedDeliversRouteTableCols(sort_name, sortedArrName, "desc")
            }
        }

        sortedArrName !== "selectedDeliver" && sortedArrName !== "filteredSelectedDeliver"&& this.showCurrentAmountDocument();
    }


    filterByDeliversList(nameTableFilterState){
        let firstFilter = true;
        this.copyDeliversList = this.deliversList.slice();

        for (const selectFilter of this[nameTableFilterState].selected_filters) {
            if (selectFilter.filter_text !== "без фильтра") {
                if (firstFilter) {
                    this.filteredDeliversList = this.copyDeliversList.filter(objDoc => {
                        if (selectFilter.filter_name === "driver_name") {
                            return objDoc.drivers_info[0].driver_name === selectFilter.filter_value
                        } else if (selectFilter.filter_name === "forwarder_name") {
                            return objDoc.drivers_info[1].driver_name === selectFilter.filter_value
                        } else {
                            return objDoc[selectFilter.filter_params_name] === selectFilter.filter_value
                        }
                    })

                    firstFilter = false;
                }

                this.filteredDeliversList = this.filteredDeliversList.filter(objDoc => {
                    if (selectFilter.filter_name === "driver_name") {
                        return objDoc.drivers_info[0].driver_name === selectFilter.filter_value
                    } else if (selectFilter.filter_name === "forwarder_name") {
                        return objDoc.drivers_info[1].driver_name === selectFilter.filter_value
                    } else {
                        return objDoc[selectFilter.filter_params_name] === selectFilter.filter_value
                    }
                })


            }
        }

        if (!this[nameTableFilterState].select_filter) {
            this.selectSortedDocList("deliversList", "deliverListTableSortState", nameTableFilterState,  "filteredDeliversList");
        } else if (this.deliverListTableSortState.sort_value !== 0) {
            this.selectSortedDocList("filteredDeliversList", "deliverListTableSortState", nameTableFilterState,  "filteredDeliversList");
        } else if(this.tableSearchDeliveredState !== "") {
            this.changeSearchAddress(this.tableSearchDeliveredState)
        }else {
            this.showCurrentAmountDocument();
        }
    }

    filterBySelectedDeliver(nameTableFilterState, allNoFilter){
        let firstFilter = true;
        this.copySelectedDeliver = JSON.parse(JSON.stringify(this.selectedDeliver));
        if (allNoFilter){
            this.filteredSelectedDeliver = {...this.copySelectedDeliver}
        } else {
            for (const selectFilter of this[nameTableFilterState].selected_filters){
                if (selectFilter.filter_text !== "без фильтра") {
                    if (firstFilter) {
                        this.filteredSelectedDeliver = {
                            ...this.copySelectedDeliver,
                            route_location: this.copySelectedDeliver.route_location.slice().filter(objRoute => {
                                if (selectFilter.filter_name === "order_client") {
                                    const searchValue = objRoute.thisInitialOrEndPoint === true ? objRoute.name : objRoute.order_client;
                                    return searchValue === selectFilter.filter_value
                                } else if (selectFilter.filter_name === "delivered_status") {
                                    const searchValue = objRoute.thisInitialOrEndPoint === true ? "Начальная/Конечная точка" : objRoute.delivered_status;
                                    return searchValue === selectFilter.filter_value
                                } else {
                                    // return objDoc[selectFilter.filter_params_name] === selectFilter.filter_value
                                }
                            })
                        }

                        firstFilter = false;
                    }

                    this.filteredSelectedDeliver = {
                        ...this.filteredSelectedDeliver,
                        route_location: this.filteredSelectedDeliver.route_location.filter(objRoute => {
                            if (selectFilter.filter_name === "order_client") {
                                const searchValue = objRoute.thisInitialOrEndPoint === true ? objRoute.name : objRoute.order_client;
                                return searchValue === selectFilter.filter_value
                            } else if (selectFilter.filter_name === "delivered_status") {
                                const searchValue = objRoute.thisInitialOrEndPoint === true ? "Начальная/Конечная точка" : objRoute.delivered_status;
                                return searchValue === selectFilter.filter_value
                            } else {
                                // return objDoc[selectFilter.filter_params_name] === selectFilter.filter_value
                            }
                        })
                    }


                }
            }
        }


        if (!this[nameTableFilterState].select_filter) {
            this.selectSortedDocList("filteredSelectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver");
        } else if (this.deliverListTableSortState.sort_value !== 0) {
            this.selectSortedDocList("filteredSelectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver");
        } else {
            this.selectSortedDocList("filteredSelectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver");
        }
    }

    selectFilterAndUpdateDocList(selectFilter, nameTableFilterState) {

        this[nameTableFilterState].select_filter = true;

        if (selectFilter !== "UpdateDocList") {
            const foundFilter = this[nameTableFilterState].selected_filters.find((obj, index) => {
                if (obj.filter_name === selectFilter.filter_name) {
                    this[nameTableFilterState].selected_filters[index] = selectFilter;
                    return true
                }
                return false
            });

            if (!foundFilter) {
                this[nameTableFilterState].selected_filters.push(selectFilter);
            }
        }

        const allNoFilter = this[nameTableFilterState].selected_filters.every(obj => obj.filter_text === "без фильтра");

        if (allNoFilter) {
            this[nameTableFilterState].select_filter = false;
        }

        if(nameTableFilterState === "tableFilterDeliveredPointsState"){
            this.filterBySelectedDeliver(nameTableFilterState, allNoFilter);
        } else if (nameTableFilterState === "tableFilterDeliveredState"){
            this.filterByDeliversList(nameTableFilterState);
        }



    }

    changeSearchAddress(newSearValue) {
        if (this.tableSearchDeliveredState === "") {
            if (this.tableFilterDeliveredState.select_filter) {
                this.selectFilterAndUpdateDocList("UpdateDocList", "tableFilterDeliveredState");
            } else {
                this.copyDeliversList = [...this.deliversList];
            }
        }

        this.tableSearchDeliveredState = newSearValue;
        const regex = new RegExp(`^.*${newSearValue.split(' ').join('.*')}.*$`, 'i');

        if (this.tableFilterDeliveredState.select_filter && this.filteredDeliversList.length > 0){
            this.copyDeliversList = this.filteredDeliversList.filter(docObj =>
                regex.test(docObj.document_number.toString()) || regex.test(docObj.vehicle_number.toString()) || regex.test(docObj.drivers_info[0]?.driver_name.toString()) || regex.test(docObj.drivers_info[1]?.driver_name.toString())
            )
        } else {
            this.copyDeliversList = this.deliversList.filter(docObj =>
                regex.test(docObj.document_number.toString()) || regex.test(docObj.vehicle_number.toString()) || regex.test(docObj.drivers_info[0]?.driver_name.toString()) || regex.test(docObj.drivers_info[1]?.driver_name.toString())
            )
        }

    }


}

export default new DeliversRoutes();

