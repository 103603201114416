import React, {memo, useEffect} from 'react';
import {ReactComponent as SettingIcon} from '../../../imges/SettingIcon.svg';
import style from './AddressCoordinateItem.module.css';
import {Tooltip} from 'react-tooltip';
import {ReactComponent as InfoIcon} from '../../../imges/InfoIcon.svg';
import {observer} from "mobx-react-lite";
import editCoordinatesAddress
    from "../../../store/transportationLogisticsService/pageEditCoordinatesAddress/editCoordinatesAddress";
import {Link, useNavigate, useParams} from "react-router-dom";

const AddressCoordinateItem = observer(({addressData, showSelectAddressToMap, uid, indexElem}) => {
    const {addressList, statePage, listScrollToAddress} = editCoordinatesAddress;

    const changeSettingAddress = () => {

    }

    return (
        <div
            onClick={() => {
                showSelectAddressToMap(addressData)
            }}
            // className={(!document.show_to_map) ? style.routeReadyDocument : `${style.routeReadyDocument} ${style.active}`
            // }
            className={(statePage.index_edit_address === indexElem) ? `${style.addressCoordsItem} ${style.addressCoordsItemActive}` : style.addressCoordsItem}
        >
            <div
            >
                <div data-tooltip-id={`client-info_${uid}`}
                     data-tooltip-variant="info">
                    {(addressData.client.length > 20) ? addressData.client.slice(0, 20) + "..." : addressData.client}
                    {(addressData.client.length > 20) ? <Tooltip
                        id={`client-info_${uid}`}
                        content={addressData.client}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
                <div data-tooltip-id={`address-info_${uid}`}
                     data-tooltip-variant="info">
                    {(addressData.address.length > 45) ? addressData.address.slice(0, 40) + "..." : addressData.address}
                    {(addressData.address.length > 45) ? <Tooltip
                        id={`address-info_${uid}`}
                        content={addressData.address}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
            </div>
            <div>
                {
                    (addressData.lat === null || addressData.lon === null) ?
                        <div
                            data-tooltip-id="address-info-icon"
                            data-tooltip-variant="info"
                            className={style.infoIcon}
                        >
                            <InfoIcon/>
                        </div>
                        :
                        <>
                            <div>{(String(addressData.lat).length > 9) ? String(addressData.lat).slice(0, 9) : addressData.lat}</div>
                            <div>{(String(addressData.lon).length > 9) ? String(addressData.lon).slice(0, 9) : addressData.lon}</div>
                        </>
                }
                <Tooltip
                    place={"top"}
                    style={{zIndex: 5}}
                    id="address-info-icon"
                    html="По данному адресу отсутствуют координаты. <br/> Перейдите в редактирование, чтобы исправить ошибку."
                />
            </div>
            <div
                className={style.addressSetting}
            >
                <Link to={`/logisticService/addressCoordinateEditing/editAddress/${addressData.id}`}>
                    <SettingIcon/>
                </Link>
            </div>
        </div>
    );
})

export default memo(AddressCoordinateItem);