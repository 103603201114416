import React, {useCallback, useState} from 'react';
import Skeleton from '@mui/material/Skeleton';
import style from './DeliveryVehiclesEditForm.module.css'
import pageDeliveryVehiclesStore
    from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
import {observer} from "mobx-react-lite";
import debounce from "lodash/debounce";
import DeliveryVehiclesEditForm from "./DeliveryVehiclesEditForm";
import {useNavigate} from "react-router-dom";

const DeliveryVehiclesEditFormSkeleton = observer(() => {

    return (
        <>
            <div className={style.createForm}>
                <div className={style.settingDeliveryZoneNavCreateNameZone}>
                    <span>Наименование:</span>
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={236}
                        height={31}
                    />
                </div>

                <div className={style.settingDeliveryZoneNavCreateNameZone}>
                    <span>Гос. номер:</span>
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={236}
                        height={31}
                    />
                </div>

                <div className={style.settingDeliveryZoneNavCreateNameZone}>
                    <span>Марка автомобиля:</span>
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={236}
                        height={31}
                    />
                </div>

                <div className={style.settingDeliveryZoneNavCreateNameZone}>
                    <span>Грузоподъемность (тонны):</span>
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={236}
                        height={31}
                    />
                </div>
            </div>
        </>
    )
        ;
});

export default DeliveryVehiclesEditFormSkeleton;