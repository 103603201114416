import React, {memo} from 'react';
import style from './DeliversRoutesSelectedItem.module.css';
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import DeliversRoutesSelectedPointList from "../DeliversRoutesSelectedPointList/DeliversRoutesSelectedPointList";

const DeliversRoutesSelectedItem = () => {
    const {document_number, document_date, delivery_date, route_location, drivers_info, route_completed, route_summary} = deliversRoutes.selectedDeliver;
    const handleViewDelivered = () =>{
        deliversRoutes.handleViewDeliver();
    }

    const getCurrentDate = (valueDate) => {
        let date = new Date(valueDate);

        // Форматирование даты и времени в формат "dd.mm.yyyy hhч mmм"
        return date.getDate().toString().padStart(2, '0') + '.' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
            date.getFullYear();
    }

    const getCurrentTime = (valueDate) => {
        let date = new Date(valueDate);

        return date.getHours().toString().padStart(2, '0') + 'ч ' +
        date.getMinutes().toString().padStart(2, '0') + 'м';
    }

    const getCurrentTimeAllRoute = () => {
        if (route_summary?.time){
            const milliseconds = route_summary?.time * 1000;
            let hours = Math.floor(milliseconds / (1000 * 60 * 60));
            let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
            return `${hours}ч ${minutes}м`;
        }
    }

    return (
        <div className={style.deliversInfo}>
            <div className={style.deliversInfoHeader}
            >{`Информация по маршруту № ${document_number}`}
            </div>
            <button
                className={style.prevBtn}
            onClick={()=> handleViewDelivered()}
            >
                Назад
            </button>

            { deliversRoutes.selectedDeliver && <div className={style.deliversInfoContent}>
                <div className={style.infoContent}>
                    <div>Дата документа</div>
                    <div>{getCurrentDate(document_date)}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Дата доставки</div>
                    <div>{getCurrentDate(delivery_date)}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Время выезда</div>
                    <div>{getCurrentTime(delivery_date)}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Торговых точек</div>
                    <div>{route_location.length - 2}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Водитель</div>
                    <div>{drivers_info[0].driver_name}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Экспедитор</div>
                    <div>{drivers_info[1].driver_name}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Статус маршрута</div>
                    <div>{(route_completed) ? `Завершен` : `В маршруте`}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Время на маршрут</div>
                    <div>{getCurrentTimeAllRoute()}</div>
                </div>
                <div className={style.infoContent}>
                    <div>Километраж маршрута</div>
                    <div>{`${Number(route_summary.length).toFixed(1)} км`}</div>
                </div>
            </div>}

            <DeliversRoutesSelectedPointList/>

        </div>
    );
};

export default memo(DeliversRoutesSelectedItem);