import {
  // user,
  distributors,
  manufacturer,
  profileDistibutorRetail,
  profileDistibutorRegisterDate,
  sku,
  counteragentsAndRetailOutlets,
  distibutorNomenclature,
  counterparties,
  retailOutlets,
  docs,
  subscriptions,
  registerDates,
  docsSum,
  distributorCurrentRemains,
  distributorHistoryRemains,
  manufacturerHistoryRemains,
  distributorUsers,
  manufacturerNomenclature,
  totalSells,
  profileDistributors,
  profileManufacturersList,
  distributorDocsSum,
  distributorCounteragentsAndRetailOutlets,
  manufacturerSKU,
  manufacturerCurrentRemains,
} from "./apiDataSave";

export const reducers = {
  // user,
  manufacturer,
  docsSum,
  sku,
  counteragentsAndRetailOutlets,
  distributors,
  counterparties,
  distibutorNomenclature,
  registerDates,
  subscriptions,
  retailOutlets,
  profileDistibutorRetail,
  distributorHistoryRemains,
  distributorCurrentRemains,
  distributorUsers,
  manufacturerNomenclature,
  manufacturerHistoryRemains,
  totalSells,
  profileDistributors,
  profileManufacturersList,
  profileDistibutorRegisterDate,
  distributorDocsSum,
  distributorCounteragentsAndRetailOutlets,
  manufacturerSKU,
  manufacturerCurrentRemains
};
