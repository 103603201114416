import React from 'react';
import style from './ModalEditRouteSheetTableHeader.module.css';
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const ModalEditRouteSheetTableHeader = observer(() => {
    const {tableEditRouteSheetsState:{selectAll}} = createRouteSheetsStore

    return (
        <div className={style.tableHeader}>
            <div className={style.checkbox}
                onClick={(e) => {
                    e.preventDefault()
                    createRouteSheetsStore.changeSelectedAllInTable(!selectAll, "orderInRoute")
                }}
            >
                <input
                    type="checkbox"
                    className={style.selectDocumentML}
                    id="selectAll_AreaList"
                    name="selectAll_AreaList"
                    value={selectAll}
                    checked={selectAll}
                    onChange={(e) => {}}
                />
                <label htmlFor="selectAll_AreaList"></label>
            </div>
            <div className={style.address}>Адрес</div>
            <div className={style.client}>Клиент</div>
            <div className={style.tonnage}>Вес</div>
            <div className={style.delete}>Удалить</div>
        </div>
    );
});

export default ModalEditRouteSheetTableHeader;