import React, {memo} from 'react';
import style from './DeliversRoutesItemList.module.css'
import {ReactComponent as InfoRouteIcon} from '../../../../imges/InfoRouteIcon.svg';
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";

const DeliversRoutesItemList = ({routeData}) => {
    const {document_number, route_location, route_completed, vehicle_number, drivers_info,document_date,delivery_date} = routeData;

    const getCurrentDate = (valueDate) => {
        let date = new Date(valueDate);

        // Форматирование даты и времени в формат "dd.mm.yyyy hhч mmм"
        return date.getDate().toString().padStart(2, '0') + '.' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
            date.getFullYear();
    }

    const countDeliveredPoint = () => {
        let routePoints = [...route_location];
        routePoints.shift();
        routePoints.pop();

        return routePoints.filter(obj => obj.delivered === true).length;
    }

    const handleViewDelivered = () =>{
        deliversRoutes.handleViewDeliver();
        deliversRoutes.handleSelectDeliver(routeData);
    }

    return (
        <div onClick={() => handleViewDelivered()}  className={style.deliversItem}>
            <div className={style.numberDoc}>{document_number}</div>
            <div className={style.progressRoute}>{`${countDeliveredPoint()} из ${route_location.length - 2}`}</div>
            <div className={style.statusAndCar}>
                <div>{(route_completed) ? "Завершен" : "В маршруте"}</div>
                <div>{vehicle_number}</div>
            </div>
            <div className={style.dateDocAndDelivery}>
                <div>{getCurrentDate(document_date)}</div>
                <div>{getCurrentDate(delivery_date)}</div>
            </div>
            <div className={style.driverAndForward}>
                <div title={drivers_info[0].driver_name}>{drivers_info[0].driver_name}</div>
                <div title={drivers_info[1].driver_name}>{drivers_info[1].driver_name}</div>
            </div>
            <div className={style.infoRoute}
                // onClick={() => handleViewDelivered()}
            >
                <InfoRouteIcon/>
            </div>
        </div>
    );
};

export default memo(DeliversRoutesItemList);