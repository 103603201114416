import React, {useCallback, useEffect, useState, memo, useRef, useMemo} from 'react';
import InputMask from "react-input-mask";
import {Button, Popover} from "@mui/material";
import Calendar from "react-calendar";
import debounce from "lodash/debounce";
import {ReactComponent as SelectDataIcon} from '../../../imges/SelectDataIcon.svg';
import style from './DatePicker.module.css';
import './DatePicker.css';
import {observer} from "mobx-react-lite";
import moment from "moment";



const DatePicker = ({value, onChange,typeChangeDate, typeSetting}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [date, setDate] = useState(value);
    const inputRef = useRef(null);

    const firstRender = useRef(true);

    /**
     * Форматирует указанную дату в строку в формате "DD.MM.YYYY".
     *
     * @param {Date} date - дата для форматирования (опционально, по умолчанию используется текущая дата)
     * @return {string} отформатированная строка даты
     */
    const formatDate = (date = new Date()) => {
        const dataDate = new Date(date);
        if (!dataDate) {
            return "";
        }

        const day = dataDate?.getDate().toString().padStart(2, '0');
        const month = (dataDate?.getMonth() + 1).toString().padStart(2, '0');
        const year = dataDate?.getFullYear();

        return `${day}.${month}.${year}`;
    };

    const [inputDate, setInputDate] = useState((typeSetting === "generalSettingForDocuments")?value:formatDate(date));

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            onChange((value === undefined) ? new Date() : value);
        } else {
            if (typeSetting === "generalSettingForDocuments"){
                onChange(value);
            }else{
                onChange(value);
            }
        }
    }, [value]);

    /**
     * Обрабатывает событие изменения ввода, обрабатывает значение ввода и обновляет дату, если она действительна.
     *
     * @param {object} e - Объект события
     * @return {void}
     */
    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value === null){
            setInputDate(null)
        } else {
            const [day, month, year] = value.split(".");
            const newDate = new Date(`${year}-${month}-${day}`);
            const isValid = moment(newDate, 'MM/DD/YYYY', true).isValid();
            setInputDate(value);
            if (isValid){
                delayedUpdateDate(value);
            }
        }

    };

    /**
     * Задерживает обновление даты на указанное время после изменения значения ввода.
     *
     * @param {string} value - Значение ввода
     * @return {void}
     */
    const delayedUpdateDate = useCallback(
        debounce((value) => {
            const [day, month, year] = value.split(".");
            const newDate = new Date(`${year}-${month}-${day}`);
            setDate(newDate);

            if (!isNaN(new Date(newDate).getTime())){
                onChange(newDate);
            }

        }, 3000), // Задержка в миллисекундах
        []
    );

    useEffect(() => {
        if (date === null || date === undefined){
            setInputDate(null)
        } else {
            setInputDate(formatDate(date)); // Обновление inputDate при изменении date
        }

    }, [date]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDateChange = (newDate) => {
        setDate(new Date(newDate));
        handleClose();

        onChange(new Date(newDate));
    };

    return (
        <div className={style.selectedDataDocumentBlock}>
            <InputMask
                inputRef={inputRef}
                onChange={handleInputChange}
                value={inputDate || ""}
                mask="99.99.9999"
                maskChar=" "
                placeholder="ДД.ММ.ГГГГ"
            />
            <Button
                sx={{
                    borderRadius: "0px",
                    borderTopRightRadius: "7px",
                    borderBottomRightRadius: "7px",
                    maxWidth: "55px",
                    minWidth: "55px",
                    height: "29px",
                    backgroundColor: "rgb(0, 110, 202, 1)",
                    zIndex: 5
                }}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
            >
                <SelectDataIcon/>
            </Button>
            <Popover
                disableScrollLock={true}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{zIndex: 9999999999}}
            >
                <div>
                    <Calendar
                        onChange={handleDateChange}
                        value={date}
                        locale="ru-RU"
                    />
                </div>
            </Popover>
        </div>
    );
};

export default memo(DatePicker);