import React from 'react';
import style from './DeliveryAreaCreateForm.module.css'
import createNewZona from "../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone";
import {observer} from "mobx-react-lite";

const DeliveryAreaCreateForm = observer(() => {
    return (
        <div className={style.createForm}>
            <div className={style.settingDeliveryZoneNavCreateNameZone}>
                <span>Наименование зоны:</span>
                <input type="text" required={true} value={createNewZona.newZona.nameZona}
                       placeholder="Наименование зоны..."
                       onChange={(e) => createNewZona.updateNameZone(e.target.value)}/>
            </div>

            <div className={style.settingDeliveryZoneNavCreateColorZone}>
                <span>Цвет зоны:</span>
                <input
                    type="color" required={true} value={createNewZona.newZona.color_area}
                    onChange={(e) => createNewZona.updateColorZona(e.target.value)}/>
            </div>
        </div>
    );
});

export default DeliveryAreaCreateForm;