import React, {useContext, useEffect, useRef, useState} from 'react';
import style from './CreateRouteSheetsMap.module.css'
import mmrgl from 'mmr-gl';
import 'mmr-gl/dist/mmr-gl.css';
import globalState from "../../../../store/transportationLogisticsService/globalState";
import GlobalContext from "../../../../context/GlobalContext";
import MapData_CreateRouteSheets
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/mapData_CreateRouteSheets";
import {observer} from 'mobx-react-lite';
import RequestLimitLogistics from '../../../RequestLimitLogistics/RequestLimitLogistics';
import {ReactComponent as SettingIcon} from '../../../../imges/SettingMenuIcons.svg';
import {ReactComponent as ShowSidebar} from '../../../../imges/ShowSidebar.svg';
import createRouteSheetsStore, {resetStore}
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import PointDisplayOptionsPopup
    from "../../../../popups/logisticService/PointDisplayOptionsPopup/PointDisplayOptionsPopup";

const CreateRouteSheetsMap = observer(() => {
        const {popupState: {popupShow, popupType, popupScrollPosition},
            sideBlocksContentView:{leftBlock, rightBlock} } = createRouteSheetsStore
        const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
        const mapRef = useRef(null);
        const [tariff, setTariff] = useState(true);

        /**
         * Хук useEffect для инициализации карты при загрузке компонента
         */
        useEffect(() => {

            async function initMap() {
                try {
                    const resultCheck = await globalState.checkCountOrTariff("mapСount", authTokens, setUser, setAuthTokens, logoutUser);
                    setTariff(resultCheck.status === 200 ? true : false)
                    if (resultCheck.status === 200) {
                        // Инициализация карты при загрузке компонента
                        mmrgl.accessToken = process.env.REACT_APP_ACCES_TOKEN_FOR_VKMAP;
                        // mmrgl.accessToken = "";

                        /**
                         * Создание нового экземпляра карты
                         */
                        const map = new mmrgl.Map({
                            container: 'map',
                            zoom: 12,
                            center: [34.11823, 44.94554],
                            style: 'mmr://api/styles/main_style.json',
                            hash: false
                        });

                        map.boxZoom.disable();
                        map.doubleClickZoom.disable();

                        mapRef.current = map;
                        MapData_CreateRouteSheets.setMapData(mapRef.current)
                        createRouteSheetsStore.initializationSelectionBox();
                    }
                } catch (e) {
                    console.error(e)
                }
            }

            initMap();

            // Функция очистки для удаления карты при размонтировании компонента
            return () => {
                if (mapRef.current) {
                    MapData_CreateRouteSheets.clearAreaMap();
                    MapData_CreateRouteSheets.clearOrderPoint();
                    mapRef.current.remove();
                    MapData_CreateRouteSheets.setMapData(null);
                    createRouteSheetsStore.clearStore()
                }
            };
        }, []);

        const changePointDisplayOption = () => {
            createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "changePointDisplayOption");
        }

        const changePointDisplayOptions = async (newOptions) => {
            await createRouteSheetsStore.changePointDisplayOptions(newOptions)
        }

        const toggleSideBlocksVisibility = (blockName, value) => {
            createRouteSheetsStore.toggleSideBlocksVisibility(blockName, value);
        }

        return (
            <div className={style.mapContent}>
                {tariff ?
                    <div id="map" style={{width: '100%', height: '95vh'}}>
                    </div>
                    : <RequestLimitLogistics/>}
                <div className={style.setting}
                     onClick={() => changePointDisplayOption()}
                >
                    <SettingIcon/>
                </div>

                <PointDisplayOptionsPopup
                    isOpen={popupShow && popupType === "changePointDisplayOption"}
                    onClose={() => createRouteSheetsStore.closePopup()}
                    scrollPosition={popupScrollPosition}
                    onSave={(newOptions) => changePointDisplayOptions(newOptions)}
                />

                <div className={`${style.showSidebarBtn} ${style.leftPosition}
                ${(leftBlock)? style.rotateBtn : ""}
                `} onClick={() => toggleSideBlocksVisibility("leftBlock", !leftBlock)}>
                    <ShowSidebar/>
                </div>
                <div className={`${style.showSidebarBtn} ${style.rightPosition}
                ${(rightBlock)? "" : style.rotateBtn }
                `} onClick={() => toggleSideBlocksVisibility("rightBlock", !rightBlock)}>
                    <ShowSidebar/>
                </div>
            </div>
        );
    }
);

export default CreateRouteSheetsMap;