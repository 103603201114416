import React, {memo} from 'react';
import deliversRoutes from "../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import style from "../DeliversRoutesPageComponents/DeliversRoutesSelectedPointList/DeliversRoutesSelectedPointList.module.css";
import DriverMessagesInfo from "../DriverMessagesInfo/DriverMessagesInfo";
import PopupRouting from "../../../popups/logisticService/ModalMaster/ModalMaster";
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import {observer} from "mobx-react-lite";

const DriverMessages = observer(() => {
    const {popup_type, popup_show, popup_scroll_position} = deliversRoutes.deliversRoutesPage
    return (
        <>
            <PopupRouting
                isOpen={popup_show && popup_type === "driverMessage"}
                onClose={() => {
                    deliversRoutes.closePopupDriverInfo()
                }}
                scrollPosition={popup_scroll_position}
                styles={{overflow: "hidden"}}
            >
                <div className={style.popupHeader}>
                    Информация от водителя
                    <div
                        className={style.btnClosePopup}
                        onClick={() => {
                            deliversRoutes.closePopupDriverInfo()
                        }}
                    ><DeleteIcon/></div>
                </div>
                <DriverMessagesInfo/>
            </PopupRouting>
        </>
    );
});

export default memo(DriverMessages);