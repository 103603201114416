import React, {useEffect} from 'react';
import style from './DeliveryAreaCreate.module.css'
import './DeliveryAreaCreate.css'
import {observer} from 'mobx-react-lite';
import createNewZona from '../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone'
import "driver.js/dist/driver.css";
import DeliveryAreaCreateHeaderList from "../DeliveryAreaCreateHeaderList/DeliveryAreaCreateHeaderList";
import DeliveryAreaCreatePointList from "../DeliveryAreaCreatePointList/DeliveryAreaCreatePointList";
import DeliveryAreaCreateForm from "../DeliveryAreaCreateForm/DeliveryAreaCreateForm";
import {useNavigate} from "react-router-dom";
import DeliveryAreaCreateActionBtnsGroup from "../DeliveryAreaCreateActionBtnsGroup/DeliveryAreaCreateActionBtnsGroup";
import MapData_DeliveryArea
    from "../../../../store/transportationLogisticsService/PageDeliveryZone/mapData_DeliveryArea";


const DeliveryAreaCreate = observer(({typeAction}) => {
    const navigate = useNavigate();

    useEffect(() => {
        createNewZona.initializationCreatePage();

    }, [MapData_DeliveryArea.mapData])

    useEffect(() => {
        return () => {
            createNewZona.clearMap();
            createNewZona.changeDefaultValue();
        }
    }, []);

    return (
        <div className={style.settingDeliveryZoneNavCreateZone}>

            <div className={style.settingDeliveryZoneNavHeader}>
                <div className={style.goBackToList}
                     onClick={() => navigate(-1)}>Назад
                </div>
                <div>Создание зоны</div>
            </div>

            <DeliveryAreaCreateForm/>

            <DeliveryAreaCreateActionBtnsGroup/>

            <DeliveryAreaCreateHeaderList/>

            <DeliveryAreaCreatePointList/>

        </div>);
});

export default DeliveryAreaCreate;