import React, { memo, useState } from "react";
import style from "./RouteSheetsTableHeader.module.css";
import { ReactComponent as FilterTableIcon } from "../../../../imges/FilterTableIcon.svg";
import { ReactComponent as ArrowUp } from "../../../../imges/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../../imges/ArrowDown.svg";
import optimizationRouteSheetStore from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import PopoverBasic from "../../UI/PopoverBasic/PopoverBasic";
import { observer } from "mobx-react-lite";
import toast from "react-hot-toast";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toastMassage } from "../../../ToastMassage/ToastMassage";

const RouteSheetsTableHeader = observer(() => {
  const {
    routingSheets,
    tableFilterRouteSheetsState: {
      uniqueDriversNames,
      statusDocumentList,
      selected_filters,
    },
    routeListTableSortState: { sort_value, sort_name },
  } = optimizationRouteSheetStore;
  const { setting_document, checked_all_document_route } =
    optimizationRouteSheetStore.pageState;

  const [showPopover_StatusDoc, setShowPopover_StatusDoc] = useState(false);
  const [showPopover_DriverName, setShowPopover_DriverName] = useState(false);
  const [newValuePopover, setNewValuePopover] = useState(null);

  // Записываем новое значение того, сколько элементов отображать на странице
  const getSelectedValueDestination = (newValue, filterName) => {
    setNewValuePopover(newValue);
    if (filterName === "status_document") {
      setShowPopover_StatusDoc(false);
    } else if (filterName === "driver_name") {
      setShowPopover_DriverName(false);
    }

    if (newValue === "closePopover") {
      return;
    }

    if (!routingSheets) {
      toastMassage(
        toast.error,
        "Отсутствуют загруженные документы",
        10000,
        "bottom-right"
      );
      return;
    }

    optimizationRouteSheetStore.selectFilterAndUpdateDocList(newValue);
  };

  const handlePopoverVision = (filterName) => {
    if (!routingSheets) {
      toastMassage(
        toast.error,
        "Отсутствуют загруженные документы",
        10000,
        "bottom-right"
      );
      return;
    } else if (newValuePopover === "closePopover") {
      setNewValuePopover(null);
      return;
    }

    if (filterName === "status_document") {
      setShowPopover_StatusDoc(!showPopover_StatusDoc);
    } else if (filterName === "driver_name") {
      setShowPopover_DriverName(!showPopover_DriverName);
    }
  };

  const checkedFilerStatus = (filterName) => {
    if (selected_filters.length === 0) {
      return true;
    }
    for (const filter of selected_filters) {
      if (
        filter.filter_name === filterName &&
        filter.filter_text === "без фильтра"
      ) {
        return true;
      }
    }
    return false;
  };

  const getSelectOptionPopover = (filterName) => {
    if (selected_filters.length === 0) {
      return;
    }

    return selected_filters.find((filter) => filter.filter_name === filterName);
  };

  return (
    <div className={style.routeListHeader}>
      <div>
        <input
          type="checkbox"
          className={style.selectDocumentML}
          id="selectDocumentMlALL"
          name="selectDocumentMlALL"
          value={checked_all_document_route}
          checked={checked_all_document_route}
          onChange={(e) => {
            optimizationRouteSheetStore.selectAllDocumentRoute(
              !checked_all_document_route
            );
          }}
        />
        <label htmlFor="selectDocumentMlALL"></label>
      </div>
      <div
        onClick={() => {
          if (!routingSheets) {
            toastMassage(
              toast.error,
              "Отсутствуют загруженные документы",
              10000,
              "bottom-right"
            );
            return;
          }
          optimizationRouteSheetStore.changeSortCollTableRouteList(
            "document_number"
          );
          optimizationRouteSheetStore.selectSortedDocList("routingSheets");
        }}
      >
        Номер
        <div>
          <span
            style={{
              visibility:
                ((sort_value === 1 || sort_value === 0) &&
                  sort_name === "document_number") ||
                sort_name !== "document_number"
                  ? "visible"
                  : "hidden",
            }}
            className={
              sort_value === 1 && sort_name === "document_number"
                ? style.activeArrow
                : ""
            }
          >
            <ArrowUp />
          </span>
          <span
            style={{
              visibility:
                ((sort_value === 2 || sort_value === 0) &&
                  sort_name === "document_number") ||
                sort_name !== "document_number"
                  ? "visible"
                  : "hidden",
            }}
            className={
              sort_value === 2 && sort_name === "document_number"
                ? style.activeArrow
                : ""
            }
          >
            <ArrowDown />
          </span>
        </div>
      </div>
      <div>
        Статус документа
        <span
          className={`
                                         ${
                                           showPopover_StatusDoc
                                             ? style.showPopover
                                             : ""
                                         }
                                         ${
                                           checkedFilerStatus("status_document")
                                             ? ""
                                             : style.selectFiler
                                         }
                                    `}
          onClick={() => handlePopoverVision("status_document")}
        >
          <FilterTableIcon />
        </span>
        <PopoverBasic
          selectOptions={getSelectOptionPopover("status_document")}
          options={statusDocumentList}
          onChange={(newValue) =>
            getSelectedValueDestination(newValue, "status_document")
          }
          open={showPopover_StatusDoc}
        />
      </div>
      <div>
        <div
          onClick={() => {
            if (!routingSheets) {
              toastMassage(
                toast.error,
                "Отсутствуют загруженные документы",
                10000,
                "bottom-right"
              );
              return;
            }
            optimizationRouteSheetStore.changeSortCollTableRouteList(
              "document_date"
            );
            optimizationRouteSheetStore.selectSortedDocList("routingSheets");
          }}
          className={style.documentDate}
        >
          Дата документа
          <div>
            <span
              style={{
                visibility:
                  ((sort_value === 1 || sort_value === 0) &&
                    sort_name === "document_date") ||
                  sort_name !== "document_date"
                    ? "visible"
                    : "hidden",
              }}
              className={
                sort_value === 1 && sort_name === "document_date"
                  ? style.activeArrow
                  : ""
              }
            >
              <ArrowUp />
            </span>
            <span
              style={{
                visibility:
                  ((sort_value === 2 || sort_value === 0) &&
                    sort_name === "document_date") ||
                  sort_name !== "document_date"
                    ? "visible"
                    : "hidden",
              }}
              className={
                sort_value === 2 && sort_name === "document_date"
                  ? style.activeArrow
                  : ""
              }
            >
              <ArrowDown />
            </span>
          </div>
        </div>
        <div
          onClick={() => {
            if (!routingSheets) {
              toastMassage(
                toast.error,
                "Отсутствуют загруженные документы",
                10000,
                "bottom-right"
              );
              return;
            }
            optimizationRouteSheetStore.changeSortCollTableRouteList(
              "delivery_date"
            );
            optimizationRouteSheetStore.selectSortedDocList("routingSheets");
          }}
          className={style.documentDelivery}
        >
          Дата доставки
          <div>
            <span
              style={{
                visibility:
                  ((sort_value === 1 || sort_value === 0) &&
                    sort_name === "delivery_date") ||
                  sort_name !== "delivery_date"
                    ? "visible"
                    : "hidden",
              }}
              className={
                sort_value === 1 && sort_name === "delivery_date"
                  ? style.activeArrow
                  : ""
              }
            >
              <ArrowUp />
            </span>
            <span
              style={{
                visibility:
                  ((sort_value === 2 || sort_value === 0) &&
                    sort_name === "delivery_date") ||
                  sort_name !== "delivery_date"
                    ? "visible"
                    : "hidden",
              }}
              className={
                sort_value === 2 && sort_name === "delivery_date"
                  ? style.activeArrow
                  : ""
              }
            >
              <ArrowDown />
            </span>
          </div>
        </div>
      </div>
      <div>
        <div>
          Водитель
          <span
            className={`
                                         ${
                                           showPopover_DriverName
                                             ? style.showPopover
                                             : ""
                                         }
                                         ${
                                           checkedFilerStatus("driver_name")
                                             ? ""
                                             : style.selectFiler
                                         }
                                    `}
            onClick={() => handlePopoverVision("driver_name")}
          >
            <FilterTableIcon />
          </span>
          <PopoverBasic
            selectOptions={getSelectOptionPopover("driver_name")}
            options={uniqueDriversNames}
            onChange={(newValue) =>
              getSelectedValueDestination(newValue, "driver_name")
            }
            open={showPopover_DriverName}
          />
        </div>
        <div>Номер ТС</div>
      </div>
      <div>Начальная точка</div>
      <div>Параметры</div>
    </div>
  );
});

export default memo(RouteSheetsTableHeader);
