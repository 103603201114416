import React, {useCallback, useEffect, useState} from 'react';
import style from './ModalSelectedInitialPoint.module.css';
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import CreateRouteSheetsOrderListModalSelectedInitialPoint
    from "../ModalChangeRouteParam/ModalChangeRouteParam";
import {observer} from "mobx-react-lite";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import {AutoSizer, List} from "react-virtualized";
import InitialPointPopupItem from "../../InitialPointPopupItem/InitialPointPopupItem";
import {v4 as uuidv4} from "uuid";
import ModalSelectedInitialPointItem from "../ModalSelectedInitialPointItem/ModalSelectedInitialPointItem";
import debounce from "lodash/debounce";
import routeParameterModalHOC from "../hoc/RouteParameterModalHOC";
import ModalSelectedVehicle from "../ModalSelectedVehicle/ModalSelectedVehicle";
import routeParamHandlerHoc from "../hoc/RouteParamHandlerHOC";



const ModalSelectedInitialPoint = observer(({newSearchValue, nameArrayData, nameChange, typeAction}) => {

    const listData = createRouteSheetsStore[nameArrayData]
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        return () => {
            createRouteSheetsStore.changeDefaultModalData(nameArrayData)
        }
    }, [])

    const ModalSelectedInitialPointItemHOC = routeParamHandlerHoc(
        ModalSelectedInitialPointItem,
        "changeInitPoint",
        typeAction
    )

    const rowRenderer = ({index, key, style}) => {
        return (
            <div key={key} style={style}>
                <ModalSelectedInitialPointItemHOC
                    paramRowData={listData[index]}
                    key={uuidv4()}
                />
            </div>
        );
    };

    const changeNewSearchValue = (newValue) => {
        setSearchValue(newValue);
        newSearchValue(newValue);
    }

    return (
        <div className={style.selectInitialPointContent}>
            <div className={style.popupHeader}>
                <div className={style.goBackToList}
                     onClick={() => createRouteSheetsStore.changeRouteSheetDataModal(false, nameChange)}>Назад
                </div>
                Выбор начальной точки
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        createRouteSheetsStore.closePopup();
                    }}
                >
                    <DeleteIcon/>
                </div>
            </div>

            <div
                id={"searchInputZone"}
                className={style.inputSearchNameZona}>
                <input
                    type="text"
                    maxLength={50}
                    value={searchValue}
                    onChange={(e) => changeNewSearchValue(e.target.value)}
                    placeholder="Поиск.."
                />
                <span onClick={
                    () => {
                        setSearchValue("")
                        changeNewSearchValue("")
                    }
                }>Очистить</span>
            </div>

            <div className={style.popupHeaderList}>
                <div>Наименование начальной точки</div>
                <div>Адрес начальной точки</div>
            </div>


            {
               listData && <div>
                    <AutoSizer>
                        {({width, height}) => (
                            <List
                                width={width}
                                height={405}
                                rowCount={listData?.length} // Количество элементов в списке
                                rowHeight={101} // Высота отдельного элемента списка
                                rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                className={style.popupInitPointListBlock}
                            />
                        )}
                    </AutoSizer>
                </div>
            }
        </div>
    );
});

export default ModalSelectedInitialPoint;