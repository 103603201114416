import React, {memo, useEffect} from 'react';
import style from './DeliversRoutesListPagination.module.css'
import TablePaginationRouteSheets from "../../TablePaginationRouteSheets/TablePaginationRouteSheets";
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import {observer} from "mobx-react-lite";
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';

const DeliversRoutesListPagination = observer(() => {
    const {page} = deliversRoutes.paginationState;
    const {deliversList} = deliversRoutes;

    const handleChangePage = (newPage) => {
        deliversRoutes.handleChangePage(newPage)
    }

    const handleChangeRowsPerPage = (rowsPerPage) => {
        deliversRoutes.handleChangeRowsPerPage(rowsPerPage)
    }

    useEffect(() => {
        console.log("update")
    }, []);

    return (
        <div className={style.paginationTable}>
            <div className={style.searchTable}>
                <input
                    type="text"
                    placeholder="Поиск..."
                    value={deliversRoutes.tableSearchDeliveredState}
                    onChange={(e) => deliversRoutes.changeSearchAddress(e.target.value)}
                />
                <span
                    onClick={
                        (e) => deliversRoutes.changeSearchAddress("")
                    }
                >
                    <DeleteIcon/>
                </span>
            </div>
            <div>
                <TablePaginationRouteSheets
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    count={
                        (deliversList === undefined) ? 0 : deliversList.length
                    }
                    paginationText={"Документов на странице:"}
                />
            </div>
        </div>
    );
});

export default memo(DeliversRoutesListPagination);