import React, {memo, useCallback, useEffect} from 'react';
import style from './CreateRouteSheetsSelectOrdersPanel.module.css'
import {CSSTransition} from "react-transition-group";
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';

const CreateRouteSheetsSelectOrdersPanel = observer(() => {
    const {selectOrderListData} = createRouteSheetsStore

    const getTotalTonnage = () => {
        let totalTonnage = 0;
        selectOrderListData.forEach(order => {
            totalTonnage += order.vehicle_tonnage
        })
        return Number(totalTonnage).toFixed(2)
    }

    const getTotalGoods = () => {
        let totalGoods = 0;
        selectOrderListData.forEach(order => {
            totalGoods += order.volume_goods
        })
        return Number(totalGoods).toFixed(3)
    }

    const createRouteSheet = useCallback(() => {
        const {popupShow} = createRouteSheetsStore.popupState
        createRouteSheetsStore.changeTypeCreatingRouteSheet("selectedOrderList")
        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "createRouteSheetModal");
    }, []);

    const clearSelectedOrder = () => {
        createRouteSheetsStore.clearSelectOrderListData()
    }

    const addToTheRouteSheet = () => {
        const {routeSheetsList} = createRouteSheetsStore
        const {popupShow} = createRouteSheetsStore.popupState

        if (routeSheetsList.length === 0){
            toastMassage(toast.error, "Нет созданных маршрутных листов!", 10000, "bottom-right")
            return
        }

        createRouteSheetsStore.changeTypeCreatingRouteSheet("selectedOrderList")
        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "addInRouteSheet");
    }

    return (
        <div>
            <CSSTransition in={selectOrderListData.length > 0} timeout={3000} classNames="my-node" unmountOnExit>
                <div className={style.panelCreateRouteSheet}>
                    <div className={style.panelInfo}>
                        <div>
                            Выбрано заказов: {selectOrderListData.length}
                        </div>
                        <div>
                            Вес заказов: {getTotalTonnage()} кг
                        </div>
                        <div>
                            Объем заказов: {getTotalGoods()} м3
                        </div>
                    </div>
                    <div className={style.actionBtns}>
                        <button className={style.showSelectedAreaBtn}
                            onClick={() => createRouteSheet()}
                        >
                            Создать маршрутный лист
                        </button>
                        <button
                            className={style.showSelectedAreaBtn}
                            onClick={() => addToTheRouteSheet()}
                        >
                            Добавить в маршрутный лист
                        </button>
                        <button
                            className={style.cancelAskBtn}
                            onClick={() => clearSelectedOrder()}
                        >
                            Отменить выбранные
                        </button>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
});

export default memo(CreateRouteSheetsSelectOrdersPanel);