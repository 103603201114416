import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import {useState} from "react";
import Search from "../ui/Search/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "16px",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function filterNomenclatureСomparison(data, searchText) {
  return data.filter((item) => {
    const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
    const itemArticle = item.article.toLowerCase(); // Получаем поле "article" объекта
    return itemName.includes(searchText.toLowerCase()) || itemArticle.includes(searchText.toLowerCase());
  });
}
export default function ProfileModalMapping(props) {
  const { apiCheckRelativeManDistrNom, apiManualManDistrNomSelectPatch} = useAxiosAPI()
  const [openSelect, setOpenSelect] = React.useState(false)
  const [activeRowManufacturer, setActiveRowManufacturer] = React.useState(null);
  const [searchComplete, setSearchComplete] = useState("")

  const handlePatch = () => {
    const onSuccess = () => {
        props.setOpen(false);
      props.setLoader(true);
  
      setTimeout(() => {
        apiCheckRelativeManDistrNom(props.distributorNomenclature.distributorId)
        .then((result) => {
            props.setLoader(false);
            props.setDataNumDistributor(result.data);

            setTimeout(handleClose, 1000);
          })
          .catch(onError);
      }, 3000);
    };



    const onError = () => {
      props.setLoader(true);
      onSuccess(); // Вызовите onSuccess в случае ошибки
    };
  
    const patchAndReload = () => {
      apiManualManDistrNomSelectPatch({ ...activeRowManufacturer, nomDistrId: props.distributorNomenclature.id })
            .then(onSuccess)
            .catch(onError);
    };
    patchAndReload();
    setTimeout(handleClose, 1000);
    setActiveRowManufacturer(null)
  };
  const handleClose = () => props.setOpen(false);

  const handleTableRowClickManufacturer = (row) => {
    setActiveRowManufacturer(row); // Записать активную строку в состояние

  
    // props.setManufacturerNomenclature(row);
  }; 
  const ModalSelectFalse = () => {
    setActiveRowManufacturer(null)
    setOpenSelect(false)
}
const ModalMappingFalse = () => {
    setActiveRowManufacturer(null)
    handleClose()
}

   return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {!openSelect ?
        <>
          <Box>
            <List>
              <ListItem>
                <ListItemText
                  primary={`Наименование: ${props.distributorNomenclature.name}`}
                  secondary={"Наименование номенклатуры дистрибьютора"}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={`Артикул: ${
                    props.distributorNomenclature.article !== ""
                      ? props.distributorNomenclature.article
                      : "Неопределенно!"
                  }`}
                  secondary={"Артикул номенклатуры дистрибьютора"}
                />
              </ListItem>
              <Divider />
              <ListItem style={{ display: "flex", alignItems: "center" }}>
                {!Boolean(activeRowManufacturer) ?
                <ListItemText
                  primary={`Cопоставленна:  ${
                    Boolean(props.manufacturerNomenclature)
                      ? props.manufacturerNomenclature.name + " ✅"
                      : "Нет ❌"
                  }`}
                  secondary={"Наименование номенклатуры производителя"}
                /> :
                 <ListItemText
                primary={`Cопоставленна: ${activeRowManufacturer.name}`}
                secondary={"Наименование номенклатуры производителя"}
              />
                }
                <Button onClick={() => setOpenSelect(!openSelect)} variant="outlined" color="error" size="small">
                  Изменить
                </Button>
              </ListItem>
              <Divider />
              {Boolean(props.manufacturerNomenclature) ? (
                <ListItem>
                  <ListItemText
                    primary={`Артикул: ${props.manufacturerNomenclature.article}`}
                    secondary={"Артикул номенклатуры призводителя"}
                  />
                </ListItem>
              ) : null}
              <Divider />
            </List>
          </Box>
          <Button  sx={{marginRight: "1vw"}} onClick={ModalMappingFalse} variant="contained">Отмена</Button>

          <Button disabled={!Boolean(activeRowManufacturer)} onClick={handlePatch} variant="contained">Сохранить</Button>
          </>
          : <Box>
              <TableContainer
              sx={{
                maxHeight: "70vh",
                // width: "50%",
                overflow: "auto",
                marginLeft: "1%",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell> <Search
                        searchComplete={searchComplete}
                        setSearchComplete={setSearchComplete}
                    /></TableCell>
                    <TableCell>Бренд</TableCell>
                    <TableCell>Артикул</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterNomenclatureСomparison(props.nomenclature, searchComplete).map((row) => {
                    const labelId = row.id;
                    const isActive = activeRowManufacturer === row;

                    return (
                      <TableRow
                        key={row.id}
                        onClick={() => handleTableRowClickManufacturer(row)} // Добавьте обработчик клика здесь
                        sx={{
                          backgroundColor: isActive ? "#84e084" : "#ffffff",
                          "&:hover": {
                            backgroundColor: !isActive ? "#f0f0f0" : null, // Цвет при наведении, если не активна
                          },
                        }}
                      >
                        <TableCell
                          sx={{ paddingLeft: "1vw" }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.brand}</TableCell>
                        <TableCell align="left">{row.article}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button  sx={{marginTop: "1vw", marginRight: "1vw"}} onClick={ModalSelectFalse} variant="contained">Отмена</Button>
            <Button disabled={!Boolean(activeRowManufacturer)} onClick={() => setOpenSelect(!openSelect)} sx={{marginTop: "1vw"}} variant="contained">Выбрать</Button>

            </Box>
            }
          </Box>
       
      </Modal>
    </div>
  );
}
