import React, {useCallback, useEffect, useState} from 'react';
import style from './CreateRouteSheetsRightSettingBlock.module.css'
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import AxiosConfig_LogisticService from "../../../../utils/transportationLogisticsService/axiosConfig";
import RouteSheetTable from "../RouteSheetTable/RouteSheetTable";
import MapData_CreateRouteSheets
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/mapData_CreateRouteSheets";
import debounce from "lodash/debounce";
import DeleteConfirmationPopup
    from "../../../../popups/logisticService/DeleteConfirmationPopup/DeleteConfirmationPopup";
import toast from "react-hot-toast";
import SearchInput from "../../../../inputs/logisticService/SearchInput/SearchInput";
import { toastMassage } from '../../../ToastMassage/ToastMassage';


const CreateRouteSheetsRightSettingBlock = observer(() => {
    const {orderListPanelState} = createRouteSheetsStore
    const {popupShow,popupType, popupScrollPosition} = createRouteSheetsStore.popupState
    const [searchText, setSearchText] = useState("")
    const axiosInstance = AxiosConfig_LogisticService();

    useEffect(() => {

        async function getCreatedTransportOrder () {

            const map = MapData_CreateRouteSheets.mapData;

            if (map && map.isStyleLoaded()) {
                console.log(`getTransportOrderCreatedInService`)
                await createRouteSheetsStore.getTransportOrderCreatedInService(axiosInstance);
            } else {
                setTimeout(() => {
                    getCreatedTransportOrder()
                }, 1000)
            }


        }

        getCreatedTransportOrder()
    }, [orderListPanelState.uploadDate]);

    const updateAreaName = useCallback(
        debounce((value) => {
            createRouteSheetsStore.changeSearchText(value, "routeSheetsList");
        }, 200), // Задержка в миллисекундах
        []
    );

    const changeNewSearchValue = (newValue) => {
        setSearchText(newValue);
        updateAreaName(newValue);
    }

    const openModal = () => {
        const {routeSheetsList} = createRouteSheetsStore
        if (routeSheetsList.length === 0) return

        const isChecked = routeSheetsList.some((route) => route.checked === true)

        if (isChecked){
            createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "askDeleteRouteSheet");
        } else {
            toastMassage(toast.error, "Выберите хотя бы один маршрут!", 10000, "bottom-right")
        }
    }

    const deleteSelectedRouteSheet = async () => {
        await createRouteSheetsStore.deleteRouteSheetById(axiosInstance)
    }

    const clearSearchPanel = () => {
        setSearchText("")
        changeNewSearchValue("")
    }

    return (
        <div className={style.rightSettingBlock}>
            <div className={style.header}>Созданные документы</div>

            <SearchInput
                onChange={(value) => changeNewSearchValue(value)}
                placeholder={"Поиск маршрутного листа.."}
                maxLength={50}
                searchValue={searchText}
                onClear={clearSearchPanel}
            />

            <div className={style.actionBtns}>
                <button className={style.cancelAskBtn}
                        onClick={() => {
                            openModal()
                        }}
                >
                    Удалить выбранные маршруты
                </button>
            </div>

            <RouteSheetTable/>

            <DeleteConfirmationPopup
                isOpen={popupShow && popupType === "askDeleteRouteSheet"}
                onClose={() => createRouteSheetsStore.closePopup()}
                scrollPosition={popupScrollPosition}
                askText={`Вы точно хотите удалить выбранные маршруты, действие будет невозможно
                отменить?`}
                onSuccess={() => deleteSelectedRouteSheet()}
            />
        </div>
    );
});

export default CreateRouteSheetsRightSettingBlock;