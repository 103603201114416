import {makeAutoObservable} from "mobx";

class MapData_CreateRouteSheets{
    mapData = null

    constructor() {
        makeAutoObservable(this,)
    }

    setMapData(mapData) {
        this.mapData = mapData;
    }


    clearAreaMap() {
        const map = this.mapData;
        const mapStyle = map.getStyle();

        if (!mapStyle || !mapStyle.layers) return;
        const layers = mapStyle.layers.map(layer => layer.id);


        layers.forEach(layerId => {
            if (layerId.startsWith("userPolygonText") || layerId.startsWith("userPolygonBorder") || layerId.startsWith("userPolygon")) {
                map.removeLayer(layerId);
            }
        });

        const sources = mapStyle.sources;
        for (const sourceId in sources) {
            if (sourceId.startsWith("userPolygon")) {
                map.removeSource(sourceId);
            }
        }
    }

    clearOrderPoint() {
        const map = this.mapData;
        const mapStyle = map.getStyle();
        if (!mapStyle || !mapStyle.layers) return;
        const layers = mapStyle.layers.map(layer => layer.id);
        layers.forEach(layerId => {

            if (layerId.startsWith("points") || layerId.startsWith("points-text")) {
                map.removeLayer(layerId);
            }
        });

        const sources = mapStyle.sources;
        for (const sourceId in sources) {
            if (sourceId.startsWith("points")) {
                map.removeSource(sourceId);
            }
        }
    }
}

export default new MapData_CreateRouteSheets();

