import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";

export default function ConstructorPatternModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [name, setName] = useState("")

//   console.log(props.params, name)

  const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);
  const { apiPivotSave } = useAxiosAPI();

  const handleNameChange = (event) => {  
    setName(event.target.value);
  }

  


  const saveData = () => {
    apiPivotSave(
        {"name": name,
         "dataReport": props.params}
    )
    .then((resolve) => {
        // console.log(resolve)
        // Действие после создания
    })
    props.setOpen(false)
  };
  return (
    <div>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ffa500",
          color: "#ffffff",
          height: "100%",
          marginLeft: "10px",
          "&:hover": {
            background: "#D76701",
          },
        }}
        onClick={() => handleOpen()}
      >
        Сохранить шаблон <BookmarkAddIcon/>
      </Button>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ marginBottom: "10px" }}
            id="modal-modal-description"
          >
            Назовите шаблон
          </Typography>
          <TextField
            onChange={handleNameChange}
            sx={{ width: "100%" }}
            id="outlined-basic"
            label="Название"
            variant="outlined"
          />
          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <Button variant="contained" onClick={handleClose}>Отмена</Button>
            <Button
            onClick={saveData}
              sx={{
                marginLeft: "10px",
                backgroundColor: "#52AB01",
                "&:hover": {
                  background: "#008000",
                },
              }}
              variant="contained"
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
