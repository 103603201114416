import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ListAltIcon from "@mui/icons-material/ListAlt";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import mmrgl from "mmr-gl";
import "mmr-gl/dist/mmr-gl.css";
import GlobalContext from "../../context/GlobalContext";
import { generateLocalColorFromText } from "../../helpers/helpers";
import "./MapVK.css";
import krasnodarGeoJSON from "./geoJSON.json";

function convertCoordinatesStringToArray(coordinatesString) {
  const coordinatesArray = coordinatesString.split(",");
  const latitude = parseFloat(coordinatesArray[1]);
  const longitude = parseFloat(coordinatesArray[0]);
  return [latitude, longitude];
}

export const MapVK = (props) => {
  const [open, setOpen] = useState(false);
  const [pointInfo, setPointInfo] = useState({});
  const { marker, region } = useContext(GlobalContext);
  const mapRef = useRef(null);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [markersLayer, setMarkersLayer] = useState(null);

  const createGeoJSON = (markers) => {
    const geojson = {
      type: "FeatureCollection",
      features: [],
    };

    const bounds = new mmrgl.LngLatBounds();
    markers.forEach((markerCoordinate) => {
      const lngLat = convertCoordinatesStringToArray(markerCoordinate.coordinates);
      bounds.extend(lngLat);
      console.log(lngLat)
      const { coordinates } = markerCoordinate;
      console.log(coordinates)
      const feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: convertCoordinatesStringToArray(coordinates),
        },
        properties: {
          id: markerCoordinate.id,
        },
      };
      geojson.features.push(feature);
    });

    return { geojson, bounds };
  };

  

  useEffect(() => {
    mmrgl.accessToken = process.env.REACT_APP_ACCES_TOKEN_FOR_VKMAP;

    const map = new mmrgl.Map({
      container: "map",
      zoom: 5,
      center: [37.6165, 55.7505],
      style: "mmr://api/styles/main_style.json",
      hash: false,
      maxZoom: 17,
    });

    mapRef.current = map;

    map.addControl(
      new mmrgl.NavigationControl({
        compact: true,
      })
    );

    map.on("load", () => {
      map.loadImage(
        "https://maps.vk.com/api/styles/pins/red_pin.png",
        function (error, image) {
          if (error) throw error;
          map.addImage("custom_pin", image);

          setMarkersLayer(map);

          region.forEach((region) => {
            map.addSource("hexagon", {
              type: "geojson",
              data: krasnodarGeoJSON[region.distrRegion],
            });

            map.addLayer({
              id: "hexagon-fill",
              type: "fill",
              source: "hexagon",
              layout: {},
              paint: {
                "fill-color": generateLocalColorFromText(region.distrName),
                "fill-opacity": 0.5,
              },
            });
          });

          setIsMapInitialized(true);
        }
      );
    });

    return () => {
      if (map) map.remove();
    };
  }, [region]);

  useEffect(() => {
    if (!markersLayer || !isMapInitialized) return;
  
    const { geojson, bounds } = createGeoJSON(marker);
    const source = markersLayer.getSource("points");
  
    if (source) {
      source.setData(geojson);
    } else {
      markersLayer.addSource("points", {
        type: "geojson",
        data: geojson,
      });
  
      markersLayer.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        layout: {
          "icon-image": "custom_pin",
          "icon-size": 0.5,
        },
      });
    }
  
    if (geojson.features.length > 0) {
      markersLayer.fitBounds(bounds, { padding: 50 });
    }
  
    // Устанавливаем обработчики клика на маркеры только после полной инициализации карты
    markersLayer.on("click", "points", function (e) {
      const markerId = e.features[0].properties.id;
      const clickedMarker = marker.find((marker) => marker.id === markerId);
  
      if (clickedMarker) {
        setPointInfo(clickedMarker);
        setOpen(true);
      }
    });
  
    markersLayer.on("mouseenter", "points", function () {
      mapRef.current.getCanvas().style.cursor = "pointer";
    });
    markersLayer.on("mouseleave", "points", function () {
      mapRef.current.getCanvas().style.cursor = "";
    });
  
  }, [marker, markersLayer, isMapInitialized]);

  return (
    <div id="map" style={{ height: "100%", width: "100%" }}>
      {!open ? (
        <IconButton
          onClick={() => setOpen(!open)}
          className="open-icon"
          aria-label="delete"
        >
          <ListAltIcon />
        </IconButton>
      ) : (
        <div className="marker-info">
          <IconButton className="close-icon" onClick={() => setOpen(!open)}>
            <CloseIcon />
          </IconButton>
          {pointInfo ? (
            <List sx={{ width: "100%", bgcolor: "transparent", paddingTop: 0 }}>
              <ListItem sx={{ padding: 0 }}>
                <ListItemAvatar sx={{ minWidth: "30px" }}>
                  <RadioButtonCheckedIcon sx={{ color: pointInfo.color }}/>
                </ListItemAvatar>
                <ListItemText primary="Наименование" secondary={pointInfo.name}/>
              </ListItem>
              <ListItem sx={{ padding: 0 }}>
                <ListItemAvatar sx={{ minWidth: "30px" }}>
                  <RadioButtonCheckedIcon sx={{ color: pointInfo.color }}/>
                </ListItemAvatar>
                <ListItemText primary="ИНН" secondary={pointInfo.inn}/>
              </ListItem>
              <ListItem sx={{ padding: 0 }}>
                <ListItemAvatar sx={{ minWidth: "30px" }}>
                  <RadioButtonCheckedIcon sx={{ color: pointInfo.color }}/>
                </ListItemAvatar>
                <ListItemText primary="КПП" secondary={pointInfo.kpp}/>
              </ListItem>
              <ListItem sx={{ padding: 0 }}>
                <ListItemAvatar sx={{ minWidth: "30px" }}>
                  <RadioButtonCheckedIcon sx={{ color: pointInfo.color }}/>
                </ListItemAvatar>
                <ListItemText primary="Адрес" secondary={pointInfo.address}/>
              </ListItem>
            </List>
          ) : null}
        </div>
      )}
    </div>
  );
};
