import { Box, Paper } from '@mui/material';
import "./RequestLimitLogistics.css"
import LockImg from "../../imges/lock.svg"

import { Typography } from '@mui/material';
const RequestLimitLogistics = () => {
  


    return (
        <Box>
            <Box>
            <Typography sx={{textAlign: 'center', marginTop: "30%", fontSize: "1.5rem", fontWeight: 300}}>
            Лимит запросов превышен
</Typography>
</Box>
            <Box sx={{display: 'flex' }}>
                <img style={{width: "70%", margin: '0 auto'}} src={LockImg}/>
                </Box>
                </Box>
    )
}
export default RequestLimitLogistics