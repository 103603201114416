import React, {memo, useState} from 'react';
import style from './DeliversRoutesSelectedPointListHeader.module.css'
import {ReactComponent as FilterTableIcon} from '../../../../imges/FilterTableIcon.svg';
import {ReactComponent as ArrowUp} from '../../../../imges/ArrowUp.svg';
import {ReactComponent as ArrowDown} from '../../../../imges/ArrowDown.svg';
import {ReactComponent as MessageInfoIcon} from '../../../../imges/MessageInfoIcon.svg';
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import toast from "react-hot-toast";
import {observer} from "mobx-react-lite";
import PopoverBasic from "../../UI/PopoverBasic/PopoverBasic";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';

const DeliversRoutesSelectedPointListHeader = observer(() => {
    const {sort_value,sort_name} = deliversRoutes.pointListTableSortState

    const { selected_filters,select_filter,statusDeliveredPoints,uniqueOrderClientNames} = deliversRoutes.tableFilterDeliveredPointsState
    const [showPopover_DeliveredStatus, setShowPopover_DeliveredStatus] = useState(false);
    const [showPopover_OrderClient, setShowPopover_OrderClient] = useState(false);
    const [newValuePopover, setNewValuePopover] = useState(null);

    const handlePopoverVision = (filterName) => {
        if (!deliversRoutes.selectedDeliver) {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
            return
        } else if (newValuePopover === "closePopover"){
            setNewValuePopover(null)
            return
        }

        if (filterName === "delivered_status") {
            setShowPopover_DeliveredStatus(!showPopover_DeliveredStatus);
        } else if (filterName === "order_client"){
            setShowPopover_OrderClient(!showPopover_OrderClient);
        }
    }

    const checkedFilerStatus = (filterName) => {
        if (selected_filters.length === 0) {
            return true
        }
        for (const filter of selected_filters) {
            if (filter.filter_name === filterName && filter.filter_text === "без фильтра") {
                return true
            }
        }
        return false
    }

    const getSelectedValueDestination = (newValue, filterName) => {
        setNewValuePopover(newValue);
        if (filterName === "delivered_status") {
            setShowPopover_DeliveredStatus(false);
        } else if (filterName === "order_client") {
            setShowPopover_OrderClient(false)
        }

        if (newValue === "closePopover") {
            return
        }

        if (!deliversRoutes.copySelectedDeliver) {
            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
            return
        }

        deliversRoutes.selectFilterAndUpdateDocList(newValue, "tableFilterDeliveredPointsState");
    };

    const getSelectOptionPopover = (filterName) => {
        if (selected_filters.length === 0) {
            return
        }

        return selected_filters.find((filter) => filter.filter_name === filterName)
    }

    return (
        <div className={style.deliversTableHeader}>
            <div>Номер по маршруту</div>
            <div
                onClick={() => {
                    if (Object.keys(deliversRoutes.copySelectedDeliver).length === 0) {
                        toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                        return
                    }
                    deliversRoutes.changeSortCollTableRouteList("order_adress_delivery", "pointListTableSortState");
                    deliversRoutes.selectSortedDocList("selectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver")
                }}
            >Адрес
                <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "order_adress_delivery" || sort_name !== "order_adress_delivery") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "order_adress_delivery") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                    <span
                        style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "order_adress_delivery" || sort_name !== "order_adress_delivery") ? "visible" : "hidden"}}
                        className={(sort_value === 2 && sort_name === "order_adress_delivery") ? style.activeArrow : ""}
                    ><ArrowDown/></span>
                </div>
            </div>
            <div className={style.clientOrStartPoint}>Клиент или склад
                <span
                    className={
                        `
                                         ${showPopover_OrderClient ? style.showPopover : ""}
                                         ${checkedFilerStatus("order_client") ? "" : style.selectFiler}
                                    `
                    }
                    onClick={() => handlePopoverVision("order_client")}
                ><FilterTableIcon/></span>
                <PopoverBasic
                    selectOptions={getSelectOptionPopover("order_client")}
                    options={uniqueOrderClientNames}
                    onChange={(newValue) => getSelectedValueDestination(newValue, "order_client")}
                    open={showPopover_OrderClient}/>
            </div>
            <div className={style.orderInfo}>
                <div
                    onClick={() => {
                        if (Object.keys(deliversRoutes.copySelectedDeliver).length === 0) {
                            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                            return
                        }
                        deliversRoutes.changeSortCollTableRouteList("order_number", "pointListTableSortState");
                        deliversRoutes.selectSortedDocList("selectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver")
                    }}
                >Номер заказа
                    <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "order_number" || sort_name !== "order_number") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "order_number") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "order_number" || sort_name !== "order_number") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "order_number") ? style.activeArrow : ""}
                        ><ArrowDown/></span>
                    </div>
                </div>
                <div>
                    Статус заказа
                    <span
                        className={
                            `
                                         ${showPopover_OrderClient ? style.showPopover : ""}
                                         ${checkedFilerStatus("delivered_status") ? "" : style.selectFiler}
                                    `
                        }
                        onClick={() => handlePopoverVision("delivered_status")}
                    ><FilterTableIcon/></span>
                    <PopoverBasic
                        selectOptions={getSelectOptionPopover("delivered_status")}
                        options={statusDeliveredPoints}
                        onChange={(newValue) => getSelectedValueDestination(newValue, "delivered_status")}
                        open={showPopover_DeliveredStatus}/>
                </div>
            </div>
            <div className={style.timeInfo}>
                <div
                    onClick={() => {
                        if (Object.keys(deliversRoutes.copySelectedDeliver).length === 0) {
                            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                            return
                        }
                        deliversRoutes.changeSortCollTableRouteList("dateAndTimeThisPoint", "pointListTableSortState");
                        deliversRoutes.selectSortedDocList("selectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver")
                    }}
                >Время приезда
                    <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "dateAndTimeThisPoint" || sort_name !== "dateAndTimeThisPoint") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "dateAndTimeThisPoint") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "dateAndTimeThisPoint" || sort_name !== "dateAndTimeThisPoint") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "dateAndTimeThisPoint") ? style.activeArrow : ""}
                        ><ArrowDown/></span>
                    </div>
                </div>
                <div
                    onClick={() => {
                        if (Object.keys(deliversRoutes.copySelectedDeliver).length === 0) {
                            toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                            return
                        }
                        deliversRoutes.changeSortCollTableRouteList("dateAndTimeUnload", "pointListTableSortState");
                        deliversRoutes.selectSortedDocList("selectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver")
                    }}
                >Время выезда
                    <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "dateAndTimeUnload" || sort_name !== "dateAndTimeUnload") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "dateAndTimeUnload") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                        <span
                            style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "dateAndTimeUnload" || sort_name !== "dateAndTimeUnload") ? "visible" : "hidden"}}
                            className={(sort_value === 2 && sort_name === "dateAndTimeUnload") ? style.activeArrow : ""}
                        ><ArrowDown/></span>
                    </div>
                </div>
            </div>
            <div
                onClick={() => {
                    if (Object.keys(deliversRoutes.copySelectedDeliver).length === 0) {
                        toastMassage(toast.error, "Отсутствуют загруженные документы", 10000, "bottom-right")
                        return
                    }
                    deliversRoutes.changeSortCollTableRouteList("lengthToNextPoint", "pointListTableSortState");
                    deliversRoutes.selectSortedDocList("selectedDeliver", "pointListTableSortState", "tableFilterDeliveredPointsState", "filteredSelectedDeliver")
                }}
            >Километраж до ТТ
                <div>
                                <span
                                    style={{visibility: ((sort_value === 1 || sort_value === 0) && sort_name === "lengthToNextPoint" || sort_name !== "lengthToNextPoint") ? "visible" : "hidden"}}
                                    className={(sort_value === 1 && sort_name === "lengthToNextPoint") ? style.activeArrow : ""}
                                ><ArrowUp/></span>
                    <span
                        style={{visibility: ((sort_value === 2 || sort_value === 0) && sort_name === "lengthToNextPoint" || sort_name !== "lengthToNextPoint") ? "visible" : "hidden"}}
                        className={(sort_value === 2 && sort_name === "lengthToNextPoint") ? style.activeArrow : ""}
                    ><ArrowDown/></span>
                </div>
            </div>
            <div className={style.driverMessage}
            >
                <MessageInfoIcon/>
            </div>

        </div>
    );
});

export default memo(DeliversRoutesSelectedPointListHeader);