import React from "react";
import style from "./DeliveryVehiclesTableHeaderList.module.css";
import pageDeliveryVehiclesStore from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
import { observer } from "mobx-react-lite";
import { ReactComponent as ArrowUp } from "../../../../imges/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../../imges/ArrowDown.svg";

const DeliveryVehiclesTableHeaderList = observer(
  ({ sortTemplates, sortOrder }) => {
    const { selectedAllVehicle } = pageDeliveryVehiclesStore.listPageState;

    const changeSelectAllVehicle = (newValue) => {
      pageDeliveryVehiclesStore.changeSelectedAllVehicle(newValue);
    };
    return (
      <div className={style.templateListTableHeader}>
        <div>
          <input
            type="checkbox"
            className={style.selectAllTemplate}
            id="selectAllVehicle"
            name="selectAllVehicle"
            checked={selectedAllVehicle}
            value={selectedAllVehicle}
            onChange={(event) => {
              event.stopPropagation();
              changeSelectAllVehicle(!selectedAllVehicle);
            }}
          />
          <label htmlFor="selectAllVehicle"></label>
        </div>
        <div
          className={style.documentDelivery}
          onClick={() => sortTemplates("appellation")}
        >
          Наименование
          <div>
            <span
              className={
                sortOrder.appellation === "asc" ? style.activeArrow : ""
              }
            >
              <ArrowUp />
            </span>
            <span
              className={
                sortOrder.appellation === "desc" ? style.activeArrow : ""
              }
            >
              <ArrowDown />
            </span>
          </div>
        </div>
        <div>
          <div
            style={{ display: "flex" }}
            className={style.documentDelivery}
            onClick={() => sortTemplates("license_plate")}
          >
            Гос. номер
            <div>
              <span
                className={
                  sortOrder.license_plate === "asc" ? style.activeArrow : ""
                }
              >
                <ArrowUp />
              </span>
              <span
                className={
                  sortOrder.license_plate === "desc" ? style.activeArrow : ""
                }
              >
                <ArrowDown />
              </span>
            </div>
          </div>
          <div
            style={{ display: "flex" }}
            className={style.documentDelivery}
            onClick={() => sortTemplates("vehicle_brand")}
          >
            Марка
            <div>
              <span
                className={
                  sortOrder.vehicle_brand === "asc" ? style.activeArrow : ""
                }
              >
                <ArrowUp />
              </span>
              <span
                className={
                  sortOrder.vehicle_brand === "desc" ? style.activeArrow : ""
                }
              >
                <ArrowDown />
              </span>
            </div>
          </div>
        </div>
        <div
          className={style.documentDelivery}
          onClick={() => sortTemplates("tonnage")}
        >
          Грузоподъемность
          <div>
            <span
              className={sortOrder.tonnage === "asc" ? style.activeArrow : ""}
            >
              <ArrowUp />
            </span>
            <span
              className={sortOrder.tonnage === "desc" ? style.activeArrow : ""}
            >
              <ArrowDown />
            </span>
          </div>
        </div>
        <div>Удалить</div>
        <div>Редактирование</div>
      </div>
    );
  }
);

export default DeliveryVehiclesTableHeaderList;
