import React, {useEffect, useState} from 'react';
import {List} from "react-virtualized";
import style from "./OrderTransferPopup.module.css";
import {observer} from "mobx-react-lite";
import RouteSheet from "./RouteSheet";
import createRouteSheetsStore
    from "../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const RouteSheetList = observer(({onTransfer}) => {
    const [routeSheetsListCurrent, setRouteSheetsListCurrent] = useState(null);

    useEffect(() => {
        const {routeSheetsList, transferOrderState: {orderData}} = createRouteSheetsStore

        const currentList = routeSheetsList.filter(route => {

            return route?.order_list.some(order => order?.id === orderData?.id) === false
        });

        setRouteSheetsListCurrent(currentList)

    }, [])

    const rowRenderer = ({key, index, style}) => (
        <div key={key} style={{...style, display: 'flex', minWidth: '600px'}}>
            <RouteSheet docData={routeSheetsListCurrent[index]} onTransfer={(orderId => onTransfer(orderId))}/>
        </div>
    );

    return (
        <div style={{width: "100%", height: '100%', overflowX: 'auto'}}>
            {
                routeSheetsListCurrent && <List
                    width={760} // Используем переданную ширину
                    height={500 - 200} // Используем переданную высоту
                    rowCount={routeSheetsListCurrent.length} // Количество строк
                    rowHeight={91} // Высота одной строки
                    rowRenderer={rowRenderer} // Функция для рендеринга строки
                    overscanRowCount={3} // Количество строк, которые будут предварительно загружены
                    className={style.tableList}
                />}
        </div>

    );
});

export default RouteSheetList;