import React, {memo, useEffect, useMemo, useState} from 'react';
import style from './DeliversRoutesSelectedPointItemList.module.css'
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import {ReactComponent as MessageInfoIcon} from '../../../../imges/MessageInfoIcon.svg';
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import {observer} from "mobx-react-lite";
import DriverMessagesInfo from "../../DriverMessagesInfo/DriverMessagesInfo";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';


const DeliversRoutesSelectedPointItemList = observer(({index}) => {
    const [messageInfo, setMessageInfo] = useState(false);
    const {
        route_location,
        delivery_date,
        route_summary_time,
        dateAndTimePrevPoint,
        dateAndTimeToNextPoint,
        delivery_message
    } = deliversRoutes.copySelectedDeliver;

    const [currentComponent, setCurrentComponent] = useState(null);

    useEffect(() => {
        console.log("TEST")
    }, [])


    const openDriverInfo = () => {

        if (messageInfo) {
            deliversRoutes.openPopupDriverInfo(route_location[index], window.pageYOffset, "driverMessage")
        } else {
            toastMassage(toast.error, "Нет новой информации от водителя", 10000, "bottom-right")
        }

    }

    useMemo(() => {
        if (route_location[index].delivery_message !== undefined && route_location[index].delivery_message.some(messageObj => messageObj.delivery_status_info !== "")) {

            setMessageInfo(true)
        }
    }, [])

    useEffect(() => {
        if (route_location[index].numberInRoute === 1) {
            setCurrentComponent(
                <div className={style.deliversItem}>
                    <div>{index + 1}</div>
                    <div>{route_location[index]?.address || route_location[index]?.adress}</div>
                    <div>{route_location[index]?.name}</div>
                    <div className={style.orderInfo}>
                        <div>---</div>
                        <div>---</div>
                    </div>
                    <div className={style.timeInfo}>
                        <div>---</div>
                        <div>{getCurrentDateAndTime(route_location[index].dateAndTimeThisPoint)}</div>
                    </div>
                    <div>{`---`}</div>
                    <div className={style.driverMessage}></div>
                </div>);
        } else if (route_location[index].numberInRoute === deliversRoutes.selectedDeliver.route_location.length) {
            setCurrentComponent(
                <div className={style.deliversItem}>
                    <div>{index + 1}</div>
                    <div>{route_location[index]?.address || route_location[index]?.adress}</div>
                    <div>{route_location[index]?.name}</div>
                    <div className={style.orderInfo}>
                        <div>---</div>
                        <div>---</div>
                    </div>
                    <div className={style.timeInfo}>
                        <div>{getCurrentDateAndTime(route_location[index].dateAndTimeThisPoint)}</div>
                        <div>---</div>
                    </div>
                    <div>{`${Number(route_location[index]?.lengthToThisPoint).toFixed(1)} км`}</div>
                    <div className={style.driverMessage}></div>
                </div>);
        } else {
            setCurrentComponent(<div  className={style.deliversItem}>
                <div>{index + 1}</div>
                <div>{route_location[index]?.order_adress_delivery}</div>
                <div>{route_location[index]?.order_client}</div>
                <div className={style.orderInfo}>
                    <div>{route_location[index]?.order_number}</div>
                    <div
                        className={
                            `
                            ${style.deliveredStatus} +
                            ${(route_location[index]?.delivered_status === "Отгружено")? style.deliveredStatus_delivered : ""}
                            ${(route_location[index]?.delivered_status === "Отгружено частично")? style.deliveredStatus_partialDelivered : ""}
                            ${(route_location[index]?.delivered_status === "Отказ")? style.deliveredStatus_rejection : ""}
                            `
                        }
                    ><span>{route_location[index]?.delivered_status}</span></div>
                </div>
                <div className={style.timeInfo}>
                    <div>{getCurrentDateAndTime(route_location[index].dateAndTimeThisPoint)}</div>
                    <div>{getCurrentDateAndTime(route_location[index].dateAndTimeUnload)}</div>
                </div>
                <div>{`${Number(route_location[index]?.lengthToThisPoint).toFixed(1)} км`}</div>
                <div
                    className={(!messageInfo) ? style.driverMessage : `${style.driverMessage} ${style.driverMessageIn}`}
                    onClick={() => openDriverInfo()}
                ><MessageInfoIcon/></div>
            </div>)
        }
    }, []);


    const getCurrentDateAndTime = (valueDate) => {
        let date = new Date(valueDate);
        return date.getDate().toString().padStart(2, '0') + '.' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
            date.getFullYear() + ' ' +
            date.getHours().toString().padStart(2, '0') + ':' +
            date.getMinutes().toString().padStart(2, '0');
    }

    return (
        <>
            {
                currentComponent
            }
        </>
    );
});

export default memo(DeliversRoutesSelectedPointItemList);