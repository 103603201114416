import * as React from 'react';
import "./ConstructorPage.css";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import Constructor from '../../components/Constructor/Constructor';
import IsLockedPage from '../IsLockedPage/IsLockedPage';

const ConstructorPage = () => {
    const {state} = useContext(GlobalContext);

    return (
        <>
        {state.subscriptions ? (
          <>
            {state.subscriptions.filter((item) => item.code === 2)[0].active ? (
        <div className={"reportsContent"}>
             <Constructor/>
        </div>
    ) : (
        <IsLockedPage nameService={"САВП"} />
      )}
    </>
  ) : null}
</>
)
}
export default ConstructorPage