import { makeAutoObservable } from "mobx";
import axios from "axios";
import toast from "react-hot-toast";
import globalState from "../globalState";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toastMassage } from "../../../components/ToastMassage/ToastMassage";

class RouteOnMap {
  // Массив координат для построения линии маршрута на карте
  routeLineCoordinates = [];

  // Массив координат Заказов, для отображения точек на крате
  pointToMap = [];

  // Массив документов с готовым маршрутом
  routeReadyDocument = [];
  // НАДО

  // Состояния на странице
  statePage = {
    select_route_date: this.getISONowDate(),
    document_loading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  async upLoadDocumentRouteSheet(
    authTokens,
    setUser,
    setAuthTokens,
    logoutUser
  ) {
    let userAccessToken;
    await globalState
      .useLogoutUserAfterTimeToken(
        authTokens,
        setUser,
        setAuthTokens,
        logoutUser
      )
      .then((tokens) => (userAccessToken = tokens));

    let dateString = this.statePage.select_route_date;
    let formattedDate = dateString.split("T")[0];

    const headers = {
      Authorization: `Bearer ${userAccessToken}`,
    };

    const data = {
      delivery_date: formattedDate, // "2022-08-23"
    };

    await axios
      .post(
        `${process.env.REACT_APP_LOGISTICS_SERVICE}/getTransportOrderByDateReadyRoute`,
        data,
        { headers }
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.routeReadyDocument = [...response.data];
          this.statePage.document_loading = false;
        } else {
          toastMassage(
            toast.error,
            "Нет готовых маршрутов за выбранную дату!",
            10000,
            "bottom-right"
          );
          this.routeReadyDocument = [];
          this.statePage.document_loading = false;
        }
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });
  }

  // Изменяем состояние документа - Маршрут показывается на карте
  showRouteToMap(idDocument) {
    this.routeReadyDocument.forEach((objDoc) => {
      if (objDoc.document_id === idDocument) {
        objDoc.show_to_map = true;
      } else {
        objDoc.show_to_map = false;
      }
    });
  }

  // Функция для формирования общего массива координат по всем точкам маршрута
  createRouteCoordinatesArray(idDocument) {
    // Инициализация массива для хранения координат линии маршрута
    this.routeLineCoordinates = [];

    // Перебор документов, готовых к маршрутизации
    this.routeReadyDocument.map((objDoc) => {
      // Проверка, соответствует ли ID документа заданному ID
      if (objDoc.document_id === idDocument) {
        // Перебор всех местоположений в документе
        objDoc.route_location.map((objLoc) => {
          // Декодирование ломаной линии маршрута и получение массива координат
          const arrayCoords = this.decodePolylineRoute(objLoc.shape);
          // Объединение текущего массива координат с ранее полученными
          this.routeLineCoordinates =
            this.routeLineCoordinates.concat(arrayCoords);
        });
      }
    });

    // Перестановка координат (из [lat, lon] в [lon, lat]) для всех элементов массива
    this.routeLineCoordinates = this.swapCoordinates(this.routeLineCoordinates);
  }

  // Декодирование ломаной маршрута
  decodePolylineRoute(str, precision) {
    let index = 0,
      lat = 0,
      lng = 0,
      coordinates = [],
      shift = 0,
      result = 0,
      byte = null,
      latitude_change,
      longitude_change,
      factor = Math.pow(10, precision || 6);
    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {
      // Reset shift, result, and byte
      byte = null;
      shift = 0;
      result = 0;
      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);
      latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
      shift = result = 0;
      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);
      longitude_change = result & 1 ? ~(result >> 1) : result >> 1;
      lat += latitude_change;
      lng += longitude_change;
      coordinates.push([lat / factor, lng / factor]);
    }
    return coordinates;
  }

  // Функция для изменения порядка координат в массиве с целью соответствия формату API
  swapCoordinates(coordinates) {
    // Инициализация массива для хранения координат с измененным порядком
    const swappedCoordinates = [];
    // Проходим по всем координатам
    for (let i = 0; i < coordinates.length; i++) {
      // Меняем местами широту и долготу
      const swapped = [coordinates[i][1], coordinates[i][0]];
      // Добавляем измененную пару координат в результат
      swappedCoordinates.push(swapped);
    }
    // Возвращаем массив координат с измененным порядком
    return swappedCoordinates;
  }

  // Функция для преобразования формата массива координат для отображения точек на карте
  transformCoordinates(idDocument) {
    // Инициализация массива для хранения нового формата координат
    this.pointToMap = [];
    // Вспомогательный массив для временного хранения координат
    let newArrayCord = [];

    // Перебор документов, готовых к маршрутизации
    this.pointToMap = this.routeReadyDocument.map((objDoc) => {
      // Проверка, соответствует ли ID документа заданному ID
      if (objDoc.document_id === idDocument) {
        // Перебор всех оптимизированных местоположений в документе
        objDoc.optimization_location.map((coords) => {
          // Добавление координат во временный массив в новом формате
          newArrayCord.push([coords.lon, coords.lat]);
        });
      }
    });

    // Присвоение массиву точек для отображения на карте значения временного массива
    this.pointToMap = newArrayCord;
  }

  orderCoordsOptimizer(idDocument, arrayPointToMap) {
    const orderArr = new Map();

    this.routeReadyDocument.forEach((objDoc) => {
        if (objDoc.document_id === idDocument) {
            arrayPointToMap.forEach((coords) => {
                const key = JSON.stringify(coords.slice().sort());

                if (!orderArr.has(key)) {
                    const list = objDoc.order_list.filter((objOrder) => {
                        const orderCoords = [
                            this.decimalPlaces(+objOrder.coordinate.lon, 6),
                            this.decimalPlaces(+objOrder.coordinate.lat, 6)
                        ].sort();
                        
                        return key === JSON.stringify(orderCoords);
                    });

                    orderArr.set(key, { coords, list });
                }
            });
        }
    });

    // Преобразуем в массив объектов и добавляем индексы
    let orderArrArray = Array.from(orderArr.values());
    let currentIndex = 1;

    orderArrArray = orderArrArray.map(item => {
        const listLength = item.list.length;
        if (listLength > 1) {
            item.index = `${currentIndex}-${currentIndex + listLength - 1}`;
            currentIndex += listLength;
        } else {
            item.index = `${currentIndex}`;
            currentIndex += 1;
        }
        return item;
    });

    return orderArrArray;
}





  getOrderByCoords(idDocument, coords) {
    let objOrderArr = null;
    this.routeReadyDocument.map((objDoc) => {
      if (objDoc.document_id === idDocument) {
        objDoc.order_list.map((objOrder) => {
          // console.log("start", coords, [objOrder.coordinate.lon,objOrder.coordinate.lat])
          if (
            this.areArraysEqual(coords, [
              this.decimalPlaces(Number(objOrder.coordinate.lon), 6),
              this.decimalPlaces(Number(objOrder.coordinate.lat), 6),
            ])
          ) {
            // console.log(objOrder)
            objOrderArr = objOrder;
          }
        });
      }
    });

    return [objOrderArr];
  }

  // Возвращаем число с определенным количество чисел после запятой без округления числа
  decimalPlaces(num, places) {
    const multiplier = Math.pow(10, places);
    return Math.trunc(num * multiplier) / multiplier;
  }

  areArraysEqual(arr1, arr2) {
    // console.log(JSON.stringify(arr1.sort()), "arr1")
    // console.log(JSON.stringify(arr2.sort()), "arr2")
    // console.log(JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort()))
    // Преобразуем каждый массив в строку и сравниваем их
    return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
  }

  changeDate(date) {
    // Увеличиваем дату на один день
    const destinationDateT = new Date(date);
    destinationDateT.setDate(destinationDateT.getDate() + 1);
    // Устанавливаем время на полночь
    destinationDateT.setHours(0, 0, 0, 0);
    // Преобразуем дату в формат ISO и обрезаем до минут
    this.statePage.select_route_date = destinationDateT
      .toISOString()
      .slice(0, 10);
  }

  getISONowDate() {
    const currentDate = new Date();
    const timezoneOffset = -currentDate.getTimezoneOffset() / 60;
    // Увеличиваем дату на один день
    const destinationDateT = new Date();
    destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);
    return destinationDateT.toISOString().slice(0, 10);
  }
}

export default new RouteOnMap();
