import React from 'react';
import style from './SkeletonEditRouteSheet.module.css'
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import {ReactComponent as SettingIcon_v2} from '../../../../imges/SettingIcon_v2.svg';
import {useNavigate, useParams} from "react-router-dom";
import {Skeleton} from "@mui/material";
import CustomisationRouteListHeaderTable from "../CustomisationRouteListHeaderTable/CustomisationRouteListHeaderTable";

const SkeletonEditRouteSheet = () => {
    const navigate = useNavigate();
    const {docId, UID} = useParams();

    return (
        <div className={style.collapseSettingDocument}>
            <div className={style.collapseSettingDocument_header}>
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={550}
                    height={30}
                />
            </div>

            {/*Блок с настройками*/}
            <div className={style.collapseSettingVehicle}>
                {/*Блок с настройками для авто*/}

                <>
                    <div className={style.customisationRouteBlockParams}>
                        <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={570}
                            height={35}
                        />
                    </div>
                    {/*{Блок для выбора параметра оптимизации}*/}
                    <div className={style.customisationOptimizationRouteBlockParams}>
                        <div className={style.btnRouteSettings}>
                            <Skeleton
                                animation="wave"
                                variant="rounded"
                                width={300}
                                height={35}
                            />
                        </div>
                    </div>
                </>


                {/*Блок для выбора начальной точки маршрута для документа*/}
                <div className={style.routeOptimizationInitPoint}>
                        <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={300}
                            height={35}
                        />
                </div>
                {/*Блок для выбора водителя для документа*/}
                <div className={style.routeOptimizationDrivers}>
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={300}
                        height={35}
                    />
                </div>
            </div>

            {/*Блок с кнопками для сохранения/отмены изменений*/}
            <div className={style.actionButtons}>
                <div
                    className={style.actionButtonsSave}
                >
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={200}
                        height={31}
                    />
                </div>
                <div
                    className={style.actionButtonsCancelEdit}
                >
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={200}
                        height={31}
                    />
                </div>
            </div>

            <CustomisationRouteListHeaderTable/>

            <Skeleton
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={60}
            />
            <Skeleton
                style={{marginTop: "3px"}}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={60}
            />
            <Skeleton
                style={{marginTop: "3px"}}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={60}
            />
            <Skeleton
                style={{marginTop: "3px"}}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={60}
            />
            <Skeleton
                style={{marginTop: "3px"}}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={60}
            />
            <Skeleton
                style={{marginTop: "3px"}}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={60}
            />

        </div>
    );
};

export default SkeletonEditRouteSheet;