import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { observer } from 'mobx-react-lite';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";


const routeParamSelectorHOC = (WrappedComponent, defaultText, typeParam, typeAction) => {
    return  observer(() => {
        const [selectedData, setSelectedData] = useState(null);

        useMemo(() => {
            const getRouteData = () => {
                if (typeAction === "createRoute") {
                    return createRouteSheetsStore.creatingRouteSheetsModalData;
                } else if (typeAction === "editRoute") {
                    return createRouteSheetsStore.editRouteSheetsData;
                }
                return {};
            };

            const { initial_route_point, drivers_info, vehicle_data } = getRouteData();

            switch (typeParam) {
                case "changeInitPoint":
                    setSelectedData(initial_route_point?.name);
                    break;
                case "changeDriver":
                    setSelectedData(drivers_info[0]?.driver_name);
                    break;
                case "changeVehicle":
                    setSelectedData(vehicle_data?.appellation);
                    break;
                default:
                    break;
            }
        }, []);

        const changeRouteData = useCallback((value) => {
            const { changeInitPoint, changeDriver, changeVehicle } = createRouteSheetsStore.creatingRouteSheetsModalState;

            switch (typeParam) {
                case "changeInitPoint":
                    createRouteSheetsStore.changeRouteSheetDataModal(!changeInitPoint, "changeInitPoint");
                    break;
                case "changeDriver":
                    createRouteSheetsStore.changeRouteSheetDataModal(!changeDriver, "changeDriver");
                    break
                case "changeVehicle":
                    createRouteSheetsStore.changeRouteSheetDataModal(!changeVehicle, "changeVehicle");
                    break
                default:
                    break;
            }
        }, []);

        return (
            <WrappedComponent
                defaultText={defaultText}
                selectedData={selectedData}
                onClick={() => changeRouteData()}
            />
        );
    });
};

export  default routeParamSelectorHOC;