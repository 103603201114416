import {makeAutoObservable} from 'mobx'
import axios from "axios";
import ReactDOM from "react-dom/client";
import MapMarketPoint from "../../../components/transportationLogisticsService/MapMarketPoint/MapMarketPoint";
import mmrgl from "mmr-gl";
import React from "react";
import editCoordinatesAddress from "../pageEditCoordinatesAddress/editCoordinatesAddress";
import globalState from "../globalState";

class EditInitialPoints {

    // Список начальных точек
    initialPointsList = [];
    copyInitialPointsList = null;

    editDataInitialPoint = null; // Объект редактируемой начальной точки

    createDataInitialPoint = null; // Объек для создания новой начальной точки

    markersList = [] // Массив маркеров на карте

    statePage = { // Состояния на странице
        create: false,
        setting: false,
        init_points_upload: false,
        value_search: "",
        show_faq: false,
        ref_list_items: null,
        index_edit_init_point: null,
        edited_address_point: null,
        edited_name_point: null
    }

    listScrollToInitPoint = {
        initial_route_point_name: null,
        address: null
    }

    constructor() {
        makeAutoObservable(this,)
    }

    async getAllInitialPoints(authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getListInitialPointRoute`, {headers}
        )
            .then(response => {
                if (response.data.length > 0) {
                    this.initialPointsList = response.data;

                    if (this.listScrollToInitPoint.initial_route_point_name != null && this.listScrollToInitPoint.address != null){
                        this.statePage.index_edit_init_point = this.initialPointsList.findIndex(obj => obj.address === this.listScrollToInitPoint.address && obj.initial_route_point_name === this.listScrollToInitPoint.initial_route_point_name);
                        console.log(this.statePage.index_edit_init_point)
                    }
                }
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });

    }

    // Устанавливаем статус редактирования на странице
    setEditInitPoint(addressData) {
        this.editDataInitialPoint = addressData;
        this.statePage.setting = true;
        this.statePage.edited_address_point = addressData.address;
        this.statePage.edited_name_point = addressData.initial_route_point_name;
        this.clearSearchValue()

        this.listScrollToInitPoint = {
            initial_route_point_name: addressData.initial_route_point_name,
            address: addressData.address,
        }
    }

    // Устанавливаем статус редактирования на странице
    setCreatePointData(addressData) {
        this.createDataInitialPoint = {
            address: null,
            lat: null,
            lon: null,
            coordinates_given: true,
            initial_route_point: true,
            initial_route_point_name: null
        };
        this.statePage.create = true;
        this.clearSearchValue()

    }

    // Очищаем введенное значение в поисковой строке адреса
    clearSearchValue() {
        this.statePage.value_search = "";

        if (this.copyInitialPointsList != null) {
            this.initialPointsList = this.copyInitialPointsList;
        }
    }

    // Обрабатываем значение в поисковой строке, для отображение нужных адресов
    changeSearchNameInitPoints(addressValue) {
        if (this.statePage.value_search === "") {
            this.copyInitialPointsList = this.initialPointsList.slice();
        }

        this.statePage.value_search = addressValue;
        const regex = new RegExp(`^.*${addressValue.split(' ').join('.*')}.*$`, 'i');

        this.initialPointsList = this.copyInitialPointsList.filter(address =>
            regex.test(address.address)
        )
    }

    // Обновляем координаты Адреса по нажатию на область на карте
    clickEditAddressInitPoint(clickData) {
        this.editDataInitialPoint = {
            ...this.editDataInitialPoint,
            coordinates_given: true,
            lat: Number(clickData.lat),
            lon: Number(clickData.lng)
        }

        this.markersList[0].setLngLat([clickData.lng, clickData.lat]);
    }

    showFaqBlock(value) {
        this.statePage.show_faq = value;
    }

    // Обрабатываем input поля, где пользователь вводить координаты
    changeNewInitialPointData(typeCoordinate, newValueCoordinate, mapData, typeData) {
        const map = mapData.current;
        if (typeCoordinate === "lat" || typeCoordinate === "lon") {

            const pattern = /^(-?\d+)\.(\d+)$/;
            this[typeData] = {
                ...this[typeData],
                lat: (typeCoordinate === "lat") ? newValueCoordinate : this[typeData].lat,
                lon: (typeCoordinate === "lon") ? newValueCoordinate : this[typeData].lon
            }

            if (this[typeData].lat != null && this[typeData].lon != null) {

                if (this.markersList.length === 0 && (pattern.test(this[typeData].lat) && pattern.test(this[typeData].lon))) {
                    this.showNewInitPointToMap({
                        lat: this[typeData].lat,
                        lng: this[typeData].lon
                    }, mapData)
                }

                if (this.markersList.length !== 0) {
                    this.markersList[0].setLngLat([this[typeData].lon, this[typeData].lat]);
                }
            }

            const coordsFocusToMarker = [[this[typeData].lon, this[typeData].lat], [this[typeData].lon, this[typeData].lat]]

            map.fitBounds(coordsFocusToMarker, {zoom: 12});
        } else if (typeCoordinate === "address" || typeCoordinate === "point_name") {
            this[typeData] = {
                ...this[typeData],
                address: (typeCoordinate === "address") ? newValueCoordinate : this[typeData].address,
                initial_route_point_name: (typeCoordinate === "point_name") ? newValueCoordinate : this[typeData].initial_route_point_name
            }
        }

    }

    // Очищаем карту от маркеров
    clearMarkers() {
        if (this.markersList.length > 0) {
            this.markersList.map(marker => {
                marker.remove();
            })
            this.markersList = [];
        }
    }

    // Добавляем маркер на карту
    addNewMarkerToMap(dataMarket) {
        this.markersList.push(dataMarket)
    }

    // Обновляем координаты Адреса после перемещения точки Адреса в другой место
    dragEditInitPoint(dragData, typeData) {
        this[typeData] = {
            ...this[typeData],
            coordinates_given: true,
            lat: dragData._lngLat.lat,
            lon: dragData._lngLat.lng
        }
    }

    // Создаем маркер с новыми координатами
    showNewInitPointToMap = (coordinates, mapData, typeData) => {
        const coordsArray = [Number(coordinates.lng), Number(coordinates.lat)];

        const map = mapData.current;

        if (map) {
            this.clearMarkers()
            // Выводим маркеры точек на карту
            const markerElement = document.createElement('div');
            const root = ReactDOM.createRoot(markerElement);
            root.render(<MapMarketPoint idDoc={1}/>);

            this.addNewMarkerToMap(new mmrgl.Marker({
                element: markerElement,
            }).setLngLat(coordsArray).addTo(map).setDraggable(true)
                .on("dragend", (dragData) => {
                    this.dragEditInitPoint(dragData.target, typeData)
                }))

            const coordsFocusToMarker = [coordsArray, coordsArray]
            map.fitBounds(coordsFocusToMarker, {zoom: 18});
        }
    }

    // Отменяем редактирование адресса
    cancelEditInitPointData() {
        this.statePage.setting = false;
        this.statePage.edited_address_point = null;
        this.statePage.edited_name_point = null;
        this.clearSearchValue()

        // Определяем индекс редакрируемого адреса
        if (this.listScrollToInitPoint.initial_route_point_name != null && this.listScrollToInitPoint.address != null) {
            this.statePage.index_edit_init_point = this.initialPointsList.findIndex(obj => obj.address === this.listScrollToInitPoint.address && obj.initial_route_point_name === this.listScrollToInitPoint.initial_route_point_name);
            console.log(this.statePage.index_edit_init_point)
        }
    }

    cancelCreatePointData() {
        this.statePage.create = false;
        this.clearSearchValue()
    }

    createNewInitPoint(coordinates, typeData) {
        this[typeData] = {
            ...this[typeData],
            coordinates_given: true,
            lat: Number(coordinates.lat),
            lon: Number(coordinates.lng)
        }
    }

    // Отправляем отредактированный адрес в БД для обновления данных
    async updateInitialPointData(authTokens, setUser, setAuthTokens, logoutUser,globalStates) {

        let mainUserId;
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        if (this.editDataInitialPoint.lat === null || this.editDataInitialPoint.lon === null) {
            // this.showAlertBlock("info",
            //     "Заполните координаты перед сохранением!")
            return
        }

        const pattern = /^(-?\d+)\.(\d+)$/;
        // Проверяем что координаты нужного формата
        if (!pattern.test(this.editDataInitialPoint.lat) && !pattern.test(this.editDataInitialPoint.lon)) {
            // this.showAlertBlock("error",
            //     "Вы указали неверный формат координат. Исправьте и повторите снова!")
            return
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            newAddressData: this.editDataInitialPoint,
            lastAddressData: this.statePage.edited_address_point,
            lastNameData: this.statePage.edited_name_point
        }

        // Данные, для того, чтобы понимать к какому элементу скролить список точек
        this.listScrollToInitPoint = {
            initial_route_point_name: this.editDataInitialPoint.initial_route_point_name,
            address: this.editDataInitialPoint.address
        }


        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/updateInitialPointRoute/`, data, {headers}
        )
            .then(async response => {
                if (response.status === 200) {
                    await this.getAllInitialPoints(authTokens, setUser, setAuthTokens, logoutUser)
                    this.statePage.setting = false;
                }
            })
            .catch(error => {
                // this.showAlertBlock("error",
                //     "Координаты не записались! Обратитесь к администрации сервиса!")
                console.error('Ошибка:', error);
            });
    }

    // Отправляем объект данных новой начальной точки в БД для записи
    async createNewInitialPointData(authTokens, setUser, setAuthTokens, logoutUser, globalStates) {

        let mainUserId;
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        // if (Object.keys(globalStates.manufacturer).length === 0) {
        //     mainUserId = globalStates.profileDistributors.inn;
        // } else if (Object.keys(globalStates.profileDistributors).length === 0) {
        //     mainUserId = globalStates.manufacturer.inn;
        // }

        if (this.createDataInitialPoint.lat === null || this.createDataInitialPoint.lon === null || this.createDataInitialPoint.address === null || this.createDataInitialPoint.initial_route_point_name === null) {
            // this.showAlertBlock("info",
            //     "Заполните координаты перед сохранением!")
            return
        }

        const pattern = /^(-?\d+)\.(\d+)$/;
        // Проверяем что координаты нужного формата
        if (!pattern.test(this.createDataInitialPoint.lat) && !pattern.test(this.createDataInitialPoint.lon)) {
            // this.showAlertBlock("error",
            //     "Вы указали неверный формат координат. Исправьте и повторите снова!")
            return
        }

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            newAddressData: this.createDataInitialPoint
        }

        // Данные, для того, чтобы понимать к какому элементу скролить список точек
        this.listScrollToInitPoint = {
            initial_route_point_name: this.createDataInitialPoint.initial_route_point_name,
            address: this.createDataInitialPoint.address
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/createInitialPointRoute/`, data, {headers}
        )
            .then(async response => {
                if (response.status === 200) {
                    await this.getAllInitialPoints(authTokens, setUser, setAuthTokens, logoutUser)
                    this.statePage.create = false;
                }
            })
            .catch(error => {
                // this.showAlertBlock("error",
                //     "Координаты не записались! Обратитесь к администрации сервиса!")
                console.error('Ошибка:', error);
            });
    }

}

export default new EditInitialPoints()