import React, {useState} from 'react';
import ModalMaster from "../ModalMaster/ModalMaster";
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import style from './PointDisplayOptionsPopup.module.css'
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const PointDisplayOptionsPopup = observer((({isOpen, onClose, scrollPosition, onSave}) => {
    const {allOrder, availableOrder, orderInRoute} = createRouteSheetsStore.pointDisplayOptions;
    const [pointDisplayOptions, setPointDisplayOptions] = useState({
        allOrder: allOrder,
        orderInRoute: orderInRoute,
        availableOrder: availableOrder
    })

    const changeOption = (nameOption) => {
        setPointDisplayOptions((prevOptions) => {
            const newOptions = {};

            // Устанавливаем все опции в false
            for (const key in prevOptions) {
                newOptions[key] = false;
            }

            // Устанавливаем только переданную опцию в true
            newOptions[nameOption] = true;

            return newOptions;
        });
    }

    return (
        <ModalMaster
            isOpen={isOpen}
            onClose={onClose}
            scrollPosition={scrollPosition}
        >
            <div className={style.popupHeader}>
                Параметры отображения заказов на карте
                <div
                    className={style.btnClosePopup}
                    onClick={onClose}
                ><DeleteIcon/></div>
            </div>

            <div className={style.selectContent}>
                <div
                    onClick={(e) => changeOption("allOrder")}
                >
                    <input
                        type="checkbox"
                        className={style.checkbox}
                        id="select_allOrder"
                        name="select_allOrder"
                        value={pointDisplayOptions.allOrder}
                        checked={pointDisplayOptions.allOrder}
                        onChange={(e) => {}}
                    />
                    <label htmlFor="select_allOrder">Отображать на карте "все заказы" за выбранную дату.</label>
                </div>
                <div
                    onClick={(e) => changeOption("orderInRoute")}
                >
                    <input
                        type="checkbox"
                        className={style.checkbox}
                        id="select_orderInRoute"
                        name="select_orderInRoute"
                        value={pointDisplayOptions.orderInRoute}
                        checked={pointDisplayOptions.orderInRoute}
                        onChange={(e) => {
                        }}
                    />
                    <label htmlFor="select_orderInRoute">Отображать на карте "все заказы в маршрутных листах" за выбранную дату.</label>
                </div>
                <div
                    onClick={(e) => changeOption("availableOrder")}
                >
                    <input
                        type="checkbox"
                        className={style.checkbox}
                        id="select_availableOrder"
                        name="select_availableOrder"
                        value={pointDisplayOptions.availableOrder}
                        checked={pointDisplayOptions.availableOrder}
                        onChange={(e) => {
                        }}
                    />
                    <label htmlFor="select_availableOrder">Отображать на карте "все заказы не в маршрутных листах" за выбранную дату.</label>
                </div>
            </div>

            <div className={style.actionAskBtns}>
                <button
                    className={style.acceptAskBtn}
                    onClick={() => onSave(pointDisplayOptions)}
                >Сохранить
                </button>
                <button
                    className={style.cancelAskBtn}
                    onClick={onClose}
                >Отменить
                </button>
            </div>
        </ModalMaster>
    );
}));

export default PointDisplayOptionsPopup;