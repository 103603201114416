import React from 'react';
import style from './ModalChangeRouteParam.module.css'
import {ReactComponent as SettingIcon_v2} from '../../../../imges/SettingIcon_v2.svg';
import {observer} from "mobx-react-lite";



const ModalChangeRouteParam = observer(({defaultText, selectedData, onClick}) => {
    return (
        <div className={style.routeOptimizationInitPoint}>
            <div
                className={style.btnSelectInitPoint}
                onClick={() => onClick()}
            >
                <SettingIcon_v2/>
                <div>{((selectedData?.length > 25) ? selectedData.slice(0, 25) + "..." : selectedData) || `${defaultText}`}</div>
            </div>

        </div>
    );
});

export default ModalChangeRouteParam;