import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import DotsVertical from 'mdi-material-ui/DotsVertical';
import * as React from 'react';
import { useContext } from "react";

import { MenuItem } from "@mui/material";
import globalContext from "../../context/GlobalContext";

export default function ChartTypeButton() {

    const {user, age, setAge} = useContext(globalContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAge(event.target.value)
        setAnchorEl(null);
    };
    const handleCloseLocal = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <div>
                <IconButton
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <DotsVertical />

                </IconButton>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseLocal}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {user.accessLevel == 1 ?
                    <MenuItem value={10} onClick={handleClose}>Доля продаж</MenuItem>
                    : null}
                    <MenuItem value={20} onClick={handleClose}>Торговые точки</MenuItem>
                    <MenuItem value={30} onClick={handleClose}>Номенклатура</MenuItem>
                </Menu>
            </div>

        </React.Fragment>
    );
}