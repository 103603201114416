import React, {useContext, useEffect} from 'react';
import DriverAndForwarderTableHeader from "../DriverAndForwarderTableHeader/DriverAndForwarderTableHeader";
import {useNavigate, useParams} from "react-router-dom";
import GlobalContext from "../../../../context/GlobalContext";
import DriverAndForwarderTableList from "../DriverAndForwarderTableList/DriverAndForwarderTableList";
import pageDriverAndForwarderStore
    from "../../../../store/transportationLogisticsService/pageDriverAndForwarderStore/pageDriverAndForwarderStore";


const DriverAndForwarderList = () => {
    const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(GlobalContext);
    const navigate = useNavigate();
    const {typeList} = useParams();

    useEffect(() => {
        async function getTemplate() {
            await pageDriverAndForwarderStore.getCardsByTypeList(typeList, authTokens, setUser, setAuthTokens, logoutUser, true);
        }

        getTemplate();

        return () => {
            // pageDriverAndForwarderStore.changeDefaultValue()
        }
    }, [typeList])

    return (
        <>
            <DriverAndForwarderTableHeader/>
            <DriverAndForwarderTableList/>
        </>
    );
};

export default DriverAndForwarderList;