import React, {useEffect} from 'react';
import style from './CreateRouteSheetsPage.module.css';
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import savpMenuLogisticOption
    from "../../store/transportationLogisticsService/savpMenuLogisticOption/savpMenuLogisticOption";
import CreateRouteSheets
    from "../../components/transportationLogisticsService/CreateRouteSheetsPageComponents/CreateRouteSheets/CreateRouteSheets";
import {Toaster} from "react-hot-toast";

const CreateRouteSheetsPage = () => {

    useEffect(() => {
        savpMenuLogisticOption.changeDefaultStateRightMenu()
        savpMenuLogisticOption.changeSubRightMenu(false);
        savpMenuLogisticOption.changeLogisticMenuOption(false);

        return () => {

        }

    }, []);

    return (
        <div className={style.page}>

            {/*Уведомления*/}
            <Toaster containerStyle={{
                position: 'fixed',
                top: '80px'
            }} />

            <MainMenuLogisticsService namePage={"Cоздание маршрутных листов"}/>

            <CreateRouteSheets/>
        </div>
    );
};

export default CreateRouteSheetsPage;