import React, {memo, useEffect} from 'react';
import style from './DeliveryVehiclesActiveBlock.module.css'
import {Link, useNavigate, useParams} from "react-router-dom";
import deliveredStatusList
    from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import {observer} from "mobx-react-lite";
import toast from "react-hot-toast";
import pageDeliveryVehiclesStore
    from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
    import { IconButton } from '@mui/material';
    import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../ToastMassage/ToastMassage';
    

const DeliveryVehiclesActiveBlock = observer(() => {
    const {
        popupState:{popupShow},
        vehicleListCopy
    } = pageDeliveryVehiclesStore
    const navigate = useNavigate();
    const {typeStatus} = useParams();

    const changeNewPath = (newTypeStatus) => {
        navigate(`/logisticService/deliveredStatusList/templateList/${newTypeStatus}`, {relative: 'path'});
    }

    function isChecked(array) {
        return array.some(veh => veh?.checked === true);
    }

    const changePopupShow = (value, typePopup) => {
        if (isChecked(vehicleListCopy)){
            pageDeliveryVehiclesStore.changePopupShow(window.pageYOffset, value, typePopup);
        } else {
            toastMassage(toast.error, "Выберите хотя бы одну карточку транспорта!", 10000, "bottom-right")
        }
    }


    return (
        <div className={style.headerPage}>
            <div className={style.actionTemplateBtns}>
                <Link to={"/logisticService/vehicleList/createVehicle"}>
                    <button className={style.createTemplateBtn}>Добавить транспорт</button>
                </Link>
                <button className={style.deleteSelectTemplateBtn}
                onClick={() => changePopupShow(!popupShow, "askDeleteSelectedVehicles")}
                >Удалить выбранный транспорт</button>
            </div>
        </div>
    );
});

export default memo(DeliveryVehiclesActiveBlock);