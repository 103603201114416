import React, {useEffect, useMemo, useRef, useState} from 'react';
// import style from './PopoverMenuBasic.module.css'
import './PopoverBasic.css';

const PopoverBasic = ({options, selectOptions, onChange, open}) => {
    const handleOptionClick = (option) => {
        onChange(option);
    };

    const popoverRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                // Нажатие было сделано за пределами блока
                onChange("closePopover");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onChange, selectOptions]);

    return (
        <>
            {open && (
                <div className={"PopoverBasic_CustomSelect"} ref={popoverRef}>

                    <div className={"PopoverBasic_SelectItems"}>
                        {options.map((option, index) => (
                            <div className={
                                (option.filter_text === selectOptions.filter_text) ? `PopoverBasic_ItemsList PopoverBasic_ItemsList_Active` : `PopoverBasic_ItemsList`
                            } key={index} onClick={() => handleOptionClick(option)}>
                                {option.filter_text}
                            </div>
                        ))}
                    </div>

                </div>
            )}
        </>

    );
};

export default PopoverBasic;