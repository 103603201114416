import React, {memo, useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import style from './RouteSheetTableItem.module.css';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import {observer} from "mobx-react-lite";

const RouteSheetTableItem = observer(({docId,  docIndex}) => {
    const {routeSheetsList} = createRouteSheetsStore
    const [docData, setDocData] = useState(null);
    const [componentState, setComponentState] = useState({
        tonnage: 0,
        freeLoad: 0
    })
    const [ statusTonnage, setStatusTonnage] = useState(undefined);

    useEffect(() => {
        initialDocumentData()
    }, []);

    useEffect(() => {
        setTonnageStatus();
        setComponentState((prevState) => {
            return {...prevState,
                tonnage: getTotalTonnage(),
                freeLoad: getFreeLoad()
            }
        })
    }, [docData]);

    function initialDocumentData() {
        const documentData = routeSheetsList.find(doc => doc.id === docId);
        setDocData(documentData)
    }

    const setTonnageStatus = () => {
        const vehicleTonnageInKg = docData?.vehicle_data?.tonnage * 1000;
        const totalTonnageInRoute = getTotalTonnage();

        if (vehicleTonnageInKg && !isNaN(vehicleTonnageInKg) && totalTonnageInRoute && !isNaN(totalTonnageInRoute)) {
            const midpoint = vehicleTonnageInKg / 2;

            if (totalTonnageInRoute <= midpoint) {
                setStatusTonnage("totalTonnageSuccess");
            } else if (totalTonnageInRoute > midpoint && totalTonnageInRoute < vehicleTonnageInKg) {
                setStatusTonnage("totalTonnageOverHalf");
            } else {
                setStatusTonnage("totalTonnagePreload");
            }
        } else {
            setStatusTonnage("unknown");
        }
    }

    const getFreeLoad = useCallback(() => {
        const vehicleTonnageInKg = docData?.vehicle_data?.tonnage * 1000;
        const totalTonnageInRoute = getTotalTonnage();

        return Number(vehicleTonnageInKg - totalTonnageInRoute).toFixed(2);
    }, [docData])

    const getTotalTonnage = useCallback(() => {
        let totalTonnage = 0;

        if (docData?.order_list){
            for (const order of docData?.order_list){
                totalTonnage += order?.vehicle_tonnage
            }

            return Number(totalTonnage).toFixed(2)
        } else {
            return totalTonnage
        }
    }, [docData])

    const editRouteSheet = (e) => {
        e.stopPropagation()
        const { popupShow} = createRouteSheetsStore.popupState;
        createRouteSheetsStore.changeEditRouteSheetsData(docData);
        createRouteSheetsStore.changePopupShow(window.pageYOffset, !popupShow, "editRouteSheet");
    }

    const selectRouteSheet = useCallback((event, checkValue, docId) => {
        event.preventDefault()
        event.stopPropagation();
        createRouteSheetsStore.changeSelectInRouteSheetsList(checkValue, docId);
    }, [])

    return (
        <>
            {
                <div className={style.tableItem}
                     onClick={(e) => editRouteSheet(e)}
                >
                    <div
                        onClick={(event) => {
                            selectRouteSheet(event,!docData?.checked, docData?.id)
                        }}
                    >
                        <input
                            type="checkbox"
                            className={style.selectDocumentML}
                            id={`"select_${docData?.id}`}
                            name={`"select_${docData?.id}`}
                            checked={docData?.checked || false}
                            onChange={()=> {}}
                        />
                        <label htmlFor={`"select_${docData?.id}`}></label>
                    </div>
                    <div>{docData?.vehicle_data?.tonnage} т.</div>
                    <div>{docData?.vehicle_data?.appellation}</div>
                    <div
                        className={`${style[statusTonnage]}`}
                    >{componentState.tonnage} кг.
                    </div>
                    <div>{docData?.order_list.length}</div>
                    <div>{docData?.drivers_info[0]?.driver_name}</div>
                    <div
                        className={`${style[statusTonnage]}`}
                    >{componentState.freeLoad} кг.
                    </div>
                    <div>{docData?.document_number}</div>
                </div>
            }
        </>
    );
});

export default memo(RouteSheetTableItem);