import * as React from "react";

import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Slider } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
export default function DistributorsRating(props) {
  const data = props.arr;

  function compareBySum(a, b) {
    const sumA =
      props.age === 10
        ? parseInt(a.sum)
        : props.age === 20
        ? parseInt(a.retailOutletDistrpercent)
        : props.age === 30
        ? parseInt(a.nomDistrpercent)
        : 0;
    const sumB =
      props.age === 10
        ? parseInt(b.sum)
        : props.age === 20
        ? parseInt(b.retailOutletDistrpercent)
        : props.age === 30
        ? parseInt(b.nomDistrpercent)
        : 0;

    return sumB - sumA;
  }

  return (
    <TableContainer component={Paper} className={"tableContainerRating"}>
      <Table
        stickyHeader
        aria-label="collapsible table"
        sx={{ backgroundColor: "#ffffff" }}
      >
        <TableBody>
          {data.sort(compareBySum).map((row, index) => (
            <React.Fragment key={index}>
              <TableRow
                sx={{
                  backgroundColor:
                    row.name === props.selectedLabel
                      ? "rgb(243,244,249)"
                      : "#ffffff",
                }}
              >
                <TableCell sx={{ padding: "0" }} align="center">
                  <WorkspacePremiumIcon
                    sx={{
                      color:
                        index === 0
                          ? "#ffd700"
                          : index === 1
                          ? "#c0c0c0"
                          : index === 2
                          ? "#cd7f32"
                          : "#ffffff",
                    }}
                  />
                </TableCell>
                <TableCell component="th" sx={{ padding: "0" }} scope="row">
                  {row.name}
                </TableCell>
                <TableCell sx={{ padding: "5px", lineHeight: 0 }}>
                  <Slider
                    value={
                      props.age === 10
                        ? row.sum
                        : props.age === 20
                        ? row.retailOutletDistrpercent
                        : props.age === 30
                        ? row.nomDistrpercent
                        : null
                    }
                    //   disabled={true}
                    sx={{
                      width: 180,
                      color: row.color,
                      borderRadius: 0,

                      "& .MuiSlider-thumb": {
                        display: "none",
                      },
                    }}
                  />
                </TableCell>
                <TableCell sx={{ padding: "0" }} align="center">
                  {props.age === 10
                    ? row.sum + " %"
                    : props.age === 20
                    ? row.retailOutletDistrpercent + " %"
                    : props.age === 30
                    ? row.nomDistrpercent + " %"
                    : null}
                </TableCell>
                {/* <TableCell align="center">
                                    <WorkspacePremiumIcon sx={{ color: (index === 0) ? "#ffd700" : (index === 1) ? "#c0c0c0" : (index === 2) ? "#cd7f32" : "#ffffff" }} />
                                </TableCell> */}
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
