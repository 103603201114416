import React from 'react';
import {ReactComponent as SettingIcon} from '../../../imges/SettingIcon.svg';
import style from './InitialPointItem.module.css';
import {Tooltip} from 'react-tooltip';
import {ReactComponent as InfoIcon} from '../../../imges/InfoIcon.svg';
import {observer} from "mobx-react-lite";
import editInitialPoints
    from "../../../store/transportationLogisticsService/pageEditInitialPoints/editInitialPoints"

const InitialPointItem = observer(({addressData, showSelectInitPointToMap,uid, indexElem}) => {
    const {statePage} = editInitialPoints;

    return (
        <div
            onClick={() => {
                showSelectInitPointToMap(addressData)
            }}
            className={(statePage.index_edit_init_point === indexElem)?`${style.initialPointItem} ${style.initialPointItemActive}`:style.initialPointItem}
        >
            <div
            >
                <div data-tooltip-id={`client-info_${uid}`}
                     data-tooltip-variant="info">
                    {(addressData.initial_route_point_name.length > 20) ? addressData.initial_route_point_name.slice(0, 20) + "..." : addressData.initial_route_point_name}
                    {(addressData.initial_route_point_name.length > 20) ? <Tooltip
                        id={`client-info_${uid}`}
                        content={addressData.initial_route_point_name}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
                <div data-tooltip-id={`address-info_${uid}`}
                     data-tooltip-variant="info">
                    {(addressData.address.length > 45) ? addressData.address.slice(0, 40) + "..." : addressData.address}
                    {(addressData.address.length > 45) ? <Tooltip
                        id={`address-info_${uid}`}
                        content={addressData.address}
                        place="top-end"
                        style={{zIndex: 5}}
                    /> : null}
                </div>
            </div>
            <div>
                {
                    (addressData.lat === null || addressData.lon === null) ?
                        <div
                            data-tooltip-id="address-info-icon"
                            data-tooltip-variant="info"
                            className={style.infoIcon}
                        >
                            <InfoIcon/>
                        </div>
                        :
                        <>
                            <div>{(String(addressData.lat).length > 9) ? String(addressData.lat).slice(0, 9) : addressData.lat}</div>
                            <div>{(String(addressData.lon).length > 9) ? String(addressData.lon).slice(0, 9) : addressData.lon}</div>
                        </>
                }
                <Tooltip
                    place={"top"}
                    style={{zIndex: 5}}
                    id="address-info-icon"
                    html="По данному адресу отсутствуют координаты. <br/> Перейдите в редактирование, чтобы исправить ошибку."
                />
            </div>
            <div
                className={style.initPointSetting}
                 onClick={() => editInitialPoints.setEditInitPoint(addressData)}
            >
                <SettingIcon/>
            </div>
        </div>
    );
})

export default InitialPointItem;