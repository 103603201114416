import {makeAutoObservable} from 'mobx'
import {v4 as uuidv4} from "uuid";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MapIcon from "@mui/icons-material/Map";
import PlaceIcon from "@mui/icons-material/Place";
import * as React from "react";

const DEFAULT_LOGISTIC_SERVICE_URL = [
    {
        fulName: "Маршрутизация", menuItemID: uuidv4(), urlName: "", icon: <ReceiptLongIcon/>, select: false, subNav: [
            {
                fulName: "Маршрутизация по маршрутному листу",
                urlName: "/logisticService/routingByRouteList/routeSheetsList/",
                icon: <CoPresentIcon/>
            },
            {
                fulName: "Создание маршрутных листов",
                urlName: "/logisticService/createRouteSheets/",
                icon: <CoPresentIcon/>
            }
        ]
    },
    {
        fulName: "Маршруты", menuItemID: uuidv4(), urlName: "", icon: <LocalShippingIcon/>, select: false, subNav: [
            {fulName: "Мониторинг маршрутов", urlName: "/logisticService/deliversRoutes/", icon: <CoPresentIcon/>},
            {fulName: "Шаблоны ответов для водителей", urlName: "/logisticService/deliveredStatusList/templateList/all", icon: <CoPresentIcon/>},
            {fulName: "Транспортные средства", urlName: "/logisticService/vehicleList/all", icon: <CoPresentIcon/>},
            {fulName: "Водители и экспедиторы", urlName: "/logisticService/driverAndForwarder/list/all", icon: <CoPresentIcon/>}
        ]
    },
    {
        fulName: "Карта", menuItemID: uuidv4(), urlName: "", icon: <MapIcon/>, select: false, subNav: [
            {
                fulName: "Готовые маршруты на карте",
                urlName: "/logisticService/mapRouteView/",
                icon: <CoPresentIcon/>
            },
            {
                fulName: "Зоны доставки",
                urlName: "/logisticService/deliveryAreas/areaList/all",
                icon: <CoPresentIcon/>
            }
        ]
    },
    {
        fulName: "Координаты", menuItemID: uuidv4(), urlName: "", icon: <PlaceIcon/>, select: false, subNav: [
            {
                fulName: "Редактирование координат адресов",
                urlName: "/logisticService/addressCoordinateEditing/addressList/all",
                icon: <CoPresentIcon/>
            },
            {
                fulName: "Редактирование координат начальной точки",
                urlName: "/logisticService/initialsPointsEditing/",
                icon: <CoPresentIcon/>
            },
        ]
    },
]

class SavpMenuLogisticOption {

    logisticServiceUrl = DEFAULT_LOGISTIC_SERVICE_URL;

    menuLogisticState = {
        showSubRightMenu: false,
        selectLogisticMenuOption : false,
        positionSubRightMenu: {x: 0, y: 0},
        selectedSubNavId: null,
        selectedSubNavData: null
    }

    constructor() {
        makeAutoObservable(this,)
    }

    changeDefaultStateRightMenu(){
        this.logisticServiceUrl = DEFAULT_LOGISTIC_SERVICE_URL;
        this.menuLogisticState.positionSubRightMenu = {x: 0, y: 0};
        this.menuLogisticState.selectedSubNavId = null;
        this.menuLogisticState.selectedSubNavId = null;
    }

    changeLogisticMenuOption(newValue){
        this.menuLogisticState.selectLogisticMenuOption = newValue;
    }
    changeSubRightMenu(newValue){
        this.menuLogisticState.showSubRightMenu = newValue;
    }
    changeNewPositionSubRightMenu(newPosition){
        this.menuLogisticState.positionSubRightMenu = newPosition;
    }


    /**
     * Обрабатывает выбор элемента навигации в логистическом сервисе и обновляет состояние меню.
     *
     * @function
     * @param {Object} params - Параметры функции.
     * @param {Object} params.menuItemRef - Ссылка на элемент меню, на который было выполнено действие.
     * @param {string} params.menuItemId - Идентификатор элемента меню, на который было выполнено действие.
     * @param {*} params.selectNav - Наименование элемента меню.
     * @param {*} params.subNavData - Данные подменю, которые будут сохранены в состоянии.
     */
    selectNavLogisticService({menuItemRef, menuItemId, selectNav, subNavData}){
        this.changeSubRightMenu(true);

        this.logisticServiceUrl = this.logisticServiceUrl.map(navObj => {
            if (navObj.fulName === selectNav) {
                const rect = menuItemRef.current.getBoundingClientRect();
                this.menuLogisticState.positionSubRightMenu = {x: rect.left, y: rect.top}

                this.menuLogisticState.selectedSubNavData = subNavData;
                this.menuLogisticState.selectedSubNavId = menuItemId;
                return {...navObj, select: true};
            } else {
                return {...navObj, select: false};
            }
        });
    }

}

export default new SavpMenuLogisticOption();