import React from 'react';
import style from './DriverAndForwarderEdit.module.css'
import Skeleton from '@mui/material/Skeleton';

const DriverAndForwarderEditSkeleton = () => {

    return (
        <div className={style.createTemplate}>
            <div className={style.createTemplateHeader}>
                Редактирование карточки
            </div>

            <div className={style.createTemplateForm}>
                <div className={style.selectTypeStatus}>
                    <div>Тип карточки:</div>
                    <Skeleton
                        style={{
                            marginLeft: "10px"
                        }}
                        animation="wave"
                        variant="rounded"
                        width={100}
                        height={20}
                    />
                </div>

                <div className={style.nameTemplateInput}>
                    <div>Наименование (ФИО):</div>
                    <Skeleton
                        style={{
                            marginLeft: "-10px"
                        }}
                        animation="wave"
                        variant="rounded"
                        width={200}
                        height={75}
                    />
                </div>
            </div>

        </div>
    );
};

export default DriverAndForwarderEditSkeleton;