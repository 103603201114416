import React, { useCallback } from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    List,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import ManufacturerCardRetailOutlets from "../ManufacturerCardRetailOutlets/ManufacturerCardRetailOutlets";

const ManufacturerTableTradingPoints = ({
                                           setItems,
                                           items,
                                           handleOpen,
                                           getCheckedIds,
                                           selectSeeAll,
                                           setSelectSeeAll,
                                           valueId,
                                       }) => {
    const getCheckedAll = useCallback((data) => {
        return data.flatMap((item) =>
            (item.retailOutletsList || []).filter((outlet) => outlet.checked)
        );
    }, []);

    const onCheckHandle = useCallback(
        (checkedItem) => {
            setItems(prevItems => prevItems.map((doc) => {
                const newRetailOutletsList = doc.retailOutletsList.map((retail) => {
                    if (retail.id === checkedItem.id) {
                        return {
                            ...retail,
                            checked: !retail.checked,
                        };
                    }
                    return retail;
                });
                return { ...doc, retailOutletsList: newRetailOutletsList };
            }));
        },
        [setItems]
    );

    return (
        <List
            sx={{
                bgcolor: "#fdfdfd",
                padding: 0,
                width: "60%",
                overflow: "hidden",
            }}
            subheader={
                <>
                    <Divider orientation="vertical" variant="middle" flexItem />
                </>
            }
        >
            <Box sx={{ display: "flex" }}>
                <Button
                    sx={{ marginLeft: "10px" }}
                    variant="contained"
                    onClick={handleOpen}
                    disabled={getCheckedIds(items).length === 0}
                >
                    Показать реализацию
                </Button>
                <FormGroup>
                    {/* <FormControlLabel
                        sx={{ marginLeft: "10px" }}
                        control={
                            <Checkbox
                                checked={selectSeeAll}
                                onChange={() => setSelectSeeAll(!selectSeeAll)}
                            />
                        }
                        label="Показать выбранные"
                    /> */}
                </FormGroup>
            </Box>
            <TableContainer sx={{ height: "75vh" }}>
                <Table stickyHeader aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Наиминование</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>ИНН</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Адрес</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!selectSeeAll && Boolean(valueId)
                            ? valueId.retailOutletsList.map((retailOutlet) => (
                                <ManufacturerCardRetailOutlets
                                    docId={valueId.id}
                                    key={retailOutlet.id}
                                    item={retailOutlet}
                                    onCheck={onCheckHandle}
                                />
                            ))
                            : selectSeeAll
                                ? getCheckedAll(items).map((retailOutlet) => (
                                    <ManufacturerCardRetailOutlets
                                        key={retailOutlet.id}
                                        item={retailOutlet}
                                        onCheck={onCheckHandle}
                                    />
                                ))
                                : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </List>
    );
};

export default ManufacturerTableTradingPoints;
