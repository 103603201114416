import Paper from "@mui/material/Paper";
import {Box, Button, TextField, Typography} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import {useState} from "react";
import {useAxiosAPI} from "../../apiRequest/apiRequest";
import AdminFormMapKey from "../AdminFormMapKey/AdminFormMapKey";
const AdminCreateUserForm = () => {
    const {apiAdminCreateUsers} = useAxiosAPI();

    const [nweUser, setNewUser] = useState({
        name: "",
        mail: "",
        password: "",
        inn: "",
        accessLevel: 0
    })
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const createUser = (data) => {
        apiAdminCreateUsers(data)
            .then((resolve) => {
                alert('Пользователь успешно создан')
            })
            .catch(() => {
                alert('Что-то пошло не так :(' )
            });
    }
    return (
        <Paper className={'adminFormPaper'} elevation={2}>
            <Typography sx={{textAlign: "center", padding: "20px"}} variant="h5" gutterBottom>
                    Новый пользователь
                </Typography>
                <Divider/>
        <Box sx={{padding: "20px"}}>
        <FormControl  sx={{margin: "20px"}} className={'adminTextField'} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">ФИО</InputLabel>
                <OutlinedInput
                    onChange={(event) =>  setNewUser(prev => ({...nweUser, name: event.target.value}) )}
                    value={nweUser.name}
                    id="outlined-adornment-password"
                    type={'text'}
                    label="ФИО"
                />
            </FormControl>
            <FormControl  sx={{margin: "20px"}} className={'adminTextField'} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">ИНН</InputLabel>
                <OutlinedInput
                    onChange={(event) =>  setNewUser(prev => ({...nweUser, inn: event.target.value}) )}
                    value={nweUser.inn}
                    id="outlined-adornment-password"
                    type={'text'}
                    label="ИНН"
                />
            </FormControl>
            <FormControl  sx={{margin: "20px"}} className={'adminTextField'} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Почта</InputLabel>
                <OutlinedInput
                    onChange={(event) =>  setNewUser(prev => ({...nweUser, mail: event.target.value}) )}
                    value={nweUser.mail}
                    id="outlined-adornment-password"
                    type={'email'}
                    label="Почта"
                />
            </FormControl>
            <FormControl  sx={{margin: "20px"}} className={'adminTextField'} variant="standard" >
                <InputLabel id="demo-simple-select-filled-label">Тип пользователя</InputLabel>
                <Select

                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={nweUser.accessLevel}
                    onChange={(event) =>  setNewUser(prev => ({...nweUser, accessLevel: event.target.value}) )}
                >
                    <MenuItem value={0}>Дистрибьютор </MenuItem>
                    <MenuItem value={1}>Производитель</MenuItem>

                </Select>
            </FormControl>
            <FormControl  sx={{margin: "20px"}} className={'adminTextField'} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                <OutlinedInput
                    onChange={(event) =>  setNewUser(prev => ({...nweUser, password: event.target.value}) )}
                    value={nweUser.password}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Пароль"
                />
            </FormControl>
            
            <Button sx={{margin: "20px"}} variant={"contained"} onClick={() => createUser(nweUser)}  className={'adminTextField'}>Сохранить</Button>

        </Box>
        </Paper>
    )
}
export default AdminCreateUserForm