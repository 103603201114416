import {
  CardHeader,
  Switch,
  TextField
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import Loader from "../Loader/Loader";
import SearchDistributorList from "../SearchDistributorList/SearchDistributorList";
import "./ProfileDistributorList.css";

import Button from "@mui/material/Button";
import ProfileModalDistributorRegion from "../ProfileModalDistributorRegion/ProfileModalDistributorRegion";
// import UnloadingPeriodInput from "../UnloadingPeriodInput/UnloadingPeriodInput";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { generateLocalColorFromText } from "../../helpers/helpers";
import ProfileDistributorRegistrationModal from "../ProfileDistributorRegistrationModal/ProfileDistributorRegistrationModal";
import UnloadingPeriodInput from "../ProfileModalDistributor/UnloadingPeriodInput";
import Search from "../ui/Search/Search";
import ProfilePatchRegionUser from "../ProfilePatchRegionUser/ProfilePatchRegionUser";

function filterDistributor(data, searchText) {
  return data.filter((item) => {
    const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
    const itemInn = item.inn.toLowerCase(); // Получаем поле "article" объекта
    return itemName.includes(searchText.toLowerCase()) || itemInn.includes(searchText.toLowerCase());
  });
}
function UserCard({
  selectedRow,
  setSelectedRow,
  setSearchResults,
  setDistributorUser,
}) {
  const { apiDistributorUsers, apiPatchDistribList, apiDistributor } =
    useAxiosAPI();
  const [openRegion, setOpenRegion] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(true); // Добавляем состояние loader

  const [region, setRegion] = useState(selectedRow.region);
  const [dayLimit, setDayLimit] = useState(selectedRow.dayLimit);
  const [uploadsAllowed, setUploadsAllowed] = useState(
    selectedRow.uploadsAllowed
  );
  const [savpAccess, setSavpAccess] = useState(
    selectedRow.savpAccess
  );

  // Открыть окно лимит дат
  const handleClose = () => {
    setOpen(true);
  };

  // Открыть окно задать регион
  const handleRegion = () => {
    setOpenRegion(true);
  };

  const handlePost = () => {
    apiPatchDistribList(
      {
        region: region,
        dayLimit: Number(dayLimit),
        uploadsAllowed: uploadsAllowed,
        savpAccess: savpAccess
      },
      selectedRow.id
    )
      .then(() => {
        apiDistributorUsers()
          .then((resolve) => {
            setSearchResults(resolve.data);
            setDistributorUser(resolve.data);
            setSelectedRow(null);
          })
          .catch(() => {
            setSearchResults([]);
            setDistributorUser([]);
          });
      })
      .catch(() => {
        alert("Ошибка");
      });
  };

  return (
    <Card sx={{ width: "100%", boxShadow: 0, height: "80vh", overflow: "auto" }}>
      <CardHeader
        title={
          <Box sx={{ marginLeft: "1vw", display: "flex" }}>
            <Box>
              <Avatar
                sx={{
                  backgroundImage:
                    "linear-gradient(98deg, #ff9900, #ffa500 94%)",
                  width: "5vw",
                  height: "5vw",
                  fontSize: "2vw",
                }}
                variant="square"
              >
                {selectedRow.name[0]}
              </Avatar>
            </Box>

            <Box sx={{ marginLeft: "1vw" }}>
              <Typography variant="h5">{selectedRow.name}</Typography>
              <Typography>Дистрибьютор</Typography>
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Box>
          {/* <ProfileModalDistributorRegion
            setLoader={setLoader}
            setSearchResults={setSearchResults}
            setDistributor={setDistributorUser}
            open={openRegion}
            setOpen={setOpenRegion}
            distributor={selectedRow}
            distribs={[selectedRow.id]}
          /> */}
          {/* <ProfilePatchRegionUser
                                    setActiveString={setSelectedRow}
                                    activeString={selectedRow}
                                    setRegions={setRegion}
                                    regions={region}
                                /> */}
          

          <Box sx={{ display: "flex", marginTop: "20px" }}>
            <Box sx={{ width: "50%" }}>
              <Box sx={{ display: "flex" }}>
                <Box className="leftString">
                  <Typography>ИНН</Typography>
                  <TextField
                    sx={{ width: "90%" }}
                    disabled
                    id="outlined-disabled"
                    // label="Disabled"
                    defaultValue={selectedRow.inn}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "10px", display: "flex" }}>
                <Box className="leftString">
                  <Typography>КПП</Typography>
                  <TextField
                    sx={{ width: "90%" }}
                    disabled
                    id="outlined-disabled"
                    // label="Disabled"
                    defaultValue={selectedRow.kpp}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "10px", display: "flex" }}>
                <UnloadingPeriodInput
                  dayLimit={dayLimit}
                  setDayLimit={setDayLimit}
                  setLoader={setLoader}
                  setSearchResults={setSearchResults}
                  setDistributor={setDistributorUser}
                  open={open}
                  setOpen={setOpen}
                  distributor={selectedRow}
                  distribs={[selectedRow.id]}
                />
              </Box>

              <Box sx={{ marginTop: "10px", display: "flex" }}>
                <Typography>
                  Доступ к выгрузке данных из 1С:
                  <Switch
                    checked={uploadsAllowed}
                    onChange={() => setUploadsAllowed(!uploadsAllowed)}
                  />
                </Typography>
              </Box>
              <Box sx={{ marginTop: "10px", display: "flex" }}>
                <Typography>
                  Доступ к САВП:
                  <Switch
                    checked={savpAccess}
                    onChange={() => setSavpAccess(!savpAccess)}
                  />
                </Typography>
              </Box>

              <Box sx={{ marginTop: "10px", display: "flex" }}>
              <Button
                  disabled={
                    savpAccess == selectedRow.savpAccess &&
                    dayLimit == selectedRow.dayLimit &&
                    region == selectedRow.region &&

                    uploadsAllowed === selectedRow.uploadsAllowed
                  }
                  sx={{ marginRight: "1vw", marginTop: "10px" }}
                  onClick={handlePost}
                  variant="contained"
                >
                  Сохранить
                </Button>
                <Button
                
                  onClick={() => setSelectedRow(null)}
                  variant="contained"
                >
                  Отмена
                </Button>
                
              </Box>
            </Box>

            <Box sx={{ width: "50%" }}>
              <Box sx={{ display: "flex" }}>
                <Box className="leftString">
                  <Typography>Почта</Typography>
                  <TextField
                    sx={{ width: "90%" }}
                    disabled
                    id="outlined-disabled"
                    // label="Disabled"
                    defaultValue={selectedRow.mail}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "10px", display: "flex" }}>
                <Box className="leftString">
                  <Typography>Производил выгрузку данных из 1С?</Typography>
                  <TextField
                    sx={{ width: "90%" }}
                    disabled
                    id="outlined-disabled"
                    // label="Disabled"
                    defaultValue={selectedRow.cardDistributor ? "ДА" : "НЕТ"}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: "10px", display: "flex" }}>
                <Box className="leftString">
                <ProfilePatchRegionUser
                                    setActiveString={setSelectedRow}
                                    activeString={selectedRow}
                                    setRegions={setRegion}
                                    regions={region}
                                />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function ProfileDistributorList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [distributorUser, setDistributorUser] = useState([]);
  const { apiDistributorUsers } = useAxiosAPI();
  const [loader, setLoader] = useState(true); // Добавляем состояние loader
  const { state } = useContext(GlobalContext);
  const [rows, setSearchResults] = useState(distributorUser);

  // console.log(rows)

  useEffect(() => {
    setLoader(true); // Установите loader в true перед началом запроса
    apiDistributorUsers()
      .then((resolve) => {
        setDistributorUser(resolve.data);
        setSearchResults(resolve.data);
        setLoader(false);
      })
      .catch(() => {
        setDistributorUser([]);
        setSearchResults([]);
        setLoader(false);
      });
  }, [apiDistributorUsers, state.manufacturer]);

  // выбранный дистрибьютор
  const [selectedRow, setSelectedRow] = useState(null);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [actionDistributor, setActionDistributor] = useState({});
  const [searchComplete, setSearchComplete] = useState("")

  useEffect(() => {
    if (selectedRow) {
      const updatedSelectedRow = rows.find((row) => row.id === selectedRow.id);
      setSelectedRow(updatedSelectedRow || null);
    }
  }, [rows]);

  const handleRowClickRegisterModal = (row) => {
    setActionDistributor(row);
    setOpenModalRegister(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", borderRadius: 0, boxShadow: 0 }}>
      <Loader open={loader} />
      {!selectedRow ? (
        <>
          <TableContainer sx={{ padding: 0, maxHeight: "calc(84vh - 16px)" }}>
            <Loader open={loader} />
            <Table stickyHeader aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" colSpan={8}>

                    <Search
                        searchComplete={searchComplete}
                        setSearchComplete={setSearchComplete}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 700 }}>
                    Наименование
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>ИНН</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Почта</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Лимит выгрузки</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Регион</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Активный пользователь
                  </TableCell>
                
                </TableRow>
              </TableHead>
              <TableBody>
                <ProfileDistributorRegistrationModal
                  openModalRegister={openModalRegister}
                  setOpenModalRegister={setOpenModalRegister}
                  actionDistributor={actionDistributor}
                />

                {filterDistributor(rows, searchComplete).map((distributor) => (
                    <>
                  <TableRow
                    key={distributor.id}
                    className={
                      distributor.isActivated ? "table-row" : "table-row-block"
                    }
                    onClick={() =>
                      distributor.isActivated
                        ? setSelectedRow(distributor)
                        : handleRowClickRegisterModal(distributor)
                    }
                  >
                    <TableCell>
                      <Avatar
                        sx={{
                          backgroundColor: generateLocalColorFromText(
                            distributor.name
                          ),
                        }}
                        alt={distributor.name}
                        src="/broken-image.jpg"
                      />
                    </TableCell>
                    <TableCell>{distributor.name}</TableCell>
                    <TableCell>{distributor.inn}</TableCell>
                    <TableCell>{distributor.mail}</TableCell>
                    <TableCell>{distributor.dayLimit}</TableCell>
                    <TableCell>{distributor.region}</TableCell>
                    <TableCell>
                      {distributor.isActivated ? (
                        <LockOpenIcon color="success" />
                      ) : (
                        <LockIcon color="error" />
                      )}
                    </TableCell>
                    
                    
                  </TableRow>

                    </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <UserCard
          setSearchResults={setSearchResults}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setDistributorUser={setDistributorUser}
        />
      )}
    </Paper>
  );
}
