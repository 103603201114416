import React from 'react';
import style from './DeliveryAreaCreateHeaderList.module.css'
import createNewZona from "../../../../store/transportationLogisticsService/PageDeliveryZone/createDeliveryZone";
import {observer} from "mobx-react-lite";

const DeliveryAreaCreateHeaderList = observer(() => {
    return (
        <div className={style.tableHeader}>
            <div className={style.selectAllPoint}>
                <input type="checkbox"
                       className={style.selectDeliveryZoneAll}
                       id={`SelectAll`}
                       name="selectedDeliveryZoneAll"
                       checked={createNewZona.statusCreatingPage.checkedAllPoint}
                       onChange={(e) => {
                           createNewZona.setAllCheckedTrue(e.target.checked)
                       }}
                />
                <label htmlFor={`SelectAll`}></label>
            </div>
            <div>Точки координат зоны</div>
            <div>Удалить</div>
        </div>
    );
});

export default DeliveryAreaCreateHeaderList;