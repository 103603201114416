import React, {memo, useEffect} from 'react';
import { ReactQrCode } from "@devmehq/react-qr-code";
import CryptoJS from 'crypto-js';
import style from './ContentPrintApplicationAccess.module.css'



const ContentPrintApplicationAccess = React.forwardRef(({docData}, ref) => {
    const {drivers_info, document_number, document_date, delivery_date, order_list, route_summary}= docData;

    const generateAuthData = () => {
        for (const driversObj of docData.drivers_info){
            if (driversObj.forwarder === false){
                const secretKey = process.env.REACT_APP_SECRET_KEY;
                const data ={
                    company_id: docData.main_user_id,
                    user_id: driversObj.driver_uid,
                    driver_name: driversObj.driver_name
                }

                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
                // var decrypted = CryptoJS.AES.decrypt(encrypted, "Secret Passphrase");
                console.log(String(encryptedData))
                return String(encryptedData);
            }
        }
    }

    const getCurrentDate = (dateISO) => {
        let date = new Date(dateISO);

        // Форматирование даты и времени в формат "dd.mm.yyyy hhч mmм"
        return date.getDate().toString().padStart(2, '0') + '.' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
            date.getFullYear()
    }

    const getCurrentTimeAllRoute = () => {
        if (route_summary?.time){
            const milliseconds = route_summary?.time * 1000;
            let hours = Math.floor(milliseconds / (1000 * 60 * 60));
            let minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
            return `${hours}ч ${minutes}м`;
        }
    }

    function countUniqueOrderClients(array) {
        const uniqueClients = new Set();

        array.forEach(item => {
            if (item.order_client) {
                uniqueClients.add(item.order_client);
            }
        });

        return uniqueClients.size;
    }

    return (
        <div className={style.contentQrPrint}>
            <div className={style.QrCode}>
                <ReactQrCode
                    value={generateAuthData()}
                    size={150}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    renderAs={"svg"}
                    marginSize={50}
                />
            </div>
            <div className={style.driverOrRouteInfo}>
                <div>{`Водитель: ${drivers_info[0].driver_name}`}</div>
                <div>{`Экспедитор: ${drivers_info[1].driver_name}`}</div>
                <div>{`Номер документа: ${document_number}`}</div>
                <div>{`Дата документа: ${getCurrentDate(document_date)}`}</div>
                <div>{`Дата доставки: ${getCurrentDate(delivery_date)}`}</div>
                <div>{`Торговых точек: ${order_list.length}, Заказов: ${countUniqueOrderClients(order_list)}`}</div>
                <div>{`Километраж маршрута: ${Number(route_summary?.length).toFixed(1)} км`}</div>
                <div>{`Время на выполнение маршрута: ${getCurrentTimeAllRoute()}`}</div>
            </div>
        </div>
    );
});

export default memo(ContentPrintApplicationAccess);