import React, {memo, useEffect} from 'react';
import style from './GeneralRouteOptimizationSettings.module.css';
import SettingOptimizationRouteByVehicle from "../SettingOptimizationRouteByVehicle/SettingOptimizationRouteByVehicle";
import {observer} from 'mobx-react-lite';
import optimizationRouteSheetStore
    from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import SelectUploadDataDocuments
    from "../SelectUploadDataDocuments/SelectUploadDataDocuments";
import RouteOptimizationParameters
    from "../RouteOptimizationParameters/RouteOptimizationParameters";
import RouteOptimizationInitPoint
    from "../RouteOptimizationInitPoint/RouteOptimizationInitPoint";
import RouteDateAndTimeStart from "../RouteDateAndTimeStart/RouteDateAndTimeStart";
import RouteButtonGroup from "../RouteButtonGroup/RouteButtonGroup";
import RouteUpdateOrUnloadButtonGroup
    from "../RouteUpdateOrUnloadButtonGroup/RouteUpdateOrUnloadButtonGroup";
import RoutePrintQrData from "../RoutePrintQrData/RoutePrintQrData";

const GeneralRouteOptimizationSettings = observer(() => {
    const {pageState:{setting_document}} = optimizationRouteSheetStore;

    return (

        <div className={
            (setting_document) ?
                `${style.generalSettingContent} ${style.generalSettingContentHide}` : style.generalSettingContent
        }>
            <div className={style.generalSettingHeader}>
                <div>ОБЩАЯ КОНФИГУРАЦИЯ ПАРАМЕТРОВ</div>
            </div>

            <div className={style.generalSettingBlock}>
                <SelectUploadDataDocuments/>

                <SettingOptimizationRouteByVehicle typeSetting={"generalSettingForDocuments"}/>

                <RouteOptimizationParameters/>

                <RouteOptimizationInitPoint/>

                <RouteDateAndTimeStart/>
            </div>

            <RouteButtonGroup/>

            <RouteUpdateOrUnloadButtonGroup/>

            <RoutePrintQrData/>
        </div>
    );
});

export default memo(GeneralRouteOptimizationSettings);