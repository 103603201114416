import * as React from "react";

import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useCallback } from "react";

export default function DistributorCardRetailOutlets({ item, onCheck }) {
    const onChangeHandle = useCallback(() => {
        onCheck(item);
    }, [onCheck, item]);

    return (
        <>
            <TableRow>
                <TableCell>
                    <FormControlLabel
                        sx={{ marginLeft: "10px", margin: 0 }}
                        control={
                            <Checkbox
                                edge="start"
                                checked={(item.checked)}
                                onChange={onChangeHandle}
                                disableRipple
                            />
                        }
                        label={item.name}
                    />
                </TableCell>
                <TableCell>{item.inn}</TableCell>
                <TableCell>{item.address}</TableCell>
            </TableRow>
        </>
    );
}
