import React, {memo, useEffect} from 'react';

import style from './DriverAndForwarderPage.module.css'
import MainMenuLogisticsService
    from "../../components/transportationLogisticsService/MainMenuLogisticsService/MainMenuLogisticsService";
import {Outlet} from "react-router-dom";
import {Toaster} from "react-hot-toast";

const DriverAndForwarderPage = () => {

    return (
        <div className={style.contentPage}>

            {/*Уведомления*/}
            <Toaster containerStyle={{
                position: 'fixed',
                top: '80px'
            }} />

            <div className={style.menuBlock}>
                <MainMenuLogisticsService namePage={"Водители и экспедиторы"}/>
            </div>

            {
                <Outlet/>
            }


        </div>
    );
};

export default memo(DriverAndForwarderPage);