import { TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useAxiosAPI } from "../../apiRequest/apiRequest";
import GlobalContext from "../../context/GlobalContext";
import ProfilePatchRegionUser from "../ProfilePatchRegionUser/ProfilePatchRegionUser";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


export default function ProfileRegistrationModalUserManufacturer(props) {
  const { state } = React.useContext(GlobalContext);
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [patronymic, setPatronymic] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [uploadDaysError, setUploadDaysError] = React.useState("");
  const { apiRegistrationUserManufacturer, apiManufacturerUsers } = useAxiosAPI();
  const [selectedValues, setSelectedValues] = React.useState([]);

  const registerPost = () => {
    apiRegistrationUserManufacturer({
        
            "mail": email,
            "name": name + " " + surname + " " + patronymic,
            "regions": selectedValues,
            "position": position
          
    })
      .then(() => {
        apiManufacturerUsers()
        .then((resolve) => {
            props.setManufacturerUser(resolve.data);
            props.setSearchResults(resolve.data);
            props.setLoader(false);
          })
          .catch(() => {
            props.setManufacturerUser([]);
            props.setSearchResults([]);
            props.setLoader(false);
          });
        props.setOpenModalRegister(false);
      })
      .catch(() => {
        alert("Пользователь с таким email уже создан");
      });
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
  };

  const handleNameChange = (event) => {
    const enteredName = event.target.value;
    setName(enteredName);
  };

  const handleSurnameChange = (event) => {
    const enteredSurname = event.target.value;
    setSurname(enteredSurname);
  };

  const handlePatronymicChange = (event) => {
    const enteredPatronymic = event.target.value;
    setPatronymic(enteredPatronymic);
  };

  const handlePositionChange = (event) => {
    const enteredPosition = event.target.value;
    setPosition(enteredPosition);
  };

  const isButtonDisabled = !email || !name || !surname || !patronymic || !position;


  const handleSelectionChange = (event, values) => {
    // Проверяем, что количество выбранных значений не превышает 6
    // if (values.length <= 6) {
      setSelectedValues(values);
    // }
  };

  return (
    <Modal
      open={props.openModalRegister}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 900 }}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ marginBottom: "3vh", width: "50%" }}>
            <TextField
              className="inputModal"
              required
              id="filled-read-only-input"
              label="Имя"
              value={name}
              onChange={handleNameChange}
              // type="number"
              variant="standard"
              error={!!uploadDaysError}
              helperText={uploadDaysError}
            />

            <TextField
              className="inputModal"
              required
              id="filled-read-only-input"
              label="Фамилия"
              value={surname}
              onChange={handleSurnameChange}
              // type="number"
              variant="standard"
              error={!!uploadDaysError}
              helperText={uploadDaysError}
            />

            <TextField
              className="inputModal"
              required
              id="filled-read-only-input"
              label="Отчество"
              value={patronymic}
              onChange={handlePatronymicChange}
              // type="number"
              variant="standard"
              error={!!uploadDaysError}
              helperText={uploadDaysError}
            />

            <TextField
              className="inputModal"
              required
              id="filled-read-only-input"
              label="Должность"
              value={position}
              onChange={handlePositionChange}
              // type="number"
              variant="standard"
              error={!!uploadDaysError}
              helperText={uploadDaysError}
            />

            <TextField
              className="inputModal"
              required
              id="filled-read-only-input"
              label="Почта"
              value={email}
              onChange={handleEmailChange}
              type="email"
              variant="standard"
            />

            <Box sx={{width: "90%", marginTop: "10px"}}>
              <ProfilePatchRegionUser
                  setRegions={setSelectedValues}
                  regions={selectedValues}
              />
            </Box>

          </Box>
          <Box sx={{ marginBottom: "3vh", width: "50%" }}>
            <h3 id="parent-modal-title">
              Уважаемый пользователь
            </h3>

            <Typography variant="body1" gutterBottom>
              Для предоставления доступа в САВП вашему сотруднику, пожалуйста,
              зарегистрируйте его в нашем сервисе. Для того чтобы обеспечить ему
              возможность взаимодействия с данными ваших дистрибьюторов, просим
              вас указать регионы, относящиеся к дистрибьюторам, к которым вы
              желаете предоставить доступ вашему сотруднику. <br />
              Также заполните информацию о сотруднике и нажмите кнопку
              "Зарегистрировать". <br />
              На указанный вами адрес электронной почты сотрудника будет
              отправлено письмо с инструкциями по предоставлению доступа к
              личному кабинету пользователя в нашем сервисе.
            </Typography>
            <Typography variant="body1" gutterBottom>
              С благодарностью за выбор нашей платформы.
            </Typography>
          </Box>
        </Box>
        <Button
          onClick={registerPost}
          sx={{ marginRight: "1vw" }}
          variant="contained"
          disabled={isButtonDisabled}
        >
          Зарегистрировать
        </Button>
        <Button
          variant="contained"
          onClick={() => props.setOpenModalRegister(false)}
        >
          Отмена
        </Button>
      </Box>
    </Modal>
  );
}