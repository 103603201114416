import React, {useContext, useEffect, useMemo, useState} from 'react';
import style from './LogisticServiceSideBar.module.css'
import {ReactComponent as IconLogo} from '../../../../imges/IconLogo.svg';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import logoGeneral from "../../../../imges/generalLogo.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MuiAppBar from "@mui/material/AppBar";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import {styled, useTheme} from "@mui/material/styles";
import savpMenuLogisticOption
    from "../../../../store/transportationLogisticsService/savpMenuLogisticOption/savpMenuLogisticOption";
import {Link, useLocation} from "react-router-dom";
import './LogisticServiceSideBar.css'
import ContentMenuLogisticService from "../../Content&MenuLogisticService/Content&MenuLogisticService";
import {v4 as uuidv4} from "uuid";
import OutputIcon from "@mui/icons-material/Output";
import GlobalContext from "../../../../context/GlobalContext";

const LogisticServiceSideBar = ({accessLogistics, child, url}) => {
    const {logoutUser, user} = useContext(GlobalContext);
    const {
        menuLogisticState: {showSubRightMenu, selectLogisticMenuOption, selectedSubNavId},
        logisticServiceUrl
    } = savpMenuLogisticOption;
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const drawerWidth = 210;
    const location = useLocation();
    let [navigate, setNavigate] = useState(decodeURIComponent(location.pathname));
    const drawerRef = React.useRef();


    

    useEffect(() => {

        setTimeout(()=> {
            setOpen(false)
        }, 150)

        return () => {
            setOpen(true)
        }

    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                if (savpMenuLogisticOption.menuLogisticState.showSubRightMenu){
                    return
                } else if (savpMenuLogisticOption.menuLogisticState.selectLogisticMenuOption === false){
                    return
                }
                handleDrawerClose();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [drawerRef]);

    const checkedLogisticService = () => {
        const substr = "/routersOnTheMap";
        const substr1 = "/logisticService";

        if (navigate.includes(substr) || navigate.includes(substr1)) {
            return false
        } else {
            return true
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        savpMenuLogisticOption.changeDefaultStateRightMenu()
        savpMenuLogisticOption.changeSubRightMenu(false);
        savpMenuLogisticOption.changeLogisticMenuOption(false);
        setOpen(false);
    };

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up("sm")]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled("div")(({theme}) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    return (
        <>
            <Box sx={{display: "flex", position: "fixed"}} className={style.page} >
                <Drawer ref={drawerRef} variant="permanent" open={open}
                        sx={{
                            width: drawerWidth,
                                flexShrink: 0,
                                whiteSpace: "nowrap",
                                boxSizing: "border-box",
                            position: "fixed",
                            zIndex: 999999,
                            ...(open && {
                                ...openedMixin(theme),
                                "& .MuiDrawer-paper": openedMixin(theme),
                            }),
                            ...(!open && {
                                ...closedMixin(theme),
                                "& .MuiDrawer-paper": closedMixin(theme),
                            })
                        }}
                        onMouseEnter={handleDrawerOpen}
                        onMouseLeave={handleDrawerClose}
                >
                    <DrawerHeader variant="permanent"
                                  sx={{
                                      width: (open)? drawerWidth : 63,
                                      flexShrink: 0,

                                      [`& .MuiDrawer-paper`]: {
                                          width: drawerWidth,
                                          boxSizing: "border-box",
                                          paddingRight: "10px",
                                          borderRight: 0,
                                          overflowY: (showSubRightMenu) ? "hidden" : "auto"
                                      }
                                  }}
                    >
                        <Toolbar>
                            <List >
                                <ListItem disablePadding className={"logo"}
                                >
                                    {
                                        open?
                                            <img className={style.logoSvg} src={logoGeneral}/> :
                                            <IconLogo className={style.iconLogo}/>
                                    }
                                </ListItem>
                            </List>
                        </Toolbar>
                    </DrawerHeader>
                    <Divider/>
                    <List id={"test"} className={"MuiButtonBase-root"}>
                        <ListItem disablePadding sx={{display: "block"}}>
                            {url.map((nav) => {
                                if (nav.fulName === "Логистика") {
                                    return <ContentMenuLogisticService 
                                    accessLogistics={accessLogistics}
                                    key={uuidv4()} nav={nav}
                                                                       navigate={navigate}
                                                                       setNavigate={setNavigate}/>
                                } else {
                                    return <ListItemButton
                                        key={nav.urlName}
                                        onClick={()=>{
                                            savpMenuLogisticOption.changeLogisticMenuOption(false);
                                            savpMenuLogisticOption.changeSubRightMenu(false);
                                            savpMenuLogisticOption.changeDefaultStateRightMenu();
                                        }}
                                        component={Link}
                                        to={nav.urlName}
                                        className={
                                            navigate === nav.urlName ? "navigateButton" : null
                                        }
                                    >
                                        <ListItemIcon
                                            sx={{
                                                color:
                                                    navigate === nav.urlName
                                                        ? "#ffffff"
                                                        : "rgba(58, 53, 65, 0.87)",
                                            }}
                                        >
                                            {nav.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={nav.fulName}/>
                                    </ListItemButton>
                                }
                            })}
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem disablePadding sx={{display: "block"}}>
                            <ListItemButton onClick={() => logoutUser()}>
                                <ListItemIcon>
                                    <OutputIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"Выйти"}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>
                <Box
                    className={
                        "contentMainDefault"
                    }
                    component="main"
                    id={"portal_menu"}>
                    {child}
                </Box>
            </Box>
        </>
    );
};

export default LogisticServiceSideBar;