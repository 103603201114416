import React, {memo, useEffect, useMemo, useState} from 'react';
import style from './DeliversRoutesSelectedPointList.module.css';
import {v4 as uuidv4} from "uuid";
import {AutoSizer, List} from "react-virtualized";
import DeliversRoutesSelectedPointItemList
    from "../DeliversRoutesSelectedPointItemList/DeliversRoutesSelectedPointItemList";
import deliversRoutes from "../../../../store/transportationLogisticsService/deliversRoutes/deliversRoutes";
import {observer} from "mobx-react-lite";
import DriverMessages from "../../DriverMessages/DriverMessages";
import DeliversRoutesSelectedPointListHeader
    from "../DeliversRoutesSelectedPointListHeader/DeliversRoutesSelectedPointListHeader";

const DeliversRoutesSelectedPointList = observer(() => {
    const {
        route_location
    } = deliversRoutes.copySelectedDeliver;

    const rowRenderer = ({index, key, style, isVisible, isScrolling}) => {
        const content = <DeliversRoutesSelectedPointItemList key={uuidv4()} index={index}/>;

        return (
            <div key={key} style={style}>
                {content}
            </div>
        )
            ;
    };

    return (
        <div className={style.deliversTable}>

            <DeliversRoutesSelectedPointListHeader/>

            <div>
                {
                    route_location &&
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <List
                                width={width}
                                height={805}
                                rowCount={route_location.length} // Количество элементов в списке
                                rowHeight={76} // Высота отдельного элемента списка
                                rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                                className={style.popupInitPointListBlock}
                                overscanRowCount={10}
                            />
                        )}
                    </AutoSizer>
                }
            </div>

            <>
                <DriverMessages/>
            </>


        </div>
    );
});

export default memo(DeliversRoutesSelectedPointList);