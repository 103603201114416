import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GlobalContext from "../../../context/GlobalContext";
import "../DistributorRatingBox/DistributorRatingBox.css";

import { Box, Skeleton } from "@mui/material";
import { useAxiosAPI } from "../../../apiRequest/apiRequest";
import SearchSKU from "../../SearchSKU/SearchSKU";
import Search from "../../ui/Search/Search";
import RotatingLoadIcon from "../../RotatingLoadIcon/RotatingLoadIcon";
function filterDistributorTable(data, searchText) {
  return data.filter((item) => {
    const itemName = item.name.toLowerCase(); // Получаем поле "name" объекта
    const itemINN = item.inn.toLowerCase(); // Получаем поле "article" объекта
    return itemName.includes(searchText.toLowerCase())
        || itemINN.includes(searchText.toLowerCase());
  });
}
export default function DistributorTableRetail(props) {
  const { apiGetRetailOutlets } = useAxiosAPI();
  const [searchComplete, setSearchComplete] = useState("")

  const [load, setLoad] = useState(false);
  const {
   
    distributorFinishDate,
 
  } = useContext(GlobalContext);
  // Массив документов
  const [nom, setNom] = useState([]);
  // Выбранный документ
  const [listOpen, setListOpen] = useState(true);

  // Добавляем состояние для хранения информации о нажатых кнопках

  useEffect(() => {
    setLoad(true);

    apiGetRetailOutlets(props.id)
      .then((resolve) => {
        setNom(resolve.data);
        setListOpen(!listOpen);
        setLoad(false);
      })
      .catch(() => {
        console.log("Ошибка");
        setLoad(false);
      });
  }, [distributorFinishDate]);



  return (
    <Box sx={{ overflow: "auto", height: "24vw" }}>
      {!load && nom.length > 0?
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{width: "25%"}}>
              <Search
                  searchComplete={searchComplete}
                  setSearchComplete={setSearchComplete}
              />            </TableCell>
            <TableCell>ИНН</TableCell>
            <TableCell>Адрес</TableCell>
            <TableCell>Сумма</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
                    {filterDistributorTable(nom, searchComplete).map((tag) => (
                        <TableRow key={tag.id}>
                            <TableCell>{tag.name}</TableCell>
                            <TableCell>{tag.inn}</TableCell>
                            <TableCell>{tag.address}</TableCell>
                            <TableCell>{tag.sum}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
          </Table>
                :
            <RotatingLoadIcon/>
        }
    </Box>
  );
}
