import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "mdi-material-ui/Shopping";
import StorefrontIcon from "mdi-material-ui/Storefront";
import TrendingUp from "mdi-material-ui/TrendingUp";
import "../DistributorRatingBox/DistributorRatingBox.css";

import AccountOutline from "mdi-material-ui/AccountOutline";
import {useContext} from "react";
import GlobalContext from "../../../context/GlobalContext";
import RotatingLoadIcon from "../../RotatingLoadIcon/RotatingLoadIcon";
import * as React from "react";

function formatNumberWithUnit(number) {
    // Удаление пробелов и замена запятой на точку
    const cleanedNumberString = number.replace(/\s/g, "").replace(",", ".");
    // Разделитель тысяч
    const separator = " ";
    const parsedNumber = parseFloat(cleanedNumberString);

    if (parsedNumber >= 1000000) {
        const millions = (parsedNumber / 1000000).toFixed(1);
        if (millions.endsWith(".0")) {
            return millions.replace(".0", "") + " млн"; // Убираем .0, если есть
        } else {
            return millions.replace(".", ",") + " млн";
        }
    } else if (parsedNumber >= 1000) {
        const thousands = (parsedNumber / 1000).toFixed(1);
        if (thousands.endsWith(".0")) {
            return thousands.replace(".0", "") + separator + "тыс"; // Убираем .0, если есть
        } else {
            return thousands.replace(".", ",") + separator + "тыс";
        }
    } else {
        return cleanedNumberString.replace(".", ",");
    }
}

const renderStats = (state, totalSumRO, totalSumNum, totalSum) => {
    const salesData = [
        {
            stats: formatNumberWithUnit(totalSum),
            title: "Продажи",
            color: "primary",
            icon: <TrendingUp sx={{fontSize: "1.75rem"}}/>,
        },
        {
            stats: state.distributors.length,
            title: "Дистрибьюторов",
            color: "success",
            icon: <AccountOutline sx={{fontSize: "1.75rem"}}/>,
        },
        {
            stats: totalSumRO,
            color: "info",
            title: "Торговых точек",
            icon: <StorefrontIcon sx={{fontSize: "1.75rem"}}/>,
        },
        {
            stats: totalSumNum,
            color: "warning",
            title: "Номенклатуры",
            icon: <ShoppingCartIcon sx={{fontSize: "1.75rem"}}/>,
        },
    ];
    return salesData.map((item, index) => (
        <Grid item xs={12} sm={3} key={index}>
            <Box key={index} sx={{display: "flex", alignItems: "center"}}>
                <Avatar
                    variant="rounded"
                    sx={{
                        mr: 3,
                        width: 44,
                        height: 44,
                        boxShadow: 3,
                        color: "common.white",
                        backgroundColor: `${item.color}.main`,
                    }}
                >
                    {item.icon}
                </Avatar>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography variant="caption">{item.title}</Typography>
                    <Typography variant="h6">{item.stats}</Typography>
                </Box>
            </Box>
        </Grid>
    ));
};

const DistributorStatisticsCard = (props) => {
    const {state} = useContext(GlobalContext);
    const totalSumRO = state.docsSum
        .reduce((accumulator, obj) => accumulator + obj.retailOutletCount, 0)
        .toLocaleString();
    const totalSumNum = state.docsSum
        .reduce((accumulator, obj) => accumulator + obj.nomCount, 0)
        .toLocaleString();
    const totalSum = state.docsSum
        .reduce((accumulator, obj) => accumulator + obj.manSum, 0)
        .toLocaleString();

    return (
        <Card sx={{boxShadow: "0px 2px 10px 0px rgba(58, 53, 65, 0.1)", position: 'relative'}}>
            {!props.loadSum ?
                <Box>

                    <CardHeader
                        title="Общая статистика"
                        titleTypographyProps={{
                            sx: {
                                mb: 2.5,
                                lineHeight: "2rem !important",
                                letterSpacing: "0.15px !important",
                            },
                        }}
                    />
                    <CardContent sx={{pt: (theme) => `${theme.spacing(3)} !important`}}>
                        <Grid container spacing={[5, 0]}>
                            {renderStats(state, totalSumRO, totalSumNum, totalSum)}
                        </Grid>
                    </CardContent>
                </Box>
                :
                <Box sx={{height: "15vw"}}>
                    <RotatingLoadIcon/>
                </Box>
            }
        </Card>
    );
};

export default DistributorStatisticsCard;
