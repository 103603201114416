export function dateConversion(dateXML) {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
    };
    return new Date(dateXML).toLocaleString("ru", options)
}

export function dateConvertSelection(s) {
    // Дата в формате 01.05.2002
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC',
    };
    let inp = new Date(s)
    let out = inp.toLocaleString("ru", options)
    out = out.replace(/\./g, '-')
    out = out.split("-").reverse().join("-");
    return out;
}



export function transformArray(array) {
// Преобразование актуальных остатков после запроса
    const transformedArray = [];

    array.forEach((item) => {
        const {nomenclatureObj, ...rest} = item;
        const existingNomenclature = transformedArray.find((obj) => obj.nomenclatureObj.id === nomenclatureObj.id);

        if (existingNomenclature) {
            existingNomenclature.nomenclatureObj.currentRemains.push({...rest});
        } else {
            transformedArray.push({
                nomenclatureObj: {
                    ...nomenclatureObj,
                    currentRemains: [{...rest}],
                },
            });
        }
    });

    return transformedArray;
}



export function optimizeArray (docs) {
    // Фцнкция преобразования номенклатуры в разделе SKU
    const modifiedArray = docs.map((item) => {

        const docTable = item.docTable.map((docItem) => {
            // Удаление проблелов у строк с числами
            const modifiedDocItem = {...docItem};
            modifiedDocItem.quantity = modifiedDocItem.quantity.replace(/\s/g, '');
            modifiedDocItem.price = modifiedDocItem.price.replace(/\s/g, '');
            modifiedDocItem.sum = modifiedDocItem.sum.replace(/\s/g, '');
            return modifiedDocItem;
        });

        const mergedDocTable = docTable.reduce((result, currentItem) => {

            const existingItem = result.find(
                (elem) => elem.nomenclatureId === currentItem.nomenclatureId
            );

            if (existingItem) {
                existingItem.quantity = Number(existingItem.quantity) + Number(currentItem.quantity);
                existingItem.sum = Number(existingItem.sum) + Number(currentItem.sum);
            } else {
                result.push({...currentItem});
            }

            return result;
        }, []);


        return {...item, docTable: mergedDocTable};
    });

    return modifiedArray;
};


// Функция посчета количества значений sku
export function countUniqueNomenclatureIds(dataArray) {
    const uniqueNomenclatureIds = new Set();

    dataArray.forEach((obj) => {
        if (obj.retailOutletObj && Array.isArray(obj.retailOutletObj)) {
            obj.retailOutletObj.forEach((outlet) => {
                if (outlet.docTable && Array.isArray(outlet.docTable)) {
                    outlet.docTable.forEach((item) => {
                        if (item.nomenclatureId) {
                            uniqueNomenclatureIds.add(item.nomenclatureId);
                        }
                    });
                }
            });
        }
    });

    return uniqueNomenclatureIds.size;
}

// Функция подсчета кол-во торговых точек
export function countUniqueIds(dataArray) {
    const uniqueIds = new Set();

    dataArray.forEach((obj) => {
        if (obj.retailOutletObj && Array.isArray(obj.retailOutletObj)) {
            obj.retailOutletObj.forEach((outlet) => {
                if (outlet.id) {
                    uniqueIds.add(outlet.id);
                }
            });
        }
    });

    return uniqueIds.size;
}

// Переворот даты
export function formatAndPrintDate(startDate) {
    const parts = startDate.split("-");
    const formattedDate = `${parts[2]}.${parts[1]}.${parts[0]}`;

    return formattedDate
}


// Генератор цвета на основании текста

export function generateLocalColorFromText(text) {
    // Преобразование текста в хеш
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Преобразование хеша в RGB компоненты
    const r = (hash & 0xFF0000) >> 16;
    const g = (hash & 0x00FF00) >> 8;
    const b = hash & 0x0000FF;

    // Формирование HEX цвета
    const hexColor = `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;

    return hexColor;
}
// Генератор массива цветов на основании name

export function generateColorFromText(arr) {
    const colors = [];

    for (let index of arr) {
        // Преобразование текста в хеш
        let hash = 0;
        for (let i = 0; i < index.name.length; i++) {
            hash = index.name.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Преобразование хеша в RGB компоненты
        const r = (hash & 0xFF0000) >> 16;
        const g = (hash & 0x00FF00) >> 8;
        const b = hash & 0x0000FF;

        // Формирование HEX цвета
        const hexColor = `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
        colors.push(hexColor);
    }
    return colors;



}
export function getObjectById(data, desiredId) {
    return data.find((item) => item.distrId === desiredId);
  }

export function getObjectByManId(data, id) {
    return data.find((item) => item.manId === id);
  }

