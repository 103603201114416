import {makeAutoObservable} from 'mobx'
import {v4 as uuidv4} from "uuid";
import globalState from "../globalState";
import axios from "axios";
import toast from "react-hot-toast";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from '../../../components/ToastMassage/ToastMassage';


const DEFAULT_CREATE_VALUE = {
    license_plate: '', // Гос номер
    vehicle_brand: '', // Марка авто
    appellation: '', // Наименование
    tonnage: '' // Тоннаж
}

class PageDeliveryVehiclesStore{

    vehicleList = [];
    vehicleListCopy = [];

    selectedVehicleList = [];

    listPageState = {
        selectedAllVehicle: false
    }

    editVehicleData = {};
    editPageState = {
        loading: true
    }

    createVehicleData = DEFAULT_CREATE_VALUE;

    paginationState = {
        page: 1,
        rowsPerPage: 10
    }

    popupState = {
        popupShow: false,
        popupType: "",
        popupScrollPosition: 0
    }

    constructor() {
        makeAutoObservable(this,)
    }

    changePopupShow(position,value, typePopup){
        this.popupState.popupShow = value;
        this.popupState.popupType = typePopup;
        this.popupState.popupScrollPosition = position;
    }

    closePopup(){
        this.popupState.popupShow = false;
        this.popupState.popupType = "";
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        if (rowsPerPage != null) {
            this.paginationState.rowsPerPage = rowsPerPage;
            this.paginationState.page = 1;

            this.showCurrentAmountVehicles();
        }
    };

    handleChangePage = (newPage) => {
        this.paginationState.page = newPage;

        this.showCurrentAmountVehicles();
    };

    showCurrentAmountVehicles() {
        // Вычисляем начальный индекс элементов для текущей страницы
        const startIndex = (this.paginationState.page - 1) * this.paginationState.rowsPerPage;
        // Вычисляем конечный индекс элементов для текущей страницы
        const endIndex = startIndex + this.paginationState.rowsPerPage;

        this.vehicleListCopy = this.vehicleList.slice(startIndex, endIndex);
    }

    changeSelectVehicle(vehId, newValue){
        this.vehicleList = this.vehicleList.slice().map(veh => {
            if (veh.id === vehId){
                veh.checked = newValue;

                if (newValue){
                    this.selectedVehicleList = [...this.selectedVehicleList, veh.id]
                } else {
                    this.selectedVehicleList = this.selectedVehicleList.filter(sVeh => sVeh !== veh.id)
                }
            }
            return veh
        })
    }

    changeSelectedAllVehicle(newValue){
        this.listPageState.selectedAllVehicle = newValue;

        this.selectedVehicleList = [];
        this.vehicleList = this.vehicleList.slice().map(veh => {
            veh.checked = newValue

            if (newValue){
                this.selectedVehicleList = [...this.selectedVehicleList, veh.id]
            }

            return veh
        })
    }

    changeNewInputValue(nameValue, newValue, nameEditObj){
        switch (nameValue) {
            case "appellation":
                this[nameEditObj].appellation = newValue;
                break;
            case "license_plate":
                this[nameEditObj].license_plate = newValue;
                break;
            case  "tonnage":
                this[nameEditObj].tonnage = Number(newValue);
                break;
            case "vehicle_brand":
                this[nameEditObj].vehicle_brand = newValue;
                break
            default:
                return
        }
    }

    changeDefaultValue_Edit(){
        this.editVehicleData = {};
        this.editPageState.loading = true;
    }

    changeDefaultValue_Create(){
        this.createVehicleData = DEFAULT_CREATE_VALUE;
    }

    setSelectedValue(vehicleList){

        if (this.selectedVehicleList.length === 0) {
            return vehicleList;
        }

        // Преобразование массива в объект для быстрого доступа
        const selectedVehMap = {};
        this.selectedVehicleList.forEach(id => {
            selectedVehMap[id] = true;
        });

        return vehicleList.map(vehObj => {
            // Проверка наличия id в объекте
            if (selectedVehMap[vehObj.id]) {
                vehObj.checked = true;
            }
            return vehObj;
        });
    }

    async getAllVehicle(authTokens, setUser, setAuthTokens, logoutUser){
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getAllDeliveryVehicle`, {headers}
        ).then(res => {
            this.vehicleList = this.setSelectedValue(res.data);
            this.vehicleListCopy = this.vehicleList;

            this.showCurrentAmountVehicles();
        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить транспорт!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    async getVehicleById(vehId, authTokens, setUser, setAuthTokens, logoutUser, navigate){
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getDeliveryVehicleById/vehId=${vehId}`, {headers}
        ).then(res => {
            this.editVehicleData = res.data
            this.editPageState.loading = false;
        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить транспорт!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    async createNewVehicle(authTokens, setUser, setAuthTokens, logoutUser, navigate){

        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            ...this.createVehicleData
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/createDeliveryVehicle`, data, {headers}
        ).then(res => {
            toastMassage(toast.success, "Карточка транспорта успешно создана", 2500, "bottom-right")
            navigate(`/logisticService/vehicleList/all`);
        }).catch(error => {
            toastMassage(toast.error, "Не удалось сохранить карточку транспорта!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    async updateVehicleData(authTokens, setUser, setAuthTokens, logoutUser, navigate){
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            ...this.editVehicleData
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/updateDeliveryVehicle`, data, {headers}
        ).then(res => {
            toastMassage(toast.success, "Карточка транспорта успешно обновлена!", 2500, "bottom-right")
            navigate(`/logisticService/vehicleList/all`);
        }).catch(error => {
            toastMassage(toast.error, "Не удалось обновить карточку транспорта!", 10000, "bottom-right")

            console.error('Ошибка:', error);
        })
    }

    async deleteVehicleById(vehId, authTokens, setUser, setAuthTokens, logoutUser, navigate){
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteDeliveryVehicleById/vehId=${vehId}`, {}, {headers}
        ).then(res => {
            toastMassage(toast.success, "Карточка транспорта успешно удалена!", 2500, "bottom-right")
            this.getAllVehicle(authTokens, setUser, setAuthTokens, logoutUser);
        }).catch(error => {
            toastMassage(toast.error, "Не удалось удалить карточку транспорта!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    async deleteSelectVehicles(authTokens, setUser, setAuthTokens, logoutUser, navigate){
        let userAccessToken;

        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }
        const data = {
            selectVehicles: this.selectedVehicleList
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteDeliveryVehicles`, data, {headers}
        ).then(res => {
            toastMassage(toast.success, "Карточки транспорта успешно удалены!", 2500, "bottom-right")
            this.closePopup()
            this.getAllVehicle(authTokens, setUser, setAuthTokens, logoutUser);

            this.selectedVehicleList = [];
        }).catch(error => {
            toastMassage(toast.error, "Не удалось удалить карточки транспорта!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

}

export default new PageDeliveryVehiclesStore();