import {makeAutoObservable} from "mobx";
import globalState from "../globalState";
import axios from "axios";
import toast from "react-hot-toast";
import MapData_DeliveryArea from "./mapData_DeliveryArea";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toastMassage } from "../../../components/ToastMassage/ToastMassage";


class EditDeliveryAreaStore {

    editAreaData = {}; // Объект редактируемой зоны

    listCreatedArea = []; // Массив созданных зон
    listCreatedAreaCopy = []; // Массив созданных зон

    selectCreatedArea = []; // Массив с выбранными зонами

    selectCreatedAreaState = { // Состояния модального окна выбора созданных зон
        selectAllArea: false,
        searchValue: ""
    }

    // Состояние страницы
    editPageState = {
        firstLoad: true,
        selectAllAreaPoint: false
    }

    // Состояние модального окна
    popupState = {
        popupShow: false,
        popupType: "",
        popupScrollPosition: 0
    }

    constructor() {
        makeAutoObservable(this,)
    }

    changeDefaultValueModalSelectCreatedArea() {
        this.listCreatedArea = [];
        this.listCreatedAreaCopy = [];
        this.selectCreatedAreaState.searchValue = "";
    }

    selectAllCreatedArea(newValue) {
        this.selectCreatedAreaState.selectAllArea = newValue;

        this.listCreatedArea = this.listCreatedArea.slice().map(area => {
            area.checked = newValue;
            return area
        })

        if (newValue) {
            this.selectCreatedArea = JSON.parse(JSON.stringify(this.listCreatedArea));
        } else {
            this.selectCreatedArea = [];
        }
    }

    changeDeliveryAreaText(newText) {
        this.selectCreatedAreaState.searchValue = newText;
        const regex = new RegExp(`^.*${newText.split(' ').join('.*')}.*$`, 'i');

        this.listCreatedArea = this.listCreatedAreaCopy.slice().filter(area =>
            regex.test(area.name_area.toString())
        )
    }

    async getAllDeliveryArea(areaId, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getAllDeliveryArea`, {headers}
        ).then(res => {
            const areaListData = this.changeCheckedValue_Modal(res.data, areaId);
            this.listCreatedArea = areaListData;
            this.listCreatedAreaCopy = this.listCreatedArea;
        }).catch(error => {
            toastMassage(toast.error, "Ошибка при получении зон доставки", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })

    }

    changeCheckedValue_Modal(areaList, areaId) {
        if (this.selectCreatedArea.length > 0) {
            let checkedArray = JSON.parse(JSON.stringify(this.selectCreatedArea));

            return areaList.filter(filterArea => filterArea.id !== areaId)
                .map(area => {
                    for (const checkedArea of checkedArray) {
                        if (checkedArea.id === area.id) {
                            area.checked = true;

                            checkedArray = this.selectCreatedArea.slice().filter(SelectArea => SelectArea.id !== area.id);
                            break;
                        }
                    }
                    return area;
                })
        } else {
            return areaList.filter(filterArea => filterArea.id !== areaId)
        }
    }

    selectCreatedDeliveryArea(newValue, areaId) {
        this.listCreatedArea = this.listCreatedArea.slice().map(area => {
            if (area.id === areaId) {
                area.checked = newValue;

                if (newValue) {
                    this.selectCreatedArea = [...this.selectCreatedArea, area];
                } else {
                    this.selectCreatedArea = this.selectCreatedArea.slice().filter(selectedArea =>
                        selectedArea.id !== areaId
                    )
                }
            }
            return area
        })
    }

    async getAreaById(areaId, authTokens, setUser, setAuthTokens, logoutUser) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        await axios.get(`${process.env.REACT_APP_LOGISTICS_SERVICE}/getDeliveryAreaById/areaId=${areaId}`, {headers}
        ).then(res => {
            this.editAreaData = res.data;
            this.initializationEditPage();
            this.addPointToTheMap();

        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить зону доставки!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    async updateAreaById(authTokens, setUser, setAuthTokens, logoutUser, navigate) {

        if (this.editAreaData.coordinates.length < 3){
            toastMassage(toast.error, "Необходимо чтобы у зоны доставки было как минимум 3 точки координат!", 10000, "bottom-right")
            return
        }

        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        let copiedElement = {...JSON.parse(JSON.stringify(this.editAreaData.coordinates[0]))};
        const newCoords = copiedElement.coordinates[0] -= 0.00001;

        let data;

        if (JSON.stringify(newCoords) !== JSON.stringify(copiedElement.coordinates[copiedElement.coordinates.length - 1])){
             data = {
                ...this.editAreaData,
                coordinates: [...this.editAreaData.coordinates, copiedElement]
            }
        } else {
             data = {
                ...this.editAreaData
            }
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/updateDeliveryArea`, data, {headers}
        ).then(res => {
            toastMassage(toast.success, "Зона доставки успешно обновлена!", 2500, "bottom-right")
            navigate(-1);
        }).catch(error => {
            toastMassage(toast.error, "Не удалось обновить зону доставки!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })

    }

    async deleteDeliveryAreaById(authTokens, setUser, setAuthTokens, logoutUser, nav) {
        let userAccessToken;
        await globalState.useLogoutUserAfterTimeToken(authTokens, setUser, setAuthTokens, logoutUser).then(tokens => userAccessToken = tokens)

        const headers = {
            'Authorization': `Bearer ${userAccessToken}`
        }

        const data = {
            ...this.editAreaData
        }

        await axios.post(`${process.env.REACT_APP_LOGISTICS_SERVICE}/deleteDeliveryAreaById`, data, {headers}
        ).then(res => {
            toastMassage(toast.success, "Зона доставки успешно удалена!", 2500, "bottom-right")
            nav(-1);
        }).catch(error => {
            toastMassage(toast.error, "Не удалось удалить зону доставки!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    showCreatedDeliveryArea() {
        const map = MapData_DeliveryArea.mapData;
        this.clearUserPointMap();

        if (this.selectCreatedArea.length === 0) return

        for (const createdArea of this.selectCreatedArea) {
            const idZone = createdArea.id;
            const coordinatesArray = createdArea.coordinates.map(point => point.coordinates);

            if (map) {
                // Проверяем, существует ли уже источник данных с таким именем
                if (!map.getSource(`userPolygon_${idZone}`)) {
                    map.addSource(`userPolygon_${idZone}`, {
                        type: 'geojson',
                        data: {
                            type: "FeatureCollection",
                            features: []
                        }
                    });
                }

                // if (!map.getSource(`userPoint_${idZone}`)) {
                //     map.addSource(`userPoint_${idZone}`, {
                //         type: 'geojson',
                //         data: {
                //             type: "FeatureCollection",
                //             features: []
                //         }
                //     });
                // }

                // Проверяем, существует ли уже слой с таким именем
                if (!map.getLayer(`userPolygon_${idZone}`)) {
                    map.addLayer({
                        'id': `userPolygon_${idZone}`,
                        'type': 'fill',
                        'source': `userPolygon_${idZone}`,
                        'layout': {},
                        'paint': {
                            'fill-color': `${createdArea?.color_area}`,
                            'fill-opacity': 0.5
                        }
                    });

                    map.addLayer({
                        'id': `userPolygonBorder_${idZone}`,
                        'type': 'line',
                        'source': `userPolygon_${idZone}`,
                        'layout': {},
                        'paint': {
                            'line-color': '#000000', // Цвет границы
                            'line-width': 3, // Ширина границы
                            'line-opacity': 1
                        }
                    });

                    map.addLayer({
                        'id': `userPolygonText_${idZone}`,
                        'type': 'symbol',
                        'source': `userPolygon_${idZone}`,
                        'layout': {
                            'text-field': `${createdArea?.name_area}`, // Замените 'Текст' на нужный текст
                            'text-font': ['Open Sans Regular'], // Шрифт текста
                            'text-size': 12 // Размер текста
                        },
                        'paint': {
                            'text-color': '#000000', // Цвет текста
                            'text-halo-color': '#ffffff', // Цвет алого круга
                            'text-halo-width': 2 // Ширина алого круга
                        }
                    });
                }

                // if (!map.getLayer(`userPoint_${idZone}`)) {
                //     map.addLayer({
                //         'id': `userPoint_${idZone}`,
                //         'type': 'symbol',
                //         'source': `userPoint_${idZone}`,
                //         "layout": {
                //             "icon-image": 'custom_pin',
                //             "icon-size": 0.2,
                //             'text-field': "{id}", // Отображаем номера точек
                //             'text-anchor': 'top',
                //             'text-offset': [0, 1],
                //             'text-size': 15
                //         },
                //         'paint': {
                //             'text-color': '#000000'
                //         }
                //     });
                // }


                // Формируем данные для слоя userPolygon
                const userPolygonData = {
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [coordinatesArray] // Убираем дополнительный массив
                        }
                    }]
                };

                // const userPointData = {
                //     type: "FeatureCollection",
                //     features: coordinatesArray.map((coords, index) => ({
                //         type: "Feature",
                //         properties: {
                //             id: index + 1 // Используем индекс как ID
                //         },
                //         geometry: {
                //             type: "Point",
                //             coordinates: coords
                //         }
                //     }))
                // };

                // Устанавливаем данные для источника данных userPolygon
                const userPolygonSource = map.getSource(`userPolygon_${idZone}`);
                if (userPolygonSource) {
                    userPolygonSource.setData(userPolygonData);

                    // if (userPolygonData.features[0].geometry.coordinates[0].length > 0) {
                    //     map.fitBounds(coordinatesArray, {zoom: 23});
                    // }
                }

                // const userPointSource = map.getSource(`userPoint_${idZone}`);
                // if (userPointSource) {
                //     userPointSource.setData(userPointData);
                // }
            }
        }


    }

    updateNameArea(name) {
        this.editAreaData.name_area = name;
    }

    updateColorArea(color) {
        this.editAreaData.color_area = color;

        const map = MapData_DeliveryArea.mapData;
        if (map.getLayer(`userPolygon`)) {
            map.setPaintProperty(`userPolygon`, 'fill-color', color);
        }
    }

    deleteSelectPoints() {
        // Снимаем отметку "выбрано" со всех точек
        this.editPageState.selectAllAreaPoint = false;
        // Фильтруем координаты новой зоны, оставляя только невыбранные точки
        this.editAreaData.coordinates = this.editAreaData.coordinates.filter(obj => !obj.checked);

        this.addPointToTheMap();
    }

    setAllCheckedPointArea(checkedAll) {

        this.editPageState.selectAllAreaPoint = checkedAll;

        this.editAreaData.coordinates = this.editAreaData.coordinates.map(obj => {
            obj.checked = checkedAll;
            return obj;
        });
    }

    setCheckedPointArea(targetCoordinates, newValue) {
        this.editAreaData.coordinates = this.editAreaData.coordinates.slice().map(point => {
            if (point.coordinates[0] === targetCoordinates[0] && point.coordinates[1] === targetCoordinates[1]) {
                point.checked = newValue;
            }
            return point
        })
    }

    deleteThisPoint(targetCoordinates) {
        this.editAreaData.coordinates = this.editAreaData.coordinates.slice().filter(point =>
            !(point.coordinates[0] === targetCoordinates[0] && point.coordinates[1] === targetCoordinates[1])
        )

        this.addPointToTheMap();
    }

    closePopup() {
        this.popupState.popupShow = false;
        this.popupState.popupType = "";
    }

    changePopupShow(position, value, typePopup) {
        this.popupState.popupShow = value;
        this.popupState.popupType = typePopup;
        this.popupState.popupScrollPosition = position;
    }

    addSourceAndLayer(map) {
        /**
         * Добавление источника и слоя для пользовательских полигонов
         */
        if (!map.getSource(`userPolygon`)) {
            map.addSource('userPolygon', {
                type: 'geojson',
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });
        }

        /**
         * Добавление источника и слоя для пользовательских точек
         */
        if (!map.getSource(`userPoint`)) {
            map.addSource('userPoint', {
                type: 'geojson',
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });
        }
    };

    addClickHandler(map) {

        if (!map.getLayer(`userPolygon`)) {
            map.addLayer({
                'id': 'userPolygon',
                'type': 'fill',
                'source': 'userPolygon',
                'layout': {},
                'paint': {
                    'fill-color': `${this.editAreaData.color_area}`,
                    'fill-opacity': 0.5
                }
            });
        }

        /**
         * Загрузка пользовательского изображения для точек
         */
        if (!map.getLayer(`userPoint`)) {
            map.loadImage(
                'https://maps.vk.com/api/styles/pins/blue_target.png',
                function (error, image) {
                    if (error) throw error;
                    map.addImage('custom_pin', image);
                    map.addLayer({
                        'id': 'userPoint',
                        'type': 'symbol',
                        'source': 'userPoint',
                        "layout": {
                            "icon-image": 'custom_pin',
                            "icon-size": 0.2,
                            'text-field': "{id}", // Отображаем номера точек
                            'text-anchor': 'top',
                            'text-offset': [0, 1],
                            'text-size': 15
                        },
                        'paint': {
                            'text-color': '#000000'
                        }
                    });
                }
            );
        }
    };

    addPointToTheMap() {

        const map = MapData_DeliveryArea.mapData;

        const coordinatesArray = this.editAreaData.coordinates.map(obj => obj.coordinates);

        if (map && coordinatesArray.length > 0) {

            if (coordinatesArray.length === 1) {
                this.addSourceAndLayer(map);
                this.addClickHandler(map);
            }

            // Создаем данные для пользовательского полигона
            const userPolygonData = {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [coordinatesArray], // Убираем дополнительный массив
                    }
                }]
            };

            // Создаем данные для пользовательских точек
            const userPointData = {
                type: "FeatureCollection",
                features: coordinatesArray.map((coords, index) => ({
                    type: "Feature",
                    properties: {
                        id: index + 1 // Используем индекс как ID
                    },
                    geometry: {
                        type: "Point",
                        coordinates: coords
                    }
                }))
            };

            // Устанавливаем данные для источника полигона
            const userPolygonSource = map.getSource('userPolygon');
            if (userPolygonSource) {
                userPolygonSource.setData(userPolygonData);


                if (this.editPageState.firstLoad && userPolygonData.features[0].geometry.coordinates[0].length > 0) {

                    if (coordinatesArray.length > 4){
                        map.fitBounds(coordinatesArray, {zoom: 12});
                    } else {
                        const focusCoordsArray = coordinatesArray.slice(0, coordinatesArray.length - 1);
                        map.fitBounds(focusCoordsArray, {zoom: 12});
                    }



                    this.editPageState.firstLoad = false;
                }
            }


            // Устанавливаем данные для источника точек
            const userPointSource = map.getSource('userPoint');
            if (userPointSource) {
                userPointSource.setData(userPointData);
            }
        } else if (map && coordinatesArray.length === 0) {
            // Удаляем слои, если зона отсутствует
            if (map.getLayer("userPolygon")) {
                map.removeLayer("userPolygon");
                map.removeSource("userPolygon");
            }
            if (map.getLayer("userPoint")) {
                map.removeLayer("userPoint");
                map.removeSource("userPoint");
            }
            if (map.hasImage('custom_pin')) {
                map.removeImage('custom_pin');
            }
        }
    }

    addPolygonCords = (coordinates) => {
        if (!coordinates) {
            throw new Error('Invalid coordinate');
        }
        const newCoordinate = {coordinates: [coordinates.lng, coordinates.lat], checked: false};
        this.editAreaData = {
            ...this.editAreaData,
            coordinates: [...this.editAreaData.coordinates, newCoordinate]
        }

        this.addPointToTheMap()
    };
    clickHandler = (e) => {
        this.addPolygonCords(e.lngLat);
    };

    initializationEditPage() {
        const map = MapData_DeliveryArea.mapData;
        if (map) {
            if (!map.isStyleLoaded()) {
                map.once('styledata', () => {
                    this.addSourceAndLayer(map);
                    this.addClickHandler(map);
                })
            } else {
                this.addSourceAndLayer(map);
                this.addClickHandler(map);
            }

            map.on("click", this.clickHandler);
        }
    }


    changeDefaultValue() {
        const map = MapData_DeliveryArea.mapData;
        this.editAreaData = {};
        this.selectCreatedAreaState = {
            selectAllArea: false,
            searchValue: ""
        }
        this.editPageState = {
            selectAllAreaPoint: false,
            firstLoad: true
        }
        this.popupState = {
            popupShow: false,
            popupType: "",
            popupScrollPosition: 0
        }
        this.selectCreatedArea = [];


        if (map) {
            map.off("click", this.clickHandler);
        }

    }

    clearUserPointMap() {
        const map = MapData_DeliveryArea.mapData;
        const mapStyle = map.getStyle();

        if (!mapStyle || !mapStyle.layers) return;
        const layers = mapStyle.layers.map(layer => layer.id);
        layers.forEach(layerId => {

            if (layerId.startsWith("userPolygonText_") || layerId.startsWith("userPolygonBorder_") || layerId.startsWith("userPolygon_") || layerId.startsWith("userPoint_")) {
                map.removeLayer(layerId);
            }
        });
    }

    clearMap() {
        const map = MapData_DeliveryArea.mapData;

        // Проверяем, существует ли слой "userPolygon"
        if (map.getLayer("userPolygon")) {
            map.removeLayer("userPolygon");
            map.removeSource("userPolygon");
        }

        // Проверяем, существует ли слой "userPoint"
        if (map.getLayer("userPoint")) {
            map.removeLayer("userPoint");
            map.removeSource("userPoint");
        }

        if (map.hasImage('custom_pin')) {
            // Если изображение существует, удаляем его
            map.removeImage('custom_pin');
        }
    }
}

export default new EditDeliveryAreaStore();