import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import style from "./DeliveryVehiclesTableList.module.css";
import { ReactComponent as DeleteIcon } from "../../../../imges/DeleteIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import deliveredStatusList from "../../../../store/transportationLogisticsService/deliveredStatusList/deliveredStatusList";
import { ReactComponent as ImgNoneData } from "../../../../imges/noneData.svg";
import GlobalContext from "../../../../context/GlobalContext";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import TablePaginationRouteSheets from "../../TablePaginationRouteSheets/TablePaginationRouteSheets";
import PopupRouting from "../../../../popups/logisticService/ModalMaster/ModalMaster";
import settingOptimizationRouteSheetStore from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import optimizationRouteSheetStore from "../../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import SettingBuildingRouteByVehicle from "../../RoutingByRouteListComponents/SettingBuildingRouteByVehicle/SettingBuildingRouteByVehicle";
import DeliveryVehiclesTableHeaderList from "../DeliveryVehiclesTableHeaderList/DeliveryVehiclesTableHeaderList";
import DeliveryVehiclesTableItemList from "../DeliveryVehiclesTableItemList/DeliveryVehiclesTableItemList";
import PageDeliveryVehiclesStore from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";
import pageDeliveryVehiclesStore from "../../../../store/transportationLogisticsService/pageDeliveryVehiclesStore/pageDeliveryVehiclesStore";

const DeliveryVehiclesTableList = observer(() => {
  const { authTokens, setUser, setAuthTokens, logoutUser } =
    useContext(GlobalContext);
  const { vehicleList, vehicleListCopy } = pageDeliveryVehiclesStore;
  const { page } = pageDeliveryVehiclesStore.paginationState;
  const { popupScrollPosition, popupShow, popupType } =
    pageDeliveryVehiclesStore.popupState;
  const navigate = useNavigate();

  const [sortOrder, setSortOrder] = useState({
    appellation: "asc",
    license_plate: "asc",
    vehicle_brand: "asc",
    tonnage: "asc",
  });

  const sortTemplates = (field) => {
    // Определяем новый порядок сортировки (asc, desc или none)
    const newOrder =
      sortOrder[field] === "asc"
        ? "desc"
        : sortOrder[field] === "desc"
        ? "none"
        : "asc";

    // Обновляем состояние сортировки с новым порядком для данного поля
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [field]: newOrder,
    }));

    // Проверяем, требуется ли сортировка
    if (newOrder === "none") {
      // Если сортировка отсутствует, возвращаем список к исходному состоянию (без сортировки)
      pageDeliveryVehiclesStore.vehicleListCopy =
        pageDeliveryVehiclesStore.vehicleListCopy.slice();
    } else {
      // Если сортировка задана, выполняем сортировку
      pageDeliveryVehiclesStore.vehicleListCopy =
        pageDeliveryVehiclesStore.vehicleListCopy.slice().sort((a, b) => {
          if (field === "appellation") {
            if (a.appellation < b.appellation)
              return newOrder === "asc" ? -1 : 1;
            if (a.appellation > b.appellation)
              return newOrder === "asc" ? 1 : -1;
            return 0;
          } else if (field === "license_plate") {
            if (a.license_plate < b.license_plate)
              return newOrder === "asc" ? -1 : 1;
            if (a.license_plate > b.license_plate)
              return newOrder === "asc" ? 1 : -1;
            return 0;
          } else if (field === "vehicle_brand") {
            if (a.vehicle_brand < b.vehicle_brand)
              return newOrder === "asc" ? -1 : 1;
            if (a.vehicle_brand > b.vehicle_brand)
              return newOrder === "asc" ? 1 : -1;
            return 0;
          } else if (field === "tonnage") {
            if (a.tonnage < b.tonnage) return newOrder === "asc" ? -1 : 1;
            if (a.tonnage > b.tonnage) return newOrder === "asc" ? 1 : -1;
            return 0;
          }
          return 0;
        });
    }
  };

  const handleChangePage = (newPage) => {
    pageDeliveryVehiclesStore.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    pageDeliveryVehiclesStore.handleChangeRowsPerPage(rowsPerPage);
  };

  const deleteSelectVehicles = async () => {
    await pageDeliveryVehiclesStore.deleteSelectVehicles(
      authTokens,
      setUser,
      setAuthTokens,
      logoutUser,
      navigate
    );
  };

  return (
    <div className={style.templateList}>
      <div className={style.templateListHeader}>Транспортные средства</div>

      <div className={style.pagination}>
        <TablePaginationRouteSheets
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={vehicleList.length}
          paginationText={"Карточек транспорта на странице:"}
        />
      </div>

      <DeliveryVehiclesTableHeaderList
        sortTemplates={sortTemplates}
        sortOrder={sortOrder}
      />
      {vehicleList.length > 0 ? (
        vehicleListCopy.map((temp) => {
          return (
            <DeliveryVehiclesTableItemList key={uuidv4()} vehData={temp} />
          );
        })
      ) : (
        <div className={style.noDataImg}>
          <ImgNoneData />
          <div>Нет созданных шаблонов</div>
        </div>
      )}

      <PopupRouting
        isOpen={popupShow && popupType === "askDeleteSelectedVehicles"}
        onClose={() => pageDeliveryVehiclesStore.closePopup()}
        scrollPosition={popupScrollPosition}
      >
        <div className={style.popupHeader}>
          Уточнение
          <div
            className={style.btnClosePopup}
            onClick={() => {
              pageDeliveryVehiclesStore.closePopup();
            }}
          >
            <DeleteIcon />
          </div>
        </div>
        <div className={style.askText}>
          Вы точно хотите удалить выделенные карточки транспорта, действие будет
          невозможно отменить?
        </div>
        <div className={style.actionAskBtns}>
          <button
            className={style.acceptAskBtn}
            onClick={() => deleteSelectVehicles()}
          >
            Подтвердить
          </button>
          <button
            className={style.cancelAskBtn}
            onClick={() => pageDeliveryVehiclesStore.closePopup()}
          >
            Отменить
          </button>
        </div>
      </PopupRouting>
    </div>
  );
});

export default memo(DeliveryVehiclesTableList);
