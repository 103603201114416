import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";
import ModalSelectedInitialPoint from "../ModalSelectedInitialPoint/ModalSelectedInitialPoint";
import ModalSelectedDriver from "../ModalSelectedDriver/ModalSelectedDriver";
import ModalSelectedVehicle from "../ModalSelectedVehicle/ModalSelectedVehicle";
import './сreateRouteSheetsOrderListModalSelectedData.css'
import ModalCreateRouteSheet from "../ModalCreateRouteSheet/ModalCreateRouteSheet";
import routeParameterModalHOC from "../hoc/RouteParameterModalHOC";

const ModalSelectedInitialPointHOC = routeParameterModalHOC(
    ModalSelectedInitialPoint,
    "changeInitPoint",
    "create"
);

const ModalSelectedDriverHOC = routeParameterModalHOC(
    ModalSelectedDriver,
    "changeDriver",
    "create"
);

const ModalSelectedVehicleHOC = routeParameterModalHOC(
    ModalSelectedVehicle,
    "changeVehicle",
    "create"
)

const CreateRouteSheetsOrderListModalSelectedData = observer(() => {
    const {changeInitPoint, changeDriver, changeVehicle} = createRouteSheetsStore.creatingRouteSheetsModalState
    const [modalViewContent, setModalViewContent] = useState(null);

    useMemo(() => {
        setModalViewContent(<ModalCreateRouteSheet/>)

        return () => {
            createRouteSheetsStore.changeDefaultValueCreatingRouteSheets();
        }
    }, [])

    useEffect(() => {
        if (changeInitPoint) {
            setModalViewContent(<ModalSelectedInitialPointHOC/>)
        } else if (changeDriver) {
            setModalViewContent(<ModalSelectedDriverHOC/>)
        } else if (changeVehicle) {
            setModalViewContent(<ModalSelectedVehicleHOC/>)
        } else {
            setModalViewContent(<ModalCreateRouteSheet/>);
        }
    }, [changeVehicle, changeInitPoint, changeDriver]);

    return (
        <>
            {modalViewContent}
        </>
    )

});

export default memo(CreateRouteSheetsOrderListModalSelectedData);