import {makeAutoObservable} from 'mobx'
import mmrgl from 'mmr-gl';
import toast from "react-hot-toast";
import globalState from "../globalState";
import MapData_CreateRouteSheets from "../pageCreateRouteSheetsStore/mapData_CreateRouteSheets";
import workerScript from '../../../workers/logisticService/createRouteSheets.worker'
import createRouteSheetsUtils from '../../../utils/transportationLogisticsService/createRouteSheets.utils'
import { toastMassage } from '../../../components/ToastMassage/ToastMassage';


class CreateRouteSheetsStore {

    sideBlocksContentView = {
        leftBlock: true,
        rightBlock: true
    }

    currentPopupData = null;

    orderList = []; // Список Заказов
    orderListCopy = []; // Список заказов копия
    tableOrderListState = {
        selectAllOrder: false,
        searchValue: ""
    }

    tableOrderListFilters = {
        deliveryArea: {
            deliveryAreaId: null,
            deliveryAreaName: null
        }
    }

    areaList = []; // Зоны доставки
    areaListCopy = []; // Зоны доставки

    routeSheetsList = []; // Список МЛ которые уже созданы (отображаются в правой бок панели)
    routeSheetsListCopy = [];
    tableRouteSheetsState = {
        selectAll: false,
        searchValue: ""
    }

    selectArea = []; // Массив с выбранными зонами
    modalState = { // Состояния модальных окон
        selectAll: false, // Выбраны ли все документы в таблице
        searchValue: ""
    }

    typeCreatingRouteSheet = ""; // Тип создания - По зоне, точке, выделенным точкам

    modalInitPointList = []; // Список начальных точек маршрута
    modalInitPointListCopy = [];
    modalDriverList = []; // Список водителей
    modalDriverListCopy = [];
    modalVehicleList = []; // Список транспорта
    modalVehicleListCopy = [];

    areaSelectOrdersTotalData = {}; // Данные по зоне на которую нажали
    selectOrderData = {}; // Данные по заказу на который нажали
    selectOrderListData = []; // Данные по заказам на который нажали/выделили

    editRouteSheetsData = {} // Объект редактируемого МЛ
    tableEditRouteSheetsState = {
        selectAll: false,
        searchValue: ""
    }

    creatingRouteSheetsModalData = {
        initial_route_point: {name: "", adress: "", coordinate: {lat: "", lon: ""}},
        drivers_info: [{forwarder: false, driver_uid: "", driver_name: ""}, {
            forwarder: true,
            driver_uid: "",
            driver_name: ""
        }],
        vehicle_number: "",
        vehicle_data: {},
        delivery_date: globalState.getISONowDate(),
        delivery_time: "00:00"
    }

    creatingRouteSheetsModalState = {
        changeInitPoint: false,
        changeDriver: false,
        changeVehicle: false,
        changeDeleteRoute: false
    }
    creatingRouteSheetsList = []; // Список маршрутных листов которые находят в процессе создания/сохраненные


    orderListPanelState = {
        uploadDate: globalState.getISONowDate()
    }

    pointDisplayOptions = {
        allOrder: true,
        orderInRoute: false,
        availableOrder: false
    }

    // Состояние модального окна
    popupState = {
        popupShow: false,
        popupType: "",
        popupScrollPosition: 0
    }

    mapListenerList = [];

    transferOrderState = { // Данные для переноса заказа в другой маршрутный лист
        orderData : null,
        toRouteSheetId: null,
        outRouteSheetId: null
    }

    constructor() {
        makeAutoObservable(this)
    }

    setFilterOrderList = (filterOption) => {
        this.tableOrderListFilters.deliveryArea = filterOption;

        if (filterOption.deliveryAreaId === null){
            this.orderList = this.orderListCopy
        } else {
            this.orderList = this.orderListCopy.slice().filter(order => order?.deliveryArea === filterOption.deliveryAreaId);
        }



        this.closePopup()
    }

    setTransferOrderData(data, nameValue){

        if  (nameValue === "orderData") {
            const outRouteSheet = this.routeSheetsList.find(route => route?.order_list.some(order => order?.id === data?.id))
            this.transferOrderState.outRouteSheetId = outRouteSheet.id;
        }

        this.transferOrderState[nameValue] = data;
    }

    toggleSideBlocksVisibility(blockName, value){
        this.sideBlocksContentView[blockName] = value;
    }

    // Асинхронно изменяет опции отображения точек.
    async changePointDisplayOptions(newOptions) {
        if (JSON.stringify(this.pointDisplayOptions) !== JSON.stringify(newOptions)) {
            this.pointDisplayOptions = newOptions;

            if (this.orderList.length > 0) {
                await this.updateOrderListByPointOption();
                await this.showOrderToMap();
            }
            if (this.selectArea.length > 0) {
                await this.deliveryAreaAssignment();
            }
        }
        this.closePopup();
    }

    /**
     * Изменяет дату доставки в зависимости от типа действия
     * @param {Date} date - дата доставки
     * @param {string} typeAction - тип действия (create или edit)
     */
    changeDeliveryDate(date, typeAction) {
        const currentDate = new Date();
        const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

        // Увеличиваем дату на один день
        const destinationDateT = new Date(date);
        destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);

        switch (typeAction) {
            case "create":
                // Преобразуем дату в формат ISO и обрезаем до минут
                this.creatingRouteSheetsModalData.delivery_date = destinationDateT.toISOString().slice(0, 16);
                break;
            case "edit":
                this.editRouteSheetsData.delivery_date = destinationDateT.toISOString().slice(0, 16);
                break;
            default:
                break;
        }
    }

    /**
     * Изменяет время доставки в зависимости от типа действия
     * @param {string} time - время доставки
     * @param {string} typeAction - тип действия (create или edit)
     */
    changeDeliveryTime(time, typeAction) {
        switch (typeAction) {
            case "create":
                this.creatingRouteSheetsModalData.delivery_time = time;
                break;
            case "edit":
                this.editRouteSheetsData.delivery_time = time;
                break;
            default:
                break;
        }
    }

    /**
     * Удаляет выбранные заказы из маршрута
     */
    deleteSelectedOrdersInRoute() {
        this.editRouteSheetsData.order_list = this.editRouteSheetsData.order_list.slice().filter(order => order.checked !== true);
        this.tableEditRouteSheetsState.selectAll = false;
    }

    /**
     * Изменяет выбор в таблице в зависимости от типа селектора
     * @param {boolean} value - значение выбора
     * @param {string} dataId - идентификатор данных
     * @param {string} selectorType - тип селектора (orderInRoute, routeSheetList, orderList)
     */
    changeSelectInTable(value, dataId, selectorType) {
        switch (selectorType) {
            case "orderInRoute":
                this.editRouteSheetsData.order_list = createRouteSheetsUtils.updateOrderList(this.editRouteSheetsData.order_list, dataId, value);
                this.tableEditRouteSheetsState.selectAll = false;
                break;
            case "routeSheetList":
                this.routeSheetsList = createRouteSheetsUtils.updateRouteList(this.routeSheetsList, dataId, value);
                break;
            case "orderList":
                this.orderList = createRouteSheetsUtils.updateOrderWithMapData(this.orderList.slice(), dataId, value, this.selectOrderListData, MapData_CreateRouteSheets.mapData);
                break;
            default:
                console.warn(`Нет такого типа селектора: ${selectorType}`);
                break;
        }
    }

    changeSelectInRouteSheetsList(value, docId){
        this.routeSheetsList = createRouteSheetsUtils.updateRouteList(this.routeSheetsList, docId, value);
    }

    changeSelectedAllInTable = async (value, selectorType) => {


        const handleOrderListMapState = async (orderId, deliveryAreaId, value, in_route_sheets, selectedOrderList) => {
            const map = MapData_CreateRouteSheets.mapData;
            const featureUID = String(orderId).match(/\d+/g).join('').slice(0, 15);
            const orderIndex = selectedOrderList.findIndex(sOrder => sOrder.id === orderId);

            if (deliveryAreaId && value === false && !in_route_sheets) {
                const areaData = this.selectArea.find(area => {
                    return area.id === deliveryAreaId
                });
                map.setFeatureState(
                    {source: "points", id: featureUID},
                    {colorArea: `${areaData.color_area}`, color: true}
                );
            } else {
                if (orderIndex === -1) {
                    map.setFeatureState({source: "points", id: featureUID}, {
                        colorArea: `#FF0000`,
                        color: false
                    });
                } else {
                    map.setFeatureState({source: "points", id: featureUID}, {
                        colorArea: `#006fcbe6`,
                        color: true
                    });
                }
            }


        };

        switch (selectorType) {
            case "orderInRoute":
                this.tableEditRouteSheetsState.selectAll = value;

                this.editRouteSheetsData.order_list = this.editRouteSheetsData.order_list.map(order => {
                    order.checked = value
                    return order
                })
                break;
            case "routeSheetList":
                this.tableRouteSheetsState.selectAll = value;
                this.routeSheetsList = this.routeSheetsList.map(route => {
                    route.checked = value;
                    return route
                });
                break;
            case "orderList":

                this.tableOrderListState.selectAllOrder = value;

                const worker = new Worker(workerScript);

                const orderListCopy = JSON.parse(JSON.stringify(this.orderList));
                worker.postMessage({orderList: orderListCopy, value});

                worker.onmessage = async (event) => {
                    const {orderList, selectedOrderList} = event.data;

                    await Promise.all(orderList.map(order => handleOrderListMapState(order.id, order?.deliveryArea, value, order.in_route_sheets, selectedOrderList.slice())));

                    this.selectOrderListData = selectedOrderList;
                    this.orderList = orderList;
                    worker.terminate();
                };

                worker.onerror = (error) => {
                    console.error('Worker error:', error);
                    worker.terminate();
                };
                break;
            default:
                break;
        }
    }

    deleteSelectedOrderInRoute(orderId) {
        this.editRouteSheetsData.order_list = this.editRouteSheetsData.order_list.slice().filter(order => order.id !== orderId)
    }

    clearSelectOrderListData() {

        if (this.selectOrderListData.length === 0) return
        const map = MapData_CreateRouteSheets.mapData;

        if (map) {
            for (const order of this.selectOrderListData) {
                const featureUID = String(order.id).match(/\d+/g).join('').slice(0, 15);

                if (order?.deliveryArea) {

                    const areaData = this.selectArea.find(area => {
                        return area.id === order?.deliveryArea
                    });
                    map.setFeatureState(
                        {source: "points", id: featureUID},
                        {colorArea: `${areaData.color_area}`, color: true}
                    );
                } else {
                    map.setFeatureState(
                        {source: "points", id: featureUID},
                        {colorArea: `#FF0000`, color: false}
                    );
                }


            }

            this.selectOrderListData = []
            this.changeSelectedAllInTable(false, "orderList")
        }
    }

    changeEditRouteSheetsData(dataValue) {
        this.editRouteSheetsData = JSON.parse(JSON.stringify(dataValue));
        this.editRouteSheetsData.delivery_time = this.editRouteSheetsData.delivery_date.split('T')[1]
    }

    changeSelectOrderData(orderData) {
        this.selectOrderData = JSON.parse(JSON.stringify(orderData))
    }

    changeTypeCreatingRouteSheet(newValue) {
        this.typeCreatingRouteSheet = newValue
    }

    changeRouteSheetDataModal(value, nameChangeData) {
        this.creatingRouteSheetsModalState[nameChangeData] = value
    }

    changeUploadDate(date) {
        if (date === null) {
            this.orderListPanelState.uploadDate = globalState.getISONowDate();
        } else {
            const currentDate = new Date();
            const timezoneOffset = -currentDate.getTimezoneOffset() / 60;

            // Увеличиваем дату на один день
            const destinationDateT = new Date(date);
            destinationDateT.setHours(destinationDateT.getHours() + timezoneOffset);

            // Преобразуем дату в формат ISO и обрезаем до минут
            this.orderListPanelState.uploadDate = destinationDateT.toISOString().slice(0, 16);
        }
    }

    closeCurrentPopup() {
        if (this.currentPopupData) {
            this.currentPopupData.remove();
            this.currentPopupData = null;
        }
    }

    closePopup() {
        this.popupState.popupShow = false;
        this.popupState.popupType = "";
    }

    changePopupShow(position, value, typePopup) {
        this.popupState.popupShow = value;
        this.popupState.popupType = typePopup;
        this.popupState.popupScrollPosition = position;
    }

    changeDefaultPopupValue() {
        this.popupState = {
            popupShow: false,
            popupType: "",
            popupScrollPosition: 0
        }
    }

    changeCheckedValue_Modal(areaList) {
        if (this.selectArea.length > 0) {
            let checkedArray = JSON.parse(JSON.stringify(this.selectArea));

            return areaList.map(area => {
                for (const checkedArea of checkedArray) {
                    if (checkedArea.id === area.id) {
                        area.checked = true;

                        checkedArray = this.selectArea.slice().filter(SelectArea => SelectArea.id !== area.id);
                        break;
                    }
                }
                return area;
            })
        } else {
            return areaList
        }
    }

    changeDefaultValueModalSelectArea() {
        this.areaList = [];
        this.areaListCopy = [];
        this.modalState.searchValue = "";
    }

    changeDefaultEditRouteData() {
        this.editRouteSheetsData = {};
        this.tableEditRouteSheetsState = {
            selectAll: false,
            searchValue: ""
        };
        this.popupState = {
            popupShow: false,
            popupType: "",
            popupScrollPosition: 0
        };
        this.creatingRouteSheetsModalState = {
            changeInitPoint: false,
            changeDriver: false,
            changeVehicle: false,
        }
    }

    changeDefaultModalData(arrayName) {
        this[arrayName] = []
    }

    changeSearchText(newText, filterName) {
        const {filterPropertyList} = createRouteSheetsUtils;
        const filter = filterPropertyList[filterName];
        if (!filter) {
            console.error(`Фильтр ${filterName} не найден`);
            return;
        }

        this[filter.state].searchValue = newText;
        const regex = new RegExp(`^.*${newText.split(' ').join('.*')}.*$`, 'i');

        this[filter.list] = this[filter.copy].slice().filter(item =>
            filter.fields.some(field => {
                let value = createRouteSheetsUtils.getValueByPath(item, field);
                return regex.test(value?.toString() || '');
            })
        );
    }

    selectCreatedDeliveryArea(newValue, areaId) {
        this.areaList = this.areaList.slice().map(area => {
            if (area.id === areaId) {
                area.checked = newValue;

                if (newValue) {
                    this.selectArea = [...this.selectArea, area];
                } else {
                    this.selectArea = this.selectArea.slice().filter(selectedArea =>
                        selectedArea.id !== areaId
                    )
                }
            }
            return area
        })
    }

    selectAllCreatedArea(newValue) {
        this.modalState.selectAll = newValue;

        this.areaList = this.areaList.slice().map(area => {
            area.checked = newValue;
            return area
        })

        if (newValue) {
            this.selectArea = [...this.areaList.slice()];
        } else {
            this.selectArea = [];
        }
    }

    async showCreatedDeliveryArea() {
        const map = MapData_CreateRouteSheets.mapData;
        MapData_CreateRouteSheets.clearAreaMap();

        if (this.selectArea.length === 0) {
            this.changeDefaultAssignmentArea();
            return;
        }

        const geojson = createRouteSheetsUtils.createDeliveryAreaGeoJSON(this.selectArea);
        // const bounds = createRouteSheetsUtils.extendBounds(geojson);

        if (!map.getSource('userPolygon')) {
            map.addSource('userPolygon', {
                type: 'geojson',
                data: geojson,
            });
        }

        createRouteSheetsUtils.addDeliveryAreaMapLayers(map);
        createRouteSheetsUtils.setupDeliveryAreaClickHandler(map, this);
        createRouteSheetsUtils.setupDeliveryOrderCursorEvents(map);

        map.moveLayer('points');
        map.moveLayer('points-text');

        this.deliveryAreaAssignment();
    }

    async initializationDeliveryAreaData(areaId) {
        if (this.orderList.length === 0) {
            return null;
        }

        let resData = {
            totalVehicleTonnage: 0,
            totalVolumeGoods: 0,
            totalOrders: 0,
            totalOrdersInRoute: 0,
            totalAvailableRoute: 0,
            areaId: areaId
        }

        const ordersInArea = this.orderList.slice().filter((order) => order?.deliveryArea === areaId);

        for (const order of ordersInArea) {

            if (order?.in_route_sheets) {
                resData.totalOrdersInRoute += 1;
            } else {
                resData.totalAvailableRoute += 1;
            }

            resData.totalVehicleTonnage += order?.vehicle_tonnage;
            resData.totalVolumeGoods += order?.volume_goods;
            resData.totalOrders += 1;
        }

        this.areaSelectOrdersTotalData = resData;
    }

    async showOrderToMap() {

        if (this.orderList.length === 0) {
            return;
        }

        MapData_CreateRouteSheets.clearOrderPoint();
        const map = MapData_CreateRouteSheets.mapData;

        if (map) {

            const bounds = new mmrgl.LngLatBounds();
            const geojson = createRouteSheetsUtils.createOrderGeoJSON(this.orderList, bounds);

            if (!map.getSource('points')) {
                map.addSource('points', {
                    type: 'geojson',
                    data: geojson,
                    cluster: true,
                    clusterMaxZoom: 5,
                    clusterRadius: 50,
                });
            }

            createRouteSheetsUtils.addOrderMapLayers(map);
            if (this.mapListenerList.length === 0) {
                createRouteSheetsUtils.setupOrderClickHandler(map, this);
            }
            createRouteSheetsUtils.setupOrderCursorEvents(map);

            map.moveLayer('points');
            map.moveLayer('points-text');
        }
    }

    initializationSelectionBox() {

        const map = MapData_CreateRouteSheets.mapData;

        // if (!map || !map.isStyleLoaded()) { return }
        const canvas = map.getCanvasContainer();

        let start;
        let current;
        let box;

        canvas.addEventListener('mousedown', mouseDown, true);

        function mousePos(e) {
            const rect = canvas.getBoundingClientRect();
            return new mmrgl.Point(
                e.clientX - rect.left - canvas.clientLeft,
                e.clientY - rect.top - canvas.clientTop
            );
        }

        function mouseDown(e) {
            // Continue the rest of the function if the shiftkey is pressed.
            if (!(e.shiftKey && e.button === 0)) return;
            // Disable default drag zooming when the shift key is held down.
            map.dragPan.disable();

            // Call functions for the following events
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
            document.addEventListener('keydown', onKeyDown);

            // Capture the first xy coordinates
            start = mousePos(e);
        }

        function onMouseMove(e) {
            // Capture the ongoing xy coordinates
            current = mousePos(e);

            // Append the box element if it doesnt exist
            if (!box) {
                box = document.createElement('div');
                box.classList.add('boxdraw');
                canvas.appendChild(box);
            }

            const minX = Math.min(start.x, current.x),
                maxX = Math.max(start.x, current.x),
                minY = Math.min(start.y, current.y),
                maxY = Math.max(start.y, current.y);

            // Adjust width and xy position of the box element ongoing
            const pos = `translate(${minX}px, ${minY}px)`;
            box.style.transform = pos;
            box.style.width = maxX - minX + 'px';
            box.style.height = maxY - minY + 'px';
        }

        function onMouseUp(e) {
            // Capture xy coordinates
            finish([start, mousePos(e)]);
        }

        function onKeyDown(e) {
            // If the ESC key is pressed
            if (e.keyCode === 27) finish();
        }

        const finish = (bbox) => {
            // Remove these events now that finish has been called.
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('mouseup', onMouseUp);

            if (box) {
                box.parentNode.removeChild(box);
                box = null;
            }

            // If bbox exists. use this value as the argument for `queryRenderedFeatures`
            if (bbox) {
                const features = map.queryRenderedFeatures(bbox, {
                    layers: ['points']
                });

                if (features.length >= 48) {
                    toastMassage(toast.error, "За один раз можно выделить максимум 48 заказов!", 2500, "bottom-right")
                    map.dragPan.enable();
                    return
                }

                // Run through the selected features and set a filter
                // to match features with unique FIPS codes to activate
                // the `counties-highlighted` layer.

                const fips = features.map((feature) => feature.properties.id);
                this.addSelectOrdersSelectBoxData(fips)
            }

            map.dragPan.enable();
        }
    }

    addSelectOrdersSelectBoxData(orderIdList) {
        const map = MapData_CreateRouteSheets.mapData;
        const orderMap = new Map(this.orderList.map(order => [order.id, order]));
        let totalNumberOrdersAdded = 0;

        orderIdList.forEach((orderId) => {
            const index = this.selectOrderListData.findIndex(order => order.id === orderId);
            if (index > -1){

            } else {
                totalNumberOrdersAdded++;
            }
        })

        if ((totalNumberOrdersAdded + this.selectOrderListData.length) > 48){
            toastMassage(toast.error, "За один раз можно выделить максимум 48 заказов!", 2500, "bottom-right")
            return
        }


        for (const orderId of orderIdList) {
            const orderData = orderMap.get(orderId);

            if (!orderData || orderData.in_route_sheets) {
                continue;
            }

            const featureUID = String(orderId).match(/\d+/g).join('').slice(0, 15);

            const index = this.selectOrderListData.findIndex(order => order.id === orderId);
            if (index > -1) {
                // Если заказ уже в списке selectOrderListData
                if (orderData.deliveryArea) {
                    const areaData = this.selectArea.find(area => area.id === orderData.deliveryArea);
                    if (areaData) {
                        map.setFeatureState({source: "points", id: featureUID}, {
                            colorArea: areaData.color_area,
                            color: true
                        });
                    }
                } else {
                    map.setFeatureState({source: "points", id: featureUID}, {colorArea: `#FF0000`, color: false});
                }
                this.selectOrderListData.splice(index, 1);
                this.orderList = this.orderList.slice().map(order => {
                    if (order.id === orderId) {
                        order.checked = false;
                    }
                    return order
                })
            } else {
                this.orderList = this.orderList.slice().map(order => {
                    if (order.id === orderId) {
                        order.checked = true;
                    }
                    return order
                })
                this.selectOrderListData.push(orderData);
                map.setFeatureState({source: "points", id: featureUID}, {colorArea: `#006fcbe6`, color: true});
            }
        }
    }

    addSelectOrderData(orderId) {
        const map = MapData_CreateRouteSheets.mapData;
        const orderData = this.orderList.find(order => order.id === orderId);
        const featureUID = String(orderId).match(/\d+/g).join('').slice(0, 15);

        if (orderData && map) {

            const index = this.selectOrderListData.findIndex(order =>
                order.id === orderId
            );

            if (index > -1) {
                this.selectOrderListData.splice(index, 1);

                if (orderData?.deliveryArea) {
                    const areaData = this.selectArea.find(area => area.id === orderData.deliveryArea);
                    if (areaData) {
                        map.setFeatureState({source: "points", id: featureUID}, {
                            colorArea: areaData.color_area,
                            color: true
                        });
                    }
                } else {
                    map.setFeatureState({source: "points", id: featureUID}, {colorArea: `#FF0000`, color: false});
                }
            } else {
                this.selectOrderListData.push(orderData);
                map.setFeatureState(
                    {source: "points", id: featureUID},
                    {colorArea: `#006fcbe6`, color: true}
                );
            }

        }
    }

    changeDefaultAssignmentArea() {
        const map = MapData_CreateRouteSheets.mapData;

        this.orderList = this.orderList.slice().map((order) => {
            order.deliveryArea = null;
            const featureUID = String(order.id).match(/\d+/g).join('').slice(0, 15);
            map.setFeatureState(
                {source: "points", id: featureUID},
                {colorArea: `#FF0000`, color: false}
            );
            return order
        })
    }

    zoomToPointOrder(coordsArray) {
        const map = MapData_CreateRouteSheets.mapData;

        if (map) {
            const coordsFocusToMarker = [coordsArray, coordsArray]

            map.fitBounds(coordsFocusToMarker, {zoom: 20, duration: 2000});

        }

    }

    changeInitPointCreatingRouteSheet(initPointData, typeAction) {
        const {initial_route_point_name, address, lat, lon} = initPointData;

        switch (typeAction) {
            case "create":
                this.creatingRouteSheetsModalData.initial_route_point = {
                    name: initial_route_point_name,
                    adress: address,
                    coordinate: {lat: (lat === null) ? "" : lat, lon: (lon === null) ? "" : lon}
                }
                break
            case  "edit":
                this.editRouteSheetsData.initial_route_point = {
                    name: initial_route_point_name,
                    adress: address,
                    coordinate: {lat: (lat === null) ? "" : lat, lon: (lon === null) ? "" : lon}
                }
                break
            default:
                break
        }

        this.changeRouteSheetDataModal(false, "changeInitPoint")
    }

    changeVehicleCreatingRouteSheet(vehicleData, typeAction) {
        switch (typeAction) {
            case "create":
                this.creatingRouteSheetsModalData.vehicle_number = vehicleData.license_plate;
                this.creatingRouteSheetsModalData.vehicle_data = vehicleData;
                break;
            case "edit":
                this.editRouteSheetsData.vehicle_number = vehicleData.license_plate;
                this.editRouteSheetsData.vehicle_data = vehicleData;
                break;
            default:
                break
        }
        this.changeRouteSheetDataModal(false, "changeVehicle")
    }

    changeDriverCreatingRouteSheet(driverData, typeAction) {
        const {driver_name, forwarder, driver_id} = driverData;

        switch (typeAction) {
            case "create":
                this.creatingRouteSheetsModalData.drivers_info = [{
                    forwarder: false,
                    driver_uid: driver_id,
                    driver_name: driver_name
                }, {
                    forwarder: true,
                    driver_uid: "",
                    driver_name: ""
                }]
                break;
            case "edit":
                this.editRouteSheetsData.drivers_info = [{
                    forwarder: false,
                    driver_uid: driver_id,
                    driver_name: driver_name
                }, {
                    forwarder: true,
                    driver_uid: "",
                    driver_name: ""
                }]
                break;
            default:
                break
        }


        this.changeRouteSheetDataModal(false, "changeDriver")
    }

    changeDefaultValueCreatingRouteSheets() {
        this.creatingRouteSheetsModalData = {
            initial_route_point: {name: "", adress: "", coordinate: {lat: "", lon: ""}},
            drivers_info: [{forwarder: false, driver_uid: "", driver_name: ""}, {
                forwarder: true,
                driver_uid: "",
                driver_name: ""
            }],
            vehicle_number: "",
            vehicle_data: {},
            delivery_date: globalState.getISONowDate(),
            delivery_time: "00:00"
        };

        this.typeCreatingRouteSheet = "";
    }

    /**
     * Асинхронно создает лист маршрутных листов на основе области доставки
     * @param {string} areaId - идентификатор области
     */
    async createRouteSheetBasedDeliveryArea(areaId) {
        if (this.orderList.length === 0) {
            return;
        }
        const orderListInArea = this.orderList.slice().filter(order => order?.deliveryArea === areaId && !order?.in_route_sheets);
        this.creatingRouteSheetsList = [...this.creatingRouteSheetsList, await createRouteSheetsUtils.createRouteSheet(this.creatingRouteSheetsModalData, orderListInArea)];
    }

    /**
     * Асинхронно создает ведомость маршрутов на основе выбранного заказа.
     */
    async createRouteSheetBasedSelectOrder() {
        this.creatingRouteSheetsList = [...this.creatingRouteSheetsList, await createRouteSheetsUtils.createRouteSheet(this.creatingRouteSheetsModalData, [this.selectOrderData])];
    }

    /**
     * Асинхронно создает ведомость маршрутов на основе списка выбранных заказов.
     */
    async createRouteSheetBasedSelectedOrdersList() {
        this.creatingRouteSheetsList = [...this.creatingRouteSheetsList, await createRouteSheetsUtils.createRouteSheet(this.creatingRouteSheetsModalData, this.selectOrderListData)];
        this.clearSelectOrderListData();
    }

    /**
     * Асинхронно распределяет заказы по зонам доставки
     */
    async deliveryAreaAssignment() {
        if (this.selectArea.length === 0) {
            toastMassage(toast.error, "Выберите зоны доставки перед распределением заказов!", 10000, "bottom-right")
            return
        }

        const map = MapData_CreateRouteSheets.mapData;

        const deliveryAreaAssignmentToast =

        toast.loading("Идет процесс распределения заказов по выбранным зонам!", {
            position: 'bottom-right'
        });

        this.orderList = await Promise.all(this.orderList.map(async (order) => {
            order.deliveryArea = null;

            if (order?.coordinate.lat !== null && order?.coordinate.lon !== null && order?.coordinate.lat !== "" && order?.coordinate.lon !== "null") {
                for (const polygon of this.selectArea) {
                    if (createRouteSheetsUtils.isPointInPolygon(order, polygon)) {
                        order.deliveryArea = polygon.id;

                        const featureUID = String(order.id).match(/\d+/g).join('').slice(0, 15);
                        const colorArea = order.in_route_sheets ? `#006fcbe6` : polygon.color_area;

                        map.setFeatureState({source: "points", id: featureUID}, {
                            colorArea: colorArea,
                            color: true
                        });

                        break;
                    }
                }
            }
            return order;
        }));

        setTimeout(() => {
            toast.dismiss(deliveryAreaAssignmentToast);
        }, 1000);
    }

    async updateOrderListByPointOption() {
        const {allOrder, orderInRoute, availableOrder} = this.pointDisplayOptions;

        if (allOrder) {
            this.orderList = this.orderListCopy.slice().map(order => order);
        } else if (orderInRoute) {
            this.orderList = this.orderListCopy.slice().filter(order => order.in_route_sheets === true)
        } else if (availableOrder) {
            this.orderList = this.orderListCopy.slice().filter(order => order.in_route_sheets === false)
        }
    }

    async getOrderListByDate(axiosInstance) {
        const {allOrder, orderInRoute, availableOrder} = this.pointDisplayOptions;

        await axiosInstance.get(`/getOrdersByDate/uploadDate=${this.orderListPanelState.uploadDate.slice(0, 10)}`)
            .then(res => {
                this.orderList = res.data
                this.orderListCopy = this.orderList

                this.tableOrderListFilters = {
                    deliveryArea: {
                        deliveryAreaId: null,
                        deliveryAreaName: null
                    }
                }

                if (orderInRoute || availableOrder) {
                    this.updateOrderListByPointOption();
                }

                this.showOrderToMap();
                if (this.selectArea.length > 0) {
                    this.deliveryAreaAssignment();
                }
            }).catch(error => {
                toastMassage(toast.error, "Нет заказов за выбранную дату!", 10000, "bottom-right")
                console.error(error)
                MapData_CreateRouteSheets.clearOrderPoint();
                this.orderList = [];
                this.orderListCopy = [];
            })
    }

    async getAllDeliveryArea(axiosInstance) {

        await axiosInstance.get(`/getAllFilledDeliveryArea`)
            .then(res => {
                this.areaList = this.changeCheckedValue_Modal(res.data);
                this.areaListCopy = this.areaList;
            }).catch(error => {
                toastMassage(toast.error, "Ошибка при получении зон доставки", 10000, "bottom-right")
                console.error('Ошибка:', error);
            })

    }

    async getInitPointList(axiosInstance) {

        await axiosInstance.get(`/getListFilledInitialPointRoute`)
            .then(response => {
                this.modalInitPointList = response.data.initPoints;
                this.modalInitPointListCopy = this.modalInitPointList;

            })
            .catch(error => {
                toastMassage(toast.error, "Ошибка при получении начальных точек", 10000, "bottom-right")
                console.error('Ошибка:', error);
            });

    }

    async getDrivers(axiosInstance) {

        await axiosInstance.get(`/getDriversOrForwarders/typeList=drivers`
        ).then(response => {

            this.modalDriverList = response.data
            this.modalDriverListCopy = this.modalDriverList;

        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить карточки водителей!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })

    }

    async getAllVehicle(axiosInstance) {

        await axiosInstance.get(`/getAllDeliveryVehicle`).then(res => {
            this.modalVehicleList = res.data;
            this.modalVehicleListCopy = this.modalVehicleList;
        }).catch(error => {
            toastMassage(toast.error, "Не удалось получить транспорт!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })
    }

    async createRouteSheet(axiosInstance) {

        if (this.creatingRouteSheetsList.length === 0) {
            return
        }

        const data = {
            routeSheetList: this.creatingRouteSheetsList
        }

        await axiosInstance.post(`/createTransportOrder`, data).then(res => {
            toastMassage(toast.success, "Маршрутный лист успешно создан!", 2500, "bottom-right")
            this.creatingRouteSheetsList = [];
            this.getOrderListByDate(axiosInstance);
            this.getTransportOrderCreatedInService(axiosInstance);
            this.closeCurrentPopup();
        }).catch(error => {
            toastMassage(toast.error, "Не удалось создать маршрутный лист!", 10000, "bottom-right")
            console.error('Ошибка:', error);
        })

    }

    async getTransportOrderCreatedInService(axiosInstance) {

        await axiosInstance.get(`/getTransportOrderCreatedInService/uploadDate=${this.orderListPanelState.uploadDate.slice(0, 10)}`)
            .then(res => {
                this.routeSheetsList = res.data?.routeSheets;
                this.routeSheetsListCopy = this.routeSheetsList;
            }).catch(error => {
                this.routeSheetsList = []
                console.error(error);
            })
    }

    async addSelectedOrderToTheRouteSheet(axiosInstance, routeId) {
        if (this.routeSheetsList.length === 0 && this.selectOrderListData.length === 0) {
            return
        }
        const routeData = this.routeSheetsList.find((route) => route?.id === routeId);

        let orderDataList = []

        switch (this.typeCreatingRouteSheet) {
            case "selectedOrderList":
                orderDataList = this.selectOrderListData;
                break
            case "selectPoint":
                orderDataList = [this.selectOrderData];
                break
            case "deliveryArea":
                orderDataList = this.orderList.slice().filter(order => order?.deliveryArea === this.areaSelectOrdersTotalData.areaId);
                break;
            default:
                return
        }

        if (routeData?.order_list.length + orderDataList.length > 48){
            toastMassage(toast.error, "В заказе может быть максимум 48 заказов!", 2500, "bottom-right");
            return
        }


        const data = {
            selectedRouteId: routeId,
            selectedOrderList: orderDataList
        }

        await axiosInstance.post(`/addOrdersToTheTransportOrder`, data)
            .then(res => {
                toastMassage(toast.success, "Выбранные заказы  добавлены в маршрутный лист!", 2500, "bottom-right")
                this.getOrderListByDate(axiosInstance);
                this.getTransportOrderCreatedInService(axiosInstance);
                this.selectOrderListData = [];

                this.closeCurrentPopup();
            })
            .catch(error => {
                toastMassage(toast.error, "Не удалось добавить заказ в маршрутный лист!", 10000, "bottom-right")
                console.error('Ошибка:', error);
            })
    }

    async deleteOrderInRouteSheet(axiosInstance, orderId){

        const routeData = this.routeSheetsList.find(route => route?.order_list.some(order => order?.id === orderId));

        const data = {
            routeSheetId: routeData.id,
            orderId: orderId
        }

        await axiosInstance.post(`/deleteOrderInRouteSheet`, data)
            .then(res => {
                toastMassage(toast.success, "Заказ успешно удален из маршрутного листа", 2500, "bottom-right")
                this.getOrderListByDate(axiosInstance);
                this.getTransportOrderCreatedInService(axiosInstance);

                this.closePopup();
                this.closeCurrentPopup();
            })
            .catch(error => {
                console.error(error);
                toastMassage(toast.error, "Не удалось удалить заказ из маршрутного листа!", 10000, "bottom-right")
            })


        this.idDeletedOrderInRouteSheet = null;

    }

    async updateEditRouteSheet(axiosInstance) {

        const {delivery_date, delivery_time} = this.editRouteSheetsData

        const dateOnly = delivery_date.split('T')[0];

        const deliveryDate = `${dateOnly}T${delivery_time}`

        const data = {
            oldRouteSheetData: this.routeSheetsList.slice().filter(order => order.id === this.editRouteSheetsData.id)[0],
            newRouteSheetData: {
                ...this.editRouteSheetsData,
                delivery_date: deliveryDate
            }
        }

        await axiosInstance.post(`/updateTransportOrderCreatedInService`, data)
            .then(res => {
                toastMassage(toast.success, "Маршрутный лист успешно обновлен!", 2500, "bottom-right")
                this.getOrderListByDate(axiosInstance);
                this.getTransportOrderCreatedInService(axiosInstance);

                this.closePopup();
            })
            .catch(error => {
                console.error(error);
                toastMassage(toast.error, "Ну удалось обновить данные маршрутного листа!", 10000, "bottom-right")
            })

    }

    async deleteRouteSheetById(axiosInstance, routeId = undefined) {

        const data = {
            routeSheetId: (routeId) ? [routeId] : this.routeSheetsList.filter(route => route?.checked).map(route => route.id)
        }

        await axiosInstance.post(`/deleteTransportOrderById`, data)
            .then(res => {
                toastMassage(toast.success, "Маршрутный лист успешно удален!", 2500, "bottom-right")
                this.getOrderListByDate(axiosInstance);
                this.getTransportOrderCreatedInService(axiosInstance);

                this.closePopup();
            })
            .catch(error => {
                console.error(error);
                toastMassage(toast.error, "Не удалось удалить маршрутный лист!", 10000, "bottom-right")
            })

    }

    async transferOrderAnotherRoute(axiosInstance, data) {


        await axiosInstance.post('/transferOrderAnotherRoute', data)
            .then(res => {
                toastMassage(toast.success, "Заказ успешно перенесен!", 2500, "bottom-right")
                this.getOrderListByDate(axiosInstance);
                this.getTransportOrderCreatedInService(axiosInstance);

                this.closePopup();
                this.closeCurrentPopup();
            })
            .catch(error => {
                console.error(error);
                toastMassage(toast.error, "Не удалось перенести заказ!", 10000, "bottom-right")
            })

    }

    clearStore(){
        this.orderList = []; // Список Заказов
        this.orderListCopy = []; // Список заказов копия
        this.tableOrderListState = {
            selectAllOrder: false,
            searchValue: ""
        }

        this.areaList = []; // Зоны доставки
        this.areaListCopy = []; // Зоны доставки

        this.routeSheetsList = []; // Список МЛ которые уже созданы (отображаются в правой бок панели)
        this.routeSheetsListCopy = [];
        this.tableRouteSheetsState = {
            selectAll: false,
            searchValue: ""
        }

        this.selectArea = []; // Массив с выбранными зонами

        this.mapListenerList = [];
    }
}

export default new CreateRouteSheetsStore();