import React from 'react';
import style from './InitialPointPopupList.module.css';
import {ReactComponent as DeleteIcon} from '../../../imges/DeleteIcon.svg';
import InitialPointPopupItem from "../InitialPointPopupItem/InitialPointPopupItem";
import {v4 as uuidv4} from "uuid";
import {observer} from "mobx-react-lite";
import optimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/optimizationRouteSheetStore";
import settingOptimizationRouteSheetStore
    from "../../../store/transportationLogisticsService/optimizationPageRouteSheet/settingOptimizationRouteSheetStore";
import {AutoSizer, List} from "react-virtualized";

const InitialPointPopupList = observer(({setNewData}) => {
    const {pageState} = optimizationRouteSheetStore;
    const {popupInitialPointList} = settingOptimizationRouteSheetStore;

    const rowRenderer = ({index, key, style}) => {
        return (
            <div key={key} style={style}>
                <InitialPointPopupItem
                    setNewData={(addressData) => setNewData(addressData)}
                    addressData={popupInitialPointList[index]}
                    key={uuidv4()}
                    uid={uuidv4()}
                    indexElem={index}
                />
            </div>
        )
            ;
    };

    return (
        <div className={style.selectInitialPointContent}>
            <div className={style.popupHeader}>
                {(pageState.popup_type === "selectInitialPoint_General") ? "Выберите начальную точку для маршрутов" : "Выберите начальную точку для маршрута"}
                <div
                    className={style.btnClosePopup}
                    onClick={() => {
                        optimizationRouteSheetStore.closePopupInitPoints()
                    }}
                ><DeleteIcon/></div>
            </div>
            {(pageState.popup_type === "selectInitialPoint_General") ? <div
                className={style.popupGeneralSettingInfo}
            >*Выбрав начальную точку для маршрутов, она применится ко всем загруженным
                документам.</div> : null}

            <div className={style.popupHeaderList}>
                <div>Наименование начальной точки</div>
                <div>Адрес начальной точки</div>
            </div>
            <div>
                <AutoSizer>
                    {({width, height}) => (
                        <List
                            width={width}
                            height={475}
                            rowCount={popupInitialPointList.length} // Количество элементов в списке
                            rowHeight={101} // Высота отдельного элемента списка
                            rowRenderer={rowRenderer} // Функция для отрисовки элемента списка
                            className={style.popupInitPointListBlock}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
});

export default InitialPointPopupList;