import React, {memo} from 'react';
import style from './ModalEditRouteSheetTableItem.module.css'
import {ReactComponent as DeleteIcon} from '../../../../imges/DeleteIcon.svg';
import createRouteSheetsStore
    from "../../../../store/transportationLogisticsService/pageCreateRouteSheetsStore/createRouteSheetsStore";

const ModalEditRouteSheetTableItem = ({orderData}) => {

    const deleteSelectedOrder = (orderId) => {
        createRouteSheetsStore.deleteSelectedOrderInRoute(orderId)
    }

    const changeSelectedOrder = (value, orderId) => {
        createRouteSheetsStore.changeSelectInTable(value, orderId, "orderInRoute");
    }

    return (
        <div className={style.tableHeader}
        >
            <div className={style.checkbox}
                onClick={() => changeSelectedOrder(!orderData?.checked, orderData?.id)}
            >
                <input
                    type="checkbox"
                    className={style.selectDocumentML}
                    id="selectAllArea"
                    name="selectAllArea"
                    value={orderData?.checked}
                    checked={orderData?.checked}
                    onChange={(e) => {

                    }}
                />
                <label htmlFor="selectAllArea"></label>
            </div>
            <div className={style.address}>{orderData?.order_adress_delivery}</div>
            <div className={style.client}>{orderData?.order_client}</div>
            <div className={style.tonnage}>{Number(orderData?.vehicle_tonnage).toFixed(2) || 0} кг.</div>
            <div className={style.delete}><DeleteIcon
            onClick={() => deleteSelectedOrder(orderData?.id)}
            /></div>
        </div>
    );
};

export default memo(ModalEditRouteSheetTableItem);