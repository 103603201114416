import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { IconButton, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ru'; // Импорт локали русского языка
import * as React from 'react';
import { useEffect, useState } from "react";
import styled from 'styled-components';
import "./RegisterDates.css";

export default function RegisterDates(props) {
    const events = props.dateArr;

    moment.locale('ru');

    const CalendarContainer = styled.div`
        max-width: 800px;
        margin: 0px auto;
    `;

    const CalendarHeader = styled.div`
        align-items: center;
        justify-content: space-between;
        text-align: center;
    `;

    const CalendarDays = styled.div`
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    `;

    const CalendarDay = styled.div`
        padding: 4px;
        text-align: center;
        margin: 1px;
        border-radius: 9px;
        background-color: ${props => (props.isCurrentMonth ? (props.deadline && props.isHighlighted ? '#4CAF50' : !props.deadline && props.isHighlighted ? "red" : "#ffffff") : "#e6e5e5")};
        color:${props => (props.isCurrentMonth ? (props.deadline && props.isHighlighted ? '#ffffff' : !props.deadline && props.isHighlighted ? "#ffffff" : "#000000") : "#666")};
        ${props => (props.isDayOfWeek ? "background-color: transparent; color: #000000;" : "")}
    `;

    const [currentDate, setCurrentDate] = useState(moment());
    const startOfWeek = currentDate.clone().startOf('month').startOf('week');
    const endOfWeek = currentDate.clone().endOf('month').endOf('week');
    const calendarDays = [];

    useEffect(() => {
        document.title = `Календарь - ${currentDate.format('MMMM YYYY')}`;
    }, [currentDate]);

    for (let day = startOfWeek; day.isBefore(endOfWeek); day.add(1, 'day')) {
        calendarDays.push(day.clone());
    }

    const handlePrevMonth = () => {
        setCurrentDate(currentDate.clone().subtract(1, 'month'));
    };

    const handleNextMonth = () => {
        setCurrentDate(currentDate.clone().add(1, 'month'));
    };

    return (
        <CalendarContainer>
            <CalendarHeader style={{display: "flex"}} >
                <IconButton sx={{marginLeft: "10%"}} onClick={handlePrevMonth}><ChevronLeftTwoToneIcon/></IconButton>
                <Typography  variant="h6" gutterBottom>{currentDate.format('MMMM YYYY')}</Typography>
                <IconButton sx={{marginRight: "10%"}} onClick={handleNextMonth}><ChevronRightTwoToneIcon/></IconButton>
            </CalendarHeader>
            <CalendarDays>
                {['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'].map(day => (
                    <CalendarDay key={day} isDayOfWeek>{day}</CalendarDay>
                ))}
                {calendarDays.map(day => {
                    const isCurrentMonth = day.isSame(currentDate, 'month');
                    const eventsForDay = events.filter(event => moment(event.dateOfUpload).isSame(day, 'day'));
                    const isHighlighted = eventsForDay.length > 0;
                    const deadline = eventsForDay.some(event => event.deadline);

                    return (
                        <CalendarDay
                            key={day.format('YYYY-MM-DD')}
                            deadline={deadline}
                            isHighlighted={isHighlighted}
                            isCurrentMonth={isCurrentMonth}
                        >
                            <div>{day.format('D')}</div>
                            {isHighlighted && eventsForDay.map((event, index) => (
                                <div key={index}>{moment(event.dateOfUpload).format('HH:mm')}</div>
                            ))}
                        </CalendarDay>
                    );
                })}
            </CalendarDays>
        </CalendarContainer>
    );
};
