import { TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const redButton = {
    redButtonClass: {
        marginLeft: "3vw",
        marginTop: "2vw",
        backgroundColor: "#eb2746",
        '&:hover': {
            backgroundColor: "#c92842",
      }
      },
};

export default function UnloadingPeriodInput(props) {
 



    // const handlePost = () => {
    //     apiLimitDays(num, props.distribs)
    //     props.setOpen(false)
    //     setTimeout(apiDistributorUsers, 1000)
    //     setTimeout(handleClick, 1000)
    // };



    return (
        <Box className="leftString">
        <Typography>Кол-во дней выгрузки</Typography>

        <TextField
                        sx={{ width: "90%" }}

                            id="standard-number"
                            type="number"
                      
                            value={props.dayLimit}
                            onChange={(event) => {
                                props.setDayLimit(event.target.value);
                            }}
                        />
                        {/* <ButtonGroup>
                        <Button sx={redButton.redButtonClass}
                                onClick={handleClose} variant="contained">Отмена
                        </Button>
                        <Button sx={{marginLeft: "1vw", marginTop: "2vw"}} onClick={handlePost}
                                variant="contained">Сохранить
                        </Button>
                        </ButtonGroup> */}
        </Box>
                
    );
}